import { ReviewStatus } from "@/features/line-sheet-sets/line-sheet-set.types";
import useUpdateLineSheetSetReviewStatus, {
  UpdateLineSheetSetReviewStatusResponse,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-review-status";
import { Flex } from "@chakra-ui/react";
import AppReviewStatusSelect from "@/features/ui/app-review-status-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useEffect, useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";

export interface LineSheetSetReviewStatusUpdaterProps {
  lineSheetSetId: number;
  onUpdate: (response: UpdateLineSheetSetReviewStatusResponse) => void;
}
export default function LineSheetSetReviewStatusUpdater({
  lineSheetSetId,
  onUpdate,
}: LineSheetSetReviewStatusUpdaterProps) {
  const [target, setTarget] = useState<ReviewStatus | null>(null);
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const identity = useIdentity();

  const {
    fire: fireUpdateLineSheetSetReviewStatus,
    isLoading: isUpdatingLineSheetSetReviewStatusLoading,
    error: updateLineSheetSetReviewStatusError,
    clear: clearUpdateLineSheetSetReviewStatus,
  } = useUpdateLineSheetSetReviewStatus();

  useEffect(() => {
    if (updateLineSheetSetReviewStatusError) {
      showError(updateLineSheetSetReviewStatusError);
      clearUpdateLineSheetSetReviewStatus();
    }
  }, [
    clearUpdateLineSheetSetReviewStatus,
    showError,
    updateLineSheetSetReviewStatusError,
  ]);

  return (
    <Flex gap={"8px"}>
      <AppReviewStatusSelect
        value={target}
        onSelect={(value) => {
          setTarget(value);
        }}
      />
      <AppButton
        isDisabled={!target}
        isLoading={isUpdatingLineSheetSetReviewStatusLoading}
        onClick={async () => {
          if (identity?.company?.type === "AGENCY" && target) {
            const response = await fireUpdateLineSheetSetReviewStatus(
              {
                agencyId: identity.company.id,
                lineSheetSetId,
              },
              {
                reviewStatus: target,
              }
            );
            onUpdate(response);
          }
        }}
      >
        {tTitle("change_status")}
      </AppButton>
    </Flex>
  );
}
