import { Field, FieldProps, FormikProps, useFormikContext } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import AppOrderSheetSetsExclusiveSelector from "@/features/invoices/app-order-sheet-sets-exclusive-selector";
import { LinkedOrderSheetSet } from "@/features/invoices/app-order-sheet-set-selector";
import { Flex, FormControl, FormLabel, Skeleton } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import React, { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { ListAllDenseOrderSheetSetsRequest } from "@/features/order-sheet-sets/hooks/use-list-all-dense-order-sheet-sets";
import IsolatedOrderSheetSetsPanel from "@/features/invoices/order-confirmation-invoices/isolated-order-sheet-sets-panel";
import { Company } from "@/features/types";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";

interface FormFields {
  id?: number;
  season?: string;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedBy?: Pick<Company, "id" | "name">;
  orderSheetSets: { id: number }[];
  isRemitted?: boolean;
}

interface OrderConfirmationInvoiceLinkedFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  request?: ListAllDenseOrderSheetSetsRequest;
  onSelect: (value: DenseOrderSheetSet[]) => void;
}
export default function LinkedOrderSheetSetsFormControl<T extends FormFields>({
  formik,
  isEditing = false,
  isDisabled = false,
  request,
  onSelect,
}: OrderConfirmationInvoiceLinkedFormControlProps<T>) {
  const { values } = useFormikContext<FormFields>();
  const { t, tTitle } = useI18nHelper();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Skeleton height={"16px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
        </Flex>
      </AppPanel>
    );
  }

  if (!isEditing) {
    return (
      <IsolatedOrderSheetSetsPanel
        orderSheetSetIds={
          formik.values.id
            ? formik.values.orderSheetSets.map(
                (orderSheetSet) => orderSheetSet.id
              )
            : undefined
        }
      />
    );
  }

  return (
    <AppPanel title={tTitle("common:related_order_sheets")}>
      <Field name={"orderSheetSets"}>
        {(props: FieldProps<LinkedOrderSheetSet[], FormFields>) => {
          return (
            <FormControl display={"flex"} flexDirection={"column"} gap={"4px"}>
              <FormLabel
                fontSize={"12px"}
                color={"#6F6F6C"}
                padding={"0"}
                margin={"0"}
                display={"none"}
              >
                {toTitle(t("line_sheets"))}
              </FormLabel>
              <AppOrderSheetSetsExclusiveSelector
                request={request}
                id={props.field.value.map((orderSheetSet) => orderSheetSet.id)}
                // isDisabled={isEditing && props.form.values.isRemitted}
                isDisabled={isDisabled}
                onSelect={onSelect}
              />
            </FormControl>
          );
        }}
      </Field>
    </AppPanel>
  );
}
