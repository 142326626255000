import {
  LightLineSheetSet,
  ReviewStatus,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface UpdateLineSheetSetReviewStatusRequest {
  reviewStatus: ReviewStatus;
}

export interface UpdateLineSheetSetReviewStatusResponse
  extends LightLineSheetSet {}

export interface UpdateLineSheetSetReviewStatusPathParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const updateLineSheetSetReviewStatusTemplateUrl = `/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/review-status`;

export const updateLineSheetSetReviewStatusKey: YWRKey<UpdateLineSheetSetReviewStatusPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateLineSheetSetReviewStatusTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateLineSheetSetReviewStatus() {
  const fetcher = useFetcher<
    UpdateLineSheetSetReviewStatusRequest,
    UpdateLineSheetSetReviewStatusResponse
  >();

  return useYWR(updateLineSheetSetReviewStatusKey, fetcher);
}
