import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { AppGridContext } from "@/features/order-sheet-sets/app-grid";
import AppStatusLabel from "@/features/ui/app-status-label";
import {
  AbstractInvoice,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

interface AppInvoiceStatusLabelProps<T> {
  row: T;
  context: AppGridContext;
}

export default function AppLegacyInvoiceStatusLabel<
  T extends GridRow & { status: AbstractInvoice["status"] }
>({ row, context }: AppInvoiceStatusLabelProps<T>) {
  return (
    <AppStatusLabel<T>
      i18nPrefix={"invoice_status"}
      keyOrFunc={(row) => row.status}
      row={row}
      context={context}
      isSuccessPredicate={(row) => row.status === "NORMAL"}
    />
  );
}
