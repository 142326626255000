import {
  CompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { Transportation } from "@/features/transportations/transportation.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";

export interface ListTransportationsRequest extends PageNumberBasedListRequest {
  status__eq?: Transportation["status"];
  status__in?: Transportation["status"][];
  "shippedFromList.id__in"?: number[];
}

export interface ListTransportationsResponse
  extends PageNumberBasedListResponse {
  transportations: Transportation[];
}

export interface ListTransportationsPathParameter {
  by: CompanyType;
  companyId: number;
}

export const listTransportationsTemplateUrl = `/:by/:companyId/transportations`;

export function getListTransportationsKey(
  parameter?: ListTransportationsPathParameter,
  request?: ListTransportationsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listTransportationsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListTransportations(
  parameter?: ListTransportationsPathParameter,
  request?: ListTransportationsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListTransportationsRequest,
    ListTransportationsResponse
  >();

  return useSWR(getListTransportationsKey(parameter, request), fetcher, config);
}
