import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface GetCreditInvoiceRequest {}
export interface GetCreditInvoiceResponse extends CreditInvoice {}
export interface GetCreditInvoicePathParameter extends IdentityParameter {
  creditInvoiceId: number;
}

export const getCreditInvoiceTemplateUrl = `/:by/:companyId/credit-invoices/:creditInvoiceId`;

export function getGetCreditInvoiceKey(
  parameter: GetCreditInvoicePathParameter,
  request: GetCreditInvoiceRequest
) {
  return {
    url: calcUrl(getCreditInvoiceTemplateUrl, parameter),
    params: request,
  };
}

export default function useGetCreditInvoice(
  parameter: GetCreditInvoicePathParameter,
  request: GetCreditInvoiceRequest,
  config?: SWRConfiguration<GetCreditInvoiceResponse>
) {
  const fetcher = useFetcher<
    GetCreditInvoiceRequest,
    GetCreditInvoiceResponse
  >();
  return useSWR(getGetCreditInvoiceKey(parameter, request), fetcher, config);
}
