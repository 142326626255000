import {
  ReviewStatus,
  ReviewStatuses,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";

interface AppReviewStatusSelectProps {
  value: ReviewStatus | null;
  onSelect: (value: ReviewStatus | null) => void;
}
export default function AppReviewStatusSelect({
  value,
  onSelect,
}: AppReviewStatusSelectProps) {
  const { tUpper, tTitle } = useI18nHelper();
  const options = useMemo(() => {
    return ReviewStatuses.filter((status) => status !== "SKIPPED").map(
      (status) => {
        return {
          name: tUpper(`line_sheet_set.review_status.${toLower(status)}`),
          value: status,
        };
      }
    );
  }, [tUpper]);

  return (
    <AppSelect<ReviewStatus | null>
      width={"100%"}
      name={tTitle("review_status")}
      value={value}
      options={options}
      isClearable={true}
      onSelect={(value) => {
        onSelect(value || null);
      }}
    />
  );
}
