import { TransportationStatus } from "@/features/transportations/transportation.type";
import { toLower } from "@/utils/case";
import AppLabel, { AppLabelProps } from "@/features/ui/app-label";

interface TransportationStatusLabelProps {
  value?: TransportationStatus;
}

function getLevel(value: TransportationStatus): AppLabelProps["level"] {
  switch (value) {
    default:
      return "SUCCESS";
  }
}

export default function TransportationStatusLabel({
  value,
}: TransportationStatusLabelProps) {
  if (!value) {
    return null;
  }
  return (
    <AppLabel
      nameKey={`transportation.status.${toLower(value)}`}
      level={getLevel(value)}
    />
  );
}
