import { Container, Flex, Grid, GridItem, Spacer } from "@chakra-ui/react";
import AppIdentitySelector from "@/features/ui/app-identity-selector";
import { Outlet } from "react-router";
import AppSignOutButton from "@/features/ui/app-sign-out-button";
import AppNavigationLogo from "@/features/ui/app-navigation/app-navigation-logo";
import AppDumbTransactionModal from "@/features/ui/dumb-transaction/app-dumb-transaction-modal";
import AppLanguageSelector from "@/features/ui/app-language-selector";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect } from "react";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import AppMyAccountContextMenu from "@/features/ui/app-my-account-context-menu";
import AppNavigation from "@/features/ui/app-navigation";
import { ScrollRestoration } from "react-router-dom";

export default function PrivateLayout() {
  const identity = useIdentity();
  const { navigate, location } = useRouterHelper();

  useEffect(() => {
    if (identity === null) {
      //url을 바로 변경하면
      setTimeout(() => {
        //signOut시에 location변경을 해도 component의 state가 변경됨으로 rerender발생 -> 이 hook실행됨
        //을 방지하기위해서 hack사용
        if (sessionStorage.getItem("signOut")) {
          sessionStorage.removeItem("signOut");
          return;
        }

        const redirectUrl = encodeURIComponent(
          location.pathname + location.search
        );

        navigate("/auth/sign-in?redirect_url=" + redirectUrl, {
          replace: true,
        });
      }, 300);
    } else if (identity.company === null) {
      navigate("/auth/sign-up-pending", { replace: true });
    }
  }, [identity, location.pathname, location.search, navigate]);

  if (identity === null) {
    return null;
  }

  return (
    <Container
      maxW="container.2xl"
      padding={"0"}
      background={"#FCFCFE"}
      position={"relative"}
      overflow={"hidden"}
    >
      <Grid
        height="100vh"
        maxHeight={"100vh"}
        templateColumns="180px 1fr"
        templateRows="48px 1fr"
        padding={"0"}
      >
        <GridItem
          gridColumn={"1/3"}
          gridRow={"1/2"}
          borderBottom={"1px solid #EFEFED"}
        >
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            h={"100%"}
          >
            <AppNavigationLogo collapsed={false} />
            <Spacer />
            <AppIdentitySelector />
            <AppLanguageSelector />
            {identity?.company?.type === "BUYER" ? (
              <AppMyAccountContextMenu />
            ) : (
              <AppSignOutButton />
            )}
          </Flex>
        </GridItem>
        <GridItem
          gridColumn={"1/2"}
          gridRow={"2/3"}
          borderRight={"1px solid #EFEFED"}
          position={"relative"}
          zIndex={"2"}
          background={"#FCFCFE"}
          overflowY={"auto"}
        >
          <AppNavigation />
        </GridItem>
        <GridItem gridColumn={"2/3"} gridRow={"2/3"} overflow={"hidden"}>
          <Outlet />
        </GridItem>
      </Grid>
      <AppDumbTransactionModal />
    </Container>
  );
}
