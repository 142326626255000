import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect, useMemo, useRef } from "react";
import useListAllOrderConfirmationInvoices, {
  ListAllOrderConfirmationInvoicesRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import {
  DenseOrderConfirmationInvoice,
  OrderConfirmationInvoice,
  OrderConfirmationInvoiceDetail,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useBiSWRResponse from "@/features/invoices/deposit-invoices/use-bi-swr-response";
import { isEmpty, uniqBy } from "lodash";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useListAllDenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-dense-order-confirmation-invoices";
import PriceUtils from "@/features/ui/utils/price-utils";

export interface LinkedOrderConfirmationInvoice {
  id: number;
  name: string;
  totalAmount: number;
  totalRequiredAmount: number;
  actualRemainingDepositAmount: number;
  expectedRemainingDepositAmount: number;
  orderConfirmationInvoiceDetailList: OrderConfirmationInvoiceDetail[];
  isRemitted: boolean;
}

interface AppOrderConfirmationInvoicesExclusiveSelectorProps {
  request?: ListAllOrderConfirmationInvoicesRequest;
  // value: LinkedOrderConfirmationInvoice[] | LinkedOrderConfirmationInvoice;
  id?: number | number[];
  onSelect: (value: DenseOrderConfirmationInvoice[]) => void;
  isDisabled?: boolean;
  nameFunc?: (ocInvoice: DenseOrderConfirmationInvoice) => string;
}

export default function AppOrderConfirmationInvoicesExclusiveSelector({
  request,
  // value,
  id,
  onSelect,
  isDisabled,
  nameFunc,
}: AppOrderConfirmationInvoicesExclusiveSelectorProps) {
  let isMultiple = Array.isArray(id) || id === undefined;
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle, tCurrencyString } = useI18nHelper();

  const key1 = useMemo(() => {
    if (identity?.company && request && !isEmpty(request)) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const key2 = useMemo(() => {
    if (identity?.company && id) {
      const id__in = Array.isArray(id) ? id : [id];
      if (id__in.length > 0) {
        return {
          parameter: {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          request: {
            id__in: Array.isArray(id) ? id : [id],
          },
        };
      }
    }
  }, [id, identity]);

  const response1 = useListAllDenseOrderConfirmationInvoices(
    key1?.parameter,
    key1?.request
  );

  const response2 = useListAllDenseOrderConfirmationInvoices(
    key2?.parameter,
    key2?.request
  );

  const {
    data: resources,
    isLoading: isListResourcesLoading,
    error: isListResourcesError,
  } = useBiSWRResponse(response1, response2, (data1, data2) => {
    return uniqBy([...(data1 || []), ...(data2 || [])], (i) => i.id);
  });

  useEffect(() => {
    if (isListResourcesError) {
      showError(isListResourcesError);
    }
  }, [isListResourcesError, showError]);

  const value = useMemo(() => {
    if (resources && id) {
      const ids = Array.isArray(id) ? id : [id];
      return ids
        .map((id) => resources.find((i) => i.id === id))
        .filter(
          (resource): resource is DenseOrderConfirmationInvoice =>
            resource !== undefined
        );
    }
    return [];
  }, [resources, id]);

  return (
    <AppExclusiveSelect<DenseOrderConfirmationInvoice>
      name={"order_confirmation_invoice"}
      values={value}
      options={resources || []}
      optionFunc={(ocInvoice) => {
        let name = "";
        if (nameFunc) {
          name = nameFunc(ocInvoice);
        } else {
          name = `${ocInvoice.id} / ${ocInvoice.name} `;
          name += `(${tTitle("deposit")} - `;
          name += `${tTitle("total")} : ${tCurrencyString(
            ocInvoice.totalRequiredAmount
          )}`;

          if (ocInvoice.isRemittedToBoutique) {
            name += ", ";
            name += `${tTitle("used")} : `;
            name += tCurrencyString(
              PriceUtils.placeholder(
                PriceUtils.minus(
                  [
                    ocInvoice.totalRequiredAmount,
                    ocInvoice.expectedRemainingDepositAmount,
                  ],
                  "EUR"
                ),
                "EUR"
              )
            );
            name += ` `;
            name += `${tTitle("remaining")} : ${tCurrencyString(
              ocInvoice.expectedRemainingDepositAmount
            )})`;
          }

          name += ")";
        }

        return {
          value: ocInvoice,
          name: name,
        };
      }}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isLoading={isListResourcesLoading}
      isMultiple={isMultiple}
    />
  );
}
