import {
  AppLineCardAction,
  CARD_ACTION_WIDTH,
} from "@/features/line-sheet-sets/app-flat-card";
import {
  Button,
  ChakraProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import SVG from "react-inlinesvg";

interface AppFlatCardContextMenuProps {
  actions: AppLineCardAction[];
  hasExtraContents?: boolean;
}

export default function AppFlatCardContextMenu({
  actions,
  hasExtraContents,
}: AppFlatCardContextMenuProps) {
  if (actions.length === 0) {
    return null;
  } else if (actions.length === 1) {
    const action = actions[0];

    let _hover: ChakraProps["_hover"] = {};
    if (!action.isDisabled) {
      _hover = {
        borderColor: "#1272EF",
        borderLeftColor: "transparent",
        color: "#1272EF",
      };
    }

    let buttonChild;
    if (action.isLoading) {
      buttonChild = <Spinner size={"sm"} />;
    } else if (action.iconSrc) {
      buttonChild = <SVG src={action.iconSrc} width={"20px"} height={"14px"} />;
    } else {
      buttonChild = <Text fontWeight={"700"}>{action.name}</Text>;
    }

    return (
      <Button
        p={"0"}
        m={"0"}
        borderRadius={`0 8px ${hasExtraContents ? "0" : "8px"} 0`}
        border={"0.5px solid #8F8F8C"}
        borderLeftColor={"transparent"}
        height={"100%"}
        width={CARD_ACTION_WIDTH}
        fontSize={"12px"}
        _hover={_hover}
        color={action.isDisabled ? "#D4D4D1" : "inherit"}
        backgroundColor={action.isDisabled ? "#EFEFED" : "#fff"}
        isDisabled={action.isDisabled}
        isLoading={action.isLoading}
        opacity={"1.0 !important"}
        onClick={() => {
          if (!action.isDisabled && !action.isLoading) {
            action.onClick();
          }
        }}
      >
        {buttonChild}
      </Button>
    );
  } else {
    return (
      <Popover
        placement={"bottom-start"}
        arrowShadowColor={"#8F8F8C"}
        trigger={"hover"}
      >
        <PopoverTrigger>
          <Button
            p={"0"}
            m={"0"}
            backgroundColor={"#fff"}
            borderRadius={`0 8px ${hasExtraContents ? "0" : "8px"} 0`}
            border={"0.5px solid #8F8F8C"}
            borderLeftColor={"transparent"}
            height={"100%"}
            width={CARD_ACTION_WIDTH}
            color={"#8F8F8C"}
            _hover={{
              borderColor: "#1272EF",
              borderLeftColor: "transparent",
              color: "#1272EF",
            }}
          >
            <SVG
              src={"/icons/icon_ellipsis.svg"}
              width={"20px"}
              height={"14px"}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent width={"192px"}>
          <PopoverArrow />
          <PopoverBody p={"0"}>
            <Flex
              flexDirection={"column"}
              width={"100%"}
              boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.15)"}
            >
              {actions.map((action, index) => {
                const isFirst = index === 0;
                const isLast = index === actions.length - 1;

                let _hover: ChakraProps["_hover"] = {};
                if (!action.isDisabled) {
                  _hover = {
                    color: "#1272EF",
                    background: "#F7F9FA",
                    borderColor: "#1272EF",
                    "+ *": {
                      borderTopColor: "transparent",
                    },
                  };
                }

                return (
                  <Tooltip
                    key={action.name}
                    label={action.disabledMessage}
                    isDisabled={!action.isDisabled}
                    placement={"bottom-end"}
                  >
                    <Button
                      size={"medium"}
                      isDisabled={action.isDisabled}
                      onClick={() => {
                        if (!action.isDisabled && !action.isLoading) {
                          action.onClick();
                        }
                      }}
                      width={"100%"}
                      fontSize={"12px"}
                      padding={"12px 24px"}
                      backgroundColor={action.isDisabled ? "#EFEFED" : "#fff"}
                      border={"0.5px solid #8F8F8C"}
                      borderRadius={
                        isFirst ? "4px 4px 0 0" : isLast ? "0 0 4px 4px" : "0"
                      }
                      opacity={"1.0 !important"}
                      borderBottomColor={!isLast ? "transparent" : "#8F8F8C"}
                      _hover={_hover}
                      color={action.isDisabled ? "#D4D4D1" : "inherit"}
                      onMouseEnter={() => {
                        if (
                          !action.isDisabled &&
                          !action.isLoading &&
                          action.onHover
                        ) {
                          action.onHover(true, action);
                        }
                      }}
                      onMouseLeave={() => {
                        if (
                          !action.isDisabled &&
                          !action.isLoading &&
                          action.onHover
                        ) {
                          action.onHover(false, action);
                        }
                      }}
                    >
                      {action.isLoading ? (
                        <Spinner size={"sm"} />
                      ) : (
                        <Text fontWeight={"700"}>{action.name}</Text>
                      )}
                    </Button>
                  </Tooltip>
                );
              })}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
}
