import { IdentityParameter, StoredObject } from "@/features/types";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";

export interface UpdateBankRemittanceReceiptRequest
  extends Omit<
    BankRemittanceReceipt,
    "createdAt" | "file" | "isRead" | "depositInvoice"
  > {
  file: Pick<StoredObject, "id">;
}

export interface UpdateBankRemittanceReceiptResponse
  extends BankRemittanceReceipt {}

export interface UpdateBankRemittanceReceiptPathParameter
  extends IdentityParameter {
  bankRemittanceReceiptId: number;
}

export const updateBankRemittanceReceiptTemplateUrl = `/:by/:companyId/bank-remittance-receipts/:bankRemittanceReceiptId`;

export const updateBankRemittanceReceiptKey: YWRKey<UpdateBankRemittanceReceiptPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateBankRemittanceReceiptTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateBankRemittanceReceipt(
  config?: AxiosRequestConfig<UpdateBankRemittanceReceiptRequest>
) {
  const fetcher = useFetcher<
    UpdateBankRemittanceReceiptRequest,
    UpdateBankRemittanceReceiptResponse
  >();
  return useYWR(updateBankRemittanceReceiptKey, fetcher, config);
}
