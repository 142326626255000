import useFetcher from "@/utils/use-fetcher";
import {
  createLineSheetSetKey,
  CreateLineSheetSetRequest,
  CreateLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-create-line-sheet-set";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useCreateLineSheetSetInfinite() {
  const fetcher = useFetcher<
    CreateLineSheetSetRequest,
    CreateLineSheetSetResponse
  >();
  return useYWRInfinite(createLineSheetSetKey, fetcher);
}
