import { LineSheetFile } from "@/features/line-sheet-sets/line-sheet-file-controller";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import { StoredObject } from "@/features/types";
import { toLower } from "@/utils/case";

interface LineSheetFileTypeSelectProps {
  value?: LineSheetFile["type"];
  isExcel: boolean;
  onSelect: (value: LineSheetFile["type"]) => void;
  isDisabled?: boolean;
}

export default function LineSheetFileTypeSelect({
  value,
  isExcel,
  onSelect,
}: LineSheetFileTypeSelectProps) {
  const { tTitle } = useI18nHelper();

  const options = useMemo(() => {
    return (() => {
      if (isExcel) {
        return ["LINE_SHEET", "ORDER_FORM", "ATTACHMENT"];
      } else {
        return ["ATTACHMENT"];
      }
    })().map((type) => {
      return {
        name: tTitle(toLower(type)),
        value: type,
      };
    });
  }, [isExcel, tTitle]);

  return (
    <AppSelect
      width={"100%"}
      name={tTitle("type")}
      options={options}
      value={value}
      onSelect={(value) => {
        onSelect(value as LineSheetFile["type"]);
      }}
    />
  );
}
