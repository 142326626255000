import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import useBulkPublishLineSheetSets from "@/features/line-sheet-sets/hooks/use-bulk-publish-line-sheet-sets";
import { useCallback, useEffect, useMemo, useState } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";
import useListLineSheetSets from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { appDialogState } from "@/features/ui/recoils/app-dialog-state";
import { Company, OrderByItem } from "@/features/types";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";
import AppInput from "@/features/ui/app-input";
import AppIcon from "@/features/ui/app-icon";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import debounce from "lodash/debounce";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import usePublishLineSheetSet from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set";

const DIALOG_KEY = "PUBLISH_LINE_SHEET_SET";

interface DialogProps {
  ids: number[];
}

function Dialog({ ids }: DialogProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const [query, setQuery] = useState<string>();
  const [_, setDialogState] = useRecoilState(appDialogState);

  const listLineSheetsKey = useMemo(() => {
    if (identity?.company && ids.length > 0) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: ids,
          pageSize: ids.length,
          pageNumber: 1,
          orderBy: [new OrderByItem("id", "ascend")],
        },
      };
    }
    return undefined;
  }, [identity, ids]);

  const { data: listLineSheetSetsResponse } = useListLineSheetSets(
    listLineSheetsKey?.parameter,
    listLineSheetsKey?.request
  );

  const listBuyersKey = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (identity?.company?.type === "AGENCY") {
      return {
        parameter: {
          by: "AGENCY",
          companyId: identity.company.id,
        },
        request: {
          type__in: ["BUYER"],
        },
      };
    }
    return undefined;
  }, [identity]);

  const {
    data: buyers,
    isLoading,
    error,
  } = useListAllCompanies(listBuyersKey?.parameter, listBuyersKey?.request);

  const [value, setValue] =
    useState<{ buyer: Company; isChecked: boolean; isDisabled?: boolean }[]>();

  const [filteredValue, setFilteredValue] = useState(value);

  const { fire: bulkPublishLineSheetSets } = useBulkPublishLineSheetSets();

  const { fire: publishLineSheetSet } = usePublishLineSheetSet();

  const handleConfirm = useMemo(() => {
    if (listLineSheetSetsResponse && identity?.company?.type === "AGENCY") {
      const agencyId = identity.company.id;
      const lineSheetSets = listLineSheetSetsResponse.lineSheetSets;
      const issuedToBuyers =
        value
          ?.filter((item) => item.isChecked)
          .map((item) => {
            return { id: item.buyer.id };
          }) || [];
      if (lineSheetSets.length > 1) {
        return async () => {
          return bulkPublishLineSheetSets(
            { agencyId },
            {
              lineSheetSets: lineSheetSets.map((lineSheetSet) => {
                return { id: lineSheetSet.id };
              }),
              issuedToBuyers,
            }
          );
        };
      } else if (lineSheetSets.length === 1) {
        return async () => {
          return publishLineSheetSet(
            { agencyId, lineSheetSetId: lineSheetSets[0].id },
            {
              issuedToBuyers,
            }
          );
        };
      }
    }
  }, [
    bulkPublishLineSheetSets,
    identity,
    listLineSheetSetsResponse,
    publishLineSheetSet,
    value,
  ]);

  useEffect(() => {
    if (listLineSheetSetsResponse && value && handleConfirm) {
      const isDisabled =
        listLineSheetSetsResponse.lineSheetSets.some(
          (lineSheetSet) => lineSheetSet.compressionStatus !== "COMPLETED"
        ) ||
        !value.some((item) => {
          return !item.isDisabled && item.isChecked;
        });

      setDialogState((prev) => {
        return prev.map((item) => {
          if (item.id === DIALOG_KEY) {
            return {
              ...item,
              isDisabled,
              onConfirm: handleConfirm,
            };
          }
          return item;
        });
      });
    }
  }, [handleConfirm, listLineSheetSetsResponse, setDialogState, value]);

  useEffect(() => {
    if (buyers && listLineSheetSetsResponse) {
      const isLineSheetSetIssued = listLineSheetSetsResponse.lineSheetSets.some(
        (lineSheetSet) => lineSheetSet.publishedAt !== null
      );

      const issuedToBuyerIds = listLineSheetSetsResponse.lineSheetSets
        .flatMap((lineSheetSet) => lineSheetSet.issuedToBuyers)
        .map((issuedToBuyer) => issuedToBuyer.id);

      setValue((prev) => {
        return buyers.map((buyer) => {
          const isIssuedToBuyer = issuedToBuyerIds.includes(buyer.id);
          return {
            buyer: buyer,
            isChecked: isLineSheetSetIssued ? isIssuedToBuyer : true,
            isDisabled: isIssuedToBuyer,
          };
        });
      });
    }
  }, [buyers, listLineSheetSetsResponse]);

  useEffect(() => {
    const debouncedFilter = debounce((query: string) => {
      setFilteredValue(
        value?.filter((item) =>
          item.buyer.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    });

    if (!query) {
      setFilteredValue(value);
    } else {
      debouncedFilter(query);
    }

    return () => debouncedFilter.cancel();
  }, [query, value]);

  return (
    <Flex flexDirection={"column"} gap={"8px"}>
      <Text as={"h3"} color={"#444440"} fontSize={"12px"}>
        {t("common:publish_line_sheet_sets_confirm.message")}
      </Text>

      <Flex flexDirection={"column"} gap={"10px"}>
        <Text
          fontSize={"12px"}
          borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
        >
          {tTitle("line_sheet_sets")}
        </Text>

        {listLineSheetSetsResponse ? (
          listLineSheetSetsResponse?.lineSheetSets.map((lineSheetSet) => {
            return (
              <Text
                key={`PublishLineSheetSetModalLineSheetSet_${lineSheetSet.id}`}
                fontSize={"12px"}
              >
                {lineSheetSet.name}
              </Text>
            );
          })
        ) : (
          <Skeleton height={"24px"} />
        )}
      </Flex>

      <Text fontSize={"12px"} borderBottom={"0.5px solid var(--gray, #D4D4D1)"}>
        {tTitle("receivers")}
      </Text>

      <AppInput
        value={query}
        isClearable={true}
        leftIcon={<AppIcon icon={"search"} size={"small"} />}
        onChange={(query) => {
          setQuery(query);
        }}
      />
      <Flex
        flexDirection={"column"}
        gap={"8px"}
        height={"200px"}
        overflowY={"auto"}
      >
        <AppCheckBox
          name={tTitle("all")}
          isDisabled={value?.every((item) => item.isDisabled)}
          isChecked={(value || []).every((item) => item.isChecked)}
          onChange={(isChecked) => {
            setValue((prev) => {
              return prev?.map((item) => {
                return {
                  ...item,
                  isChecked: item.isDisabled ? item.isChecked : isChecked,
                };
              });
            });
          }}
        />
        {filteredValue?.map((item) => {
          return (
            <AppCheckBox
              key={item.buyer.id}
              name={item.buyer.name}
              isChecked={item.isChecked}
              isDisabled={item.isDisabled}
              onChange={(isChecked) => {
                setValue((prev) => {
                  if (prev) {
                    return prev.map((prevItem) => {
                      if (prevItem.buyer.id === item.buyer.id) {
                        return {
                          ...prevItem,
                          isChecked,
                        };
                      }
                      return prevItem;
                    });
                  }
                });
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default function usePublishLineSheetSetIfConfirmed() {
  const { openDialog } = useAppDialog();
  const { tTitle } = useI18nHelper();

  const fire = useCallback(
    async (ids: number | number[]) => {
      return openDialog({
        id: DIALOG_KEY,
        title: tTitle("publish_line_sheet_sets"),
        confirmName: tTitle("publish.do"),
        isDisabled: true,
        message: <Dialog ids={Array.isArray(ids) ? ids : [ids]} />,
      });
    },
    [openDialog, tTitle]
  );

  return {
    fire,
  };
}
