import {
  LightOrderSheetSet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

interface BulkUpdateOrderSheetSetStatusRequest {
  orderSheetSetIds: number[];
  status: OrderSheetStatus;
}
export interface BulkUpdateOrderSheetSetStatusResponse {
  orderSheetSets: LightOrderSheetSet[];
}
interface BulkUpdateOrderSheetSetStatusPathParameter {
  agencyId: number;
}

const bulkUpdateOrderSheetStatusTemplateUrl =
  "/agencies/:agencyId/order-sheet-sets/bulk-update-status";

export const bulkUpdateOrderSheetSetStatusKey: YWRKey<BulkUpdateOrderSheetSetStatusPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(bulkUpdateOrderSheetStatusTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useBulkUpdateOrderSheetSetStatus() {
  const fetcher = useFetcher<
    BulkUpdateOrderSheetSetStatusRequest,
    BulkUpdateOrderSheetSetStatusResponse
  >();

  return useYWR(bulkUpdateOrderSheetSetStatusKey, fetcher);
}
