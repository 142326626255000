import { NavigateFunction, NavigateOptions, useNavigate } from "react-router";
import { useCallback, useMemo } from "react";

export interface WrappedNavigateFunction extends NavigateFunction {
  (delta: number, defaultPage?: string): void;
}

export default function useWrappedNavigate() {
  const navigate = useNavigate();

  const wrappedNavigate: WrappedNavigateFunction = useCallback(
    (to, optionsOrDefaultPage?: NavigateOptions | string) => {
      if (typeof to === "number") {
        if (to === -1) {
          const isLast = window.history.state.idx === 0;
          if (isLast) {
            const to =
              optionsOrDefaultPage && typeof optionsOrDefaultPage === "string"
                ? optionsOrDefaultPage
                : "/";
            navigate(to);
            return;
          }
        }
        navigate(to);
      } else if (
        optionsOrDefaultPage === undefined ||
        typeof optionsOrDefaultPage !== "string"
      ) {
        navigate(to, optionsOrDefaultPage);
      }
    },
    [navigate]
  );

  return wrappedNavigate;
}
