import { ListOrderSheetSetsRequest } from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";
import { AxiosRequestConfig } from "axios";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useSWR, { SWRConfiguration } from "swr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useListDenseOrderSheetSetsFetcher from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-sets-fetcher";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseOrderSheetSetsRequest
  extends ListOrderSheetSetsRequest {}

export interface ListDenseOrderSheetSetsResponse
  extends PageNumberBasedListResponse {
  orderSheetSets: DenseOrderSheetSet[];
}

export interface ListDenseOrderSheetSetsPathParameter
  extends IdentityParameter {}

export const listDenseOrderSheetSetsTemplateUrl =
  "/:by/:companyId/dense-order-sheet-sets";

export function getListDenseOrderSheetSetsKey(
  parameter?: ListDenseOrderSheetSetsPathParameter,
  request?: ListDenseOrderSheetSetsRequest
): SWRObjectKey<ListOrderSheetSetsRequest> | null {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseOrderSheetSetsTemplateUrl, parameter),
      params: request,
    };
  }

  return null;
}

export function parseListDenseOrderSheetSetsKey(
  config: AxiosRequestConfig<ListDenseOrderSheetSetsRequest>
) {
  const urlParser = new UrlParser<ListDenseOrderSheetSetsPathParameter>(
    listDenseOrderSheetSetsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(config.url!),
    request: config.params,
  };
}

export function readDenseOrderSheetSetProactively(orderSheetSetId: number) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-order-sheet-sets/,
    "orderSheetSets",
    orderSheetSetId
  );
}

export default function useListDenseOrderSheetSet(
  parameter?: ListDenseOrderSheetSetsPathParameter,
  request?: ListDenseOrderSheetSetsRequest,
  config: SWRConfiguration = {
    revalidateOnFocus: false,
  }
) {
  const useListDenseOrderSheetSetFetcher = useListDenseOrderSheetSetsFetcher();

  return useSWR(
    getListDenseOrderSheetSetsKey(parameter, request),
    useListDenseOrderSheetSetFetcher,
    config
  );
}
