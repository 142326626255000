import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import AppLogo from "@/features/ui/app-logo";
import AppSelect from "@/features/line-sheet-sets/app-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import useWrappedNavigate from "@/features/ui/hooks/use-wrapped-navigate";

export default function _404Page() {
  const navigate = useWrappedNavigate();
  return (
    <Flex
      maxW="440px"
      w="100%"
      backgroundColor={"#FFF"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border="0.5px solid #D4D4D1"
      borderRadius="8px"
      padding={"28px 0px"}
      gap="24px"
      position="relative"
    >
      <AppLogo />
      <Heading fontSize="64px" fontWeight={"700"} color={"#444444"}>
        404
      </Heading>
      <Text fontSize={"32px"} fontWeight={"700"} color={"#444444"}>
        {"Something's missing"}
      </Text>
      <Text
        padding={"0 80px"}
        fontSize={"14px"}
        textAlign={"center"}
        minHeight={"269px"}
      >
        {
          "Don't worry - Pathrade hasn't crashed. It's just the page that's run into minor problems."
        }
      </Text>
      {/*{socialButtons}*/}
      <Box width={"100%"} padding={"0 80px"}>
        <AppButton
          variant={"gray"}
          width={"100%"}
          size={"large"}
          height={"100%"}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </AppButton>
      </Box>

      <Text fontSize={"12px"}>© UNO Trading Corp.</Text>
      <AppSelect
        name={"Language"}
        value={"English"}
        isMultiple={false}
        options={[{ value: "English", name: "English" }]}
      />
    </Flex>
  );
}
