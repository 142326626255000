import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import {
  LightOrderSheetSet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { CompanyType } from "@/features/types";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface UpdateOrderSheetSetStatusRequest {
  status: OrderSheetStatus;
}

export interface UpdateOrderSheetSetStatusResponse {
  orderSheetSet: LightOrderSheetSet;
}

export interface UpdateOrderSheetSetStatusPathParameter {
  by: CompanyType;
  companyId: number;
  orderSheetSetId: number;
}

const updateOrderSheetStatusTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/status`;

export const updateOrderSheetSetStatusKey: YWRKey<UpdateOrderSheetSetStatusPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateOrderSheetStatusTemplateUrl, parameter);
    },
    method: "put",
  };

export function parseUpdateOrderSheetSetStatusKey(url: string) {
  const urlParser = new UrlParser<UpdateOrderSheetSetStatusPathParameter>(
    updateOrderSheetStatusTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "put",
  };
}

export default function useUpdateOrderSheetSetStatus() {
  const fetcher = useFetcher<
    UpdateOrderSheetSetStatusRequest,
    UpdateOrderSheetSetStatusResponse
  >();

  return useYWR(updateOrderSheetSetStatusKey, fetcher);
}
