import { Helmet } from "react-helmet-async";
import { Box, Text } from "@chakra-ui/react";
import AuthLayout from "@/features/auth/auth-layout";
import SocialButtonGroup from "@/features/auth/social-buttons/social-button-group";
import SignInForm from "@/features/auth/sign-in/sign-in-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export default function SignInPage() {
  const { t } = useTranslation();
  const tail = (
    <Box fontSize={"12px"}>
      {t("new_to_here", { ns: "auth" })} &nbsp;
      <Link to={"/auth/sign-up"}>
        <Text as="u" fontWeight={"700"}>
          {t("sign_up_now", { ns: "auth" })}
        </Text>
      </Link>
    </Box>
  );

  return (
    <AuthLayout
      title={toTitle(t("sign_in", { ns: "auth" }))}
      tail={tail}
      // socialButtons={<SocialButtonGroup name={"sign in"} />}
    >
      <Helmet>
        <title>Pathrade | {t("sign_in", { ns: "auth" })}</title>
        <meta
          name="description"
          content={t("meta.description", { ns: "auth" })}
        />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content={t("meta.og.title", { ns: "auth" })}
        />
        <meta
          property="og:description"
          content={t("meta.description", { ns: "auth" })}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <SignInForm />
    </AuthLayout>
  );
}
