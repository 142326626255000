import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { IdentityParameter } from "@/features/types";
import {
  getGetOrderSheetSetKey,
  GetOrderSheetSetRequest,
  GetOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-set";
import {
  getGetLineSheetSetKey,
  GetLineSheetSetRequest,
  GetLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-get-line-sheet-set";
import { AxiosRequestConfig } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import {
  DenseOrderSheetSet,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import useGetDenseOrderSheetSetFetcher from "@/features/order-sheet-sets/hooks/use-get-dense-order-sheet-set-fetcher";

export interface GetDenseOrderSheetSetRequest {}

export type GetDenseOrderSheetSetResponse = DenseOrderSheetSet;

export interface GetDenseOrderSheetSetPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetIds?: number[];
  ts?: string;
}

export const getDenseOrderSheetSetTemplateUrl = `/:by/:companyId/dense-order-sheet-sets/:orderSheetSetId`;

export function getGetDenseOrderSheetSetKey(
  parameter?: GetDenseOrderSheetSetPathParameter,
  request?: GetDenseOrderSheetSetRequest
): SWRObjectKey<GetDenseOrderSheetSetRequest> | null {
  if (parameter && request && parameter.orderSheetSetId > -1) {
    return {
      url: calcUrl(getDenseOrderSheetSetTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetDenseOrderSheetSetKey(
  key: AxiosRequestConfig<GetDenseOrderSheetSetRequest>
) {
  const urlParser = new UrlParser<GetDenseOrderSheetSetPathParameter>(
    getDenseOrderSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      orderSheetIds: StringTransformer.parseIds,
      ts: StringTransformer.parseString,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function withGetDenseOrderSheetSetFetcher(
  getOrderSheetSetFetcher: (
    config: AxiosRequestConfig<GetOrderSheetSetRequest>
  ) => Promise<GetOrderSheetSetResponse>,
  getLineSheetSetFetcher: (
    config: AxiosRequestConfig<GetLineSheetSetRequest>
  ) => Promise<GetLineSheetSetResponse>
) {
  return async (
    config: AxiosRequestConfig<GetDenseOrderSheetSetRequest>
  ): Promise<GetDenseOrderSheetSetResponse> => {
    const { url: _virtualUrl, params } = config;
    const virtualUrl = _virtualUrl!;
    const { parameter } = parseGetDenseOrderSheetSetKey({
      url: virtualUrl,
      params,
    });

    const getOrderSheetSetKey = getGetOrderSheetSetKey(
      {
        by: parameter.by,
        companyId: parameter.companyId,
        orderSheetSetId: parameter.orderSheetSetId,
      },
      {}
    );

    const getOrderSheetSetResponse = await getOrderSheetSetFetcher(
      getOrderSheetSetKey
    );

    if (getOrderSheetSetResponse) {
      const lineSheetSetId = getOrderSheetSetResponse.lineSheetSet.id;
      const getLineSheetSetKey = getGetLineSheetSetKey(
        {
          by: parameter.by,
          companyId: parameter.companyId,
          lineSheetSetId,
        },
        {}
      )!!;

      const getLineSheetSetResponse = await getLineSheetSetFetcher(
        getLineSheetSetKey
      );

      const lightOrderSheetSet: LightOrderSheetSet = getOrderSheetSetResponse;
      const denseOrderSheetSet: DenseOrderSheetSet = {
        ...lightOrderSheetSet,
        lineSheetSet: getLineSheetSetResponse,
        orderSheets: lightOrderSheetSet.orderSheets.map((orderSheet) => {
          const lineSheet = getLineSheetSetResponse.lineSheets.find(
            (i) => i.id === orderSheet.lineSheet.id
          )!!;

          const orderSheetTabs = orderSheet.orderSheetTabs.map(
            (orderSheetTab) => {
              const lineSheetTab = lineSheet.lineSheetTabs.find(
                (i) => i.index === orderSheetTab.index
              )!!;
              return {
                ...orderSheetTab,
                name: lineSheetTab.name,
              };
            }
          );

          return {
            ...orderSheet,
            orderSheetTabs,
            lineSheet,
          };
        }),
      };
      return Promise.resolve(denseOrderSheetSet);
    }

    throw {
      error: {
        code: -1,
        status: "RESOURCE NOT FOUND",
        message: "",
      },
    };
  };
}

export default function useGetDenseOrderSheetSet(
  parameter?: GetDenseOrderSheetSetPathParameter,
  request?: GetDenseOrderSheetSetRequest,
  config?: SWRConfiguration
) {
  const fetcher = useGetDenseOrderSheetSetFetcher();

  return useSWR(
    getGetDenseOrderSheetSetKey(parameter, request),
    fetcher,
    config
  );
}
