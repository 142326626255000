import { TriangularTradeFormFields } from "@/features/invoices/triangular-trades/upload-triangular-trade-page";
import { FormikProps } from "formik";
import { useCallback, useEffect } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import BinarySplitView from "@/features/ui/binary-split-view";
import TriangularTradeInformationFormControl from "@/features/invoices/triangular-trades/triangular-trade-information-form-control";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import InvoiceFileInput from "@/features/invoices/invoice-file-input";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import { isFile, isStoredObject, StoredObject } from "@/features/types";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import { findYWRInfiniteSegment, postProcessYWRInfiniteData } from "@/features/ui/helpers/ywr-helpers";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

interface TriangularTradeFormProps {
  formik: FormikProps<TriangularTradeFormFields>;
  isEditing?: boolean;
  isRefreshing?: boolean;
}

export default function TriangularTradeForm({
  formik,
  isEditing = false,
  isRefreshing,
}: TriangularTradeFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  const { error: showError } = useAppToasts();

  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );


  const handleFilesAdd = useCallback(
    (value: File | File[]) => {
      let files: (File | Pick<StoredObject, "id" | "name">)[] = Array.isArray(value) ? value : [value];
      if (formik.values.importedFiles) {
        files = formik.values.importedFiles.concat(files);
      }
      formik.setFieldValue("importedFiles", files);
    }
    ,
    [formik]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const { tTitle } = useI18nHelper();

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <TriangularTradeInformationFormControl
              formik={formik}
              isEditing={isEditing}
              isRefreshing={isRefreshing}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            {isEditing && (
              <AppFileDragAndDropZone
              isMultiple={true}
              onFilesAdd={handleFilesAdd}
              />
            )}
            {formik.values.importedFiles && (
              <AppPanel title={tTitle("original_file")}>
                {formik.values.importedFiles.map((file, index) => {
                  return (
                    <Flex
                      key={index}
                      marginBottom={"8px"}
                    >
                      <InvoiceFileInput
                        key={index}
                        value={{
                          id: -1,
                          name: file.name,
                          createdAt: new Date()
                        }}
                        isReadOnly={!isEditing}
                        isLoading={isStoredObject(file) ? findDownloadUnoFileSegment(file.id)?.isLoading : false}
                        onChange={(value) => {
                          formik.setFieldValue("importedFiles", formik.values.importedFiles?.map((f, i) => {
                            if (i === index) {
                              return value;
                            }
                            return f;
                          }));
                        }}
                        onDelete={() => {
                          formik.setFieldValue("importedFiles", formik.values.importedFiles?.filter((f, i) => i !== index));
                        }}
                        onClick={() => {
                          if (!isFile(file)) {
                            fireDownloadUnoFile(
                              {
                                unoFileId: file.id
                              },
                              {}
                            );
                          }
                        }}
                      />
                    </Flex>
                  );
                })}
              </AppPanel>
            )}

            {!isEditing && (
              <InvoiceFileFormControl
                showCreatedAt={true}
                formik={formik}
                isEditing={false}
                title={tTitle("common:output_file")}
                fieldName={"exportedFile"}
              />
            )}

            {!isEditing &&
              formik.values.status === "PENDING" &&
              !formik.values.exportedFile &&
              formik.values.importedFiles && (
                <AppPanel title={tTitle("output_file")}>
                  <InvoiceFileInput
                    value={{
                      id: -1,
                      name: "",
                      createdAt: null,
                    }}
                    isReadOnly={true}
                    isLoading={true}
                    onChange={() => {}}
                    onDelete={() => {}}
                  />
                </AppPanel>
              )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={400}
      />
    </Box>
  );
}
