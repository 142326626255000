import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import AppSelect from "@/features/line-sheet-sets/app-select";
import React, { useMemo } from "react";
import { range } from "lodash";
import { useTranslation } from "react-i18next";

interface AppDatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  name?: string;
  months: string[];
  setIsOpen: (isOpen: boolean) => void;
  minDate?: Date;
  maxDate?: Date;
  locale: Locale;
}
export default function AppDatePickerHeader(props: AppDatePickerHeaderProps) {
  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const maxYear = props.maxDate?.getFullYear() || currentYear + 4;
    return range(props.minDate?.getFullYear() || 2020, maxYear + 1).map((i) => {
      return {
        name: String(i),
        value: i,
      };
    });
  }, [props.minDate, props.maxDate]);

  const monthOptions = useMemo(() => {
    const currentYear = props.date.getFullYear();
    const minMonth =
      props.minDate?.getFullYear() === currentYear
        ? props.minDate?.getMonth()
        : 0;
    const maxMonth =
      props.maxDate?.getFullYear() === currentYear
        ? props.maxDate?.getMonth()
        : 11;

    return range(minMonth, maxMonth + 1).map((i) => {
      return {
        name: props.locale.localize!.month(i),
        value: i,
      };
    });
  }, [props.date, props.minDate, props.maxDate, props.locale.localize]);

  const { t } = useTranslation();

  return (
    <>
      <Text
        textAlign={"center"}
        fontSize={"12px"}
        fontWeight={"700"}
        borderBottom={"1px solid #D4D4D1"}
        padding={"8px 12px"}
        background={"#F7F9FA"}
        position={"relative"}
      >
        {props.name || "Choose Date"}
        <IconButton
          position={"absolute"}
          right={"12px"}
          top={"12px"}
          aria-label={"close"}
          minW={"0"}
          minH={"0"}
          w={"12px"}
          h={"12px"}
          boxSize={"12px"}
          background={"transparent"}
          _hover={{ background: "transparent" }}
          onClick={() => props.setIsOpen(false)}
          icon={
            <SVG
              src={"/icons/icon_x_letter.svg"}
              color={"#8F8F8C"}
              width={"12px"}
              height={"12px"}
            />
          }
        />
      </Text>
      <Flex
        w={"100%"}
        flexDirection={"row"}
        justifyContent={"center"}
        gap={"8px"}
        padding={"8px 0"}
      >
        <AppSelect<number>
          width={"88px"}
          name={t("year")}
          options={yearOptions}
          value={props.date.getFullYear()}
          isClearable={false}
          onSelect={(year) => {
            if (year !== undefined) {
              if (props.minDate?.getFullYear() === year) {
                if (props.date.getMonth() < props.minDate.getMonth()) {
                  props.changeMonth(props.minDate.getMonth());
                }
              }

              if (props.maxDate?.getFullYear() === year) {
                if (props.date.getMonth() > props.maxDate.getMonth()) {
                  props.changeMonth(props.maxDate.getMonth());
                }
              }
              props.changeYear(year);
            }
          }}
        />
        <AppSelect<number>
          value={props.date.getMonth()}
          width={"88px"}
          name={t("month")}
          options={monthOptions}
          isClearable={false}
          onSelect={(month) => {
            if (month !== undefined) {
              props.changeMonth(month);
            }
          }}
        />
      </Flex>
    </>
  );
}
