import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export class TriGridMapper<
  T1 extends { id: number },
  R1 extends GridRow,
  T2 extends { id: number },
  R2 extends GridRow,
  T3 extends { id: number },
  R3 extends GridRow
> {
  private readonly mapper1: GridMapper<T1, R1>;
  private readonly mapper2: GridMapper<T2, R2>;
  private readonly mapper3: GridMapper<T3, R3>;
  private readonly isT1: (resource: T1 | T2 | T3) => resource is T1;
  private readonly isT2: (resource: T1 | T2 | T3) => resource is T2;
  private readonly isT3: (resource: T1 | T2 | T3) => resource is T3;

  constructor(
    mapper1: GridMapper<T1, R1>,
    isT1: (resource: T1 | T2 | T3) => resource is T1,
    mapper2: GridMapper<T2, R2>,
    isT2: (resource: T1 | T2 | T3) => resource is T2,
    mapper3: GridMapper<T3, R3>,
    isT3: (resource: T1 | T2 | T3) => resource is T3
  ) {
    this.mapper1 = mapper1;
    this.mapper2 = mapper2;
    this.mapper3 = mapper3;
    this.isT1 = isT1;
    this.isT2 = isT2;
    this.isT3 = isT3;
  }

  toRows(
    resources?: (T1 | T2 | T3)[],
    prevRows?: (R1 | R2 | R3)[],
    parentRow?: GridRow
  ) {
    if (resources) {
      return resources
        .map((resource) => {
          if (this.isT1(resource)) {
            return this.mapper1.toRow(
              resource,
              prevRows?.find(
                (r): r is R1 =>
                  r.id === resource.id && r._rowType === this.mapper1.type
              ),
              parentRow
            );
          } else if (this.isT2(resource)) {
            return this.mapper2.toRow(
              resource,
              prevRows?.find(
                (r): r is R2 =>
                  r.id === resource.id && r._rowType === this.mapper2.type
              ),
              parentRow
            );
          } else if (this.isT3(resource)) {
            return this.mapper3.toRow(
              resource,
              prevRows?.find(
                (r): r is R3 =>
                  r.id === resource.id && r._rowType === this.mapper3.type
              ),
              parentRow
            );
          }
        })
        .filter((row): row is R1 | R2 | R3 => row !== undefined);
    }

    return undefined;
  }

  copy() {
    return new TriGridMapper(
      this.mapper1.copy(),
      this.isT1,
      this.mapper2.copy(),
      this.isT2,
      this.mapper3.copy(),
      this.isT3
    );
  }

  toResource(row: R1 | R2 | R3): T1 | T2 | T3 {
    if (this.mapper1.type === row._rowType) {
      return this.mapper1.toResource(row as R1) as T1;
    } else if (this.mapper2.type === row._rowType) {
      return this.mapper2.toResource(row as R2) as T2;
    } else if (this.mapper3.type === row._rowType) {
      return this.mapper3.toResource(row as R3) as T3;
    } else {
      throw {
        message: `failed to find mapper for row type:${row._rowType}`,
      };
    }
  }

  toResources(rows: (R1 | R2 | R3)[]): (T1 | T2 | T3)[] {
    return rows.map((row) => this.toResource(row));
  }
}
