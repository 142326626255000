import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import {
  LightOrderSheet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { CompanyType } from "@/features/types";

export interface BulkUpdateOrderSheetStatusRequest {
  orderSheetIds: number[];
  status: OrderSheetStatus;
}

export interface BulkUpdateOrderSheetStatusResponse {
  orderSheets: LightOrderSheet[];
}

export interface BulkUpdateOrderSheetStatusPathParameter {
  by: CompanyType;
  companyId: number;
  orderSheetSetId: number;
}

export const bulkUpdateOrderSheetStatusTemplateUrl =
  "/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/bulk-update-status";

export const bulkUpdateOrderSheetStatusKey: YWRKey<BulkUpdateOrderSheetStatusPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(bulkUpdateOrderSheetStatusTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useBulkUpdateOrderSheetStatus() {
  const fetcher = useFetcher<
    BulkUpdateOrderSheetStatusRequest,
    BulkUpdateOrderSheetStatusResponse
  >();

  return useYWR(bulkUpdateOrderSheetStatusKey, fetcher);
}
