import { Box, Flex, Heading, Spacer, Textarea } from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useUpdateLineSheetSetOrderRules, {
  UpdateLineSheetSetOrderRulesResponse,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-order-rules";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface OrderRulesPanelProps {
  onClose: () => void;
  isEditable: boolean;
  orderRules: string[];
  lineSheetSetId: number;
  onUpdate: (response: UpdateLineSheetSetOrderRulesResponse) => void;
}

export default function OrderRulesPanel({
  onClose,
  isEditable,
  orderRules,
  lineSheetSetId,
  onUpdate,
}: OrderRulesPanelProps) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(orderRules.join("\n"));
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const { openDialog } = useAppDialog();
  const { warning: showWarning, error: showError } = useAppToasts();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { t, tTitle } = useI18nHelper();

  const {
    fire: fireUpdateLineSheetSetOrderRules,
    data: updateLineSheetSetOrderRulesData,
    isLoading: isUpdateLineSheetSetOrderRulesLoading,
    error: updateLineSheetSetOrderRulesError,
    clear: clearUpdateLineSheetSetOrderRules,
  } = useUpdateLineSheetSetOrderRules();

  useEffect(() => {
    showError(updateLineSheetSetOrderRulesError);
  }, [showError, updateLineSheetSetOrderRulesError]);

  const handleClose = useCallback(() => {
    if (isEdited) {
      openDialog({
        title: "Discard Changes?",
        message: "Are you sure you want to discard your changes?",
        confirmName: "Discard",
        onConfirm: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  }, [onClose, isEdited, openDialog]);

  const handleSave = useCallback(() => {
    if (company?.type === "AGENCY" && lineSheetSetId > -1) {
      fireUpdateLineSheetSetOrderRules(
        {
          agencyId: company.id,
          lineSheetSetId,
        },
        {
          orderRules: value.split("\n"),
        }
      );
    }
  }, [company, value, fireUpdateLineSheetSetOrderRules, lineSheetSetId]);

  useEffect(() => {
    //저장하고 또 저장할수 있께 해줘야하는데, ㅇㅋ
    if (updateLineSheetSetOrderRulesData) {
      setIsEdited(false);
      setIsEditing(false);

      clearUpdateLineSheetSetOrderRules();
      onUpdate(updateLineSheetSetOrderRulesData);
    }
  }, [
    updateLineSheetSetOrderRulesData,
    clearUpdateLineSheetSetOrderRules,
    setIsEditing,
    setIsEdited,
    onUpdate,
  ]);

  return (
    <Flex flexDirection={"column"} height={"100%"} width={"280px"}>
      <Flex
        marginTop={"4px"}
        padding={"12px 8px"}
        gap={"8px"}
        borderRadius={"0px 4px 0px 0px"}
        border={"0.5px solid var(--Gray, #D4D4D1)"}
        background={"var(--White, #FFF)"}
      >
        <Heading as={"h3"} fontSize={"12px"} paddingLeft={"4px"}>
          {tTitle("order_rules")}
        </Heading>
        <Spacer />

        {isEditable && (
          <>
            <AppIconButton
              ariaLabel={"save order rules"}
              icon={"save"}
              size={"medium"}
              isLoading={isUpdateLineSheetSetOrderRulesLoading}
              isDisabled={!isEditing || !isEdited}
              onClick={handleSave}
            />
            <AppIconButton
              ariaLabel={"edit order rules"}
              icon={"edit"}
              size={"medium"}
              isActive={isEditing}
              isDisabled={isUpdateLineSheetSetOrderRulesLoading}
              onClick={() => {
                setIsEditing(true);
                if (textAreaRef.current) {
                  textAreaRef.current.focus();
                }
              }}
            />
          </>
        )}

        <AppIconButton
          ariaLabel={"close order rules"}
          icon={"x"}
          size={"medium"}
          isDisabled={isUpdateLineSheetSetOrderRulesLoading}
          onClick={handleClose}
        />
      </Flex>
      <Box
        flexGrow={"1"}
        padding={"8px"}
        borderRadius={"0px 0px 4px 0px"}
        borderRight={"0.5px solid var(--Gray, #D4D4D1)"}
        borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
        borderLeft={"0.5px solid var(--Gray, #D4D4D1)"}
        background={"var(--White, #FFF)"}
        marginBottom={"15px"}
      >
        <Textarea
          ref={textAreaRef}
          height={"100%"}
          padding={isEditing ? "8px" : "4px"}
          lineHeight={1.6}
          fontSize={"12px"}
          border={isEditing ? "0.5px solid var(--Gray, #D4D4D1)" : "none"}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setIsEdited(true);
          }}
          readOnly={!isEditing}
        ></Textarea>
      </Box>
    </Flex>
  );
}
