import { Image } from "@chakra-ui/react";

export default function AppLogo() {
  return (
    <h1>
      <a href={"/?utm_source=pathrade-web&utm_medium=product&utm_campaign=product-auth-signin-link&utm_content=text-link"}>
        <Image src={"/logo.svg"} alt={"Pathrade Logo"} width={"176px"} />
      </a>
    </h1>
  );
}
