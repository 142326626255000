import useDashboardPagePersistedQuery from "@/features/dashboard/hooks/use-dashboard-page-persisted-query";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppDashboardCard from "@/features/dashboard/app-dashboard-card";
import { Box, Flex } from "@chakra-ui/react";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import React, { useEffect, useMemo } from "react";
import {
  ListOrderStatisticsByTypeRequest,
  useListOrderStatisticsByType,
} from "@/features/dashboard/hooks/use-list-order-statistics-by-type";
import AppTable, { AppTableCell } from "@/features/line-sheet-sets/app-table";
import { OrderStatisticByType } from "@/features/dashboard/dashboard.type";
import AppButton from "@/features/line-sheet-sets/app-button";
import { CURRENT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import AppLink from "@/features/dashboard/app-link";
import { PersistedLegacyLineSheetSetsListPageQuery } from "@/features/line-sheet-sets/hooks/use-persisted-line-sheet-set-list-query";

export default function OrderStatisticsCard() {
  const { value: queryValue, set: setQuery } = useDashboardPagePersistedQuery();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tCurrencyString, tUpper, tTitle, tNumberString } = useI18nHelper();
  const listOrderStatisticsKey = useMemo(() => {
    if (identity?.company?.type === "BUYER") {
      const request: ListOrderStatisticsByTypeRequest = {
        season__in: queryValue.orderStatisticSeason__in,
      };

      if (queryValue["orderStatisticMadeByCompany.id__eq"]) {
        request["madeByCompany.id__eq"] =
          queryValue["orderStatisticMadeByCompany.id__eq"];
      }

      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: request,
      };
    }
  }, [identity, queryValue]);

  const {
    isLoading: isListOrderStatisticsByTypeLoading,
    data: listOrderStatisticsByTypeData,
    error: listOrderStatisticsByTypeError,
  } = useListOrderStatisticsByType(
    listOrderStatisticsKey?.parameter,
    listOrderStatisticsKey?.request
  );

  useEffect(() => {
    if (listOrderStatisticsByTypeError) {
      showError(listOrderStatisticsByTypeError);
    }
  }, [showError, listOrderStatisticsByTypeError]);

  const routerHelper = useRouterHelper();

  const table = useMemo(() => {
    const rows = listOrderStatisticsByTypeData?.orderStatisticsByType.map(
      (item): AppTableCell<OrderStatisticByType>[] => {
        const readableType = item.type.replace("_", " ");
        return [
          { value: "" },
          {
            value: (
              <Flex flexDirection={"row"} justifyContent={"start"}>
                <AppLink<PersistedLegacyLineSheetSetsListPageQuery>
                  title={tUpper(toLower(item.type.replace(/\s/, "_")))}
                  pathname={"/line-sheets"}
                  query={{
                    type__eq: item.type.includes("SEASON") ? "SEASON" : "STOCK",
                    season__in: queryValue["orderStatisticSeason__in"],
                    "madeByCompany.id__eq":
                      queryValue["orderStatisticMadeByCompany.id__eq"],
                    pageNumber: 1,
                  }}
                />
                {/*<LineSheetSetListLink*/}
                {/*  title={}*/}
                {/*  query={{*/}
                {/*    type__eq:*/}
                {/*      item.type === "[SEASON]_ORDERED_TO_AGENCY"*/}
                {/*        ? "SEASON"*/}
                {/*        : "STOCK",*/}
                {/*    season__in: queryValue["orderStatisticSeason__in"],*/}
                {/*    "madeByCompany.id__eq":*/}
                {/*      queryValue["orderStatisticMadeByCompany.id__eq"],*/}
                {/*  }}*/}
                {/*/>*/}
              </Flex>
            ),
          },
          { value: tCurrencyString(item.orderedAmount), textAlign: "end" },
          {
            value: `${tNumberString(item.orderedQuantity)}`,
            textAlign: "end",
          },
          { value: tCurrencyString(item.confirmedAmount), textAlign: "end" },
          {
            value: `${tNumberString(item.confirmedQuantity)}`,
            textAlign: "end",
          },
          { value: "" },
        ];
      }
    );

    return (
      <React.Fragment>
        <AppTable<OrderStatisticByType>
          headers={[
            {
              value: "",
              width: "24px",
              color: "#6F6F6C",
            },
            {
              value: "",
              width: "2fr",
              color: "#6F6F6C",
            },
            {
              value: tTitle("ordered_amount"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
            },
            {
              value: tTitle("ordered_quantity"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
            },
            {
              value: tTitle("confirmed_amount"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
            },
            {
              value: tTitle("confirmed_quantity"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
            },
            {
              value: "",
              width: "24px",
              color: "#6F6F6C",
            },
          ]}
          rows={rows}
          rowHeight={"32px"}
          isLoading={listOrderStatisticsByTypeData === undefined}
          loadingRowCount={2}
          gap={"80px"}
        />
        <Box height={"24px"}></Box>
      </React.Fragment>
    );
  }, [
    listOrderStatisticsByTypeData,
    queryValue,
    tCurrencyString,
    tNumberString,
    tTitle,
    tUpper,
  ]);

  return (
    <AppDashboardCard
      title={tTitle("order")}
      filters={[
        <AppCompanySelect
          key={"OrderStatusFilter_Brand"}
          name={tTitle("brand")}
          types={["BRAND"]}
          id={queryValue["orderStatisticMadeByCompany.id__eq"]}
          onSelect={(company) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  "orderStatisticMadeByCompany.id__eq": company?.id,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppSeasonMultiSelect
          key={"OrderStatusFilter_Season"}
          value={queryValue.orderStatisticSeason__in}
          onSelect={(value) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  orderStatisticSeason__in: value,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppButton
          key={"OrderStatusFilter_Reset"}
          variant={"normal"}
          size={"medium"}
          width={"144px"}
          onClick={() => {
            setQuery((prev) => {
              return {
                ...prev,
                "orderStatisticMadeByCompany.id__eq": undefined,
                orderStatisticSeason__in: [CURRENT_SEASON],
              };
            });
          }}
        >
          {tTitle("reset_filter")}
        </AppButton>,
      ]}
      totalStatistics={[
        {
          name: tTitle("total_ordered_amount"),
          value: tCurrencyString(
            listOrderStatisticsByTypeData?.totalOrderStatistic.orderedAmount ||
              0
          ),
        },
        {
          name: tTitle("total_confirmed_amount"),
          value: tCurrencyString(
            listOrderStatisticsByTypeData?.totalOrderStatistic
              .confirmedAmount || 0
          ),
        },
        {
          name: tTitle("total_ordered_quantity"),
          value: `${tNumberString(
            listOrderStatisticsByTypeData?.totalOrderStatistic
              .orderedQuantity || 0
          )}`,
        },
        {
          name: tTitle("total_confirmed_quantity"),
          value: `${tNumberString(
            listOrderStatisticsByTypeData?.totalOrderStatistic
              .confirmedQuantity || 0
          )}`,
        },
      ]}
    >
      {table}
    </AppDashboardCard>
  );
}
