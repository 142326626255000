import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";
import { Company } from "@/features/types";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  getListIssuedByListKey,
  ListIssuedByListPathParameter,
  ListIssuedByListRequest,
  ListIssuedByListResponse,
} from "@/features/invoices/triangular-trades/hooks/use-list-issued-by";

export interface ListAllIssuedByListRequest
  extends Omit<ListIssuedByListRequest, "pageSize" | "pageNumber"> {}
type ListAllIssuedByListResponse = Company[];
export interface ListAllIssuedByListPathParameter
  extends ListIssuedByListPathParameter {}

export const listAllIssuedByListTemplateUrl = `/:by/:companyId/issued_by`;

export function getListAllIssuedByListKey(
  parameter?: ListAllIssuedByListPathParameter,
  request?: ListAllIssuedByListRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllIssuedByListTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllIssuedByListKey(
  key: AxiosRequestConfig<ListAllIssuedByListRequest>
) {
  const urlParser = new UrlParser<ListAllIssuedByListPathParameter>(
    listAllIssuedByListTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllIssuedByList(
  parameter?: ListAllIssuedByListPathParameter,
  request?: ListAllIssuedByListRequest,
  config: SWRConfiguration = { revalidateOnFocus: false }
) {
  const listFetcher = useFetcher<
    ListIssuedByListRequest,
    ListIssuedByListResponse
  >();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListIssuedByListRequest>) => {
        const { parameter, request } = parseListAllIssuedByListKey(config);
        return listFetcher(getListIssuedByListKey(parameter, request)!);
      },
      (response) => {
        return response.issuedByList;
      }
    );
  }, [listFetcher]);

  return useSWR(getListAllIssuedByListKey(parameter, request), fetcher, config);
}
