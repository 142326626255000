import { useMemo } from "react";
import { ChakraProps, Flex, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toUpper } from "@/utils/case";

type Level = "SUCCESS" | "ERROR" | "PRIMARY" | "LIGHT";

export interface AppLabelProps {
  nameKey: string;
  level?: Level;
  isFaded?: boolean;
  isToUpper?: boolean;
  size?: "small" | "medium" | "large";
}

export default function AppLabel({
  nameKey,
  level = "LIGHT",
  size = "medium",
  isFaded = false,
  isToUpper = true,
}: AppLabelProps) {
  const { t } = useI18nHelper();
  const style = useMemo(() => {
    const style: ChakraProps = {
      borderRadius: "2px",
      borderWidth: "1px",
      borderStyle: "solid",
      fontSize: "12px",
      padding: "0 8px",
      fontWeight: "700",
    };

    switch (size) {
      case "small":
        style.fontSize = "10px";
        style.padding = "0 4px";
        break;
    }

    switch (level) {
      case "SUCCESS":
        if (!isFaded) {
          style.borderColor = "#16B41C";
          style.backgroundColor = "#F0FAEF";
          style.color = "#444440";
        } else {
          style.borderColor = "#82C284";
          style.backgroundColor = "#FCFCFB";
          style.color = "#6F6F6C";
        }
        break;
      case "ERROR":
        if (!isFaded) {
          style.borderColor = "#EE7D7A";
          style.backgroundColor = "#FFEEEE";
          style.color = "#444440";
        } else {
          style.borderColor = "#FFC9C9";
          style.backgroundColor = "#FCFCFB";
          style.color = "#6F6F6C";
        }
        break;
      case "PRIMARY":
        if (!isFaded) {
          style.borderColor = "#1272EF";
          style.backgroundColor = "#F7F9FA";
          style.color = "#444440";
        } else {
          style.borderColor = "#72AEFC";
          style.backgroundColor = "#F7F9FA";
          style.color = "#6F6F6C";
        }
        break;
      case "LIGHT":
        if (!isFaded) {
          style.borderColor = "#8F8F8C";
          style.color = "#444440";
        } else {
          style.borderColor = "#D4D4D1";
          style.backgroundColor = "#FCFCFB";
          style.color = "#6F6F6C";
        }
        break;
    }
    return style;
  }, [isFaded, level, size]);

  const name = useMemo(() => {
    return isToUpper ? toUpper(t(nameKey)) : t(nameKey);
  }, [isToUpper, nameKey, t]);

  return (
    <Flex flexDirection={"row"} alignItems={"center"} justifyContent={"start"}>
      <Text {...style}>{name}</Text>
    </Flex>
  );
}
