import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import React, { useCallback, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { Grid } from "@chakra-ui/react";
import BankRemittanceReceiptForm from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-form";
import { BankRemittanceReceiptFormFields } from "@/features/invoices/bank-remittance-receipts/upload-bank-remittance-receipt-page";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { isFile, isStoredObject, Price, StoredObject } from "@/features/types";
import { useBankRemittanceReceiptDumbTransaction } from "@/features/invoices/bank-remittance-receipts/hooks/use-bank-remittance-receipt-dumb-transaction";
import useUpdateBankRemittanceReceipt, {
  UpdateBankRemittanceReceiptPathParameter,
  UpdateBankRemittanceReceiptRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-update-bank-remittance-receipt";
import useBankRemittanceReceiptFormValidationSchema from "@/features/invoices/bank-remittance-receipts/hooks/use-bank-remittance-receipt-form-validation-schema";
import useGetDenseBankRemittanceReceipt, {
  GetDenseBankRemittanceReceiptParameter,
  GetDenseBankRemittanceReceiptRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-get-dense-bank-remittance-receipt";
import useReadResource from "@/features/invoices/proforma-invoices/hooks/use-read-resource";
import useReadBankRemittanceReceipt from "@/features/invoices/bank-remittance-receipts/hooks/use-read-bank-remittance-receipt";
import InvoiceDetailPageHeader from "@/features/invoices/bank-remittance-receipts/invoice-detail-page-header";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";
import PriceUtils from "@/features/ui/utils/price-utils";

interface BankRemittanceReceiptDetailPageProps {
  isEditing?: boolean;
}

export default function BankRemittanceReceiptDetailPage({
  isEditing = false,
}: BankRemittanceReceiptDetailPageProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { bankRemittanceReceiptId } = useRequiredParams<{
    bankRemittanceReceiptId: number;
  }>();
  const { navigate } = useRouterHelper();

  const getBankRemittanceReceiptKey = useMemo(():
    | {
        parameter: GetDenseBankRemittanceReceiptParameter;
        request: GetDenseBankRemittanceReceiptRequest;
      }
    | undefined => {
    if (identity?.company && bankRemittanceReceiptId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          bankRemittanceReceiptId,
        },
        request: {},
      };
    }

    return undefined;
  }, [bankRemittanceReceiptId, identity]);

  const {
    data: bankRemittanceReceipt,
    error: getBankRemittanceReceiptError,
    mutate: mutateGetBankRemittanceReceipt,
  } = useGetDenseBankRemittanceReceipt(
    getBankRemittanceReceiptKey?.parameter,
    getBankRemittanceReceiptKey?.request
  );

  useEffect(() => {
    if (getBankRemittanceReceiptError) {
      showError(getBankRemittanceReceiptError);
    }
  }, [getBankRemittanceReceiptError, showError]);

  const initialValues = useMemo((): BankRemittanceReceiptFormFields => {
    if (bankRemittanceReceipt) {
      return {
        id: bankRemittanceReceipt.id,
        name: bankRemittanceReceipt.name,
        season: bankRemittanceReceipt.season,
        orderedBy: bankRemittanceReceipt.issuedToCompany,
        issuedOn: bankRemittanceReceipt.issuedOn,
        publicComment: bankRemittanceReceipt.publicComment || undefined,
        privateComment: bankRemittanceReceipt.privateComment || undefined,
        file: bankRemittanceReceipt.file || undefined,
        sender: bankRemittanceReceipt.sender,
        receiver: bankRemittanceReceipt.receiver,
        issuedThrough: bankRemittanceReceipt.issuedThroughCompany,
        depositInvoice: bankRemittanceReceipt.depositInvoice || undefined,
        amount: bankRemittanceReceipt.amount,
        payments: bankRemittanceReceipt.payments.map((payment) => {
          return {
            id: payment.id,
            amount: payment.amount,
            name: payment.name,
            unremittedAmount: payment.unremittedAmount,
          };
        }),
        bankRemittanceReceiptDetailList:
          bankRemittanceReceipt.bankRemittanceReceiptDetailList,
      };
    } else {
      return {
        issuedOn: new Date(),
        payments: [],
        bankRemittanceReceiptDetailList: [],
      };
    }
  }, [bankRemittanceReceipt]);

  const validateSchema = useBankRemittanceReceiptFormValidationSchema();

  const validate = useCallback(
    async (values: BankRemittanceReceiptFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { isUpdateQueued, handleSubmit } = useDumbMixedUpdate({
    smartFunction: useUpdateBankRemittanceReceipt,
    dumbFunction: useBankRemittanceReceiptDumbTransaction,
    isSmart: (request): request is UpdateBankRemittanceReceiptRequest => {
      return isStoredObject(request.file);
    },
    toRequest: (values: BankRemittanceReceiptFormFields) => {
      const request: Omit<UpdateBankRemittanceReceiptRequest, "file"> & {
        file: Pick<StoredObject, "id" | "name"> | File;
      } = {
        id: values.id!,
        name: values.name!,
        season: values.season!,
        issuedToCompany: values.orderedBy!,
        issuedThroughCompany: values.issuedThrough!,
        issuedOn: values.issuedOn!,
        publicComment: values.publicComment || null,
        privateComment: values.privateComment || null,
        sender: values.sender!,
        receiver: values.receiver!,
        payments: values.payments.map((payment) => {
          return {
            id: payment.id,
          };
        }),
        amount: PriceUtils.placeholder(
          values.amount,
          values.receiver?.type === "BOUTIQUE" ? "EUR" : "KRW"
        ),
        file: values.file!,
        bankRemittanceReceiptDetailList:
          values.bankRemittanceReceiptDetailList.map((detail) => {
            return {
              id: detail.id,
              amount: detail.amount as Price,
              payment: detail.payment!,
            };
          }),
      };
      return request;
    },
    defaultRedirectUrl: `/bank-remittance-receipts/${bankRemittanceReceiptId}`,
    mutate: mutateGetBankRemittanceReceipt,
  });

  useReadResource({
    ywrFunc: useReadBankRemittanceReceipt,
    resource: bankRemittanceReceipt,
    mutate: mutateGetBankRemittanceReceipt,
    when: () => identity?.company?.type === "BUYER",
  });

  return (
    <Formik<BankRemittanceReceiptFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          return await handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              bankRemittanceReceiptId: values.id!,
            },
            values,
            formikHelpers
          );
        }
        return false;
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <InvoiceDetailPageHeader
              isEditing={isEditing}
              formik={props}
              isUpdateQueued={
                identity?.company && props.values.id
                  ? isUpdateQueued({
                      by: identity.company.type,
                      companyId: identity.company.id,
                      bankRemittanceReceiptId: props.values.id,
                    })
                  : false
              }
              title={tTitle("bank_remittance_receipt")}
              baseUrl={"/bank-remittance-receipts"}
            />
            <BankRemittanceReceiptForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
