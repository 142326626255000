import { DenseProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useGetDenseProformaFetcher from "@/features/invoices/proforma-invoices/hooks/use-get-dense-proforma-fetcher";
import useSWR, { SWRConfiguration } from "swr";

export interface GetDenseProformaInvoiceParameter extends IdentityParameter {
  proformaInvoiceId: number;
}
export interface GetDenseProformaInvoiceRequest {}
export interface GetDenseOrderDenseConfirmationInvoiceResponse
  extends DenseProformaInvoice {}

export const getDenseProformaInvoiceTemplateUrl = `/:by/:companyId/dense-proforma-invoices/:proformaInvoiceId`;

export function getGetDenseProformaInvoiceKey(
  parameter?: GetDenseProformaInvoiceParameter,
  request?: GetDenseProformaInvoiceRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDenseProformaInvoiceTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetDenseProformaInvoiceKey(
  key: AxiosRequestConfig<GetDenseProformaInvoiceRequest>
) {
  const urlParser = new UrlParser<GetDenseProformaInvoiceParameter>(
    getDenseProformaInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      proformaInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetDenseProformaInvoice(
  parameter?: GetDenseProformaInvoiceParameter,
  request?: GetDenseProformaInvoiceRequest,
  config?: SWRConfiguration<GetDenseOrderDenseConfirmationInvoiceResponse>
) {
  const fetcher = useGetDenseProformaFetcher();
  return useSWR(
    getGetDenseProformaInvoiceKey(parameter, request),
    fetcher,
    config
  );
}
