import {
  Box,
  Button,
  ChakraProps,
  Flex,
  Icon,
  Input,
  List,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import AppSelectOption from "@/features/line-sheet-sets/app-select-option";
import React, {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { AppUtils } from "@/features/ui/utils/app-utils";

type AppSelectSizeType = "medium" | "small";

export type AppSelectProps<T> = SingleSelectProps<T> | MultipleSelectProps<T>;

interface SelectProps<T> {
  name: string;
  variant?: "normal" | "icon";
  title?: string;
  value?: T | T[];
  idFunc?: (t: T) => any;
  options: AppSelectOption<T>[];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  minWidth?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  size?: AppSelectSizeType;
  // buttonRef?: React.Ref<HTMLButtonElement>;
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
  isSearchable?: boolean;
  isClearable?: boolean;
  isReadOnly?: boolean;
  isMultiple?: boolean;
  isLiteral?: boolean;
  popoverStrategy?: "fixed" | "absolute";
}

interface SingleSelectProps<T> extends SelectProps<T> {
  value?: T;
  isMultiple?: false;
  onSelect?: (value?: T) => void;
}

interface MultipleSelectProps<T> extends SelectProps<T> {
  value?: T[];
  isMultiple: true;
  onSelect?: (value?: T[]) => void;
}

function calcSizeStyle(size: AppSelectSizeType): ChakraProps {
  let customStyle: ChakraProps = {};
  if (size === "medium") {
    customStyle = {
      p: "4px 12px",
    };
  } else if (size === "small") {
    customStyle = {
      p: "4px 8px",
    };
  }
  return customStyle;
}

function calcVariantStyle(
  isDisabled: boolean,
  isActive: boolean,
  isHovered: boolean,
  readOnly: boolean,
  variant: AppSelectProps<any>["variant"]
): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: "4px",
    borderWidth: "0.5px",
    borderStyle: "solid",
    _hover: {},
  };

  let customStyle: ChakraProps = {};

  if (variant === "normal") {
    if (isDisabled) {
      customStyle = {
        borderColor: "#8F8F8C",
        backgroundColor: "#EFEFED",
        color: "#6F6F6C",
        cursor: "default",
      };
    } else if (isActive) {
      customStyle = {
        borderColor: "#1272EF",
        backgroundColor: "#FFF",
        color: "#1272EF",
      };
    } else if (isHovered) {
      customStyle = {
        borderColor: "#444440",
        backgroundColor: "#FFF",
        color: "#444440",
        cursor: isDisabled || readOnly ? "default" : "pointer",
      };
    } else if (readOnly) {
      customStyle = {
        borderColor: "#6F6F6C",
        backgroundColor: "#FFF",
        color: "#6F6F6C",
        cursor: "default",
      };
    } else {
      customStyle = {
        borderColor: "#8F8F8C",
        backgroundColor: "#FFF",
        color: "#444440",
      };
    }
  } else if (variant === "icon") {
    if (isHovered) {
      customStyle = {
        color: "#1272EF",
        border: "none",
        backgroundColor: "transparent",
        _hover: {},
        _active: {
          background: "transparent",
        },
      };
    } else {
      customStyle = {
        color: "#8F8F8C",
        border: "none",
        backgroundColor: "transparent",
        _hover: {},
        _active: {
          background: "transparent",
        },
      };
    }
  }

  return {
    ...defaultStyle,
    ...customStyle,
  };
}

function isSingleSelect<T>(
  onSelect: ((value?: T) => void) | ((value?: T[]) => void),
  isMultiple: boolean
): onSelect is (value?: T) => void {
  return !isMultiple;
}

export default function AppSelect<T>({
  name,
  variant = "normal",
  title,
  value,
  idFunc = (t: T) => t,
  onSelect,
  options,
  minWidth,
  width,
  height,
  isActive = false,
  isDisabled = false,
  onClick = () => {},
  size = "medium",
  buttonRef,
  // isSearchable = false,
  isClearable = true,
  isReadOnly = false,
  isMultiple = false,
  isLiteral = false,
  popoverStrategy = "absolute",
}: AppSelectProps<T>) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [queryValue, setQueryValue] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const customStyle = {
    ...calcVariantStyle(isDisabled, isActive, isHover, isReadOnly, variant),
    ...calcSizeStyle(size),
  };

  // function handleClick(t: AppSelectOption<T>) {
  //   if (onSelect) {
  //     if (isMultiple) {
  //       //todo
  //     } else {
  //       onSelect(t.value);
  //     }
  //   }
  //   onClose();
  // }

  const calcIsActive = useCallback(
    (option: AppSelectOption<T>) => {
      if (value) {
        if (Array.isArray(value)) {
          return value.some((v) => idFunc(v) === idFunc(option.value));
        } else {
          return idFunc(value) === idFunc(option.value);
        }
      }
      return false;
    },
    [idFunc, value]
  );

  const handlePopoverClose = useCallback(() => {
    setQueryValue("");
    onClose();
  }, [onClose]);

  const handleClick = useCallback(
    (t: AppSelectOption<T>) => {
      if (onSelect) {
        if (isSingleSelect(onSelect, isMultiple)) {
          onSelect(t.value);
          handlePopoverClose();
        } else {
          const isActive = calcIsActive(t);
          if (isActive) {
            onSelect(
              (value as T[]).filter((v) => idFunc(v) !== idFunc(t.value))
            );
          } else {
            onSelect([...((value || []) as T[]), t.value]);
          }
          handlePopoverClose();
        }
      }
    },
    [onSelect, isMultiple, handlePopoverClose, calcIsActive, value, idFunc]
  );

  const filteredOptions = options.filter((option) => {
    if (queryValue.length > 0) {
      const lowerCasedQueryValue = queryValue.toLowerCase();
      return option.name.toLowerCase().includes(lowerCasedQueryValue);
    } else {
      return true;
    }
  });

  const onCustomToggle: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    if (!isDisabled && !isReadOnly) {
      onClick(event);
      if (options.length > 0) {
        onToggle();
      }
    }
  };

  function handleChangeQueryValue(e: ChangeEvent<HTMLInputElement>) {
    setQueryValue(e.target.value);
  }

  // const triggerName = useMemo(() => {
  //   if (value || value === 0) {
  //     if (Array.isArray(value)) {
  //       if (value.length > 0) {
  //         const first = value[0];
  //         const option = options.find(
  //           (option) => idFunc(option.value) === idFunc(first)
  //         );
  //
  //         if (option) {
  //           return `${option.name}${value.length > 1 ? " ,..." : ""}`;
  //         }
  //       }
  //     } else {
  //       const option = options.find(
  //         (option) => idFunc(option.value) === idFunc(value)
  //       );
  //       if (option) {
  //         return option.component || option.name;
  //       }
  //     }
  //   }
  //   return name;
  // }, [idFunc, name, options, value]);

  const triggerComponent = useMemo(() => {
    if (value || value === 0) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const first = value[0];
          const option = options.find(
            (option) => idFunc(option.value) === idFunc(first)
          );

          if (option) {
            return (
              <Text>{`${option.name}${value.length > 1 ? " ,..." : ""}`}</Text>
            );
          }
        }
      } else {
        const option = options.find(
          (option) => idFunc(option.value) === idFunc(value)
        );
        if (option) {
          return option.component || <Text>{option.name}</Text>;
        }
      }
    }
    return <Text>{name}</Text>;
  }, [idFunc, name, options, value]);

  const isSearchable = useMemo(() => {
    return options.length > 5;
  }, [options.length]);

  const [matchWidth, setMatchWidth] = useState<boolean>(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // if (name === "라인 시트") {
    //   console.dir({
    //     trigger: triggerRef.current?.clientWidth,
    //     content: contentRef.current?.clientWidth,
    //     matchWidth,
    //   });
    // }

    if (
      !matchWidth &&
      triggerRef.current &&
      contentRef.current &&
      options.length > 0 &&
      width === undefined
    ) {
      if (triggerRef.current.clientWidth >= contentRef.current.clientWidth) {
        setMatchWidth(true);
      }
    }
  }, [width, options.length, matchWidth]);

  if (isLiteral) {
    // if (React.isValidElement(value)) {
    //   return value;
    // }
    // return <Text>{AppUtils.isTrue(value) ? triggerName : ""}</Text>;
    return triggerComponent;
  }

  return (
    <Popover
      placement={"bottom-start"}
      flip={true}
      isOpen={isOpen}
      onClose={handlePopoverClose}
      initialFocusRef={searchInputRef}
      // boundary={"clippingParents"}
      strategy={popoverStrategy}
      preventOverflow={true}
      matchWidth={matchWidth}
    >
      <PopoverTrigger>
        <Button
          ref={triggerRef}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          fontSize={"12px"}
          minH={"0"}
          minWidth={minWidth}
          width={width || "auto"}
          height={height || "28px"}
          variant={"ghost"}
          onClick={onCustomToggle}
          {...customStyle}
        >
          {variant === "normal" && (
            <Flex
              width={"100%"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={"8px"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              position={"relative"}
              backgroundColor={"inherit"}
              pr={"8px"}
              height={"100%"}
            >
              {triggerComponent}
              {!isReadOnly && (
                <Box
                  position={"absolute"}
                  right={"0"}
                  backgroundColor={"inherit"}
                  paddingLeft={"4px"}
                >
                  <Icon
                    right={"0"}
                    as={SVG}
                    color={"inherit"}
                    src={"/icons/icon_polygon_1.svg"}
                    boxSize={"8px"}
                  />
                </Box>
              )}

              {value != undefined &&
                !isDisabled &&
                isClearable &&
                !isReadOnly && (
                  <Box
                    alignItems={"center"}
                    position={"absolute"}
                    right={"8px"}
                    backgroundColor={"inherit"}
                    display={isHover ? "flex" : "flex"}
                    padding={"0 6px 0 4px"}
                    minW={"0"}
                    height={"16px"}
                    _hover={{}}
                    borderRadius={"0"}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (onSelect) {
                        onSelect(undefined);
                        handlePopoverClose();
                      }
                    }}
                    aria-label={"clear"}
                  >
                    <Icon
                      right={"0"}
                      as={SVG}
                      color={isHover ? "inherit" : "#8F8F8C"}
                      src={"/icons/icon_x_letter.svg"}
                      boxSize={"10px"}
                    />
                  </Box>
                )}
            </Flex>
          )}
          {variant === "icon" && (
            <SVG
              color={"inherit"}
              src={"/icons/icon_hamburger_menu.svg"}
              width={"14px"}
              height={"14px"}
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={contentRef}
        width={variant === "icon" && width ? width : "auto"}
        overflow={"hidden"}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            event.stopPropagation();
            handlePopoverClose();
          }
        }}
      >
        <PopoverHeader background={"#F7F9FA"} fontSize={"12px"}>
          <Text>{title ? title : name}</Text>
          <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody p={"0"}>
          {isSearchable && (
            <Input
              type={"text"}
              placeholder={t("search")}
              fontSize={"12px"}
              border={"none"}
              borderRadius={"0"}
              padding={"8px"}
              height={"auto"}
              value={queryValue}
              onChange={handleChangeQueryValue}
              borderBottom={"0.5px solid #D4D4D1"}
              autoComplete={"off"}
              ref={searchInputRef}
            />
          )}
          <List
            display={"flex"}
            flexDirection={"column"}
            fontSize={"12px"}
            maxH={"172px"}
            overflowY={"auto"}
          >
            {filteredOptions.map((option) => {
              return (
                <AppSelectOption<T>
                  key={idFunc(option.value)}
                  option={option}
                  isActive={calcIsActive(option)}
                  handleClick={handleClick}
                />
              );
            })}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
