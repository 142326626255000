import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Box, Flex, Grid } from "@chakra-ui/react";
import AppProgressTrail, {
  AppProgressTrailProps,
} from "@/features/transportations/app-progress-trail";
import useGetMySelf from "@/features/auth/use-get-my-self";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { Field, FieldProps, FormikProps } from "formik";
import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";
import { toLower } from "@/utils/case";

interface TransportationDetailsFormControlProps {
  formik: FormikProps<TransportationFormFields>;
  isEditing?: boolean;
}

export default function TransportationDetailsFormControl({
  formik,
  isEditing = false,
}: TransportationDetailsFormControlProps) {
  const { t, tUpper, tTitle, tLocalDateTimeString } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const { data: getMySelf, error: getMySelfError } = useGetMySelf();

  if (isEditing) {
    return null;
  }

  return (
    <AppPanel title={tTitle("transportations_details")}>
      <Field name={"details"}>
        {(
          props: FieldProps<
            TransportationFormFields["details"],
            TransportationFormFields
          >
        ) => {
          return (
            <Flex flexDirection={"column"}>
              {props.field.value.map((detail, index) => {
                let variant: AppProgressTrailProps["variant"] = "upcoming";
                if (detail.createdAt !== null) {
                  if (
                    index < props.field.value.length - 1 &&
                    props.field.value[index + 1].createdAt !== null
                  ) {
                    variant = "completed";
                  } else {
                    variant = "current";
                  }
                }

                let statusColor;
                let messageColor;
                let timeColor;

                if (variant === "upcoming") {
                  statusColor = "#8F8F8C";
                  messageColor = "#8F8F8C";
                  timeColor = "#D4D4D1";
                } else if (variant === "current") {
                  statusColor = "#1272EF";
                  messageColor = "#22221F";
                  timeColor = "#6F6F6C";
                } else {
                  statusColor = "#6F6F6C";
                  messageColor = "#6F6F6C";
                  timeColor = "#8F8F8C";
                }

                return (
                  <Grid
                    key={index}
                    gridTemplateColumns={"210px 1fr auto"}
                    gap={"8px"}
                    fontSize={"12px"}
                    minHeight={"32px"}
                    paddingLeft={"calc(16px + 8px)"}
                    position={"relative"}
                  >
                    <Box fontWeight={"700"} color={statusColor}>
                      {tUpper(
                        `transportation.status.${toLower(detail.status)}`
                      )}
                    </Box>
                    <Box color={messageColor}>
                      {detail.createdAt
                        ? t(
                            `transportation.status.${toLower(
                              detail.status
                            )}.message`,
                            {
                              flightNumber: props.form.values.flightNumber,
                            }
                          )
                        : ""}
                    </Box>
                    <Box color={timeColor}>
                      {tLocalDateTimeString(detail.createdAt)}
                    </Box>
                    <AppProgressTrail
                      variant={variant}
                      isTailLess={props.field.value.length - 1 === index}
                    />
                  </Grid>
                );
              })}
            </Flex>
          );
        }}
      </Field>
    </AppPanel>
  );
}
