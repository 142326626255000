import { useRecoilState } from "recoil";
import {
  DumbPathParameter,
  DumbRequest,
  dumbTransactionsState,
  isIdle,
} from "@/features/ui/dumb-transaction/dumb-transaction-state";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { useEffect, useMemo, useRef, useState } from "react";
import { produce } from "immer";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import FileUploadProgress from "@/features/line-sheet-sets/file-upload-progress";

const dumbKey: YWRKey<DumbPathParameter> = {
  method: "POST", //it will be overridden
  url: ({ url }) => url,
};

const MAX_ACTIVE_TRANSACTION_COUNT = 10;

export default function AppDumbTransactionModal() {
  const [dumbTransactions, setDumbTransactions] = useRecoilState(
    dumbTransactionsState
  );
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const fetcher = useFetcher();

  const { data, fire, clear } = useYWRInfinite<
    DumbPathParameter,
    DumbRequest,
    any,
    any
  >(dumbKey, fetcher);

  useEffect(() => {
    const activeCount = dumbTransactions.filter((tx) => !isIdle(tx)).length;

    if (activeCount < MAX_ACTIVE_TRANSACTION_COUNT) {
      dumbTransactions
        .filter(isIdle)
        .slice(0, MAX_ACTIVE_TRANSACTION_COUNT - activeCount)
        .forEach((tx) => {
          fire(
            { url: tx.url },
            tx.request,
            tx.fetcher ? { fetcher: tx.fetcher } : undefined,
            {
              method: tx.method,
              headers: { "Content-Type": "multipart/form-data" },
              timeout: 60 * 60 * 1000,
            }
          );
        });
    }
  }, [fire, dumbTransactions]);

  useEffect(() => {
    //clear
    const completedTransactions = dumbTransactions.filter((tx) => {
      return (
        !tx.isLoading && (tx.response !== undefined || tx.error !== undefined)
      );
    });

    if (completedTransactions.length > 0) {
      setDumbTransactions((prev) => {
        return prev.filter((tx) => {
          return !completedTransactions.some((ct) => ct.url === tx.url);
        });
      });

      completedTransactions
        .filter((tx) => tx.error)
        .forEach((tx) => {
          showError(tx.error);
        });

      completedTransactions
        .filter((tx) => tx.response)
        .forEach((tx) => {
          tx.postProcess(tx.response);
        });

      clear(completedTransactions.map((tx) => tx.url));
    }
  }, [dumbTransactions, clear, setDumbTransactions, showError]);

  useEffect(() => {
    if (data) {
      setDumbTransactions((prev) => {
        return produce(prev, (draft) => {
          for (let i = 0; i < prev.length; i++) {
            const tx = prev[i];
            const datum = data.find((datum) => datum.key === tx.url);
            if (datum) {
              draft[i] = {
                ...draft[i],
                isLoading: datum.isLoading,
                response: datum.response,
                error: datum.error,
                progress: datum.progress,
              };
            }
          }
        });
      });
    }
  }, [data, setDumbTransactions]);

  const progressComponent = useMemo(() => {
    return dumbTransactions.flatMap((tx, txIndex) => {
      return tx.files.map((file, fileIndex) => {
        const result = tx.url.match(/[&?]_v=(\d+)/);
        let _v = "0";
        if (result !== null && result.length > 1) {
          _v = result[1];
        }

        return (
          <FileUploadProgress
            // key={`FileUploadProgress_${_v}_${fileIndex}`}
            key={`FileUploadProgress_${txIndex}_${fileIndex}`}
            file={file.file}
            isLoading={tx.isLoading}
            isError={tx.error}
            progress={tx.progress}
            isComplete={tx.response !== undefined}
          />
        );
      });
    });
  }, [dumbTransactions]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (dumbTransactions.length > 0) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    if (dumbTransactions.length > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dumbTransactions.length]);

  if (dumbTransactions.length === 0 || progressComponent.length === 0) {
    return null;
  }

  return (
    <Flex
      position={"absolute"}
      right={"4px"}
      bottom={"4px"}
      zIndex={"2"}
      borderRadius={"4px 4px 0px 0px"}
      border={"0.5px solid #8F8F8C"}
      borderBottom={"none"}
      background={"#FFF"}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.20)"}
      flexDirection={"column"}
      minW={"400px"}
    >
      <Box
        p={"12px 20px"}
        borderRadius={"4px 4px 0px 0px"}
        borderBottom={isCollapsed ? "none" : "0.5px solid #8F8F8C"}
        background={"#EFEFED"}
        position={"relative"}
      >
        <Text color={"#444440"} fontSize={"14px"}>
          Files Are Uploading
        </Text>
        <IconButton
          position={"absolute"}
          top={"4px"}
          right={"8px"}
          aria-label={"collapse"}
          background={"transparent"}
          _hover={{ background: "transparent" }}
          icon={
            <SVG
              src={
                isCollapsed
                  ? "/icons/icon_chevron_bottom.svg"
                  : "/icons/icon_chevron_right.svg"
              }
              color={"#8F8F8C"}
            />
          }
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </Box>
      <Flex
        flexDirection={"column"}
        p={dumbTransactions.length > 0 ? "16px 20px" : "0"}
        gap={"8px"}
        maxH={"160px"}
        overflowY={"auto"}
        style={{ scrollbarGutter: "stable" }}
        display={isCollapsed ? "none" : "inherit"}
      >
        {/*<FileUploadProgress*/}
        {/*  // key={`FileUploadProgress_${_v}_${fileIndex}`}*/}
        {/*  key={`test`}*/}
        {/*  file={new File([], "test.txt")}*/}
        {/*  isLoading={true}*/}
        {/*  isError={false}*/}
        {/*  isComplete={false}*/}
        {/*/>*/}
        {progressComponent}
      </Flex>
    </Flex>
  );
}
