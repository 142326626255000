import useListDenseProformaInvoicesFetcher from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices-fetcher";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import {
  ListAllProformaInvoicesPathParameter,
  ListAllProformaInvoicesRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";

export const listAllDenseProformaInvoicesTemplateUrl = `/:by/:companyId/dense-proforma-invoices`;

export function getListAllDenseProformaInvoicesKey(
  parameter?: ListAllProformaInvoicesPathParameter,
  request?: ListAllProformaInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllDenseProformaInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListAllDenseProformaInvoices(
  parameter?: ListAllProformaInvoicesPathParameter,
  request?: ListAllProformaInvoicesRequest,
  config?: SWRConfiguration
) {
  const listDenseProformaInvoicesFetcher =
    useListDenseProformaInvoicesFetcher();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      listDenseProformaInvoicesFetcher,
      (response) => response.proformaInvoices
    );
  }, [listDenseProformaInvoicesFetcher]);

  return useSWR(
    getListAllDenseProformaInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
