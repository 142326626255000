import { toCamelKeyObject, toSnake } from "@/utils/case";
import { YWRKey } from "@/features/ui/hooks/use-ywr";
import {
  IdentityParameter,
  isCompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
  PreSignedStoredObject,
} from "@/features/types";
import { pluralizeCompanyType } from "@/features/ui/helpers/identity-helpers";
import { AxiosRequestConfig } from "axios";
import { uniqueId } from "lodash";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

function createMojoLink(url: string) {
  const element = document.createElement("a");
  element.style.display = "none";
  element.href = url;
  element.id = uniqueId("mojo-link_");
  element.download = "";
  element.target = "_blank";
  document.body.appendChild(element);
  return element;
}

export function withPreSignedFetcher<
  Request,
  Response extends PreSignedStoredObject
>(fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>) {
  return async (config: AxiosRequestConfig<Request>) => {
    return fetcher(config).then((r) => {
      const element = createMojoLink(r.url);

      setTimeout(() => {
        element.click();
      }, 500);

      setTimeout(() => {
        element.remove();
      }, 5 * 1000);

      return r;
    });
  };
}

export interface SWRObjectKey<T> {
  url: string;
  params?: T;
}

export interface VerboseResponse<Parameter, Request, Response> {
  parameter?: Parameter;
  request?: Request;
  response: Response;
}

export function withVerboseFetcher<Parameter, Request, Response>(
  fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>,
  parseKey: (key: SWRObjectKey<Request>) => {
    parameter: Parameter;
    request: Request;
  }
) {
  return async (
    key: SWRObjectKey<Request>
  ): Promise<VerboseResponse<Parameter, Request, Response>> => {
    const source = await fetcher(key);
    const { parameter, request } = parseKey(key);
    return Promise.resolve({
      parameter,
      request,
      response: source,
    });
  };
}

export function withListAllFetcher<
  Request extends PageNumberBasedListRequest,
  Response extends PageNumberBasedListResponse,
  Resource
>(
  fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>,
  func: (response: Response) => Resource[]
) {
  return async (
    config: AxiosRequestConfig<Omit<Request, "pageSize" | "pageNumber">>
  ): Promise<Resource[]> => {
    const { url, params } = config;
    const pageSize = 100;

    const first = await fetcher({
      url,
      params: {
        ...params,
        pageNumber: 1,
        pageSize,
      },
    });

    const totalCount = first.totalCount;
    const promises: Promise<Response>[] = [];

    if (totalCount > func(first).length) {
      const pageCount = Math.ceil(totalCount / pageSize);
      for (let i = 2; i <= pageCount; i++) {
        promises.push(
          fetcher({
            url,
            params: {
              ...params,
              pageNumber: i,
              pageSize,
            },
          })
        );
      }
    }

    const data = await Promise.all(promises);

    return [first, ...data].flatMap((r) => {
      return func(r);
    });

    // return Promise.resolve(
    //   [first, ...data].flatMap((r) => {
    //     return func(r);
    //   })
    // );
  };
}

export function calcIdentityUrlPrefix(parameter: IdentityParameter) {
  return `/${pluralizeCompanyType(parameter.by).toLowerCase()}/${
    parameter.companyId
  }`;
}

export function getIdentityUrlPrefixTemplate() {
  return `/:companyType/:companyId`;
}

export function calcUrl(
  templateUrl: string,
  parameter: { [key: string]: any }
) {
  let url = templateUrl;
  const searchParams = new URLSearchParams();
  Object.keys(parameter).forEach((key) => {
    let value = parameter[key];
    if ((key === "by" || key === "targetBy") && isCompanyType(value)) {
      value = pluralizeCompanyType(value).toLowerCase();
    } else {
      value = StringTransformer.stringifyAny(value);
    }

    if (url.includes(`:${key}`)) {
      url = url.replace(`:${key}`, value);
    } else if (value) {
      const snakeKey = toSnake(key);
      const serializedValue = StringTransformer.stringifyAny(value);
      if (serializedValue) {
        if (Array.isArray(serializedValue)) {
          serializedValue.forEach((item) => {
            searchParams.append(key, item);
          });
        } else {
          searchParams.append(snakeKey, serializedValue);
        }
      }
    }
  });

  if (searchParams.toString()) {
    url += "?" + searchParams.toString();
  }
  return url;
}

export function calcYWRUrl<T>(key: YWRKey<T>, parameter: T) {
  return typeof key.url === "string" ? key.url : key.url(parameter);
}

export function parseYWRUrl(templateUrl: string, actualUrl: string) {
  let templatePathName = templateUrl;
  let actualPathName = actualUrl;
  let obj: { [key: string]: string } = {};

  const i = templatePathName.lastIndexOf("?");
  if (i > -1) {
    templatePathName = templatePathName.substring(0, i);
  }

  const j = actualUrl.lastIndexOf("?");
  if (j > -1) {
    actualPathName = actualUrl.substring(0, j);
    const rawSearchParams = actualUrl.substring(j + 1);
    const searchParams = new URLSearchParams(rawSearchParams);

    // @ts-ignore
    [...searchParams.entries()].forEach((e, i) => {
      const [key, value] = e;
      obj[key] = value;
    });
    obj = toCamelKeyObject(obj);
  }

  const templateItems = templatePathName.split("/");
  const actualItems = actualPathName.split("/");
  for (let i = 0; i < Math.min(templateItems.length, actualItems.length); i++) {
    const key = templateItems[i];
    const value = actualItems[i];
    if (key.startsWith(":")) {
      obj[key.substring(1)] = value;
    }
  }
  return obj;
}

export function createYWRAxiosConfig<PARAMETER, REQUEST>(
  key: YWRKey<PARAMETER>,
  parameter: PARAMETER,
  request: REQUEST
): AxiosRequestConfig<REQUEST> {
  const { url: urlOrFunc, method } = key;
  return {
    url: typeof urlOrFunc === "string" ? urlOrFunc : urlOrFunc(parameter),
    method,
    data: request,
  };
}
