import useSWR, { SWRConfiguration } from "swr";
import {
  ListLineSheetSetsRequest,
  ListLineSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import useFetcher from "@/utils/use-fetcher";
import {
  ListOrderSheetSetsRequest,
  ListOrderSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";
import { AxiosRequestConfig } from "axios";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import {
  LightLineSheetSetPair,
  LineSheetSetPair,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { getUrlPrefixByIdentityParameter } from "@/features/ui/helpers/zwr-helpers";
import { isEqual } from "lodash";

export interface ListLineSheetSetPairsKey {
  url: string;
  params: ListLineSheetSetPairsRequest;
}

export interface ListLineSheetSetPairsResponse
  extends PageNumberBasedListResponse {
  pairs: LightLineSheetSetPair[];
}

export interface ListLineSheetSetPairsRequest
  extends ListLineSheetSetsRequest {}

function withFetcher(
  listLineSheetSetsFetcher: (
    config: AxiosRequestConfig<ListLineSheetSetsRequest>
  ) => Promise<ListLineSheetSetsResponse>,
  listOrderSheetSetsFetcher: (
    config: AxiosRequestConfig<ListOrderSheetSetsRequest>
  ) => Promise<ListOrderSheetSetsResponse>
) {
  function bypass(
    source: Promise<ListLineSheetSetsResponse> | ListLineSheetSetsResponse
  ): Promise<ListLineSheetSetPairsResponse> {
    if ("then" in source) {
      return source.then((p) => bypass(p));
    } else {
      return Promise.resolve({
        totalCount: source.totalCount,
        pairs: source.lineSheetSets.map((s) => ({
          lineSheetSet: s,
          orderSheetSet: null,
        })),
      });
    }
  }

  return async (
    key: ListLineSheetSetPairsKey
  ): Promise<ListLineSheetSetPairsResponse> => {
    const { url: virtualUrl, params } = key;
    const listLineSheetSetPromise = listLineSheetSetsFetcher({
      url: virtualUrl.replace("line-sheet-set-pairs", "line-sheet-sets"),
      params: params,
    });

    const isByBuyer = virtualUrl.startsWith("buyers");

    if (!isByBuyer) {
      return bypass(listLineSheetSetPromise);
    }

    const listLineSheetSetsResponse = await listLineSheetSetPromise;

    if (listLineSheetSetsResponse.lineSheetSets.length === 0) {
      return bypass(listLineSheetSetsResponse);
    }

    const lineSheetSetIds = listLineSheetSetsResponse.lineSheetSets.map(
      (l) => l.id
    );
    const listOrderSheetSetsRequest: ListOrderSheetSetsRequest = {
      pageSize: lineSheetSetIds.length,
      pageNumber: 1,
      "lineSheetSet.id__in": lineSheetSetIds,
    };

    const listOrderSheetSetsResponse = await listOrderSheetSetsFetcher({
      url: virtualUrl.replace("line-sheet-set-pairs", "order-sheet-sets"),
      params: listOrderSheetSetsRequest,
    });

    if (listOrderSheetSetsResponse.orderSheetSets.length === 0) {
      return bypass(listLineSheetSetsResponse);
    } else {
      const pairs = listLineSheetSetsResponse.lineSheetSets.map((l) => {
        return {
          lineSheetSet: l,
          orderSheetSet:
            listOrderSheetSetsResponse.orderSheetSets.find(
              (o) => o.lineSheetSet.id === l.id
            ) || null,
        };
      });
      return Promise.resolve({
        totalCount: listLineSheetSetsResponse.totalCount,
        pairs: pairs,
      });
    }
  };
}

export interface ListLineSheetSetPairsPathParameter extends IdentityParameter {}

export function getListLineSheetSetPairsPathParameter(
  identity: Identity | null
) {
  return toIdentityParameter(identity);
}

export function getListLineSheetSetPairsKey(
  identityOrParameter: Identity | ListLineSheetSetPairsPathParameter | null,
  request: ListLineSheetSetPairsRequest
): ListLineSheetSetPairsKey | null {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListLineSheetSetPairsPathParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    const prefix = getUrlPrefixByIdentityParameter(parameter);
    return {
      url: `${prefix}/line-sheet-set-pairs`,
      params: request,
    };
  }
  return null;
}

export function isListLineSheetSetPairKey(
  value: any
): value is ListLineSheetSetPairsKey {
  if (typeof value === "object") {
    const candidate = value as ListLineSheetSetPairsKey;
    if (candidate.url !== undefined) {
      return candidate.url.indexOf("/line-sheet-set-pairs") > -1;
    }
  }
  return false;
}

export function isListLineSheetSetPairKeysEqual(
  left: ListLineSheetSetPairsKey,
  right: ListLineSheetSetPairsKey
) {
  return isEqual(left, right);
}

export const DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE = 10;

export default function useListLineSheetSetPairs(
  parameter: ListLineSheetSetPairsPathParameter | null,
  request: ListLineSheetSetPairsRequest,
  config: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }
) {
  const listLineSheetSetsFetcher = useFetcher<
    ListLineSheetSetsRequest,
    ListLineSheetSetsResponse
  >();
  const listOrderSheetSetsFetcher = useFetcher<
    ListOrderSheetSetsRequest,
    ListOrderSheetSetsResponse
  >();

  return useSWR(
    getListLineSheetSetPairsKey(parameter, request),
    withFetcher(listLineSheetSetsFetcher, listOrderSheetSetsFetcher),
    config
  );
}
