import {
  reparseLineSheetKey,
  ReparseLineSheetRequest,
  ReparseLineSheetResponse,
} from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";

export default function useReparseLineSheetInfinite() {
  const fetcher = useFetcher<
    ReparseLineSheetRequest,
    ReparseLineSheetResponse
  >();

  return useYWRInfinite(reparseLineSheetKey, fetcher);
}
