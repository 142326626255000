import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import {
  createTransportationKey,
  CreateTransportationPathParameter,
  CreateTransportationRequest,
} from "@/features/transportations/hooks/use-create-transportation";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useCreateTransportationInfinite from "@/features/transportations/hooks/use-create-transportation-infinite";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import { useCallback } from "react";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import useUpdateTransportation, {
  updateTransportationKey,
  UpdateTransportationParameter,
  UpdateTransportationRequest,
} from "@/features/transportations/hooks/use-update-transportation";
import { isFile, StoredObject } from "@/features/types";

function mutateTransportations(transactionId?: number) {
  if (transactionId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers)\/\d+\/transportations\/${transactionId}/`
      )
    );
  }

  mutateIfMatched(/\/(agencies|buyers)\/\d+\/transportations/);
}

export function useTransportationDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { fire: fireCreateTransportation } = useCreateTransportationInfinite();
  const { fire: fireUpdateTransportation } = useUpdateTransportation();
  const addCreateTransportation = useCallback(
    (
      parameter: CreateTransportationPathParameter,
      request: Omit<CreateTransportationRequest, "files"> & {
        files: File[];
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (source) => source.files,
        convertFunc: (source, storeObjectResponses) => ({
          ...source,
          files: storeObjectResponses,
        }),
        keyOrFunc: (parameter) => {
          return `${calcYWRUrl(
            createTransportationKey,
            parameter
          )}?v=${new Date().getTime()}`;
        },
        fire: fireCreateTransportation,
        onErrorFunc: showError,
        onPostFunc: () => {
          mutateTransportations();
        },
      });
    },
    [addDumbTransactions, fireCreateTransportation, showError]
  );

  const addUpdateTransportation = useCallback(
    (
      parameter: UpdateTransportationParameter,
      request: Omit<UpdateTransportationRequest, "files"> & {
        files: (File | Pick<StoredObject, "id" | "name">)[];
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (source) => source.files.filter(isFile),
        convertFunc: (source, storeObjectResponses) => {
          let i = 0;
          return {
            ...source,
            files: source.files.map((file) => {
              if (isFile(file)) {
                return storeObjectResponses[i++];
              }
              return file;
            }),
          };
        },
        keyOrFunc: calcYWRUrl(updateTransportationKey, parameter),
        fire: fireUpdateTransportation,
        onErrorFunc: showError,
        onPostFunc: () => {
          mutateTransportations(parameter.transportationId);
        },
      });
    },
    [addDumbTransactions, fireUpdateTransportation, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateTransportationParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateTransportationKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate: addCreateTransportation,
    addUpdate: addUpdateTransportation,
    isUpdateQueued: isUpdateQueued,
  };
}
