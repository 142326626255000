import { useRecoilCallback } from "recoil";
import { DenseLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  buyerLineSheetSetRowsMutateId,
  buyerLineSheetSetRowsState,
} from "@/features/order-sheet-sets/recoils/buyer-line-sheet-set-rows-state";
import { createBuyerLineSheetSetRows } from "@/features/order-sheet-sets/helpers/buyer-line-sheet-set-row-helper";
import { BuyerLineSheetSetRow } from "@/features/order-sheet-sets/line-sheet-set-row.type";
import { ReadLineSheetSetResponse } from "@/features/line-sheet-sets/hooks/use-read-line-sheet-set";
import { gridRowsUpdater } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

export default function useBuyerLineSheetSetRows() {
  const setRows = useRecoilCallback(
    ({ set, snapshot }) =>
      (lineSheetSets?: DenseLineSheetSet[]) => {
        set(buyerLineSheetSetRowsState, (prev) => {
          return createBuyerLineSheetSetRows(lineSheetSets, prev);
        });
      },
    []
  );

  const mutateRows = useRecoilCallback(
    ({ set }) =>
      () => {
        set(buyerLineSheetSetRowsMutateId, (prev) => {
          return prev + 1;
        });
      },
    []
  );

  //근데 이렇게 하니까 swr에는 isRead:false라서 새로 들어오면 일단 stale보여줘서 색이 바뀐당;
  //swr꺼를 바꿔야 하나봐... 그래서 eagerRead를 만들었음
  // const mutateRowsByReadLineSheetSetResponses = useRecoilCallback(
  //   ({ set }) =>
  //     (responses: ReadLineSheetSetResponse[]) => {
  //       set(buyerLineSheetSetRowsState, (prev) => {
  //         return gridRowsUpdater(
  //           prev,
  //           responses,
  //           (response) => {
  //             return (row) => {
  //               return (
  //                 row.id === response.id && row._rowType === "LineSheetSet"
  //               );
  //             };
  //           },
  //           (response) => {
  //             return (row) => {
  //               return {
  //                 ...row,
  //                 isRead: true,
  //               };
  //             };
  //           }
  //         );
  //       });
  //     },
  //   []
  // );

  return {
    setRows,
    mutateRows,
    // mutateRowsByReadLineSheetSetResponses,
  };
}
