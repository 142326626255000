import { Company, CompanyType } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

interface CreateCompanyRequest {
  name: string;
}

interface CreateCompanyResponse extends Company {}

interface CreateCompanyPathParameter {
  by: CompanyType;
  companyId: number;
  targetBy: CompanyType;
}

export const createCompanyTemplateUrl = `/:by/:companyId/:targetBy`;

export const createCompanyKey: YWRKey<CreateCompanyPathParameter> = {
  url: (parameters: CreateCompanyPathParameter) => {
    return calcUrl(createCompanyTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateCompany() {
  const fetcher = useFetcher<CreateCompanyRequest, CreateCompanyResponse>();
  return useYWR(createCompanyKey, fetcher);
}
