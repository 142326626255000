import useFetcher from "@/utils/use-fetcher";
import {
  getListOrderSheetSetsKey,
  ListOrderSheetSetsRequest,
  ListOrderSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";
import {
  getListLineSheetSetsKey,
  ListLineSheetSetsRequest,
  ListLineSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDenseOrderSheetSetsRequest,
  ListDenseOrderSheetSetsResponse,
  parseListDenseOrderSheetSetsKey,
} from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import { uniq } from "lodash";
import { DenseOrderSheet } from "@/features/order-sheets/order-sheet.type";
import { OrderByItem } from "@/features/types";

export default function useListDenseOrderSheetSetsFetcher() {
  const listOrderSheetSetsFetcher = useFetcher<
    ListOrderSheetSetsRequest,
    ListOrderSheetSetsResponse
  >();

  const listLineSheetSetsFetcher = useFetcher<
    ListLineSheetSetsRequest,
    ListLineSheetSetsResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseOrderSheetSetsRequest>
    ): Promise<ListDenseOrderSheetSetsResponse> => {
      const { parameter, request } = parseListDenseOrderSheetSetsKey(config);
      const listOrderSheetSetsResponse = await listOrderSheetSetsFetcher(
        getListOrderSheetSetsKey(parameter, request)!
      );

      if (listOrderSheetSetsResponse.orderSheetSets.length > 0) {
        const lineSheetSetIds = uniq(
          listOrderSheetSetsResponse.orderSheetSets.map(
            (i) => i.lineSheetSet.id
          )
        );

        const listLineSheetSetsResponse = await listLineSheetSetsFetcher(
          getListLineSheetSetsKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
            },
            {
              id__in: lineSheetSetIds,
              pageNumber: 1,
              pageSize: lineSheetSetIds.length,
              orderBy: [new OrderByItem("createdAt", "descend")],
            }
          )!
        );

        return Promise.resolve({
          totalCount: listOrderSheetSetsResponse.totalCount,
          orderSheetSets: listOrderSheetSetsResponse.orderSheetSets.map(
            (orderSheetSet) => {
              const lineSheetSet = listLineSheetSetsResponse.lineSheetSets.find(
                (j) => j.id === orderSheetSet.lineSheetSet.id
              )!!;
              const { orderSheets: _orderSheets, ...rest } = orderSheetSet;

              const orderSheets = _orderSheets.map(
                (orderSheet): DenseOrderSheet => {
                  const { lineSheet: _lineSheet, ...rest } = orderSheet;

                  const lineSheet = lineSheetSet.lineSheets.find(
                    (lineSheet) => {
                      return lineSheet.id === orderSheet.lineSheet.id;
                    }
                  )!!;

                  const orderSheetTabs = orderSheet.orderSheetTabs.map(
                    (orderSheetTab) => {
                      const lineSheetTab = lineSheet.lineSheetTabs.find(
                        (i) => i.index === orderSheetTab.index
                      )!!;
                      return {
                        ...orderSheetTab,
                        name: lineSheetTab.name,
                      };
                    }
                  );

                  return {
                    ...rest,
                    orderSheetTabs,
                    lineSheet: lineSheet,
                  };
                }
              );

              return {
                ...rest,
                orderSheets: orderSheets,
                lineSheetSet: lineSheetSet,
              };
            }
          ),
        });
      } else {
        return Promise.resolve({
          totalCount: 0,
          orderSheetSets: [],
        });
      }
    };
  }, [listOrderSheetSetsFetcher, listLineSheetSetsFetcher]);
}
