import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import { Field, FieldProps, FormikHelpers, FormikProps } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { toTitle } from "@/utils/case";
import AppRemittanceStatusLabel from "@/features/ui/app-remittance-status-label";
import AppInput from "@/features/ui/app-input";
import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import AppInvoiceStatusSelect from "@/features/invoices/order-confirmation-invoices/app-invoice-status-select";
import AppSeasonSelect from "@/features/line-sheet-sets/app-season-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppTextArea from "@/features/ui/app-text-area";
import CreateCompanyModal, {
  CreateCompanyModalProps,
} from "@/features/ui/create-company-modal";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useIdentity from "@/features/ui/hooks/use-identity";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { Company } from "@/features/types";
import AppInvoiceStatusLabel from "@/features/ui/app-invoice-status-label";
import InvoiceReceiverSelect from "@/features/invoices/invoice-receiver-select";

interface PaymentInformationFormControlProps {
  formik: FormikProps<FormFields>;
  isEditing: boolean;
  isDisabled: boolean;
}

interface FormFields extends PaymentFormFields {}

export default function PaymentInformationFormControl({
  formik,
  isEditing,
  isDisabled,
}: PaymentInformationFormControlProps) {
  const { tTitle, t } = useI18nHelper();
  const identity = useIdentity();

  type LINK_AFFECTING_KEYS =
    | "season"
    | "issuedBy"
    | "orderedBy"
    | "issuedThrough"
    | "sender"
    | "receiver";

  const handleLinkAffectingFieldChange = useCallback(
    <K extends keyof FormFields & LINK_AFFECTING_KEYS>(
      key: LINK_AFFECTING_KEYS,
      value: FormFields[K],
      setValues: FormikHelpers<FormFields>["setValues"]
    ) => {
      return setValues((prev) => {
        let next = {
          ...prev,
          [key]: value,
        };

        if (key === "orderedBy") {
          //updateSenderByOrderedBy(next);
        } else if (key === "issuedBy" || key === "issuedThrough") {
          //updateReceiverByIssuedThroughOrIssuedBy(next);
        } else if (key === "sender") {
          if (next.sender?.type === "AGENCY") {
            // next.receiver = next.issuedBy
            //   ? {
            //       ...next.issuedBy,
            //       type: "BOUTIQUE",
            //     }
            //   : undefined;
          }
        } else if (key === "receiver") {
          if (next.receiver?.type === "AGENCY") {
            next.sender = next.orderedBy
              ? {
                  ...next.orderedBy,
                  type: "BUYER",
                }
              : undefined;
          }
        }

        if (next.amount) {
          next.amount.currency =
            next.receiver?.type === "BOUTIQUE" ? "EUR" : "KRW";
        }

        next.orderConfirmationInvoices = [];
        next.proformaInvoices = [];

        return next;
      });
    },
    []
  );

  const {
    isOpen: isCreateCompanyModelOpen,
    onClose: onCreateCompanyModalClose,
    data: createCompanyModalData,
    openWithData: openCreateCompanyModal,
  } = useDisclosureWithData<CreateCompanyModalProps>();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          rowGap={"8px"}
          gridTemplateRows={"repeat(7, 16px 24px) repeat(2, 16px 60px)"}
        >
          {(Array(18).fill(0) as number[]).map((_, index) => {
            return <Skeleton key={index} />;
          })}
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:payment_information.abbr")}>
      <Grid rowGap={"8px"}>
        {!isEditing && (
          <>
            <Field name={"status"}>
              {(props: FieldProps<FormFields["status"], FormFields>) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("status"))}
                    </FormLabel>
                    <Spacer />
                    <AppInvoiceStatusLabel value={props.field.value} />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"isRemitted"}>
              {(props: FieldProps<FormFields["isRemitted"], FormFields>) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("remittance_status"))}
                    </FormLabel>
                    <Spacer />
                    <AppRemittanceStatusLabel
                      value={
                        props.field.value !== undefined
                          ? props.field.value
                            ? "REMITTED"
                            : "UNREMITTED"
                          : undefined
                      }
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Box
              height={"8px"}
              borderBottom={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
            ></Box>
          </>
        )}

        {formik.values.id !== -1 && (
          <Field name={"name"}>
            {(props: FieldProps<FormFields["name"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("title"))}
                  </FormLabel>
                  <AppInput
                    value={props.field.value}
                    isReadOnly={true}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "BOUTIQUE" && isEditing && (
          <Field name={"status"}>
            {(props: FieldProps<FormFields["status"], FormFields>) => {
              let excludeStatuses: AbstractInvoice["status"][] = [];
              if (props.form.values.id === -1) {
                excludeStatuses = ["COMPLETED", "REPLACED"];
              } else {
                excludeStatuses = ["REPLACED"];
              }

              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("status"))}
                  </FormLabel>
                  <AppInvoiceStatusSelect
                    value={props.field.value}
                    isReadOnly={!isEditing}
                    excludeStatuses={excludeStatuses}
                    onSelect={(value) => {
                      props.form.setValues((prev) => {
                        return {
                          ...prev,
                          status: value,
                        };
                      });
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        <Field name={"season"}>
          {(props: FieldProps<FormFields["season"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("season"))}
                </FormLabel>
                <AppSeasonSelect
                  width={"100%"}
                  value={props.field.value}
                  isDisabled={isDisabled}
                  readonly={!isEditing}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "season",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type !== "BUYER" && (
          <Field name={"orderedBy"}>
            {(props: FieldProps<FormFields["orderedBy"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("ordered_by"))}
                  </FormLabel>
                  <AppCompanySelect
                    name={tTitle("ordered_by")}
                    types={["BUYER"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    isReadOnly={!isEditing}
                    isDisabled={isDisabled}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "orderedBy",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "AGENCY" &&
          identity?.company?.type !== "BOUTIQUE" && (
            <Field name={"issuedThrough"}>
              {(props: FieldProps<FormFields["issuedThrough"], FormFields>) => {
                return (
                  <Flex flexDirection={"row"} alignItems={"end"} gap={"8px"}>
                    <FormControl
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"4px"}
                      isRequired={isEditing}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        color={"#6F6F6C"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {toTitle(t("issued_through"))}
                      </FormLabel>

                      <AppCompanySelect
                        name={tTitle("issued_through")}
                        types={["AGENCY"]}
                        width={"100%"}
                        id={props.field.value?.id}
                        isReadOnly={!isEditing}
                        isDisabled={isDisabled}
                        onSelect={(value) => {
                          handleLinkAffectingFieldChange(
                            "issuedThrough",
                            value,
                            props.form.setValues
                          );
                        }}
                      />
                    </FormControl>
                    {isEditing && (
                      <AppButton
                        onClick={() => {
                          openCreateCompanyModal(() => {
                            return {
                              targetBy: "AGENCY",
                              onCreate: (value) => {
                                handleLinkAffectingFieldChange(
                                  "issuedThrough",
                                  value,
                                  props.form.setValues
                                );
                              },
                            };
                          });
                        }}
                      >
                        {tTitle("add")}
                      </AppButton>
                    )}
                  </Flex>
                );
              }}
            </Field>
          )}

        <Field name={"sender"}>
          {(props: FieldProps<FormFields["sender"], FormFields>) => {
            const options: AppSelectOption<Company>[] = [];
            //송금하는 주체, 에 or 바
            if (props.form.values.issuedThrough) {
              if (props.form.values.receiver?.type !== "AGENCY") {
                options.push({
                  name: props.form.values.issuedThrough.name,
                  value: {
                    ...props.form.values.issuedThrough,
                    type: "AGENCY",
                  },
                });
              }
            }

            if (props.form.values.orderedBy) {
              options.push({
                name: props.form.values.orderedBy.name,
                value: {
                  ...props.form.values.orderedBy,
                  type: "BUYER",
                },
              });
            }

            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("sender"))}
                </FormLabel>
                <AppSelect<Company>
                  name={tTitle("sender")}
                  options={options}
                  isMultiple={false}
                  value={props.field.value}
                  idFunc={(option) => `${option.type}_${option.id}`}
                  isClearable={false}
                  isDisabled={isDisabled}
                  isReadOnly={!isEditing}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "sender",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"receiver"}>
          {(props: FieldProps<FormFields["receiver"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("receiver"))}
                </FormLabel>
                <InvoiceReceiverSelect
                  value={props.field.value}
                  isEditing={isEditing}
                  isDisabled={isEditing && props.form.values.id !== -1}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "receiver",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"publicComment"}>
          {(props: FieldProps<FormFields["publicComment"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {identity?.company?.type === "AGENCY"
                    ? `${toTitle(t("public_comment"))}(${t(
                        "public_comment.note"
                      )})`
                    : `${toTitle(t("comment"))}`}
                </FormLabel>
                <AppTextArea
                  flexGrow={"1"}
                  fontSize={"12px"}
                  rows={4}
                  placeholder={isEditing ? t("comment_placeholder") : undefined}
                  value={props.field.value || ""}
                  onChange={(e) => {
                    props.form.setFieldValue("publicComment", e.target.value);
                  }}
                  isReadOnly={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type === "AGENCY" && (
          <Field name={"privateComment"}>
            {(props: FieldProps<FormFields["privateComment"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    marginBottom={"4px"}
                  >
                    {toTitle(t("private_comment"))}
                  </FormLabel>
                  <AppTextArea
                    flexGrow={"1"}
                    minHeight={"0"}
                    fontSize={"12px"}
                    rows={4}
                    placeholder={
                      isEditing ? t("comment_placeholder") : undefined
                    }
                    value={props.field.value || ""}
                    onChange={(e) => {
                      props.form.setFieldValue(
                        "privateComment",
                        e.target.value
                      );
                    }}
                    isReadOnly={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}
      </Grid>
      {isCreateCompanyModelOpen && createCompanyModalData && (
        <CreateCompanyModal
          isOpen={isCreateCompanyModelOpen}
          onClose={onCreateCompanyModalClose}
          {...createCompanyModalData}
        />
      )}
    </AppPanel>
  );
}
