import { useTranslation } from "react-i18next";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router";

const options: AppSelectOption<string>[] = [
  {
    value: "en",
    name: "English",
  },
  {
    value: "ko",
    name: "한국어",
  },
  // {
  //   value: "it",
  //   name: "Italiano",
  // },
];

export function isSupportedLanguage(code: string) {
  return options.map((option) => option.value).includes(code);
}

function getCurrentLanguageCode(pathname: string) {
  const first = pathname.split("/")[0];
  if (options.map((option) => option.value).includes(first)) {
    return first;
  } else {
    return "en";
  }
}

export default function AppLanguageSelector() {
  const { locale } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <AppSelect<string>
      name={t("language")}
      options={options}
      width={"88px"}
      value={i18n.resolvedLanguage}
      isClearable={false}
      onSelect={async (value) => {
        if (value) {
          const parameters = location.pathname
            .split("/")
            .map((p) => p.trim())
            .filter((p) => p !== "");

          const candidates = options
            .map((option) => option.value)
            .filter((value) => value !== "en");

          if (candidates.includes(parameters[0])) {
            parameters.shift();
          }

          await i18n.changeLanguage(value);

          if (value !== "en") {
            parameters.unshift(value);
          }

          const next = "/" + parameters.join("/") + location.search;

          navigate(next, { replace: true });
        }
      }}
    />
  );
}
