import { useReducer } from "react";
import { toCamelKeyObject } from "@/utils/case";
import { AxiosRequestConfig, Method } from "axios";
import { boolean } from "yup";

interface ZWRResponse<RequestData = any, ResponseData = any, Error = any> {
  data: ResponseData | undefined;
  error: Error | undefined;
  isLoading: boolean;
  clear: () => void;
  fire: (
    param?: any[],
    r?: RequestData,
    rethrow?: boolean
  ) => Promise<ResponseData>;
}

type Action<Data = any, Error = any> =
  | { type: "start" }
  | { type: "success"; data: Data }
  | { type: "error"; error: Error }
  | { type: "clear" };

function reducer<R = any, Data = any>(
  prevState: ZWRResponse<R, Data>,
  action: Action
): ZWRResponse<R> {
  switch (action.type) {
    case "start":
      return {
        ...prevState,
        isLoading: true,
        data: undefined,
        error: undefined,
      };
    case "success":
      return {
        ...prevState,
        isLoading: false,
        data: action.data,
        error: undefined,
      };
    case "error":
      return {
        ...prevState,
        isLoading: false,
        data: undefined,
        error: action.error,
      };
    case "clear":
      return {
        ...prevState,
        isLoading: false,
        data: undefined,
        error: undefined,
      };
  }
}

export type ZWRKey = {
  url: string | ((param: any) => string);
  method: Method;
};
export default function useZWR<Request, Response>(
  key: ZWRKey | null,
  fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>,
  config?: AxiosRequestConfig<Request>
): ZWRResponse<Request, Response> {
  const [response, dispatch] = useReducer(reducer<Request, Response>, {
    data: undefined,
    error: undefined,
    isLoading: false,
    clear: () => {
      dispatch({ type: "clear" });
    },
    fire: (
      param?: any[],
      request?: Request,
      rethrow: boolean = false
    ): Promise<Response | null> => {
      if (key === null) {
        const error = {
          message: "I've not decided how to handle the case of null key",
        };

        dispatch({
          type: "error",
          error: error,
        });

        return Promise.reject(error);
      }

      const { url, method } = key;

      if (!response.isLoading) {
        dispatch({ type: "start" });
        let actualUrl: string;

        if (typeof url === "string") {
          actualUrl = url;
        } else {
          actualUrl = url(param);
        }

        const actualConfig = { url: actualUrl, method, ...config };
        if (actualConfig.method === "get") {
          actualConfig.params = request;
        } else {
          actualConfig.data = request;
        }

        return fetcher(actualConfig)
          .then((r) => {
            dispatch({ type: "success", data: r });
            return r;
          })
          .catch((error) => {
            dispatch({ type: "error", error: error });
            if (rethrow) {
              throw error;
            }
            return Promise.resolve(null);
          });
      }

      const error = {
        message: "fire is already running",
      };

      return Promise.reject(error);
    },
  });

  return response;
}
