import { Field, FieldProps, FormikProps } from "formik";
import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Box, Grid, Skeleton } from "@chakra-ui/react";
import React, { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPriceInput, {
  PriceInputValue,
} from "@/features/invoices/app-price-input";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import { Price } from "@/features/types";
import { MathUtils } from "@/features/ui/utils/math-utils";
import { InvoiceUtils } from "@/features/invoices/invoice-utils";
import PriceUtils from "@/features/ui/utils/price-utils";

interface PaymentFigureFormControlProps {
  formik: FormikProps<PaymentFormFields>;
  isEditing: boolean;
}

export default function PaymentFigureFormControl({
  formik,
  isEditing,
}: PaymentFigureFormControlProps) {
  const { tTitle, tUpper } = useI18nHelper();

  const currency = useMemo((): Price["currency"] => {
    return InvoiceUtils.getCurrencyByParties({
      sender: formik.values.sender,
      receiver: formik.values.receiver,
    });
  }, [formik.values.sender, formik.values.receiver]);

  const fromSummary = useMemo((): {
    remittedAmount: PriceInputValue;
    unremittedAmount: PriceInputValue;
    requiredAmount: PriceInputValue;
  } => {
    const requiredAmount = PriceUtils.sum(
      [
        ...[
          ...formik.values.orderConfirmationInvoices,
          ...formik.values.proformaInvoices,
        ].map((invoice) => invoice.totalRequiredAmount),
      ],
      "EUR"
    );

    const to = {
      remittedAmount: formik.values.remittedAmount,
      unremittedAmount: formik.values.unremittedAmount,
      requiredAmount: formik.values.amount,
    };

    const from = {
      remittedAmount: {
        value:
          (to.requiredAmount?.value || 0) > 0
            ? MathUtils.trim(
                (requiredAmount?.value || 0) *
                  ((to.remittedAmount?.value || 0) /
                    (to.requiredAmount?.value || 0))
              )
            : 0,
        currency: requiredAmount.currency,
      },
      unremittedAmount: {
        value: 0,
        currency: requiredAmount.currency,
      },
      requiredAmount,
    };

    from.unremittedAmount.value = MathUtils.trim(
      (from.requiredAmount?.value || 0) - from.remittedAmount.value
    );

    return from;
  }, [
    formik.values.amount,
    formik.values.orderConfirmationInvoices,
    formik.values.proformaInvoices,
    formik.values.remittedAmount,
    formik.values.unremittedAmount,
  ]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          paddingRight={"12px"}
          gridTemplateColumns={"repeat(3, 1fr)"}
          gridTemplateRows={"20px 24px 20px 24px"}
          gridColumnGap={"8px"}
          gridRowGap={"4px"}
        >
          <Skeleton />
          <Box />
          <Box />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Box />
          <Box />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton gridColumn={"1/4"} />
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("payment_figures")}>
      <Grid
        gridTemplateColumns={"repeat(3, 1fr)"}
        // gridTemplateRows={"au"}
        rowGap={"4px"}
        columnGap={"8px"}
        alignItems={"center"}
        fontSize={"12px"}
      >
        <Box gridColumn={"1/-1"}>{tUpper("payment")}</Box>

        <Box>{formik.values.id !== -1 && tTitle("common:remitted_amount")}</Box>
        <Box>
          {formik.values.id !== -1 && tTitle("common:unremitted_amount")}
        </Box>
        <Box>{tTitle("common:total_remittance_required_amount.abbr")}</Box>

        <Box>
          {formik.values.id !== -1 && (
            <Field name={"remittedAmount"}>
              {(
                props: FieldProps<
                  PaymentFormFields["remittedAmount"],
                  PaymentFormFields
                >
              ) => {
                return (
                  <AppPriceInput
                    value={{
                      ...props.field.value,
                      currency,
                    }}
                    isReadOnly={true}
                  />
                );
              }}
            </Field>
          )}
        </Box>
        <Box>
          {formik.values.id !== -1 && (
            <Field name={"unremittedAmount"}>
              {(
                props: FieldProps<
                  PaymentFormFields["unremittedAmount"],
                  PaymentFormFields
                >
              ) => {
                return (
                  <AppPriceInput
                    value={{
                      ...props.field.value,
                      currency,
                    }}
                    isReadOnly={true}
                  />
                );
              }}
            </Field>
          )}
        </Box>
        <Box>
          <Field name={"amount"}>
            {(
              props: FieldProps<PaymentFormFields["amount"], PaymentFormFields>
            ) => {
              return (
                <AppPriceInput
                  value={{
                    ...props.field.value,
                    currency,
                  }}
                  isReadOnly={!isEditing}
                  isDisabled={isEditing && formik.values.isRemitted}
                  onChange={(value) => {
                    formik.setValues((prev) => {
                      const next = {
                        ...prev,
                        amount: {
                          value: value?.value,
                          currency,
                        },
                      };

                      next.unremittedAmount = PriceUtils.minus(
                        [next.amount, next.remittedAmount],
                        currency
                      );

                      return next;
                    });
                  }}
                  // isLiteral={true}
                  // isReadOnly={true}
                />
              );
            }}
          </Field>
        </Box>

        <Box></Box>
        <Box></Box>
        <Box justifySelf={"end"}>
          <Field name={"isFeeIncluded"}>
            {(
              props: FieldProps<
                PaymentFormFields["isFeeIncluded"],
                PaymentFormFields
              >
            ) => {
              return (
                <AppCheckBox
                  name={tTitle("fee_included")}
                  isChecked={props.field.value}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={
                    !isEditing || formik.values.receiver?.type === "BOUTIQUE"
                  }
                />
              );
            }}
          </Field>
        </Box>

        <Box gridColumn={"1/-1"}>{tUpper("related_invoices_summary")}</Box>

        <Box>{tTitle("remitted_amount")}</Box>
        <Box>{tTitle("unremitted_amount")}</Box>
        <Box>{tTitle("common:total_remittance_required_amount.abbr")}</Box>

        <Box>
          <AppPriceInput value={fromSummary.remittedAmount} isReadOnly={true} />
        </Box>
        <Box>
          <AppPriceInput
            value={fromSummary.unremittedAmount}
            isReadOnly={true}
          />
        </Box>
        <Box>
          <AppPriceInput value={fromSummary.requiredAmount} isReadOnly={true} />
        </Box>
      </Grid>
    </AppPanel>
  );
}
