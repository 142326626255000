import { DateTimeString, DateUtils } from "@/features/ui/utils/date-utils";
import { Box, Flex, Grid, Tag, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";

interface FlightTimeProps {
  estimated: DateTimeString | Date;
  actual: DateTimeString | Date | null;
  direction?: "row" | "column";
}

export default function FlightTime({
  estimated,
  actual,
  direction = "row",
}: FlightTimeProps) {
  const { tLocalDateTimeString2 } = useI18nHelper();

  const values = useMemo(() => {
    if (actual) {
      if (!DateUtils.isEqual(estimated, actual)) {
        return [actual, estimated];
      }
      return [actual];
    } else {
      return [estimated];
    }
  }, [actual, estimated]);

  return (
    <Flex flexDirection={direction} alignItems={"baseline"} alignSelf={"start"}>
      <Box as={"span"} color={"#444440"}>
        {tLocalDateTimeString2(values[0])}
      </Box>
      {values.length > 1 && (
        <Box
          as={"span"}
          color={"#8F8F8C"}
          textDecoration={"line-through"}
          fontSize={"0.9em"}
        >
          {tLocalDateTimeString2(values[1])}
        </Box>
      )}
    </Flex>
  );
}
