import { DepositInvoiceRow } from "@/features/invoices/deposit-invoices/deposit-invoice-row.type";
import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export const depositInvoiceRowMapper = new GridMapper<
  DepositInvoice,
  DepositInvoiceRow
>(
  "DepositInvoice",
  (resource) => {
    return {
      ...resource,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
