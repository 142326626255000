import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import { IdentityParameter, StoredObject } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { CreateCreditInvoiceRequest } from "@/features/invoices/credit-invoices/hooks/use-create-credit-invoice";
import useFetcher from "@/utils/use-fetcher";

export interface CreateDepositInvoiceRequest
  extends Omit<
    DepositInvoice,
    "id" | "createdAt" | "updatedAt" | "isRead" | "file"
  > {
  file: Pick<StoredObject, "id" | "name">;
}

export interface CreateDepositInvoiceResponse extends DepositInvoice {}

export interface CreateDepositInvoicePathParameter extends IdentityParameter {}

export const createDepositInvoiceTemplateUrl = `/:by/:companyId/deposit-invoices`;

export const createDepositInvoiceKey: YWRKey<CreateDepositInvoicePathParameter> =
  {
    url: (parameters: CreateDepositInvoicePathParameter) => {
      return calcUrl(createDepositInvoiceTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateDepositInvoice(
  config?: AxiosRequestConfig<CreateCreditInvoiceRequest>
) {
  const fetcher = useFetcher<
    CreateCreditInvoiceRequest,
    CreateDepositInvoiceResponse
  >();
  return useYWR(createDepositInvoiceKey, fetcher, config);
}
