import { Helmet } from "react-helmet-async";
import AuthLayout from "@/features/auth/auth-layout";
import SignUpStep1Form, {
  SignUpStep1FormFields,
} from "@/features/auth/sign-up/sign-up-step1-form";
import SignUpStep2Form, {
  SignUpStep2FormFields,
} from "@/features/auth/sign-up/sign-up-step2-form";
import SocialButtonGroup from "@/features/auth/social-buttons/social-button-group";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Box, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export type SignUpFormValues = SignUpStep1FormFields & SignUpStep2FormFields;

function getStep(raw: string | null) {
  return raw === "2" ? 2 : 1;
}

function getIsValidStep(step: 1 | 2, formValues: SignUpFormValues) {
  if (step === 2) {
    return formValues.email !== "" && formValues.password !== "";
  }
  return true;
}

export default function SignUpPage({ step }: { step: 1 | 2 }) {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<SignUpFormValues>({
    email: "",
    password: "",
    passwordConfirmation: "",
    userInputedCompanyName: "",
    name: "",
    mobileNumber: "",
    agreeToAll: false,
    agreeToTermsOfUse: false,
    agreeToCollectPersonalInformation: false,
    agreeToProvidePersonalInformation: false,
    agreeToMarketing: false,
    over14: false,
    countryCode: "+82",
  });
  
  const isValidStep = getIsValidStep(step, formValues);
  const { tTitle, tSentence } = useI18nHelper();

  useEffect(() => {
    if (step === 2) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [step]);

  useEffect(() => {
    if (!isValidStep) {
      navigate("/auth/sign-up", { replace: true });
      //navigate(0);
    }
  }, [isValidStep, navigate]);

  if (!isValidStep) {
    return <>wrong.. redirect..</>;
  }

  const tail = (
    <Box fontSize={"12px"}>
      {tSentence("already_have_an_account", { ns: "auth" })} &nbsp;
      <Link to={"/auth/sign-in"}>
        <Text as="u" fontWeight={"700"}>
          {tSentence("sign_in.do", { ns: "auth" })}
        </Text>
      </Link>
    </Box>
  );

  const handleSubmit = (step1FormValues: SignUpStep1FormFields) => {
    setFormValues({ ...formValues, ...step1FormValues });
    navigate("/auth/sign-up/2");
  };

  const form = (() => {
    if (step === 1) {
      return (
        <SignUpStep1Form initialValue={formValues} onSubmit={handleSubmit} />
      );
    } else {
      return <SignUpStep2Form initialValue={formValues} bySocial={false} />;
    }
  })();

  return (
    <AuthLayout
      title={tTitle("sign_up", { ns: "auth" })}
      tail={tail}
      indicator={`Step ${step} of 2`}
      socialButtons={step === 1 && <SocialButtonGroup name={"sign up"} />}
    >
      <Helmet>
        <title>Pathrade | Sign Up</title>
        <meta
          name="description"
          content="Sign up for the Pathrade to use a B2B Fashion Platform tailored for the global luxury fashion landscape with a seamless ordering experience and insightful analytics."
        />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content="Pathrade | Sign up to the Luxury Fashion B2B Platform & Saas."
        />
        <meta
          property="og:description"
          content="Sign up for the Pathrade to use a B2B Fashion Platform tailored for the global luxury fashion landscape with a seamless ordering experience and insightful analytics."
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      {form}
    </AuthLayout>
  );
}
