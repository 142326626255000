import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadDepositInvoiceRequest {}
export interface ReadDepositInvoiceResponse extends LightLineSheet {}

interface ReadDepositInvoicePathParameter extends IdentityParameter {
  depositInvoiceId: number;
}

export const readDepositInvoiceTemplateUrl =
  "/:by/:companyId/deposit-invoices/:depositInvoiceId/read";

export const readDepositInvoiceKey: YWRKey<ReadDepositInvoicePathParameter> = {
  url: (parameter: ReadDepositInvoicePathParameter) => {
    return calcUrl(readDepositInvoiceTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReadDepositInvoiceKey(url: string) {
  const urlParser = new UrlParser<ReadDepositInvoicePathParameter>(
    readDepositInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      depositInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadDepositInvoice() {
  const fetcher = useFetcher<
    ReadDepositInvoiceRequest,
    ReadDepositInvoiceResponse
  >();
  return useYWR(readDepositInvoiceKey, fetcher);
}
