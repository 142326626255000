import useFetcher from "@/utils/use-fetcher";
import {
  getGetOrderSheetStatisticKey,
  GetOrderSheetStatisticRequest,
  GetOrderSheetStatisticResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-statistic";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListOrderSheetStatisticsRequest,
  ListOrderSheetStatisticsResponse,
  parseListOrderSheetStatisticsKey,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-statistics";

export default function useListOrderSheetStatisticsFetcher() {
  const getOrderSheetStatisticFetcher = useFetcher<
    GetOrderSheetStatisticRequest,
    GetOrderSheetStatisticResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListOrderSheetStatisticsRequest>
    ): Promise<ListOrderSheetStatisticsResponse> => {
      const { parameter, request } = parseListOrderSheetStatisticsKey(config);

      const promises = request.orderSheetIds.map((orderSheetId) => {
        return getOrderSheetStatisticFetcher(
          getGetOrderSheetStatisticKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
              orderSheetSetId: parameter.orderSheetSetId,
              orderSheetId,
            },
            {}
          )!
        );
      });

      //promise.all의 순서는 보장되겟지?

      const responses = await Promise.all(promises);
      return Promise.resolve({
        orderSheetStatistics: responses.map((response, index) => {
          return {
            orderSheetId: request.orderSheetIds[index],
            ...response,
          };
        }),
      });
    };
  }, [getOrderSheetStatisticFetcher]);
}
