import AppGrid from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import { useEffect, useMemo, useState } from "react";
import { AgencyLineSheetSetRow } from "@/features/order-sheet-sets/line-sheet-set-row.type";
import { Spinner } from "@chakra-ui/react";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { lineSheetSetMapper } from "@/features/invoices/utils/line-sheet-set-mapper";
import AppGridCell from "@/features/order-sheet-sets/app-grid-cell";

interface LineSheetSetGridProps {
  isPendingLineSheetSetRefreshing?: boolean;
  resources?: LightLineSheetSet[];
  mutateResources: () => void;
}

export default function AgencyLineSheetSetGrid({
  isPendingLineSheetSetRefreshing,
  resources,
  mutateResources,
}: LineSheetSetGridProps) {
  const [rows, setRows] = useState<AgencyLineSheetSetRow[]>();
  const rowMapper = useMemo(() => {
    return lineSheetSetMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(resources, prev);
    });
  }, [resources, rowMapper]);

  const { navigateToLineSheetSetPage } = useRouterHelper();

  const lineSheetSetGrid = useLineSheetSetGrid({
    rows,
    mutateRows: mutateResources,
  });

  return (
    <>
      <AppGrid
        rows={rows}
        metas={[
          {
            isCheckable: false,
            rowType: "LineSheetSet",
            showCheckAll: false,
            gap: "4px",
            columns: [
              {
                ...lineSheetSetGrid.pairs.lineSheetSetType.column,
                width: "52px",
              },
              { ...lineSheetSetGrid.pairs.season.column, width: "52px" },
              {
                ...lineSheetSetGrid.pairs.issuedByBrand.column,
                width: "104px",
              },
              {
                ...lineSheetSetGrid.pairs.issuedByBoutique.column,
                width: "104px",
              },
              {
                ...lineSheetSetGrid.pairs.title.column,
                width: "minmax(224px, 1fr)",
              },
              { ...lineSheetSetGrid.pairs.uploadedOn.column, width: "88px" },
              { ...lineSheetSetGrid.pairs.publishedOn.column, width: "88px" },
              { ...lineSheetSetGrid.pairs.validUntil.column, width: "152px" },
              { ...lineSheetSetGrid.pairs.status.column, width: "200px" },
              { ...lineSheetSetGrid.pairs.reviewStatus.column, width: "128px" },
            ],
            fields: [
              lineSheetSetGrid.pairs.lineSheetSetType.field,
              lineSheetSetGrid.pairs.season.field,
              lineSheetSetGrid.pairs.issuedByBrand.field,
              lineSheetSetGrid.pairs.issuedByBoutique.field,
              lineSheetSetGrid.pairs.title.field,
              {
                ...lineSheetSetGrid.pairs.uploadedOn.field,
                chakraProps: { fontWeight: "700" },
              },
              {
                ...lineSheetSetGrid.pairs.publishedOn.field,
                chakraProps: { fontWeight: "700" },
              },
              {
                ...lineSheetSetGrid.pairs.validUntil.field,
                chakraProps: { fontWeight: "700" },
              },
              {
                name: "status",
                value: (_row, context) => {
                  const row = _row as AgencyLineSheetSetRow;

                  if (
                    isPendingLineSheetSetRefreshing &&
                    (row.status === "PARSING_PENDING" ||
                      row.compressionStatus === null ||
                      row.compressionStatus === "PENDING")
                  ) {
                    return <Spinner size={"sm"} />;
                  }

                  return (
                    <AppGridCell
                      value={[
                        lineSheetSetGrid.pairs.status.field.value(row, context),
                        lineSheetSetGrid.pairs.compressionStatus.field.value(
                          row,
                          context
                        ),
                      ]}
                    />
                  );
                },
              },
              lineSheetSetGrid.pairs.reviewStatus.field,
            ],
            actions: [
              lineSheetSetGrid.actions.downloadCompressedImportedFile,
              lineSheetSetGrid.actions.reportLineSheetSet,
              lineSheetSetGrid.actions.publishLineSheetSet,
              lineSheetSetGrid.actions.reparseLineSheetSet,
              lineSheetSetGrid.actions.pendLineSheetSetReviewStatus,
              lineSheetSetGrid.actions.approveLineSheetSetReviewStatus,
              lineSheetSetGrid.actions.rejectLineSheetSetReviewStatus,
              lineSheetSetGrid.actions.deleteLineSheetSet,
              lineSheetSetGrid.actions.restoreLineSheetSet,
            ],
            indicator: (row) => {
              return (
                <GridRowIndicator
                  type={"VIEW"}
                  isHovered={row.isHovered}
                  isCollapsed={row.isCollapsed}
                />
              );
            },
            onClick: (row) => {
              const lineSheetSetRow = row as AgencyLineSheetSetRow;
              navigateToLineSheetSetPage(lineSheetSetRow.id);
            },
          },
        ]}
      />
    </>
  );
}
