import { Box, Flex, Spinner } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useMemo, useRef } from "react";
import {
  FileType,
  getAccept,
  isExcel,
  isPDF,
  StoredObject,
} from "@/features/types";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface InvoiceFileInputProps {
  value: File | Pick<StoredObject, "id" | "name" | "createdAt">;
  onChange: (file: File) => void;
  onClick?: () => void;
  onDelete: () => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isLoading?: boolean;
  allowFileTypes?: FileType[];
  showCreatedAt?: boolean;
}

export default function InvoiceFileInput({
  value,
  onChange,
  onDelete,
  isDisabled = false,
  isReadOnly = false,
  isLoading = false,
  showCreatedAt = false,
  onClick,
  allowFileTypes,
}: InvoiceFileInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { tLocalDateTimeString } = useI18nHelper();

  const createdAt = useMemo(() => {
    if (value instanceof File) {
      return new Date();
    }
    return value.createdAt;
  }, [value]);

  const icon = useMemo(() => {
    if (isLoading) {
      return (
        <Spinner
          size={"sm"}
          position={"absolute"}
          top={"6px"}
          left={"8px"}
          zIndex={"2"}
          //transform={"translate(0, -50%)"}
        />
      );
    }

    let iconSrc = "/icons/icon_upload.svg";

    if (isReadOnly) {
      if (isExcel(value)) {
        iconSrc = "/icons/icon_excel_md.svg";
      } else if (isPDF(value)) {
        iconSrc = "/icons/icon_pdf_md.svg";
      } else {
        iconSrc = "/icons/icon_download.svg";
      }
    }

    return (
      <SVG
        src={iconSrc}
        color={"#8F8F8C"}
        style={{
          position: "absolute",
          top: "50%",
          left: "8px",
          transform: "translate(0, -50%)",
          zIndex: "2",
        }}
      />
    );
  }, [isLoading, isReadOnly, value]);

  return (
    <>
      <Flex
        width={"100%"}
        alignItems={"center"}
        height={"28px"}
        gap={"8px"}
        position={"relative"}
      >
        {icon}
        <Box flexGrow={"1"}>
          <AppInput
            value={value.name}
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
            paddingLeft={"28px"}
            onClick={() => {
              if (!isReadOnly) {
                fileInputRef.current?.click();
              } else if (!isLoading) {
                onClick?.();
              }
            }}
            color={"#8F8F8C"}
            backgroundColor={"#EFEFED"}
            _hover={{
              cursor: isReadOnly ? "pointer" : "default",
            }}
          />
        </Box>

        <input
          type={"file"}
          style={{ display: "none" }}
          ref={fileInputRef}
          accept={getAccept(allowFileTypes)}
          onChange={(event) => {
            if (event.target.files && event.target.files.length > 0) {
              onChange(event.target.files[0]);
            }
          }}
        />
        {!isDisabled && !isReadOnly && (
          <Flex flexShrink={"0"}>
            <AppIconButton
              ariaLabel={"delete partial"}
              icon={"x"}
              onClick={() => {
                onDelete();
              }}
              size={"small"}
            />
          </Flex>
        )}
      </Flex>
      {showCreatedAt && (
        <Box
          fontSize={"12px"}
          color={"#8F8F8C"}
          // 우측 정렬
          textAlign={"right"}
          position={"relative"}
        >
          {createdAt ? `Created at ${tLocalDateTimeString(createdAt)}` : ""}
        </Box>
      )}
    </>
  );
}
