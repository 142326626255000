import {
  getGetCreditInvoiceKey,
  GetCreditInvoiceRequest,
  GetCreditInvoiceResponse,
} from "@/features/invoices/credit-invoices/hooks/use-get-credit-invoice";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseCreditInvoiceRequest,
  GetDenseOrderDenseConfirmationInvoiceResponse,
  parseGetDenseCreditInvoiceKey,
} from "@/features/invoices/credit-invoices/hooks/use-get-dense-credit-invoice";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-condense-proforma-invoices";

export default function useGetDenseCreditFetcher() {
  const getCreditFetcher = useFetcher<
    GetCreditInvoiceRequest,
    GetCreditInvoiceResponse
  >();

  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();

  const condenseProformaInvoices = useCondenseProformaInvoices();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseCreditInvoiceRequest>
    ): Promise<GetDenseOrderDenseConfirmationInvoiceResponse> => {
      const { parameter, request } = parseGetDenseCreditInvoiceKey(config);

      const getCreditResponse = await getCreditFetcher(
        getGetCreditInvoiceKey(parameter, request)
      );
      if (getCreditResponse) {
        const promises = [
          condenseOrderConfirmationInvoices(
            [getCreditResponse],
            (i) => {
              return i.orderConfirmationInvoices;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.orderConfirmationInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          condenseProformaInvoices(
            [getCreditResponse],
            (i) => {
              return i.earningProformaInvoices;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.earningProformaInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          condenseProformaInvoices(
            [getCreditResponse],
            (i) => {
              return i.usingProformaInvoices;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.usingProformaInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
        ];

        await Promise.all(promises);
      }

      // @ts-ignore
      return Promise.resolve(getCreditResponse);
    };
  }, [
    getCreditFetcher,
    condenseOrderConfirmationInvoices,
    condenseProformaInvoices,
  ]);
}
