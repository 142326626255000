import { TriGridMapper } from "@/features/ui/grid-row/tri-grid-mapper";
import {
  AttachmentFormField,
  isAttachmentFormField,
  isLineSheetFormField,
  isOrderSheetFormField,
  LineSheetFormField,
  OrderSheetFormField,
} from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import {
  AttachmentRow,
  IsolatedLineSheetRow,
  IsolatedOrderSheetRow,
} from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

export function isLineSheetRow(row: GridRow): row is IsolatedLineSheetRow {
  return row._rowType === "LineSheet";
  // return (row as IsolatedLineSheetRow).type !== undefined;
}

export function isOrderFormRow(row: GridRow): row is IsolatedLineSheetRow {
  const lineSheetRow = row as IsolatedLineSheetRow;
  return (
    lineSheetRow._rowType === "LineSheet" && lineSheetRow.type === "ORDER_FORM"
  );
}

export function isOrderSheetRow(row: GridRow): row is IsolatedOrderSheetRow {
  return row._rowType === "OrderSheet";
}

export function isAttachmentRow(row: GridRow): row is AttachmentRow {
  return row._rowType === "Attachment";
}

export type LineSheetableRow =
  | IsolatedLineSheetRow
  | IsolatedOrderSheetRow
  | AttachmentRow;

export const lineSheetableMapper = new TriGridMapper<
  LineSheetFormField,
  IsolatedLineSheetRow,
  AttachmentFormField,
  AttachmentRow,
  OrderSheetFormField,
  IsolatedOrderSheetRow
>(
  new GridMapper<LineSheetFormField, IsolatedLineSheetRow>(
    "LineSheet",
    (lineSheet) => {
      return {
        type: lineSheet.type,
        name: lineSheet.name,
        status: lineSheet.status,
        reviewStatus: lineSheet.reviewStatus,
        file: lineSheet.file,
        updatedAt: lineSheet.updatedAt,
        isCheckable: lineSheet.isCheckable,
        isChecked: lineSheet.isChecked,
        source: lineSheet.source || lineSheet,
      };
    },
    (row) => {
      return {
        type: row.type,
        name: row.name,
        status: row.status,
        reviewStatus: row.reviewStatus,
        file: row.file,
        updatedAt: row.updatedAt,
        isCheckable: row.isCheckable,
        isChecked: row.isChecked,
        source: row.source,
      };
    }
  ),
  isLineSheetFormField,
  new GridMapper<AttachmentFormField, AttachmentRow>(
    "Attachment",
    (resource) => {
      return {
        id: resource.id,
        name: resource.name,
        file: resource.file,
        isCheckable: resource.isCheckable,
        isChecked: resource.isChecked,
        source: resource.source || resource,
      };
    },
    (row) => {
      return {
        id: row.id,
        name: row.name,
        file: row.file,
        isCheckable: row.isCheckable,
        isChecked: row.isChecked,
        source: row.source,
      };
    }
  ),
  isAttachmentFormField,
  new GridMapper<OrderSheetFormField, IsolatedOrderSheetRow>(
    "OrderSheet",
    (orderSheet) => {
      return {
        name: orderSheet.lineSheet.name,
        status: orderSheet.status,
        uploadedFile: orderSheet.uploadedFile,
        exportedFile: orderSheet.exportedFile,
        lineSheet: orderSheet.lineSheet,
        toBeSubmitted: orderSheet.toBeSubmitted,
        orderedAmount: orderSheet.orderedAmount,
        orderedAmountWithoutCondition: orderSheet.orderedAmountWithoutCondition,
        orderedQuantity: orderSheet.orderedQuantity,
        orderedConditionType: orderSheet.orderedConditionType,
        confirmedAmount: orderSheet.confirmedAmount,
        confirmedQuantity: orderSheet.confirmedQuantity,
        isChecked: orderSheet.isChecked,
        isCheckable: orderSheet.isCheckable,
      };
    },
    (orderSheet) => {
      return {
        status: orderSheet.status,
        uploadedFile: orderSheet.uploadedFile,
        exportedFile: orderSheet.exportedFile,
        lineSheet: orderSheet.lineSheet,
        toBeSubmitted: orderSheet.toBeSubmitted,
        orderedAmount: orderSheet.orderedAmount,
        orderedAmountWithoutCondition: orderSheet.orderedAmountWithoutCondition,
        orderedQuantity: orderSheet.orderedQuantity,
        orderedConditionType: orderSheet.orderedConditionType,
        confirmedAmount: orderSheet.confirmedAmount,
        confirmedQuantity: orderSheet.confirmedQuantity,
        isChecked: orderSheet.isChecked,
        isCheckable: orderSheet.isCheckable,
      };
    }
  ),
  isOrderSheetFormField
);
