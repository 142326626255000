import useUpdateLineSheetSetV2, {
  updateLineSheetSetV2Key,
  UpdateLineSheetSetV2PathParameter,
  UpdateLineSheetSetV2Request,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-v2";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useRecoilCallback } from "recoil";
import { isFile, StoredObject } from "@/features/types";
import {
  LineSheet,
  LineSheetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { useCallback, useEffect } from "react";
import { dumbTransactionsState } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import { createStoreObjectDumbTransaction } from "@/features/ui/utils/dumb-transaction-util";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import {
  CreateLineSheetSetPathParameter,
  CreateLineSheetSetRequest,
} from "@/features/line-sheet-sets/hooks/use-create-line-sheet-set";
import useCreateLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-create-line-sheet-set-infinite";

function mutateLineSheetSets(lineSheetSetId?: number) {
  if (lineSheetSetId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers|boutiques)\/\d+\/dense-line-sheet-sets\/${lineSheetSetId}/`
      )
    );
  }

  mutateIfMatched(
    /\/(agencies|buyers|boutiques)\/\d+\/(dense-)?line-sheet-sets/
  );
}

export function useLineSheetSetDumbTransaction() {
  const { fire: fireUpdateLineSheetSet, error: updateLineSheetSetError } =
    useUpdateLineSheetSetV2();
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();

  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  useEffect(() => {
    if (updateLineSheetSetError) {
      showError(updateLineSheetSetError);
    }
  }, [showError, updateLineSheetSetError]);

  const { fire: fireCreateLineSheetSet } = useCreateLineSheetSetInfinite();

  const addCreateLineSheetSet = useRecoilCallback(
    ({ set }) =>
      (
        parameter: CreateLineSheetSetPathParameter,
        request: Omit<
          CreateLineSheetSetRequest,
          "lineSheets" | "attachments"
        > & {
          lineSheets: (Omit<
            CreateLineSheetSetRequest["lineSheets"][number],
            "file"
          > & {
            file: File | Pick<StoredObject, "id">;
          })[];
          attachments: (File | Pick<StoredObject, "id">)[];
        }
      ) => {
        const [storeObjectTransactions, storeObjectPromises] =
          createStoreObjectDumbTransaction([
            ...request.lineSheets
              .map((lineSheet) => lineSheet.file)
              .filter(isFile),
            ...request.attachments
              .map((attachment) => attachment)
              .filter(isFile),
          ]);

        set(dumbTransactionsState, (prev) => {
          return [...prev, ...storeObjectTransactions];
        });

        Promise.all(storeObjectPromises).then(async (storedObjects) => {
          let i = 0;
          const createLineSheetSetRequest: CreateLineSheetSetRequest = {
            ...request,
            lineSheets: request.lineSheets.map((lineSheet) => {
              return {
                ...lineSheet,
                file: isFile(lineSheet.file)
                  ? storedObjects[i++]
                  : lineSheet.file,
              };
            }),
            attachments: request.attachments.map((attachment) => {
              if (isFile(attachment)) {
                return storedObjects[i++];
              } else {
                return { id: attachment.id };
              }
            }),
          };

          try {
            await fireCreateLineSheetSet(parameter, createLineSheetSetRequest);
            mutateLineSheetSets();
          } catch (e) {
            showError(e);
          }
        });
      },
    [fireCreateLineSheetSet, showError]
  );

  const addUpdateLineSheetSetTransaction = useRecoilCallback(
    ({ set }) =>
      (
        parameter: UpdateLineSheetSetV2PathParameter,
        request: Omit<
          UpdateLineSheetSetV2Request,
          "lineSheets" | "attachments"
        > & {
          lineSheets: {
            id: number;
            type: LineSheetType;
            file: File | Pick<StoredObject, "id">;
            reviewStatus: LineSheet["reviewStatus"];
          }[];
          attachments: (File | Pick<StoredObject, "id">)[];
        }
      ) => {
        addDumbTransactions({
          parameter,
          source: request,
          getFilesFunc: (request) => {
            return [
              ...request.lineSheets
                .map((lineSheet) => lineSheet.file)
                .filter(isFile),
              ...request.attachments.filter(isFile),
            ];
          },
          convertFunc: (source, storeObjectResponses) => {
            let i = 0;
            let request = {
              ...source,
            };

            request.lineSheets = request.lineSheets.map((lineSheet) => {
              if (isFile(lineSheet.file)) {
                return {
                  ...lineSheet,
                  file: storeObjectResponses[i++],
                };
              }
              return lineSheet;
            });

            request.attachments = request.attachments.map((attachment) => {
              if (isFile(attachment)) {
                return storeObjectResponses[i++];
              }
              return attachment;
            });

            return request as UpdateLineSheetSetV2Request;
          },
          fire: fireUpdateLineSheetSet,
          keyOrFunc: calcYWRUrl(updateLineSheetSetV2Key, parameter),
          onPostFunc: (res) => {
            mutateLineSheetSets(parameter.lineSheetSetId);
          },
          onErrorFunc: showError,
        });
      },
    [addDumbTransactions, fireUpdateLineSheetSet, showError]
  );

  const isUpdateLineSheetSetQueued = useCallback(
    (parameter: UpdateLineSheetSetV2PathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateLineSheetSetV2Key, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreateLineSheetSet,
    addUpdateLineSheetSetTransaction,
    isUpdateLineSheetSetQueued,
  };
}
