import { Grid, Skeleton } from "@chakra-ui/react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import React from "react";

export default function InvoiceInformationFormControlSkeleton() {
  return (
    <AppPanel title={<Skeleton height={"32px"} />}>
      <Grid
        rowGap={"8px"}
        gridTemplateRows={"repeat(7, 16px 24px) repeat(2, 16px 60px)"}
      >
        {(Array(18).fill(0) as number[]).map((_, index) => {
          return <Skeleton key={index} />;
        })}
      </Grid>
    </AppPanel>
  );
}
