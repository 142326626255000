import { CreditInvoiceType } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { Company, isFile, LightCompany, StoredObject } from "@/features/types";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import { Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import CreditInvoiceForm from "@/features/invoices/credit-invoices/credit-invoice-form";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useCreditInvoiceDumbTransaction from "@/features/invoices/credit-invoices/hooks/use-credit-invoice-dumb-transaction";
import useCreditInvoiceFormValidationSchema from "@/features/invoices/credit-invoices/hooks/use-credit-invoice-form-validation-schema";
import useIdentity from "@/features/ui/hooks/use-identity";
import useGetIssuedThroughOrIssuedTo from "@/features/invoices/hooks/use-get-issued-through-or-issued-to";
import { PriceInputValue } from "@/features/invoices/app-price-input";
import PriceUtils from "@/features/ui/utils/price-utils";

export interface CreditInvoiceFormFields {
  id?: number;
  number?: string;
  name?: string;
  season?: string;
  type?: CreditInvoiceType;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedBy?: Company;
  issuedThrough?: LightCompany;
  issuedOn?: Date;
  orderConfirmationInvoices: {
    id: number;
    name?: string;
    totalRequiredAmount?: PriceInputValue;
  }[];
  earningProformaInvoices: {
    id: number;
    name?: string;
    totalRequiredAmount?: PriceInputValue;
  }[];
  usingProformaInvoices: { id: number }[];
  publicComment?: string;
  privateComment?: string;
  file?: File | Pick<StoredObject, "id" | "name">;
  amount?: PriceInputValue;
  emailThreadMessageId?: string;
  sendAsNewEmail: boolean;
}

export default function UploadCreditInvoicePage() {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const { navigate } = useRouterHelper();
  const identity = useIdentity();

  const { addCreate } = useCreditInvoiceDumbTransaction();

  const validateSchema = useCreditInvoiceFormValidationSchema();
  const validate = useCallback(
    async (values: CreditInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const handleSubmit = useCallback(
    (values: CreditInvoiceFormFields) => {
      if (identity?.company && isFile(values.file!)) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            number: values.number!,
            season: values.season!,
            type: values.type!,
            issuedOn: values.issuedOn!,
            issuedByCompany: values.issuedBy!,
            issuedToCompany: values.orderedBy!,
            issuedThroughCompany: values.issuedThrough!,
            file: values.file,
            amount: PriceUtils.placeholder(values.amount!, "EUR"),
            emailThreadMessageId: values.emailThreadMessageId || null,
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            orderConfirmationInvoices: values.orderConfirmationInvoices.map(
              (ocInvoice) => {
                return {
                  id: ocInvoice.id,
                };
              }
            ),
            earningProformaInvoices: values.earningProformaInvoices.map(
              (pInvoice) => {
                return {
                  id: pInvoice.id,
                };
              }
            ),
          }
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    [addCreate, identity]
  );

  const issuedThroughOrIssuedTo = useGetIssuedThroughOrIssuedTo();

  const initialValues = useMemo((): CreditInvoiceFormFields => {
    return {
      id: -1,
      season: DEFAULT_SEASON,
      type: "FIXING",
      issuedOn: (() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
      })(),
      sendAsNewEmail: identity?.company?.type !== "AGENCY",
      orderConfirmationInvoices: [],
      usingProformaInvoices: [],
      earningProformaInvoices: [],
      ...issuedThroughOrIssuedTo,
    };
  }, [identity, issuedThroughOrIssuedTo]);

  return (
    <Formik<CreditInvoiceFormFields>
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"UploadOrderConfirmationInvoice"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("credit_invoices:upload_credit_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppButton
                  key={`UploadAndAddAnother`}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      props.resetForm();
                    }
                  }}
                >
                  {tTitle("upload_and_add_another.do")}
                </AppButton>,
                <AppButton
                  key={`Upload`}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      navigate("/credit-invoices");
                    }
                  }}
                >
                  {tTitle("upload")}
                </AppButton>,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/credit-invoices"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <CreditInvoiceForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
