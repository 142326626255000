import { useCallback, useEffect, useMemo } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";
import { FormikProps } from "formik";
import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import { IsolatedLineSheetRow } from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import { SEASON_OPTIONS } from "@/features/line-sheet-sets/app-season-select";

interface Parameter {
  formik: FormikProps<LineSheetSetFormFields>;
}

function findMatchedOne<T extends { name: string }>(
  material: string,
  items: T[]
): T | undefined {
  return items.find((c) => {
    return material.toLowerCase().includes(c.name.toLowerCase());
  });
}

export default function useFillOutLineSheetSetForm({ formik }: Parameter) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const key = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          type__in: ["BRAND", "BOUTIQUE"],
        },
      };
    }
  }, [identity]);

  const { data, error } = useListAllCompanies(key?.parameter, key?.request, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  const issuedByBrands = useMemo(() => {
    return data?.filter((company) => company.type === "BRAND") || [];
  }, [data]);

  const issuedByBoutiques = useMemo(() => {
    return data?.filter((company) => company.type === "BOUTIQUE") || [];
  }, [data]);

  return useCallback(
    (row: IsolatedLineSheetRow) => {
      formik.setValues((prev) => {
        return {
          ...prev,
          name: prev.name || row.name,
          season:
            (
              findMatchedOne(row.name, SEASON_OPTIONS) ||
              findMatchedOne(
                row.name.replace(/(ss|fw)(\d{2})/i, "$2$1"),
                SEASON_OPTIONS
              )
            )?.value || prev.season,
          issuedByBrand:
            prev.type === "SEASON"
              ? findMatchedOne(row.name, issuedByBrands)
              : prev.issuedByBrand,
          issuedByBoutique:
            findMatchedOne(row.name, issuedByBoutiques) ||
            prev.issuedByBoutique,
        };
      });
    },
    [formik, issuedByBoutiques, issuedByBrands]
  );
}
