import { Select } from "@chakra-ui/react";
import { ChangeEvent, useCallback, useMemo } from "react";
import useGetMySelf from "@/features/auth/use-get-my-self";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useLocation, useNavigate } from "react-router";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { Company } from "@/features/types";

export default function AppIdentitySelector() {
  const identity = useIdentity();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, data: user } = useGetMySelf();

  const handleSelect = useCallback(
    (value: Company | undefined) => {
      if (value) {
        const nextUrl = location.pathname.replace(/\/u\/\d+/, `/u/${value.id}`);
        navigate(nextUrl);
      }
    },
    [navigate, location]
  );

  const value = useMemo((): Company | undefined => {
    if (identity && user) {
      return user.companies.find((c) => c.id === identity.company?.id);
    }
  }, [identity, user]);

  const options = useMemo((): AppSelectOption<Company>[] => {
    if (user && user.companies) {
      return user.companies.map((c) => ({
        name: `[${c.name}] ${user.name}`,
        value: c,
      }));
    }
    return [];
  }, [user]);

  return (
    <AppSelect<Company>
      name={"identity"}
      value={value}
      width={"180px"}
      idFunc={(c) => c.id}
      options={options}
      isClearable={false}
      onSelect={handleSelect}
    />
  );
}
