import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";
import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";

export interface ReadLineSheetSetRequest {}
export interface ReadLineSheetSetResponse extends LightLineSheet {}

interface ReadLineSheetSetPathParameter extends IdentityParameter {
  lineSheetSetId: number;
}

export const readLineSheetSetTemplateUrl =
  "/:by/:companyId/line-sheet-sets/:lineSheetSetId/read";

export const readLineSheetSetKey: YWRKey<ReadLineSheetSetPathParameter> = {
  url: (parameter: ReadLineSheetSetPathParameter) => {
    return calcUrl(readLineSheetSetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReadLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<ReadLineSheetSetPathParameter>(
    readLineSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadLineSheetSet() {
  const fetcher = useFetcher<
    ReadLineSheetSetRequest,
    ReadLineSheetSetResponse
  >();
  return useYWR(readLineSheetSetKey, fetcher);
}
