import { ParseString } from "@/features/ui/helpers/string-transformer";
import { toCamel } from "@/utils/case";

export class SearchParamsParser<T> {
  private readonly urlSearchParams: URLSearchParams;
  private readonly entries: Record<keyof T & string, ParseString<T>>;

  constructor(
    urlSearchParams: URLSearchParams,
    entries: Record<keyof T & string, ParseString<T>>
  ) {
    this.urlSearchParams = urlSearchParams;
    this.entries = entries;
  }

  private getDumbObject(): {
    [key: string]: string | string[];
  } {
    let dumbObject: { [key: string]: string | string[] } = {};
    const keys: string[] = [];
    for (let [key] of this.urlSearchParams.entries()) {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    }

    keys.forEach((_key) => {
      const value = this.urlSearchParams.getAll(_key);
      // const [field, operator] = _key.split("__");
      // const key = toCamel(field) + (operator ? "__" + toCamel(operator) : "");
      const key = toCamel(_key);
      if (value.length === 1) {
        dumbObject[key] = decodeURIComponent(value[0]);
      } else {
        dumbObject[key] = value.map((item) => decodeURIComponent(item));
      }
    });

    return dumbObject;
  }

  parse(): T {
    const dumbObject = this.getDumbObject();
    const obj = {} as T;

    for (const _key in dumbObject) {
      const key = _key as keyof T & string;
      if (this.entries.hasOwnProperty(key)) {
        const func = this.entries[key];
        const value = func(dumbObject[key]);
        if (value !== undefined) {
          obj[key] = value;
        }
      } else {
        obj[key] = dumbObject[key] as any;
      }
    }

    // for (const _key in this.entries) {
    //   const key = _key as keyof T & string;
    //   if (this.entries.hasOwnProperty(key) && key in dumbObject) {
    //     const func = this.entries[key];
    //     const value = func(dumbObject[key]);
    //     if (value !== undefined) {
    //       obj[key] = value;
    //     }
    //   }
    // }

    return obj;
  }
}
