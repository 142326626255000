import useIdentity from "@/features/ui/hooks/use-identity";
import { Helmet } from "react-helmet-async";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { useEffect, useMemo } from "react";
import {
  DEFAULT_LIST_PROFORMA_INVOICES_PAGE_SIZE,
  ListProformaInvoicesRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-list-proforma-invoices";
import useListDenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices";
import {
  GetInvoiceStatisticByTypePathParameter,
  GetInvoiceStatisticByTypeRequest,
  useGetInvoiceStatisticByType,
} from "@/features/dashboard/hooks/use-get-invoice-statistic-by-type";
import { toTitle } from "@/utils/case";
import AppListHeader from "@/features/ui/app-list-header";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppRemittanceStatusSelect from "@/features/invoices/app-remittance-status-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import { Box, Flex } from "@chakra-ui/react";
import ProformaInvoiceGrid from "@/features/invoices/proforma-invoices/proforma-invoice-grid";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";

export type PersistedProformaInvoicesListPageQuery = Omit<
  ListProformaInvoicesRequest,
  | "pageSize"
  | "isPublic__eq"
  | "unremitted_amount__gt"
  | "status__eq"
  | "status__in"
  | "originallyIssuedByCompany.id__eq"
  | "issuedByCompany.id__eq"
  | "validPayments__exists"
>;

export default function ProformaInvoiceListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { t, tCurrencyString, tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();

  const [query, setQuery] =
    useTypedSearchParams<PersistedProformaInvoicesListPageQuery>();

  const listProformaInvoicesKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_PROFORMA_INVOICES_PAGE_SIZE,
        },
      };
    }
  }, [query, identity]);

  const {
    data: listDenseProformaInvoicesData,
    error: listDenseProformaInvoicesError,
    isLoading: isListDenseProformaInvoicesLoading,
    mutate: mutateListDenseProformaInvoices,
  } = useListDenseProformaInvoices(
    listProformaInvoicesKey?.parameter,
    listProformaInvoicesKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    showError(listDenseProformaInvoicesError);
  }, [showError, listDenseProformaInvoicesError]);

  const getInvoiceStatisticKey = useMemo(():
    | {
        parameter: GetInvoiceStatisticByTypePathParameter;
        request: GetInvoiceStatisticByTypeRequest;
      }
    | undefined => {
    if (identity?.company?.type === "BUYER") {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          type: "PROFORMA",
        },
        request: {
          season__in: query.season__in,
          "issuedByCompany.id__in": query["issuedByCompany.id__in"],
          issuedOn__eq: query.issuedOn__eq,
          isRemittedToAgency__eq: query.isRemittedToAgency__eq,
          isRemittedToBoutique__eq: query.isRemittedToBoutique__eq,
          "madeByCompany.id__eq":
            query["proformaInvoiceDetailList.brand.id__eq"],
        },
      };
    }
  }, [identity, query]);

  const { data: getInvoiceStatisticData, error: getInvoiceStatisticError } =
    useGetInvoiceStatisticByType(
      getInvoiceStatisticKey?.parameter,
      getInvoiceStatisticKey?.request,
      {
        revalidateOnFocus: false,
      }
    );

  useEffect(() => {
    if (getInvoiceStatisticError) {
      showError(getInvoiceStatisticError);
    }
  }, [showError, getInvoiceStatisticError]);

  const figures = useMemo(() => {
    const figures = [];

    if (getInvoiceStatisticData) {
      figures.push({
        name: toTitle(t("common:total_remittance_required_amount.abbr")),
        value: tCurrencyString(getInvoiceStatisticData["totalAmount"])!,
      });
      figures.push({
        name: toTitle(t("common:total_remitted_amount.abbr")),
        value: tCurrencyString(getInvoiceStatisticData["remittedAmount"])!,
      });
    }

    return figures;
  }, [getInvoiceStatisticData, tCurrencyString, t]);

  return (
    <>
      <Helmet>
        <title>Pathrade | {tTitle("proforma_invoices")}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("proforma_invoices")}`}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              width={"120px"}
              value={query.season__in}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            () => {
              if (identity?.company?.type !== "BUYER") {
                return (
                  <AppCompanySelect
                    key={"AgencyProformaInvoiceListFilter_OrderedBy"}
                    name={toTitle(t("ordered_by"))}
                    types={["BUYER"]}
                    width={"120px"}
                    id={query["issuedToCompany.id__eq"]}
                    onSelect={(value) =>
                      setQuery({
                        ...query,
                        "issuedToCompany.id__eq": value?.id,
                        pageNumber: 1,
                      })
                    }
                  />
                );
              }
            },
            () => {
              if (identity?.company?.type !== "BOUTIQUE") {
                return (
                  <AppCompanySelect
                    key={"AgencyProformaInvoiceListFilter_IssuedBy"}
                    name={toTitle(t("issued_by"))}
                    types={["BOUTIQUE", "CROSS_TRADER"]}
                    width={"120px"}
                    id={query["issuedByCompany.id__in"]}
                    isMultiple={true}
                    onSelect={(value) =>
                      setQuery({
                        ...query,
                        "issuedByCompany.id__in": value?.map((item) => item.id),
                        pageNumber: 1,
                      })
                    }
                  />
                );
              }
            },
            <AppDatePicker
              key={"AgencyProformaInvoiceListFilter_IssuedOn"}
              name={toTitle(t("issued_on"))}
              value={query.issuedOn__eq}
              showTimeInput={false}
              width={"120px"}
              onChange={(value) =>
                setQuery({ ...query, issuedOn__eq: value, pageNumber: 1 })
              }
            />,
            <AppRemittanceStatusSelect
              key={"is_remitted_to_boutique"}
              to={"BOUTIQUE"}
              width={"120px"}
              value={(() => {
                if (query.isRemittedToBoutique__eq !== undefined) {
                  return query.isRemittedToBoutique__eq
                    ? "REMITTED"
                    : "REMITTANCE_REQUIRED";
                }
              })()}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    isRemittedToBoutique__eq:
                      value !== undefined ? value === "REMITTED" : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppRemittanceStatusSelect
              key={"is_remitted_to_agency"}
              to={"AGENCY"}
              width={"120px"}
              value={(() => {
                if (query.isRemittedToAgency__eq !== undefined) {
                  return query.isRemittedToAgency__eq
                    ? "REMITTED"
                    : "REMITTANCE_REQUIRED";
                }
              })()}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    isRemittedToAgency__eq:
                      value !== undefined ? value === "REMITTED" : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"proformaInvoiceDetailList.brand.id"}
              types={["BRAND"]}
              width={"120px"}
              name={tTitle("brand")}
              id={query["proformaInvoiceDetailList.brand.id__eq"]}
              onSelect={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    "proformaInvoiceDetailList.brand.id__eq": value?.id,
                    pageNumber: 1,
                  };
                })
              }
            />,
            <AppButton
              key={"AgencyProformaInvoiceListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              width={"120px"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <AppButton
              key={"AgencyProformaInvoiceListAction_UploadInvoices"}
              variant={"primary"}
              size={"medium"}
              onClick={() => {
                //onUploadProformaInvoicesOpen();
                navigate("/proforma-invoices/upload");
              }}
            >
              {toTitle(
                t("upload_proforma_invoices", { ns: "proforma_invoices" })
              )}
            </AppButton>,
          ]}
          figures={figures}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <ProformaInvoiceGrid
            resources={listDenseProformaInvoicesData?.proformaInvoices}
            mutateResources={mutateListDenseProformaInvoices}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listDenseProformaInvoicesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              });
            }}
          />
        </Flex>
      </Flex>
    </>
  );
}
