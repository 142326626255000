import { useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import exp from "constants";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface AppSeasonSelectProps {
  value?: string;
  onSelect?: (value?: string) => void;
  width?: string;
  isDisabled?: boolean;
  after?: string;
  before?: string;
  isClearable?: boolean;
  readonly?: boolean;
}

export const SEASON_OPTIONS = (() => {
  //21SS시작 1/1 다음 해의 SS 추가, 7/1 다음 해의 FW 추가
  const suffixes = ["FW", "SS"];
  const currentYear = new Date().getFullYear();
  const options: AppSelectOption<string>[] = [];

  for (let i = currentYear + 1; i >= 2021; i--) {
    const prefix = String(i).substring(2);

    suffixes
      .filter((suffix) => {
        if (i === currentYear + 1 && suffix === "FW") {
          return (
            new Date().getTime() > new Date(`${currentYear}-07-01`).getTime()
          );
        }
        return true;
      })
      .forEach((suffix) => {
        options.push({
          name: `${prefix}${suffix}`,
          value: `${prefix}${suffix}`,
        });
      });
  }
  options.push(
    {
      name: "MIX",
      value: "MIX",
    },
    {
      name: "CO",
      value: "CO",
    }
  );
  return options;
})();

export const DEFAULT_SEASON = SEASON_OPTIONS[0].value;
export const CURRENT_SEASON = SEASON_OPTIONS[0].value;

export function getSeason(diff: number = 0): string {
  const currentSeason = CURRENT_SEASON;
  const year = parseInt(currentSeason.substring(0, 2));
  const suffix = currentSeason.substring(2, 4);

  const yearDiff = Math.floor(diff / 2);
  const isEvenDiff = diff % 2 === 0;

  if (isEvenDiff) {
    return `${year + yearDiff}${suffix}`;
  }

  if (suffix === "FW") {
    return `${year + yearDiff}SS`;
  }

  return `${year + yearDiff - 1}FW`;
}

export function getSeasonOptions(after?: string, before?: string) {
  let candidate = SEASON_OPTIONS;
  if (after) {
    const index = candidate.findIndex((option) => option.value === after);
    if (index > -1) {
      candidate = candidate.slice(0, index + 1);
    }
  }

  if (before) {
    const index = candidate.findIndex((option) => option.value === before);
    if (index > -1) {
      candidate = candidate.slice(index);
    }
  }
  return candidate;
}

export default function AppSeasonSelect({
  value,
  width = "144px",
  onSelect,
  isDisabled = false,
  after,
  before,
  isClearable = true,
  readonly = false,
}: AppSeasonSelectProps) {
  const { tTitle } = useI18nHelper();
  const options = useMemo(() => {
    return getSeasonOptions(after, before);
  }, [after, before]);

  return (
    <AppSelect<string>
      name={tTitle("season")}
      options={options}
      width={width}
      value={value}
      isReadOnly={readonly}
      isDisabled={isDisabled}
      onSelect={onSelect}
      isSearchable={true}
      isMultiple={false}
      isClearable={isClearable}
    />
  );
}
