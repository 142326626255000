import { IHeaderParams } from "ag-grid-community";
import { Flex } from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";

interface ProductImageHeaderProps extends IHeaderParams {
  isCollapsed: boolean;
  isDisabled?: boolean;
  onClick: (isCollapsed: boolean) => void;
}

export default function ProductImageHeader({
  isCollapsed,
  isDisabled = true,
  onClick,
}: ProductImageHeaderProps) {
  if (isDisabled) {
    return null;
  }

  return (
    <Flex
      flexDirection={"row"}
      alignItems={"end"}
      justifyContent={"end"}
      width={"100%"}
      padding={"0 4px 0 0"}
    >
      <AppIconButton
        ariaLabel={"open"}
        size={"large"}
        icon={isCollapsed ? "right" : "left"}
        onClick={() => {
          //props.columnApi.setColumnWidth(props.column, 100);
          onClick(!isCollapsed);
        }}
      />
    </Flex>
  );
}
