import {
  BalanceTransaction,
  BalanceTransactionRow,
} from "@/features/balance-transaction-status/balance-transaction.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export const balanceTransactionRowMapper = new GridMapper<
  BalanceTransaction,
  BalanceTransactionRow
>(
  "BalanceTransaction",
  (resource) => {
    const invoice =
      resource.creditInvoice ||
      resource.proformaInvoice ||
      resource.orderConfirmationInvoice;

    return {
      ...resource,
      issuedToCompany: invoice!.issuedToCompany,
      issuedByCompany: invoice!.issuedByCompany,
      issuedThroughCompany: invoice!.issuedThroughCompany,
      invoiceNumber: invoice!.number,
      issuedOn: invoice!.issuedOn,
    };
  },
  (row) => {
    //warning
    return {
      ...row,
    };
  }
);
