import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import React, { useCallback, useEffect, useMemo } from "react";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useGetDenseProformaInvoice, {
  GetDenseProformaInvoiceParameter,
  GetDenseProformaInvoiceRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-get-dense-proforma-invoice";
import useIdentity from "@/features/ui/hooks/use-identity";
import { Formik } from "formik";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { Grid } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import ProformaInvoiceForm from "@/features/invoices/proforma-invoices/proforma-invoice-form";
import { useProformaInvoiceDumbTransaction } from "@/features/invoices/proforma-invoices/hooks/use-proforma-invoice-dumb-transaction";
import { isStoredObject } from "@/features/types";
import useUpdateProformaInvoice, {
  UpdateProformaInvoiceRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-update-proforma-invoice";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import useProformaInvoiceFormValidationSchema from "@/features/invoices/proforma-invoices/hooks/use-proforma-invoice-form-validation-schema";
import useReadResource from "@/features/invoices/proforma-invoices/hooks/use-read-resource";
import useReadProformaInvoice from "@/features/invoices/proforma-invoices/hooks/use-read-proforma-invoice";
import InvoiceDetailPageHeader from "@/features/invoices/bank-remittance-receipts/invoice-detail-page-header";
import PriceUtils from "@/features/ui/utils/price-utils";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";

interface ProformaInvoiceDetailPageProps {
  isEditing?: boolean;
}

export default function ProformaInvoiceDetailPage({
  isEditing = false,
}: ProformaInvoiceDetailPageProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { proformaInvoiceId } = useRequiredParams<{
    proformaInvoiceId: number;
  }>();
  const { navigate } = useRouterHelper();

  const getDenseProformaInvoiceKey = useMemo(():
    | {
        parameter: GetDenseProformaInvoiceParameter;
        request: GetDenseProformaInvoiceRequest;
      }
    | undefined => {
    if (identity?.company && proformaInvoiceId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          proformaInvoiceId,
        },
        request: {},
      };
    }
    return undefined;
  }, [identity, proformaInvoiceId]);

  const {
    data: proformaInvoice,
    error: getProformaInvoiceError,
    mutate: mutateGetProformaInvoice,
  } = useGetDenseProformaInvoice(
    getDenseProformaInvoiceKey?.parameter,
    getDenseProformaInvoiceKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (getProformaInvoiceError) {
      showError(getProformaInvoiceError);
    }
  }, [getProformaInvoiceError, showError]);

  const initialValues = useMemo((): ProformaInvoiceFormFields => {
    if (proformaInvoice) {
      const expectedRemainingDepositAmount = PriceUtils.sum(
        proformaInvoice.orderConfirmationInvoices
          .flatMap((ocInvoice) => ocInvoice.orderConfirmationInvoiceDetailList)
          .map((ocDetail) => ocDetail.expectedRemainingDepositAmount),
        "EUR"
      );

      const clientSideProformaInvoiceDetailList: ProformaInvoiceFormFields["proformaInvoiceDetailList"] =
        proformaInvoice.proformaInvoiceDetailList.map((pDetail) => {
          let orderConfirmationDetail;

          if (pDetail.orderConfirmationInvoiceDetail) {
            const orderConfirmationInvoice =
              proformaInvoice.orderConfirmationInvoices.find((ocInvoice) => {
                return ocInvoice.orderConfirmationInvoiceDetailList.some(
                  (ocDetail) =>
                    ocDetail.id === pDetail.orderConfirmationInvoiceDetail?.id
                );
              });

            if (pDetail.orderConfirmationInvoiceDetail) {
              let expectedRemainingDepositAmount =
                pDetail.orderConfirmationInvoiceDetail
                  .expectedRemainingDepositAmount;

              // orderConfirmationDetail = {
              //   ...pDetail.orderConfirmationInvoiceDetail,
              //   expectedRemainingDepositAmount:
              //     expectedRemainingDepositAmount +
              //     (isEditing ? pDetail.usedDepositAmount : 0),
              //   orderConfirmationInvoice: orderConfirmationInvoice!,
              // };

              orderConfirmationDetail = {
                ...pDetail.orderConfirmationInvoiceDetail,
                expectedRemainingDepositAmount,
                orderConfirmationInvoice: orderConfirmationInvoice!,
              };

              if (isEditing) {
                orderConfirmationDetail.expectedRemainingDepositAmount =
                  PriceUtils.placeholder(
                    PriceUtils.sum(
                      [
                        orderConfirmationDetail.expectedRemainingDepositAmount,
                        pDetail.usedDepositAmount,
                      ],
                      "EUR"
                    ),
                    "EUR"
                  );
              }
            }
          }

          let clientSideOrderSheetSet;

          if (pDetail.orderSheetSet) {
            const orderSheetSet = proformaInvoice.orderSheetSets.find(
              (oss) => oss.id === pDetail.orderSheetSet!.id
            );

            if (orderSheetSet) {
              clientSideOrderSheetSet = {
                ...pDetail.orderSheetSet,
                lineSheetSet: {
                  id: orderSheetSet.lineSheetSet.id,
                  name: orderSheetSet.lineSheetSet.name,
                },
              };
            }
          }

          return {
            ...pDetail,
            // orderConfirmationInvoiceDetail:
            //   pDetail.orderConfirmationInvoiceDetail || undefined,
            // orderSheetSet: pDetail.orderSheetSet || undefined,
            orderConfirmationInvoiceDetail: orderConfirmationDetail,
            orderSheetSet: clientSideOrderSheetSet,
          };
        });

      PriceUtils.distribute(
        PriceUtils.sum(
          clientSideProformaInvoiceDetailList.map((pDetail) => pDetail.amount),
          "EUR"
        ),
        clientSideProformaInvoiceDetailList,
        (pDetail) => pDetail.amount,
        (pDetail, value) => (pDetail.usedCreditAmount = value),
        "EUR"
      );

      clientSideProformaInvoiceDetailList.map((pDetail) => {
        pDetail.requiredAmount = PriceUtils.minus(
          [pDetail.amount, pDetail.usedDepositAmount, pDetail.usedCreditAmount],
          "EUR"
        );
      });

      return {
        id: proformaInvoice.id,
        name: proformaInvoice.name,
        season: proformaInvoice.season,
        status: proformaInvoice.status,
        lineSheetSetType: proformaInvoice.lineSheetSetType || undefined,
        usedDeposit: proformaInvoice.usedDeposit,
        orderedBy: proformaInvoice.issuedToCompany,
        isRemittedToBoutique: proformaInvoice.isRemittedToBoutique,
        isRemittedToAgency: proformaInvoice.isRemittedToAgency,
        file: proformaInvoice.file || undefined,
        //생성과 다름, 사용된 deposit 더해줌
        expectedRemainingDepositAmount: PriceUtils.sum(
          [
            expectedRemainingDepositAmount,
            isEditing ? proformaInvoice.usedDeposit : undefined,
          ],
          "EUR"
        ),
        // expectedRemainingDepositAmount +
        // (isEditing ? proformaInvoice.usedDeposit : 0),

        issuedThrough: proformaInvoice.issuedThroughCompany,
        issuedBy: proformaInvoice.issuedByCompany,
        originallyIssuedBy: proformaInvoice.originallyIssuedByCompany,
        issuedOn: proformaInvoice.issuedOn,
        paymentMethod: proformaInvoice.paymentMethod,
        paidByAgency: proformaInvoice.paidBy === "AGENCY",
        exposedToBuyer: proformaInvoice.isPublic,
        isDetailsEditedManually:
          proformaInvoice.isDetailsEditedManually || false,
        usedDepositComment: proformaInvoice.usedDepositComment || undefined,
        usedCreditInvoices: proformaInvoice.usedCreditInvoices.map(
          (cInvoice) => {
            return {
              id: cInvoice.id,
              amount: cInvoice.amount,
              season: cInvoice.season,
            };
          }
        ),
        usedCreditComment: proformaInvoice.usedCreditComment || undefined,
        orderConfirmationInvoices:
          proformaInvoice.orderConfirmationInvoices.map((ocInvoice) => {
            return {
              ...ocInvoice,
              orderConfirmationInvoiceDetailList:
                ocInvoice.orderConfirmationInvoiceDetailList.map((ocDetail) => {
                  const pDetail =
                    proformaInvoice.proformaInvoiceDetailList.find(
                      (pDetail) => {
                        return (
                          pDetail.orderConfirmationInvoiceDetail?.id ===
                          ocDetail.id
                        );
                      }
                    );

                  return {
                    ...ocDetail,
                    expectedRemainingDepositAmount: PriceUtils.placeholder(
                      PriceUtils.sum(
                        [
                          ocDetail.expectedRemainingDepositAmount,
                          isEditing && pDetail
                            ? pDetail.usedDepositAmount
                            : undefined,
                        ],
                        "EUR"
                      ),
                      "EUR"
                    ),
                  };
                }),
            };
          }),
        orderSheetSets: proformaInvoice.orderSheetSets,
        proformaInvoiceDetailList: clientSideProformaInvoiceDetailList,
        childProformaInvoices: proformaInvoice.childProformaInvoices.map(
          (pInvoice) => {
            return {
              id: pInvoice.id,
              orderConfirmationInvoices: pInvoice.orderConfirmationInvoices.map(
                (ocInvoice) => {
                  return {
                    id: ocInvoice.id,
                  };
                }
              ),
              orderSheetSets: pInvoice.orderSheetSets.map((oss) => {
                return {
                  id: oss.id,
                };
              }),
            };
          }
        ),
        parentProformaInvoices: proformaInvoice.parentProformaInvoices.map(
          (pInvoice) => {
            return {
              id: pInvoice.id,
            };
          }
        ),

        number: proformaInvoice.number || undefined,
        privateComment: proformaInvoice.privateComment || undefined,
        publicComment: proformaInvoice.publicComment || undefined,
        earnedCreditInvoices: proformaInvoice.earnedCreditInvoices,
        attachments: proformaInvoice.attachments,
        payments: proformaInvoice.payments,
      };
    } else {
      return {
        isDetailsEditedManually: false,
        proformaInvoiceDetailList: [],
        orderSheetSets: [],
        orderConfirmationInvoices: [],
        usedCreditInvoices: [],
        exposedToBuyer: identity?.company?.type !== "BOUTIQUE",
        paidByAgency: false,
        issuedOn: new Date(),
        childProformaInvoices: [],
        parentProformaInvoices: [],
        earnedCreditInvoices: [],
        payments: [],
        attachments: [],
      };
    }
  }, [proformaInvoice, isEditing, identity?.company?.type]);

  const validateSchema = useProformaInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: ProformaInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const {
    fire: fireUpdateProformaInvoice,
    error: updateProformaInvoiceError,
    clear: clearUpdateProformaInvoice,
  } = useUpdateProformaInvoice();

  useEffect(() => {
    if (updateProformaInvoiceError) {
      showError(updateProformaInvoiceError);
    }
  }, [updateProformaInvoiceError, showError]);

  const { isUpdateQueued, handleSubmit } = useDumbMixedUpdate({
    dumbFunction: useProformaInvoiceDumbTransaction,
    smartFunction: useUpdateProformaInvoice,
    isSmart: (request): request is UpdateProformaInvoiceRequest => {
      return (
        request.file &&
        isStoredObject(request.file) &&
        request.attachments.every(isStoredObject)
      );
    },
    toRequest: (values: ProformaInvoiceFormFields) => {
      const totalUsedCredit = PriceUtils.sum(
        values.usedCreditInvoices.map((ocInvoice) => ocInvoice.amount),
        "EUR"
      );

      const totalAmount = PriceUtils.sum(
        values.proformaInvoiceDetailList.map((pDetail) => pDetail.amount),
        "EUR"
      );
      const totalUsedDeposit = PriceUtils.placeholder(
        values.usedDeposit,
        "EUR"
      );

      return {
        id: values.id!,
        number: values.number!,
        season: values.season!,
        name: values.name!,
        status: values.status!,
        lineSheetSetType: values.lineSheetSetType || null,
        issuedToCompany: values.orderedBy!,
        issuedByCompany: values.issuedBy!,
        issuedThroughCompany: values.issuedThrough!,
        issuedOn: values.issuedOn!,
        paymentMethod: values.paymentMethod!,
        paidBy: values.paidByAgency ? ("AGENCY" as const) : ("BUYER" as const),
        isPublic: values.exposedToBuyer,
        publicComment: values.publicComment || null,
        privateComment: values.privateComment || null,
        usedCreditInvoices: values.usedCreditInvoices.map((cInvoice) => {
          return {
            id: cInvoice.id,
          };
        }),
        orderSheetSets: values.orderSheetSets.map((oss) => {
          return {
            id: oss.id,
          };
        }),
        orderConfirmationInvoices: values.orderConfirmationInvoices.map(
          (ocInvoice) => {
            return {
              id: ocInvoice.id,
            };
          }
        ),
        usedDeposit: PriceUtils.placeholder(values.usedDeposit, "EUR"),
        usedDepositComment: values.usedDepositComment || null,
        usedCredit: PriceUtils.placeholder(totalUsedCredit, "EUR"),
        usedCreditComment: values.usedCreditComment || null,
        isDetailsEditedManually: values.isDetailsEditedManually,
        totalAmount: PriceUtils.placeholder(totalAmount, "EUR"),
        totalRequiredAmount: PriceUtils.placeholder(
          PriceUtils.minus(
            [totalAmount, totalUsedCredit, totalUsedDeposit],
            "EUR"
          ),
          "EUR"
        ),
        proformaInvoiceDetailList: values.proformaInvoiceDetailList.map(
          (pDetail) => {
            return {
              id: pDetail.id,
              amount: PriceUtils.placeholder(pDetail.amount, "EUR"),
              usedDepositAmount: PriceUtils.placeholder(
                pDetail.usedDepositAmount,
                "EUR"
              ),
              brand: pDetail.brand!,
              orderSheetSet: pDetail.orderSheetSet || null,
              orderConfirmationInvoiceDetail:
                pDetail.orderConfirmationInvoiceDetail || null,
            };
          }
        ),
        originallyIssuedByCompany: values.originallyIssuedBy!,
        childProformaInvoices: values.childProformaInvoices.map((pInvoice) => {
          return {
            id: pInvoice.id,
          };
        }),
        file: values.file!,
        attachments: values.attachments,
      };
    },
    mutate: mutateGetProformaInvoice,
    defaultRedirectUrl: `/proforma-invoices/${proformaInvoiceId}`,
  });

  useReadResource({
    ywrFunc: useReadProformaInvoice,
    resource: proformaInvoice,
    when: () => identity?.company?.type === "BUYER",
    mutate: mutateGetProformaInvoice,
  });

  return (
    <Formik<ProformaInvoiceFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          return handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              proformaInvoiceId: values.id!,
            },
            values,
            formikHelpers
          );
        } else {
          return false;
        }
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <InvoiceDetailPageHeader
              formik={props}
              isUpdateQueued={
                identity?.company && props.values.id
                  ? isUpdateQueued({
                      by: identity.company.type,
                      companyId: identity.company.id,
                      proformaInvoiceId: props.values.id,
                    })
                  : false
              }
              title={tTitle("proforma_invoice")}
              baseUrl={"/proforma-invoices"}
              isEditing={isEditing}
            />

            <ProformaInvoiceForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
