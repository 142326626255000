import { IdentityParameter, PageNumberBasedListRequest, PageNumberBasedListResponse } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";
import { Identity } from "@/features/ui/hooks/use-identity";
import { isIdentity, toIdentityParameter } from "@/features/ui/helpers/identity-helpers";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { Gmail } from "@/features/gmails/gmail.types";

export interface ListGmailsRequest extends PageNumberBasedListRequest {}

export interface ListGmailsResponse extends PageNumberBasedListResponse {
  gmails: Gmail[];
}

export interface ListGmailsPathParameter extends IdentityParameter {}

export function getListGmailsParameter(
  identity: Identity | null
): ListGmailsPathParameter | null {
  return toIdentityParameter(identity);
}

export const listGmailsTemplateUrl = (() => {
  let url = "";
  url += "/gmails";
  return url;
})();

export function getListGmailsKey(
  identityOrParameter?: Identity | ListGmailsPathParameter | null,
  request?: ListGmailsRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListGmailsParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    return {
      url: calcUrl(listGmailsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListGmails(
  parameter?: ListGmailsPathParameter | null,
  request?: ListGmailsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListGmailsRequest,
    ListGmailsResponse
  >();
  return useSWR(getListGmailsKey(parameter, request), fetcher, config);
}
