import {
  reportLineSheetSetKey,
  ReportLineSheetSetRequest,
  ReportLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-report-line-sheet-set";
import useFetcher from "@/utils/use-fetcher";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useReportLineSheetSetInfinite() {
  const fetcher = useFetcher<
    ReportLineSheetSetRequest,
    ReportLineSheetSetResponse
  >();
  return useYWRInfinite(reportLineSheetSetKey, fetcher);
}
