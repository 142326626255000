import {
  Flex,
  Grid,
  GridItem,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";

interface AppTooltipLabelProps {
  header: string;
  body: string;
  instructions: string[];
}

export default function AppInstructionTooltipLabel({
  header,
  body,
  instructions,
}: AppTooltipLabelProps) {
  return (
    <Grid
      gridTemplateColumns={"1fr"}
      gridTemplateRows={"32px 1fr"}
      borderRadius={"0px 4px 4px 4px"}
      background={"#444440"}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.25)"}
      color={"#FFFFFF"}
      width={"320px"}
    >
      <Flex
        gridColumn={"1/2"}
        borderRadius={"0 8px 0 0 0"}
        borderBottom={"0.5px solid #FCFCFB"}
        fontWeight={"700"}
        padding={"8px 12px"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Text flexGrow={"1"}>{header}</Text>
        <AppIconButton ariaLabel={"close"} icon={"x"} size={"small"} />
      </Flex>
      <Flex
        gridColumn={"1/2"}
        gridRow={"2/3"}
        borderRadius={"0 0 8px 8px"}
        padding={"8px 12px"}
        flexDirection={"column"}
      >
        {body}
        <OrderedList>
          {instructions.map((instruction, index) => {
            return (
              <ListItem key={`Instruction_` + index}>{instruction}</ListItem>
            );
          })}
        </OrderedList>
      </Flex>
    </Grid>
  );
}
