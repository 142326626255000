import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { ListLineSheetSetsRequest } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useSWR, { SWRConfiguration } from "swr";
import useListDenseLineSheetSetsFetcher from "@/features/line-sheet-sets/hooks/use-list-dense-line-sheet-sets-fetcher";
import { DenseLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseLineSheetSetsRequest
  extends ListLineSheetSetsRequest {}
export interface ListDenseLineSheetSetsResponse
  extends PageNumberBasedListResponse {
  lineSheetSets: DenseLineSheetSet[];
}

export interface ListDenseLineSheetSetsPathParameter
  extends IdentityParameter {}

export const listDenseLineSheetSetsTemplateUrl = `/:by/:companyId/dense-line-sheet-sets`;

export function getListDenseLineSheetSetsKey(
  parameter?: ListDenseLineSheetSetsPathParameter,
  request?: ListDenseLineSheetSetsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseLineSheetSetsTemplateUrl, parameter),
      params: request,
    };
  }
}

export function parseListDenseLineSheetSetsKey(
  config: AxiosRequestConfig<ListDenseLineSheetSetsRequest>
) {
  const urlParser = new UrlParser<ListDenseLineSheetSetsPathParameter>(
    listDenseLineSheetSetsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(config.url!),
    request: config.params,
  };
}

export function readDenseLineSheetSetProactively(lineSheetSetId: number) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-line-sheet-sets/,
    "lineSheetSets",
    lineSheetSetId
  );
}

export default function useListDenseLineSheetSets(
  parameter?: ListDenseLineSheetSetsPathParameter,
  request?: ListDenseLineSheetSetsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useListDenseLineSheetSetsFetcher();

  return useSWR(
    getListDenseLineSheetSetsKey(parameter, request),
    fetcher,
    config
  );
}
