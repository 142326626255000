import { FieldProps } from "formik";
import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";
import { useEffect, useState } from "react";
import { TransportationStatuses } from "@/features/transportations/transportation.type";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useTransportationFormValidationSchema from "@/features/transportations/hooks/use-transportation-form-validation";
import useAppToasts, {
  isValidationError,
} from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useWithSWROnDemand from "@/features/transportations/hooks/use-with-swr-on-demand";
import useGetFlight from "@/features/transportations/hooks/use-get-flight";
import { DateUtils } from "@/features/ui/utils/date-utils";
import { isErrorResponse } from "@/utils/use-fetcher";

interface FlightSearchButtonProps {
  fieldProps: FieldProps<
    TransportationFormFields["flightNumber"],
    TransportationFormFields
  >;
  isEditing?: boolean;
}

export default function FlightSearchButton({
  fieldProps,
  isEditing,
}: FlightSearchButtonProps) {
  const { tTitle, t } = useI18nHelper();
  const validateSchema = useTransportationFormValidationSchema();
  const [disabledMessage, setDisabledMessage] = useState<string>();
  const { error: showError, warning: showWarning } = useAppToasts();

  useEffect(() => {
    //validateSchema를 안써서 formik의 buil-in validateField를 사용 못함;
    async function validateYupFields() {
      try {
        const yupFields = [
          "flightNumber",
          "flightDepartureAirport",
          "flightEtd",
        ];
        await Promise.all(
          yupFields.map((field) => {
            return validateSchema.validateAt(field, fieldProps.form.values);
          })
        );
        setDisabledMessage(undefined);
      } catch (error) {
        if (isValidationError(error) && error.errors.length > 0) {
          setDisabledMessage(error.errors[0]);
        }
      }
    }

    const status = fieldProps.form.initialValues.status;
    if (
      status &&
      TransportationStatuses.indexOf("FLIGHT_DEPARTED") <=
        TransportationStatuses.indexOf(status)
    ) {
      setDisabledMessage(t("flight_change_disabled_after_departure"));
    } else {
      validateYupFields();
    }
  }, [
    t,
    fieldProps.form.initialValues.status,
    fieldProps.form.values,
    validateSchema,
  ]);

  const {
    fire: fireGetFlight,
    data: getFlight,
    isLoading: isGetFlightLoading,
    isValidating: isGetFlightValidating,
    error: getFlightError,
  } = useWithSWROnDemand(useGetFlight);

  useEffect(() => {
    if (getFlightError) {
      if (isErrorResponse(getFlightError)) {
        const error = getFlightError.error;
        if (error.code === 404) {
          showWarning(t("get_flight.404"));
        } else {
          showError(error);
        }
      }
    }
  }, [getFlightError, showError, showWarning, t]);

  useEffect(() => {
    if (getFlight) {
      fieldProps.form.setValues((prev) => {
        return {
          ...prev,
          flightEtd: DateUtils.toDate(getFlight.etd),
          flightEta: DateUtils.toDate(getFlight.eta),
          flightAta: getFlight.ata
            ? DateUtils.toDate(getFlight.ata)
            : undefined,
          flightAtd: getFlight.atd
            ? DateUtils.toDate(getFlight.atd)
            : undefined,
          flightDepartureAirport: getFlight.departureAirport,
          flightArrivalAirport: getFlight.arrivalAirport,
        };
      });
    }
  }, [fieldProps.form, getFlight]);

  return (
    <AppButton
      width={"88px"}
      disabledMessage={disabledMessage}
      isDisabled={disabledMessage !== undefined}
      isLoading={isGetFlightLoading || isGetFlightValidating}
      onClick={() => {
        fireGetFlight(
          {},
          {
            number__eq: fieldProps.form.values.flightNumber!,
            etd__eq: DateUtils.toDateTimeString(
              fieldProps.form.values.flightEtd!
            ),
            departureAirport__eq:
              fieldProps.form.values.flightDepartureAirport!,
          }
        );
      }}
    >
      {tTitle("search")}
    </AppButton>
  );
}
