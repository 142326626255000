import { Transportation } from "@/features/transportations/transportation.type";
import { IdentityParameter } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface GetTransportationParameter extends IdentityParameter {
  transportationId: number;
}

export interface GetTransportationRequest {}

export interface GetTransportationResponse extends Transportation {}

const getTransportationTemplateUrl =
  "/:by/:companyId/transportations/:transportationId";

export function getTransportationKey(
  parameter?: GetTransportationParameter,
  request?: GetTransportationRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getTransportationTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useGetTransportation(
  parameter?: GetTransportationParameter,
  request?: GetTransportationRequest,
  config?: SWRConfiguration<GetTransportationResponse>
) {
  const fetcher = useFetcher<
    GetTransportationRequest,
    GetTransportationResponse
  >();

  return useSWR(getTransportationKey(parameter, request), fetcher, config);
}
