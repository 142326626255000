import {
  CompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { Payment } from "@/features/invoices/payment/payment.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export interface ListPaymentsRequest extends PageNumberBasedListRequest {
  "sender.id__eq"?: number;
  "receiver.id__eq"?: number;
  season__eq?: string;
  season__in?: string[];
  isRemitted__eq?: boolean;
  createdOn__eq?: Date;
  id__in?: number[];
  status__eq?: AbstractInvoice["status"];
  "issuedByCompany.id__eq"?: number;
  "issuedByCompany.id__in"?: number[];
  "issuedThroughCompany.id__eq"?: number;
  "issuedToCompany.id__eq"?: number;
  status__in?: AbstractInvoice["status"][];
  "receiver.type__eq"?: CompanyType;
  "sender.type__eq"?: CompanyType;
  orderConfirmationInvoices__exists?: boolean;
  proformaInvoices__exists?: boolean;
}

export interface ListPaymentsPathParameter {
  by: CompanyType;
  companyId: number;
}

export interface ListPaymentsResponse extends PageNumberBasedListResponse {
  payments: Payment[];
}

export const listPaymentsTemplateUrl = `/:by/:companyId/payments`;

export function getListPaymentsKey(
  parameter?: ListPaymentsPathParameter,
  request?: ListPaymentsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listPaymentsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export const DEFAULT_LIST_PAYMENTS_PAGE_SIZE = 10;

export function useListPayments(
  parameter?: ListPaymentsPathParameter,
  request?: ListPaymentsRequest,
  config?: SWRConfiguration<ListPaymentsResponse>
) {
  const fetcher = useFetcher<ListPaymentsRequest, ListPaymentsResponse>();

  return useSWR(getListPaymentsKey(parameter, request), fetcher, config);
}
