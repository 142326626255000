import { FormikProps } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import BinarySplitView from "@/features/ui/binary-split-view";
import OrderConfirmationInvoiceInformationFormControl from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-information-form-control";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import OrderConfirmationInvoiceFigureFormControl from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-figure-form-control";
import LinkedOrderSheetSetsFormControl from "@/features/invoices/order-confirmation-invoices/linked-order-sheet-sets-form-control";
import React, { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import IsolatedProformaInvoicePanel from "@/features/invoices/order-confirmation-invoices/isolated-proforma-invoice-panel";
import { ListAllDenseOrderSheetSetsRequest } from "@/features/order-sheet-sets/hooks/use-list-all-dense-order-sheet-sets";
import { AppUtils } from "@/features/ui/utils/app-utils";
import IsolatedCreditInvoicePanel from "@/features/invoices/credit-invoices/isolated-credit-invoice-panel";
import IsolatedDepositInvoicePanel from "@/features/invoices/deposit-invoices/isolated-deposit-invoice-panel";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import useIdentity from "@/features/ui/hooks/use-identity";
import IsolatedPaymentPanel from "@/features/invoices/payment/isolated-payment-panel";
import useSyncOrderConfirmationInvoiceDetail from "@/features/invoices/order-confirmation-invoices/hooks/use-sync-order-confirmation-invoice-detail";

interface OrderConfirmationInvoiceFormProps {
  formik: FormikProps<OrderConfirmationInvoiceFormFields>;
  isEditing?: boolean;
}

export default function OrderConfirmationInvoiceForm({
  formik,
  isEditing = false,
}: OrderConfirmationInvoiceFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listOrderSheetSetsRequest = useMemo(():
    | ListAllDenseOrderSheetSetsRequest
    | undefined => {
    if (
      formik.values.season &&
      formik.values.orderedBy &&
      formik.values.issuedBy &&
      formik.values.issuedThrough
    ) {
      return {
        "lineSheetSet.season__eq": formik.values.season,
        status__in: ["BOUTIQUE_CONFIRMED"],
        "createdByCompany.id__in": [formik.values.orderedBy.id],
        // "lineSheetSet.issuedByCompany.id__in": [issuedBy.id],
        "orderSheet.toBeSubmitted.id__eq": formik.values.issuedBy.id,
        "lineSheetSet.createdByCompany.id__eq": formik.values.issuedThrough.id,
      };
    }

    return undefined;
  }, [
    formik.values.issuedBy,
    formik.values.issuedThrough,
    formik.values.orderedBy,
    formik.values.season,
  ]);

  const isDisabled =
    isEditing &&
    (formik.values.payments.some((payment) =>
        payment.sender.id === formik.values.orderedBy?.id && payment.status === "NORMAL"
    ) ||
      formik.values.status !== "NORMAL");
  const identity = useIdentity();

  useSyncOrderConfirmationInvoiceDetail({ isEditing });

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <OrderConfirmationInvoiceInformationFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
            <InvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <OrderConfirmationInvoiceFigureFormControl
                formik={formik}
                isEditing={isEditing}
                isDisabled={isDisabled}
              />
            )}

            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <LinkedOrderSheetSetsFormControl
                formik={formik}
                isEditing={isEditing}
                isDisabled={isDisabled}
                request={listOrderSheetSetsRequest}
                onSelect={(value) => {
                  formik.setValues((prev) => {
                    return {
                      ...prev,
                      orderSheetSets: value,
                    };
                  });
                }}
              />
            )}

            {!isEditing && (
              <IsolatedProformaInvoicePanel
                proformaInvoiceIds={AppUtils.extractIds(
                  formik.values.proformaInvoices
                )}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedPaymentPanel
                paymentIds={AppUtils.extractIds(formik.values.payments)}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedCreditInvoicePanel
                creditInvoiceIds={AppUtils.extractIds(
                  formik.values.creditInvoices
                )}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedDepositInvoicePanel
                depositInvoiceIds={AppUtils.extractIds([
                  formik.values.depositInvoice,
                ])}
              />
            )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={200}
      />
    </Box>
  );
}
