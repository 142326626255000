import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheet, LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";

export interface DeleteOrderSheetSetRequest {
  orderSheetIds: number[];
}

export interface DeleteOrderSheetSetResponse {}

export interface DeleteOrderSheetSetsPathParameter {
  buyerId: number;
  orderSheetSetId: number;
}

export const deleteOrderSheetSetKey: YWRKey<DeleteOrderSheetSetsPathParameter> =
  {
    url: (parameter) => {
      const { buyerId, orderSheetSetId } = parameter;
      return `/buyers/${buyerId}/order-sheet-sets/${orderSheetSetId}`;
    },
    method: "delete",
  };

export default function useDeleteOrderSheetSet() {
  const fetcher = useFetcher<
    DeleteOrderSheetSetRequest,
    DeleteOrderSheetSetResponse
  >();

  return useYWR(deleteOrderSheetSetKey, fetcher);
}
