import useFetcher from "@/utils/use-fetcher";
import {
  reportLineSheetKey,
  ReportLineSheetRequest,
  ReportLineSheetResponse,
} from "@/features/line-sheet-sets/hooks/use-report-line-sheet";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useReportLineSheetInfinite() {
  const fetcher = useFetcher<ReportLineSheetRequest, ReportLineSheetResponse>();
  return useYWRInfinite(reportLineSheetKey, fetcher);
}
