import { ICellRendererParams } from "ag-grid-community";
import { Flex } from "@chakra-ui/react";
import { FlatProduct } from "@/features/line-sheet-sets/helpers/sheet-state";
import RepresentativeProductImage, {
  MAX_PRODUCT_IMAGE_COUNT,
} from "@/features/order-sheet-sets/representative-product-image";
import ProductImage from "@/features/order-sheet-sets/product-image";

interface ProductImageCellProps
  extends ICellRendererParams<FlatProduct, FlatProduct["imageUrls"]> {
  isCollapsed: boolean;
}

export const PRODUCT_IMAGE_SIZE = 74;

export default function ProductImageCell({
  rowIndex,
  value,
  node,
  isCollapsed,
  api,
}: ProductImageCellProps) {
  if (rowIndex % 3 === 0) {
    return (
      <Flex
        position={"relative"}
        boxSize={`${PRODUCT_IMAGE_SIZE}px`}
        gap={"4px"}
        zIndex={"3"}
        alignItems={"center"}
        width={"100%"}
      >
        {value && isCollapsed && <RepresentativeProductImage value={value} />}
        {value &&
          !isCollapsed &&
          value.slice(0, MAX_PRODUCT_IMAGE_COUNT).map((item, index) => {
            return <ProductImage key={index} value={item} />;
          })}
      </Flex>
    );
  }
  return null;
}
