import { Outlet, useParams } from "react-router";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import { useRecoilValue } from "recoil";
import { appToastsState } from "@/features/line-sheet-sets/recoils/app-toasts-state";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import { useEffect } from "react";
import AppDialog from "@/features/ui/app-dialog";
import { useTranslation } from "react-i18next";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function AppLayout() {
  const appToasts = useRecoilValue(appToastsState);
  const { remove: removeAppToast } = useAppToasts();
  const toast = useToast();
  const { dialogs, closeDialog } = useAppDialog();

  const { locale: rawLocale } = useParams();
  const { i18n } = useTranslation();
  const { t } = useI18nHelper();

  const location = useLocation();

  useEffect(() => {
    const locale = rawLocale || "en";

    if (locale !== i18n.language) {
      i18n.changeLanguage(locale);
    }
  }, [rawLocale, i18n]);

  useEffect(() => {
    if (appToasts.length > 0) {
      appToasts.forEach((appToast) => {
        const option: Omit<UseToastOptions, "id"> = {
          title: appToast.message,
          status: appToast.status,
          duration: 5000,
          position: "top",
          isClosable: true,
          onCloseComplete: () => {
            removeAppToast(appToast.id);
          },
        };

        if (toast.isActive(appToast.id)) {
          toast.update(appToast.id, option);
        } else {
          toast({
            id: appToast.id,
            ...option,
          });
        }
      });
    }
  }, [toast, appToasts, removeAppToast]);

  useEffect(() => {
    toast.closeAll();
  }, [toast, location.pathname]);

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:site_name" content={t("common:meta.site_name")} />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
        <meta
          property="twitter:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      {dialogs.map((dialog) => {
        return <AppDialog key={dialog.id} {...dialog} />;
      })}
      <Outlet />{" "}
    </>
  );
}
