import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import PriceUtils from "@/features/ui/utils/price-utils";
import { isEqual } from "lodash";

interface UseSyncOrderConfirmationInvoiceDetailProps {
  isEditing: boolean;
}

export default function useSyncOrderConfirmationInvoiceDetail({
  isEditing,
}: UseSyncOrderConfirmationInvoiceDetailProps) {
  const { values, setValues, setFieldValue } =
    useFormikContext<OrderConfirmationInvoiceFormFields>();

  useEffect(() => {
    if (isEditing) {
      const totalRequiredAmount = values.totalRequiredAmount;
      setValues((prev) => {
        return {
          ...prev,
          orderConfirmationInvoiceDetailList: PriceUtils.distribute(
            totalRequiredAmount,
            prev.orderConfirmationInvoiceDetailList,
            (item) => item.amount,
            (item, value) => {
              item.requiredAmount = value;
            },
            "EUR"
          ),
        };
      });
    }
  }, [isEditing, setValues, values.totalRequiredAmount]);

  useEffect(() => {
    if (isEditing) {
      const detailList = values.orderConfirmationInvoiceDetailList;
      setValues((prev) => {
        let updatedDetailList = [...prev.orderConfirmationInvoiceDetailList];

        updatedDetailList = PriceUtils.distribute(
          prev.totalRequiredAmount,
          updatedDetailList,
          (item) => item.amount,
          (item, value) => {
            item.requiredAmount = value;
          },
          "EUR"
        );

        if (isEqual(updatedDetailList, detailList)) {
          return prev;
        } else {
          return {
            ...prev,
            orderConfirmationInvoiceDetailList: updatedDetailList,
          };
        }
      });
    }
  }, [isEditing, setValues, values.orderConfirmationInvoiceDetailList]);

  useEffect(() => {
    if (isEditing) {
      const orderSheetSets = values.orderSheetSets;
      setValues((prev) => {
        return {
          ...prev,
          orderConfirmationInvoiceDetailList:
            prev.orderConfirmationInvoiceDetailList.map((detail) => {
              if (detail.orderSheetSet === undefined) {
                return detail;
              } else if (
                orderSheetSets
                  .map((oss) => oss.id)
                  .includes(detail.orderSheetSet.id)
              ) {
                return detail;
              } else {
                return {
                  ...detail,
                  orderSheetSet: undefined,
                };
              }
            }),
        };
      });
    }
  }, [isEditing, setValues, values.orderSheetSets]);
}
