import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useCallback } from "react";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import { Buyer, Company, isFile, StoredObject } from "@/features/types";
import {
  TransportationDetail,
  TransportationStatus,
} from "@/features/transportations/transportation.type";
import TransportationForm from "@/features/transportations/transportation-form";
import useTransportationFormValidationSchema from "@/features/transportations/hooks/use-transportation-form-validation";
import { useTransportationDumbTransaction } from "@/features/transportations/hooks/use-transportation-dumb-transaction";
import AppSubmitButton from "@/features/line-sheet-sets/app-submit-button";
import { DateUtils } from "@/features/ui/utils/date-utils";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

export interface TransportationFormFields {
  id?: number;
  name?: string;
  status?: TransportationStatus;
  shippedFromList: Company[];
  shippedTo?: Buyer;
  shippedToAddress?: string;
  forwarderInvoiceNumbers: string[];

  houseBlNumbers: string[];

  flightNumber?: string;
  flightDepartureAirport?: string;
  flightArrivalAirport?: string;
  flightEtd?: Date;
  flightEta?: Date;
  flightAtd?: Date;
  flightAta?: Date;
  finalEta?: Date;

  cost?: number;
  details: TransportationDetail[];
  files: (File | Pick<StoredObject, "id" | "name">)[];

  proformaInvoices: Pick<
    ProformaInvoice,
    "id" | "number" | "issuedByCompany" | "issuedToCompany"
  >[];
}

export default function UploadTransportationPage() {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const validateSchema = useTransportationFormValidationSchema();

  const validate = useCallback(
    async (values: TransportationFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [showError, validateSchema]
  );

  const { addCreate } = useTransportationDumbTransaction();

  const handleSubmit = useCallback(
    async (values: TransportationFormFields) => {
      if (identity?.company?.type === "AGENCY") {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            shippedFromList: values.shippedFromList,
            shippedTo: values.shippedTo!,
            shippedToAddress: values.shippedToAddress!,
            forwarderInvoiceNumbers: values.forwarderInvoiceNumbers,
            houseBlNumbers: values.houseBlNumbers,
            flightNumber: values.flightNumber!,
            flightDepartureAirport: values.flightDepartureAirport!,
            flightArrivalAirport: values.flightArrivalAirport!,
            flightEtd: DateUtils.toDateTimeString(values.flightEtd!),
            flightEta: DateUtils.toDateTimeString(values.flightEta!),
            flightAtd: values.flightAtd
              ? DateUtils.toDateTimeString(values.flightAtd)
              : null,
            flightAta: values.flightAta
              ? DateUtils.toDateTimeString(values.flightAta)
              : null,
            finalEta: values.finalEta!,
            cost: values.cost || 0,
            proformaInvoices: values.proformaInvoices.map((pInvoice) => {
              return {
                id: pInvoice.id,
              };
            }),
            files: values.files.filter(isFile),
          }
        );

        return true;
      }
      return false;
    },
    [addCreate, identity]
  );

  return (
    <Formik<TransportationFormFields>
      initialValues={{
        id: -1,
        shippedFromList: [],
        forwarderInvoiceNumbers: [""],
        houseBlNumbers: [""],
        details: [],
        files: [],
        proformaInvoices: [],
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={(values) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"header"} as={"h2"} fontSize={"14px"}>
                  {tTitle("upload_transportation")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppSubmitButton
                  formik={props}
                  key={"upload_and_add_another"}
                  addOther={true}
                />,
                <AppSubmitButton formik={props} key={"upload"} />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/transportations"}
              blockNavigateToBack={props.dirty}
            />
            <TransportationForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
