import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect, useMemo } from "react";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useListAllBankRemittanceReceipts, {
  ListAllBankRemittanceReceiptsPathParameter,
  ListAllBankRemittanceReceiptsRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-list-all-bank-remittance-receipts";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";

interface AppBankRemittanceReceiptsExclusiveSelectorProps {
  request?: ListAllBankRemittanceReceiptsRequest;
  id?: number | number[];
  onSelect: (value: BankRemittanceReceipt[]) => void;
  isDisabled?: boolean;
  isMultiple?: boolean;
}

export default function AppBankRemittanceReceiptsExclusiveSelector({
  request,
  id,
  onSelect,
  isDisabled,
  isMultiple = true,
}: AppBankRemittanceReceiptsExclusiveSelectorProps) {
  const { tTitle, tCurrencyString } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();

  const listDenseBankRemittanceReceiptsKey = useMemo(() => {
    if (identity?.company && request) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const {
    isLoading: isListAllBankRemittanceReceiptsLoading,
    data: listAllBankRemittanceReceiptsData,
    error: listAllBankRemittanceReceiptsError,
  } = useListAllBankRemittanceReceipts(
    listDenseBankRemittanceReceiptsKey?.parameter,
    listDenseBankRemittanceReceiptsKey?.request
  );

  useEffect(() => {
    if (listAllBankRemittanceReceiptsError) {
      showError(listAllBankRemittanceReceiptsError);
    }
  }, [listAllBankRemittanceReceiptsError, showError]);

  const notFetchedIds = useMemo(() => {
    if (listAllBankRemittanceReceiptsData && id) {
      const ids = Array.isArray(id) ? id : [id];
      const fetchedIds = listAllBankRemittanceReceiptsData.map((i) => i.id);
      return ids.filter((i) => !fetchedIds.includes(i));
    }
    return [];
  }, [listAllBankRemittanceReceiptsData, id]);

  const notFetchedKey = useMemo(():
    | {
        parameter: ListAllBankRemittanceReceiptsPathParameter;
        request: ListAllBankRemittanceReceiptsRequest;
      }
    | undefined => {
    if (identity?.company && notFetchedIds.length > 0) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: notFetchedIds,
        },
      };
    }

    return undefined;
  }, [identity, notFetchedIds]);

  const {
    data: notFetchedBankRemittanceReceipts,
    error: notFetchedBankRemittanceReceiptsError,
  } = useListAllBankRemittanceReceipts(
    notFetchedKey?.parameter,
    notFetchedKey?.request
  );

  useEffect(() => {
    if (notFetchedBankRemittanceReceiptsError) {
      showError(notFetchedBankRemittanceReceiptsError);
    }
  }, [notFetchedBankRemittanceReceiptsError, showError]);

  const options = useMemo(() => {
    return [
      ...(listAllBankRemittanceReceiptsData || []),
      ...(notFetchedBankRemittanceReceipts || []),
    ];
  }, [listAllBankRemittanceReceiptsData, notFetchedBankRemittanceReceipts]);

  const values = useMemo(() => {
    if (id) {
      const ids = Array.isArray(id) ? id : [id];
      return options.filter((i) => {
        return ids.includes(i.id);
      });
    }
    return [];
  }, [id, options]);

  return (
    <AppExclusiveSelect<BankRemittanceReceipt>
      name={"bank_remittance_receipt"}
      values={values}
      options={options}
      optionFunc={(i) => {
        return {
          value: i,
          name: `${i.id} / ${i.name} (${tTitle("amount")} : ${tCurrencyString(
            i.amount
          )})`,
        };
      }}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isLoading={isListAllBankRemittanceReceiptsLoading}
      isMultiple={isMultiple}
    />
  );
}
