import { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import { DepositInvoiceFormFields } from "@/features/invoices/deposit-invoices/upload-deposit-invoice-upload-page";
import { FormikProps } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import BinarySplitView from "@/features/ui/binary-split-view";
import DepositInvoiceInformationFormControl from "@/features/invoices/deposit-invoices/deposit-invoice-information-form-control";
import LinkedBankRemittanceReceiptFormControl from "@/features/invoices/bank-remittance-receipts/linked-bank-remittance-receipt-form-control";
import SimpleFigureFormControl from "@/features/invoices/deposit-invoices/simple-figure-form-control";
import IsolatedOrderConfirmationInvoicePanel from "@/features/invoices/proforma-invoices/isolated-order-confirmation-invoice-panel";
import { ListAllBankRemittanceReceiptsRequest } from "@/features/invoices/bank-remittance-receipts/hooks/use-list-all-bank-remittance-receipts";

interface DepositInvoiceFormProps {
  formik: FormikProps<DepositInvoiceFormFields>;
  isEditing?: boolean;
}

export default function DepositInvoiceForm({
  formik,
  isEditing = false,
}: DepositInvoiceFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listBankRemittanceReceiptsRequest = useMemo(() => {
    if (
      formik.values.season &&
      formik.values.issuedBy &&
      formik.values.orderedBy &&
      formik.values.issuedThrough
    ) {
      const request: ListAllBankRemittanceReceiptsRequest = {
        season__eq: formik.values.season,
        "issuedByCompany.id__eq": formik.values.issuedBy.id,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        "issuedThroughCompany.id__eq": formik.values.issuedThrough.id,
        depositInvoice__isNull: true,
        "payments.orderConfirmationInvoices__exists": true,
        "receiver.type__eq": "BOUTIQUE",
      };
      return request;
    }
  }, [
    formik.values.issuedBy,
    formik.values.issuedThrough,
    formik.values.orderedBy,
    formik.values.season,
  ]);

  const isDisabled = isEditing && formik.values.id !== -1;

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <DepositInvoiceInformationFormControl
              formik={formik}
              isEditing={isEditing}
            />
            <InvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            <SimpleFigureFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isEditing}
            />

            <LinkedBankRemittanceReceiptFormControl
              formik={formik}
              isMultiple={false}
              isEditing={isEditing}
              isDisabled={isDisabled}
              request={listBankRemittanceReceiptsRequest}
              onSelect={(value) => {
                formik.setValues((prev) => {
                  const next = {
                    ...prev,
                    bankRemittanceReceipt:
                      value.length > 0 ? value[0] : undefined,
                  };

                  next.amount = next.bankRemittanceReceipt?.amount;

                  return next;
                });
              }}
            />
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={400}
      />
    </Box>
  );
}
