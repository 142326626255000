import { Box, Flex, Text } from "@chakra-ui/react";
import GoogleSocialButton from "@/features/auth/social-buttons/google-social-button";
import FacebookSocialButton from "@/features/auth/social-buttons/facebook-social-button";
import KakaoSocialButton from "@/features/auth/social-buttons/kakao-social-button";

export default function SocialButtonGroup({ name }: { name: string }) {
  return (
    <Box>
      <Text
        fontSize="sm"
        color="gray"
        opacity="0.9"
        textAlign="center"
        margin="-8px 0 4px 0"
      >
        or {name} with
      </Text>
      <Flex flexDirection="row" alignItems="center" gap="16px">
        <GoogleSocialButton />
        <FacebookSocialButton />
        <KakaoSocialButton />
      </Flex>
    </Box>
  );
}
