import AppFlatCardIndicator from "@/features/line-sheet-sets/app-flat-card-indicator";
import { Box, Spinner } from "@chakra-ui/react";

export interface GridRowIndicatorProps {
  type:
    | "COLLAPSE"
    | "DOWNLOAD"
    | "WRITE"
    | "EDIT"
    | "LOADING"
    | "VIEW"
    | "EMPTY";
  isHovered: boolean;
  isCollapsed?: boolean;
  isLoading?: boolean;
}
export default function GridRowIndicator({
  type,
  isHovered,
  isCollapsed,
  isLoading,
}: GridRowIndicatorProps) {
  if (type === "COLLAPSE") {
    if (isCollapsed) {
      return (
        <AppFlatCardIndicator
          src={"/icons/icon_chevron_right.svg"}
          isHovered={isHovered}
          name={""}
        />
      );
    } else {
      return (
        <AppFlatCardIndicator
          src={"/icons/icon_chevron_bottom.svg"}
          isHovered={isHovered}
          name={""}
        />
      );
    }
  } else if (type === "DOWNLOAD") {
    return (
      <AppFlatCardIndicator
        isLoading={isLoading}
        src={"/icons/icon_download.svg"}
        isHovered={isHovered}
        name={"download"}
      />
    );
  } else if (type === "WRITE") {
    return (
      <AppFlatCardIndicator
        isLoading={isLoading}
        src={"/icons/icon_add.svg"}
        isHovered={isHovered}
        name={"add"}
      />
    );
  } else if (type === "EDIT") {
    return (
      <AppFlatCardIndicator
        isLoading={false}
        src={"/icons/icon_edit.svg"}
        isHovered={isHovered}
        name={"edit"}
      />
    );
  } else if (type === "VIEW") {
    return (
      <AppFlatCardIndicator
        isLoading={false}
        src={"/icons/icon_magnifier.svg"}
        isHovered={isHovered}
        name={"view"}
      />
    );
  } else if (type === "LOADING") {
    return <Spinner size={"sm"} />;
  } else if (type === "EMPTY") {
    return <Box></Box>;
  }

  return <></>;
}
