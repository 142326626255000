import {
  DenseOrderSheetSet,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import { Agency, Boutique, Buyer, StoredObject } from "@/features/types";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";

export type InvoiceType =
  | "ORDER_CONFIRMATION"
  | "PROFORMA"
  | "CREDIT"
  | "DEPOSIT_REMITTANCE";

export const InvoiceTypes: InvoiceType[] = [
  "ORDER_CONFIRMATION",
  "PROFORMA",
  "CREDIT",
  "DEPOSIT_REMITTANCE",
];

export type RemittanceStatus = "REMITTANCE_REQUIRED" | "REMITTED";

export const RemittanceStatuses: RemittanceStatus[] = [
  "REMITTANCE_REQUIRED",
  "REMITTED",
];

export interface Invoice {
  id: number;
  name: string;
  orderSheetSets: Pick<LightOrderSheetSet, "id">[];
  invoices: Invoice[];
  createdByCompany: Agency;
  issuedByCompany: Boutique;
  issuedToCompany: Buyer;
  paidBy: "BUYER" | "AGENCY";
  type: InvoiceType;
  season: string;
  _lineSheetSetType: LineSheetSetType;
  amount: number;
  issuedOn: Date;
  file: StoredObject;
  remittanceStatus: RemittanceStatus;
  updatedAt: Date;
  createdAt: Date;
}

export interface DenseInvoice extends Omit<Invoice, "orderSheetSets"> {
  orderSheetSets: DenseOrderSheetSet[];
}
