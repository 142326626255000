import { OrderSheetRevision } from "@/features/order-sheet-sets/order-sheet-revision.type";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export type OrderSheetRevisionSelectValue = OrderSheetRevision;

export interface OrderSheetRevisionSelectorProps {
  values: OrderSheetRevisionSelectValue[];
  value?: OrderSheetRevisionSelectValue;
  onSelect: (value?: OrderSheetRevisionSelectValue) => void;
  width?: string;
  minWidth?: string;
}

export default function OrderSheetRevisionSelector({
  values,
  value,
  onSelect,
  width,
  minWidth,
}: OrderSheetRevisionSelectorProps) {
  const { tTitle } = useI18nHelper();
  const options = values
    ? values.map((value) => {
        return {
          name: `${value.createdAt.toString().replace(/\s*\(.+\)/, "")} [${
            value.createdByCompany.name
          }]`,
          suffix: value.revisionNumber,
          value,
        };
      })
    : [];
  return (
    <AppSelect<OrderSheetRevisionSelectValue>
      name={tTitle("revisions")}
      width={width}
      minWidth={minWidth}
      options={options}
      value={value}
      isClearable={false}
      isMultiple={false}
      idFunc={(value) => {
        return `OrderSheetRevisionSelect_${value.revisionNumber}`;
      }}
      onSelect={onSelect}
      isDisabled={options.length === 0}
    />
  );
}
