import { Company, isFile, StoredObject } from "@/features/types";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback, useEffect, useMemo } from "react";
import DepositInvoiceForm from "@/features/invoices/deposit-invoices/deposit-invoice-form";
import useDepositInvoiceDumbTransaction from "@/features/invoices/deposit-invoices/hooks/use-deposit-invoice-dumb-transaction";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import useDepositInvoiceFormValidationSchema from "@/features/invoices/deposit-invoices/hooks/use-deposit-invoice-form-validation-schema";
import useGetMySelf from "@/features/auth/use-get-my-self";
import { FormikUtils } from "@/features/ui/utils/formik-utils";
import { PriceInputValue } from "@/features/invoices/app-price-input";
import PriceUtils from "@/features/ui/utils/price-utils";

export interface DepositInvoiceFormFields {
  id?: number;
  number?: string;
  name?: string;
  season?: string;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedBy?: Company;
  issuedThrough?: Pick<Company, "id" | "name">;
  issuedOn?: Date;
  bankRemittanceReceipt?: {
    id: number;
    name?: string;
    amount?: PriceInputValue;
  };
  publicComment?: string;
  privateComment?: string;
  file?: File | Pick<StoredObject, "id" | "name">;
  amount?: PriceInputValue;
  exposedToBuyer: boolean;
}

export default function UploadDepositInvoiceUploadPage() {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();

  const validateSchema = useDepositInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: DepositInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { addCreate } = useDepositInvoiceDumbTransaction();

  const handleSubmit = useCallback(
    (values: DepositInvoiceFormFields) => {
      if (identity?.company && isFile(values.file!)) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            number: values.number!,
            name: values.name!,
            season: values.season!,
            issuedToCompany: values.orderedBy!,
            issuedByCompany: values.issuedBy!,
            issuedThroughCompany: values.issuedThrough!,
            issuedOn: values.issuedOn!,
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            file: values.file!,
            amount: PriceUtils.placeholder(values.amount!, "EUR"),
            bankRemittanceReceipt: values.bankRemittanceReceipt!,
          }
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    [addCreate, identity]
  );

  const { data: mySelf, error: getMySelfError } = useGetMySelf();

  useEffect(() => {
    if (getMySelfError) {
      showError(getMySelfError);
    }
  }, [getMySelfError, showError]);

  const initialValues = useMemo((): DepositInvoiceFormFields => {
    return {
      id: -1,
      season: DEFAULT_SEASON,
      issuedOn: (() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
      })(),
      exposedToBuyer: true,
      ...FormikUtils.issuedToOrIssuedThrough(mySelf),
    };
  }, [mySelf]);

  return (
    <Formik<DepositInvoiceFormFields>
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values, formikHelpers) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"Header"} as={"h2"} fontSize={"14px"}>
                  {tTitle("upload_deposit_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppButton
                  key={`UploadAndAddAnother`}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      props.resetForm();
                    }
                  }}
                >
                  {tTitle("upload_and_add_another.do")}
                </AppButton>,
                <AppButton
                  key={`Upload`}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      navigate("/deposit-invoices");
                    }
                  }}
                >
                  {tTitle("upload")}
                </AppButton>,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/deposit-invoices"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <DepositInvoiceForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
