import { Box, Flex } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import { useMemo } from "react";

export interface AppProgressTrailProps {
  isActive?: boolean;
  variant?: "current" | "completed" | "upcoming";
  isTailLess?: boolean;
}

const tailSVG =
  `<svg width="10" height="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
<!--    <rect y="5" width="100%" height="5" fill="currentColor"/>-->
    <circle cx="50%" cy="5" r="5" fill="currentColor"/>
<!--    <circle cx="50%" cy="10" r="5" fill="currentColor"/>-->
</svg>`
    .replace(/\s+/g, " ")
    .replaceAll("currentColor", "%236F6F6C");

export default function AppProgressTrail({
  variant = "upcoming",
  isTailLess = false,
}: AppProgressTrailProps) {
  const headStyle = useMemo(() => {
    switch (variant) {
      case "current":
        return {
          src: "/icons/icon_circle.svg",
          color: "#1272EF",
        };
      case "completed":
        return {
          src: "/icons/icon_circle.svg",
          color: "#6F6F6C",
        };
      default:
        return {
          src: "/icons/icon_hollow_circle.svg",
          color: "#6F6F6C",
        };
    }
  }, [variant]);

  return (
    <Flex
      position={"absolute"}
      height={"100%"}
      overflow={"hidden"}
      flexDirection={"column"}
    >
      <Flex height={"18px"} flexShrink={"0"} alignItems={"center"}>
        <SVG width={"8px"} height={"8px"} {...headStyle} />
      </Flex>

      {!isTailLess && (
        <Box
          style={{
            backgroundImage: `url('data:image/svg+xml, ${tailSVG}')`,
          }}
          backgroundRepeat={"repeat-y"}
          backgroundSize={"4px"}
          backgroundPosition={"center top"}
          // backgroundColor={"red.50"}
          // color={"red"}
          height={"100%"}
        />
      )}
    </Flex>
  );
}
