import {
  ConditionType,
  FooStatistic,
} from "@/features/order-sheets/order-sheet.type";
import { IdentityParameter } from "@/features/types";
import {
  calcIdentityUrlPrefix,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface GetOrderSheetSetStatisticRequest {}

export interface GetOrderSheetSetStatisticResponse extends FooStatistic {}

export interface GetOrderSheetSetStatisticPathParameter
  extends IdentityParameter {
  orderSheetSetId: number;
}

export function getGetOrderSheetSetStatisticKey(
  parameter?: GetOrderSheetSetStatisticPathParameter,
  request?: GetOrderSheetSetStatisticRequest
): SWRObjectKey<GetOrderSheetSetStatisticRequest> | null {
  if (parameter && request) {
    const { orderSheetSetId } = parameter;
    let url = calcIdentityUrlPrefix(parameter);
    url += `/order-sheet-sets/${orderSheetSetId}/statistic`;
    return {
      url,
      params: request,
    };
  }
  return null;
}

export default function useGetOrderSheetSetStatistic(
  parameter?: GetOrderSheetSetStatisticPathParameter,
  request?: GetOrderSheetSetStatisticRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    GetOrderSheetSetStatisticRequest,
    GetOrderSheetSetStatisticResponse
  >();

  return useSWR(
    getGetOrderSheetSetStatisticKey(parameter, request),
    fetcher,
    config
  );
}
