import {
  createOrderConfirmationInvoiceKey,
  CreateOrderConfirmationInvoiceRequest,
  CreateOrderConfirmationInvoiceResponse,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-create-order-confirmation-invoice";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";

export default function useCreateOrderConfirmationInvoiceInfinite() {
  const fetcher = useFetcher<
    CreateOrderConfirmationInvoiceRequest,
    CreateOrderConfirmationInvoiceResponse
  >();
  return useYWRInfinite(createOrderConfirmationInvoiceKey, fetcher);
}
