import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { StoredObject } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface UpdateFallbackOrderSheetPathParameter {
  buyerId: number;
  orderSheetSetId: number;
  orderSheetId: number;
  // _v: number;
}
export interface UpdateFallbackOrderSheetRequest {
  uploadedFile: File;
}

export interface UpdateFallbackOrderSheetResponse extends StoredObject {}

export const updateFallbackOrderSheetTemplateUrl =
  "/buyers/:buyerId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/uploaded-file";

export const updateFallbackOrderSheetKey: YWRKey<UpdateFallbackOrderSheetPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateFallbackOrderSheetTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateFallbackOrderSheet() {
  const fetcher = useFetcher<
    UpdateFallbackOrderSheetRequest,
    UpdateFallbackOrderSheetResponse
  >();

  return useYWR(updateFallbackOrderSheetKey, fetcher, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
