import { useMemo } from "react";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useListDenseOrderConfirmationInvoicesFetcher from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices-fetcher";
import {
  getListDenseOrderConfirmationInvoicesKey,
  ListDenseOrderConfirmationInvoicesPathParameter,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices";
import { condenseResourcesById } from "@/features/ui/helpers/condense-helper";

export default function useCondenseOrderConfirmationInvoices() {
  const listDenseOrderConfirmationInvoicesFetcher =
    useListDenseOrderConfirmationInvoicesFetcher();

  return useMemo(() => {
    return async <T>(
      items: T[],
      getter: (item: T) => ({ id: number } | null)[] | { id: number } | null,
      setter: (
        item: T,
        value: OrderConfirmationInvoice[] | OrderConfirmationInvoice
      ) => void,
      parameter: ListDenseOrderConfirmationInvoicesPathParameter
    ) => {
      await condenseResourcesById(
        items,
        getter,
        setter,
        async (ids: number[]) => {
          const response = await listDenseOrderConfirmationInvoicesFetcher(
            getListDenseOrderConfirmationInvoicesKey(parameter, {
              pageSize: ids.length,
              pageNumber: 1,
              id__in: ids,
            })!
          );
          return response.orderConfirmationInvoices;
        }
      );
    };
  }, [listDenseOrderConfirmationInvoicesFetcher]);
}
