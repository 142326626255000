import useZWR from "@/utils/use-zwr";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

export interface SignUpRequest {
  email: string;
  password: string;
  userInputedCompanyName: string;
  name: string;
  mobileNumber: string;
  agreeToMarketing: boolean;
}

interface SignUpResponse {
  accessToken: string;
}

interface SignUpPathParameter {}

export const signUpTemplateUrl = `/auth/register`;
export const signUpKey: YWRKey<SignUpPathParameter> = {
  url: signUpTemplateUrl,
  method: "post",
};
export default function useSignUp() {
  const fetcher = useFetcher<SignUpRequest, SignUpResponse>();
  return useYWR(signUpKey, fetcher);
}
