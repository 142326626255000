import Cookies from "js-cookie";
import { base64UrlDecode } from "@/utils/base64-url";
import { CompanyType } from "@/features/types";

const accessTokenKey = "access_token";
const refreshTokenKey = "refresh_token";

export interface JWTScope {
  type: CompanyType;
  id: number;
  to: "ALL";
}

export interface JWTPayload {
  sub: number;
  scopes: JWTScope[];
  iat: Date;
  exp: Date;
}

export function getJwtPayload(): JWTPayload | undefined {
  const accessToken = getAccessToken();
  if (accessToken) {
    try {
      const obj = JSON.parse(base64UrlDecode(accessToken.split(".")[1]));
      const { scopes, iat, exp, ...rest } = obj;
      const inflatedScopes = scopes.map((s: string) => {
        const [type, rawId, to] = s.split(":");
        return { type, id: parseInt(rawId), to };
      });

      return {
        ...rest,
        scopes: inflatedScopes,
        iat: new Date(parseInt(iat) * 1000),
        exp: new Date(parseInt(exp) * 1000),
      };
    } catch (e) {
      clearTokens();
    }
  }
}

export function getAccessToken() {
  return Cookies.get(accessTokenKey);
}

export function getRefreshToken() {
  return Cookies.get(refreshTokenKey);
}

export function setTokens(accessToken: string, refreshToken: string) {
  Cookies.set(accessTokenKey, accessToken);
  Cookies.set(refreshTokenKey, refreshToken);
}

export function clearTokens() {
  Cookies.remove(accessTokenKey);
  Cookies.remove(refreshTokenKey);
}
