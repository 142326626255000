import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  getListOrderSheetRevisionsKey,
  ListOrderSheetRevisionsRequest,
  ListOrderSheetRevisionsResponse,
  parseListOrderSheetRevisionsKey,
} from "@/features/order-sheet-sets/hooks/use-list-order-sheet-revisions";

export default function useListOrderSheetRevisionsFetcher() {
  const listOrderSheetRevisionsFetcher = useFetcher<
    ListOrderSheetRevisionsRequest,
    ListOrderSheetRevisionsResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListOrderSheetRevisionsRequest>
    ): Promise<ListOrderSheetRevisionsResponse> => {
      const { parameter, request } = parseListOrderSheetRevisionsKey(config);
      if (parameter.orderSheetSetId > -1) {
        const listOrderSheetRevisionsResponse =
          await listOrderSheetRevisionsFetcher(
            getListOrderSheetRevisionsKey(parameter, request)!
          );

        if (listOrderSheetRevisionsResponse.revisions.length > 0) {
          return Promise.resolve({
            totalCount: listOrderSheetRevisionsResponse.totalCount,
            revisions: listOrderSheetRevisionsResponse.revisions,
          });
        }
      }
      return Promise.resolve({
        totalCount: 0,
        revisions: [],
      });
    };
  }, [listOrderSheetRevisionsFetcher]);
}
