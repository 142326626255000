import useListAllCreditInvoices, {
  ListAllCreditInvoicesRequest,
} from "@/features/invoices/credit-invoices/hooks/use-list-all-credit-invoices";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { Price } from "@/features/types";

type CreditInvoiceSelectValue = {
  id: number;
  season: string;
  amount: Price;
};

interface AppCreditInvoiceSelectorProps {
  request?: ListAllCreditInvoicesRequest;
  excludeIds: number[];
  onSelect: (value: CreditInvoiceSelectValue) => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  width?: string;
}

export default function AppCreditInvoiceSelector({
  request,
  excludeIds = [],
  onSelect,
  isDisabled = false,
  isReadOnly = false,
  width = "100%",
}: AppCreditInvoiceSelectorProps) {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();

  const { tCurrencyString } = useI18nHelper();

  const key = useMemo(() => {
    if (request && identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const {
    data: resources,
    isLoading: isListResourcesLoading,
    error: listResourcesError,
  } = useListAllCreditInvoices(key?.parameter, key?.request);

  useEffect(() => {
    if (listResourcesError) {
      showError(listResourcesError);
    }
  }, [listResourcesError, showError]);

  const options = useMemo((): AppSelectOption<CreditInvoice>[] => {
    if (resources) {
      return resources
        .flatMap((resource) => resource)
        .filter((i) => !excludeIds.includes(i.id))
        .map((i) => {
          return {
            value: i,
            name: `${i.season} / ${i.issuedByCompany.name} /${tCurrencyString(
              i.amount
            )}`,
          };
        });
    }
    return [];
  }, [resources, excludeIds, tCurrencyString]);

  return (
    <AppSelect<CreditInvoice>
      isSearchable={true}
      name={tTitle("credit")}
      width={width}
      idFunc={(i) => i.id}
      options={options}
      onSelect={(selectedValue) => {
        if (selectedValue) {
          onSelect(selectedValue);
        }
      }}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isClearable={false}
    />
  );
}
