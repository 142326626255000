import useZWR from "@/utils/use-zwr";
import { AxiosRequestConfig } from "axios";
import { clearTokens } from "@/utils/jwt";

interface SignOutRequest {}

interface SignOutResponse {}

export default function useSignOut() {
  //const fetcher = useFetcher<SignOutRequest, SignOutResponse>();
  const response = useZWR<SignOutRequest, SignOutResponse>(
    { url: "/auth", method: "delete" },
    fetcher
  );

  return response;
}

function fetcher(config: AxiosRequestConfig): Promise<SignOutResponse> {
  clearTokens();
  return Promise.resolve({});
}
