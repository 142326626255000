import { Container, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import { Outlet } from "react-router";
import AppHtmlToLink from "@/features/ui/app-html-to-link";
import { useTranslation } from "react-i18next";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface PublicLayoutProps {
  showBackgroundImage?: boolean;
}

const tuples = [
  [
    "/images/alex-E4qWx2XE1j0-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@alx_andru?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">alex°</a> on <a href="https://unsplash.com/photos/E4qWx2XE1j0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/apex-360-MCRmDTUUvUM-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@apex360_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Apex 360</a> on <a href="https://unsplash.com/photos/MCRmDTUUvUM?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/carolina-6nMu3_ms-P8-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@a_cat?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Carolina</a> on <a href="https://unsplash.com/photos/6nMu3_ms-P8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/diego-sanchez-C6ukzAPpYGc-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@ediegosanchez?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">DIEGO SÁNCHEZ</a> on <a href="https://unsplash.com/photos/C6ukzAPpYGc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/eugene-chystiakov-udEtTnAcSD8-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@eugenechystiakov?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Eugene Chystiakov</a> on <a href="https://unsplash.com/photos/udEtTnAcSD8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/feteme-fuentes-SMGl-QEUbu0-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@feteme_1993?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Feteme Fuentes</a> on <a href="https://unsplash.com/photos/SMGl-QEUbu0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/hanna-postova-F7ayfiqDeMk-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@annapostovaya?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Hanna Postova</a> on <a href="https://unsplash.com/photos/F7ayfiqDeMk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/jas-rolyn-GfY505F8T_Y-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@jasrolyn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jas Rolyn</a> on <a href="https://unsplash.com/photos/GfY505F8T_Y?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/pedro-vit-nxssxDuytMA-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@pedrovit?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Pedro Vit</a> on <a href="https://unsplash.com/photos/nxssxDuytMA?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
  [
    "/images/ryanwar-hanif-TJEtlWNsfgM-unsplash.jpg",
    'Photo by <a href="https://unsplash.com/@sagalawae16?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ryanwar Hanif</a> on <a href="https://unsplash.com/photos/TJEtlWNsfgM?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
  ],
];

export default function PublicLayout({
  showBackgroundImage,
}: PublicLayoutProps) {
  const { tTitle } = useI18nHelper();
  let backgroundImageUrl;
  let links;
  if (showBackgroundImage) {
    const index = Math.floor(Math.random() * tuples.length);
    const [imageUrl, source] = tuples[index];
    links = AppHtmlToLink({ htmlString: source });
    backgroundImageUrl = imageUrl;
  }

  return (
    <Flex
      backgroundColor={"#D9D9D9"}
      minH={"100vh"}
      backgroundImage={backgroundImageUrl}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      position={"relative"}
    >
      <Flex
        color={"#8F8F8C"}
        fontSize={"12px"}
        position={"absolute"}
        top={"20px"}
        right={"40px"}
        gap={"4px"}
      >
        {links}
      </Flex>
      <Container maxW="container.2xl" flexGrow={1}>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          h={"100%"}
        >
          <Spacer />
          <Outlet />
          <Spacer />
          <Flex
            color={"#cfcfcf"}
            fontSize={"12px"}
            flexDirection={"column"}
            gap={"10px"}
            padding={"16px 32px"}
            marginBottom={"16px"}
            borderRadius={"8px"}
            backgroundColor={"rgba(0,0,0,0.3)"}
            backdropBlur={"8px"}
            backdropFilter={"blur(8px)"}
          >
            <Flex gap={"20px"}>
              <Link
                textDecoration={"underline"}
                href={
                  "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Privacy-Policy-v1.1-20240111.pdf"
                }
                isExternal={true}
              >
                {tTitle("privacy_policy")}
              </Link>
              <Link
                textDecoration={"underline"}
                isExternal={true}
                href={
                  "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Service-Agreement-v1.0-20231208.pdf"
                }
              >
                {tTitle("terms_and_conditions")}
              </Link>
            </Flex>
            <Flex
              gap={{ base: "4px", md: "20px" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Text>{tTitle("business_address")}</Text>
              <Flex gap={"8px"}>
                <Text>{tTitle("customer_support_email")}</Text>
                <Text>support@unotrading.co.kr</Text>
              </Flex>
              <Flex gap={"8px"}>
                <Text>{tTitle("business_registration_number")}</Text>
                <Text>750-87-01065</Text>
              </Flex>
            </Flex>
            <Flex>
              <Text>
                © Copyright 2023 UNO Trading Corp. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}
