import useIdentity from "@/features/ui/hooks/use-identity";
import { Helmet } from "react-helmet-async";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useListBankRemittanceReceipts, {
  DEFAULT_LIST_BANK_REMITTANCE_RECEIPTS_PAGE_SIZE,
  ListBankRemittanceReceiptsRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-list-bank-remittance-receipts";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { useEffect, useMemo } from "react";
import AppListHeader from "@/features/ui/app-list-header";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { toTitle } from "@/utils/case";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppButton from "@/features/line-sheet-sets/app-button";
import { Box, Flex } from "@chakra-ui/react";
import BankRemittanceReceiptGrid from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-grid";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";

export type PersistedBankRemittanceReceiptListPage = Omit<
  ListBankRemittanceReceiptsRequest,
  "pageSize" | "season__eq"
>;

export default function BankRemittanceReceiptListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const [query, setQuery] =
    useTypedSearchParams<PersistedBankRemittanceReceiptListPage>();

  const listBankRemittanceReceiptsKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_BANK_REMITTANCE_RECEIPTS_PAGE_SIZE,
          "receiver.type__eq":
            identity?.company?.type === "BOUTIQUE"
              ? ("BOUTIQUE" as const)
              : undefined,
        },
      };
    }
  }, [query, identity]);

  const {
    data: listBankRemittanceReceiptsData,
    error: listBankRemittanceReceiptsError,
    isLoading: isListBankRemittanceReceiptsLoading,
    mutate: mutateListBankRemittanceReceipts,
  } = useListBankRemittanceReceipts(
    listBankRemittanceReceiptsKey?.parameter,
    listBankRemittanceReceiptsKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(listBankRemittanceReceiptsError);
  }, [showError, listBankRemittanceReceiptsError]);

  return (
    <>
      <Helmet>
        <title>{`Pathrade | ${tTitle("bank_remittance_receipts")}`}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("bank_remittance_receipts")}`}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              width={"120px"}
              value={query.season__in}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"sender"}
              name={toTitle(t("sender"))}
              types={["AGENCY", "BUYER"]}
              width={"120px"}
              id={query["sender.id__eq"]}
              onSelect={(value) =>
                setQuery({
                  ...query,
                  "sender.id__eq": value?.id,
                  pageNumber: 1,
                })
              }
            />,
            <AppCompanySelect
              key={"receiver"}
              name={toTitle(t("receiver"))}
              types={["AGENCY", "BOUTIQUE"]}
              width={"120px"}
              id={query["receiver.id__eq"]}
              onSelect={(value) =>
                setQuery({
                  ...query,
                  "receiver.id__eq": value?.id,
                  pageNumber: 1,
                })
              }
            />,
            // () => {
            //   if (identity?.company?.type !== "BUYER") {
            //     return (
            //       <AppCompanySelect
            //         key={"AgencyBankRemittanceReceiptListFilter_OrderedBy"}
            //         name={toTitle(t("ordered_by"))}
            //         types={["BUYER"]}
            //         width={"120px"}
            //         id={query["issuedToCompany.id__eq"]}
            //         onSelect={(value) =>
            //           setQuery((prev) => {
            //             return {
            //               ...prev,
            //               "issuedToCompany.id__eq": value?.id,
            //               pageNumber: 1,
            //             };
            //           })
            //         }
            //       />
            //     );
            //   }
            // },
            <AppDatePicker
              key={"AgencyBankRemittanceReceiptListFilter_IssuedOn"}
              name={toTitle(t("issued_on"))}
              value={query.issuedOn__eq}
              showTimeInput={false}
              width={"120px"}
              onChange={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    issuedOn__eq: value,
                    pageNumber: 1,
                  };
                })
              }
            />,
            <AppButton
              key={"AgencyBankRemittanceReceiptListFilter_ResetFilter"}
              variant={"normal"}
              width={"120px"}
              size={"medium"}
              onClick={() => {
                setQuery({ pageNumber: 1 });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            () => {
              if (identity?.company?.type !== "BOUTIQUE") {
                return (
                  <AppButton
                    key={"AgencyBankRemittanceReceiptListAction_UploadInvoices"}
                    variant={"primary"}
                    size={"medium"}
                    onClick={() => {
                      navigate("/bank-remittance-receipts/upload");
                    }}
                  >
                    {toTitle(
                      t("upload_bank_remittance_receipts", {
                        ns: "bank_remittance_receipts",
                      })
                    )}
                  </AppButton>
                );
              }
            },
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <BankRemittanceReceiptGrid
            resources={listBankRemittanceReceiptsData?.bankRemittanceReceipts}
            mutateResources={mutateListBankRemittanceReceipts}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listBankRemittanceReceiptsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              })
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
