import useFetcher from "@/utils/use-fetcher";
import { StoredObject } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

interface CreateStandardOrderFormRequest {}

interface CreateStandardOrderFormResponse extends StoredObject {}

interface CreateStandardOrderFormPathParameter {}

export const createStandardOrderFormKey: YWRKey<CreateStandardOrderFormPathParameter> =
  {
    url: () => {
      return "/standard-order-forms";
    },
    method: "post",
  };

export default function useCreateStandardOrderForm() {
  const fetcher = useFetcher<
    CreateStandardOrderFormRequest,
    CreateStandardOrderFormResponse
  >();
  return useYWR(createStandardOrderFormKey, fetcher);
}
