import { LineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import AppLabel from "@/features/ui/app-label";
import { toLower } from "lodash";

interface AppLineSheetSetCompressionStatusLabelProps {
  value: LineSheetSet["compressionStatus"];
}

export default function AppLineSheetSetCompressionStatusLabel({
  value,
}: AppLineSheetSetCompressionStatusLabelProps) {
  if (!value) {
    return null;
  }
  return (
    <AppLabel
      nameKey={`line_sheet_set.compression_status.${toLower(value)}`}
      level={(() => {
        if (value === "FAILED") {
          return "ERROR";
        } else if (value === "COMPLETED") {
          return "SUCCESS";
        }
        return "LIGHT";
      })()}
    />
  );
}
