import { FormikHelpers } from "formik";
import { MyNavigateOptions } from "@/features/ui/hooks/use-router-helper";
import { User } from "@/features/auth/use-get-my-self";
import { getIdentity } from "@/features/ui/helpers/identity-helpers";
import { Location } from "@remix-run/router";

export class FormikUtils {
  static withDumbOrNotHandleSubmit<FormFields, RES>(
    handleSubmit: (values: FormFields) => Promise<RES | boolean>,
    clear: () => void,
    navigate: (to: string | number, option?: MyNavigateOptions) => void
  ) {
    return async (
      values: FormFields,
      formikHelpers: FormikHelpers<FormFields>
    ) => {
      formikHelpers.setSubmitting(true);

      try {
        const response = await handleSubmit(values);
        const isByDumb = typeof response === "boolean";

        if (!isByDumb) {
          clear();
        }

        navigate(-1, { defaultPage: "/deposit-invoices" });
      } finally {
        formikHelpers.setSubmitting(false);
      }
    };
  }

  static issuedToOrIssuedThrough(mySelf?: User) {
    if (mySelf) {
      const identity = getIdentity();
      if (identity && identity.company) {
        const company = mySelf.companies.find(
          (company) => company.id === identity.company!.id
        );
        if (company?.type === "BUYER") {
          return {
            orderedBy: {
              id: company.id,
              name: company.name,
            },
          };
        } else if (company?.type === "AGENCY") {
          return {
            issuedThrough: {
              id: company.id,
              name: company.name,
            },
          };
        }
      }
    }

    return {};
  }

  static initialIssuedOnFunc() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static isFieldsDirty(
    current: any,
    initial: any,
    excludePaths: string[],
    basePath: string = ""
  ): boolean {
    if (Array.isArray(current) && Array.isArray(initial)) {
      return current.some((item, index) =>
        FormikUtils.isFieldsDirty(
          item,
          initial[index],
          excludePaths,
          `${basePath}`
        )
      );
    } else if (
      typeof current === "object" &&
      current !== null &&
      typeof initial === "object" &&
      initial !== null
    ) {
      return Object.keys(current).some((key) => {
        const path = `${basePath ? basePath + "." : ""}${key}`;
        if (excludePaths.includes(path)) {
          return false;
        }

        // if (current[key] !== undefined && initial[key] === undefined) {
        //   debugger;
        // }

        return FormikUtils.isFieldsDirty(
          current[key],
          initial[key],
          excludePaths,
          path
        );
      });
    }
    return current !== initial;
  }

  static handleDumbMixedSubmit<T>({
    values,
    formikHelpers,
    submit,
    clear,
    navigate,
  }: {
    values: T;
    formikHelpers: FormikHelpers<T>;
    submit: (values: T) => Promise<any>;
    clear: () => void;
    location: Location;
    navigate: (to: string | number, option?: MyNavigateOptions) => void;
  }) {}
}
