import EmptyLayout from "@/features/ui/empty-layout";
import { ReactNode } from "react";
import { Flex, Heading, Select, Text } from "@chakra-ui/react";
import AppLogo from "@/features/ui/app-logo";
import AppSelect from "@/features/line-sheet-sets/app-select";
import AppLanguageSelector from "@/features/ui/app-language-selector";

interface SignInOrUpLayoutProps {
  title: string;
  tail: ReactNode;
  children: ReactNode;
  indicator?: string;
  socialButtons?: ReactNode;
}

export default function AuthLayout({
  title,
  tail,
  children,
  indicator,
  socialButtons,
}: SignInOrUpLayoutProps) {
  return (
    <Flex
      maxW="440px"
      w="100%"
      backgroundColor={"#FFF"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border="0.5px solid #D4D4D1"
      borderRadius="8px"
      padding={"28px 80px"}
      gap="24px"
      position="relative"
    >
      {indicator && (
        <Text
          color={"#444444"}
          fontWeight={"400"}
          fontSize="12px"
          position={"absolute"}
          top={"12px"}
          left={"20px"}
          alignSelf={"flex-start"}
        >
          {indicator}
        </Text>
      )}
      <AppLogo />
      <Heading fontSize="20px" fontWeight={"700"} color={"#444444"}>
        {title}
      </Heading>
      <Flex flexDirection="column" justifyContent="stretch" gap="0px" w="280px">
        {children}
      </Flex>

      {/*{socialButtons}*/}

      {/*<Text fontSize="sm">{tail}</Text>*/}
      {tail}
      <Text fontSize={"12px"}>© UNO Trading Corp.</Text>
      {/*<Select variant="unstyled" maxWidth="120px">*/}
      {/*  <option>EN</option>*/}
      {/*</Select>*/}
      <AppLanguageSelector />
    </Flex>
  );
}
