import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadCreditInvoiceRequest {}
export interface ReadCreditInvoiceResponse extends LightLineSheet {}

interface ReadCreditInvoicePathParameter extends IdentityParameter {
  creditInvoiceId: number;
}

export const readCreditInvoiceTemplateUrl =
  "/:by/:companyId/credit-invoices/:creditInvoiceId/read";

export const readCreditInvoiceKey: YWRKey<ReadCreditInvoicePathParameter> = {
  url: (parameter: ReadCreditInvoicePathParameter) => {
    return calcUrl(readCreditInvoiceTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReadCreditInvoiceKey(url: string) {
  const urlParser = new UrlParser<ReadCreditInvoicePathParameter>(
    readCreditInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      creditInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadCreditInvoice() {
  const fetcher = useFetcher<
    ReadCreditInvoiceRequest,
    ReadCreditInvoiceResponse
  >();
  return useYWR(readCreditInvoiceKey, fetcher);
}
