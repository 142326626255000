import { IdentityParameter } from "@/features/types";
import { toIdentityParameter } from "@/features/ui/helpers/identity-helpers";
import {
  calcIdentityUrlPrefix,
  calcUrl,
  calcYWRUrl,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface GetLineSheetSetRequest {}
export interface GetLineSheetSetResponse extends LightLineSheetSet {}

export interface GetLineSheetSetPathParameter extends IdentityParameter {
  lineSheetSetId: number;
}

export const getLineSheetSetTemplateUrl = `/:by/:companyId/line-sheet-sets/:lineSheetSetId`;

export function getGetLineSheetSetKey(
  parameter?: GetLineSheetSetPathParameter,
  request?: GetLineSheetSetRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getLineSheetSetTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetLineSheetSetKey(
  key: SWRObjectKey<GetLineSheetSetRequest>
) {
  const urlParser = new UrlParser<GetLineSheetSetPathParameter>(
    getLineSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url),
    request: key.params,
  };
}

export default function useGetLineSheetSet(
  parameter?: GetLineSheetSetPathParameter,
  request: GetLineSheetSetRequest = {},
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<GetLineSheetSetRequest, GetLineSheetSetResponse>();
  return useSWR(getGetLineSheetSetKey(parameter, request), fetcher, config);
}
