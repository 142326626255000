import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import { Gmail, GmailRow } from "@/features/gmails/gmail.types";

export const GmailRowsMapper = new GridMapper<Gmail, GmailRow>(
  "Gmail",
  (gmail) => {
    return {
      id: gmail.id,
      gmailId: gmail.gmailId,
      labelNames: gmail.labelNames,
      createdAt: gmail.createdAt,
      receivedAt: gmail.receivedAt,
      LineSheetSetType: gmail.LineSheetSetType,
      season: gmail.season,
      title: gmail.title,
      contents: gmail.contents,
      sender: gmail.sender,
      to: gmail.to,
      availableLinks: gmail.availableLinks,
      Attachments: gmail.Attachments,
      boutique: gmail.boutique,
      brand: gmail.brand,
      lineSheetSet: gmail.lineSheetSet,
    };
  },
  (row) => {
    return {
      id: row.id,
      gmailId: row.gmailId,
      labelNames: row.labelNames,
      createdAt: row.createdAt,
      receivedAt: row.receivedAt,
      LineSheetSetType: row.LineSheetSetType,
      season: row.season,
      title: row.title,
      contents: row.contents,
      sender: row.sender,
      to: row.to,
      availableLinks: row.availableLinks,
      Attachments: row.Attachments,
      boutique: row.boutique,
      brand: row.brand,
      lineSheetSet: row.lineSheetSet,
    };
  }
);

export const agencyGmailRowState = atom<GmailRow[] | undefined>({
  key: "AgencyGmailRows",
  default: undefined,
});

export default function useGmailRows() {
  const setRows = useRecoilCallback(
    ({ set }) =>
      (gmails?: Gmail[]) => {
        set(agencyGmailRowState, (prev) => {
          return GmailRowsMapper.toRows(gmails, prev);
        });
      },
    []
  );

  const rows = useRecoilValue(agencyGmailRowState);

  return {
    setRows,
    rows,
  };
}
