import { useCallback, useMemo, useRef } from "react";
import { toCamel, toSnake } from "@/utils/case";
import { CompanyId } from "@/features/line-sheet-sets/app-company-select";
import {
  Deserializable,
  isSerializable,
  Serializable,
} from "@/features/ui/app.type";
import useStableSearchParams from "@/features/invoices/hooks/use-stable-search-params";
import { difference } from "lodash";

function parseKey(rawKey: string) {
  const i = rawKey.lastIndexOf("__");
  if (i > -1) {
    return {
      field: rawKey.slice(0, i),
      operator: rawKey.slice(i + 2),
    };
  }
  return {
    field: rawKey,
    operator: undefined,
  };
}

type FieldPredicate = (
  field: string,
  operator: string | undefined,
  value: string | string[]
) => boolean;
type FieldDeserialize = (value: string) => any;
type ValueSerialize = (value: any) => string | string[];
type ValuePredicate = (value: any) => boolean;

class FieldDeserializer {
  isField: FieldPredicate;
  deserialize: FieldDeserialize;

  constructor(predicate: FieldPredicate, deserializer: FieldDeserialize) {
    this.isField = predicate;
    this.deserialize = deserializer;
  }
}

class ValueSerializer {
  isValue: ValuePredicate;
  serialize: ValueSerialize;

  constructor(predicate: ValuePredicate, serializer: ValueSerialize) {
    this.isValue = predicate;
    this.serialize = serializer;
  }
}

const booleanDeserializer = new FieldDeserializer(
  (field, operator, value) => {
    const func = (value: string) => value === "true" || value === "false";
    return Array.isArray(value) ? value.every(func) : func(value);
  },
  (value) => {
    return value === "true";
  }
);

const numberDeserializer = new FieldDeserializer(
  (field, operator, value) => {
    const numberSuffixes = ["id", "number", "index", "count", "size", "amount"];
    return numberSuffixes.some(
      (suffix) => field.toLowerCase().endsWith(suffix) && !isNaN(Number(value))
    );
  },
  (value) => {
    return Number(value);
  }
);

function createDeserializer(object: Deserializable) {
  return new FieldDeserializer(
    (field, operator, value) => {
      const func = object.isDeserializable;
      return Array.isArray(value) ? value.every(func) : func(value);
    },
    (value) => {
      return object.deserialize(value);
    }
  );
}

const stringDeserializer = new FieldDeserializer(
  (value) => true,
  (value) => value
);

const fieldDeserializers: FieldDeserializer[] = [
  booleanDeserializer,
  numberDeserializer,
  createDeserializer(Date.prototype),
  createDeserializer(CompanyId.prototype),
  stringDeserializer,
];

const booleanSerializer = new ValueSerializer(
  (value) => {
    const func = (value: any) => typeof value === "boolean";
    return Array.isArray(value) ? value.every(func) : func(value);
  },
  (value) => {
    return value ? "true" : "false";
  }
);

const numberSerializer = new ValueSerializer(
  (value) => {
    const func = (value: any) => typeof value === "number";
    return Array.isArray(value) ? value.every(func) : func(value);
  },
  (value) => {
    return String(value);
  }
);

const serializableSerializer = new ValueSerializer(
  (value) => {
    const func = isSerializable;
    return Array.isArray(value) ? value.every(func) : func(value);
  },
  (value) => {
    return value.serialize();
  }
);

const stringSerializer = new ValueSerializer(
  (value) => {
    const func = (value: any) => typeof value === "string";
    return Array.isArray(value) ? value.every(func) : func(value);
  },
  (value) => {
    return String(value);
  }
);

const serializers: ValueSerializer[] = [
  booleanSerializer,
  numberSerializer,
  serializableSerializer,
  stringSerializer,
];

// function isNumberField(
//   field: string,
//   operator: string | undefined,
//   value: string | string[]
// ) {
//   const numberSuffixes = ["id", "number", "index", "count"];
//   return numberSuffixes.some((suffix) => field.toLowerCase().endsWith(suffix));
// }
//
// function isBooleanField(
//   field: string,
//   operator: string | undefined,
//   value: string | string[]
// ) {
//   const func = (value: string) => value === "true" || value === "false";
//   return Array.isArray(value) ? value.every(func) : func(value);
// }
//
// function withDeserializable(
//   deserializable: Deserializable
// ): [FieldPredicate, FieldDeserialize] {
//   const predicate = (
//     field: string,
//     operator: string | undefined,
//     value: string | string[]
//   ) => {
//     const func = deserializable.isDeserializable;
//     return Array.isArray(value) ? value.every(func) : func(value);
//   };
//
//   const deserializer = (value: string) => {
//     return deserializable.deserialize(value);
//   };
//
//   return [predicate, deserializer];
// }

export default function useTypedSearchParams<
  T extends { [key: string]: any }
>() {
  const [stableSearchParam, setStableSearchParam] = useStableSearchParams();
  const usedKeys = useRef<string[]>([]);

  const value = useMemo(() => {
    const obj = {} as T;

    [...stableSearchParam.keys()].forEach((snakeKey) => {
      const { field, operator } = parseKey(toCamel(snakeKey));
      const rawValue = stableSearchParam.getAll(snakeKey);

      const isArray = operator === "in";

      const value = isArray ? rawValue : rawValue[0];

      const deserializer = fieldDeserializers.find((deserializer) => {
        return deserializer.isField(field, operator, value);
      });

      if (deserializer) {
        //@ts-ignore
        obj[toCamel(snakeKey)] = Array.isArray(value)
          ? value.map(deserializer.deserialize)
          : deserializer.deserialize(value);
      }
    });

    return obj;
  }, [stableSearchParam]);

  const setValue = useCallback(
    (valueOrUpdater: T | ((prev: T) => T)) => {
      function isFunction<T>(
        value: T | ((prev: T) => T)
      ): value is (prev: T) => T {
        return typeof value === "function";
      }

      let nextObj = isFunction(valueOrUpdater)
        ? valueOrUpdater(value)
        : valueOrUpdater;

      const preservedKeys = difference(Object.keys(value), usedKeys.current);
      if (preservedKeys) {
        nextObj = {
          ...nextObj,
          ...preservedKeys.reduce((acc, key) => {
            //@ts-ignore
            acc[key] = value[key];
            return acc;
          }, {} as T),
        };
      }

      //hook에서 관리하지 않는 query string을 유지해야한다,(예, reference), 어케??
      //setValue에서 한번이라도 호출된 애를 기억해놓고, 한번도 호출안된애들은 기본값으로 유지시켜볼까?

      const nextParams = new URLSearchParams();

      Object.keys(nextObj).forEach((camelKey) => {
        const snakeKey = toSnake(camelKey);
        const objValue = nextObj[camelKey];

        if (!usedKeys.current.includes(camelKey)) {
          usedKeys.current.push(camelKey);
        }

        if (objValue !== undefined) {
          const serializer = serializers.find((serializer) => {
            return serializer.isValue(objValue);
          });

          if (serializer) {
            if (Array.isArray(objValue)) {
              const paramValue = objValue.flatMap(serializer.serialize);
              paramValue.forEach((value) => {
                nextParams.append(snakeKey, value);
              });
            } else {
              const paramValue = serializer.serialize(objValue);
              if (Array.isArray(paramValue)) {
                paramValue.forEach((value) => {
                  nextParams.append(snakeKey, value);
                });
              } else {
                nextParams.append(snakeKey, paramValue);
              }
            }
          }
        }
      });

      setStableSearchParam(nextParams);
    },
    [value, setStableSearchParam]
  );

  const setFieldValue = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setValue((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [setValue]
  );

  return [value, setValue] as const;
}
