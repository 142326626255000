import { FormikProps } from "formik";
import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import BinarySplitView from "@/features/ui/binary-split-view";
import PaymentInformationFormControl from "@/features/invoices/payment/payment-information-form-control";
import LinkedOrderConfirmationInvoicesFormControl from "@/features/invoices/proforma-invoices/linked-order-confirmation-invoices-form-control";
import { ListAllOrderConfirmationInvoicesRequest } from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import KRWInvoiceFileFormControl from "@/features/invoices/payment/krw-invoice-file-form-control";
import { ListAllProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import LinkedProformaInvoiceFormControl from "@/features/invoices/proforma-invoices/linked-proforma-invoice-form-control";
import { uniqBy, uniqWith } from "lodash";
import PaymentFigureFormControl from "@/features/invoices/payment/payment-figure-form-control";
import { InvoiceUtils } from "@/features/invoices/invoice-utils";
import PriceUtils from "@/features/ui/utils/price-utils";
import IsolatedBankRemittanceReceiptPanel from "@/features/invoices/bank-remittance-receipts/isolated-bank-remittance-receipt-panel";
import { AppUtils } from "@/features/ui/utils/app-utils";

interface PaymentFormProps {
  formik: FormikProps<PaymentFormFields>;
  isEditing: boolean;
}

export default function PaymentForm({ formik, isEditing }: PaymentFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listOrderConfirmationInvoicesRequest = useMemo(():
    | ListAllOrderConfirmationInvoicesRequest
    | undefined => {
    if (
      formik.values.season &&
      formik.values.orderedBy &&
      // formik.values.issuedBy &&
      formik.values.sender &&
      formik.values.receiver
    ) {
      const request: ListAllOrderConfirmationInvoicesRequest = {
        season__eq: formik.values.season,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        // "issuedByCompany.id__eq": formik.values.issuedBy.id,
        isPublic__eq: true,
        status__eq: "NORMAL",
        paidBy__eq: InvoiceUtils.getPaidByParties({
          sender: formik.values.sender,
          receiver: formik.values.receiver,
        }),
      };

      if (formik.values.receiver.type === "BOUTIQUE") {
        request["validPaymentToBoutique__exists"] = false;
      } else {
        request["validPaymentToAgency__exists"] = false;
      }

      return request;
    }
  }, [
    // formik.values.issuedBy,
    formik.values.orderedBy,
    formik.values.receiver,
    formik.values.season,
    formik.values.sender,
  ]);

  const listProformaInvoicesRequest = useMemo(():
    | ListAllProformaInvoicesRequest
    | undefined => {
    if (
      formik.values.season &&
      formik.values.orderedBy &&
      // formik.values.issuedBy &&
      formik.values.sender &&
      formik.values.receiver
    ) {
      const request: ListAllProformaInvoicesRequest = {
        season__eq: formik.values.season,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        // "issuedByCompany.id__eq": formik.values.issuedBy.id,
        isPublic__eq: true,
        status__eq: "NORMAL",
        paidBy__eq: InvoiceUtils.getPaidByParties({
          sender: formik.values.sender,
          receiver: formik.values.receiver,
        }),
      };

      if (formik.values.receiver.type === "BOUTIQUE") {
        request["validPaymentToBoutique__exists"] = false;
      } else {
        request["validPaymentToAgency__exists"] = false;
      }

      return request;
    }
  }, [
    // formik.values.issuedBy,
    formik.values.orderedBy,
    formik.values.season,
    formik.values.sender,
    formik.values.receiver,
  ]);

  const { setValues } = formik;

  useEffect(() => {
    setValues((prev) => {
      return {
        ...prev,
      };
    });
  }, [
    setValues,
    formik.values.orderConfirmationInvoices,
    formik.values.proformaInvoices,
  ]);

  const isDisabled = useMemo(() => {
    return isEditing && formik.values.status !== "NORMAL";
  }, [formik.values.status, isEditing]);

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <PaymentInformationFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
            <KRWInvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            <PaymentFigureFormControl formik={formik} isEditing={isEditing} />
            <LinkedOrderConfirmationInvoicesFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
              request={listOrderConfirmationInvoicesRequest}
              nameFunc={(ocInvoice) => {
                return `${ocInvoice.number} / ${ocInvoice.name}`;
              }}
              onSelect={(rawValue) => {
                formik.setValues((prev) => {
                  const next = {
                    ...prev,
                    orderConfirmationInvoices: rawValue.map((ocInvoice) => {
                      return {
                        id: ocInvoice.id,
                        number: ocInvoice.number,
                        paidBy: ocInvoice.paidBy,
                        totalRequiredAmount: ocInvoice.totalRequiredAmount,
                        orderConfirmationInvoiceDetailList:
                          ocInvoice.orderConfirmationInvoiceDetailList.map(
                            (ocDetail) => {
                              return {
                                id: ocDetail.id,
                                brand: ocDetail.brand,
                              };
                            }
                          ),
                      };
                    }),
                  };

                  if (next.receiver?.type === "BOUTIQUE") {
                    next.amount = PriceUtils.sum(
                      [
                        ...next.orderConfirmationInvoices.map(
                          (ocInvoice) => ocInvoice.totalRequiredAmount
                        ),
                        ...next.proformaInvoices.map(
                          (pInvoice) => pInvoice.totalRequiredAmount
                        ),
                      ],
                      "EUR"
                    );
                  }

                  return next;
                });
              }}
            />

            <LinkedProformaInvoiceFormControl
              formik={formik}
              isEditing={isEditing}
              request={listProformaInvoicesRequest}
              nameFunc={(pInvoice) => {
                return `${pInvoice.number} / ${pInvoice.name}`;
              }}
              isDisabled={isDisabled}
              onSelect={(rawValue) => {
                formik.setValues((prev) => {
                  const next = {
                    ...prev,
                    proformaInvoices: rawValue.map((pInvoice) => {
                      return {
                        id: pInvoice.id,
                        number: pInvoice.number,
                        paidBy: pInvoice.paidBy,
                        totalRequiredAmount: pInvoice.totalRequiredAmount,
                        proformaInvoiceDetailList:
                          pInvoice.proformaInvoiceDetailList.map((pDetail) => {
                            return {
                              id: pDetail.id,
                              brand: pDetail.brand,
                            };
                          }),
                      };
                    }),
                  };

                  if (next.receiver?.type === "BOUTIQUE") {
                    next.amount = PriceUtils.sum(
                      [
                        ...next.orderConfirmationInvoices.map(
                          (ocInvoice) => ocInvoice.totalRequiredAmount
                        ),
                        ...next.proformaInvoices.map(
                          (pInvoice) => pInvoice.totalRequiredAmount
                        ),
                      ],
                      "EUR"
                    );
                  }

                  return next;
                });
              }}
            />

            {!isEditing && (
              <IsolatedBankRemittanceReceiptPanel
                bankRemittanceReceiptIds={AppUtils.extractIds(
                  formik.values.bankRemittanceReceipts
                )}
              />
            )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={400}
      />
    </Box>
  );
}
