import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect, useMemo } from "react";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import useListAllProformaInvoices, {
  ListAllProformaInvoicesRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import useBiSWRResponse from "@/features/invoices/deposit-invoices/use-bi-swr-response";
import { uniqBy } from "lodash";
import {
  DenseProformaInvoice,
  ProformaInvoice,
} from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import useListAllDenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-list-all-dense-proforma-invoices-fetcher";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface LinkedProformaInvoice {
  id: number;
  name: string;
  totalRequiredAmount: number;
}

interface AppProformaInvoicesExclusiveSelectorProps {
  request?: ListAllProformaInvoicesRequest;
  // value: LinkedProformaInvoice[];
  onSelect: (value: DenseProformaInvoice[]) => void;
  isDisabled?: boolean;
  id?: number | number[];
  nameFunc?: (pInvoice: DenseProformaInvoice) => string;
}

export default function AppProformaInvoicesExclusiveSelector({
  request,
  id,
  onSelect,
  isDisabled,
  nameFunc,
}: AppProformaInvoicesExclusiveSelectorProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle } = useI18nHelper();

  const key1 = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const key2 = useMemo(() => {
    if (identity?.company && id) {
      const id__in = Array.isArray(id) ? id : [id];
      if (id__in.length > 0) {
        return {
          parameter: {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          request: {
            id__in: Array.isArray(id) ? id : [id],
          },
        };
      }
    }
  }, [id, identity]);

  const response1 = useListAllDenseProformaInvoices(
    key1?.parameter,
    key1?.request
  );
  const response2 = useListAllDenseProformaInvoices(
    key2?.parameter,
    key2?.request
  );

  const {
    data: resources,
    isLoading: isListResourcesLoading,
    error: isListResourcesError,
  } = useBiSWRResponse(response1, response2, (data1, data2) => {
    return uniqBy([...(data1 || []), ...(data2 || [])], (i) => i.id);
  });

  useEffect(() => {
    if (isListResourcesError) {
      showError(isListResourcesError);
    }
  }, [isListResourcesError, showError]);

  const value = useMemo(() => {
    if (resources && id) {
      const ids = Array.isArray(id) ? id : [id];
      return resources.filter((i) => ids.includes(i.id));
    }
    return [];
  }, [resources, id]);

  return (
    <AppExclusiveSelect<DenseProformaInvoice>
      name={"proforma_invoice"}
      values={value}
      options={resources || []}
      optionFunc={(pInvoice) => {
        let name = "";
        if (nameFunc) {
          name = nameFunc(pInvoice);
        } else {
          name = `${pInvoice.id} / ${pInvoice.name}`;
        }

        return {
          value: pInvoice,
          name: name,
        };
      }}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isLoading={isListResourcesLoading}
    />
  );
}
