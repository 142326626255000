import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useMemo } from "react";
import {
  Form,
  Formik,
  Field,
  FieldProps,
  FormikProps,
  FieldInputProps,
} from "formik";
import * as Yup from "yup";
import useIsValidEmail from "@/features/auth/hooks/use-is-valid-email";
import debounce from "lodash/debounce";
import { ValidationError } from "yup";
import AppButton from "@/features/line-sheet-sets/app-button";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface SignUpStep1FormFields {
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface SignUpStep1FormProps {
  initialValue: SignUpStep1FormFields;
  onSubmit: (formValues: SignUpStep1FormFields) => void;
}

export default function SignUpStep1Form({
  initialValue,
  onSubmit,
}: SignUpStep1FormProps) {
  const { fire, error } = useIsValidEmail();
  const { tTitle, tSentence } = useI18nHelper();
  const { withMessage } = useYupHelper({
    prefix: "sign_up_form",
    ns: "auth",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      email: Yup.string()
        .email(withMessage.email)
        .required(withMessage.required)
        .test(
          "EmailUniqueness",
          tSentence("email_uniqueness.error.400", { ns: "auth" }),
          async (value) => {
            let res = await fire([], { email: value });
            return Promise.resolve(res && res.valid);
          }
        ),
      password: Yup.string()
        .min(8, withMessage.min)
        .test(
          "PasswordDiversity",
          tSentence("auth:sign_up_form.messages.error.not_diverse_password"),
          (value, context) => {
            if (value) {
              let typeCount = 0;

              if (/[0-9]/.test(value)) typeCount++; // Check for digits
              if (/[a-zA-Z]/.test(value)) typeCount++;
              if (/[^A-Za-z0-9]/.test(value)) typeCount++;

              return typeCount >= 2;
            }
            return true;
          }
        )
        .required(withMessage.required),
      passwordConfirmation: Yup.string().test(
        "PasswordEquality",
        tSentence("auth:sign_up_form.messages.error.not_identical_password"),
        (value, context) => {
          const { password } = context.parent;
          if (password && password.length >= 8) {
            return value === context.parent.password;
          }
          return true;
        }
      ),
    });
  }, [
    fire,
    tSentence,
    withMessage.email,
    withMessage.min,
    withMessage.required,
  ]);

  return (
    <Formik
      initialValues={initialValue}
      validateOnChange={true}
      validationSchema={validateSchema}
      onSubmit={(values, actions) => {
        onSubmit(values);
      }}
    >
      {(props: FormikProps<SignUpStep1FormFields>) => (
        <Form>
          <Field name="email">
            {({
              field,
              form,
            }: {
              field: FieldInputProps<string>;
              form: FormikProps<SignUpStep1FormFields>;
            }) => (
              <FormControl
                isRequired
                isInvalid={!!form.errors.email && form.touched.email}
              >
                <FormLabel display="flex" m={"0"}>
                  <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                    {tTitle("email")}
                  </Text>
                </FormLabel>
                <Input {...field} fontSize={"14px"} />
                <Flex h="24px" flexDirection="row" alignItems={"center"}>
                  <FormErrorMessage m="0" fontSize={"12px"}>
                    {form.errors.email}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({
              field,
              form,
            }: {
              field: FieldInputProps<string>;
              form: FormikProps<SignUpStep1FormFields>;
            }) => (
              <FormControl
                isRequired
                isInvalid={!!form.errors.password && form.touched.password}
              >
                <FormLabel display="flex" m={"0"}>
                  <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                    {tTitle("password")}
                  </Text>
                </FormLabel>
                <Input {...field} type={"password"} />
                <Flex minH="24px" flexDirection="row" alignItems={"center"}>
                  <FormErrorMessage m="0" fontSize={"12px"}>
                    {form.errors.password}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({
              field,
              form,
            }: {
              field: FieldInputProps<string>;
              form: FormikProps<SignUpStep1FormFields>;
            }) => (
              <FormControl
                isRequired
                isInvalid={
                  !!form.errors.passwordConfirmation &&
                  form.touched.passwordConfirmation
                }
              >
                <FormLabel display="flex" m={"0"}>
                  <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                    {tTitle("password_confirmation")}
                  </Text>
                </FormLabel>
                <Input {...field} type={"password"} />
                <Flex h="24px" flexDirection="row" alignItems={"center"}>
                  <FormErrorMessage m="0" fontSize={"12px"}>
                    {form.errors.passwordConfirmation}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
            )}
          </Field>
          {/*<Button type="submit" colorScheme={"blue"} w="100%" mt={"8px"}>*/}
          {/*  Sign up with email*/}
          {/*</Button>*/}

          <AppButton
            variant={"gray"}
            size={"large"}
            width={"100%"}
            chakraProps={{ height: "40px" }}
            isDisabled={!(props.isValid && props.dirty)}
            type={"submit"}
          >
            <Text fontSize={"14px"}>
              {tSentence("sign_up_with_email.do", { ns: "auth" })}
            </Text>
          </AppButton>
        </Form>
      )}
    </Formik>
  );
}
