import AppSelect from "@/features/line-sheet-sets/app-select";
import {
  LightLineSheet,
  LineSheet,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface LineSheetSelectorProps {
  values: LightLineSheet[];
  width?: string;
  minWidth?: string;
  value?: LightLineSheet;
  onSelect: (value?: LightLineSheet) => void;
}
export default function LineSheetSelector({
  values,
  value,
  onSelect,
  minWidth,
  width,
}: LineSheetSelectorProps) {
  const { tTitle } = useI18nHelper();
  const options = values.map((value) => {
    return {
      name: `#${value.id} ${value.name}`,
      value,
    };
  });
  return (
    <AppSelect<LightLineSheet>
      name={tTitle("line_sheet")}
      width={width}
      minWidth={minWidth}
      options={options}
      value={value}
      isClearable={false}
      isMultiple={false}
      idFunc={(v) => {
        return "LineSheetSelect_" + v.id;
      }}
      onSelect={onSelect}
    />
  );
}
