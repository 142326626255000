import { Flex, Input, Text } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

interface AppQueryInputProps {
  value?: string;
  width?: string;
  onChange?: (value: string) => void;
}
export default function AppQueryController({
  value = "",
  width = "288px",
  onChange,
}: AppQueryInputProps) {
  const [text, setText] = useState<string>(value);
  const { t } = useTranslation();

  useEffect(() => {
    setText(value);
  }, [value]);

  const submit = () => {
    if (onChange) {
      onChange(text);
    }
  };

  return (
    <Flex fontSize={"12px"} flexDirection={"row"} width={width} gap={"8px"}>
      <Input
        padding={"0 8px"}
        margin={"0"}
        minHeight={"0"}
        height={"auto"}
        fontSize={"inherit"}
        textAlign={"start"}
        value={text}
        onChange={(event) => {
          //onChange(parseFloat(event.target.value));
          setText(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            submit();
          }
        }}
      />
      <AppButton
        variant={"normal"}
        onClick={() => {
          submit();
        }}
      >
        {toTitle(t("search"))}
      </AppButton>
    </Flex>
  );
}
