import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import {
  copyOrderSheetKey,
  CopyOrderSheetRequest,
} from "@/features/order-status/hooks/use-copy-order-sheet";
import { CreateOrderSheetResponse } from "@/features/line-sheet-sets/hooks/use-create-order-sheet";
import useFetcher from "@/utils/use-fetcher";

export default function useCopyOrderSheetInfinite() {
  const fetcher = useFetcher<CopyOrderSheetRequest, CreateOrderSheetResponse>();
  return useYWRInfinite(copyOrderSheetKey, fetcher);
}
