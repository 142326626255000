import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { Boutique, Buyer, StoredObject } from "@/features/types";
import {
  Transportation,
  TransportationDetail,
  TransportationStatus,
} from "@/features/transportations/transportation.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { DateTimeString } from "@/features/ui/utils/date-utils";

export interface TransportationRow extends GridRow {
  id: number;
  name: string;
  status: TransportationStatus;
  shippedFromList: Boutique[];
  shippedTo: Buyer;
  shippedToAddress: string;
  forwarderInvoiceNumbers: string[];

  houseBlNumbers: string[];

  flightNumber: string;

  flightDepartureAirport: string;
  flightArrivalAirport: string;

  flightEtd: DateTimeString;
  flightEta: DateTimeString;

  flightAtd: DateTimeString | null;
  flightAta: DateTimeString | null;

  finalEta: Date | null;

  cost: number;
  details: TransportationDetail[];
  files: StoredObject[];

  proformaInvoices: { id: number; name: string }[];
  createdAt: Date;
  updatedAt: Date;
}

export const transportationRowMapper = new GridMapper<
  Transportation,
  TransportationRow
>(
  "Transportation",
  (resource) => {
    return { ...resource };
  },
  (row) => {
    return { ...row };
  }
);
