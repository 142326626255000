import { useMemo } from "react";
import * as Yup from "yup";
import { Company } from "@/features/types";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import PriceUtils from "@/features/ui/utils/price-utils";
import { BankRemittanceReceiptFormFields } from "@/features/invoices/bank-remittance-receipts/upload-bank-remittance-receipt-page";

export default function useBankRemittanceReceiptFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const { withMessage } = useYupHelper({
    ns: "bank_remittance_receipts",
    prefix: "update_bank_remittance_receipt_form",
  });

  return useMemo(() => {
    return Yup.object<BankRemittanceReceiptFormFields>({
      name: Yup.string().required(withMessage.required),
      season: Yup.string().required(withMessage.required),
      orderedBy: Yup.mixed<Company>().required(withMessage.required),
      // issuedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedThrough: Yup.mixed<Company>().required(withMessage.required),
      issuedOn: Yup.date().required(withMessage.required),
      payments: Yup.array()
        .of(
          Yup.object({
            id: Yup.number(),
          })
        )
        .required(withMessage.required)
        .min(1, withMessage.min),
      amount: Yup.object({
        value: Yup.number().required(withMessage.required),
        currency: Yup.string().required(withMessage.required),
      }).test(
        "amount_less_than_unremitted_amount",
        t(
          "bank_remittance_receipts:upload_bank_remittance_receipts_form.messages.error.amount_less_than_unremitted_amount"
        ),
        (value, context) => {
          const { payments } =
            context.parent as BankRemittanceReceiptFormFields;
          if (payments && payments.length > 0) {
            return (
              value.value <=
              PriceUtils.sum(
                payments.map((p) => p.amount),
                "EUR"
              ).value!
            );
          }
        }
      ),
      bankRemittanceReceiptDetailList: Yup.array()
        .of(
          Yup.object({
            payment: Yup.object({
              id: Yup.number().required(withMessage.required),
              name: Yup.string().required(withMessage.required),
            }).required(withMessage.required),
            amount: Yup.object({
              value: Yup.number()
                .label("remitted_amount")
                .required(withMessage.required),
              currency: Yup.string().required(withMessage.required),
            }),
          })
        )
        .required(withMessage.required)
        .min(1, withMessage.min)
        .test(
          "amount_less_than_unremitted_amount",
          t(
            "bank_remittance_receipts:upload_bank_remittance_receipts_form.messages.error.amount_less_than_unremitted_amount"
          ),
          (value, context) => {
            const { payments } =
              context.parent as BankRemittanceReceiptFormFields;
            if (payments && payments.length > 0) {
              return value.some((detail) => {
                const payment = payments.find(
                  (p) => p.id === detail.payment.id
                );
                return (
                  payment &&
                  detail.amount.value <= payment.unremittedAmount.value
                );
              });
            }
          }
        ),

      file: Yup.mixed<File>().required(withMessage.required),
      sender: Yup.mixed<Company>()
        .label("sender")
        .required(withMessage.required),
      receiver: Yup.mixed<Company>()
        .label("receiver")
        .required(withMessage.required),
    });
  }, [t, withMessage]);
}
