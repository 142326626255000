import { Flex, Skeleton } from "@chakra-ui/react";

export default function LineSheetSetDetailRightSkeleton() {
  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      <Skeleton height={"34px"} borderRadius={"4px"} />
      <Skeleton height={"34px"} />
      <Skeleton height={"34px"} />
      <Skeleton height={"34px"} />
    </Flex>
  );
}
