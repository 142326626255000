import { FormikProps } from "formik";
import AppButton from "@/features/line-sheet-sets/app-button";
import { useLocation } from "react-router-dom";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppSubmitButtonProps {
  formik: FormikProps<any>;
  addOther?: boolean;
}
export default function AppSubmitButton({
  formik,
  addOther = false,
}: AppSubmitButtonProps) {
  const location = useLocation();
  const { navigate } = useRouterHelper();
  const { tTitle } = useI18nHelper();

  const defaultPage = useMemo(() => {
    if (/(ko|it|en)?\/line-sheet-sets.*/.test(location.pathname)) {
      return "/line-sheets";
    } else if (location.pathname.includes("edit")) {
      return location.pathname.substring(
        0,
        location.pathname.indexOf("edit") - 1
      );
    } else if (location.pathname.includes("upload")) {
      return location.pathname.substring(
        0,
        location.pathname.indexOf("upload") - 1
      );
    } else {
      return "/";
    }
  }, [location.pathname]);

  return (
    <AppButton
      variant={addOther ? "normal" : "primary"}
      onClick={async () => {
        const result = await formik.submitForm();
        // @ts-ignore
        if (result) {
          if (addOther) {
            formik.resetForm();
          } else {
            navigate(-1, {
              defaultPage,
            });
          }
        }
      }}
      isLoading={formik.isSubmitting}
    >
      {tTitle(addOther ? "common:upload_and_add_another.do" : "upload")}
    </AppButton>
  );
}
