import { Field, FieldProps, FormikProps } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { getFileNameWithoutExtension, StoredObject } from "@/features/types";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import InvoiceFileInput from "@/features/invoices/invoice-file-input";

interface InvoiceAttachmentsFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  fieldName: keyof T;
}

interface FormFields {
  id?: number;
}

export default function InvoiceAttachmentsFormControl<T extends FormFields>({
  formik,
  fieldName,
  isEditing,
  isDisabled,
}: InvoiceAttachmentsFormControlProps<T>) {
  const { tTitle } = useI18nHelper();
  const endOfFileRef = useRef<HTMLDivElement>(null);
  const value = formik.values[fieldName] as (
    | File
    | Pick<StoredObject, "id" | "name">
  )[];

  useEffect(() => {
    if (formik.values[fieldName] !== formik.initialValues[fieldName]) {
      endOfFileRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [fieldName, formik.initialValues, formik.values, value]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"24px"} />}>
        <Skeleton height={"48px"} />
      </AppPanel>
    );
  }

  return (
    <Field name={fieldName}>
      {(
        props: FieldProps<
          (File | Pick<StoredObject, "id" | "name" | "createdAt">)[],
          FormFields
        >
      ) => {
        if (
          props.form.values.id !== -1 &&
          (!isEditing || isDisabled) &&
          props.field.value.length === 0
        ) {
          return null;
        }

        return (
          <Flex flexDirection={"column"} gap={"8px"}>
            <AppPanel
              variant={"ghost"}
              title={tTitle("proof_of_divisions_files")}
              isCompact={true}
            >
              {isEditing && !isDisabled && (
                <AppFileDragAndDropZone
                  isMultiple={true}
                  onFilesAdd={(value: File[] | File) => {
                    const files = Array.isArray(value) ? value : [value];
                    formik.setValues((prev) => {
                      return {
                        ...prev,
                        [fieldName]: [
                          // @ts-ignore
                          ...prev[fieldName],
                          ...files,
                        ],
                      };
                    });
                  }}
                />
              )}
            </AppPanel>

            {props.field.value.length > 0 && (
              <AppPanel>
                <Flex flexDirection={"column"} gap={"8px"} width={"100%"}>
                  {props.field.value.map((file, index) => {
                    return (
                      <InvoiceFileInput
                        key={index}
                        value={file}
                        isReadOnly={!isEditing}
                        onChange={(value) => {
                          formik.setValues((prev) => {
                            return {
                              ...prev,
                              [fieldName]: (prev[fieldName] as []).map(
                                (f, i) => {
                                  return i === index ? value : f;
                                }
                              ),
                            };
                          });
                        }}
                        onDelete={() => {
                          formik.setValues((prev) => {
                            return {
                              ...prev,
                              [fieldName]: (prev[fieldName] as []).filter(
                                (f, i) => i !== index
                              ),
                            };
                          });
                        }}
                      />
                    );
                  })}
                </Flex>
                <div ref={endOfFileRef} />
              </AppPanel>
            )}
          </Flex>
        );
      }}
    </Field>
  );
}
