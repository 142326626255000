import { FormikProps } from "formik";
import { Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import React from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppButton from "@/features/line-sheet-sets/app-button";
import { isStoredObject, StoredObject } from "@/features/types";
import AppDownloadButton from "@/features/invoices/app-download-button";
import useIdentity from "@/features/ui/hooks/use-identity";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface InvoiceDetailPageHeaderProps<
  T extends { id?: number; file?: File | Pick<StoredObject, "id" | "name"> }
> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isUpdateQueued: boolean;
  title: string;
  baseUrl: string;
}

export default function InvoiceDetailPageHeader<
  T extends {
    id?: number;
    file?: File | Pick<StoredObject, "id" | "name">;
  }
>({
  title,
  formik,
  isUpdateQueued,
  isEditing = false,
  baseUrl,
}: InvoiceDetailPageHeaderProps<T>) {
  const { tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { navigate } = useRouterHelper();
  return (
    <AppListHeader
      headers={[
        <Heading key={"OrderConfirmationInvoice"} as={"h2"} fontSize={"14px"}>
          {title}
        </Heading>,
      ]}
      filters={[]}
      actions={[
        () => {
          if (isEditing) {
            return (
              <AppButton
                key={"Save"}
                variant={"primary"}
                isLoading={formik.isSubmitting}
                isDisabled={formik.isSubmitting || !formik.dirty}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                {tTitle("common:save")}
              </AppButton>
            );
          }
        },
        () => {
          if (!isEditing && formik.values.file) {
            return (
              <AppDownloadButton
                key={"Download"}
                value={
                  formik.initialValues.file &&
                  isStoredObject(formik.initialValues.file)
                    ? formik.initialValues.file.id
                    : undefined
                }
              />
            );
          }
        },
        () => {
          if (!isEditing && identity?.company?.type !== "BOUTIQUE") {
            return (
              <AppButton
                key={"Edit"}
                isLoading={isUpdateQueued}
                onClick={() => {
                  if (formik.values.id) {
                    navigate(`${baseUrl}/${formik.values.id}/edit`);
                  }
                }}
              >
                {tTitle("common:edit")}
              </AppButton>
            );
          }
        },
      ]}
      showNavigateToBack={true}
      emptyStackPage={baseUrl}
      blockNavigateToBack={formik.dirty && isEditing}
    />
  );
}
