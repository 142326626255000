import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Field, FieldProps, FormikProps } from "formik";
import { toTitle } from "@/utils/case";
import AppPriceInput, {
  PriceInputValue,
} from "@/features/invoices/app-price-input";
import React from "react";

interface FormFields {
  amount?: PriceInputValue;
  id?: number;
}

interface SimpleFigureFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
}

export default function SimpleFigureFormControl<T extends FormFields>({
  formik,
  isEditing = false,
  isDisabled = false,
}: SimpleFigureFormControlProps<T>) {
  const { t, tTitle } = useI18nHelper();
  const isReadOnly = !isEditing;
  // const isDisabled = isEditing && formik.values.id !== -1;
  return (
    <AppPanel title={tTitle("common:invoice_figures")}>
      <Flex gap={"12px"} flexDirection={"column"}>
        <Field name={"amount"}>
          {(props: FieldProps<FormFields["amount"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                alignItems={"start"}
                gap={"4px"}
              >
                <FormLabel
                  margin={"0"}
                  padding={"0"}
                  minHeight={"0"}
                  minWidth={"0"}
                  fontSize={"12px"}
                  color={"#444440"}
                >
                  {tTitle("amount")}
                </FormLabel>
                <AppPriceInput
                  value={props.field.value}
                  isDisabled={isDisabled}
                  isReadOnly={isReadOnly}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>
      </Flex>
    </AppPanel>
  );
}
