import React, { ButtonHTMLAttributes, useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppDatePickerInputProps
  extends React.DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  inputName: string;
  width: string;
  onInputClick: () => void;
  isDisabled?: boolean;
  onClear: () => void;
  isReadOnly?: boolean;
}
export default function AppDatePickerInput(
  props: AppDatePickerInputProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { value, inputName } = props;
  const { tLocalDateString, tLocalDateTimeString } = useI18nHelper();

  const name = useMemo(() => {
    if (typeof value === "string" && value.length > 0) {
      const jsDate = new Date(value);
      if (value.includes(" ")) {
        return tLocalDateTimeString(jsDate);
      } else {
        return tLocalDateString(jsDate);
      }
    }
    return inputName;
  }, [value, inputName, tLocalDateTimeString, tLocalDateString]);

  return (
    <AppSelect
      name={name}
      value={typeof value === "string" && value.length > 0 ? name : undefined}
      options={[]}
      width={props.width}
      isDisabled={props.isDisabled}
      isReadOnly={props.isReadOnly}
      buttonRef={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.onClick) {
          props.onClick(e);
        }
        props.onInputClick();
      }}
      onSelect={(value) => {
        if (value === undefined) {
          props.onClear();
        }
      }}
    />
  );
}
