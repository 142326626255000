import {
  Agency,
  Brand,
  Buyer,
  Company,
  Price,
  StoredObject,
} from "@/features/types";
import {
  DenseOrderSheetSet,
  OrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import {
  ProformaInvoice,
  ProformaInvoiceDetail,
} from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import { DensePayment } from "@/features/invoices/payment/payment.type";

export type PaymentMethod = "NORMAL" | "LOAN" | "SKIP";

export interface AbstractInvoice {
  id: number;
  number: string;
  name: string;
  season: string;
  issuedToCompany: Pick<Buyer, "id" | "name">;
  issuedByCompany: Company;
  issuedThroughCompany: Pick<Agency, "id" | "name">;
  issuedOn: Date;

  paidBy: "BUYER" | "AGENCY";
  paymentMethod: PaymentMethod;
  //인보이스 금액
  totalAmount: Price;
  //입금 필요 금액(oc - 디파짓, p는 인보이스금액 - 크레딧, 디파짓금액, 실제 입금해도 변화 없음, 실제 입금필요금액은 연산해야함)
  totalRequiredAmount: Price;
  //입금 금액 (안씀, 죽음 by 심우)
  // totalRemittedAmount: number;

  //실제 입금한 금액인듯
  // totalBuyerToBoutiqueAmount: number;
  // totalBuyerToAgencyAmount: number;
  // totalAgencyToBoutiqueAmount: number;
  //외상 금액: totalAgencyToBoutiqueAmount - totalBuyerToAgencyAmount
  //물품대금금액: totalRequiredAmount - totalBuyerToBoutiqueAmount - totalBuyerToAgencyAmount

  isRemittedToBoutique: boolean;
  isRemittedToAgency: boolean;
  status: "NORMAL" | "CANCELED" | "COMPLETED" | "REPLACED";
  isPublic: boolean;

  orderSheetSets: Pick<OrderSheetSet, "id">[];

  file: StoredObject | null;
  // bankRemittanceReceipts: Pick<BankRemittanceReceipt, "id">[];
  publicComment: string | null;
  privateComment: string | null;
  createdAt: Date;
  payments: { id: number }[];
}

export function isInvoiceStatus(str: string): str is AbstractInvoice["status"] {
  return ["NORMAL", "CANCELED", "COMPLETED", "REPLACED"].includes(str);
}

export interface OrderConfirmationInvoice extends AbstractInvoice {
  proformaInvoices: ProformaInvoice[];
  creditInvoices: CreditInvoice[];
  depositInvoice: DepositInvoice | null;
  actualRemainingDepositAmount: Price;
  expectedRemainingDepositAmount: Price;
  isRead: boolean;

  orderConfirmationInvoiceDetailList: OrderConfirmationInvoiceDetail[];
}

export function isOrderConfirmationInvoice(
  invoice: OrderConfirmationInvoice | ProformaInvoice
): invoice is OrderConfirmationInvoice {
  return (invoice as ProformaInvoice).usedDeposit === undefined;
}

export interface OrderConfirmationInvoiceDetail {
  id: number;
  amount: Price;
  // requiredAmount: number;
  // remittedAmount: number; //to boutique만 인데..
  actualRemainingDepositAmount: Price;
  expectedRemainingDepositAmount: Price;
  brand: Pick<Brand, "id" | "name">;
  orderSheetSet: {
    id: number;
  } | null;
}

// oc.requiredAmount - (oc.buyerToAgency - oc.buyerToBoutique); //목록
// detail.requiredAmount - detail.remittedAmount(= oc.buyerToBoutique)

export function isOrderConfirmationInvoiceDetails(
  details: OrderConfirmationInvoiceDetail[] | ProformaInvoiceDetail[]
): details is OrderConfirmationInvoiceDetail[] {
  return (
    details.length > 0 &&
    (details as OrderConfirmationInvoiceDetail[])[0]
      .actualRemainingDepositAmount !== undefined
  );
}

export function isOrderConfirmationInvoiceDetail(
  details: OrderConfirmationInvoiceDetail | ProformaInvoiceDetail
): details is OrderConfirmationInvoiceDetail {
  return (
    (details as OrderConfirmationInvoiceDetail).actualRemainingDepositAmount !==
    undefined
  );
}

export function isProformaInvoiceDetails(
  details: OrderConfirmationInvoiceDetail[] | ProformaInvoiceDetail[]
): details is ProformaInvoiceDetail[] {
  return !isOrderConfirmationInvoiceDetails(details);
}

export function isProformaInvoiceDetail(
  details: OrderConfirmationInvoiceDetail | ProformaInvoiceDetail
): details is ProformaInvoiceDetail {
  return !isOrderConfirmationInvoiceDetail(details);
}

export interface DenseOrderConfirmationInvoice
  extends Omit<OrderConfirmationInvoice, "orderSheetSets" | "payments"> {
  orderSheetSets: DenseOrderSheetSet[];
  payments: DensePayment[];
}
