import SocialButton from "@/features/auth/social-buttons/social-button";

export default function KakaoSocialButton() {
  return (
    <SocialButton
      bgColor="#FEE500"
      src="/ic_kakao.svg"
      name="Kakao"
      padding="10px"
    />
  );
}
