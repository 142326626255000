import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import {
  LightOrderSheet,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import { Company } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface UpdateOrderSheetToBeSubmittedRequest {
  toBeSubmitted: {
    id: number;
  } | null;
}

export interface UpdateOrderSheetToBeSubmittedResponse
  extends LightOrderSheet {}

export interface UpdateOrderSheetToBeSubmittedPathParameter {
  agencyId: number;
  orderSheetSetId: number;
  orderSheetId: number;
}

export const updateOrderSheetToBeSubmittedTemplateUrl =
  "/agencies/:agencyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/to-be-submitted";

export const updateOrderSheetToBeSubmittedKey: YWRKey<UpdateOrderSheetToBeSubmittedPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateOrderSheetToBeSubmittedTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateOrderSheetToBeSubmitted() {
  const fetcher = useFetcher<
    UpdateOrderSheetToBeSubmittedRequest,
    UpdateOrderSheetToBeSubmittedResponse
  >();

  return useYWR(updateOrderSheetToBeSubmittedKey, fetcher);
}
