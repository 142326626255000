import { IdentityParameter, StoredObject } from "@/features/types";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";
import { CreateCreditInvoiceRequest } from "@/features/invoices/credit-invoices/hooks/use-create-credit-invoice";

export interface UpdateCreditInvoiceRequest
  extends Omit<CreateCreditInvoiceRequest, "file"> {
  id: number;
  file: Pick<StoredObject, "id" | "name">;
}
export interface UpdateCreditInvoiceResponse extends CreditInvoice {}
export interface UpdateCreditInvoicePathParameter extends IdentityParameter {
  creditInvoiceId: number;
}
export const updateCreditInvoiceTemplateUrl = `/:by/:companyId/credit-invoices/:creditInvoiceId`;

export const updateCreditInvoiceKey: YWRKey<UpdateCreditInvoicePathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateCreditInvoiceTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateCreditInvoice(
  config?: AxiosRequestConfig<UpdateCreditInvoiceRequest>
) {
  const fetcher = useFetcher<
    UpdateCreditInvoiceRequest,
    UpdateCreditInvoiceResponse
  >();
  return useYWR(updateCreditInvoiceKey, fetcher, config);
}
