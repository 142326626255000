import { useCallback } from "react";
import { NavigateOptions, To, useNavigate, useParams } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import { use } from "i18next";
import { getDefaultPageByIdentity } from "@/pages";
import useIdentity from "@/features/ui/hooks/use-identity";

export interface MyNavigateOptions extends NavigateOptions {
  defaultPage?: string;
  appendReferer?: boolean;
}

export default function useRouterHelper() {
  const identity = useIdentity();
  const { locale } = useParams();
  const sourceNavigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const calcNavigateTo = useCallback(
    (to: string, option?: MyNavigateOptions) => {
      let calcTo = to;
      if (locale && !to.startsWith(`/${locale}`)) {
        calcTo = `/${locale}${to}`;
      }

      if (option?.appendReferer) {
        let referer = "";
        if (location.search.includes("referer=")) {
          const searchParams = new URLSearchParams(location.search);
          referer = encodeURIComponent(searchParams.get("referer") || "");
        } else {
          referer = encodeURIComponent(location.pathname + location.search);
        }

        if (calcTo.includes("?")) {
          calcTo += `&referer=${referer}`;
        } else {
          calcTo += `?referer=${referer}`;
        }
      }

      return calcTo;
    },
    [locale, location.pathname, location.search]
  );

  const navigate = useCallback(
    (to: string | number, option?: MyNavigateOptions) => {
      if (typeof to === "number") {
        if (to === -1) {
          const isLast = window.history.state.idx === 0;
          if (isLast) {
            //referer가 있으면 개를 따라가게해야겟다.ㅇㅋ

            let referer;
            if (location.search.includes("referer=")) {
              const rawReferer = new URLSearchParams(location.search).get(
                "referer"
              );
              if (rawReferer) {
                referer = decodeURIComponent(rawReferer);
              }
            }

            const to = calcNavigateTo(
              referer ||
                option?.defaultPage ||
                getDefaultPageByIdentity(identity),
              option
            );
            setTimeout(() => {
              sourceNavigate(to, option);
            }, 300);
            return;
          }
        }
        sourceNavigate(to);
      } else {
        // setTimeout(() => {
        //   sourceNavigate(calcNavigateTo(to), option);
        // }, 300);
        sourceNavigate(calcNavigateTo(to, option), option);
      }
    },
    [sourceNavigate, location.search, calcNavigateTo, identity]
  );

  const navigateToLineSheetSetPage = useCallback(
    (lineSheetSetId: number) => {
      navigate(`/line-sheet-sets/${lineSheetSetId}`, { appendReferer: true });
    },
    [navigate]
  );

  const navigateToOrderSheetSetPage = useCallback(
    (lineSheetSetId: number, orderSheetSetId: number) => {
      navigate(
        `/line-sheet-sets/${lineSheetSetId}?order_sheet_set_id=${orderSheetSetId}`,
        { appendReferer: true }
      );
    },
    [navigate]
  );

  const navigateToOrderSheetSetDraftPage = useCallback(
    (orderSheetSetId: number, orderSheetId?: number, lineSheetId?: number) => {
      if (orderSheetId) {
        navigate(
          `/order-sheets/${orderSheetSetId}?order_sheet_id=${orderSheetId}`,
          { appendReferer: true }
        );
      } else if (lineSheetId) {
        navigate(
          `/order-sheets/${orderSheetSetId}?line_sheet_id=${lineSheetId}`,
          { appendReferer: true }
        );
      }
    },
    [navigate]
  );

  const navigateToLineSheetSetDraftPage = useCallback(
    (lineSheetSetId: number, lineSheetId: number) => {
      navigate(`/line-sheets/${lineSheetSetId}?line_sheet_id=${lineSheetId}`, {
        appendReferer: true,
      });
    },
    [navigate]
  );

  return {
    navigate,
    navigateToLineSheetSetPage,
    navigateToOrderSheetSetPage,
    navigateToLineSheetSetDraftPage,
    navigateToOrderSheetSetDraftPage,
    calcNavigateTo,
    location,
    searchParams,
  };
}
