export function isDirty<T extends {}>(
  currentValues: T,
  initialValues: T,
  excludedFields: (keyof T)[] = []
) {
  for (const key in currentValues) {
    if (
      currentValues.hasOwnProperty(key) &&
      !excludedFields.includes(key as keyof T) &&
      currentValues[key] !== initialValues[key]
    ) {
      return true;
    }
  }
  return false;
}

export function withWithHandleChange<T, K extends keyof T>(
  source: T,
  onChange: (value: T) => void
) {
  return (key: K) => {
    return (value: T[K]) => {
      onChange({
        ...source,
        [key]: value,
      });
    };
  };
}
