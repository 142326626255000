import { toCamel, toSnake } from "@/utils/case";
import {
  ParseString,
  StringTransformer,
} from "@/features/ui/helpers/string-transformer";
import { SearchParamsParser } from "@/features/ui/helpers/search-params-parser";
import { SetURLSearchParams, URLSearchParamsInit } from "react-router-dom";
import { createSearchParams } from "react-router-dom/dist/dom";
import { NavigateOptions } from "react-router";

export class SearchParamsTransformer<T extends {}> {
  private readonly setUrlSearchParams: SetURLSearchParams;
  private readonly entries: {
    [K in keyof T & string]: {
      parse: (rawValue: string | string[]) => T[K] | undefined;
      stringify: (value: T[K]) => string | string[] | undefined;
    };
  };

  constructor(
    setUrlSearchParams: SetURLSearchParams,
    entries: {
      [K in keyof T & string]: {
        parse: (rawValue: string | string[]) => T[K] | undefined;
        stringify: (value: T[K]) => string | string[] | undefined;
      };
    }
  ) {
    this.setUrlSearchParams = setUrlSearchParams;
    this.entries = entries;
  }

  parse(urlSearchParams: URLSearchParams): T {
    const searchParamsParser = new SearchParamsParser(
      urlSearchParams,
      Object.keys(this.entries)
        .map((_key) => {
          const key = _key as keyof T & string;
          return [key, this.entries[key].parse];
        })
        .reduce((acc, i) => {
          const [_key, _func] = i;
          const key = _key as keyof T & string;
          const func = _func as ParseString<T>;
          acc[key] = func;
          return acc;
        }, {} as Record<keyof T & string, ParseString<T>>)
    );

    return searchParamsParser.parse();
  }

  patch(_key: keyof T & string, _value: T[keyof T & string]) {
    if (this.entries.hasOwnProperty(_key)) {
      const func = this.entries[_key].stringify;
      const value = func(_value);
      this.setUrlSearchParams((prev) => {
        const key = toSnake(_key);
        const next = new URLSearchParams(prev);
        next.delete(key);
        if (value) {
          this._set(next, key, value);
        }
        return next;
      });
    }
  }

  update(partial: Partial<T>) {
    this.setUrlSearchParams(
      (prev) => {
        const next = new URLSearchParams(prev);

        Object.keys(partial).forEach((_key) => {
          const key = _key as keyof T & string;
          if (this.entries.hasOwnProperty(key)) {
            const func = this.entries[key].stringify;
            const value = partial[key];
            const snakeKey = toSnake(key);
            next.delete(snakeKey);
            if (value) {
              const stringifiedValue = func(value);
              if (stringifiedValue) {
                this._set(next, snakeKey, stringifiedValue);
              }
            }
          }
        });
        return next;
      },
      { replace: true }
    );
  }

  private _set(
    urlSearchParams: URLSearchParams,
    snakeKey: string,
    value: string | string[]
  ) {
    if (StringTransformer.isStringArray(value)) {
      urlSearchParams.delete(snakeKey);
      value.forEach((item) => {
        urlSearchParams.append(snakeKey, encodeURIComponent(item));
      });
    } else {
      urlSearchParams.set(snakeKey, encodeURIComponent(value));
    }
  }

  delete(_key: keyof T & string) {
    if (this.entries.hasOwnProperty(_key)) {
      this.setUrlSearchParams((prev) => {
        const key = toSnake(_key);
        const next = new URLSearchParams(prev);
        next.delete(key);
        return prev;
      });
    }
  }

  clear(defaultObject?: T) {
    this.setUrlSearchParams(
      (prev) => {
        const next = new URLSearchParams(prev);

        Object.keys(this.entries).forEach((_key) => {
          const key = _key as keyof T & string;
          const snakeKey = toSnake(key);
          next.delete(snakeKey);
        });

        if (defaultObject) {
          Object.keys(defaultObject).forEach((_key) => {
            if (this.entries.hasOwnProperty(_key)) {
              const key = _key as keyof T & string;
              const value = defaultObject[key];
              const func = this.entries[key].stringify;
              const stringifiedValue = func(value);
              if (stringifiedValue) {
                const snakeKey = toSnake(key);
                this._set(next, snakeKey, stringifiedValue);
              }
            }
          });
        }
        return next;
      },
      {
        replace: true,
      }
    );
  }

  set(updater: (prev: T) => T, navigateOpts?: NavigateOptions) {
    this.setUrlSearchParams((prev) => {
      const nextObj = updater(this.parse(prev));
      const next = new URLSearchParams(prev);

      Object.keys(nextObj).forEach((_key) => {
        const key = _key as keyof T & string;
        const value = nextObj[key];

        if (this.entries[key] === undefined) {
          this._set(next, toSnake(key), value as string | string[]);
        } else if (value !== undefined) {
          const func = this.entries[key].stringify;
          const stringifiedValue = func(value);
          if (stringifiedValue !== undefined) {
            const snakeKey = toSnake(key);
            this._set(next, snakeKey, stringifiedValue);
          }
        } else {
          const snakeKey = toSnake(key);
          next.delete(snakeKey);
        }
      });
      return next;
    }, navigateOpts);
  }
}
