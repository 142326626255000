import { Flex } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback } from "react";
import PriceConditionSelector, {
  PriceConditionOption,
} from "@/features/order-status/price-condition-selector";
import { toTitle } from "@/utils/case";
import { useTranslation } from "react-i18next";

interface PriceConditionExclusiveSelectorProps {
  value: PriceConditionOption[];
  onSelect: (value: PriceConditionOption[]) => void;
  isDisabled?: boolean;
}

export default function PriceConditionExclusiveSelector({
  value,
  onSelect,
  isDisabled,
}: PriceConditionExclusiveSelectorProps) {
  const { t } = useTranslation();
  const handleAddClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      onSelect([
        ...value,
        {
          category: "ALL",
          gender: "ALL",
          type: "DISCOUNT",
          value: 0,
        },
      ]);
    },
    [onSelect, value]
  );

  const withHandleDelete = useCallback(
    (index: number) => {
      return () => {
        const next = [...value];
        next.splice(index, 1);
        onSelect(next);
      };
    },
    [value, onSelect]
  );

  const withHandleSelect = useCallback(
    (index: number) => {
      return (selectedValue: PriceConditionOption) => {
        const next = [...value];
        next[index] = selectedValue;
        onSelect(next);
      };
    },
    [value, onSelect]
  );

  const selectedCategories = value
    .map((value) => value.category)
    .filter((category): category is string => category !== undefined);

  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      {value.map((value, index) => {
        const excludeCategories = value.category
          ? selectedCategories.filter((category) => category !== value.category)
          : selectedCategories;

        return (
          <PriceConditionSelector
            key={`PriceConditionSelector_${index}`}
            value={value}
            // excludeCategories={excludeCategories}
            onSelect={withHandleSelect(index)}
            onDelete={withHandleDelete(index)}
          />
        );
      })}
      <AppButton
        width={"100%"}
        variant={"normal"}
        isDisabled={isDisabled}
        onClick={handleAddClick}
      >
        <SVG
          src={"/icons/icon_add.svg"}
          width={"14px"}
          height={"14px"}
          style={{ marginRight: "8px" }}
        />
        {toTitle(t("add_condition"))}
      </AppButton>
    </Flex>
  );
}
