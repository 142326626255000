import { usePersistedQuery } from "@/features/order-sheet-sets/hooks/use-persisted-query";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { useCallback } from "react";
import { NavigateOptions } from "react-router";
import { SheetTabFilterQuery } from "@/features/order-sheet-sets/sheet-tab-filter";

export interface LineSheetSetSelectQuery {
  lineSheetId?: number;
  lineSheetTabIndex?: number;
}

export interface LineSheetSetPagePersistedQuery
  extends LineSheetSetSelectQuery,
    SheetTabFilterQuery {
  referer: string;
}

export default function useLineSheetSetPagePersistedQuery() {
  const { value, set } = usePersistedQuery<LineSheetSetPagePersistedQuery>({
    entries: {
      referer: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      lineSheetId: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      lineSheetTabIndex: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      brandName__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      gender__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      category__eq: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
      quantity__gte: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      brandName__like: {
        parse: StringTransformer.parseString,
        stringify: StringTransformer.stringifyString,
      },
    },
  });

  return {
    value,
    set,
  };
}
