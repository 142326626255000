import {
  getGetProformaInvoiceKey,
  GetProformaInvoiceRequest,
  GetProformaInvoiceResponse,
} from "@/features/invoices/proforma-invoices/hooks/use-get-proforma-invoice";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseOrderDenseConfirmationInvoiceResponse,
  GetDenseProformaInvoiceRequest,
  parseGetDenseProformaInvoiceKey,
} from "@/features/invoices/proforma-invoices/hooks/use-get-dense-proforma-invoice";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondensePayments from "@/features/invoices/payment/hooks/use-condense-payments";
import useIdentity from "@/features/ui/hooks/use-identity";

export default function useGetDenseProformaFetcher() {
  const getProformaFetcher = useFetcher<
    GetProformaInvoiceRequest,
    GetProformaInvoiceResponse
  >();

  const condenseOrderSheetSets = useCondenseOrderSheetSets();
  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();
  const condensePayments = useCondensePayments();
  const identity = useIdentity();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseProformaInvoiceRequest>
    ): Promise<GetDenseOrderDenseConfirmationInvoiceResponse> => {
      const { parameter, request } = parseGetDenseProformaInvoiceKey(config);

      const getProformaResponse = await getProformaFetcher(
        getGetProformaInvoiceKey(parameter, request)!
      );

      if (getProformaResponse) {
        await Promise.all([
          condenseOrderConfirmationInvoices(
            [getProformaResponse],
            (i) => i.orderConfirmationInvoices,
            (item, value) => {
              if (Array.isArray(value)) {
                item.orderConfirmationInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          condenseOrderSheetSets(
            [getProformaResponse],
            (i) => {
              return i.orderSheetSets;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.orderSheetSets = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          identity?.company?.type !== "BOUTIQUE"
            ? condensePayments(
                [getProformaResponse],
                (i) => i.payments,
                (item, value) => {
                  if (Array.isArray(value)) {
                    item.payments = value;
                  }
                },
                {
                  by: parameter.by,
                  companyId: parameter.companyId,
                }
              )
            : Promise.resolve(),
        ]);
      }
      // @ts-ignore
      return Promise.resolve(getProformaResponse);
    };
  }, [
    getProformaFetcher,
    condenseOrderConfirmationInvoices,
    condenseOrderSheetSets,
    condensePayments,
  ]);
}
