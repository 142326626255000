import { SheetTab } from "@/features/order-sheets/sheet.type";
import {
  calcIdentityUrlPrefix,
  parseYWRUrl,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import { IdentityParameter } from "@/features/types";
import { AxiosRequestConfig } from "axios";
import { singularizeCompanyType } from "@/features/ui/helpers/identity-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useListAllSheetTabsFetcher from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs-fetcher";

export interface ListAllSheetTabsRequest {
  lineSheetSetId: number;
  lineSheetId: number;
  sheetTabIndexes: number[];
}

export interface ListAllSheetTabsResponse {
  sheetTabs: SheetTab[];
  _orderSheetSetId: number;
  _orderSheetId: number;
}

export interface ListAllSheetTabsPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
}

export function getListAllSheetTabsKey(
  parameter?: ListAllSheetTabsPathParameter,
  request?: ListAllSheetTabsRequest
): SWRObjectKey<ListAllSheetTabsRequest> | null {
  if (parameter && request) {
    const { orderSheetSetId, orderSheetId } = parameter;
    let url = calcIdentityUrlPrefix(parameter);
    url += `/sheet-sets/${orderSheetSetId}/sheets/${orderSheetId}/sheet-tabs`;
    return {
      url,
      params: request,
    };
  }
  return null;
}

export function parseListAllSheetTabsKey(
  key: AxiosRequestConfig<ListAllSheetTabsRequest>
): {
  parameter: ListAllSheetTabsPathParameter;
  request: ListAllSheetTabsRequest;
} {
  const { url, params } = key;
  const obj = parseYWRUrl(
    "/:companyType/:companyId/sheet-sets/:orderSheetSetId/sheets/:orderSheetId/sheet-tabs",
    url!!
  );

  const parameter: ListAllSheetTabsPathParameter = {
    by: singularizeCompanyType(obj["companyType"]),
    companyId: parseInt(obj["companyId"]),
    orderSheetSetId: parseInt(obj["orderSheetSetId"]),
    orderSheetId: parseInt(obj["orderSheetId"]),
  };

  return {
    parameter,
    request: key.params!!,
  };
}

export default function useListAllSheetTabs(
  parameter?: ListAllSheetTabsPathParameter,
  request?: ListAllSheetTabsRequest,
  config: SWRConfiguration = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  }
) {
  const fetcher = useListAllSheetTabsFetcher();

  return useSWR(getListAllSheetTabsKey(parameter, request), fetcher, config);
}
