import {
  updateLineSheetReviewStatusKey,
  UpdateLineSheetReviewStatusRequest,
  UpdateLineSheetReviewStatusResponse,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-review-status";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";

export default function useUpdateLineSheetReviewStatusInfinite() {
  const fetcher = useFetcher<
    UpdateLineSheetReviewStatusRequest,
    UpdateLineSheetReviewStatusResponse
  >();
  return useYWRInfinite(updateLineSheetReviewStatusKey, fetcher);
}
