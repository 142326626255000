import useSWR, { SWRConfiguration } from "swr";
import useListAllSheetTabsWithRevisionFetcher from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs-revision-fetcher";
import {
  getOrderSheetRevisionKey,
  GetOrderSheetRevisionPathParameter,
  GetOrderSheetRevisionRequest,
} from "./use-get-order-sheet-revision";

export default function useGetListOrderSheetTabsWithRevision(
  parameter: GetOrderSheetRevisionPathParameter,
  request: GetOrderSheetRevisionRequest,
  config: SWRConfiguration = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  }
) {
  const fetcher = useListAllSheetTabsWithRevisionFetcher();
  return useSWR(getOrderSheetRevisionKey(parameter, request), fetcher, config);
}
