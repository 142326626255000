import useFetcher from "@/utils/use-fetcher";
import {
  getListOrderConfirmationInvoicesKey,
  ListOrderConfirmationInvoicesRequest,
  ListOrderConfirmationInvoicesResponse,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-order-confirmation-invoices";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDenseOrderConfirmationInvoicesRequest,
  ListDenseOrderConfirmationInvoicesResponse,
  parseListDenseOrderConfirmationInvoicesKey,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";

export default function useListDenseOrderConfirmationInvoicesFetcher() {
  const listOrderConfirmationInvoiceFetcher = useFetcher<
    ListOrderConfirmationInvoicesRequest,
    ListOrderConfirmationInvoicesResponse
  >();
  const condenseOrderSheetSets = useCondenseOrderSheetSets();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseOrderConfirmationInvoicesRequest>
    ): Promise<ListDenseOrderConfirmationInvoicesResponse> => {
      const { parameter, request } =
        parseListDenseOrderConfirmationInvoicesKey(config);

      const listOrderConfirmationInvoicesResponse =
        await listOrderConfirmationInvoiceFetcher(
          getListOrderConfirmationInvoicesKey(parameter, request)!
        );

      if (
        listOrderConfirmationInvoicesResponse.orderConfirmationInvoices.length >
        0
      ) {
        const orderSheetSetsPromise = condenseOrderSheetSets(
          listOrderConfirmationInvoicesResponse.orderConfirmationInvoices,
          (item) => {
            return item.orderSheetSets;
          },
          (item, values) => {
            if (Array.isArray(values)) {
              item.orderSheetSets = values;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        await Promise.all([orderSheetSetsPromise]);
      }

      // @ts-ignore
      return Promise.resolve(listOrderConfirmationInvoicesResponse);
    };
  }, [listOrderConfirmationInvoiceFetcher, condenseOrderSheetSets]);
}
