import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { OrderStatisticByType } from "@/features/dashboard/dashboard.type";

export interface ListOrderStatisticsByTypeRequest {
  "madeByCompany.id__eq"?: number;
  season__gte?: string;
  season__lte?: string;
  season__in?: string[];
}
export interface ListOrderStatisticsByTypeResponse {
  totalOrderStatistic: {
    orderedAmount: number;
    orderedQuantity: number;
    confirmedAmount: number;
    confirmedQuantity: number;
  };
  orderStatisticsByType: OrderStatisticByType[];
}

export interface ListOrderStatisticsByTypeParameter extends IdentityParameter {}

export const listOrderStatisticsByTypeTemplateUrl = `/:by/:companyId/order-statistics-by-type`;

export function getListOrderStatisticsByTypeKey(
  parameter?: ListOrderStatisticsByTypeParameter,
  request?: ListOrderStatisticsByTypeRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listOrderStatisticsByTypeTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function useListOrderStatisticsByType(
  parameters?: ListOrderStatisticsByTypeParameter,
  request?: ListOrderStatisticsByTypeRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListOrderStatisticsByTypeRequest,
    ListOrderStatisticsByTypeResponse
  >();

  return useSWR(
    getListOrderStatisticsByTypeKey(parameters, request),
    fetcher,
    config
  );
}
