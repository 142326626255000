import { AxiosProgressEvent, AxiosRequestConfig, Method } from "axios";
import { useCallback, useReducer, useRef } from "react";
import { ErrorResponse } from "@/utils/uno-axios";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface YWRConfig<Request = any, Response = any> {
  rethrow?: boolean;
  autoClear?: boolean;
  fetcher?: (config: AxiosRequestConfig<Request>) => Promise<Response>;
}

export interface YWRResponse<PathParameter, Request, Response> {
  key?: string;
  request?: Request;
  data?: Response;
  error?: ErrorResponse;
  uploadProgress?: Progress;
  isLoading: boolean;
  clear: () => void;
}

type Action<Request, Response> =
  | { type: "start"; key: string; request: Request }
  | { type: "success"; data: Response }
  | { type: "error"; error: ErrorResponse }
  | { type: "clear" }
  | { type: "uploadProgress"; progress: Progress };

interface Progress {
  loaded: number;
  total: number;
  percent: number;
}

function ywrReducer<PathParameter, Request, Response>(
  prevState: YWRResponse<PathParameter, Request, Response>,
  action: Action<Request, Response>
): YWRResponse<PathParameter, Request, Response> {
  switch (action.type) {
    case "start":
      return {
        ...prevState,
        key: action.key,
        request: action.request,
        isLoading: true,
        data: undefined,
        error: undefined,
      };
    case "uploadProgress":
      return {
        ...prevState,
        uploadProgress: action.progress,
      };
    case "success":
      return {
        ...prevState,
        isLoading: false,
        data: action.data,
        error: undefined,
      };
    case "error":
      return {
        ...prevState,
        isLoading: false,
        data: undefined,
        error: action.error,
        uploadProgress: undefined,
      };
    case "clear":
      return {
        ...prevState,
        request: undefined,
        isLoading: false,
        data: undefined,
        error: undefined,
        uploadProgress: undefined,
      };
  }
}

export function getConfig<PathParameter, Request>(
  key: YWRKey<PathParameter>,
  parameter: PathParameter,
  request: Request,
  defaultConfig?: AxiosRequestConfig<Request>,
  fireConfig?: AxiosRequestConfig<Request>
) {
  const { url: urlOrFunc, method } = key;
  const config: AxiosRequestConfig<Request> = {
    url: typeof urlOrFunc === "string" ? urlOrFunc : urlOrFunc(parameter),
    method,
    ...defaultConfig,
    ...fireConfig,
  };

  if (config.method === "get" || config.method === "GET") {
    config.params = {
      ...config.params,
      ...request,
    };
  } else {
    config.data = request;
  }

  return config;
}

export interface YWRKey<PathParameter> {
  url: string | ((parameter: PathParameter) => string);
  method: Method;
}

//imitate swr
export default function useYWR<PathParameter, Request, Response, Error = any>(
  key: YWRKey<PathParameter>,
  fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>,
  defaultConfig?: AxiosRequestConfig<Request>
) {
  const initialArgumentsRef = useRef({
    key,
    fetcher,
    defaultConfig,
  });

  //const firedKeyRef = useRef<string | undefined>();

  const [response, dispatch] = useReducer(
    ywrReducer<PathParameter, Request, Response>,
    {
      key: undefined,
      request: undefined,
      data: undefined,
      error: undefined,
      isLoading: false,
      uploadProgress: undefined,
      clear: () => dispatch({ type: "clear" }),
    }
  );

  const calcConfig = useCallback(
    (
      parameter: PathParameter,
      request: Request,
      fireConfig?: AxiosRequestConfig<Request>
    ) => {
      const { key, defaultConfig } = initialArgumentsRef.current;
      const { url: urlOrFunc, method } = key;
      const config = {
        url: calcYWRUrl(key, parameter),
        method,
        ...defaultConfig,
        ...fireConfig,
      };

      if (config.method === "get" || config.method === "GET") {
        config.params = {
          ...config.params,
          ...request,
        };
      } else {
        config.data = request;
      }

      return config;
    },
    []
  );

  const fire = useCallback(
    async (
      parameter: PathParameter,
      request: Request,
      ywrConfig?: YWRConfig,
      fireConfig?: AxiosRequestConfig<Request>
    ) => {
      const config = calcConfig(parameter, request, fireConfig);
      const { fetcher: _initialFetcher } = initialArgumentsRef.current;
      const datumKey = config.url;
      const fetcher = ywrConfig?.fetcher || _initialFetcher;
      // const { fetcher } = initialArgumentsRef.current;

      //const fetcher = ywrConfig?.fetcher || initialArgumentsRef.current.fetcher;

      try {
        dispatch({ type: "start", key: datumKey, request: request });
        const response = await fetcher(config);
        dispatch({ type: "success", data: response });
        return Promise.resolve(response);
      } catch (error: any) {
        dispatch({ type: "error", error: error });
        if (ywrConfig?.rethrow) {
          throw error;
        }
        return Promise.reject(error);
      }
    },
    [calcConfig, dispatch]
  );

  return {
    ...response,
    fire,
    ywrKey: initialArgumentsRef.current.key,
  };
}
