import { ReviewStatus } from "@/features/line-sheet-sets/line-sheet-set.types";
import AppLabel, { AppLabelProps } from "@/features/ui/app-label";
import { toLower } from "@/utils/case";

interface AppReviewStatusLabelProps {
  value: ReviewStatus | null;
}

function getLevel(value: ReviewStatus): AppLabelProps["level"] {
  switch (value) {
    case "APPROVED":
      return "SUCCESS";
    case "REJECTED":
      return "ERROR";
    default:
      return "LIGHT";
  }
}

export default function AppReviewStatusLabel({
  value,
}: AppReviewStatusLabelProps) {
  if (!value) {
    return null;
  }
  return (
    <AppLabel
      nameKey={`line_sheet_set.review_status.${toLower(value)}`}
      level={getLevel(value)}
    />
  );
}
