import {
  LightLineSheet,
  ReviewStatus,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface UpdateLineSheetReviewStatusRequest {
  reviewStatus: ReviewStatus;
}

export interface UpdateLineSheetReviewStatusResponse extends LightLineSheet {}

export interface UpdateLineSheetReviewStatusPathParameter {
  agencyId: number;
  lineSheetSetId: number;
  lineSheetId: number;
}

export const UpdateLineSheetReviewStatusTemplateUrl = `/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/line-sheets/:lineSheetId/review-status`;

export const updateLineSheetReviewStatusKey: YWRKey<UpdateLineSheetReviewStatusPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(UpdateLineSheetReviewStatusTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateLineSheetReviewStatus() {
  const fetcher = useFetcher<
    UpdateLineSheetReviewStatusRequest,
    UpdateLineSheetReviewStatusResponse
  >();

  return useYWR(updateLineSheetReviewStatusKey, fetcher);
}
