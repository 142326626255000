import { ListOrderConfirmationInvoicesRequest } from "@/features/invoices/order-confirmation-invoices/hooks/use-list-order-confirmation-invoices";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { keyBy, uniq } from "lodash";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  DenseOrderConfirmationInvoice,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useListDenseOrderConfirmationInvoicesFetcher from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices-fetcher";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseOrderConfirmationInvoicesRequest
  extends ListOrderConfirmationInvoicesRequest {}

export interface ListDenseOrderConfirmationInvoicesResponse
  extends PageNumberBasedListResponse {
  orderConfirmationInvoices: DenseOrderConfirmationInvoice[];
}

export interface ListDenseOrderConfirmationInvoicesPathParameter
  extends IdentityParameter {}

export const listDenseOrderConfirmationInvoicesTemplateUrl =
  "/:by/:companyId/dense-order-confirmation-invoices";

export function getListDenseOrderConfirmationInvoicesKey(
  parameter?: ListDenseOrderConfirmationInvoicesPathParameter,
  request?: ListDenseOrderConfirmationInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseOrderConfirmationInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListDenseOrderConfirmationInvoicesKey(
  key: AxiosRequestConfig<ListDenseOrderConfirmationInvoicesRequest>
) {
  const urlParser =
    new UrlParser<ListDenseOrderConfirmationInvoicesPathParameter>(
      listDenseOrderConfirmationInvoicesTemplateUrl,
      {
        by: StringTransformer.parseBy,
        companyId: StringTransformer.parseId,
      }
    );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function readDenseOrderConfirmationInvoiceProactively(
  orderConfirmationInvoiceId: number
) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-order-confirmation-invoices/,
    "orderConfirmationInvoices",
    orderConfirmationInvoiceId
  );
}

export default function useListDenseOrderConfirmationInvoices(
  parameter?: ListDenseOrderConfirmationInvoicesPathParameter,
  request?: ListDenseOrderConfirmationInvoicesRequest,
  config?: SWRConfiguration<ListDenseOrderConfirmationInvoicesResponse>
) {
  const listOrderConfirmationInvoicesFetcher =
    useListDenseOrderConfirmationInvoicesFetcher();

  return useSWR(
    getListDenseOrderConfirmationInvoicesKey(parameter, request),
    listOrderConfirmationInvoicesFetcher,
    config
  );
}
