import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface PublishLineSheetSetParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export interface PublishLineSheetSetRequest {
  issuedToBuyers: { id: number }[];
}

export interface PublishLineSheetSetResponse extends LightLineSheetSet {}

export const publishLineSheetSetTemplateUrl =
  "/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/publish";

export const publishLineSheetSetKey: YWRKey<PublishLineSheetSetParameter> = {
  url: (parameter: PublishLineSheetSetParameter) => {
    return calcUrl(publishLineSheetSetTemplateUrl, parameter);
  },
  method: "put",
};

export default function usePublishLineSheetSet() {
  const fetcher = useFetcher<
    PublishLineSheetSetRequest,
    PublishLineSheetSetResponse
  >();
  return useYWR(publishLineSheetSetKey, fetcher);
}
