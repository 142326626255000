import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import {
  CreateOrderSheetSeamlesslyParameter,
  createOrderSheetSeamlesslyTemplateUrl,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { StoredObject } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";

export interface CreateFallbackOrderSheetSeamlesslyRequest {
  file: File;
}
export interface CreateFallbackOrderSheetSeamlesslyResponse
  extends StoredObject {
  _orderSheetSetId: number;
  _orderSheetId: number;
}
export interface CreateFallbackOrderSheetSeamlesslyParameter
  extends CreateOrderSheetSeamlesslyParameter {}

export const createFallbackOrderSheetSeamlesslyTemplateUrl = `/:by/:companyId/create-fallback-order-sheet-seamlessly?orderSheetSetId=:orderSheetSetId&lineSheetSetId=:lineSheetSetId&lineSheetId=:lineSheetId&index=:index`;

export const createFallbackOrderSheetSeamlesslyKey: YWRKey<CreateFallbackOrderSheetSeamlesslyParameter> =
  {
    url: (parameter) => {
      return calcUrl(createFallbackOrderSheetSeamlesslyTemplateUrl, parameter);
    },
    method: "post",
  };

export function parseCreateFallbackOrderSheetSeamlesslyKey(url: string) {
  const urlParser = new UrlParser<CreateFallbackOrderSheetSeamlesslyParameter>(
    createOrderSheetSeamlesslyTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      lineSheetId: StringTransformer.parseId,
      index: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useCreateFallbackOrderSheetSeamlessly() {
  const fetcher = useFetcher<
    CreateFallbackOrderSheetSeamlesslyRequest,
    CreateFallbackOrderSheetSeamlesslyResponse
  >();

  return useYWR(createFallbackOrderSheetSeamlesslyKey, fetcher, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
