import React, { useCallback, useEffect, useMemo, useState } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";
import { Grid } from "@chakra-ui/react";
import { Formik } from "formik";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useLineSheetSetDumbTransaction } from "@/features/line-sheet-sets/hooks/use-line-sheet-set-dumb-transaction";
import useUpdateLineSheetSetV2, {
  UpdateLineSheetSetV2PathParameter,
  UpdateLineSheetSetV2Request,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-v2";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { Agency, StoredObject } from "@/features/types";
import {
  DenseLineSheet,
  LightLineSheet,
  LineSheet,
  LineSheetSetStatus,
  LineSheetSetType,
  ReviewStatus,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useGetDenseLineSheetSet, {
  GetDenseLineSheetSetPathParameter,
  GetDenseLineSheetSetRequest,
} from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import {
  DenseOrderSheet,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import usePersistedLineSheetSetDetailPageQuery from "@/features/line-sheet-sets/detail/use-persisted-line-sheet-sheet-set-detail-page-query";
import { AppUtils } from "@/features/ui/utils/app-utils";
import LineSheetSetDetailPageHeader from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page-header";
import LineSheetSetDetailForm from "@/features/line-sheet-sets/line-sheet-set-detail-form";
import useLineSheetSetFormValidationSchema from "@/features/line-sheet-sets/detail/hooks/use-line-sheet-set-form-validation-schema";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import usePublishLineSheetSetIfConfirmed from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set-if-confirmed";

interface LineSheetSetDetailPageProps {
  isEditing?: boolean;
}

export type LineSheetFormField = Omit<
  DenseLineSheet,
  "file" | "lineSheetTabs" | "status"
> & {
  file: Pick<StoredObject, "id" | "name"> | File;
  status?: LineSheet["status"];
  isChecked: boolean;
  isCheckable: boolean;
  source?: LineSheetFormField | AttachmentFormField;
};

export type AttachmentFormField = Pick<StoredObject, "id" | "name"> & {
  file?: File;
  isChecked: boolean;
  isCheckable: boolean;
  // prevLineSheetFormFields?: LineSheetFormField;
  source?: LineSheetFormField | AttachmentFormField;
};

export type OrderSheetFormField = Omit<
  DenseOrderSheet,
  "orderSheetTabs" | "lineSheet"
> & {
  isChecked: boolean;
  isCheckable: boolean;
  lineSheet: Omit<LightLineSheet, "lineSheetTabs">;
};

export function isLineSheetFormField(
  value: LineSheetFormField | AttachmentFormField | OrderSheetFormField
): value is LineSheetFormField {
  return (
    (value as LineSheetFormField).type !== undefined &&
    (value as OrderSheetFormField).lineSheet === undefined
  );
}

export function isAttachmentFormField(
  value: LineSheetFormField | AttachmentFormField | OrderSheetFormField
): value is AttachmentFormField {
  return !isOrderSheetFormField(value) && !isLineSheetFormField(value);
}

export function isOrderSheetFormField(
  value: LineSheetFormField | AttachmentFormField | OrderSheetFormField
): value is OrderSheetFormField {
  return (value as OrderSheetFormField).lineSheet !== undefined;
}

export interface LineSheetSetFormFields {
  id?: number;
  name?: string;
  season?: string;
  issuedAt?: Date;
  issuedByBoutique?: {
    id: number;
    name: string;
  };
  issuedByBrand?: {
    id: number;
    name: string;
  };
  status?: LineSheetSetStatus;
  reviewStatus?: ReviewStatus | null;
  type?: LineSheetSetType;
  validUntil?: Date;
  joinedOrderRules?: string;
  //수정시에 순서 보장을 위해서 lineSheet와 attachment를 합쳐야함,
  lineSheetOrAttachmentList: (
    | LineSheetFormField
    | AttachmentFormField
    | OrderSheetFormField
  )[];
  createdByCompany?: Agency;
  createdAt?: Date;
  publishedAt?: Date | null;
  externalFileLinks: string[];
  compressionStatus?: LineSheet["compressionStatus"];
  compressedImportedFile?: StoredObject | null;
  orderSheetSet?: LightOrderSheetSet;
  isDeleted: boolean;
  issuedToBuyers?: { id: number; name: string }[];
}

export default function LineSheetSetDetailPage({
  isEditing = false,
}: LineSheetSetDetailPageProps) {
  const identity = useIdentity();
  const { value: query } = usePersistedLineSheetSetDetailPageQuery();
  const { lineSheetSetId } = useRequiredParams<{ lineSheetSetId: number }>();
  const { navigate } = useRouterHelper();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const { error: showError } = useAppToasts();

  const getDenseLineSheetSetKey = useMemo(():
    | {
        parameter: GetDenseLineSheetSetPathParameter;
        request: GetDenseLineSheetSetRequest;
      }
    | undefined => {
    if (identity?.company && lineSheetSetId) {
      if (lineSheetSetId) {
        return {
          parameter: {
            lineSheetSetId,
            by: identity.company.type,
            companyId: identity.company.id,
            orderSheetSetId: query.orderSheetSetId,
            infer: identity.company.type === "BUYER",
          },
          request: {},
        };
      }
    }
    return undefined;
  }, [identity, query.orderSheetSetId, lineSheetSetId]);

  const {
    data: getDenseLineSheetSetData,
    mutate: mutateGetLineSheetSetData,
    error: getLineSheetSetError,
  } = useGetDenseLineSheetSet(
    getDenseLineSheetSetKey?.parameter,
    getDenseLineSheetSetKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (getLineSheetSetError) {
      showError(getLineSheetSetError);
    }
  }, [getLineSheetSetError, showError]);

  const initialValues = useMemo((): LineSheetSetFormFields => {
    if (getDenseLineSheetSetData) {
      // const ignoreEmptyLineSheet =
      //   getDenseLineSheetSetData.orderSheetSet !== undefined &&
      //   isOrderedToAgency(getDenseLineSheetSetData.orderSheetSet);

      const {
        issuedByBrand,
        orderRules,
        lineSheets,
        attachments,
        publishedAt,
        isDeleted,
        ...rest
      } = getDenseLineSheetSetData;

      const isPublished = publishedAt !== null;
      const isAgency = identity?.company?.type === "AGENCY";
      const isLineSheetSetOrAttachmentCheckable = !isPublished && isAgency;

      const lineSheetOrAttachmentList: LineSheetSetFormFields["lineSheetOrAttachmentList"] =
        [
          ...lineSheets.flatMap(
            (lineSheet): (LineSheetFormField | OrderSheetFormField)[] => {
              if (lineSheet.orderSheets.length > 0) {
                return lineSheet.orderSheets.map((orderSheet) => {
                  let isOrderSheetCheckable;
                  if (isAgency) {
                    isOrderSheetCheckable = ![
                      "INITIAL_DRAFT",
                      "WORKING_DRAFT",
                      "CANCELED",
                      "MODIFICATION_REQUIRED",
                      "MODIFICATION_COMPLETED",
                      "COMPLETED",
                    ].includes(orderSheet.status);
                  } else {
                    //파싱실패거나 오더폼일 경우 수량이 0이어도 체크 가능
                    const isEffectivelyOrderForm =
                      lineSheet.type === "ORDER_FORM" ||
                      (lineSheet.type === "LINE_SHEET" &&
                        lineSheet.status === "PARSING_FAILED");

                    isOrderSheetCheckable =
                      (isEffectivelyOrderForm ||
                        orderSheet.orderedQuantity > 0) &&
                      [
                        "WORKING_DRAFT",
                        "ORDERED_TO_AGENCY",
                        "RESUBMIT_REQUIRED",
                      ].includes(orderSheet.status);
                  }

                  return {
                    ...orderSheet,
                    lineSheet: lineSheet,
                    isChecked: false,
                    isCheckable: isOrderSheetCheckable,
                  };
                });
              } else {
                return [
                  {
                    ...lineSheet,
                    file: lineSheet.file,
                    isChecked: false,
                    isCheckable: isLineSheetSetOrAttachmentCheckable,
                  },
                ];
              }
            }
          ),
          ...attachments.map((attachment) => {
            return {
              ...attachment,
              isChecked: false,
              isCheckable: isLineSheetSetOrAttachmentCheckable,
            };
          }),
        ];

      if (getDenseLineSheetSetData.orderSheetSet) {
        const getOrder = (
          field: LineSheetFormField | OrderSheetFormField | AttachmentFormField
        ) => {
          if (isOrderSheetFormField(field)) {
            if (field.orderedQuantity > 0) {
              return 1;
            } else {
              return 2;
            }
          } else if (isLineSheetFormField(field)) {
            return 2;
          } else {
            return 3;
          }
        };
        lineSheetOrAttachmentList.sort((a, b) => {
          return getOrder(a) - getOrder(b);
        });
      }

      return {
        ...rest,
        lineSheetOrAttachmentList,
        issuedByBrand: getDenseLineSheetSetData.issuedByBrand
          ? {
              id: getDenseLineSheetSetData.issuedByBrand.id,
              name: getDenseLineSheetSetData.issuedByBrand.name,
            }
          : undefined,
        joinedOrderRules: orderRules.join("\n"),
        publishedAt: publishedAt,
        isDeleted: isDeleted,
      };
    } else {
      return {
        externalFileLinks: [],
        lineSheetOrAttachmentList: [],
        isDeleted: false,
      };
    }
  }, [getDenseLineSheetSetData, identity]);

  const validateSchema = useLineSheetSetFormValidationSchema();

  async function validate(values: LineSheetSetFormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const { addUpdateLineSheetSetTransaction } = useLineSheetSetDumbTransaction();

  const {
    fire: fireUpdateLineSheetSet,
    data: updateLineSheetSetData,
    error: updateLineSheetSetError,
    isLoading: isUpdateLineSheetSetLoading,
    clear: clearUpdateLineSheetSet,
  } = useUpdateLineSheetSetV2();

  useEffect(() => {
    if (updateLineSheetSetError) {
      showError(updateLineSheetSetError);
    }
  }, [updateLineSheetSetError, showError]);

  async function handleSubmit(values: LineSheetSetFormFields) {
    if (identity?.company?.type === "AGENCY") {
      const parameter: UpdateLineSheetSetV2PathParameter = {
        agencyId: identity.company.id,
        lineSheetSetId: values.id!,
      };

      const request: Omit<
        UpdateLineSheetSetV2Request,
        "lineSheets" | "attachments"
      > = {
        name: values.name!,
        season: values.season!,
        type: values.type!,
        issuedByBrand: values.issuedByBrand
          ? {
              id: values.issuedByBrand.id,
            }
          : undefined,
        issuedByBoutique: {
          id: values.issuedByBoutique!.id,
        },
        orderRules: values.joinedOrderRules!.split("\n"),
        validUntil: values.validUntil!,
        externalFileLinks: values.externalFileLinks || [],
      };

      if (
        values.lineSheetOrAttachmentList
          .filter(
            (i): i is LineSheetFormField | AttachmentFormField =>
              isLineSheetFormField(i) || isAttachmentFormField(i)
          )
          .some((lineSheetOrAttachment) => {
            if (lineSheetOrAttachment.file instanceof File) {
              return true;
            }
          })
      ) {
        addUpdateLineSheetSetTransaction(parameter, {
          ...request,
          lineSheets: values.lineSheetOrAttachmentList
            .filter(isLineSheetFormField)
            .map((lineSheet) => {
              return {
                id: lineSheet.id,
                type: lineSheet.type,
                file: lineSheet.file,
                reviewStatus: lineSheet.reviewStatus,
              };
            }),
          attachments: values.lineSheetOrAttachmentList
            .filter(isAttachmentFormField)
            .map((attachment) => {
              if (attachment.file) {
                return attachment.file;
              } else {
                return {
                  id: attachment.id,
                  name: attachment.name,
                };
              }
            }),
        });
      } else {
        const response = await fireUpdateLineSheetSet(parameter, {
          ...request,
          lineSheets: values.lineSheetOrAttachmentList
            .filter(isLineSheetFormField)
            .map((lineSheet) => {
              return {
                id: lineSheet.id,
                type: lineSheet.type,
                file: lineSheet.file as Pick<StoredObject, "id">,
                reviewStatus: lineSheet.reviewStatus,
              };
            }),
          attachments: values.lineSheetOrAttachmentList
            .filter(isAttachmentFormField)
            .map((attachment) => {
              return {
                id: attachment.id,
                name: attachment.name,
              };
            }),
        });

        await mutateGetLineSheetSetData();
        return response;
      }
    }

    await AppUtils.delay(500);

    return true;
  }

  useEffect(() => {
    async function refresh() {
      if (!isEditing) {
        setIsRefreshing(true);
        await mutateGetLineSheetSetData();
        setIsRefreshing(false);
      }
    }

    let intervalId: NodeJS.Timer | null = null;
    if (getDenseLineSheetSetData && !isEditing) {
      if (
        getDenseLineSheetSetData.lineSheets.some(
          (lineSheet) =>
            lineSheet.status === "PARSING_PENDING" ||
            lineSheet.compressionStatus === "PENDING"
        )
      ) {
        intervalId = setInterval(refresh, 5 * 1000);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [getDenseLineSheetSetData, isEditing, mutateGetLineSheetSetData]);

  const { fire: publishLineSheetSetIfConfirmed } =
    usePublishLineSheetSetIfConfirmed();

  const handlePublishLineSheetSetClick = useCallback(async () => {
    if (lineSheetSetId) {
      const result = await publishLineSheetSetIfConfirmed([lineSheetSetId]);
      if (result) {
        mutateGetLineSheetSetData();
      }
    }
  }, [
    lineSheetSetId,
    publishLineSheetSetIfConfirmed,
    mutateGetLineSheetSetData,
  ]);

  return (
    <Formik<LineSheetSetFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        try {
          formikHelpers.setSubmitting(true);
          const response = await handleSubmit(values);

          if (response) {
            const isByDumb = typeof response === "boolean";

            if (!isByDumb) {
              clearUpdateLineSheetSet();
            }

            navigate(-1, {
              replace: true,
              defaultPage: `/line-sheet-sets/${lineSheetSetId}`,
            });
          }
        } finally {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto 1fr"}
          >
            <LineSheetSetDetailPageHeader
              query={query}
              formik={props}
              isEditing={isEditing}
              mutate={mutateGetLineSheetSetData}
              onPublishClick={handlePublishLineSheetSetClick}
            />
            <LineSheetSetDetailForm
              formik={props}
              isEditing={isEditing}
              mutate={mutateGetLineSheetSetData}
              isRefreshing={isRefreshing}
              onPublishClick={handlePublishLineSheetSetClick}
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
