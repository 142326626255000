import useIdentity from "@/features/ui/hooks/use-identity";
import BuyerOrderSheetSetListPage from "@/features/order-status/buyer-order-sheet-set-list-page";
import AgencyOrderSheetSetListPage from "@/features/order-status/agency-order-sheet-set-list-page";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function OrderSheetSetListPage() {
  const identity = useIdentity();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Pathrade | {t("order_sheets")}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content={`Pathrade | ${t("order_sheets")}`} />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      {identity?.company?.type === "AGENCY" ? (
        <AgencyOrderSheetSetListPage />
      ) : identity?.company?.type === "BUYER" ? (
        <BuyerOrderSheetSetListPage />
      ) : (
        <></>
      )}
    </>
  );
}
