import {
  FileType,
  getFileNameWithoutExtension,
  isFile,
  StoredObject,
} from "@/features/types";
import { Field, FieldProps, FormikProps } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import { useEffect } from "react";
import InvoiceFileInput from "@/features/invoices/invoice-file-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useDownloadUnoFile from "@/hooks/use-download-uno-file";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

interface InvoiceFileFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  fieldName?: keyof T;
  title?: string;
  allowFileTypes?: FileType[];
  showCreatedAt?: boolean;
}

interface FormFields {
  id?: number;
  name?: string;
}

export default function InvoiceFileFormControl<T extends FormFields>({
  formik,
  isEditing,
  title,
  isDisabled = false,
  // @ts-ignore
  fieldName = "file",
  allowFileTypes,
  showCreatedAt,
}: InvoiceFileFormControlProps<T>) {
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const {
    fire: fireDownloadUnoFile,
    error: downloadUnoFileError,
    isLoading: isDownloadUnoFileLoading,
  } = useDownloadUnoFile();

  useEffect(() => {
    if (downloadUnoFileError) {
      showError(downloadUnoFileError);
    }
  }, [downloadUnoFileError, showError]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"24px"} />}>
        <Skeleton height={"48px"} />
      </AppPanel>
    );
  }

  return (
    <Field name={fieldName}>
      {(
        props: FieldProps<
          File | Pick<StoredObject, "id" | "name" | "createdAt">,
          FormFields
        >
      ) => {
        if (!props.field.value && isEditing) {
          return (
            <Flex flexDirection={"column"} gap={"8px"}>
              <Heading as={"h3"} fontSize={"14px"}>
                {title || tTitle("invoice_file")}
              </Heading>
              <AppFileDragAndDropZone
                isMultiple={false}
                allowFileTypes={allowFileTypes}
                onFilesAdd={(value: File[] | File) => {
                  if (!Array.isArray(value)) {
                    props.form.setValues((prev) => {
                      return {
                        ...prev,
                        [fieldName]: value,
                        name:
                          prev.name || getFileNameWithoutExtension(value.name),
                      };
                    });
                  }
                }}
              />
            </Flex>
          );
        } else if (props.field.value) {
          return (
            <AppPanel title={title || tTitle("invoice_file")}>
              <InvoiceFileInput
                showCreatedAt={showCreatedAt}
                value={props.field.value}
                isReadOnly={!isEditing}
                isDisabled={isDisabled}
                allowFileTypes={allowFileTypes}
                isLoading={isDownloadUnoFileLoading}
                onChange={(value) => {
                  props.form.setValues((prev) => {
                    return {
                      ...prev,
                      [fieldName]: value,
                      name:
                        prev.name || getFileNameWithoutExtension(value.name),
                    };
                  });
                }}
                onClick={() => {
                  if (props.field.value && !isFile(props.field.value)) {
                    fireDownloadUnoFile(
                      {
                        unoFileId: props.field.value.id,
                      },
                      {}
                    );
                  }
                }}
                onDelete={() => {
                  props.form.setFieldValue(props.field.name, undefined);
                }}
              />
            </AppPanel>
          );
        }
      }}
    </Field>
  );
}
