import useZWR from "@/utils/use-zwr";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

interface SignInRequest {
  email: string;
  password: string;
}

interface SignInResponse {
  accessToken: string;
  refreshToken: string;
}

export const signInKey: YWRKey<any> = {
  url: "/auth",
  method: "post",
};

export default function useSignIn() {
  const fetcher = useFetcher<SignInRequest, SignInResponse>();

  return useYWR(signInKey, fetcher);
}
