import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormLabel, Grid, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppSeasonSelect from "@/features/line-sheet-sets/app-season-select";
import { Company } from "@/features/types";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useDownloadDepositState from "@/features/balance-transaction-status/hooks/use-download-deposit-state";

export interface DownloadDepositStateModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: FormFields;
}

interface FormFields {
  season?: string;
  issuedBy?: Company;
  issuedTo?: Company;
}

export default function DownloadDepositStateModal({
  isOpen,
  onClose,
  initialValues,
}: DownloadDepositStateModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { withMessage } = useYupHelper({
    prefix: "download_deposit_state_form",
    ns: "invoices",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      season: Yup.string().required(withMessage.required),
      issuedBy: Yup.object().required(withMessage.required),
      issuedTo: Yup.object().when([], {
        is: () => {
          return identity?.company?.type === "AGENCY";
        },
        then: (schema) => schema.required(),
      }),
    });
  }, [withMessage, identity]);

  const validate = useCallback(
    async (values: FormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e: any) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { fire: fireDownloadDepositState } = useDownloadDepositState();

  // useEffect(() => {
  //   if (downloadDepositStateError) {
  //     showError(downloadDepositStateError);
  //     clearDownloadDepositState();
  //   }
  // }, [downloadDepositStateError, showError, clearDownloadDepositState]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company?.type) {
        try {
          const response = await fireDownloadDepositState(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              season__eq: values.season!,
              "issuedByCompany.id__eq": values.issuedBy!.id,
              "issuedToCompany.id__eq": values.issuedTo?.id,
            },
            {}
          );

          setTimeout(() => {
            onClose();
          }, 500);

          return response;
        } catch (e) {
          showError(e);
          return false;
        }
      }
      return false;
    },
    [fireDownloadDepositState, identity, onClose, showError]
  );

  return (
    <Formik<FormFields>
      initialValues={initialValues || {}}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={"download_deposit_state"}
        submitName={"download"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <>
          <Text fontSize={"12px"} padding={"20px 0"}>
            {t("common:download_deposit_state.message")}
          </Text>
          <Form style={{ width: "100%" }}>
            <Grid
              gridTemplateColumns={"100px 1fr"}
              alignItems={"center"}
              columnGap={"8px"}
              rowGap={"10px"}
            >
              <Field name={"season"}>
                {(props: FieldProps<FormFields["season"], FormFields>) => {
                  return (
                    <>
                      <FormLabel
                        color={"#444440"}
                        margin={"0"}
                        padding={"0"}
                        fontSize={"12px"}
                      >
                        {tTitle("season")}
                      </FormLabel>
                      <AppSeasonSelect
                        width={"100%"}
                        value={props.field.value}
                        onSelect={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    </>
                  );
                }}
              </Field>

              <Field name={"issuedBy"}>
                {(props: FieldProps<FormFields["issuedBy"], FormFields>) => {
                  return (
                    <>
                      <FormLabel
                        color={"#444440"}
                        margin={"0"}
                        padding={"0"}
                        fontSize={"12px"}
                      >
                        {tTitle("issued_by")}
                      </FormLabel>
                      <AppCompanySelect
                        width={"100%"}
                        onSelect={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                        id={props.field.value?.id}
                        name={tTitle("issued_by")}
                        types={["BOUTIQUE", "CROSS_TRADER"]}
                      />
                    </>
                  );
                }}
              </Field>

              {identity?.company?.type === "AGENCY" && (
                <Field name={"issuedTo"}>
                  {(props: FieldProps<FormFields["issuedTo"], FormFields>) => {
                    return (
                      <>
                        <FormLabel
                          color={"#444440"}
                          margin={"0"}
                          padding={"0"}
                          fontSize={"12px"}
                        >
                          {tTitle("issued_to")}
                        </FormLabel>
                        <AppCompanySelect
                          width={"100%"}
                          onSelect={(value) => {
                            props.form.setFieldValue(props.field.name, value);
                          }}
                          id={props.field.value?.id}
                          name={tTitle("issued_to")}
                          types={["BUYER"]}
                        />
                      </>
                    );
                  }}
                </Field>
              )}
            </Grid>
          </Form>
        </>
      </AppFormModal>
    </Formik>
  );
}
