import { Box, Flex, FlexProps } from "@chakra-ui/react";

interface AppGridCellProps extends FlexProps {
  value: (string | JSX.Element)[];
}

export default function AppGridCell({ value, ...rest }: AppGridCellProps) {
  return (
    <Flex flexDirection={"column"} gap={"4px"} {...rest}>
      {value.map((item, index) => {
        if (typeof item === "string") {
          return <Box key={index}>{item}</Box>;
        } else {
          return <Box key={index}>{item}</Box>;
        }
      })}
    </Flex>
  );
}
