import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useCreditInvoiceFormValidationSchema from "@/features/invoices/credit-invoices/hooks/use-credit-invoice-form-validation-schema";
import React, { useCallback, useEffect, useMemo } from "react";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import useGetDenseCreditInvoice, {
  GetDenseCreditInvoiceParameter,
  GetDenseCreditInvoiceRequest,
} from "@/features/invoices/credit-invoices/hooks/use-get-dense-credit-invoice";
import { CreditInvoiceFormFields } from "@/features/invoices/credit-invoices/upload-credit-invoice-page";
import { Formik, FormikProps } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import CreditInvoiceForm from "@/features/invoices/credit-invoices/credit-invoice-form";
import useCreditInvoiceDumbTransaction from "@/features/invoices/credit-invoices/hooks/use-credit-invoice-dumb-transaction";
import useUpdateCreditInvoice, {
  UpdateCreditInvoicePathParameter,
  UpdateCreditInvoiceRequest,
} from "@/features/invoices/credit-invoices/hooks/use-update-credit-invoice";
import { isFile } from "@/features/types";
import AppDownloadButton from "@/features/invoices/app-download-button";
import PriceUtils from "@/features/ui/utils/price-utils";
import useReadResource from "@/features/invoices/proforma-invoices/hooks/use-read-resource";
import useReadCreditInvoice from "@/features/invoices/credit-invoices/hooks/use-read-credit-invoice";

interface CreditInvoiceDetailPageProps {
  isEditing?: boolean;
}

export default function CreditInvoiceDetailPage({
  isEditing = false,
}: CreditInvoiceDetailPageProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { navigate } = useRouterHelper();

  const validateSchema = useCreditInvoiceFormValidationSchema();
  const validate = useCallback(
    async (values: CreditInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { creditInvoiceId } = useRequiredParams<{ creditInvoiceId: number }>();

  const key = useMemo(():
    | {
        parameter: GetDenseCreditInvoiceParameter;
        request: GetDenseCreditInvoiceRequest;
      }
    | undefined => {
    if (identity?.company && creditInvoiceId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          creditInvoiceId,
        },
        request: {},
      };
    }
  }, [creditInvoiceId, identity]);

  const {
    data: creditInvoice,
    error: getCreditInvoiceError,
    mutate: mutateGetCreditInvoice,
  } = useGetDenseCreditInvoice(key?.parameter, key?.request);

  useEffect(() => {
    if (getCreditInvoiceError) {
      showError(getCreditInvoiceError);
    }
  }, [getCreditInvoiceError, showError]);

  const initialValues = useMemo((): CreditInvoiceFormFields => {
    if (creditInvoice) {
      return {
        id: creditInvoice.id,
        number: creditInvoice.number,
        type: creditInvoice.type,
        file: creditInvoice.file || undefined,
        amount: creditInvoice.amount,
        issuedOn: creditInvoice.issuedOn,
        name: creditInvoice.name,
        season: creditInvoice.season,
        orderedBy: creditInvoice.issuedToCompany,
        issuedBy: creditInvoice.issuedByCompany,
        sendAsNewEmail: creditInvoice.emailThreadMessageId === null,
        emailThreadMessageId: creditInvoice.emailThreadMessageId || undefined,
        privateComment: creditInvoice.privateComment || undefined,
        publicComment: creditInvoice.publicComment || undefined,
        earningProformaInvoices: creditInvoice.earningProformaInvoices,
        orderConfirmationInvoices: creditInvoice.orderConfirmationInvoices,
        usingProformaInvoices: creditInvoice.usingProformaInvoices,
      };
    } else {
      return {
        earningProformaInvoices: [],
        orderConfirmationInvoices: [],
        sendAsNewEmail: false,
        usingProformaInvoices: [],
      };
    }
  }, [creditInvoice]);

  const { isUpdateQueued, addUpdate } = useCreditInvoiceDumbTransaction();

  const {
    fire: fireUpdateCreditInvoice,
    error: updateCreditInvoiceError,
    isLoading: isUpdateCreditInvoiceLoading,
    clear: clearUpdateCreditInvoice,
  } = useUpdateCreditInvoice();

  const handleSubmit = useCallback(
    async (values: CreditInvoiceFormFields) => {
      if (identity?.company) {
        const parameter: UpdateCreditInvoicePathParameter = {
          by: identity.company.type,
          companyId: identity.company.id,
          creditInvoiceId: values.id!,
        };

        const request: Omit<UpdateCreditInvoiceRequest, "file"> = {
          id: values.id!,
          name: values.name!,
          number: values.number!,
          season: values.season!,
          type: values.type!,
          issuedOn: values.issuedOn!,
          issuedByCompany: values.issuedBy!,
          issuedToCompany: values.orderedBy!,
          issuedThroughCompany: values.issuedThrough!,
          amount: PriceUtils.placeholder(values.amount!, "EUR"),
          emailThreadMessageId: values.emailThreadMessageId || null,
          publicComment: values.publicComment || null,
          privateComment: values.privateComment || null,
          orderConfirmationInvoices: values.orderConfirmationInvoices.map(
            (ocInvoice) => {
              return {
                id: ocInvoice.id,
              };
            }
          ),
          earningProformaInvoices: values.earningProformaInvoices.map(
            (pInvoice) => {
              return {
                id: pInvoice.id,
              };
            }
          ),
        };

        if (isFile(values.file!)) {
          addUpdate(parameter, { ...request, file: values.file! });
        } else {
          const response = await fireUpdateCreditInvoice(parameter, {
            ...request,
            file: values.file!,
          });
          await mutateGetCreditInvoice();
          return response;
        }
      }

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });

      return true;
    },
    [addUpdate, fireUpdateCreditInvoice, identity, mutateGetCreditInvoice]
  );

  const createHeaderAction = useCallback(
    (props: FormikProps<CreditInvoiceFormFields>) => {
      if (isEditing) {
        return [
          <AppButton
            key={"Save"}
            variant={"primary"}
            isLoading={props.isSubmitting}
            isDisabled={props.isSubmitting || !props.dirty}
            onClick={() => {
              props.submitForm();
            }}
          >
            {tTitle("common:save")}
          </AppButton>,
        ];
      } else {
        let isLoading = false;

        if (identity?.company && creditInvoiceId) {
          isLoading = isUpdateQueued({
            by: identity.company.type,
            companyId: identity.company.id,
            creditInvoiceId,
          });
        }

        return [
          <AppDownloadButton key={"Download"} value={initialValues.file} />,
          <AppButton
            key={"Edit"}
            isLoading={isLoading}
            onClick={() => {
              navigate(`/credit-invoices/${creditInvoiceId}/edit`);
            }}
          >
            {tTitle("common:edit")}
          </AppButton>,
        ];
      }
    },
    [
      creditInvoiceId,
      identity,
      initialValues.file,
      isEditing,
      isUpdateQueued,
      navigate,
      tTitle,
    ]
  );

  useReadResource({
    ywrFunc: useReadCreditInvoice,
    resource: creditInvoice,
    when: () => identity?.company?.type === "BUYER",
    mutate: mutateGetCreditInvoice,
  });

  return (
    <Formik<CreditInvoiceFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        let response;
        try {
          response = await handleSubmit(values);
        } catch (e) {
          showError(e);
        } finally {
          formikHelpers.setSubmitting(false);
        }

        if (response) {
          const byDumbTransaction = typeof response === "boolean";

          if (!byDumbTransaction) {
            clearUpdateCreditInvoice();
          }

          navigate(-1, {
            replace: true,
            defaultPage: `/credit-invoices/${creditInvoiceId}`,
          });
        }
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"UploadOrderConfirmationInvoice"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("credit_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={createHeaderAction(props)}
              showNavigateToBack={true}
              emptyStackPage={"/credit-invoices"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <CreditInvoiceForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
