import { Flex } from "@chakra-ui/react";
import ChangePasswordCard from "@/features/my-account/change-password-card";
import UserCard from "@/features/my-account/user-card";

export default function BuyerMyAccountPage() {
  return (
    <Flex
      padding={"24px"}
      maxWidth={"960px"}
      margin={"0 auto"}
      flexDirection={"column"}
      gap={"16px"}
      fontSize={"12px"}
      alignItems={"center"}
    >
      <UserCard />
      <ChangePasswordCard />
    </Flex>
  );
}
