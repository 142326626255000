import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import AppLabel from "@/features/ui/app-label";
import { toLower } from "@/utils/case";
import { useMemo } from "react";

interface AppInvoiceStatusLabelProps {
  value?: AbstractInvoice["status"];
}

export default function AppInvoiceStatusLabel({
  value,
}: AppInvoiceStatusLabelProps) {
  const level = useMemo(() => {
    switch (value) {
      case "NORMAL":
        return "PRIMARY";
      case "CANCELED":
        return "ERROR";
      default:
        return "LIGHT";
    }
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <AppLabel
      nameKey={`invoice_status.${toLower(value)}`}
      level={level}
      size={"small"}
    />
  );
}
