import AppSelect from "@/features/line-sheet-sets/app-select";
import { useMemo } from "react";
import {
  BalanceTransactionType,
  BalanceTransactionTypes,
} from "@/features/balance-transaction-status/balance-transaction.type";
import { useTranslation } from "react-i18next";
import { toLower } from "@/utils/case";

interface AppBalanceTransactionTypeSelectorProps {
  value?: BalanceTransactionType;
  onSelect?: (type: BalanceTransactionType | undefined) => void;
  width?: string;
  isClearable?: boolean;
}

export default function AppBalanceTransactionTypeSelector({
  value,
  width = "144px",
  onSelect,
  isClearable,
}: AppBalanceTransactionTypeSelectorProps) {
  const { t } = useTranslation();

  const options = useMemo((): AppSelectOption<BalanceTransactionType>[] => {
    return BalanceTransactionTypes.map((type) => {
      return {
        name: t(`balance_transaction.type.${toLower(type)}`),
        value: type,
      };
    });
  }, [t]);

  return (
    <AppSelect
      name={t("type")}
      options={options}
      width={width}
      value={value}
      onSelect={onSelect}
      isClearable={isClearable}
    />
  );
}
