import { IdentityParameter, PreSignedStoredObject } from "@/features/types";
import { CompanyId } from "@/features/line-sheet-sets/app-company-select";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import {
  calcUrl,
  withPreSignedFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";

interface DownloadDepositStateRequest {}

interface DownloadDepositStateResponse extends PreSignedStoredObject {}

interface DownloadDepositStatusParameter extends IdentityParameter {
  season__eq: string;
  "issuedByCompany.id__eq": number;
  "issuedToCompany.id__eq"?: number;
}

export const downloadDepositStateTemplateUrl = `/:by/:companyId/balance-transactions/download-deposit-state`;

export const downloadDepositStateKey: YWRKey<DownloadDepositStatusParameter> = {
  url: (parameter) => {
    return calcUrl(downloadDepositStateTemplateUrl, parameter);
  },
  method: "post",
};

export default function useDownloadDepositState() {
  const vanillaFetcher = useFetcher<
    DownloadDepositStateRequest,
    DownloadDepositStateResponse
  >();

  const fetcher = useMemo(() => {
    return withPreSignedFetcher(vanillaFetcher);
  }, [vanillaFetcher]);

  return useYWR(downloadDepositStateKey, fetcher);
}
