import {
  ButtonProps,
  ChakraProps,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import SVG from "react-inlinesvg";

function calcSizeStyle(size: AppIconButtonProps["size"]): ChakraProps {
  let baseStyle: ChakraProps = {
    backgroundColor: "white",
    p: "0",
    m: "0",
    minW: "0",
    minH: "0",
  };
  if (size === "medium") {
    return {
      ...baseStyle,
      w: "16px",
      h: "16px",
    };
  } else if (size === "large") {
    return {
      ...baseStyle,
      w: "20px",
      h: "20px",
    };
  } else if (size === "small") {
    return {
      ...baseStyle,
      w: "12px",
      h: "12px",
    };
  } else if (size === "extraSmall") {
    return {
      ...baseStyle,
      w: "10px",
      h: "10px",
    };
  }
  return baseStyle;
}
function calcVariantStyle({
  variant,
  isActive,
  isDisabled,
}: Pick<
  AppIconButtonProps,
  "variant" | "isActive" | "isDisabled"
>): ChakraProps {
  let baseStyle: ChakraProps = {
    backgroundColor: "transparent",
  };
  if (variant === "ghost") {
    if (isDisabled) {
      return {
        ...baseStyle,
        color: "#D4D4D1",
        cursor: "not-allowed",
        _hover: {},
      };
    } else if (isActive) {
      return {
        ...baseStyle,
        color: "#1272EF",
      };
    } else {
      return {
        ...baseStyle,
        color: "#8F8F8C",
        _hover: {
          color: "#1272EF",
          background: "none",
        },
      };
    }
  }

  return baseStyle;
}

function calcIcon(
  icon: AppIconButtonProps["icon"],
  size: AppIconButtonProps["size"]
) {
  let src = "";
  if (icon === "hamburger") {
    src = "/icons/icon_hamburger_menu.svg";
  } else if (icon === "left") {
    src = "/icons/icon_chevron_left.svg";
  } else if (icon === "right") {
    src = "/icons/icon_chevron_right.svg";
  } else if (icon === "x") {
    src = "/icons/icon_x_letter.svg";
  } else if (icon === "save") {
    src = "/icons/icon_save.svg";
  } else if (icon === "edit") {
    src = "/icons/icon_edit.svg";
  }

  const sizeProps = { width: "16px", height: "16px" };
  if (size === "small") {
    sizeProps.height = "12px";
    sizeProps.width = "12px";
  } else if (size === "large") {
    sizeProps.height = "20px";
    sizeProps.width = "20px";
  } else if (size === "extraSmall") {
    sizeProps.height = "10px";
    sizeProps.width = "10px";
  }

  return <SVG src={src} {...sizeProps} />;
}

export interface AppIconButtonProps {
  variant?: "ghost" | "outline";
  size?: "extraSmall" | "small" | "medium" | "large";
  ariaLabel: string;
  icon: "hamburger" | "left" | "right" | "x" | "save" | "edit";
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonRef?: React.Ref<HTMLButtonElement>;
  height?: string;
}

export default function AppIconButton({
  variant = "ghost",
  ariaLabel,
  size = "medium",
  isActive,
  isDisabled,
  isLoading,
  icon,
  onClick,
  buttonRef,
  height = "auto",
}: AppIconButtonProps) {
  let sizeStyle = calcSizeStyle(size);
  let variantStyle = calcVariantStyle({ variant, isActive, isDisabled });

  if (isLoading) {
    return <Spinner w={sizeStyle.w} h={sizeStyle.h} />;
  }

  return (
    <IconButton
      ref={buttonRef}
      aria-label={ariaLabel}
      {...sizeStyle}
      {...variantStyle}
      verticalAlign={"middle"}
      icon={calcIcon(icon, size)}
      onClick={(event) => {
        if (onClick && !isLoading && !isDisabled) {
          onClick(event);
        }
      }}
    />
  );
}
