import { Box, Button, Flex, Spacer, Spinner } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import { StoredObject } from "@/features/types";

interface StoredObjectCellProps {
  storageObject: StoredObject;
  onClick?: () => void;
  isLoading?: boolean;
}

export default function StoredObjectCell({
  storageObject,
  isLoading = false,
  onClick,
}: StoredObjectCellProps) {
  return (
    <Flex height={"32px"} width={"100%"}>
      <Flex
        as={Button}
        backgroundColor={"transparent"}
        _hover={{
          backgroundColor: "transparent",
          textDecoration: "underline",
        }}
        borderRadius={"0"}
        gap={"8px"}
        flexDirection={"row"}
        height={"auto"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        fontSize={"12px"}
        fontWeight={"400"}
        onClick={onClick}
      >
        <SVG src={"/icons/icon_excel.svg"} />
        {isLoading ? <Spinner size={"sm"} /> : storageObject.name}
      </Flex>
      <Box width={"16px"}></Box>
    </Flex>
  );
}
