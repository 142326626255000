import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { range } from "lodash";
import AppDatePickerHeader from "@/features/line-sheet-sets/app-date-picker-header";
import ko from "date-fns/locale/ko";
import it from "date-fns/locale/it";
import en_US from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import AppDatePickerInput from "@/features/line-sheet-sets/app-date-picker-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

registerLocale("ko", ko);
registerLocale("it", it);
registerLocale("en", en_US);

interface AppDatePickerProps {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  showTimeInput?: boolean;
  width?: string;
  name?: string;
  isDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isReadOnly?: boolean;
  isLiteral?: boolean;
}

export default function AppDatePicker({
  value,
  onChange,
  width = "100%",
  showTimeInput = true,
  name = "DeadLine",
  isDisabled = false,
  minDate,
  maxDate,
  isReadOnly = false,
  isLiteral = false,
}: AppDatePickerProps) {
  const [hour, setHour] = useState<number>(value ? value.getHours() : 0);
  const [minute, setMinute] = useState<number>(value ? value.getMinutes() : 0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hourOptions = useMemo(() => {
    return range(0, 24).map((i): AppSelectOption<number> => {
      return {
        name: String(i).padStart(2, "0"),
        value: i,
      };
    });
  }, []);

  const minuteOptions = useMemo(() => {
    return range(0, 60).map((i) => {
      return {
        name: String(i).padStart(2, "0"),
        value: i,
      };
    });
  }, []);

  const customInputRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen) {
      const elements = document.getElementsByClassName(
        "react-datepicker__day--selected"
      );
      if (elements.length > 0) {
        (elements[0] as HTMLInputElement).focus();
      }
    } else {
      if (customInputRef.current) {
        customInputRef.current.focus();
      }
    }
  }, [isOpen]);

  const { t, i18n, tLocalDateString, tLocalDateTimeString } = useI18nHelper();
  const locale = useMemo(() => {
    if (i18n.language === "ko") {
      return ko;
    } else if (i18n.language === "it") {
      return it;
    } else {
      return en_US;
    }
  }, [i18n.language]);

  const ForwardedAppDatePickerInput = forwardRef(AppDatePickerInput);

  if (isLiteral) {
    const name = value
      ? showTimeInput
        ? tLocalDateTimeString(value)
        : tLocalDateString(value)
      : "";
    return <Box>{name}</Box>;
  }

  return (
    <Box width={width}>
      <ReactDatePicker
        popperProps={{
          strategy: "fixed",
        }}
        id={"app-date-picker"}
        minDate={minDate}
        maxDate={maxDate}
        showPopperArrow={false}
        onChange={(value) => {
          if (value) {
            value.setHours(hour, minute, 0);
            onChange(value);

            if (!showTimeInput) {
              setIsOpen(false);
            }

            return;
          }

          if (!showTimeInput) {
            setIsOpen(false);
          }

          onChange(undefined);
        }}
        selected={value}
        openToDate={value!!}
        open={isOpen}
        shouldCloseOnSelect={false}
        customInput={
          <ForwardedAppDatePickerInput
            ref={customInputRef}
            inputName={name}
            width={width}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            onInputClick={() => setIsOpen((prev) => !prev)}
            onClear={() => {
              onChange(undefined);
            }}
          />
        }
        locale={locale}
        customInputRef={"ref"}
        onCalendarOpen={() => {}}
        onClickOutside={(event) => {
          const target = event.target as HTMLDivElement;

          if (
            [target, target.parentElement].some((element) => {
              return element && element.ariaLabel === "clear";
            })
          ) {
            onChange(undefined);
          }

          setIsOpen(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            setIsOpen(false);
          }
        }}
        dateFormat={showTimeInput ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`}
        renderCustomHeader={(props) => {
          const months = range(0, 12).map((i) => locale.localize!.month(i));
          return (
            <AppDatePickerHeader
              {...props}
              name={name}
              months={months}
              locale={locale}
              setIsOpen={setIsOpen}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        }}
      >
        {showTimeInput && (
          <Box
            display={"inline-flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            gap={"8px"}
            w={"100%"}
          >
            <AppSelect<number>
              name={t("hour")}
              width={"88px"}
              options={hourOptions}
              value={hour}
              isClearable={false}
              onSelect={(hour) => {
                if (hour !== undefined) {
                  setHour(hour);
                  if (value) {
                    const next = new Date(value);
                    next.setHours(hour);
                    onChange(next);
                  }
                }
              }}
            />
            <AppSelect<number>
              name={t("minute")}
              width={"88px"}
              options={minuteOptions}
              value={minute}
              isClearable={false}
              onSelect={(minute) => {
                if (minute !== undefined) {
                  setMinute(minute);
                  if (value) {
                    const next = new Date(value);
                    next.setMinutes(minute);
                    onChange(next);
                  }
                }
              }}
            />
          </Box>
        )}
      </ReactDatePicker>
    </Box>
  );
}
