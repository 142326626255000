import { Company } from "@/features/types";
import { Field, FieldProps, FormikProps, useFormikContext } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useCallback } from "react";
import { ListAllOrderConfirmationInvoicesRequest } from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, FormControl, FormLabel, Skeleton } from "@chakra-ui/react";
import { LinkedOrderSheetSet } from "@/features/invoices/app-order-sheet-set-selector";
import { toTitle } from "@/utils/case";
import AppOrderConfirmationInvoicesExclusiveSelector, {
  LinkedOrderConfirmationInvoice,
} from "@/features/invoices/deposit-invoices/app-order-confirmation-invoices-exclusive-selector";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import IsolatedOrderConfirmationInvoicePanel from "@/features/invoices/proforma-invoices/isolated-order-confirmation-invoice-panel";
import { AppUtils } from "@/features/ui/utils/app-utils";
import {
  DenseOrderConfirmationInvoice,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

interface FormFields {
  id?: number;
  season?: string;
  issuedBy?: Pick<Company, "id" | "name">;
  orderedBy?: Pick<Company, "id" | "name">;
  orderConfirmationInvoices: { id: number }[];
  proformaInvoiceDetailList?: ProformaInvoiceFormFields["proformaInvoiceDetailList"];
}

interface LinkedOrderConfirmationInvoicesFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  request?: ListAllOrderConfirmationInvoicesRequest;
  onSelect: (value: DenseOrderConfirmationInvoice[]) => void;
  nameFunc?: (ocInvoice: DenseOrderConfirmationInvoice) => string;
}

export default function LinkedOrderConfirmationInvoicesFormControl<
  T extends FormFields
>({
  formik,
  isEditing,
  isDisabled,
  request,
  onSelect,
  nameFunc,
}: LinkedOrderConfirmationInvoicesFormControlProps<T>) {
  const { values } = useFormikContext<FormFields>();
  const { t, tTitle } = useI18nHelper();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Skeleton height={"16px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
        </Flex>
      </AppPanel>
    );
  }

  if (!isEditing) {
    return (
      <IsolatedOrderConfirmationInvoicePanel
        orderConfirmationInvoiceIds={formik.values.orderConfirmationInvoices.map(
          (ocInvoice) => ocInvoice.id
        )}
      />
    );
  }

  return (
    <AppPanel title={tTitle("common:related_order_confirmation_invoices")}>
      <Field name={"orderConfirmationInvoices"}>
        {(
          props: FieldProps<FormFields["orderConfirmationInvoices"], FormFields>
        ) => {
          return (
            <FormControl display={"flex"} flexDirection={"column"} gap={"4px"}>
              <FormLabel
                fontSize={"12px"}
                color={"#6F6F6C"}
                padding={"0"}
                margin={"0"}
                display={"none"}
              >
                {toTitle(t("line_sheets"))}
              </FormLabel>

              <AppOrderConfirmationInvoicesExclusiveSelector
                id={AppUtils.extractIds(props.field.value)}
                nameFunc={nameFunc}
                request={request}
                isDisabled={isDisabled}
                onSelect={(value) => {
                  onSelect(value);
                }}
              />
            </FormControl>
          );
        }}
      </Field>
    </AppPanel>
  );
}
