import AppGrid from "@/features/order-sheet-sets/app-grid";
import useGmailGrid from "@/features/gmails/hooks/use-gmail-grid";
import useGmailRows from "@/features/gmails/hooks/use-inventory-rows";

interface AgencyGmailGridProps {
  mutateRows: () => void;
}
export default function AgencyGmailGrid({
  mutateRows,
}: AgencyGmailGridProps) {
  const { rows } = useGmailRows();
  const gmailGrid = useGmailGrid({ rows, mutateRows });

  return (
    <>
      <AppGrid rows={rows} metas={[gmailGrid.metas.withGmail(false)]} />
    </>
  );
}
