import { useFormikContext } from "formik";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { useContext, useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import {
  DetailBrandContext,
  DetailBrandContextType,
} from "@/features/invoices/proforma-invoices/detail-brand-provider";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";

export type DetailBrand = {
  id: number;
  orderSheetSet?: {
    id: number;
    lineSheetSet: {
      id: number;
      name: string;
    };
  };

  brand?: {
    id: number;
    name: string;
  };

  orderConfirmationInvoiceDetail?: ProformaInvoice["proformaInvoiceDetailList"][number]["orderConfirmationInvoiceDetail"] & {
    orderConfirmationInvoice: {
      id: number;
      name: string;
    };
  };
};

interface AppDetailBrandSelectProps {
  value: DetailBrand;
  onSelect: (value?: DetailBrand) => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isSearchable?: boolean;
}

function idFunc(detailBrand: DetailBrand) {
  const {
    orderConfirmationInvoiceDetail: ocDetail,
    brand,
    orderSheetSet: oss,
  } = detailBrand;

  if (brand) {
    if (ocDetail) {
      const ocInvoice = ocDetail.orderConfirmationInvoice;
      return `${ocInvoice.id}_${ocDetail.id}_${brand.id}`;
    }

    if (oss) {
      return `${oss.id}_${brand.id}`;
    }

    return `${brand.id}`;
  }

  return detailBrand.id;
}

export function getDetailBrandName(detailBrand: DetailBrand) {
  const {
    brand,
    orderConfirmationInvoiceDetail: ocDetail,
    orderSheetSet: oss,
  } = detailBrand;

  if (ocDetail) {
    const ocInvoice = ocDetail.orderConfirmationInvoice;
    let name = `OC ${ocInvoice.id} / `;
    if (ocDetail.orderSheetSet?.id) {
      name += `OSS ${ocDetail.orderSheetSet.id} / `;
    }
    name += brand!.name;
    return name;
  }

  if (oss) {
    return `OSS ${oss.id} / ${brand!.name}`;
  }

  return brand!.name;
}

export default function AppDetailBrandSelect<T>({
  value,
  onSelect,
  isReadOnly,
  isDisabled,
  isSearchable,
}: AppDetailBrandSelectProps) {
  const { values } = useFormikContext<{
    proformaInvoiceDetailList?: ProformaInvoiceFormFields["proformaInvoiceDetailList"];
    orderConfirmationInvoiceDetailList?: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"];
  }>();

  const detailList = useMemo(() => {
    return (
      values.orderConfirmationInvoiceDetailList ||
      values.proformaInvoiceDetailList ||
      []
    );
  }, [
    values.orderConfirmationInvoiceDetailList,
    values.proformaInvoiceDetailList,
  ]);

  const { items } = useContext<DetailBrandContextType>(DetailBrandContext);

  const options = useMemo(() => {
    const allSelectedId = detailList.map((pDetail) => {
      return idFunc(pDetail);
    });

    const myId = idFunc(value);

    return items
      .filter((detailBrand: DetailBrand) => {
        const id = idFunc(detailBrand);
        return !allSelectedId.includes(id) || id === myId;
      })
      .map((detailBrand: DetailBrand) => {
        return {
          value: detailBrand,
          name: getDetailBrandName(detailBrand),
        };
      });
  }, [items, value, detailList]);

  const name = useMemo(() => {
    const anyOC = items.some(
      (item) => item.orderConfirmationInvoiceDetail !== undefined
    );
    const anyOSS = items.some((item) => item.orderSheetSet !== undefined);

    let name = "Brand";
    let key = [];

    if (anyOC) {
      key.push("OC");
    }

    if (anyOSS) {
      key.push("OSS");
    }

    if (key.length > 0) {
      name = `${key.join(" OR ")} Id / ${name}`;
    }

    return name;
  }, [items]);

  return (
    <AppSelect<DetailBrand>
      value={value}
      name={name}
      idFunc={idFunc}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isSearchable={isSearchable}
      isClearable={false}
      options={options}
      onSelect={onSelect}
    />
  );
}
