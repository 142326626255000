export class NegativeSequencer {
  private _value: number = 0;

  get value(): number {
    return this._value;
  }

  next(): number {
    return --this._value;
  }
}
