import { IdentityParameter } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import {
  calcIdentityUrlPrefix,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { OrderSheetTab } from "@/features/order-sheets/order-sheet.type";

export interface GetOrderSheetTabRequest {}
export interface GetOrderSheetTabResponse extends OrderSheetTab {}

export interface GetOrderSheetTabPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
  orderSheetTabIndex: number;
}

export function getGetOrderSheetTabKey(
  parameter: GetOrderSheetTabPathParameter,
  request?: GetOrderSheetTabRequest
): SWRObjectKey<GetOrderSheetTabRequest> {
  const { orderSheetSetId, orderSheetId, orderSheetTabIndex } = parameter;
  let url = calcIdentityUrlPrefix(parameter);
  url += `/order-sheet-sets/${orderSheetSetId}/order-sheets/${orderSheetId}/order-sheet-tabs/${orderSheetTabIndex}`;
  return {
    url,
    params: request,
  };
}
export default function useGetOrderSheetTab(
  parameter: GetOrderSheetTabPathParameter,
  request: GetOrderSheetTabRequest,
  config: SWRConfiguration
) {
  const fetcher = useFetcher<
    GetOrderSheetTabRequest,
    GetOrderSheetTabResponse
  >();

  return useSWR(getGetOrderSheetTabKey(parameter, request), fetcher, config);
}
