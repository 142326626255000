import useFetcher from "@/utils/use-fetcher";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import {
  createFtaSignatureKey,
  CreateFtaSignatureRequest,
  CreateFtaSignatureResponse,
} from "@/features/invoices/triangular-trades/hooks/use-create-fta-signature";

export default function useCreateFtaSignatureInfinite() {
  const fetcher = useFetcher<
    CreateFtaSignatureRequest,
    CreateFtaSignatureResponse
  >();
  return useYWRInfinite(createFtaSignatureKey, fetcher);
}
