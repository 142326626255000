import AppProformaInvoicesExclusiveSelector from "@/features/invoices/app-proforma-invoices-exclusive-selector";
import { Field, FieldProps, FormikProps } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useMemo } from "react";
import { ListAllProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, FormControl, FormLabel, Skeleton } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import IsolatedProformaInvoicePanel from "@/features/invoices/order-confirmation-invoices/isolated-proforma-invoice-panel";
import { DenseProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

interface FormFields {
  id?: number;
}

interface LinkedProformaInvoiceFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  onSelect: (value: DenseProformaInvoice[]) => void;
  request?: ListAllProformaInvoicesRequest;
  title?: string;
  fieldName?: keyof T;
  nameFunc?: (pInvoice: DenseProformaInvoice) => string;
}

export default function LinkedProformaInvoiceFormControl<T extends FormFields>({
  formik,
  isEditing,
  isDisabled = false,
  onSelect,
  request,
  title = "related_proforma_invoices",
  //@ts-ignore
  fieldName = "proformaInvoices",
  nameFunc,
}: LinkedProformaInvoiceFormControlProps<T>) {
  const { t, tTitle } = useI18nHelper();

  const ids = useMemo(() => {
    const candidate = formik.values[fieldName];
    if (Array.isArray(candidate)) {
      return candidate.map((pInvoice) => pInvoice.id);
    }
    return [];
  }, [fieldName, formik.values]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Skeleton height={"16px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
        </Flex>
      </AppPanel>
    );
  }

  if (!isEditing) {
    return (
      <IsolatedProformaInvoicePanel title={title} proformaInvoiceIds={ids} />
    );
  }

  return (
    <AppPanel title={tTitle(title)}>
      <Field name={fieldName}>
        {(props: FieldProps<{ id: number } | [] | undefined, FormFields>) => {
          return (
            <FormControl display={"flex"} flexDirection={"column"} gap={"4px"}>
              <FormLabel
                fontSize={"12px"}
                color={"#6F6F6C"}
                padding={"0"}
                margin={"0"}
                display={"none"}
              >
                {toTitle(t("proforma_invoices"))}
              </FormLabel>

              <AppProformaInvoicesExclusiveSelector
                nameFunc={nameFunc}
                id={ids}
                request={request}
                isDisabled={isDisabled}
                onSelect={(value) => {
                  onSelect(value);
                }}
              />
            </FormControl>
          );
        }}
      </Field>
    </AppPanel>
  );
}
