import { IdentityParameter } from "@/features/types";
import { DenseBankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import useGetDenseBankRemittanceReceiptFetcher from "@/features/invoices/bank-remittance-receipts/hooks/use-get-dense-bank-remittance-receipt-fetcher";

export interface GetDenseBankRemittanceReceiptParameter
  extends IdentityParameter {
  bankRemittanceReceiptId: number;
}

export interface GetDenseBankRemittanceReceiptRequest {}
export interface GetDenseBankRemittanceReceiptResponse
  extends DenseBankRemittanceReceipt {}

export const getDenseBankRemittanceReceiptTemplateUrl = `/:by/:companyId/dense-bank-remittance-receipts/:bankRemittanceReceiptId/dense`;

export function getGetDenseBankRemittanceReceiptKey(
  parameter?: GetDenseBankRemittanceReceiptParameter,
  request?: GetDenseBankRemittanceReceiptRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDenseBankRemittanceReceiptTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetDenseBankRemittanceReceiptKey(
  key: AxiosRequestConfig<GetDenseBankRemittanceReceiptRequest>
) {
  const urlParse = new UrlParser<GetDenseBankRemittanceReceiptParameter>(
    getDenseBankRemittanceReceiptTemplateUrl,

    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      bankRemittanceReceiptId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParse.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetDenseBankRemittanceReceipt(
  parameter?: GetDenseBankRemittanceReceiptParameter,
  request?: GetDenseBankRemittanceReceiptRequest,
  config?: SWRConfiguration<GetDenseBankRemittanceReceiptResponse>
) {
  const fetcher = useGetDenseBankRemittanceReceiptFetcher();
  return useSWR(
    getGetDenseBankRemittanceReceiptKey(parameter, request),
    fetcher,
    config
  );
}
