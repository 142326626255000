import { Flex, Text } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppFileWithTypeInput from "@/features/line-sheet-sets/app-file-with-type-input";
import { DragEventHandler, useRef, useState } from "react";
import { produce } from "immer";
import { TransientFile } from "@/features/types";
import { uniqueId } from "lodash";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toTitle } from "@/utils/case";

interface AppFileFormControlProps<Type> {
  name: string;
  transientFiles: TransientFile<Type>[];
  types?: Type[];
  typeFunc?: (binary: File) => Type;
  onChange?: (files: TransientFile<Type>[]) => void;
  number1?: boolean;
  multiple?: boolean;
}

export default function AppFileFormControl<Type = any>({
  name,
  transientFiles,
  types = [],
  typeFunc = (binary: File) => ({} as Type),
  onChange = (files: TransientFile<Type>[]) => {},
  number1 = false,
  multiple = true,
}: AppFileFormControlProps<Type>) {
  //const [files, setFiles] = useState<File[]>([]);
  const { warning: showWarning } = useAppToasts();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const { tWithFuc } = useI18nHelper();

  const handleOnDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
    handleAddBinaries(event.dataTransfer.files);
  };

  const handleOnDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
  };

  const handleOnDragLeave: DragEventHandler<HTMLDivElement> = () => {
    setIsDragging(false);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleAddBinaries = (fileList: FileList | null) => {
    const binaries = Array.from(fileList || []);

    if (!multiple && binaries.length > 1) {
      showWarning("Only one file is allowed.");
      return;
    }

    if (!multiple) {
      onChange([
        {
          id: uniqueId(),
          type: typeFunc(binaries[0]),
          binary: binaries[0],
          number1: number1 ? 0 : undefined,
        },
      ]);
    } else {
      onChange([
        ...transientFiles,
        ...binaries.map(
          (i): TransientFile<Type> => ({
            id: uniqueId(),
            type: typeFunc(i),
            binary: i,
            number1: number1 ? 0 : undefined,
          })
        ),
      ]);
    }
  };

  const handleTransientFileDelete = (target: TransientFile) => {
    onChange(transientFiles.filter((t) => t.id !== target.id));
  };

  const handleTransientFileChange = (file: TransientFile) => {
    const next = produce(transientFiles, (draft) => {
      const i = draft.findIndex((f) => f.id === file.id);
      if (i > -1) {
        draft[i] = file;
      }
      return draft;
    });
    onChange(next);
  };

  return (
    <Flex gridColumn={"1/3"} flexDirection={"column"} gap={"12px"}>
      <Flex
        border={"0.5px dashed #8F8F8C"}
        borderColor={isDragging ? "#1272EF" : "#8F8F8C"}
        borderRadius={"4px"}
        padding={"12px 20px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        height={"96px"}
        onDrop={handleOnDrop}
        onDragEnter={handleOnDragEnter}
        onDragLeave={handleOnDragLeave}
        onDragOver={handleDragOver}
      >
        <Text fontSize={"12px"} textAlign={"center"}>
          {tWithFuc("drag_and_drop_file_here", toTitle)}
          <br />
          {tWithFuc("or", toTitle)}
        </Text>
        <AppButton
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          {tWithFuc("choose_file", toTitle)}
        </AppButton>
      </Flex>

      <input
        type={"file"}
        multiple={true}
        ref={fileRef}
        style={{ display: "none" }}
        onChange={(e) => {
          handleAddBinaries(e.target.files);
        }}
      />
      <Flex
        flexDirection={"column"}
        gap={"8px"}
        h={multiple ? "144px" : "24px"}
        overflowY={"scroll"}
        paddingRight={"8px"}
        style={{ scrollbarGutter: "stable" }}
      >
        {transientFiles.map((t, index) => {
          return (
            <AppFileWithTypeInput<Type>
              key={`AppFileInput_${t.id}`}
              types={types}
              transientFile={t}
              number1={number1}
              handleDelete={handleTransientFileDelete}
              handleChange={handleTransientFileChange}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
