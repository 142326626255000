import AppSelect from "@/features/line-sheet-sets/app-select";
import { useMemo } from "react";
import {
  CreditInvoiceType,
  CreditInvoiceTypes,
} from "@/features/invoices/credit-invoices/credit-invoice.type";
import { useTranslation } from "react-i18next";
import { toLower, toTitle } from "@/utils/case";

interface AppCreditInvoiceTypeSelectorProps {
  value?: CreditInvoiceType;
  onSelect?: (type: CreditInvoiceType | undefined) => void;
  width?: string;
  isClearable?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

export default function AppCreditInvoiceTypeSelector({
  value,
  width = "144px",
  onSelect,
  isClearable,
  isReadOnly = false,
  isDisabled = false,
}: AppCreditInvoiceTypeSelectorProps) {
  const { t } = useTranslation();
  const options = useMemo((): AppSelectOption<CreditInvoiceType>[] => {
    return CreditInvoiceTypes.map((type) => {
      return {
        name: toTitle(t(`credit_invoice.type.${toLower(type)}`)),
        value: type,
      };
    });
  }, [t]);
  return (
    <AppSelect
      name={toTitle(t("credit_invoice.type"))}
      options={options}
      width={width}
      value={value}
      onSelect={onSelect}
      isClearable={isClearable}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
    />
  );
}
