import { keyBy, uniq } from "lodash";

interface ResourceReference {
  id: number;
}

export type ResourceReferenceGetter<LIGHT> = (
  item: LIGHT
) => (ResourceReference | null)[] | ResourceReference | null;

export type ResourceReferenceSetter<LIGHT, DENSE> = (
  item: LIGHT,
  value: DENSE[] | DENSE
) => void;

export async function condenseResourcesById<LIGHT, DENSE>(
  lightItems: LIGHT[],
  getter: (
    item: LIGHT
  ) => (ResourceReference | null)[] | ResourceReference | null,
  setter: (item: LIGHT, value: DENSE[] | DENSE) => void,
  denseItemsFetcher: (ids: number[]) => Promise<DENSE[]>
) {
  const ids = uniq(
    lightItems
      .flatMap((item) => {
        const value = getter(item);
        if (Array.isArray(value)) {
          return value.map((i) => (i !== null ? i.id : null));
        } else if (value != null) {
          return [value.id];
        } else {
          return [null];
        }
      })
      .filter((i): i is number => i !== null)
  );
  const denseItems = await denseItemsFetcher(ids);
  const denseItemById = keyBy(denseItems, "id");
  lightItems.forEach((item) => {
    const lightValue = getter(item);
    if (Array.isArray(lightValue)) {
      setter(
        item,
        lightValue
          .filter((i): i is { id: number } => i !== null)
          .map((i) => denseItemById[i.id])
          .filter((i) => i !== undefined)
      );
    } else if (lightValue != null) {
      const denseItem = denseItemById[lightValue.id];
      if (denseItem !== undefined) {
        setter(item, denseItemById[lightValue.id]);
      }
    }
  });
}
