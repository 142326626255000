import { Box, Flex } from "@chakra-ui/react";
import AppTag from "@/features/invoices/app-tag";
import { produce } from "immer";

interface AppTagControllerProps {
  values: { id: any; name: string }[];
  onChange?: (values: { id: any; name: string }[]) => void;
  isDisabled?: boolean;
}
export default function AppTagController({
  values = [],
  onChange = () => {},
  isDisabled = false,
}: AppTagControllerProps) {
  return (
    <Flex
      borderRadius={"4px"}
      border={"0.5px solid var(--Gray-Groomy, #8F8F8C)"}
      background={"var(--pathrade-white, #FFF)"}
      height={"100%"}
      flexDirection={"row"}
      alignItems={"center"}
      padding={"0px 8px"}
      gap={"4px"}
      overflowX={"hidden"}
      // css={`
      //   &::-webkit-scrollbar {
      //     height: 4px;
      //     width: auto;
      //   }
      //   &::-webkit-scrollbar-track {
      //     background: var(--Primary-Bright, #f7f9fa);
      //   }
      //   &::-webkit-scrollbar-thumb {
      //     background: var(--Gray-Groomy, #8f8f8c);
      //   }
      // `}
    >
      {values.map((value, index) => {
        const handleDelete = () => {
          onChange(
            produce(values, (draft) => {
              draft.splice(index, 1);
            })
          );
        };
        return (
          <AppTag
            key={"AppTag_" + value.id}
            value={value}
            isDisabled={isDisabled}
            onDelete={handleDelete}
          />
        );
      })}
    </Flex>
  );
}
