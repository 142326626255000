export function base64UrlDecode(encoded: string) {
  let decoded = encoded.replace(/-/g, "+").replace(/_/g, "/");

  const pad = decoded.length % 4;
  if (pad) {
    if (pad === 1) {
      //ERROR
    }
    decoded += new Array(5 - pad).join("=");
  }
  return atob(decoded);
}

export function base64UrlEncode(decoded: any) {
  let encoded = btoa(decoded).replace(/-/g, "+").replace("/_/g", "/");
  const pad = encoded.length % 4;
  if (pad) {
    if (pad === 1) {
    }
    encoded += new Array(5 - pad).join("=");
  }
  return encoded;
}
