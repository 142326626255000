import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface GetBankRemittanceReceiptRequest {}
export interface GetBankRemittanceReceiptResponse
  extends BankRemittanceReceipt {}
export interface GetBankRemittanceReceiptPathParameter
  extends IdentityParameter {
  bankRemittanceReceiptId: number;
}

export const getBankRemittanceReceiptTemplateUrl = `/:by/:companyId/bank-remittance-receipts/:bankRemittanceReceiptId`;

export function getGetBankRemittanceReceiptKey(
  parameter?: GetBankRemittanceReceiptPathParameter,
  request?: GetBankRemittanceReceiptRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getBankRemittanceReceiptTemplateUrl, parameter),
      params: request,
    };
  }

  return null;
}

export default function useGetBankRemittanceReceipt(
  parameter?: GetBankRemittanceReceiptPathParameter,
  request?: GetBankRemittanceReceiptRequest,
  config?: SWRConfiguration<GetBankRemittanceReceiptResponse>
) {
  const fetcher = useFetcher<
    GetBankRemittanceReceiptRequest,
    GetBankRemittanceReceiptResponse
  >();
  return useSWR(
    getGetBankRemittanceReceiptKey(parameter, request),
    fetcher,
    config
  );
}
