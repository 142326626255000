import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toTitle } from "@/utils/case";
import useIdentity from "../ui/hooks/use-identity";
import landingNavigationStyle from "./landing-navigation.module.scss";
import { Link } from "react-router-dom";
import { getDefaultPageByIdentity } from "@/pages";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface LandingNavigationProps {
  nextPageName: string;
  signedInClass: string | null;
}

export default function LandingNavigation({
  nextPageName,
  signedInClass,
}: LandingNavigationProps) {
  const { t } = useI18nHelper();
  const identity = useIdentity();
  const { calcNavigateTo } = useRouterHelper();

  return (
    <nav className={`${landingNavigationStyle.navWrapper}`}>
      <div
        className={`${landingNavigationStyle.navContainer} ${signedInClass}`}
      >
        <div className={`${landingNavigationStyle.navLogo}`}>
          <a
            href={window.location.origin + "/#"}
            aria-label={t("common:meta.site_name")}
          >
            <img
              src={window.location.origin + "/logo-white.svg"}
              className={`${landingNavigationStyle.navLogoImg}`}
              alt={toTitle(t("common:pathrade_logo"))}
              title={toTitle(t("common:pathrade_logo"))}
              loading="lazy"
              height="32px"
              width="156px"
            />
          </a>
        </div>
        <div className={`${landingNavigationStyle.navRight}`}>
          <Link
            to={calcNavigateTo(getDefaultPageByIdentity(identity))}
            className={"gradientButton gradientBorder"}
          >
            <div className="gradientButtonBG">{nextPageName}</div>
          </Link>
        </div>
      </div>
    </nav>
  );
}
