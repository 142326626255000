import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { CreateOrderSheetResponse } from "@/features/line-sheet-sets/hooks/use-create-order-sheet";
import { OrderSheetTab } from "@/features/order-sheets/order-sheet.type";
import { OrderSheetRevision } from "@/features/order-sheet-sets/order-sheet-revision.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface CopyOrderSheetRequest {
  "sourceOrderSheet.id": number;
}
export interface CopyOrderSheetResponse extends OrderSheetRevision {}
export interface CopyOrderSheetPathParameter {
  agencyId: number;
  orderSheetSetId: number;
  _sourceOrderSheetId: number;
}

export const copyOrderSheetTemplateUrl = `/agencies/:agencyId/order-sheet-sets/:orderSheetSetId/order-sheets/create-by-copy?_source_order_sheet_id=:_sourceOrderSheetId`;

export const copyOrderSheetKey: YWRKey<CopyOrderSheetPathParameter> = {
  url: (parameter) => {
    return calcUrl(copyOrderSheetTemplateUrl, parameter);
  },
  method: "post",
};

export default function useCopyOrderSheet() {
  const fetcher = useFetcher<CopyOrderSheetRequest, CreateOrderSheetResponse>();
  return useYWR(copyOrderSheetKey, fetcher);
}
