import AppSelect from "@/features/line-sheet-sets/app-select";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toLower, toTitle } from "@/utils/case";

interface AppBalanceTransactionAddUseSelectorProps {
  value?: BalanceTransactionAddUse;
  onSelect?: (type: BalanceTransactionAddUse | undefined) => void;
  width?: string;
  isClearable?: boolean;
}

export type BalanceTransactionAddUse = "Add" | "Use";
export const BalanceTransactionAddUses: BalanceTransactionAddUse[] = [
  "Add",
  "Use",
];

export default function AppBalanceTransactionAddUseSelector({
  value,
  width = "144px",
  onSelect,
  isClearable,
}: AppBalanceTransactionAddUseSelectorProps) {
  const { t } = useTranslation();
  const options = useMemo((): AppSelectOption<BalanceTransactionAddUse>[] => {
    return BalanceTransactionAddUses.map((v) => {
      return {
        name: t(`add_or_use.${toLower(v)}`),
        value: v,
      };
    });
  }, [t]);

  return (
    <AppSelect
      name={toTitle(t("add_or_use"))}
      options={options}
      width={width}
      value={value}
      onSelect={onSelect}
      isClearable={isClearable}
    />
  );
}
