import {
  getGetOrderConfirmationInvoiceKey,
  GetOrderConfirmationInvoiceRequest,
  GetOrderConfirmationInvoiceResponse,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-get-order-confirmation-invoice";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseOrderConfirmationInvoiceRequest,
  GetDenseOrderDenseConfirmationInvoiceResponse,
  parseGetDenseOrderConfirmationInvoiceKey,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-get-dense-order-confirmation-invoice";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";
import useCondensePayments from "@/features/invoices/payment/hooks/use-condense-payments";
import useIdentity from "@/features/ui/hooks/use-identity";

export default function useGetDenseOrderConfirmationFetcher() {
  const getOrderConfirmationFetcher = useFetcher<
    GetOrderConfirmationInvoiceRequest,
    GetOrderConfirmationInvoiceResponse
  >();

  const condenseOrderSheetSets = useCondenseOrderSheetSets();
  const condensePayments = useCondensePayments();
  const identity = useIdentity();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseOrderConfirmationInvoiceRequest>
    ): Promise<GetDenseOrderDenseConfirmationInvoiceResponse> => {
      const { parameter, request } =
        parseGetDenseOrderConfirmationInvoiceKey(config);

      const getOrderConfirmationResponse = await getOrderConfirmationFetcher(
        getGetOrderConfirmationInvoiceKey(parameter, request)
      );

      if (getOrderConfirmationResponse) {
        await Promise.all([
          condenseOrderSheetSets(
            [getOrderConfirmationResponse],
            (i) => {
              return i.orderSheetSets;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.orderSheetSets = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          identity?.company?.type !== "BOUTIQUE"
            ? condensePayments(
                [getOrderConfirmationResponse],
                (i) => {
                  return i.payments;
                },
                (item, value) => {
                  if (Array.isArray(value)) {
                    item.payments = value;
                  }
                },
                {
                  by: parameter.by,
                  companyId: parameter.companyId,
                }
              )
            : Promise.resolve(),
        ]);
      }

      // @ts-ignore
      return Promise.resolve(getOrderConfirmationResponse);
    };
  }, [getOrderConfirmationFetcher, condenseOrderSheetSets, condensePayments]);
}
