import AppLabel, { AppLabelProps } from "@/features/ui/app-label";
import { useMemo } from "react";

interface AppRemittanceStatusLabelProps {
  to?: "AGENCY" | "BOUTIQUE";
  value?: "REMITTED" | "UNREMITTED" | "SKIPPED";
}

export default function AppRemittanceStatusLabel({
  to,
  value,
}: AppRemittanceStatusLabelProps) {
  const props = useMemo((): AppLabelProps | undefined => {
    let prefix = "remittance_status";
    switch (to) {
      case "AGENCY":
        prefix = "common:remittance_status_to_agency";
        break;
      case "BOUTIQUE":
        prefix = "common:remittance_status_to_boutique";
        break;
    }

    switch (value) {
      case "REMITTED":
        return {
          nameKey: `${prefix}.remitted`,
          level: "SUCCESS",
        };
      case "UNREMITTED":
        return {
          nameKey: `${prefix}.remittance_required`,
          level: "ERROR",
        };
      case "SKIPPED":
        return {
          nameKey: `${prefix}.remittance_skipped`,
          level: "LIGHT",
        };
    }
  }, [to, value]);

  if (props) {
    return <AppLabel {...props} size={"small"} />;
  } else {
    return null;
  }
}
