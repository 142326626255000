import useFetcher from "@/utils/use-fetcher";
import {
  getGetOrderSheetTabKey,
  GetOrderSheetTabRequest,
  GetOrderSheetTabResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-tab";
import {
  getGetLineSheetTabKey,
  GetLineSheetTabRequest,
  GetLineSheetTabResponse,
} from "@/features/line-sheet-sets/hooks/use-get-line-sheet-tab";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListAllSheetTabsRequest,
  ListAllSheetTabsResponse,
  parseListAllSheetTabsKey,
} from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs";
import { SheetProduct } from "@/features/order-sheets/sheet.type";

export default function useListAllSheetTabsFetcher() {
  const getOrderSheetTabFetcher = useFetcher<
    GetOrderSheetTabRequest,
    GetOrderSheetTabResponse
  >();
  const getLineSheetTabFetcher = useFetcher<
    GetLineSheetTabRequest,
    GetLineSheetTabResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListAllSheetTabsRequest>
    ): Promise<ListAllSheetTabsResponse> => {
      const { parameter, request } = parseListAllSheetTabsKey(config);

      const responses = await Promise.all(
        request.sheetTabIndexes.flatMap((tabIndex) => {
          return [
            getOrderSheetTabFetcher(
              getGetOrderSheetTabKey({
                by: parameter.by,
                companyId: parameter.companyId,
                orderSheetSetId: parameter.orderSheetSetId,
                orderSheetId: parameter.orderSheetId,
                orderSheetTabIndex: tabIndex,
              })
            ),
            getLineSheetTabFetcher(
              getGetLineSheetTabKey({
                by: parameter.by,
                companyId: parameter.companyId,
                lineSheetSetId: request.lineSheetSetId,
                lineSheetId: request.lineSheetId,
                lineSheetTabIndex: tabIndex,
              })!!
            ),
          ];
        })
      );

      const sheetTabs = [];

      for (let i = 0; i < responses.length; i += 2) {
        const orderSheetTab = responses[i] as GetOrderSheetTabResponse;
        const lineSheetTab = responses[i + 1] as GetLineSheetTabResponse;

        const sheetProducts = lineSheetTab.products.map(
          (lineSheetProduct, index): SheetProduct => {
            const orderSheetProduct = orderSheetTab.products[index];
            if (orderSheetProduct) {
              return {
                ...lineSheetProduct,
                supplyPrice: orderSheetProduct.supplyPrice,
                priceAdjustedBy: orderSheetProduct.priceAdjustedBy,
                orderQuantityWithOptionList:
                  orderSheetProduct.orderQuantityWithOptionList,
                confirmedPriceAdjustedBy:
                  orderSheetProduct.confirmedPriceAdjustedBy,
                confirmedPrice: orderSheetProduct.confirmedPrice,
                newStockWithOptionList:
                  orderSheetProduct.newStockWithOptionList,
                confirmedQuantityWithOptionList:
                  orderSheetProduct.confirmedQuantityWithOptionList,
                firstOrderQuantityWithOptionList:
                orderSheetProduct.firstOrderQuantityWithOptionList,
                prevOrderQuantityWithOptionList:
                orderSheetProduct.prevOrderQuantityWithOptionList,
                latestOrderQuantityWithOptionList:
                  orderSheetProduct.latestOrderQuantityWithOptionList,
              };
            } else {
              return {
                ...lineSheetProduct,
                orderQuantityWithOptionList: [],
                confirmedPriceAdjustedBy: null,
                confirmedPrice: null,
                newStockWithOptionList: null,
                confirmedQuantityWithOptionList: null,
                firstOrderQuantityWithOptionList: null,
                prevOrderQuantityWithOptionList: null,
                latestOrderQuantityWithOptionList: null,
              };
            }
          }
        );

        sheetTabs.push({
          index: lineSheetTab.index,
          name: lineSheetTab.name,
          products: sheetProducts,
        });
      }

      return Promise.resolve({
        _orderSheetSetId: parameter.orderSheetSetId,
        _orderSheetId: parameter.orderSheetId,
        sheetTabs,
      });
    };
  }, [getOrderSheetTabFetcher, getLineSheetTabFetcher]);
}
