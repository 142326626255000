import useIdentity from "@/features/ui/hooks/use-identity";
import BuyerLineSheetSetListPage from "@/features/order-sheet-sets/buyer-line-sheet-set-list-page";
import AgencyLineSheetSetListPage from "@/features/order-sheet-sets/agency-line-sheet-set-list-page";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import BoutiqueLineSheetSetListPage from "@/features/order-sheet-sets/boutique-line-sheet-set-list-page";

export default function LineSheetSetListPage() {
  const identity = useIdentity();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Pathrade | {t("line_sheets")}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content={`Pathrade | ${t("line_sheets")}`} />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>

      {identity?.company?.type === "AGENCY" && <AgencyLineSheetSetListPage />}
      {identity?.company?.type === "BOUTIQUE" && (
        <BoutiqueLineSheetSetListPage />
      )}
      {identity?.company?.type === "BUYER" && <BuyerLineSheetSetListPage />}
    </>
  );
}
