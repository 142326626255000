import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";
import {
  createBankRemittanceReceiptKey,
  CreateBankRemittanceReceiptRequest,
  CreateBankRemittanceReceiptResponse,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-create-bank-remittance-receipt";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useCreateBankRemittanceReceiptInfinite(
  config?: AxiosRequestConfig<CreateBankRemittanceReceiptRequest>
) {
  const fetcher = useFetcher<
    CreateBankRemittanceReceiptRequest,
    CreateBankRemittanceReceiptResponse
  >();

  return useYWRInfinite(createBankRemittanceReceiptKey, fetcher, config);
}
