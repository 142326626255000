import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useUpdateBankRemittanceReceipt, {
  updateBankRemittanceReceiptKey,
  UpdateBankRemittanceReceiptPathParameter,
  UpdateBankRemittanceReceiptRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-update-bank-remittance-receipt";
import { useCallback } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isFile, StoredObject } from "@/features/types";
import {
  createBankRemittanceReceiptKey,
  CreateBankRemittanceReceiptPathParameter,
  CreateBankRemittanceReceiptRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-create-bank-remittance-receipt";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import useCreateBankRemittanceReceiptInfinite from "@/features/invoices/bank-remittance-receipts/hooks/use-create-bank-remittance-receipt-infinite";

function mutateBankRemittanceReceipts(bankRemittanceReceiptId?: number) {
  if (bankRemittanceReceiptId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers)\/\d+\/(dense-)?bank-remittance-receipts\/${bankRemittanceReceiptId}/`
      )
    );
  }
  mutateIfMatched(
    /\/(agencies|buyers)\/\d+\/(dense-)?bank-remittance-receipts/
  );
}

export function useBankRemittanceReceiptDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const { fire: fireUpdateBankRemittanceReceipt } =
    useUpdateBankRemittanceReceipt();

  const { fire: fireCreateBankRemittanceReceipt } =
    useCreateBankRemittanceReceiptInfinite();

  const addCreate = useCallback(
    async (
      parameter: CreateBankRemittanceReceiptPathParameter,
      request: Omit<CreateBankRemittanceReceiptRequest, "file"> & {
        file: File;
      }
    ) => {
      addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return [request.file];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: storeObjectResponses[0],
          };
        },
        fire: fireCreateBankRemittanceReceipt,
        keyOrFunc: calcYWRUrl(createBankRemittanceReceiptKey, parameter),
        onPostFunc: () => {
          mutateBankRemittanceReceipts();
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreateBankRemittanceReceipt, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdateBankRemittanceReceiptPathParameter,
      request: Omit<UpdateBankRemittanceReceiptRequest, "file"> & {
        file: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          if (isFile(request.file)) {
            return [request.file];
          }
          return [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: isFile(source.file) ? storeObjectResponses[0] : source.file,
          };
        },
        fire: fireUpdateBankRemittanceReceipt,
        keyOrFunc: calcYWRUrl(updateBankRemittanceReceiptKey, parameter),
        onPostFunc: (res) => {
          mutateBankRemittanceReceipts(res.id);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireUpdateBankRemittanceReceipt, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateBankRemittanceReceiptPathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateBankRemittanceReceiptKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate,
    addUpdate,
    isUpdateQueued,
  };
}
