import useIdentity from "@/features/ui/hooks/use-identity";
import { Helmet } from "react-helmet-async";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppListHeader from "@/features/ui/app-list-header";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { toTitle } from "@/utils/case";
import AppCreditInvoiceTypeSelector from "@/features/invoices/credit-invoices/app-credit-invoice-type-selector";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppButton from "@/features/line-sheet-sets/app-button";
import { Box, Flex } from "@chakra-ui/react";
import CreditInvoiceGrid from "@/features/invoices/credit-invoices/credit-invoice-grid";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useEffect, useMemo } from "react";
import {
  DEFAULT_LIST_CREDIT_INVOICES_PAGE_SIZE,
  ListCreditInvoicesRequest,
} from "@/features/invoices/credit-invoices/hooks/use-list-credit-invoices";
import useListDenseCreditInvoiceInvoices from "@/features/invoices/credit-invoices/hooks/use-list-dense-credit-invoices";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";

export interface PersistedCreditInvoiceListPageQuery
  extends Partial<ListCreditInvoicesRequest> {}

export default function CreditInvoiceListPage() {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const { t } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const [query, setQuery] =
    useTypedSearchParams<PersistedCreditInvoiceListPageQuery>();

  const listCreditInvoicesKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_CREDIT_INVOICES_PAGE_SIZE,
        },
      };
    }
  }, [identity, query]);

  const {
    data: listDenseCreditInvoicesData,
    error: listDenseCreditInvoicesError,
    isLoading: isListDenseCreditInvoiceInvoicesLoading,
    mutate: mutateListDenseCreditInvoiceInvoices,
  } = useListDenseCreditInvoiceInvoices(
    listCreditInvoicesKey?.parameter,
    listCreditInvoicesKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(listDenseCreditInvoicesError);
  }, [showError, listDenseCreditInvoicesError]);

  return (
    <>
      <Helmet>
        <title>{`Pathrade | ${tTitle("credit_invoices")}`}</title>
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("credit_invoices")}`}
        />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              value={query.season__in}
              onSelect={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                })
              }
              width={"120px"}
            />,
            // <AppRemittanceStatusSelector
            //   key={"AgencyInvoiceListFilter_RemittanceStatus"}
            //   value={query.remittanceStatus__in?.[0]}
            //   onSelect={(v) => patchQuery("remittanceStatus__in", [v])}
            // />,
            <AppCompanySelect
              key={"AgencyCreditInvoiceListFilter_OrderedBy"}
              name={toTitle(t("ordered_by"))}
              types={["BUYER"]}
              width={"120px"}
              id={query["issuedToCompany.id__eq"]}
              onSelect={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    "issuedToCompany.id__eq": value?.id,
                    pageNumber: 1,
                  };
                })
              }
            />,
            <AppCompanySelect
              key={"AgencyCreditInvoiceListFilter_IssuedBy"}
              name={toTitle(t("issued_by"))}
              types={["BOUTIQUE", "CROSS_TRADER"]}
              width={"120px"}
              isMultiple={true}
              id={query["issuedByCompany.id__in"]}
              onSelect={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    "issuedByCompany.id__in": value?.map((v) => v.id),
                    pageNumber: 1,
                  };
                })
              }
            />,
            <AppCreditInvoiceTypeSelector
              width={"120px"}
              key={"AgencyCreditInvoiceListFilter_CreditInvoiceType"}
              value={query.type__eq}
              onSelect={(value) => {
                //patchQuery("type__in", [value]);
                setQuery((prev) => {
                  return {
                    ...prev,
                    type__eq: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppDatePicker
              key={"AgencyCreditInvoiceListFilter_IssuedOn"}
              name={toTitle(t("issued_on"))}
              value={query.issuedOn__eq}
              showTimeInput={false}
              width={"120px"}
              onChange={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    issuedOn__eq: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"AgencyCreditInvoiceListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              width={"120px"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <AppButton
              key={"AgencyCreditInvoiceListAction_UploadInvoices"}
              variant={"primary"}
              size={"medium"}
              onClick={() => {
                navigate("/credit-invoices/upload");
              }}
            >
              {toTitle(t("upload_credit_invoices", { ns: "credit_invoices" }))}
            </AppButton>,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <CreditInvoiceGrid
            resources={listDenseCreditInvoicesData?.creditInvoices}
            mutateResources={mutateListDenseCreditInvoiceInvoices}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listDenseCreditInvoicesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: 1,
                };
              })
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
