import useIdentity from "@/features/ui/hooks/use-identity";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import BuyerMyAccountPage from "@/features/my-account/buyer-my-account-page";

export default function MyAccountPage() {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);

  if (company?.type === "BUYER") {
    return (
      <>
        <Helmet>
          <title>Pathrade | My Account</title>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
          <meta property="og:title" content="Pathrade | My Account" />
          <meta
            property="og:url"
            content={window.location.origin + window.location.pathname}
          />
        </Helmet>
        <BuyerMyAccountPage />
      </>
    );
  } else {
    return null;
  }




}