import {
  Company,
  CompanyType,
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { getUrlPrefixByIdentityParameter } from "@/features/ui/helpers/zwr-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { FtaSignature } from "@/features/invoices/triangular-trades/triangular-trade.type";
//
// export interface ListFtaSignatureListWithoutPagingRequest {
//   type__in?: CompanyType[];
// }

export interface ListFtaSignatureListRequest
  extends PageNumberBasedListRequest {}

export interface ListFtaSignatureListResponse
  extends PageNumberBasedListResponse {
  ftaSignatureList: FtaSignature[];
}

export interface ListFtaSignatureListPathParameter extends IdentityParameter {}

export function getListFtaSignatureListPathParameter(
  identity: Identity | null
): ListFtaSignatureListPathParameter | null {
  return toIdentityParameter(identity);
}

export function getListFtaSignatureListKey(
  identityOrParameter?: Identity | ListFtaSignatureListPathParameter | null,
  request?: ListFtaSignatureListRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListFtaSignatureListPathParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    const prefix = getUrlPrefixByIdentityParameter(parameter);
    return {
      url: `${prefix}/fta_signature`,
      params: request,
    };
  }
  return null;
}

export default function useListFtaSignatureList(
  parameter?: ListFtaSignatureListPathParameter | null,
  request?: ListFtaSignatureListRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListFtaSignatureListRequest,
    ListFtaSignatureListResponse
  >();
  return useSWR(
    getListFtaSignatureListKey(parameter, request),
    fetcher,
    config
  );
}
