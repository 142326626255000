import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect, useMemo } from "react";
import {
  DEFAULT_LIST_PAYMENTS_PAGE_SIZE,
  ListPaymentsPathParameter,
  ListPaymentsRequest,
  useListPayments,
} from "@/features/invoices/payment/hooks/use-list-payments";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { Helmet } from "react-helmet-async";
import { Box, Flex } from "@chakra-ui/react";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";
import AppListHeader from "@/features/ui/app-list-header";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { toLower, toTitle } from "@/utils/case";
import AppRemittanceStatusSelect from "@/features/invoices/app-remittance-status-select";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import PaymentGrid from "@/features/invoices/payment/payment-grid";
import AppButton from "@/features/line-sheet-sets/app-button";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { CompanyType } from "@/features/types";

export type PersistedPaymentListPage = Omit<ListPaymentsRequest, "pageSize">;

export default function PaymentListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();
  const [query, setQuery] = useTypedSearchParams<PersistedPaymentListPage>();
  const { navigate } = useRouterHelper();

  const listPaymentsKey = useMemo(():
    | {
        parameter: ListPaymentsPathParameter;
        request: ListPaymentsRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_PAYMENTS_PAGE_SIZE,
          status__in:
            identity.company.type === "BUYER"
              ? ["NORMAL", "COMPLETED"]
              : undefined,
        },
      };
    }
  }, [identity, query]);

  const {
    data: listPaymentsData,
    error: listPaymentsError,
    mutate: mutateListPayments,
  } = useListPayments(listPaymentsKey?.parameter, listPaymentsKey?.request, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (listPaymentsError) {
      showError(listPaymentsError);
    }
  }, [listPaymentsError, showError]);

  return (
    <>
      <Helmet>
        <title>{`Pathrade | ${tTitle("payments")}`}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("payments")}`}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              width={"120px"}
              value={query.season__in}
              onSelect={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                })
              }
            />,
            <AppSelect<CompanyType>
              key={"sender_type"}
              width={"120px"}
              name={tTitle("sender_type")}
              value={query["sender.type__eq"]}
              options={(query["receiver.type__eq"] === "AGENCY"
                ? (["BUYER"] as const)
                : (["BUYER", "AGENCY"] as const)
              ).map((option) => {
                return {
                  name: toTitle(t(toLower(option))),
                  value: option,
                };
              })}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "sender.type__eq": value,
                    "sender.id__eq":
                      prev["sender.type__eq"] === value
                        ? prev["sender.id__eq"]
                        : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"sender"}
              name={toTitle(t("sender"))}
              types={
                query["sender.type__eq"]
                  ? [query["sender.type__eq"]]
                  : ["AGENCY", "BUYER"]
              }
              width={"120px"}
              id={query["sender.id__eq"]}
              onSelect={(value) =>
                setQuery({
                  ...query,
                  "sender.id__eq": value?.id,
                  "sender.type__eq": value?.type,
                  pageNumber: 1,
                })
              }
            />,
            <AppSelect<CompanyType>
              key={"receiver_type"}
              width={"120px"}
              name={tTitle("receiver_type")}
              value={query["receiver.type__eq"]}
              options={(query["sender.type__eq"] === "AGENCY"
                ? (["BOUTIQUE"] as const)
                : (["AGENCY", "BOUTIQUE"] as const)
              ).map((option) => {
                return {
                  name: toTitle(t(toLower(option))),
                  value: option,
                };
              })}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "receiver.type__eq": value,
                    "receiver.id__eq":
                      prev["receiver.type__eq"] === value
                        ? prev["receiver.id__eq"]
                        : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"receiver"}
              name={toTitle(t("receiver"))}
              types={
                query["receiver.type__eq"]
                  ? [query["receiver.type__eq"]]
                  : ["AGENCY", "BOUTIQUE"]
              }
              width={"120px"}
              id={query["receiver.id__eq"]}
              onSelect={(value) =>
                setQuery({
                  ...query,
                  "receiver.id__eq": value?.id,
                  "receiver.type__eq": value?.type,
                  pageNumber: 1,
                })
              }
            />,
            () => {
              if (identity?.company?.type !== "BUYER") {
                return (
                  <AppCompanySelect
                    key={"ordered_by"}
                    name={toTitle(t("issued_to"))}
                    types={["BUYER"]}
                    width={"120px"}
                    id={query["issuedToCompany.id__eq"]}
                    onSelect={(value) =>
                      setQuery((prev) => {
                        return {
                          ...prev,
                          "issuedToCompany.id__eq": value?.id,
                          pageNumber: 1,
                        };
                      })
                    }
                  />
                );
              }
            },
            <AppRemittanceStatusSelect
              key={"remittance_status"}
              width={"120px"}
              value={
                query.isRemitted__eq !== undefined
                  ? query.isRemitted__eq
                    ? "REMITTED"
                    : "REMITTANCE_REQUIRED"
                  : undefined
              }
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    isRemitted__eq:
                      value !== undefined ? value === "REMITTED" : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppDatePicker
              width={"120px"}
              key={"created_on"}
              name={tTitle("requested_on")}
              value={query.createdOn__eq}
              showTimeInput={false}
              onChange={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    createdOn__eq: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              width={"120px"}
              key={"clear"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            () => {
              if (identity?.company?.type !== "BOUTIQUE") {
                return (
                  <AppButton
                    key={"upload"}
                    variant={"primary"}
                    size={"medium"}
                    onClick={() => {
                      navigate("/payments/upload");
                    }}
                  >
                    {toTitle(t("upload_payment"))}
                  </AppButton>
                );
              }
            },
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <PaymentGrid
            resources={listPaymentsData?.payments}
            mutateResources={mutateListPayments}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listPaymentsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              })
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
