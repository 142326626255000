import { Payment } from "@/features/invoices/payment/payment.type";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo, useState } from "react";
import { PaymentRow } from "@/features/invoices/payment/payment-invoice-row.type";
import { paymentRowMapper } from "@/features/invoices/utils/payment-row-mapper";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface PaymentGridProps {
  resources?: Payment[];
  mutateResources: () => void;
}

export default function PaymentGrid({
  resources,
  mutateResources,
}: PaymentGridProps) {
  const identity = useIdentity();
  const { tTitle, tCurrencyString, tLocalDateString } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const [rows, setRows] = useState<PaymentRow[]>();
  const rowMapper = useMemo(() => {
    return paymentRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(
        identity?.company?.type !== "BUYER"
          ? resources?.map((resource) => {
              return {
                ...resource,
                isRead: false,
              };
            })
          : resources,
        prev
      );
    });
  }, [identity?.company?.type, resources, rowMapper]);

  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: mutateResources,
  });

  switch (identity?.company?.type) {
    case "AGENCY":
      return (
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "Payment",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.sender.column,
                invoiceGrid.pairs.receiver.column,
                // invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.orderedBy.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.remittedAmount.column,
                invoiceGrid.pairs.unremittedAmount.column,
                invoiceGrid.pairs.status.column,
                invoiceGrid.pairs.requestedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.sender.field,
                invoiceGrid.pairs.receiver.field,
                // invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.orderedBy.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.remittedAmount.field,
                invoiceGrid.pairs.unremittedAmount.field,
                invoiceGrid.pairs.status.field,
                invoiceGrid.pairs.requestedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              onClick: (_row) => {
                const row = _row as PaymentRow;
                navigate(`/payments/${row.id}`);
              },
            },
          ]}
        />
      );
    case "BUYER":
      return (
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "Payment",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.sender.column,
                invoiceGrid.pairs.receiver.column,
                invoiceGrid.pairs.orderedBy.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.remittedAmount.column,
                invoiceGrid.pairs.unremittedAmount.column,
                invoiceGrid.pairs.status.column,
                invoiceGrid.pairs.requestedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.sender.field,
                invoiceGrid.pairs.receiver.field,
                invoiceGrid.pairs.orderedBy.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.remittedAmount.field,
                invoiceGrid.pairs.unremittedAmount.field,
                invoiceGrid.pairs.status.field,
                invoiceGrid.pairs.requestedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              onClick: (_row) => {
                const row = _row as PaymentRow;
                navigate(`/payments/${row.id}`);
              },
            },
          ]}
        />
      );
    default:
      return null;
  }
}
