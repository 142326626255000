import { useMemo } from "react";
import * as Yup from "yup";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { Company, StoredObject } from "@/features/types";
import {
  AbstractInvoice,
  PaymentMethod,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { LinkedOrderConfirmationInvoice } from "@/features/invoices/deposit-invoices/app-order-confirmation-invoices-exclusive-selector";
import { difference, uniq } from "lodash";
import { getDetailBrandName } from "@/features/invoices/app-detail-brand-select";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useIdentity from "@/features/ui/hooks/use-identity";
import PriceUtils from "@/features/ui/utils/price-utils";

export default function useProformaInvoiceFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { withMessage, formT } = useYupHelper({
    ns: "proforma_invoices",
    prefix: "upload_proforma_invoices_form",
  });

  return useMemo(() => {
    return Yup.object<ProformaInvoiceFormFields>({
      name: Yup.string(),
      number: Yup.string()
        .label("invoice_number")
        .when({
          is: () => identity?.company?.type !== "BOUTIQUE",
          then: (schema) => schema.required(withMessage.required),
          otherwise: (schema) => schema,
        }),
      season: Yup.string().required(withMessage.required),
      lineSheetSetType: Yup.mixed<LineSheetSetType>().when(
        ["orderSheetSets", "orderConfirmationInvoices", "name"],
        (values: any, schema) => {
          if (identity?.company?.type === "BOUTIQUE") {
            return schema;
          } else {
            const [orderSheetSets, orderConfirmationInvoices] = values as [
              ProformaInvoiceFormFields["orderSheetSets"],
              ProformaInvoiceFormFields["orderConfirmationInvoices"]
            ];
            if (
              orderSheetSets.length === 0 &&
              orderConfirmationInvoices.length === 0
            ) {
              return schema.required(withMessage.required);
            }
          }

          return schema;
        }
      ),
      orderedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedBy: Yup.mixed<Company>().when({
        is: () => identity?.company?.type !== "BOUTIQUE",
        then: (schema) => schema.required(withMessage.required),
        otherwise: (schema) => schema,
      }),
      issuedThrough: Yup.mixed<Company>().when({
        is: () => identity?.company?.type !== "BOUTIQUE",

        then: (schema) => schema.required(withMessage.required),
        otherwise: (schema) => schema,
      }),
      issuedOn: Yup.date().required(withMessage.required),
      exposedToBuyer: Yup.boolean().required(withMessage.required),
      paidByAgency: Yup.boolean().required(withMessage.required),
      paymentMethod: Yup.mixed<PaymentMethod>().required(withMessage.required),
      orderConfirmationInvoices: Yup.array()
        .of(
          Yup.object<LinkedOrderConfirmationInvoice>({
            id: Yup.number().positive().required(),
            name: Yup.string().required(),
          })
        )
        .test(
          "not_both_of_order_confirmation_invoice_and_order_sheet_set",
          t(
            "upload_proforma_invoices_form.messages.error.not_both_of_order_confirmation_invoice_and_order_sheet_set",
            { ns: "proforma_invoices" }
          ),
          (orderConfirmInvoices, context) => {
            const { orderSheetSets } = context.parent;

            return !(
              (orderConfirmInvoices || []).length > 0 &&
              (orderSheetSets || []).length > 0
            );
          }
        )
        .test((value, context) => {
          //brand가 연결되지않은 oc가 존재하면 에러!
          if (value && value.length > 0) {
            const linkedOCIds = (
              value as ProformaInvoiceFormFields["orderConfirmationInvoices"]
            ).map((oc) => oc.id);

            const { proformaInvoiceDetailList, orderConfirmationInvoices } =
              context.parent as ProformaInvoiceFormFields;

            const usedOCIds = uniq(
              proformaInvoiceDetailList
                .map((pDetail) => {
                  return (
                    pDetail.orderConfirmationInvoiceDetail
                      ?.orderConfirmationInvoice.id || -1
                  );
                })
                .filter((id) => id > 0)
            );

            const diff = difference(linkedOCIds, usedOCIds);

            if (diff.length > 0) {
              debugger;
            }

            if (diff.length > 0) {
              return context.createError({
                message: t(
                  "proforma_invoices:upload_proforma_invoices_form.messages.error.unlinked_order_confirmation_invoice",
                  { id: diff[0] }
                ),
              });
            }
          }
          return true;
        }),
      orderSheetSets: Yup.array().test((value, context) => {
        if (value && value.length > 0) {
          //brand가 연결되지않은 oss존재하면 에러!
          const linkedOSSIds = (
            value as ProformaInvoiceFormFields["orderSheetSets"]
          ).map((oss) => oss.id);
          const { proformaInvoiceDetailList } =
            context.parent as ProformaInvoiceFormFields;

          const usedOSSIds = uniq(
            proformaInvoiceDetailList
              .map((pDetail) => pDetail.orderSheetSet?.id || 0)
              .filter((id) => id > 0)
          );

          const diff = difference(linkedOSSIds, usedOSSIds);

          if (diff.length > 0) {
            return context.createError({
              message: t(
                "proforma_invoices:upload_proforma_invoices_form.messages.error.unlinked_order_sheet_set",
                { id: diff[0] }
              ),
            });
          }
        }
        return true;
      }),
      file: Yup.mixed<File | Pick<StoredObject, "id" | "name">>()
        .label("invoice_file")
        .required(withMessage.required),
      proformaInvoiceDetailList: Yup.array()
        .of(
          Yup.object<
            ProformaInvoiceFormFields["proformaInvoiceDetailList"][number]
          >({
            brand: Yup.mixed<Company>()
              .label("brand")
              .required(withMessage.required),
            amount: Yup.object({
              value: Yup.number()
                .label("amount_by_brand")
                .required(withMessage.required)
                .positive(withMessage.positive),
              currency: Yup.string(),
            }),
            reamingDepositAmount: Yup.object({
              value: Yup.number(),
              currency: Yup.string(),
            }),
          })
        )
        .label("invoice_figures")
        .test((value, context) => {
          if (value && value.length > 0) {
            const proformaDetailList =
              value as ProformaInvoiceFormFields["proformaInvoiceDetailList"];

            const overUsedDetail = proformaDetailList.find((pDetail) => {
              return (
                (pDetail.usedDepositAmount || 0) >
                (pDetail.orderConfirmationInvoiceDetail
                  ?.expectedRemainingDepositAmount || 0)
              );
            });

            if (overUsedDetail) {
              let id = getDetailBrandName(overUsedDetail);
              return context.createError({
                message: tTitle(
                  "proforma_invoices:upload_proforma_invoices_form.messages.error.exceeding_used_deposit_detail",
                  {
                    id,
                  }
                ),
              });
            }
          }
          return true;
        })
        .test(
          "mismatched_used_deposit",
          t(
            "upload_proforma_invoices_form.messages.error.mismatched_used_deposit",
            { ns: "proforma_invoices" }
          ),
          (value, context) => {
            if (value && value.length > 0) {
              const proformaDetailList =
                value as ProformaInvoiceFormFields["proformaInvoiceDetailList"];
              const { usedDeposit } =
                context.parent as ProformaInvoiceFormFields;
              const totalUsedDepositAmount = PriceUtils.sum(
                proformaDetailList.map((pDetail) => {
                  return pDetail.usedDepositAmount;
                }),
                "EUR"
              );

              return totalUsedDepositAmount.value === usedDeposit?.value;
            }
            return true;
          }
        )
        .when({
          is: () => identity?.company?.type !== "BOUTIQUE",

          then: (schema) =>
            schema.required(withMessage.required).min(1, withMessage.min),
          otherwise: (schema) => schema,
        }),
    });
  }, [
    identity?.company?.type,
    t,
    tTitle,
    withMessage.min,
    withMessage.positive,
    withMessage.required,
  ]);
}
