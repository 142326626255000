import {
  createTransportationKey,
  CreateTransportationRequest,
  CreateTransportationResponse,
} from "@/features/transportations/hooks/use-create-transportation";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";

export default function useCreateTransportationInfinite() {
  const fetcher = useFetcher<
    CreateTransportationRequest,
    CreateTransportationResponse
  >();
  return useYWRInfinite(createTransportationKey, fetcher);
}
