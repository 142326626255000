import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { IssuedBy } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";

interface CreateIssuedByRequest {
  companyName: string;
  businessRegistrationNumber: string;
  representativeName: string;
  representativeEmail: string;
  representativeMobileNumber: string;
  pec: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  detailAddress: string;
  zipCode: string;
  rea: string;
  eInvoiceUniqueCode: string;
  capital: string;
}

interface CreateIssuedByResponse extends IssuedBy {}

interface CreateIssuedByPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createIssuedByTemplateUrl = `/:by/:companyId/issued_by`;

export const createIssuedByKey: YWRKey<CreateIssuedByPathParameter> = {
  url: (parameters: CreateIssuedByPathParameter) => {
    return calcUrl(createIssuedByTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateIssuedBy() {
  const fetcher = useFetcher<CreateIssuedByRequest, CreateIssuedByResponse>();
  return useYWR(createIssuedByKey, fetcher);
}
