import {
  IdentityParameter,
  OrderByItem,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import {
  OrderSheetSet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";

export interface ListOrderSheetSetsRequest extends PageNumberBasedListRequest {
  id__in?: number[];
  status__in?: OrderSheetStatus[];
  status__eq?: OrderSheetStatus;
  "lineSheetSet.id__in"?: number[];
  "lineSheetSet.issuedByCompany.id__in"?: number[];
  "lineSheetSet.issuedByCompany.id__eq"?: number;
  "lineSheetSet.season__in"?: string[];

  "lineSheetSet.createdByCompany.id__in"?: number[];
  "lineSheetSet.createdByCompany.id__eq"?: number;

  "lineSheetSet.season__eq"?: string;
  "lineSheetSet.type__eq"?: LineSheetSetType;
  "createdByCompany.id__eq"?: number;
  "createdByCompany.id__in"?: number[];

  "orderSheet.toBeSubmitted.id__eq"?: number;
  "orderSheet.toBeSubmitted.id__in"?: number[];

  submittedAt__eq?: Date;
  orderBy?: OrderByItem[];
}

export interface ListOrderSheetSetsResponse
  extends PageNumberBasedListResponse {
  orderSheetSets: OrderSheetSet[];
}

export interface ListOrderSheetSetsPathParameter extends IdentityParameter {}

export function getListOrderSheetSetsParameter(
  identity: Identity | null
): ListOrderSheetSetsPathParameter | null {
  return toIdentityParameter(identity);
}

export const listOrderSheetSetsTemplateUrl = (() => {
  let url = "";
  url += "/:by/:companyId/order-sheet-sets";
  return url;
})();

export function getListOrderSheetSetsKey(
  identityOrParameter: Identity | ListOrderSheetSetsPathParameter | null,
  request: ListOrderSheetSetsRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListOrderSheetSetsParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }

    return {
      url: calcUrl(listOrderSheetSetsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListOrderSheetSets(
  parameter: ListOrderSheetSetsPathParameter | null,
  request: ListOrderSheetSetsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListOrderSheetSetsRequest,
    ListOrderSheetSetsResponse
  >();
  return useSWR(getListOrderSheetSetsKey(parameter, request), fetcher, config);
}
