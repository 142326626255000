import {
  isLineSheetRow,
  LineSheetableRow,
} from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import {
  LineSheet,
  ReviewStatus,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import { uniq } from "lodash";
import { IsolatedLineSheetRow } from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import AppReviewStatusSelect from "@/features/ui/app-review-status-select";

interface LineSheetReviewStatusGridHeaderCellProps {
  rows?: LineSheetableRow[];
  isEditing?: boolean;
  onChange?: (status: LineSheet["reviewStatus"]) => void;
}

export default function LineSheetReviewStatusGridHeaderCell({
  rows,
  isEditing = false,
  onChange,
}: LineSheetReviewStatusGridHeaderCellProps) {
  const { tTitle } = useI18nHelper();
  const values = useMemo(() => {
    if (
      isEditing &&
      rows &&
      rows
        .filter((row) => isLineSheetRow(row) && row.type === "LINE_SHEET")
        .some((row) => row.isChecked)
    ) {
      return uniq(
        rows
          .filter(
            (row): row is IsolatedLineSheetRow =>
              isLineSheetRow(row) && row.type === "LINE_SHEET"
          )
          .filter((row) => row.isChecked)
          .map((row) => row.reviewStatus)
          .filter((type): type is ReviewStatus | null => type !== undefined)
      );
    }
  }, [isEditing, rows]);

  if (values) {
    return (
      <AppReviewStatusSelect
        value={values.length === 1 ? values[0] : null}
        onSelect={(reviewStatus) => {
          if (onChange) {
            onChange(reviewStatus);
          }
        }}
      />
    );
  } else {
    return <>{tTitle("common:review_status")}</>;
  }
}
