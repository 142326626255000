import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { toLower } from "@/utils/case";

export interface AppPaidBySelectProps {
  value?: "AGENCY" | "BUYER";
  onSelect?: (value?: "AGENCY" | "BUYER") => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export default function AppPaidBySelect({
  value,
  onSelect,
  isDisabled = false,
  isReadOnly = false,
}: AppPaidBySelectProps) {
  const { tTitle } = useI18nHelper();
  const options = useMemo((): AppSelectOption<"AGENCY" | "BUYER">[] => {
    return (["AGENCY", "BUYER"] as ("AGENCY" | "BUYER")[]).map((value) => {
      return {
        name: tTitle(`${toLower(value)}`),
        value,
      };
    });
  }, [tTitle]);

  return (
    <AppSelect<"AGENCY" | "BUYER">
      isClearable={false}
      width={"100%"}
      name={tTitle("paid_by")}
      options={options}
      value={value}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    />
  );
}
