import { FormikProps } from "formik";
import { useLocation } from "react-router-dom";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useMemo } from "react";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppEditOrSaveButtonProps {
  formik: FormikProps<any>;
  isEditQueued?: boolean;
}

export default function AppEditOrSaveButton({
  formik,
  isEditQueued,
}: AppEditOrSaveButtonProps) {
  const location = useLocation();
  const { tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const isEditing = useMemo(
    () => location.pathname.includes("edit"),
    [location.pathname]
  );

  if (isEditing) {
    return (
      <AppButton
        variant="primary"
        isLoading={formik.isSubmitting}
        isDisabled={formik.isSubmitting || !formik.dirty}
        onClick={() => {
          formik.submitForm();
        }}
      >
        {tTitle("save")}
      </AppButton>
    );
  } else {
    return (
      <AppButton
        variant="normal"
        isLoading={isEditQueued}
        onClick={() => {
          navigate(`${location.pathname}/edit`, { appendReferer: true });
        }}
      >
        {tTitle("edit")}
      </AppButton>
    );
  }
}
