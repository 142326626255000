import { CompanyType } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { Payment } from "@/features/invoices/payment/payment.type";

export interface UpdatePaymentRequest
  extends Omit<
    Payment,
    | "name"
    | "isRead"
    | "createdAt"
    | "isRemitted"
    | "remittedAmount"
    | "unremittedAmount"
    | "bankRemittanceReceipts"
  > {}

export interface UpdatePaymentResponse extends Payment {}

export interface UpdatePaymentParameter {
  by: CompanyType;
  companyId: number;
  paymentId: number;
}

export const updatePaymentTemplateUrl = `/:by/:companyId/payments/:paymentId`;

export const updatePaymentKey = {
  url: (parameter: UpdatePaymentParameter) => {
    return calcUrl(updatePaymentTemplateUrl, parameter);
  },
  method: "put" as const,
};

export default function useUpdatePayment() {
  const fetcher = useFetcher<UpdatePaymentRequest, UpdatePaymentResponse>();
  return useYWR(updatePaymentKey, fetcher);
}
