import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { Company, Price, StoredObject } from "@/features/types";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { Formik } from "formik";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import { Grid, Heading } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback, useEffect, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import ProformaInvoiceForm from "@/features/invoices/proforma-invoices/proforma-invoice-form";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import {
  AbstractInvoice,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useProformaInvoiceDumbTransaction } from "@/features/invoices/proforma-invoices/hooks/use-proforma-invoice-dumb-transaction";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useProformaInvoiceFormValidationSchema from "@/features/invoices/proforma-invoices/hooks/use-proforma-invoice-form-validation-schema";
import useGetMySelf from "@/features/auth/use-get-my-self";
import { FormikUtils } from "@/features/ui/utils/formik-utils";
import { PriceInputValue } from "@/features/invoices/app-price-input";
import PriceUtils from "@/features/ui/utils/price-utils";

export interface ProformaInvoiceFormFields {
  id?: number;
  number?: string;
  name?: string;
  season?: string;
  status?: AbstractInvoice["status"];
  lineSheetSetType?: LineSheetSetType;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedBy?: Company;
  issuedThrough?: Pick<Company, "id" | "name">;
  originallyIssuedBy?: Company;
  issuedOn: Date;
  exposedToBuyer: boolean;
  paidByAgency: boolean;
  publicComment?: string;
  privateComment?: string;
  // orderSheetSets: LinkedOrderSheetSet[];
  orderSheetSets: DenseOrderSheetSet[];
  orderConfirmationInvoices: OrderConfirmationInvoice[];
  expectedRemainingDepositAmount?: PriceInputValue;
  paymentMethod?: ProformaInvoice["paymentMethod"];

  isRemittedToBoutique?: boolean;
  isRemittedToAgency?: boolean;
  usedDeposit?: PriceInputValue;
  usedDepositComment?: string;
  usedCreditInvoices: Pick<CreditInvoice, "id" | "season" | "amount">[];
  usedCreditComment?: string;

  isDetailsEditedManually: boolean;
  proformaInvoiceDetailList: (Omit<
    ProformaInvoice["proformaInvoiceDetailList"][number],
    | "orderSheetSet"
    | "orderConfirmationInvoiceDetail"
    | "brand"
    | "amount"
    | "usedDepositAmount"
  > & {
    orderSheetSet?: {
      id: number;
      lineSheetSet: {
        id: number;
        name: string;
      };
    };

    brand?: {
      id: number;
      name: string;
    };

    amount?: PriceInputValue;
    usedDepositAmount?: PriceInputValue;
    usedCreditAmount?: PriceInputValue;
    requiredAmount?: PriceInputValue;

    //errorMessage 생성시에 name 연산하기 위해 oc 정보 추가함
    orderConfirmationInvoiceDetail?: ProformaInvoice["proformaInvoiceDetailList"][number]["orderConfirmationInvoiceDetail"] & {
      orderConfirmationInvoice: {
        id: number;
        name: string;
      };
    };
  })[];
  file?: File | Pick<StoredObject, "id" | "name">;
  childProformaInvoices: {
    id: number;
    orderConfirmationInvoices: { id: number }[];
    orderSheetSets: { id: number }[];
  }[];
  parentProformaInvoices: { id: number }[];
  earnedCreditInvoices: { id: number }[];
  payments: {
    id: number;
    status: AbstractInvoice["status"];
    sender: Pick<Company, "id" | "name">;
  }[];
  attachments: (File | Pick<StoredObject, "id" | "name">)[];
}

function findOCByOcDetailId<
  T extends {
    id: number;
    orderConfirmationInvoiceDetailList: { id: number }[];
  }
>(items: T[], ocDetailId: number) {
  return items.find((item) => {
    return item.orderConfirmationInvoiceDetailList.some(
      (ocDetail) => ocDetail.id === ocDetailId
    );
  });
}

export default function UploadProformaInvoicePage() {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();

  const validateSchema = useProformaInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: ProformaInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { addCreate } = useProformaInvoiceDumbTransaction();

  const handleSubmit = useCallback(
    (values: ProformaInvoiceFormFields) => {
      if (identity?.company) {
        const totalDeposit = values.proformaInvoiceDetailList
          .map((pDetail) => {
            return {
              amount: pDetail.amount,
              usedDepositAmount: pDetail.usedDepositAmount,
              requiredAmount: PriceUtils.minus(
                [pDetail.amount, pDetail.usedDepositAmount],
                "EUR"
              ),
            };
          })
          .reduce(
            (acc, cur) => {
              return {
                amount: PriceUtils.sum([acc.amount, cur.amount], "EUR"),
                usedDepositAmount: PriceUtils.sum(
                  [acc.usedDepositAmount, cur.usedDepositAmount],
                  "EUR"
                ),
                requiredAmount: PriceUtils.sum(
                  [acc.requiredAmount, cur.requiredAmount],
                  "EUR"
                ),
              };
            },
            {
              amount: PriceUtils.placeholder(
                {
                  currency: "EUR",
                  value: 0,
                },
                "EUR"
              ),
              usedDepositAmount: PriceUtils.placeholder(
                {
                  currency: "EUR",
                  value: 0,
                },
                "EUR"
              ),
              requiredAmount: PriceUtils.placeholder(
                {
                  currency: "EUR",
                  value: 0,
                },
                "EUR"
              ),
            }
          );

        const usedCredit = PriceUtils.sum(
          values.usedCreditInvoices.map(
            (creditInvoice) => creditInvoice.amount
          ),
          "EUR"
        );

        const usedOrderSheetSetIds = values.proformaInvoiceDetailList
          .map((pDetail) => pDetail.orderSheetSet?.id)
          .filter((id): id is number => id !== undefined);

        const usedOrderConfirmationInvoiceIds = values.proformaInvoiceDetailList
          .map((pDetail) => pDetail.orderConfirmationInvoiceDetail?.id)
          .filter(
            (ocDetailId): ocDetailId is number => ocDetailId !== undefined
          )
          .map(
            (ocDetailId) =>
              findOCByOcDetailId(values.orderConfirmationInvoices, ocDetailId)
                ?.id || -1
          );

        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            number: values.number!,
            lineSheetSetType: values.lineSheetSetType!,
            season: values.season!,
            issuedByCompany: values.issuedBy!,
            issuedToCompany: values.orderedBy!,
            originallyIssuedByCompany: values.originallyIssuedBy!,
            issuedThroughCompany: values.issuedThrough || null,
            issuedOn: values.issuedOn,
            paidBy: values.paidByAgency ? "AGENCY" : "BUYER",
            totalAmount: totalDeposit.amount! as Price,
            totalRequiredAmount: PriceUtils.minus(
              [totalDeposit.requiredAmount, usedCredit],
              "EUR"
            ) as Price,
            paymentMethod: values.paymentMethod!,
            isPublic: values.exposedToBuyer,
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            usedDeposit: PriceUtils.placeholder(
              totalDeposit.usedDepositAmount,
              "EUR"
            ),
            usedDepositComment: values.usedDepositComment || null,
            usedCredit: PriceUtils.placeholder(usedCredit, "EUR"),
            usedCreditComment: values.usedCreditComment || null,
            usedCreditInvoices: values.usedCreditInvoices.map(
              (creditInvoice) => {
                return {
                  id: creditInvoice.id,
                };
              }
            ),
            orderSheetSets: values.orderSheetSets
              .filter((orderSheetSet) =>
                usedOrderSheetSetIds.includes(orderSheetSet.id)
              )
              .map((orderSheetSet) => {
                return {
                  id: orderSheetSet.id,
                };
              }),
            orderConfirmationInvoices: values.orderConfirmationInvoices
              .filter((ocInvoice) =>
                usedOrderConfirmationInvoiceIds.includes(ocInvoice.id)
              )
              .map((ocInvoice) => {
                return {
                  id: ocInvoice.id,
                };
              }),
            proformaInvoiceDetailList: values.proformaInvoiceDetailList.map(
              (pDetail) => {
                return {
                  amount: PriceUtils.placeholder(
                    pDetail.amount,
                    "EUR"
                  ) as Price,
                  usedDepositAmount: PriceUtils.placeholder(
                    pDetail.usedDepositAmount,
                    "EUR"
                  ) as Price,
                  brand: {
                    id: pDetail.brand!.id,
                    name: pDetail.brand!.name,
                  },
                  orderSheetSet: pDetail.orderSheetSet
                    ? { id: pDetail.orderSheetSet.id }
                    : null,
                  orderConfirmationInvoiceDetail:
                    pDetail.orderConfirmationInvoiceDetail
                      ? {
                          id: pDetail.orderConfirmationInvoiceDetail.id,
                        }
                      : null,
                };
              }
            ),
            file: values.file!,
            isDetailsEditedManually: values.isDetailsEditedManually,
            childProformaInvoices: values.childProformaInvoices.map(
              (pInvoice) => {
                return {
                  id: pInvoice.id,
                };
              }
            ),
            attachments: values.attachments,
          }
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    [addCreate, identity]
  );

  const { data: mySelf, error: getMySelfError } = useGetMySelf();

  useEffect(() => {
    if (getMySelfError) {
      showError(getMySelfError);
    }
  }, [getMySelfError, showError]);

  const initialValues = useMemo((): ProformaInvoiceFormFields => {
    return {
      id: -1,
      season: DEFAULT_SEASON,
      status: "NORMAL",
      issuedOn: (() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
      })(),
      exposedToBuyer: true,
      paidByAgency: false,
      orderSheetSets: [],
      orderConfirmationInvoices: [],
      usedCreditInvoices: [],
      isDetailsEditedManually: false,
      issuedBy:
        identity?.company?.type === "BOUTIQUE"
          ? {
              id: identity.company.id,
              name: "* ignore",
              type: identity?.company?.type,
            }
          : undefined,
      originallyIssuedBy:
        identity?.company?.type === "BOUTIQUE"
          ? {
              id: identity.company.id,
              name: "* ignore",
              type: identity?.company?.type,
            }
          : undefined,
      proformaInvoiceDetailList: [
        // todo너도 왜 -1을 하는거야?
        // {
        //   id: -1,
        // },
      ],
      paymentMethod: "NORMAL",
      childProformaInvoices: [],
      parentProformaInvoices: [],
      earnedCreditInvoices: [],
      payments: [],
      ...FormikUtils.issuedToOrIssuedThrough(mySelf),
      attachments: [],
    };
  }, [identity, mySelf]);

  return (
    <Formik<ProformaInvoiceFormFields>
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"UploadOrderConfirmationInvoice"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("proforma_invoices:upload_proforma_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppButton
                  key={`UploadAndAddAnother`}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      props.resetForm();
                    }
                  }}
                >
                  {tTitle("upload_and_add_another.do")}
                </AppButton>,
                <AppButton
                  key={`Upload`}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      navigate("/proforma-invoices");
                    }
                  }}
                >
                  {tTitle("upload")}
                </AppButton>,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/proforma-invoices"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <ProformaInvoiceForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
