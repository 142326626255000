import { Flex } from "@chakra-ui/react";
import InvoiceStatisticCard from "@/features/dashboard/invoice-statistic-card";
import OrderStatisticsCard from "@/features/dashboard/order-statistics-card";
import BalanceTransactionCard from "@/features/dashboard/balance-transaction-card";
import useDashboardPagePersistedQuery, {
  DashboardPagePersistedQuery,
} from "@/features/dashboard/hooks/use-dashboard-page-persisted-query";
import { useEffect } from "react";

export default function BuyerDashboardPage() {
  const { value, set: setQuery } = useDashboardPagePersistedQuery();

  useEffect(() => {
    const isEmpty = Object.keys(value).every(
      (key) => value[key as keyof DashboardPagePersistedQuery] === undefined
    );

    if (isEmpty) {
      setQuery(
        (prev) => {
          return {
            ...prev,
            orderStatisticSeason__in: ["25SS"],
            invoiceStatisticSeason__in: ["24SS", "24FW"],
            balanceTransactionStatisticSeason__in: ["24SS", "24FW"],
          };
        },
        {
          replace: true,
        }
      );
    }
  }, [value, setQuery]);

  return (
    <Flex
      padding={"16px"}
      width={"100%"}
      flexDirection={"column"}
      gap={"16px"}
      fontSize={"12px"}
      height={"100%"}
      overflow={"auto"}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <OrderStatisticsCard />
      <InvoiceStatisticCard />
      <BalanceTransactionCard />
    </Flex>
  );
}
