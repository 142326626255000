import { Boutique, Buyer, StoredObject } from "@/features/types";
import { DateTimeString } from "@/features/ui/utils/date-utils";

export type TransportationStatus =
  | "WAYBILL_ISSUED"
  | "FLIGHT_DEPARTED"
  | "FLIGHT_ARRIVED"
  | "CUSTOMS_DECLARED"
  | "CUSTOMS_WAREHOUSING"
  | "CUSTOMS_CLEARED"
  | "CUSTOMS_EXPORT_DECLARED"
  // | "DOMESTIC_RECEIVED"
  | "DOMESTIC_DISPATCHING"
  // | "DOMESTIC_DISPATCHED"
  | "DOMESTIC_SHIPPING"
  | "DOMESTIC_SHIPPING_COMPLETED"
  | "SETTLEMENT_REQUESTED"
  | "SETTLEMENT_COMPLETED";

export const TransportationStatuses: TransportationStatus[] = [
  "WAYBILL_ISSUED",
  "FLIGHT_DEPARTED",
  "FLIGHT_ARRIVED",
  "CUSTOMS_DECLARED",
  "CUSTOMS_WAREHOUSING",
  "CUSTOMS_CLEARED",
  "CUSTOMS_EXPORT_DECLARED",
  // "DOMESTIC_RECEIVED",
  "DOMESTIC_DISPATCHING",
  // "DOMESTIC_DISPATCHED",
  "DOMESTIC_SHIPPING",
  "DOMESTIC_SHIPPING_COMPLETED",
  "SETTLEMENT_REQUESTED",
  "SETTLEMENT_COMPLETED",
];

export interface Transportation {
  id: number;
  name: string;
  status: TransportationStatus;
  shippedFromList: Boutique[];
  shippedThrough: Boutique | null;
  shippedTo: Buyer;
  shippedToAddress: string;
  forwarderInvoiceNumbers: string[];

  masterBlNumber: string;
  houseBlNumbers: string[];

  flightNumber: string;
  flightDepartureAirport: string;
  flightArrivalAirport: string;
  flightEtd: DateTimeString;
  flightEta: DateTimeString;
  flightAtd: DateTimeString;
  flightAta: DateTimeString;
  finalEta: Date | null;

  cost: number;
  details: TransportationDetail[];
  files: StoredObject[];

  proformaInvoices: { id: number; name: string }[];

  createdAt: Date;
  updatedAt: Date;
}

export interface TransportationDetail {
  status: TransportationStatus;
  createdAt: Date | null;
}
