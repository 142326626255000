import useListDenseOrderSheetSetsFetcher from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-sets-fetcher";
import { useMemo } from "react";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import { uniq } from "lodash";
import {
  getListDenseOrderSheetSetsKey,
  ListDenseOrderSheetSetsPathParameter,
} from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import {
  condenseResourcesById,
  ResourceReferenceGetter,
  ResourceReferenceSetter,
} from "@/features/ui/helpers/condense-helper";

export default function useCondenseOrderSheetSets() {
  const listDenseOrderSheetSetsFetcher = useListDenseOrderSheetSetsFetcher();

  return useMemo(() => {
    return async <T>(
      items: T[],
      getter: ResourceReferenceGetter<T>,
      setter: ResourceReferenceSetter<T, DenseOrderSheetSet>,
      parameter: ListDenseOrderSheetSetsPathParameter
    ) => {
      await condenseResourcesById(
        items,
        getter,
        setter,
        async (ids: number[]) => {
          if (ids.length > 0) {
            const orderSheetSetsResponse = await listDenseOrderSheetSetsFetcher(
              getListDenseOrderSheetSetsKey(parameter, {
                pageSize: ids.length,
                pageNumber: 1,
                id__in: ids,
              })!
            );
            return orderSheetSetsResponse.orderSheetSets;
          }
          return [];
        }
      );
    };
  }, [listDenseOrderSheetSetsFetcher]);
}
