import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { toTitle } from "@/utils/case";
import AppDateRangePicker from "@/features/ui/app-date-range-picker";
import usePersistedGmailListQuery from "@/features/gmails/hooks/use-persisted-gmail-list-query";
import useListGmails, {
  ListGmailsPathParameter,
  ListGmailsRequest,
} from "@/features/gmails/hooks/use-list-gmails";
import useGmailRows from "@/features/gmails/hooks/use-inventory-rows";
import AgencyGmailGrid from "@/features/gmails/agency-gmail-grid";
import SyncGmailModal from "@/features/gmails/sync-gmail-modal";

export default function GmailListPage() {
  const { t, tTitle } = useI18nHelper();
  const {
    value: query,
    patch: patchQuery,
    set: setQuery,
    clear: clearQuery,
  } = usePersistedGmailListQuery();
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GmailListPage" });
  const { setRows } = useGmailRows();

  const {
    isOpen: isSyncGmailModalOpen,
    onClose: onSyncGmailModalClose,
    onOpen: onSyncGmailModalOpen,
  } = useDisclosure();

  const listGmailsKey = useMemo(():
    | {
        parameter: ListGmailsPathParameter;
        request: ListGmailsRequest;
      }
    | undefined => {
    if (identity?.company?.type === "AGENCY") {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
        },
      };
    }
    return undefined;
  }, [identity, query]);

  const {
    data: listGmailsData,
    error: listGmailsError,
    mutate: mutateListGmails,
  } = useListGmails(listGmailsKey?.parameter, listGmailsKey?.request, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    setRows(listGmailsData?.gmails);
  }, [listGmailsData, setRows]);

  useEffect(() => {
    return () => {
      mutateListGmails((prev) => prev, false);
    };
  }, [mutateListGmails]);

  useEffect(() => {
    if (listGmailsError) {
      showError(listGmailsError);
    }
  }, [showError, listGmailsError]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[]}
          actions={[
            <AppButton
              key={"AgencyGmailList__SyncGmail"}
              variant={"primary"}
              onClick={onSyncGmailModalOpen}
            >
              {"메일 가져오기"}
            </AppButton>,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <AgencyGmailGrid mutateRows={mutateListGmails} />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
            totalCount={listGmailsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(pageNumber) => patchQuery("pageNumber", pageNumber)}
          />
        </Flex>
      </Flex>
      {isSyncGmailModalOpen && (
        <Box>
          <SyncGmailModal
            isOpen={isSyncGmailModalOpen}
            onClose={onSyncGmailModalClose}
          />
        </Box>
      )}
    </>
  );
}
