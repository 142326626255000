import { ReactNode, useMemo, useState } from "react";
import {
  Box,
  ChakraProps,
  Flex,
  Grid,
  GridItem,
  GridProps,
  Spinner,
} from "@chakra-ui/react";
import { times } from "lodash";
import React from "react";

export interface AppTableHeader<T> {
  width: string;
  value: string | ReactNode;
  color?: ChakraProps["color"];
  textAlign?: ChakraProps["textAlign"];
  marginRight?: ChakraProps["marginRight"];
}

export interface AppTableCell<T> {
  value: string | number | ReactNode;
  textAlign?: ChakraProps["textAlign"];
}

export function createHeaderSpacer<T>(width: string): AppTableHeader<T> {
  return {
    width: width,
    value: "",
  };
}

export function createCellSpacer<T>(width: string): AppTableCell<T> {
  return {
    value: "",
  };
}

export function insertTableSpacers<T>(
  headers: AppTableHeader<T>[],
  rows: AppTableCell<T>[][],
  leftWidth?: string,
  rightWidth?: string
) {
  if (leftWidth) {
    const headerSpacer = createHeaderSpacer<T>(leftWidth);
    const cellSpacer = createCellSpacer<T>(leftWidth);

    headers.unshift(headerSpacer);
    rows.forEach((row) => {
      row.unshift(cellSpacer);
    });
  }

  if (rightWidth) {
    const headerSpacer = createHeaderSpacer<T>(rightWidth);
    const cellSpacer = createCellSpacer<T>(rightWidth);

    headers.push(headerSpacer);
    rows.forEach((row) => {
      row.push(cellSpacer);
    });
  }
}

interface AppTableProps<T> {
  headers: AppTableHeader<T>[];
  rows?: AppTableCell<T>[][];
  rowHeight: string;
  padding?: string;
  gap: GridProps["gap"];
  divider?: boolean;
  isLoading?: boolean;
  loadingRowCount?: number;
  isHeadless?: boolean;
}

function extractPaddings(padding: string = "0") {
  const paddings = padding.split(" ");
  switch (paddings.length) {
    case 1:
      return {
        paddingTop: paddings[0],
        paddingRight: paddings[0],
        paddingBottom: paddings[0],
        paddingLeft: paddings[0],
      };
    case 2:
      return {
        paddingTop: paddings[0],
        paddingRight: paddings[1],
        paddingBottom: paddings[0],
        paddingLeft: paddings[1],
      };
    case 3:
      return {
        paddingTop: paddings[0],
        paddingRight: paddings[1],
        paddingBottom: paddings[2],
        paddingLeft: paddings[1],
      };
    case 4:
      return {
        paddingTop: paddings[0],
        paddingRight: paddings[1],
        paddingBottom: paddings[2],
        paddingLeft: paddings[3],
      };
    default:
      return {
        paddingTop: "0",
        paddingRight: "0",
        paddingBottom: "0",
        paddingLeft: "0",
      };
  }
}

export default function AppTable<T>({
  headers,
  rows,
  rowHeight,
  padding,
  gap,
  isLoading = false,
  loadingRowCount = 4,
  isHeadless = false,
}: AppTableProps<T>) {
  const gridTemplateColumns = headers.map((header) => header.width).join(" ");
  const [hoverRowIndex, setHoverRowIndex] = useState<number>(-1);
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } =
    extractPaddings(padding);

  const skeleton = useMemo(() => {
    return (
      <React.Fragment>
        {times(loadingRowCount).map((index, rowIndex) => {
          return (
            <Grid
              key={"AppTableRow_" + rowIndex}
              gridTemplateColumns={"1fr"}
              gridTemplateRows={rowHeight}
              alignItems={"center"}
              gap={gap}
              borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
              opacity={"0.4"}
            >
              <GridItem></GridItem>
            </Grid>
          );
        })}
        <Spinner
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
        />
      </React.Fragment>
    );
  }, [loadingRowCount, rowHeight, gap]);

  return (
    <Flex
      flexDirection={"column"}
      position={"relative"}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {!isHeadless && (
        <Grid
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows={rowHeight}
          alignItems={"center"}
          gap={gap}
          borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
          borderColor={hoverRowIndex === 0 ? "#1272EF" : "#D4D4D1"}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
        >
          {headers.map((header, index) => {
            return (
              <GridItem
                key={"AppTableHeader_" + index}
                fontWeight={"700"}
                textAlign={header.textAlign}
                color={header.color}
                marginRight={header.marginRight}

                //borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
              >
                {header.value}
              </GridItem>
            );
          })}
        </Grid>
      )}

      {isLoading && skeleton}
      {!isLoading &&
        rows &&
        rows.map((row, rowIndex) => {
          //border: 0.5px solid var(--pathrade-primary, #1272EF);
          //background: var(--pathrade-primary-bright, #F7F9FA);
          return (
            <Grid
              key={"AppTableRow_" + rowIndex}
              gridTemplateColumns={gridTemplateColumns}
              gridTemplateRows={rowHeight}
              alignItems={"center"}
              gap={gap}
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
              _hover={{
                background: "var(--pathrade-primary-bright, #F7F9FA)",
              }}
              overflow={"hidden"}
              onMouseEnter={() => {
                setHoverRowIndex(rowIndex);
              }}
              onMouseLeave={() => {
                setHoverRowIndex(-1);
              }}
              borderColor={
                rowIndex === hoverRowIndex || rowIndex === hoverRowIndex - 1
                  ? "#1272EF"
                  : "#D4D4D1"
              }
            >
              {row.map((cell, cellIndex) => {
                return (
                  <GridItem
                    key={"AppTableCell_" + rowIndex + "_" + cellIndex}
                    textAlign={cell.textAlign}
                  >
                    {cell.value}
                  </GridItem>
                );
              })}
            </Grid>
          );
        })}
    </Flex>
  );
}
