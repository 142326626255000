import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppMyAccountCard from "@/features/my-account/app-my-account-card";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useGetMySelf from "@/features/auth/use-get-my-self";
import useUpdateUser from "@/features/my-account/hooks/use-update-user";
import { useEffect, useRef } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

interface UpdateUserFormValues {
  name: string;
}

export default function UserCard() {
  const identity = useIdentity();
  const userId = identity?.id;
  const { t, tTitle, tSentence } = useI18nHelper();
  const { withMessage, formT } = useYupHelper({
    prefix: "user_form",
    ns: "my-account",
  });
  const { isLoading: isUserLoading, data: user, error } = useGetMySelf();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const {
    isLoading: isUpdateUserLoading,
    error: updateUserError,
    fire: fireUpdateUser,
  } = useUpdateUser();

  const formikRef = useRef<FormikProps<UpdateUserFormValues> | null>(null);

  useEffect(() => {
    if (updateUserError) {
      showError(updateUserError);
    }
  }, [updateUserError, showError]);

  useEffect(() => {
    if (user && formikRef.current && formikRef.current?.dirty === false) {
      formikRef.current?.setValues({
        name: user.name,
      });
    }
  }, [user]);

  return (
    <AppMyAccountCard title={tTitle("common:profile")}>
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(withMessage.required),
        })}
        onSubmit={(values, actions) => {
          if (!isUserLoading && userId) {
            fireUpdateUser(
              {
                userId: userId,
              },
              {
                name: values.name!,
              }
            );
          }
          actions.setSubmitting(false);
        }}
        innerRef={formikRef}
      >
        {(props) => {
          return (
            <Form>
              <Field name="name">
                {({
                  field,
                  form,
                }: {
                  field: FieldInputProps<string | undefined>;
                  form: FormikProps<UpdateUserFormValues>;
                }) => (
                  <FormControl
                    isRequired
                    isInvalid={!!form.errors.name && form.touched.name}
                  >
                    <Grid
                      gridTemplateColumns={"320px 88px"}
                      gridTemplateRows={"16px 24px"}
                      rowGap={"4px"}
                      columnGap={"8px"}
                      position={"relative"}
                    >
                      <FormLabel display="flex" m={"0"}>
                        <Text
                          color="#6F6F6F"
                          fontSize={"12px"}
                          fontWeight={"400"}
                        >
                          {tTitle("name")}
                        </Text>
                      </FormLabel>
                      <Box></Box>
                      <Input
                        {...field}
                        fontSize={"12px"}
                        minHeight={"0"}
                        height={"28px"}
                      />
                      <AppButton
                        variant={"primary"}
                        size={"medium"}
                        type={"submit"}
                        isLoading={isUpdateUserLoading || props.isSubmitting}
                        isDisabled={!(props.isValid && props.dirty)}
                        onClick={(event) => {
                          //event.preventDefault();
                        }}
                      >
                        {tTitle("common:save")}
                      </AppButton>
                      <Box position={"absolute"} bottom={"-16px"}>
                        <FormErrorMessage m="0" fontSize={"12px"}>
                          {form.errors.name}
                        </FormErrorMessage>
                      </Box>
                    </Grid>
                  </FormControl>
                )}
              </Field>
            </Form>
          );
        }}
      </Formik>
    </AppMyAccountCard>
  );
}
