import { Link, useLocation } from "react-router-dom";
import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import { Item } from "@/features/ui/app-navigation";
import { AppUtils } from "@/features/ui/utils/app-utils";

export interface AppNavigationLinkProp {
  level?: number;
  item: Item;
  onClick: (item: Item) => void;
}

export default function AppNavigationLink({
  level = 1,
  item,
  onClick,
}: AppNavigationLinkProp) {
  const { calcNavigateTo } = useRouterHelper();
  const { t } = useTranslation();
  const isLeaf = (item.children || []).length === 0;

  let as = isLeaf ? Link : Link;
  //
  // if (onClick) {
  // } else {
  //   as = Link;
  // }

  const to = useMemo(() => {
    const a =
      item.children && item.children.length > 0 ? item.children[0] : item;
    return calcNavigateTo(AppUtils.calcPureTo(a.pathname, a.query));
  }, [calcNavigateTo, item]);

  return (
    <Flex
      flexDirection={"column"}
      gap={"12px"}
      paddingLeft={`${(level - 1) * 8}px`}
    >
      <Button
        display={"flex"}
        flexGrow={"1"}
        as={as}
        to={to}
        size={"sm"}
        justifyContent={"flex-start"}
        leftIcon={
          <Icon
            as={SVG}
            src={item.icon}
            boxSize={"12px"}
            _active={{ fill: "#1272EF" }}
            _hover={{ fill: "#22221F" }}
          />
        }
        iconSpacing={"8px"}
        variant={"ghost"}
        color={"#22221F"}
        _hover={{
          color: "#22221F",
          bgColor: "#EFEFED",
          borderRadius: "0",
          position: "relative",
          _after: {
            content: '""',
            position: "absolute",
            right: "0",
            height: "100%",
            width: "3px",
            backgroundColor: "#8f8f8c",
          },
        }}
        _active={
          isLeaf
            ? {
                color: "#1272EF",
                bgColor: "#E8F9FF",
                borderRadius: "0",
                position: "relative",
                _after: {
                  content: '""',
                  position: "absolute",
                  right: "0",
                  height: "100%",
                  width: "3px",
                  backgroundColor: "#1272EF",
                },
              }
            : {}
        }
        padding={"0 8px 0 12px"}
        isActive={item.isActive}
        onClick={(event) => {
          if (onClick) {
            onClick(item);
            if (!isLeaf) {
              event.preventDefault();
            }
          }
        }}
      >
        <Text fontSize={"12px"} minW={"88px"} flexGrow={"1"}>
          {toTitle(t(item.key))}
        </Text>
        {(item.children || []).length > 0 && (
          <SVG
            src={
              item.isCollapsed
                ? "/icons/icon_chevron_right.svg"
                : "/icons/icon_chevron_bottom.svg"
            }
            width={"1em"}
            height={"1em"}
            color={"#22221F"}
            //color={isActive ? "#1272EF" : "#22221F"}
          />
        )}
      </Button>
      {!item.isCollapsed &&
        item.children?.map((child) => {
          return (
            <AppNavigationLink
              // key={item.suffix + "__" + child.suffix}
              key={child.key}
              level={level + 1}
              item={child}
              onClick={onClick}
            />
          );
        })}
    </Flex>
  );
}
