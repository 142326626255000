import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import {
  calcIdentityUrlPrefix,
  calcUrl,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface GetOrderSheetSetRequest {}
export interface GetOrderSheetSetResponse extends LightOrderSheetSet {}

export interface GetOrderSheetSetPathParameter extends IdentityParameter {
  orderSheetSetId: number;
}

export const getGetOrderSheetSetTemplateUrl =
  "/:by/:companyId/order-sheet-sets/:orderSheetSetId";

export function getGetOrderSheetSetKey(
  parameter: GetOrderSheetSetPathParameter,
  request: GetOrderSheetSetRequest
): SWRObjectKey<GetOrderSheetSetRequest> {
  return {
    url: calcUrl(getGetOrderSheetSetTemplateUrl, parameter),
    params: request,
  };
}

export function parseGetOrderSheetSetKey(
  key: SWRObjectKey<GetOrderSheetSetRequest>
) {
  const urlParser = new UrlParser<GetOrderSheetSetPathParameter>(
    getGetOrderSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url),
    request: key.params,
  };
}

export default function useGetOrderSheetSet(
  paramter: GetOrderSheetSetPathParameter,
  request: GetOrderSheetSetRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    GetOrderSheetSetRequest,
    GetOrderSheetSetResponse
  >();
  return useSWR(getGetOrderSheetSetKey(paramter, request), fetcher, config);
}
