import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface GetDepositInvoiceRequest {}
export interface GetDepositInvoiceResponse extends DepositInvoice {}
export interface GetDepositInvoicePathParameter extends IdentityParameter {
  depositInvoiceId: number;
}

export const getDepositInvoiceTemplateUrl = `/:by/:companyId/deposit-invoices/:depositInvoiceId`;

export function getGetDepositInvoiceKey(
  parameter?: GetDepositInvoicePathParameter,
  request?: GetDepositInvoiceRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDepositInvoiceTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useGetDepositInvoice(
  parameter?: GetDepositInvoicePathParameter,
  request?: GetDepositInvoiceRequest,
  config?: SWRConfiguration<GetDepositInvoiceResponse>
) {
  const fetcher = useFetcher<
    GetDepositInvoiceRequest,
    GetDepositInvoiceResponse
  >();
  return useSWR(getGetDepositInvoiceKey(parameter, request), fetcher, config);
}
