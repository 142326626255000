import { Company, CompanyType } from "@/features/types";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";
import { useMemo } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import {
  CompanyId,
  getSelectedCompanyValue,
} from "@/features/line-sheet-sets/app-company-select";

interface AppExclusiveCompanySelectProps {
  types?: CompanyType[];
  name?: string;
  ids?: number[] | CompanyId[];
  onSelect?: (value: Company[]) => void;
  isLiteral?: boolean;
}

export default function AppExclusiveCompanySelect({
  types,
  name = "company",
  ids,
  onSelect,
  isLiteral = false,
}: AppExclusiveCompanySelectProps) {
  const identity = useIdentity();
  const key = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          type__in: types,
        },
      };
    }
    return undefined;
  }, [identity, types]);

  const { data } = useListAllCompanies(key?.parameter, key?.request);

  const value = useMemo(() => {
    const value = getSelectedCompanyValue(data, ids);
    if (value) {
      return Array.isArray(value) ? value : [value];
    }
    return [];
  }, [data, ids]);

  return (
    <AppExclusiveSelect<Company>
      name={name}
      values={value}
      options={data || []}
      optionFunc={(company) => {
        return {
          name: company.name,
          value: company,
        };
      }}
      onSelect={onSelect || (() => {})}
      isLiteral={isLiteral}
    />
  );
}
