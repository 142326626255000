import { Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import SVG from "react-inlinesvg";

export interface SheetableIndicatorProps {
  isLoading?: boolean;
  src: string;
  isHovered: boolean;
  isActive?: boolean;
  isRead?: boolean;
  name?: string;
}
export default function AppFlatCardIndicator({
  isLoading = false,
  src,
  isHovered,
  isActive = false,
  isRead = false,
  name,
}: SheetableIndicatorProps) {
  if (isLoading) {
    return <Spinner size={"sx"} />;
  } else {
    let color;
    if (isActive) {
      color = "#1272EF";
    } else if (isHovered) {
      color = "#444440";
    } else if (isRead) {
      color = "";
    } else {
      color = "#8F8F8C";
    }

    return (
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        height={"100%"}
        gap={"8px"}
        paddingLeft={"4px"}
      >
        <Icon as={SVG} src={src} color={color} boxSize={"1.5em"} />
        {/*{isHovered && <Text color={color}>{name}</Text>}*/}
      </Flex>
    );
  }
}
