import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface ReparseLineSheetRequest {}

export interface ReparseLineSheetResponse extends LightLineSheet {}

export interface ReparseLineSheetParameter {
  agencyId: number;
  lineSheetSetId: number;
  lineSheetId: number;
}

export const reparseLineSheetTemplateUrl =
  "/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/line-sheets/:lineSheetId/reparse";

export const reparseLineSheetKey: YWRKey<ReparseLineSheetParameter> = {
  url: (parameter) => {
    return calcUrl(reparseLineSheetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReparseLineSheetKey(url: string) {
  const urlParser = new UrlParser<ReparseLineSheetParameter>(
    reparseLineSheetTemplateUrl,
    {
      agencyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      lineSheetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReparseLineSheet() {
  const fetcher = useFetcher<
    ReparseLineSheetRequest,
    ReparseLineSheetResponse
  >();

  return useYWR(reparseLineSheetKey, fetcher);
}
