import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, ChakraProps, Flex } from "@chakra-ui/react";
import BoxHandler from "@/features/ui/box-handler";
import { Direction } from "@/features/ui/resizable-box";
import { current } from "immer";
import { throttle } from "lodash";
import { ElementScrollRestoration } from "@epic-web/restore-scroll";

interface BinarySplitViewProps {
  left: React.ReactNode;
  right: React.ReactNode;
  minLeftWidth?: number;
  minLeftHeight?: number;
  minRightWidth?: number;
  gap?: number;
}

export default function BinarySplitView({
  left,
  right,
  minLeftWidth = 8,
  minLeftHeight,
  minRightWidth = 8,
  gap = 16,
}: BinarySplitViewProps) {
  const leftRef = React.useRef<HTMLDivElement>(null);
  const rightRef = React.useRef<HTMLDivElement>(null);
  const [leftWidth, setLeftWidth] = useState<number>();

  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [direction, setDirection] = useState<Direction>();
  const [target, setTarget] = useState<"LEFT" | "RIGHT">();
  const padding = 0;
  useLayoutEffect(() => {
    if (leftRef.current && rightRef.current) {
      setLeftWidth(leftRef.current.offsetWidth);
      //setWrapperWidth(leftRef.current.parentElement!.offsetWidth);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (leftRef.current && rightRef.current) {
        setLeftWidth(leftRef.current.offsetWidth);
        //setWrapperWidth(leftRef.current.parentElement!.offsetWidth);
      }
      //setWrapperWidth(leftRef.current?.parentElement!.offsetWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseDown = useCallback(
    (direction: Direction, target: "LEFT" | "RIGHT") => {
      return (event: React.MouseEvent) => {
        setDirection(direction);
        setTarget(target);
        setStartX(event.clientX);
        setStartY(event.clientY);
        event.stopPropagation();
      };
    },
    []
  );

  const handleMouseUp = useCallback(() => {
    setDirection(undefined);
    setTarget(undefined);
  }, []);

  const throttledHandleMouseMove = useMemo(() => {
    return throttle((event: MouseEvent) => {
      if (target && leftWidth && leftRef.current) {
        const wrapperWidth =
          leftRef.current.parentElement!.offsetWidth - padding * 2 - gap;
        const nextLeftWidth = leftWidth + (event.clientX - startX);
        const nextRightWidth = wrapperWidth - nextLeftWidth;

        if (nextLeftWidth < minLeftWidth || nextRightWidth < minRightWidth) {
          setTarget(undefined);
          setDirection(undefined);
          return;
        } else {
          setLeftWidth(nextLeftWidth);
        }

        setStartX(event.clientX);
        setStartY(event.clientY);
      }
    }, 16);
  }, [gap, leftWidth, minLeftWidth, minRightWidth, startX, target]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      throttledHandleMouseMove(event);
    },
    [throttledHandleMouseMove]
  );

  // const handleMouseMove = useCallback(
  //   (event: MouseEvent) => {
  //     if (target && leftWidth && leftRef.current) {
  //       const wrapperWidth =
  //         leftRef.current.parentElement!.offsetWidth - padding * 2 - gap;
  //       const nextLeftWidth = leftWidth + (event.clientX - startX);
  //       const nextRightWidth = wrapperWidth - nextLeftWidth;
  //
  //       if (nextLeftWidth < minLeftWidth || nextRightWidth < minRightWidth) {
  //         setTarget(undefined);
  //         setDirection(undefined);
  //         return;
  //       } else {
  //         setLeftWidth(nextLeftWidth);
  //       }
  //
  //       setStartX(event.clientX);
  //       setStartY(event.clientY);
  //     }
  //   },
  //   [target, leftWidth, gap, startX, minLeftWidth, minRightWidth]
  // );

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <Flex
      flexDirection={"row"}
      gap={`${gap}px`}
      // padding={`${padding}px`}
      height={"100%"}
      maxWidth={"100%"}
    >
      <Box
        id={"left"}
        width={leftWidth ? `${leftWidth}px` : `${minLeftWidth}px`}
        minWidth={minLeftWidth ? `${minLeftWidth}px` : undefined}
        // height={"400px"}
        // background={"red.50"}
        borderColor={"transparent"}
        // border={"1px solid red"}
        position={"relative"}
        ref={leftRef}
        height={"100%"}
        overflowY={"auto"}
        style={{ scrollbarGutter: "stable" }}
      >
        <Box position={"relative"}>
          {left}
          {(["RIGHT"] as Direction[]).map((direction) => {
            return (
              <BoxHandler
                key={direction}
                direction={direction}
                onMouseDown={handleMouseDown(direction, "LEFT")}
              />
            );
          })}
        </Box>
      </Box>

      <Box
        // height={rightSize ? `${rightSize.height}px` : "auto"}
        minWidth={minRightWidth ? `${minRightWidth}px` : undefined}
        // height={"400px"}
        flexGrow={"1"}
        // background={"red.200"}
        // border={"1px solid red"}
        position={"relative"}
        ref={rightRef}
        height={"100%"}
        overflow={"auto"}
        style={{ scrollbarGutter: "stable" }}
        id={"right"}
      >
        <Box position={"relative"}>
          {right}
          {(["LEFT"] as Direction[]).map((direction) => {
            return (
              <BoxHandler
                key={direction}
                direction={direction}
                onMouseDown={handleMouseDown(direction, "RIGHT")}
              />
            );
          })}
        </Box>
      </Box>
      <ElementScrollRestoration elementQuery={"#left"} />
      <ElementScrollRestoration elementQuery={"#right"} />
    </Flex>
  );
}
