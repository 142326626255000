import { useFormikContext } from "formik";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { isDirty } from "@/utils/form";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback } from "react";
import { ModalProps } from "@chakra-ui/modal";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";

interface AppFormModalProps<T> extends ModalProps {
  children: React.ReactNode;
  title: string;
  submitName: string;
  dirtyCheckExcludeFields?: (keyof T)[];
  onConfirmSubmit?: () =>
    | { title: string; message: string | JSX.Element; confirmName: string }
    | undefined;
}

export default function AppFormModal<T extends {}>({
  isOpen,
  onClose,
  title,
  submitName,
  children,
  onConfirmSubmit,
  ...modalProps
}: AppFormModalProps<T>) {
  const { values, initialValues, submitForm, isSubmitting } =
    useFormikContext<T>();
  const { tTitle } = useI18nHelper();
  const { openDialog } = useAppDialog();

  const confirm = useCallback(async (): Promise<boolean> => {
    if (onConfirmSubmit) {
      const confirmProps = onConfirmSubmit();
      if (confirmProps) {
        return new Promise((resolve, reject) => {
          openDialog({
            ...confirmProps,
            size: "xl",
            onConfirm: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
          });
        });
      }
    }

    return true;
  }, [onConfirmSubmit, openDialog]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onEsc={() => {
        if (isSubmitting) {
          return;
        }
        onClose();
      }}
      onOverlayClick={() => {
        if (isDirty(values, initialValues)) {
          return;
        }
        onClose();
      }}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {tTitle(title)}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            onClick={() => {
              onClose();
            }}
          >
            {tTitle("not_now")}
          </AppButton>
          <AppButton
            variant={"primary"}
            isLoading={isSubmitting}
            onClick={async () => {
              if (await confirm()) {
                submitForm();
              }
            }}
          >
            {tTitle(submitName)}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
