import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { BankInfo } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";

interface CreateBankInfoRequest {
  alias: string;
  bankName: string;
  iban: string;
  swiftCode: string;
  branchName: string;
  branchAddress: string;
}

interface CreateBankInfoResponse extends BankInfo {}

interface CreateBankInfoPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createBankInfoTemplateUrl = `/:by/:companyId/bank_info`;

export const createBankInfoKey: YWRKey<CreateBankInfoPathParameter> = {
  url: (parameters: CreateBankInfoPathParameter) => {
    return calcUrl(createBankInfoTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateBankInfo() {
  const fetcher = useFetcher<CreateBankInfoRequest, CreateBankInfoResponse>();
  return useYWR(createBankInfoKey, fetcher);
}
