import { Box, ChakraProps, Flex } from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";

interface AppTagProps {
  value: { id: any; name: string };
  onDelete: () => void;
  isDisabled?: boolean;
}

function calcVariantStyle({
  isDisabled,
}: Pick<AppTagProps, "isDisabled">): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: "4px",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#8F8F8C",
    backgroundColor: "#FFF",
    color: "#444440",
  };

  if (isDisabled) {
    return {
      ...defaultStyle,
      borderColor: "#8F8F8C",
      backgroundColor: "#EFEFED",
      color: "#6F6F6C",
    };
  }

  return defaultStyle;
}

export default function AppTag({ value, onDelete, isDisabled }: AppTagProps) {
  const style = {
    ...calcVariantStyle({ isDisabled }),
  };

  return (
    <Flex
      {...style}
      flexDirection={"row"}
      alignItems={"center"}
      gap={"4px"}
      fontSize={"12px"}
      padding={"0 4px"}
    >
      <Box
        maxWidth={"120px"}
        textOverflow={"ellipsis"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        borderRadius={"2px"}
        title={value.name}
      >
        {value.name}
      </Box>
      {!isDisabled && (
        <AppIconButton
          ariaLabel={"delete tag"}
          icon={"x"}
          size={"extraSmall"}
          onClick={onDelete}
        />
      )}
    </Flex>
  );
}
