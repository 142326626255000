import { Inventory, InventoryRow } from "@/features/inventories/inventory.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import useAppGridMutate from "@/features/ui/hooks/use-app-grid-mutate";

export const buyerInventoryRowsMapper = new GridMapper<Inventory, InventoryRow>(
  "Inventory",
  (inventory) => {
    return {
      name: inventory.name,
      compressedFile: inventory.compressedFile,
      createdAt: inventory.createdAt,
      updatedAt: inventory.updatedAt,
      createdByCompany: inventory.createdByCompany,
      comment: inventory.comment,
      organizedOn: inventory.organizedOn,
      organizedBy: inventory.organizedBy,
      files: inventory.files,
      createdBy: inventory.createdBy,
      updatedBy: inventory.updatedBy,
    };
  },
  (row) => {
    return {
      name: row.name,
      compressedFile: row.compressedFile,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
      createdByCompany: row.createdByCompany,
      comment: row.comment,
      organizedOn: row.organizedOn,
      organizedBy: row.organizedBy,
      files: row.files,
      createdBy: row.createdBy,
      updatedBy: row.updatedBy,
    };
  }
);

export const buyerInventoryRowsState = atom<InventoryRow[] | undefined>({
  key: "BuyerInventoryRows",
  default: undefined,
});

export default function useBuyerInventoryRows() {
  const rows = useRecoilValue(buyerInventoryRowsState);
  const appGridMutate = useAppGridMutate(buyerInventoryRowsState);

  const setRows = useRecoilCallback(
    ({ set }) =>
      (inventories?: Inventory[]) => {
        set(buyerInventoryRowsState, (prev) => {
          return buyerInventoryRowsMapper.toRows(inventories, prev);
        });
      },
    []
  );

  return {
    setRows,
    rows,
    ...appGridMutate,
  };
}
