import useCreateOrderSheetSeamlesslyFetcher from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly-fetcher";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  CreateFallbackOrderSheetSeamlesslyRequest,
  CreateFallbackOrderSheetSeamlesslyResponse,
  parseCreateFallbackOrderSheetSeamlesslyKey,
} from "@/features/line-sheet-sets/hooks/use-create-fallback-order-sheet-seamlessly";
import { createYWRAxiosConfig } from "@/features/ui/helpers/fetcher-helpers";
import { createOrderSheetSeamlesslyKey } from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly";
import {
  updateFallbackOrderSheetKey,
  UpdateFallbackOrderSheetRequest,
  UpdateFallbackOrderSheetResponse,
} from "@/features/line-sheet-sets/hooks/use-update-fallback-order-sheet";

export default function useCreateFallbackOrderSheetSeamlesslyFetcher() {
  const createOrderSheetSeamlesslyFetcher =
    useCreateOrderSheetSeamlesslyFetcher();

  const updateFallbackOrderSheetFetcher = useFetcher<
    UpdateFallbackOrderSheetRequest,
    UpdateFallbackOrderSheetResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<CreateFallbackOrderSheetSeamlesslyRequest>
    ): Promise<CreateFallbackOrderSheetSeamlesslyResponse> => {
      const { parameter } = parseCreateFallbackOrderSheetSeamlesslyKey(
        config.url!!
      );

      const createOrderSheetSeamlesslyResponse =
        await createOrderSheetSeamlesslyFetcher(
          createYWRAxiosConfig(
            createOrderSheetSeamlesslyKey,
            {
              by: parameter.by,
              companyId: parameter.companyId,
              orderSheetSetId: parameter.orderSheetSetId,
              lineSheetSetId: parameter.lineSheetSetId,
              lineSheetId: parameter.lineSheetId,
              index: parameter.index,
            },
            {}
          )
        );

      const orderSheetSetId =
        createOrderSheetSeamlesslyResponse._orderSheetSetId;

      const orderSheetId = createOrderSheetSeamlesslyResponse.id;

      const updateFallbackOrderSheetResponse =
        await updateFallbackOrderSheetFetcher({
          ...createYWRAxiosConfig(
            updateFallbackOrderSheetKey,
            {
              orderSheetSetId: orderSheetSetId,
              orderSheetId: orderSheetId,
              buyerId: parameter.companyId,
              _v: new Date().getTime(),
            },
            {
              uploadedFile: config.data!.file,
            }
          ),
          headers: { "Content-Type": "multipart/form-data" },
        });

      return Promise.resolve({
        ...updateFallbackOrderSheetResponse,
        _orderSheetSetId: orderSheetSetId,
        _orderSheetId: orderSheetId,
      });
    };
  }, [createOrderSheetSeamlesslyFetcher, updateFallbackOrderSheetFetcher]);
}
