import {
  OrderSheet,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";

const orderSheetStatus: OrderSheetStatus[] = [
  "INITIAL_DRAFT",
  "WORKING_DRAFT",
  "ORDERED_TO_AGENCY",
  "AGENCY_CONFIRMED",
  "ORDERED_TO_BOUTIQUE",
  "CANCELED",
  "MODIFICATION_REQUIRED",
  "MODIFICATION_COMPLETED",
  "BOUTIQUE_CONFIRMED",
  "COMPLETED",
];

export function isOrderSheetStatusAfter(
  target: OrderSheet | OrderSheetStatus,
  criteria: OrderSheetStatus,
  include: boolean = false
) {
  const targetStatus = getOrderSheetStatus(target);
  const targetValue = orderSheetStatus.indexOf(targetStatus);
  const criteriaValue = orderSheetStatus.indexOf(criteria);
  return include ? targetValue >= criteriaValue : targetValue > criteriaValue;
}

export function isOrderSheetStatusBefore(
  target: OrderSheet | OrderSheetStatus,
  criteria: OrderSheetStatus,
  include: boolean = false
) {
  const targetStatus = getOrderSheetStatus(target);
  const targetValue = orderSheetStatus.indexOf(targetStatus);
  const criteriaValue = orderSheetStatus.indexOf(criteria);
  return include ? targetValue <= criteriaValue : targetValue < criteriaValue;
}

function getOrderSheetStatus(item: OrderSheet | OrderSheetStatus) {
  if (typeof item === "string") {
    return item;
  } else {
    return item.status;
  }
}

export function isOrderSheetStatus(value: string): value is OrderSheetStatus {
  return orderSheetStatus.some((t) => t === value);
}

export function isOrderSheetDraft(item: { status: OrderSheetStatus }) {
  return ["INITIAL_DRAFT", "WORKING_DRAFT"].includes(item.status);
}

export function isOrderSheetOrderedToAgency(item: {
  status: OrderSheetStatus;
}) {
  return ["ORDERED_TO_AGENCY"].includes(item.status);
}
