import { ICellEditorParams } from "ag-grid-community";
import {
  ForwardedRef,
  KeyboardEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import styles from "./numeric-editor.module.css";
import { Box, Flex, Input, Text } from "@chakra-ui/react";

interface NumericEditorProps extends ICellEditorParams {
  unit?: string;
  del?: number;
}

export default function NumericEditor(
  props: NumericEditorProps,
  ref: ForwardedRef<any>
) {
  const KEY_BACKSPACE = "Backspace";
  const KEY_F2 = "F2";
  const KEY_ENTER = "Enter";
  const KEY_TAB = "Tab";

  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
    initialState.highlightAllOnFocus
  );
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const eInput = refInput.current;

    if (eInput) {
      eInput.focus();

      if (highlightAllOnFocus) {
        eInput.select();
        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0) {
          eInput.setSelectionRange(length, length);
        }
      }
    }
  }, [highlightAllOnFocus]);

  const cancelBeforeStart = props.charPress && !/\d/.test(props.charPress);

  const isLeftOrRight = (event: any) => {
    return ["ArrowLeft", "ArrowLeft"].indexOf(event.key) > -1;
  };

  const isCharNumeric = (charStr: string) => {
    return !!/\d/.test(charStr);
  };

  const isKeyPressedNumeric = (event: any) => {
    const charStr = event.key;
    return isCharNumeric(charStr) || charStr === ".";
  };

  const isBackspace = (event: any) => {
    return event.key === KEY_BACKSPACE;
  };

  const finishedEditingPressed = (event: any) => {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (isLeftOrRight(event) || isBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }

    if (finishedEditingPressed(event)) {
      props.stopEditing();
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return parseFloat(value);
      },
      isCancelBeforeStart() {
        return cancelBeforeStart;
      },
      isCancelAfterEnd() {
        return value > 10000000;
      },
    };
  });

  // box-sizing: border-box;
  // padding-left: var(--ag-grid-size);
  // width: 100%;
  // height: 100%;
  return (
    <Flex flexDirection={"row"} alignItems={"center"} width={"100%"}>
      <Input
        ref={refInput}
        value={value}
        textAlign={"center"}
        paddingLeft={"var(--ag-grid-size)"}
        fontSize={"12px"}
        h={"auto"}
        minH={"0"}
        minW={"0"}
        p={"0"}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        // className={styles.numericInput}
      />
      {props.unit && <Box>{props.unit}</Box>}
      {props.del !== undefined && <Text as={"del"}>{props.del}</Text>}
    </Flex>
  );
}
