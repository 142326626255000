import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { IdentityParameter, StoredObject } from "@/features/types";
import {
  OrderConfirmationInvoice,
  OrderConfirmationInvoiceDetail,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export interface UpdateOrderConfirmationInvoicePathParameter
  extends IdentityParameter {
  orderConfirmationInvoiceId: number;
}

export interface UpdateOrderConfirmationInvoiceRequest
  extends Omit<
    OrderConfirmationInvoice,
    | "file"
    | "isRemitted"
    | "isRemittedToAgency"
    | "isRemittedToBoutique"
    | "isRead"
    | "actualRemainingDepositAmount"
    | "expectedRemainingDepositAmount"
    | "bankRemittanceReceipts"
    | "proformaInvoices"
    | "creditInvoices"
    | "totalRemittedAmount"
    | "totalAgencyToBoutiqueAmount"
    | "totalBuyerToAgencyAmount"
    | "totalBuyerToBoutiqueAmount"
    | "depositInvoice"
    | "createdAt"
    | "orderConfirmationInvoiceDetailList"
    | "payments"
  > {
  file: Pick<StoredObject, "id">;
  orderConfirmationInvoiceDetailList: Omit<
    OrderConfirmationInvoiceDetail,
    | "remittedAmount"
    | "actualRemainingDepositAmount"
    | "expectedRemainingDepositAmount"
  >[];
}

export interface UpdateOrderConfirmationInvoiceResponse
  extends OrderConfirmationInvoice {}

export const updateOrderConfirmationInvoiceTemplateUrl = `/:by/:companyId/order-confirmation-invoices/:orderConfirmationInvoiceId`;

export const updateOrderConfirmationInvoiceKey: YWRKey<UpdateOrderConfirmationInvoicePathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateOrderConfirmationInvoiceTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateOrderConfirmationInvoice(
  config?: AxiosRequestConfig<UpdateOrderConfirmationInvoiceRequest>
) {
  const fetcher = useFetcher<
    UpdateOrderConfirmationInvoiceRequest,
    UpdateOrderConfirmationInvoiceResponse
  >();
  return useYWR(updateOrderConfirmationInvoiceKey, fetcher, config);
}
