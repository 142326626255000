import useFetcher from "@/utils/use-fetcher";
import {
  readOrderSheetSetKey,
  ReadOrderSheetSetRequest,
  ReadOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-read-order-sheet-set";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useReadOrderSheetSetInfinite() {
  const fetcher = useFetcher<
    ReadOrderSheetSetRequest,
    ReadOrderSheetSetResponse
  >();

  return useYWRInfinite(readOrderSheetSetKey, fetcher);
}
