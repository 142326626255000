import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";
import { Company } from "@/features/types";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  getListIssuedToListKey,
  ListIssuedToListPathParameter,
  ListIssuedToListRequest,
  ListIssuedToListResponse,
} from "@/features/invoices/triangular-trades/hooks/use-list-issued-to";

export interface ListAllIssuedToListRequest
  extends Omit<ListIssuedToListRequest, "pageSize" | "pageNumber"> {}
type ListAllIssuedToListResponse = Company[];
export interface ListAllIssuedToListPathParameter
  extends ListIssuedToListPathParameter {}

export const listAllIssuedToListTemplateUrl = `/:by/:companyId/issued_to`;

export function getListAllIssuedToListKey(
  parameter?: ListAllIssuedToListPathParameter,
  request?: ListAllIssuedToListRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllIssuedToListTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllIssuedToListKey(
  key: AxiosRequestConfig<ListAllIssuedToListRequest>
) {
  const urlParser = new UrlParser<ListAllIssuedToListPathParameter>(
    listAllIssuedToListTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllIssuedToList(
  parameter?: ListAllIssuedToListPathParameter,
  request?: ListAllIssuedToListRequest,
  config: SWRConfiguration = { revalidateOnFocus: false }
) {
  const listFetcher = useFetcher<
    ListIssuedToListRequest,
    ListIssuedToListResponse
  >();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListIssuedToListRequest>) => {
        const { parameter, request } = parseListAllIssuedToListKey(config);
        return listFetcher(getListIssuedToListKey(parameter, request)!);
      },
      (response) => {
        return response.issuedToList;
      }
    );
  }, [listFetcher]);

  return useSWR(getListAllIssuedToListKey(parameter, request), fetcher, config);
}
