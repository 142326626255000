import { Inventory } from "@/features/inventories/inventory.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { CompanyType, IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface CreateInventoryRequest {
  name: string;
  comment: string | null;
  organizedOn: Date;
  organizedBy: { id: number };
  files: { id: number }[];
}

export interface CreateInventoryResponse extends Inventory {}

export interface CreateInventoryPathParameter extends IdentityParameter {}

export const createInventoryKey: YWRKey<CreateInventoryPathParameter> = {
  url: (parameter) => {
    return calcUrl("/:by/:companyId/inventories", parameter);
  },
  method: "post",
};

export default function useCreateInventory() {
  const fetcher = useFetcher<CreateInventoryRequest, CreateInventoryResponse>();

  return useYWR(createInventoryKey, fetcher);
}
