import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useBulkCreateDepositInvoices from "@/features/invoices/deposit-invoices/hooks/use-bulk-create-deposit-invoice";
import useUpdateDepositInvoice, {
  updateDepositInvoiceKey,
  UpdateDepositInvoicePathParameter,
  UpdateDepositInvoiceRequest,
} from "@/features/invoices/deposit-invoices/hooks/use-update-deposit-invoice";
import {
  createDepositInvoiceKey,
  CreateDepositInvoicePathParameter,
  CreateDepositInvoiceRequest,
} from "@/features/invoices/credit-invoices/hooks/use-create-deposit-invoice";
import { isFile, StoredObject } from "@/features/types";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import { useCallback } from "react";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import useCreateDepositInvoiceInfinite from "@/features/invoices/credit-invoices/hooks/use-create-deposit-invoice-infinite";

function mutateDepositInvoices(depositInvoiceId?: number) {
  if (depositInvoiceId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers)\/\d+\/(dense-)?deposit-invoices\/${depositInvoiceId}/`
      )
    );
  }
  mutateIfMatched(/\/(agencies|buyers)\/\d+\/(dense-)?deposit-invoices/);
}

export default function useDepositInvoiceDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { fire: fireBulkCreateDepositInvoices } =
    useBulkCreateDepositInvoices();
  const { fire: fireUpdateDepositInvoice } = useUpdateDepositInvoice();
  const { fire: fireCreateDepositInvoice } = useCreateDepositInvoiceInfinite();

  const addCreate = useCallback(
    (
      parameter: CreateDepositInvoicePathParameter,
      request: Omit<CreateDepositInvoiceRequest, "file"> & {
        file: File;
      }
    ) => {
      addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return [request.file];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: storeObjectResponses[0],
          };
        },
        fire: fireCreateDepositInvoice,
        keyOrFunc: calcYWRUrl(createDepositInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateDepositInvoices(res.id);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreateDepositInvoice, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdateDepositInvoicePathParameter,
      request: Omit<UpdateDepositInvoiceRequest, "file"> & {
        file: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return isFile(request.file) ? [request.file] : [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: isFile(source.file) ? storeObjectResponses[0] : source.file,
          };
        },
        fire: fireUpdateDepositInvoice,
        keyOrFunc: calcYWRUrl(updateDepositInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateDepositInvoices(res.id);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireUpdateDepositInvoice, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateDepositInvoicePathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateDepositInvoiceKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate,
    addUpdate,
    isUpdateQueued,
  };
}
