import useIdentity from "@/features/ui/hooks/use-identity";
import { Helmet } from "react-helmet-async";
import { toTitle } from "@/utils/case";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { useEffect, useMemo } from "react";
import {
  DEFAULT_LIST_ORDER_CONFIRMATION_INVOICES_PAGE_SIZE,
  ListOrderConfirmationInvoicesRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-order-confirmation-invoices";
import useListDenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices";
import AppListHeader from "@/features/ui/app-list-header";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppRemittanceStatusSelect from "@/features/invoices/app-remittance-status-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import { Box, Flex } from "@chakra-ui/react";
import OrderConfirmationInvoiceGrid from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-grid";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";
import {
  GetInvoiceStatisticByTypePathParameter,
  GetInvoiceStatisticByTypeRequest,
  useGetInvoiceStatisticByType,
} from "@/features/dashboard/hooks/use-get-invoice-statistic-by-type";

export type PersistedOrderConfirmationInvoiceListPageQuery = Omit<
  ListOrderConfirmationInvoicesRequest,
  | "pageSize"
  | "depositInvoice__isNull"
  | "isPublic__eq"
  | "isRemittedOrIsDeferred__eq"
  | "unremitted_amount__gt"
  | "status__eq"
  | "status__in"
  | "season__eq"
  | "issuedByCompany.id__eq"
>;

export default function OrderConfirmationInvoiceListPage() {
  const identity = useIdentity();
  const { t, tCurrencyString, tTitle } = useI18nHelper();
  const title = `Pathrade | ${toTitle(t("order_confirmation_invoices"))}`;
  const { error: showError } = useAppToasts();
  const { navigate } = useRouterHelper();

  const [query, setQuery] =
    useTypedSearchParams<PersistedOrderConfirmationInvoiceListPageQuery>();

  const listDenseOrderConfirmationInvoicesKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageSize: DEFAULT_LIST_ORDER_CONFIRMATION_INVOICES_PAGE_SIZE,
        },
      };
    }
  }, [identity, query]);

  const {
    data: listDenseOrderConfirmationInvoicesData,
    error: listDenseOrderConfirmationInvoicesError,
    isLoading: isListDenseOrderConfirmationInvoicesLoading,
    mutate: mutateListDenseOrderConfirmationInvoices,
  } = useListDenseOrderConfirmationInvoices(
    listDenseOrderConfirmationInvoicesKey?.parameter,
    listDenseOrderConfirmationInvoicesKey?.request,
    {
      revalidateOnFocus: false,
      //revalidateOnMount: false,
    }
  );

  useEffect(() => {
    showError(listDenseOrderConfirmationInvoicesError);
  }, [showError, listDenseOrderConfirmationInvoicesError]);

  const getInvoiceStatisticKey = useMemo(():
    | {
        parameter: GetInvoiceStatisticByTypePathParameter;
        request: GetInvoiceStatisticByTypeRequest;
      }
    | undefined => {
    if (identity?.company?.type === "BUYER") {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          type: "ORDER_CONFIRMATION",
        },
        request: {
          season__in: query.season__in,
          "issuedByCompany.id__in": query["issuedByCompany.id__in"],
          issuedOn__eq: query.issuedOn__eq,
          isRemittedToAgency__eq: query["isRemittedToAgency__eq"],
          isRemittedToBoutique__eq: query["isRemittedToBoutique__eq"],
          "madeByCompany.id__eq":
            query["orderConfirmationInvoiceDetailList.brand.id__eq"],
        },
      };
    }
  }, [identity, query]);

  const { data: getInvoiceStatisticData, error: getInvoiceStatisticError } =
    useGetInvoiceStatisticByType(
      getInvoiceStatisticKey?.parameter,
      getInvoiceStatisticKey?.request,
      {
        revalidateOnFocus: false,
      }
    );

  useEffect(() => {
    if (getInvoiceStatisticError) {
      showError(getInvoiceStatisticError);
    }
  }, [showError, getInvoiceStatisticError]);

  const figures = useMemo(() => {
    const figures = [];

    if (getInvoiceStatisticData) {
      figures.push({
        name: toTitle(t("common:total_remittance_required_amount.abbr")),
        value: tCurrencyString(getInvoiceStatisticData["totalAmount"])!,
      });
      figures.push({
        name: toTitle(t("common:total_remitted_amount.abbr")),
        value: tCurrencyString(getInvoiceStatisticData["remittedAmount"])!,
      });
    }

    return figures;
  }, [getInvoiceStatisticData, tCurrencyString, t]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              value={query.season__in}
              width={"120px"}
              onSelect={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  season__in: value,
                  pageNumber: 1,
                }))
              }
            />,
            () => {
              if (identity?.company?.type !== "BUYER") {
                return (
                  <AppCompanySelect
                    key={"AgencyOrderConfirmationInvoiceListFilter_OrderedBy"}
                    name={toTitle(t("issued_to"))}
                    types={["BUYER"]}
                    width={"120px"}
                    id={query["issuedToCompany.id__eq"]}
                    onSelect={(value) =>
                      setQuery((prev) => ({
                        ...prev,
                        "issuedToCompany.id__eq": value?.id,
                        pageNumber: 1,
                      }))
                    }
                  />
                );
              }
            },
            () => {
              if (identity?.company?.type !== "BOUTIQUE") {
                return (
                  <AppCompanySelect
                    key={"AgencyOrderConfirmationInvoiceListFilter_IssuedBy"}
                    name={toTitle(t("issued_by"))}
                    types={["BOUTIQUE"]}
                    width={"120px"}
                    isMultiple={true}
                    id={query["issuedByCompany.id__in"]}
                    onSelect={(value) =>
                      setQuery((prev) => {
                        return {
                          ...prev,
                          "issuedByCompany.id__in": value?.map((i) => i.id),
                          pageNumber: 1,
                        };
                      })
                    }
                  />
                );
              }
            },
            <AppDatePicker
              key={"AgencyOrderConfirmationInvoiceListFilter_IssuedOn"}
              name={toTitle(t("issued_on"))}
              value={query.issuedOn__eq}
              showTimeInput={false}
              width={"120px"}
              onChange={(value) => setQuery({ ...query, issuedOn__eq: value })}
            />,
            <AppRemittanceStatusSelect
              key={"is_remitted_to_boutique"}
              to={"BOUTIQUE"}
              width={"120px"}
              value={(() => {
                if (query.isRemittedToBoutique__eq !== undefined) {
                  return query.isRemittedToBoutique__eq
                    ? "REMITTED"
                    : "REMITTANCE_REQUIRED";
                }
              })()}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    isRemittedToBoutique__eq:
                      value !== undefined ? value === "REMITTED" : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppRemittanceStatusSelect
              key={"is_remitted_to_agency"}
              to={"AGENCY"}
              width={"120px"}
              value={(() => {
                if (query.isRemittedToAgency__eq !== undefined) {
                  return query.isRemittedToAgency__eq
                    ? "REMITTED"
                    : "REMITTANCE_REQUIRED";
                }
              })()}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    isRemittedToAgency__eq:
                      value !== undefined ? value === "REMITTED" : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"orderConfirmationInvoiceDetailList.brand.id"}
              types={["BRAND"]}
              width={"120px"}
              name={tTitle("brand")}
              id={query["orderConfirmationInvoiceDetailList.brand.id__eq"]}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "orderConfirmationInvoiceDetailList.brand.id__eq":
                      value?.id,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"AgencyOrderConfirmationInvoiceListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              width={"120px"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <AppButton
              key={"AgencyInvoiceListAction_UploadInvoices"}
              variant={"primary"}
              size={"medium"}
              onClick={() => {
                navigate("/order-confirmation-invoices/upload");
              }}
            >
              {toTitle(
                t("upload_order_confirmation_invoices", {
                  ns: "order_confirmation_invoices",
                })
              )}
            </AppButton>,
          ]}
          figures={figures}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <OrderConfirmationInvoiceGrid
            resources={
              listDenseOrderConfirmationInvoicesData?.orderConfirmationInvoices
            }
            mutateResources={mutateListDenseOrderConfirmationInvoices}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listDenseOrderConfirmationInvoicesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) => setQuery({ ...query, pageNumber: value })}
          />
        </Flex>
      </Flex>
    </>
  );
}
