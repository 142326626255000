import { Flex, Grid, GridItem, Heading, Spacer } from "@chakra-ui/react";

interface AppDashboardCardProps {
  title: string;
  filters: JSX.Element[];
  totalStatistics: {
    name: string;
    value: string;
  }[];
  children: JSX.Element;
}
export default function AppDashboardCard({
  title,
  filters,
  totalStatistics,
  children,
}: AppDashboardCardProps) {
  return (
    <Flex
      flexDirection={"column"}
      borderRadius={"8px"}
      border={"0.5px solid var(--pathrade-primary, #D4D4D1)"}
      background={"var(--pathrade-white, #FFF)"}
      boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.10)"}
      _hover={{
        borderColor: "#1272EF",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
      }}
    >
      <Heading
        as={"h3"}
        fontSize={"18px"}
        borderBottom={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
        padding={"16px 24px"}
      >
        {title}
      </Heading>
      <Flex
        flexDirection={"row"}
        padding={"8px 24px"}
        borderBottom={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
        gap={"8px"}
        alignItems={"center"}
      >
        {filters}
        <Spacer />
        <Grid
          gridTemplateColumns={"repeat(2, 1fr)"}
          width={"720px"}
          columnGap={"16px"}
          fontSize={"14px"}
        >
          {totalStatistics.map((statistic, index) => {
            return (
              <Grid
                gridTemplateColumns={"repeat(2, 1fr)"}
                key={"DashboardTotalStatistic_" + index}
                fontSize={"12px"}
              >
                <GridItem color={"#6F6F6C"}>{statistic.name}</GridItem>
                <GridItem textAlign={"end"}>
                  {statistic.value}
                </GridItem>
              </Grid>
            );
          })}
        </Grid>
      </Flex>
      {children}
    </Flex>
  );
}
