import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppMyAccountCard from "@/features/my-account/app-my-account-card";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useEffect, useMemo, useRef } from "react";
import * as Yup from "yup";
import useChangePassword from "@/features/my-account/hooks/use-change-password";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isErrorResponse } from "@/utils/use-fetcher";

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export default function ChangePasswordCard() {
  const { t, tTitle, tSentence } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage, formT } = useYupHelper({
    prefix: "change_password_form",
    ns: "my-account",
  });
  const { isLoading, data, error, fire } = useChangePassword();

  const formikRef = useRef<FormikProps<ChangePasswordFormValues> | null>(null);

  useEffect(() => {
    if (error) {
      showError(error, {
        id: "CHANGE_PASSWORD_ERROR",
        i18n: {
          keyPrefix: "change_password.error",
        },
      });
    }
  }, [showError, error]);

  const validationSchema = useMemo(() => {
    return Yup.object({
      oldPassword: Yup.string()
        // .min(8, withMessage.min)
        // .test(
        //   "PasswordDiversity",
        //   tSentence("auth:sign_up_form.messages.error.not_diverse_password"),
        //   (value, context) => {
        //     if (value) {
        //       let typeCount = 0;
        //
        //       if (/[0-9]/.test(value)) typeCount++; // Check for digits
        //       if (/[a-zA-Z]/.test(value)) typeCount++;
        //       if (/[^A-Za-z0-9]/.test(value)) typeCount++;
        //
        //       return typeCount >= 2;
        //     }
        //     return true;
        //   }
        // )
        .required(withMessage.required),
      newPassword: Yup.string()
        .min(8, withMessage.min)
        .test(
          "PasswordDiversity",
          tSentence("auth:sign_up_form.messages.error.not_diverse_password"),
          (value, context) => {
            if (value) {
              let typeCount = 0;

              if (/[0-9]/.test(value)) typeCount++; // Check for digits
              if (/[a-zA-Z]/.test(value)) typeCount++;
              if (/[^A-Za-z0-9]/.test(value)) typeCount++;

              return typeCount >= 2;
            }
            return true;
          }
        )
        .required(withMessage.required),
      newPasswordConfirmation: Yup.string().test(
        "PasswordEquality",
        tSentence("auth:sign_up_form.messages.error.not_identical_password"),
        (value, context) => {
          const { newPassword } = context.parent;
          if (newPassword && newPassword.length >= 8) {
            return value === context.parent.newPassword;
          }
          return true;
        }
      ),
    });
  }, [tSentence, withMessage.min, withMessage.required]);

  return (
    <AppMyAccountCard title={tTitle("common:change_password")}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          if (identity) {
            fire(
              {
                userId: identity.id,
              },
              {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
              }
            );
          }
          actions.setSubmitting(false);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Flex flexDirection={"column"}>
                <Field name="oldPassword">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string>;
                    form: FormikProps<ChangePasswordFormValues>;
                  }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        !!form.errors.oldPassword && form.touched.oldPassword
                      }
                    >
                      <FormLabel display="flex" m={"0"}>
                        <Text
                          color="#6F6F6F"
                          fontSize={"12px"}
                          fontWeight={"400"}
                        >
                          {tTitle("old_password")}
                        </Text>
                      </FormLabel>
                      <Input
                        {...field}
                        type={"password"}
                        height={"28px"}
                        maxWidth={"320px"}
                      />
                      <Flex
                        minH="28px"
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <FormErrorMessage m="0" fontSize={"12px"}>
                          {form.errors.oldPassword}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                <Field name="newPassword">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string>;
                    form: FormikProps<ChangePasswordFormValues>;
                  }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        !!form.errors.newPassword && form.touched.newPassword
                      }
                    >
                      <FormLabel display="flex" m={"0"}>
                        <Text
                          color="#6F6F6F"
                          fontSize={"12px"}
                          fontWeight={"400"}
                        >
                          {tTitle("new_password")}
                        </Text>
                      </FormLabel>
                      <Input
                        {...field}
                        type={"password"}
                        height={"28px"}
                        maxWidth={"320px"}
                      />
                      <Flex
                        minH="28px"
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <FormErrorMessage m="0" fontSize={"12px"}>
                          {form.errors.newPassword}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                <Field name="newPasswordConfirmation">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string>;
                    form: FormikProps<ChangePasswordFormValues>;
                  }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        !!form.errors.newPasswordConfirmation &&
                        form.touched.newPasswordConfirmation
                      }
                    >
                      <FormLabel display="flex" m={"0"}>
                        <Text
                          color="#6F6F6F"
                          fontSize={"12px"}
                          fontWeight={"400"}
                        >
                          {tTitle("new_password_confirmation")}
                        </Text>
                      </FormLabel>
                      <Input
                        {...field}
                        type={"password"}
                        height={"28px"}
                        maxWidth={"320px"}
                      />
                      <Flex h="28px" flexDirection="row" alignItems={"center"}>
                        <FormErrorMessage m="0" fontSize={"12px"}>
                          {form.errors.newPasswordConfirmation}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                <Box height={"28px"}>
                  <AppButton
                    variant={"primary"}
                    size={"medium"}
                    type={"submit"}
                    isDisabled={!(props.isValid && props.dirty)}
                    isLoading={isLoading || props.isSubmitting}
                    onClick={(event) => {
                      //event.preventDefault();
                    }}
                  >
                    {tTitle("common:change")}
                  </AppButton>
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </AppMyAccountCard>
  );
}
