import { FlatProductImageUrl } from "@/features/line-sheet-sets/helpers/sheet-state";
import { Box, Grid, Image, Tooltip } from "@chakra-ui/react";
import AppLabel from "@/features/ui/app-label";
import { times } from "lodash";
import { AppUtils } from "@/features/ui/utils/app-utils";
import ProductImageLabel from "@/features/order-sheet-sets/product-image-label";
import { PRODUCT_IMAGE_SIZE } from "@/features/order-sheet-sets/product-image-cell";

interface RepresentativeProductImageProps {
  value: FlatProductImageUrl[];
}

export const MAX_PRODUCT_IMAGE_COUNT = 8;
//caution ALTERNATIVE_IMAGE_COUNT must be less than IMAGE_COUNT
const ALTERNATIVE_IMAGE_COUNT = 2;

export default function RepresentativeProductImage({
  value,
}: RepresentativeProductImageProps) {
  let flatImageUrls: FlatProductImageUrl[] = [];

  if (value) {
    flatImageUrls = value
      .filter((item) => item.type === "ORIGINAL")
      .slice(0, MAX_PRODUCT_IMAGE_COUNT);

    if (flatImageUrls.length < MAX_PRODUCT_IMAGE_COUNT) {
      flatImageUrls = [
        ...flatImageUrls,
        ...value
          .filter((item) => item.type === "ALTERNATIVE")
          .slice(0, MAX_PRODUCT_IMAGE_COUNT - flatImageUrls.length),
      ];
    } else {
      flatImageUrls = [
        ...flatImageUrls.slice(
          0,
          MAX_PRODUCT_IMAGE_COUNT - ALTERNATIVE_IMAGE_COUNT
        ),
        ...value
          .filter((item) => item.type === "ALTERNATIVE")
          .slice(0, ALTERNATIVE_IMAGE_COUNT),
      ];
    }
  }

  return (
    <Tooltip
      placement={"right"}
      backgroundColor={`var(--gray-dark, #444440)`}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.20)"}
      maxWidth={"auto"}
      hasArrow={true}
      p={"0"}
      m={"0"}
      label={<ProductImageLabel value={flatImageUrls} />}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ]}
    >
      <Image
        borderRadius={"8px"}
        border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
        background={"#fff"}
        position={"relative"}
        flexGrow={"1"}
        alt={"alt"}
        src={
          flatImageUrls.length > 0
            ? AppUtils.getThumbnailUrl(flatImageUrls[0].url)
            : undefined
        }
        fallbackSrc={
          flatImageUrls.length > 0 ? flatImageUrls[0].url : undefined
        }
        boxSize={`${PRODUCT_IMAGE_SIZE}px`}
        objectFit={"contain"}
        zIndex={"10"}
      />
    </Tooltip>
  );
}
