import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import AppSheetSetBillboard, {
  BillBoardItem,
} from "@/features/line-sheet-sets/app-sheet-set-billboard";
import { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppLineSheetSetStatusLabel from "@/features/ui/app-line-sheet-set-status-label";

interface LineSheetSetBillboardProps {
  lineSheetSet: LightLineSheetSet | undefined;
}
export default function LineSheetSetBillboard({
  lineSheetSet,
}: LineSheetSetBillboardProps) {
  const { tTitle, tWithPrefix, tLocalDateString, tLocalDateTimeString2 } =
    useI18nHelper();

  const rows = useMemo((): BillBoardItem[][] => {
    if (lineSheetSet) {
      return [
        [
          { value: tTitle("status"), width: "64px" },
          {
            // value: tWithPrefix("line_sheet_set.status", lineSheetSet.status),
            value: <AppLineSheetSetStatusLabel value={lineSheetSet.status} />,
            variant: "bold",
            width: "160px",
          },
          { value: tTitle("issued_on"), width: "64px" },
          {
            value: tLocalDateString(lineSheetSet.publishedAt),
            variant: "bold",
            width: "128px",
          },
        ],
        [
          { value: tTitle("common:type") },
          {
            value: tWithPrefix("line_sheet_set.type", lineSheetSet.type),
            variant: "bold",
            width: "80px",
          },
          { value: tTitle("common:valid_until") },
          {
            value: tLocalDateTimeString2(lineSheetSet.validUntil),
            variant: "bold",
          },
        ],
        [
          { value: tTitle("issued_by") },
          { value: lineSheetSet.issuedByBoutique.name, variant: "bold" },
        ],
      ];
    }
    return [[]];
  }, [
    lineSheetSet,
    tLocalDateString,
    tLocalDateTimeString2,
    tTitle,
    tWithPrefix,
  ]);

  return <AppSheetSetBillboard rows={rows} />;
}
