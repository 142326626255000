import React, { useCallback, useMemo } from "react";
import { differenceBy } from "lodash";
import AppDeletableSelect from "@/features/ui/app-deletable-select";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import { Draft, produce } from "immer";

interface AppExclusiveSelectProps<T> {
  name: string;
  values: T[];
  options: T[];
  optionFunc: (item: T) => AppSelectOption<T>;
  onSelect: (values: T[]) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isLiteral?: boolean;
}

export default function AppExclusiveSelect<T extends { id: number }>({
  name,
  values,
  options,
  optionFunc,
  onSelect,
  isLoading,
  isDisabled,
  isMultiple = true,
  isLiteral = false,
}: AppExclusiveSelectProps<T>) {
  const { t, i18n } = useTranslation();

  const selects = useMemo(() => {
    const diff = differenceBy(options, values, "id");

    function withOnSelect(index: number) {
      return (selectedValue: T | undefined) => {
        onSelect(
          produce(values, (draft) => {
            if (selectedValue) {
              draft[index] = selectedValue as Draft<T>;
            } else {
              draft.splice(index, 1);
            }
          })
        );
      };
    }

    function withOnDelete(index: number) {
      return () => {
        onSelect(values.filter((v, i) => i !== index));
      };
    }

    return values.map((value, index) => {
      return (
        <AppDeletableSelect<T>
          key={"AppExclusiveSelects_" + index}
          name={name}
          options={[value, ...diff].map(optionFunc)}
          value={value}
          onSelect={withOnSelect(index)}
          onDelete={withOnDelete(index)}
          isDisabled={isDisabled}
        />
      );
    });
  }, [values, options, optionFunc, name, isDisabled, onSelect]);

  const handleAddClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const diff = differenceBy(options, values, "id");
      if (diff.length > 0) {
        const nextValues = [...values, diff[0]];
        onSelect(nextValues);
      }
    },
    [options, values, onSelect]
  );

  const label = useMemo(() => {
    if (i18n.exists(`add_${name}`)) {
      return toTitle(t(`add_${name}`));
    } else {
      return toTitle(t("add.do"));
    }
  }, [i18n, t, name]);

  if (isLiteral) {
    return (
      <Flex flexDirection={"column"} gap={"8px"}>
        {values.map((value, index) => {
          return <Text key={index}>{optionFunc(value).name}</Text>;
        })}
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      {selects}
      <AppButton
        width={"100%"}
        variant={"normal"}
        isDisabled={
          isDisabled ||
          options.length === values.length ||
          (!isMultiple && values.length > 0)
        }
        onClick={handleAddClick}
      >
        {isLoading ? (
          <Spinner size={"sm"} maxBlockSize={"24px"} />
        ) : (
          <>
            <SVG
              src={"/icons/icon_add.svg"}
              width={"14px"}
              height={"14px"}
              style={{ marginRight: "8px" }}
            />
            {label}
          </>
        )}
      </AppButton>
    </Flex>
  );
}
