import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useCallback } from "react";
import { isFile, StoredObject } from "@/features/types";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import {
  createFtaSignatureKey,
  CreateFtaSignaturePathParameter,
  CreateFtaSignatureRequest,
} from "@/features/invoices/triangular-trades/hooks/use-create-fta-signature";
import useCreateFtaSignatureInfinite from "@/features/invoices/triangular-trades/hooks/use-create-fta-signature-infinite";

function mutateFtaSignatureList() {
  mutateIfMatched(/\/(agencies|buyers|boutiques)\/\d+\/(dense-)?fta_signature/);
}

export function useFtaSignatureDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();

  const { fire: fireCreateFtaSignature } = useCreateFtaSignatureInfinite();

  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const addCreate = useCallback(
    (
      parameter: CreateFtaSignaturePathParameter,
      request: Omit<CreateFtaSignatureRequest, "signatureFile"> & {
        signatureFile: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      addDumbTransactions({
        parameter: parameter,
        source: request,
        getFilesFunc: (request) => {
          if (isFile(request.signatureFile)) {
            return [request.signatureFile];
          }
          return [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            signatureFile: storeObjectResponses[0],
          };
        },
        fire: fireCreateFtaSignature,
        keyOrFunc: calcYWRUrl(createFtaSignatureKey, parameter),
        onPostFunc: (res) => {
          mutateFtaSignatureList();
        },
        onErrorFunc: (error) => {
          showError(error);
        },
      });
    },
    [addDumbTransactions, fireCreateFtaSignature, showError]
  );

  return {
    addCreate,
  };
}
