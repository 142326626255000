import { ListCreditInvoicesRequest } from "@/features/invoices/credit-invoices/hooks/use-list-credit-invoices";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { DenseCreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import useListDenseCreditInvoicesFetcher from "@/features/invoices/credit-invoices/hooks/use-list-dense-credit-invoices-fethcer";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseCreditInvoicesRequest
  extends ListCreditInvoicesRequest {}

export interface ListDenseCreditInvoicesResponse
  extends PageNumberBasedListResponse {
  creditInvoices: DenseCreditInvoice[];
}

export interface ListDenseCreditInvoicesPathParameter
  extends IdentityParameter {}

export const listDenseCreditInvoicesTemplateUrl =
  "/:by/:companyId/dense-credit-invoices";

export function getListDenseCreditInvoicesKey(
  parameter?: ListDenseCreditInvoicesPathParameter,
  request?: ListDenseCreditInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseCreditInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
}

export function parseListDenseCreditInvoicesKey(
  key: AxiosRequestConfig<ListDenseCreditInvoicesRequest>
) {
  const urlParser = new UrlParser<ListDenseCreditInvoicesPathParameter>(
    listDenseCreditInvoicesTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function readDenseCreditInvoiceProactively(creditInvoiceId: number) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-credit-invoices/,
    "creditInvoices",
    creditInvoiceId
  );
}

export default function useListDenseCreditInvoices(
  parameter?: ListDenseCreditInvoicesPathParameter,
  request?: ListDenseCreditInvoicesRequest,
  config?: SWRConfiguration<ListDenseCreditInvoicesResponse>
) {
  const fetcher = useListDenseCreditInvoicesFetcher();

  return useSWR(
    getListDenseCreditInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
