import { Box, Input } from "@chakra-ui/react";
import PriceAdjustedByTypeSelect from "@/features/order-sheet-sets/price-adjusted-by-type-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import { useCallback, useMemo, useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { produce } from "immer";
import { PriceAdjustedBy } from "@/features/line-sheet-sets/line-sheet-set.types";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import { InflatedProduct } from "@/features/line-sheet-sets/helpers/sheet-state";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface OrderSheetAdjustPriceFragmentProps {
  orderSheetSetId: number;
  orderSheetId: number;
  revisionNumber: number;
  tabIndex: number;
  selectedProductIds: number[];
  isEditable: boolean;
}

export default function OrderSheetAdjustPriceFragment({
  orderSheetSetId,
  orderSheetId,
  revisionNumber,
  tabIndex,
  selectedProductIds,
  isEditable,
}: OrderSheetAdjustPriceFragmentProps) {
  const { t, tTitle } = useI18nHelper();
  const { warning: showWarning } = useAppToasts();
  const [amount, setAmount] = useState<number>(0);
  const [by, setBy] = useState<PriceAdjustedBy["by"]>("MARKUP");
  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId,
      orderSheetId,
      revisionNumber,
    };
  }, [orderSheetSetId, orderSheetId, revisionNumber]);
  const { getInflatedProductById, updateInflatedProducts } = useInflatedTabs();

  const selectedProducts = useMemo(() => {
    return selectedProductIds
      .map((id) => {
        return getInflatedProductById(sheetKey, tabIndex, id);
      })
      .filter((product): product is InflatedProduct => {
        return product !== undefined;
      });
  }, [sheetKey, tabIndex, selectedProductIds, getInflatedProductById]);

  const isDisabled = useMemo(() => {
    return !isEditable || selectedProducts.length === 0;
  }, [isEditable, selectedProducts]);

  const handleApply = useCallback(() => {
    if (amount > 100 || amount < 0) {
      showWarning(
        t("common:apply_price_condition_form.messages.error.out_of_range"),
        "apply_price_condition_form"
      );
      return;
    } else {
      const nextProducts = produce(selectedProducts, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          draft[i].priceAdjustedBy = {
            by: by,
            value: amount,
          };
        }
      });
      updateInflatedProducts(sheetKey, tabIndex, nextProducts);
    }
  }, [
    t,
    showWarning,
    by,
    amount,
    sheetKey,
    tabIndex,
    selectedProducts,
    updateInflatedProducts,
  ]);

  return (
    <>
      <PriceAdjustedByTypeSelect
        width={"96px"}
        value={by}
        isDisabled={isDisabled}
        onSelect={(v) => {
          if (v) {
            setBy(v);
          }
        }}
      />
      <Input
        width={"60px"}
        textAlign={"center"}
        fontSize={"12px"}
        type={"number"}
        h={"28px"}
        minH={"0"}
        p={"0"}
        borderColor={"#D4D4D1"}
        onChange={(e) => {
          setAmount(Number(e.target.value));
        }}
        disabled={isDisabled}
      />
      <Box fontSize={"12px"}>%</Box>
      <AppButton isDisabled={isDisabled} onClick={handleApply}>
        {tTitle("apply")}
      </AppButton>
    </>
  );
}
