import useFetcher from "@/utils/use-fetcher";
import {
  updateLineSheetSetReviewStatusKey,
  UpdateLineSheetSetReviewStatusRequest,
  UpdateLineSheetSetReviewStatusResponse,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-review-status";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useUpdateLineSheetSetReviewStatusInfinite() {
  const fetcher = useFetcher<
    UpdateLineSheetSetReviewStatusRequest,
    UpdateLineSheetSetReviewStatusResponse
  >();

  return useYWRInfinite(updateLineSheetSetReviewStatusKey, fetcher);
}
