import { DenseLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { AxiosRequestConfig } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import useGetDenseLineSheetSetFetcher from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set-fetcher";

export interface GetDenseLineSheetSetRequest {}
export interface GetDenseLineSheetSetResponse extends DenseLineSheetSet {}

export interface GetDenseLineSheetSetPathParameter extends IdentityParameter {
  lineSheetSetId: number;
  orderSheetSetId?: number;
  infer?: boolean;
}

export const getDenseLineSheetSetTemplateUrl = `/:by/:companyId/dense-line-sheet-sets/:lineSheetSetId?orderSheetSetId=:orderSheetSetId&infer=:infer`;

export function getGetDenseLineSheetSetKey(
  parameter?: GetDenseLineSheetSetPathParameter,
  request?: GetDenseLineSheetSetRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDenseLineSheetSetTemplateUrl, parameter),
      params: request,
    };
  }
}

export function parseGetDenseLineSheetSetKey(
  config: AxiosRequestConfig<GetDenseLineSheetSetRequest>
) {
  const urlParser = new UrlParser<GetDenseLineSheetSetPathParameter>(
    getDenseLineSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      infer: StringTransformer.parseBoolean,
    }
  );

  return {
    parameter: urlParser.parse(config.url!),
    request: config.params,
  };
}

export default function useGetDenseLineSheetSet(
  parameter?: GetDenseLineSheetSetPathParameter,
  request?: GetDenseLineSheetSetRequest,
  config?: SWRConfiguration
) {
  const fetcher = useGetDenseLineSheetSetFetcher();

  return useSWR(
    getGetDenseLineSheetSetKey(parameter, request),
    fetcher,
    config
  );
}
