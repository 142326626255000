import useFetchLineSheetSetsFromEmail from "@/features/line-sheet-sets/hooks/use-fetch-line-sheet-sets-from-email";
import { useEffect } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppButton from "@/features/line-sheet-sets/app-button";
import { toTitle } from "@/utils/case";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";

export default function FetchLineSheetSetsFromEmailButton() {
  const {
    error: showError,
    warning: showWarning,
    info: showInfo,
  } = useAppToasts({ id: "AgencyLineSheetSetListPage" });
  const { t } = useI18nHelper();
  const { openDialog } = useAppDialog();

  const {
    data: fetchLineSheetSetsFromEmailData,
    fire: fireFetchLineSheetSetsFromEmail,
    error: fetchLineSheetSetsFromEmailError,
  } = useFetchLineSheetSetsFromEmail();

  useEffect(() => {
    if (fetchLineSheetSetsFromEmailData) {
      showInfo(
        t("fetch_line_sheet_emails.messages.success", { ns: "line_sheet_sets" })
      );
    }
  }, [showInfo, fetchLineSheetSetsFromEmailData, t]);

  useEffect(() => {
    if (fetchLineSheetSetsFromEmailError) {
      showError(fetchLineSheetSetsFromEmailError);
    }
  }, [showError, fetchLineSheetSetsFromEmailError]);

  return (
    <AppButton
      key={"AgencyLineSheetSetListFilter_ResetFilter"}
      variant={"normal"}
      size={"medium"}
      onClick={async () => {
        // 확인 모달 띄우기
        const result = await openDialog({
          title: t("fetch_line_sheet_emails", {
            ns: "line_sheet_sets",
          }),
          message: t("fetch_line_sheet_emails_confirm.message", {
            ns: "line_sheet_sets",
          }),
          confirmName: toTitle(t("fetch")),
        });

        if (result) {
          fireFetchLineSheetSetsFromEmail(
            {},
            {
              make_line_sheet_sets: true,
              force: false,
            }
          );
        }
      }}
    >
      {t("fetch_line_sheet_emails", { ns: "line_sheet_sets" })}
    </AppButton>
  );
}
