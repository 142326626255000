import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { DateTimeString } from "@/features/ui/utils/date-utils";
import { TransportationDetail } from "@/features/transportations/transportation.type";
import { IdentityParameter, StoredObject } from "@/features/types";

export interface UpdateTransportationParameter extends IdentityParameter {
  transportationId: number;
}

export interface UpdateTransportationRequest {
  id: number;
  status: TransportationDetail["status"];
  name: string;
  shippedFromList: { id: number }[];
  shippedTo: { id: number };
  shippedToAddress: string;
  forwarderInvoiceNumbers: string[];

  houseBlNumbers: string[];

  flightNumber: string;
  flightDepartureAirport: string;
  flightEtd: DateTimeString;
  flightAtd: DateTimeString | null;
  flightEta: DateTimeString;
  flightAta: DateTimeString | null;
  flightArrivalAirport: string;
  finalEta: Date;
  cost: number;
  files: Pick<StoredObject, "id" | "name">[];

  proformaInvoices: { id: number }[];
}

export interface UpdateTransportationResponse {}

export const updateTransportationTemplateUrl = `/:by/:companyId/transportations/:transportationId`;

export const updateTransportationKey: YWRKey<UpdateTransportationParameter> = {
  url: (parameters: UpdateTransportationParameter) => {
    return calcUrl(updateTransportationTemplateUrl, parameters);
  },
  method: "put",
};

export default function useUpdateTransportation() {
  const fetcher = useFetcher<
    UpdateTransportationRequest,
    UpdateTransportationResponse
  >();

  return useYWR(updateTransportationKey, fetcher);
}
