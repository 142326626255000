import { FormikProps, FormikValues } from "formik";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import BinarySplitView from "@/features/ui/binary-split-view";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import ProformaInvoiceInformationFormControl from "@/features/invoices/proforma-invoices/proforma-invoice-information-form-control";
import ProformaInvoiceFigureFormControl from "@/features/invoices/proforma-invoices/proforma-invoice-figure-form-control";
import LinkedOrderSheetSetsFormControl from "@/features/invoices/order-confirmation-invoices/linked-order-sheet-sets-form-control";
import LinkedOrderConfirmationInvoicesFormControl from "@/features/invoices/proforma-invoices/linked-order-confirmation-invoices-form-control";
import { ListAllOrderConfirmationInvoicesRequest } from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import LinkedProformaInvoiceFormControl from "@/features/invoices/proforma-invoices/linked-proforma-invoice-form-control";
import { ListAllProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import { ListAllDenseOrderSheetSetsRequest } from "@/features/order-sheet-sets/hooks/use-list-all-dense-order-sheet-sets";
import IsolatedCreditInvoicePanel from "@/features/invoices/credit-invoices/isolated-credit-invoice-panel";
import { AppUtils } from "@/features/ui/utils/app-utils";
import IsolatedBankRemittanceReceiptPanel from "@/features/invoices/bank-remittance-receipts/isolated-bank-remittance-receipt-panel";
import { groupBy, isEqual, uniqBy } from "lodash";
import IsolatedProformaInvoicePanel from "@/features/invoices/order-confirmation-invoices/isolated-proforma-invoice-panel";
import InvoiceAttachmentsFormControl from "@/features/invoices/order-confirmation-invoices/invoice-attachments-form-control";
import { MathUtils } from "@/features/ui/utils/math-utils";
import useIdentity from "@/features/ui/hooks/use-identity";
import useSynchronizeProformaInvoiceDetail from "@/features/invoices/proforma-invoices/hooks/use-synchronize-proforma-invoice-detail-list";
import PriceUtils from "@/features/ui/utils/price-utils";
import IsolatedPaymentPanel from "@/features/invoices/payment/isolated-payment-panel";

interface ProformaInvoiceFormProps {
  formik: FormikProps<ProformaInvoiceFormFields>;
  isEditing?: boolean;
}

function clearProformaInvoiceDetailList(
  values: ProformaInvoiceFormFields
): ProformaInvoiceFormFields {
  const linkedOCIds = values.orderConfirmationInvoices.map(
    (ocInvoice) => ocInvoice.id
  );

  const linkedOSSIds = values.orderSheetSets.map((oss) => oss.id);

  const next = {
    ...values,
    proformaInvoiceDetailList: values.proformaInvoiceDetailList.map(
      (pDetail) => {
        if (pDetail.orderConfirmationInvoiceDetail) {
          if (
            linkedOCIds.includes(
              pDetail.orderConfirmationInvoiceDetail.orderConfirmationInvoice.id
            )
          ) {
            return pDetail;
          }
        } else if (pDetail.orderSheetSet) {
          if (linkedOSSIds.includes(pDetail.orderSheetSet.id)) {
            return pDetail;
          }
        }

        return {
          ...pDetail,
          orderSheetSet: undefined,
          brand: pDetail.brand,
          reamingDepositAmount: undefined,
          orderConfirmationInvoiceDetail: undefined,
        };
      }
    ),
  };

  next.expectedRemainingDepositAmount = PriceUtils.sum(
    next.proformaInvoiceDetailList.map(
      (pDetail) =>
        pDetail.orderConfirmationInvoiceDetail
          ?.expectedRemainingDepositAmount || { currency: "EUR" }
    ),
    "EUR"
  );

  if (
    next.orderConfirmationInvoices.length > 0 ||
    next.orderSheetSets.length > 0
  ) {
    next.lineSheetSetType = undefined;
  }

  return next;
}

export default function ProformaInvoiceForm({
  formik,
  isEditing = false,
}: ProformaInvoiceFormProps) {
  const identity = useIdentity();

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listOrderConfirmationInvoicesRequest = useMemo(():
    | ListAllOrderConfirmationInvoicesRequest
    | undefined => {
    if (formik.values.childProformaInvoices.length > 0) {
      const id__in = formik.values.childProformaInvoices
        .flatMap((pInvoice) => pInvoice.orderConfirmationInvoices)
        .map((ocInvoice) => ocInvoice.id);
      if (id__in.length > 0) {
        return {
          id__in,
        };
      } else {
        return undefined;
      }
    }

    const issuedBy = formik.values.originallyIssuedBy || formik.values.issuedBy;

    if (
      formik.values.season &&
      issuedBy &&
      formik.values.orderedBy &&
      formik.values.issuedThrough
    ) {
      return {
        season__eq: formik.values.season,
        "issuedByCompany.id__eq": issuedBy.id,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        "issuedThroughCompany.id__eq": formik.values.issuedThrough.id,
        isRemittedToBoutique__eq: true,
        paidBy__eq: formik.values.paidByAgency ? "AGENCY" : "BUYER",
        isPublic__eq: true,
        status__eq: "NORMAL",
      };
    }
    return undefined;
  }, [
    formik.values.childProformaInvoices,
    formik.values.originallyIssuedBy,
    formik.values.issuedBy,
    formik.values.season,
    formik.values.orderedBy,
    formik.values.issuedThrough,
    formik.values.paidByAgency,
  ]);

  const listOrderSheetSetsRequest = useMemo(():
    | ListAllDenseOrderSheetSetsRequest
    | undefined => {
    if (formik.values.childProformaInvoices.length > 0) {
      const id__in = formik.values.childProformaInvoices
        .flatMap((pInvoice) => pInvoice.orderSheetSets)
        .map((oss) => oss.id);
      if (id__in.length > 0) {
        return {
          id__in,
        };
      } else {
        return undefined;
      }
    }

    const issuedBy = formik.values.originallyIssuedBy || formik.values.issuedBy;

    if (
      formik.values.season &&
      formik.values.orderedBy &&
      issuedBy &&
      formik.values.issuedThrough
    ) {
      return {
        "lineSheetSet.season__eq": formik.values.season,
        status__in: ["BOUTIQUE_CONFIRMED"],
        "createdByCompany.id__in": [formik.values.orderedBy.id],
        // "lineSheetSet.issuedByCompany.id__in": [issuedBy.id],
        "orderSheet.toBeSubmitted.id__eq": issuedBy.id,
        "lineSheetSet.createdByCompany.id__eq": formik.values.issuedThrough.id,
      };
    }

    return undefined;
  }, [
    formik.values.childProformaInvoices,
    formik.values.originallyIssuedBy,
    formik.values.issuedBy,
    formik.values.season,
    formik.values.orderedBy,
    formik.values.issuedThrough,
  ]);

  const listChildProformaInvoicesRequest = useMemo(():
    | ListAllProformaInvoicesRequest
    | undefined => {
    if (
      formik.values.status !== "REPLACED" &&
      formik.values.season &&
      formik.values.originallyIssuedBy &&
      formik.values.orderedBy &&
      formik.values.issuedThrough
    ) {
      return {
        season__eq: formik.values.season,
        "originallyIssuedByCompany.id__eq": formik.values.originallyIssuedBy.id,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        "issuedThroughCompany.id__eq": formik.values.issuedThrough.id,
        status__eq: "REPLACED",
        isPublic__eq: true,
      };
    }

    return undefined;
  }, [
    formik.values.issuedThrough,
    formik.values.orderedBy,
    formik.values.originallyIssuedBy,
    formik.values.season,
    formik.values.status,
  ]);

  const isDisabled =
    isEditing &&
    (formik.values.payments.some((payment) =>
        payment.sender.id === formik.values.orderedBy?.id && payment.status === "NORMAL"
    ) ||
      formik.values.status !== "NORMAL");

  useSynchronizeProformaInvoiceDetail({ isEditing });

  const isMerging = formik.values.childProformaInvoices.length > 0;

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <ProformaInvoiceInformationFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
            <InvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />

            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <InvoiceAttachmentsFormControl
                formik={formik}
                fieldName={"attachments"}
                isEditing={isEditing}
                isDisabled={isDisabled}
              />
            )}
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <ProformaInvoiceFigureFormControl
                formik={formik}
                isEditing={isEditing}
                isDisabled={isDisabled}
              />
            )}

            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <LinkedOrderSheetSetsFormControl
                formik={formik}
                isEditing={isEditing}
                request={listOrderSheetSetsRequest}
                isDisabled={
                  isDisabled ||
                  (formik.values.orderConfirmationInvoices &&
                    formik.values.orderConfirmationInvoices.length > 0)
                }
                onSelect={(value) => {
                  formik.setValues((prev) => {
                    return {
                      ...clearProformaInvoiceDetailList({
                        ...prev,
                        orderSheetSets: value,
                        orderConfirmationInvoices: isMerging
                          ? prev.orderConfirmationInvoices
                          : [],
                      }),
                      lineSheetSetType: value.length > 0 ? "STOCK" : undefined,
                    };
                  });
                }}
              />
            )}

            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <LinkedOrderConfirmationInvoicesFormControl
                formik={formik}
                isEditing={isEditing}
                request={listOrderConfirmationInvoicesRequest}
                isDisabled={
                  isDisabled ||
                  (formik.values.orderSheetSets &&
                    formik.values.orderSheetSets.length > 0) ||
                  isMerging
                }
                onSelect={(rawValue) => {
                  let value = rawValue;

                  //잔여 디파짓을 연산을 위해 초기값을 넣어줌
                  if (isEditing && formik.values.id !== -1) {
                    value = rawValue.map((ocInvoice) => {
                      const initialOcInvoice =
                        formik.initialValues.orderConfirmationInvoices.find(
                          (initialOcInvoice) => {
                            return initialOcInvoice.id === ocInvoice.id;
                          }
                        );

                      if (initialOcInvoice) {
                        return {
                          ...ocInvoice,
                          expectedRemainingDepositAmount:
                            initialOcInvoice.expectedRemainingDepositAmount,
                          orderConfirmationInvoiceDetailList:
                            initialOcInvoice.orderConfirmationInvoiceDetailList,
                        };
                      } else {
                        return ocInvoice;
                      }
                    });
                  }

                  formik.setValues((prev) => {
                    return {
                      ...clearProformaInvoiceDetailList({
                        ...prev,
                        orderSheetSets: isMerging ? prev.orderSheetSets : [],
                        orderConfirmationInvoices: value,
                      }),
                      lineSheetSetType:
                        rawValue.length > 0 ? "SEASON" : undefined,
                    };
                  });
                }}
              />
            )}

            {!(
              identity?.company?.type === "BOUTIQUE" && formik.values.id === -1
            ) && (
              <LinkedProformaInvoiceFormControl
                formik={formik}
                isEditing={isEditing}
                request={listChildProformaInvoicesRequest}
                title={"previous_proforma_invoices"}
                isDisabled={isDisabled}
                onSelect={(value) => {
                  formik.setValues((prev) => {
                    const orderSheetSets = uniqBy(
                      value.flatMap((pInvoice) => pInvoice.orderSheetSets),
                      "id"
                    );

                    const orderConfirmationInvoices = uniqBy(
                      value.flatMap(
                        (pInvoice) => pInvoice.orderConfirmationInvoices
                      ),
                      "id"
                    );

                    const next = {
                      ...prev,
                      orderSheetSets,
                      orderConfirmationInvoices,
                      childProformaInvoices: value,
                    };

                    next.usedCreditInvoices = value.flatMap((value) => {
                      return value.usedCreditInvoices;
                    });

                    if (next.proformaInvoiceDetailList.length === 0 || true) {
                      const pDetailsByKey = groupBy(
                        value.flatMap(
                          (pInvoice) => pInvoice.proformaInvoiceDetailList
                        ),
                        (pDetail) => {
                          return `${pDetail.orderSheetSet?.id || -1}_${
                            pDetail.orderConfirmationInvoiceDetail?.id || -1
                          }_${pDetail.brand.id}`;
                        }
                      );

                      next.proformaInvoiceDetailList = Object.values(
                        pDetailsByKey
                      ).flatMap((pDetails) => {
                        const pDetail = pDetails[0];

                        const orderSheetSet = orderSheetSets.find(
                          (oss) => oss.id === pDetail.orderSheetSet?.id
                        );

                        const orderConfirmationInvoice =
                          pDetail.orderConfirmationInvoiceDetail
                            ? orderConfirmationInvoices.find((ocInvoice) => {
                                return ocInvoice.orderConfirmationInvoiceDetailList
                                  .map((ocDetail) => ocDetail.id)
                                  .includes(
                                    pDetail.orderConfirmationInvoiceDetail!.id
                                  );
                              })
                            : undefined;

                        const amount = PriceUtils.sum(
                          pDetails.map((i) => i.amount),
                          "EUR"
                        );
                        const usedDepositAmount = PriceUtils.sum(
                          pDetails.map((i) => i.usedDepositAmount),
                          "EUR"
                        );

                        return {
                          ...pDetail,
                          orderSheetSet,
                          orderConfirmationInvoiceDetail:
                            pDetail.orderConfirmationInvoiceDetail &&
                            orderConfirmationInvoice
                              ? {
                                  ...pDetail.orderConfirmationInvoiceDetail,
                                  orderConfirmationInvoice: {
                                    id: orderConfirmationInvoice.id,
                                    name: orderConfirmationInvoice.name,
                                  },
                                }
                              : undefined,
                          amount,
                          usedDepositAmount,
                        };
                      });

                      next.usedDeposit = PriceUtils.sum(
                        next.proformaInvoiceDetailList.map(
                          (pInvoice) => pInvoice.usedDepositAmount
                        ),
                        "EUR"
                      );
                    }

                    return next;
                  });
                }}
                fieldName={"childProformaInvoices"}
              />
            )}

            {!isEditing && (
              <IsolatedProformaInvoicePanel
                proformaInvoiceIds={AppUtils.extractIds(
                  formik.values.parentProformaInvoices
                )}
                title={"modified_proforma_invoices"}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedPaymentPanel
                paymentIds={AppUtils.extractIds(formik.values.payments)}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedCreditInvoicePanel
                title={"used_credit_invoices"}
                creditInvoiceIds={AppUtils.extractIds(
                  formik.values.usedCreditInvoices
                )}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedCreditInvoicePanel
                title={"earned_credit_invoices"}
                creditInvoiceIds={AppUtils.extractIds(
                  formik.values.earnedCreditInvoices
                )}
              />
            )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={400}
      />
    </Box>
  );
}
