import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { DateTimeString } from "@/features/ui/utils/date-utils";

export interface GetFlightRequest {
  number__eq: string;
  etd__eq: DateTimeString;
  departureAirport__eq: string;
}

export interface GetFlightResponse {
  number: string;
  departureAirport: string;
  arrivalAirport: string;
  eta: DateTimeString;
  etd: DateTimeString;
  ata: DateTimeString | null;
  atd: DateTimeString | null;
}

export interface GetFlightParameter {}

export const getFlightTemplateUrl = `/flight`;

export function getGetFlightKey(
  parameter?: GetFlightParameter,
  request?: GetFlightRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getFlightTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useGetFlight(
  parameter?: GetFlightParameter,
  request?: GetFlightRequest,
  config?: SWRConfiguration<GetFlightResponse>
) {
  const fetcher = useFetcher<GetFlightRequest, GetFlightResponse>();
  return useSWR(getGetFlightKey(parameter, request), fetcher, config);
}
