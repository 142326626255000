import { CellEditRequestEvent, ICellRendererParams } from "ag-grid-community";

export class AgGridUtils {
  static dispatchCellEditEvent<T>(
    newValue: T,
    params: ICellRendererParams<any, T>
  ) {
    const event: CellEditRequestEvent = {
      rowPinned: undefined,
      source: undefined,
      type: "cellEditRequest",
      api: params.api,
      newValue,
      oldValue: params.value,
      context: params.context,
      rowIndex: params.rowIndex,
      column: params.column!,
      columnApi: params.columnApi,
      data: params.data,
      value: params.value,
      colDef: params.colDef!,
      node: params.node,
    };
    params.api.dispatchEvent(event);
  }
}
