import { ListDepositInvoicesRequest } from "@/features/invoices/deposit-invoices/hooks/use-list-deposit-invoices";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { DenseDepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import useListDenseDepositInvoicesFetcher from "@/features/invoices/deposit-invoices/hooks/use-list-dense-deposit-invoices-fetcher";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseDepositInvoicesRequest
  extends ListDepositInvoicesRequest {}

export interface ListDenseDepositInvoicesResponse
  extends PageNumberBasedListResponse {
  depositInvoices: DenseDepositInvoice[];
}

export interface ListDenseDepositInvoicesPathParameter
  extends IdentityParameter {}

export const listDenseDepositInvoicesTemplateUrl =
  "/:by/:companyId/dense-deposit-invoices";

export function getListDenseDepositInvoicesKey(
  parameter?: ListDenseDepositInvoicesPathParameter,
  request?: ListDenseDepositInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseDepositInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
}

export function parseListDenseDepositInvoicesKey(
  key: AxiosRequestConfig<ListDenseDepositInvoicesRequest>
) {
  const urlParser = new UrlParser<ListDenseDepositInvoicesPathParameter>(
    listDenseDepositInvoicesTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function readDenseDepositInvoiceProactively(depositInvoiceId: number) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-deposit-invoices/,
    "depositInvoices",
    depositInvoiceId
  );
}

export default function useListDenseDepositInvoices(
  parameter?: ListDenseDepositInvoicesPathParameter,
  request?: ListDenseDepositInvoicesRequest,
  config?: SWRConfiguration<ListDenseDepositInvoicesResponse>
) {
  const fetcher = useListDenseDepositInvoicesFetcher();

  return useSWR(
    getListDenseDepositInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
