import { usePersistedQuery } from "@/features/order-sheet-sets/hooks/use-persisted-query";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export type PersistedLineSheetSetDetailPageQuery = {
  orderSheetSetId?: number;
};

export default function usePersistedLineSheetSetDetailPageQuery() {
  const { value, set, clear } =
    usePersistedQuery<PersistedLineSheetSetDetailPageQuery>({
      entries: {
        orderSheetSetId: {
          parse: StringTransformer.parseId,
          stringify: StringTransformer.stringifyId,
        },
      },
    });

  return {
    value,
    set,
    clear,
  };
}
