import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

export interface GetProformaInvoiceParameter extends IdentityParameter {
  proformaInvoiceId: number;
}

export interface GetProformaInvoiceRequest {}
export interface GetProformaInvoiceResponse extends ProformaInvoice {}

export const getProformaInvoiceTemplateUrl =
  "/:by/:companyId/proforma-invoices/:proformaInvoiceId";
export function getGetProformaInvoiceKey(
  parameter?: GetProformaInvoiceParameter,
  request?: GetProformaInvoiceRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getProformaInvoiceTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useGetProformaInvoice(
  parameter?: GetProformaInvoiceParameter,
  request?: GetProformaInvoiceRequest,
  config?: SWRConfiguration<GetProformaInvoiceResponse>
) {
  const fetcher = useFetcher<
    GetProformaInvoiceRequest,
    GetProformaInvoiceResponse
  >();
  return useSWR(getGetProformaInvoiceKey(parameter, request), fetcher, config);
}
