import { mutate } from "swr";

export function mutateIfMatched(regexp: RegExp) {
  mutate((key) => {
    if (key !== null && typeof key === "object" && "url" in key) {
      const result = key.url.match(regexp);
      return result != null;
    }
    return false;
  });
}

export function mutateResource(name: string, id?: number) {
  if (id) {
    mutateIfMatched(
      new RegExp(`/(buyers|agencies|boutiques)/(\\d+)/${name}/${id}`)
    );
  }
  mutateIfMatched(new RegExp(`/(buyers|agencies|boutiques)/(\\d+)/${name}`));
}
