import {
  CompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export interface ListOrderConfirmationInvoicesRequest
  extends PageNumberBasedListRequest {
  season__eq?: string;
  season__in?: string[];
  isRemittedToBoutique__eq?: boolean;
  isRemittedToAgency__eq?: boolean;
  validPaymentToAgency__exists?: boolean;
  validPaymentToBoutique__exists?: boolean;
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  "issuedByCompany.id__in"?: number[];
  "issuedThroughCompany.id__eq"?: number;
  issuedOn__eq?: Date;
  lineSheetSetType__eq?: LineSheetSetType;
  id__in?: number[];
  depositInvoice__isNull?: boolean;
  isPublic__eq?: boolean;
  unremitted_amount__gt?: number;
  status__eq?: OrderConfirmationInvoice["status"];
  status__in?: OrderConfirmationInvoice["status"][];
  "orderConfirmationInvoiceDetailList.brand.id__eq"?: number;
  paidBy__eq?: OrderConfirmationInvoice["paidBy"];
}

export interface ListOrderConfirmationInvoicesResponse
  extends PageNumberBasedListResponse {
  orderConfirmationInvoices: OrderConfirmationInvoice[];
}

export interface ListOrderConfirmationInvoicesPathParameter {
  by: CompanyType;
  companyId: number;
}

export const listOrderConfirmationInvoicesTemplateUrl = `/:by/:companyId/order-confirmation-invoices`;

export function getListOrderConfirmationInvoicesKey(
  parameter?: ListOrderConfirmationInvoicesPathParameter,
  request?: ListOrderConfirmationInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listOrderConfirmationInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
}

export const DEFAULT_LIST_ORDER_CONFIRMATION_INVOICES_PAGE_SIZE = 10;

export default function useListOrderConfirmationInvoices(
  parameter: ListOrderConfirmationInvoicesPathParameter,
  request: ListOrderConfirmationInvoicesRequest,
  config?: SWRConfiguration<ListOrderConfirmationInvoicesResponse>
) {
  const fetcher = useFetcher<
    ListOrderConfirmationInvoicesRequest,
    ListOrderConfirmationInvoicesResponse
  >();
  return useSWR(
    getListOrderConfirmationInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
