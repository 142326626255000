import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { MathUtils } from "@/features/ui/utils/math-utils";
import { isEqual } from "lodash";
import PriceUtils from "@/features/ui/utils/price-utils";

interface UseSynchronizeProformaInvoiceDetailProps {
  isEditing: boolean;
}

export default function useSynchronizeProformaInvoiceDetail({
  isEditing,
}: UseSynchronizeProformaInvoiceDetailProps) {
  const { values, setValues, setFieldValue } =
    useFormikContext<ProformaInvoiceFormFields>();

  useEffect(() => {
    if (isEditing) {
      setValues((prev) => {
        const prevTotalUsedDeposit = PriceUtils.sum(
          prev.proformaInvoiceDetailList.map(
            (pDetail) => pDetail.usedDepositAmount
          ),
          "EUR"
        );

        const updatedUsedDeposit = prev.usedDeposit;

        if (isEqual(updatedUsedDeposit, prevTotalUsedDeposit)) {
          return prev;
        } else {
          let proformaInvoiceDetailList = PriceUtils.distribute(
            updatedUsedDeposit,
            prev.proformaInvoiceDetailList,
            (item) => item.amount,
            (item, value) => (item.usedDepositAmount = value),
            "EUR"
          );

          proformaInvoiceDetailList = proformaInvoiceDetailList.map(
            (pDetail) => {
              return {
                ...pDetail,
                requiredAmount: PriceUtils.minus(
                  [
                    pDetail.amount,
                    pDetail.usedCreditAmount,
                    pDetail.usedDepositAmount,
                  ],
                  "EUR"
                ),
              };
            }
          );

          // console.dir({
          //   proformaInvoiceDetailList,
          //   updatedUsedDeposit,
          //   totalUsedCredit,
          // });

          return {
            ...prev,
            proformaInvoiceDetailList,
          };
        }
      });
    }
  }, [values.usedDeposit, setValues, isEditing]);

  useEffect(() => {
    if (isEditing) {
      setValues((prev) => {
        const prevTotalUsedCredit = PriceUtils.sum(
          prev.proformaInvoiceDetailList.map(
            (pDetail) => pDetail.usedCreditAmount
          ),
          "EUR"
        );

        const updatedTotalUsedCredit = PriceUtils.sum(
          prev.usedCreditInvoices.map((creditInvoice) => creditInvoice.amount),
          "EUR"
        );

        if (isEqual(prevTotalUsedCredit, updatedTotalUsedCredit)) {
          return prev;
        } else {
          let proformaInvoiceDetailList = PriceUtils.distribute(
            updatedTotalUsedCredit,
            prev.proformaInvoiceDetailList,
            (item) => item.amount,
            (item, value) => (item.usedCreditAmount = value),
            "EUR"
          );

          proformaInvoiceDetailList = proformaInvoiceDetailList.map(
            (pDetail) => {
              return {
                ...pDetail,
                requiredAmount: PriceUtils.minus(
                  [
                    pDetail.amount,
                    pDetail.usedCreditAmount,
                    pDetail.usedDepositAmount,
                  ],
                  "EUR"
                ),
              };
            }
          );

          return {
            ...prev,
            proformaInvoiceDetailList,
          };
        }
      });
    }
  }, [values.usedCreditInvoices, setValues, isEditing]);

  useEffect(() => {
    if (isEditing) {
      setValues((prev) => {
        const totalUsedCredit = PriceUtils.sum(
          prev.usedCreditInvoices.map((creditInvoice) => creditInvoice.amount),
          "EUR"
        );

        const updatedTotalUsedCredit = PriceUtils.sum(
          prev.proformaInvoiceDetailList.map(
            (pDetail) => pDetail.usedCreditAmount
          ),
          "EUR"
        );

        // const totalUsedDeposit = prev.usedDeposit;

        const updatedTotalUsedDeposit = PriceUtils.sum(
          prev.proformaInvoiceDetailList.map(
            (pDetail) => pDetail.usedDepositAmount
          ),
          "EUR"
        );

        // if (totalUsedDeposit !== updatedTotalUsedDeposit) {
        //   console.dir("1");
        //   return {
        //     ...prev,
        //     usedDeposit: updatedTotalUsedDeposit,
        //   };
        // }

        let updateProformaInvoiceDetailList = [
          ...prev.proformaInvoiceDetailList,
        ];

        if (!prev.isDetailsEditedManually) {
          updateProformaInvoiceDetailList = PriceUtils.distribute(
            updatedTotalUsedDeposit,
            prev.proformaInvoiceDetailList,
            (item) => item.amount,
            (item, value) => (item.usedDepositAmount = value),
            "EUR"
          );
        }

        updateProformaInvoiceDetailList = PriceUtils.distribute(
          totalUsedCredit,
          updateProformaInvoiceDetailList,
          (item) => item.amount,
          (item, value) => (item.usedCreditAmount = value),
          "EUR"
        );

        updateProformaInvoiceDetailList = updateProformaInvoiceDetailList.map(
          (pDetail) => {
            return {
              ...pDetail,
              requiredAmount: PriceUtils.minus(
                [
                  pDetail.amount,
                  pDetail.usedCreditAmount,
                  pDetail.usedDepositAmount,
                ],
                "EUR"
              ),
            };
          }
        );

        if (
          isEqual(
            updateProformaInvoiceDetailList,
            prev.proformaInvoiceDetailList
          ) &&
          isEqual(prev.usedDeposit, updatedTotalUsedDeposit)
        ) {
          return prev;
        } else {
          return {
            ...prev,
            usedDeposit: updatedTotalUsedDeposit,
            proformaInvoiceDetailList: updateProformaInvoiceDetailList,
            expectedRemainingDepositAmount: PriceUtils.sum(
              updateProformaInvoiceDetailList.map(
                (pDetail) =>
                  pDetail.orderConfirmationInvoiceDetail
                    ?.expectedRemainingDepositAmount
              ),
              "EUR"
            ),
          };
        }
      });
    }
  }, [values.proformaInvoiceDetailList, setValues, isEditing]);
}
