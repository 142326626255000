import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { Boutique, Buyer, LightCompany } from "@/features/types";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

export type BalanceTransactionType = "DEPOSIT" | "CREDIT";

export type BalanceTransactedBy =
  | "ADD_FIXING_CREDIT_INVOICE"
  | "ADD_SEASON_CREDIT_INVOICE"
  | "USE_CREDIT"
  | "ADD_DEPOSIT"
  | "USE_DEPOSIT";

export interface BalanceTransaction {
  id: number;
  type: BalanceTransactionType;
  season: string;
  transactionAmount: number;
  remainingAmount: number; // +/- 로 add/use 판단
  comment: string;
  transactedBy: BalanceTransactedBy;
  creditInvoice: CreditInvoice | null;
  proformaInvoice: ProformaInvoice | null;
  orderConfirmationInvoice: OrderConfirmationInvoice | null;
}

export const BalanceTransactionTypes: BalanceTransactionType[] = [
  "DEPOSIT",
  "CREDIT",
];

export const BalanceTransactedBys: BalanceTransactedBy[] = [
  "ADD_FIXING_CREDIT_INVOICE",
  "ADD_SEASON_CREDIT_INVOICE",
  "USE_CREDIT",
  "ADD_DEPOSIT",
  "USE_DEPOSIT",
];

export interface BalanceTransactionRow extends GridRow {
  season: string;
  issuedToCompany: LightCompany;
  issuedByCompany: LightCompany;
  issuedThroughCompany: LightCompany;
  invoiceNumber: string;
  type: BalanceTransactionType;
  transactionAmount: number;
  remainingAmount: number;
  issuedOn: Date;
  comment: string;
  transactedBy: BalanceTransactedBy;
}
