import { BankRemittanceReceiptFormFields } from "@/features/invoices/bank-remittance-receipts/upload-bank-remittance-receipt-page";
import { Field, FieldProps, FormikProps } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useCallback, useMemo } from "react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Box, Grid } from "@chakra-ui/react";
import AppPriceInput, {
  PriceInputValue,
} from "@/features/invoices/app-price-input";
import PriceUtils from "@/features/ui/utils/price-utils";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { InvoiceUtils } from "@/features/invoices/invoice-utils";

interface FormFields extends BankRemittanceReceiptFormFields {}

interface BankRemittanceFigureFormControlProps {
  formik: FormikProps<FormFields>;
  isEditing?: boolean;
}

export default function BankRemittanceReceiptFigureFormControl({
  formik,
  isEditing,
}: BankRemittanceFigureFormControlProps) {
  const { t, tTitle, tCurrencyString } = useI18nHelper();

  const getPayment = useCallback(
    (
      detail: BankRemittanceReceiptFormFields["bankRemittanceReceiptDetailList"][number]
    ) => {
      return formik.values.payments.find((payment) => {
        return payment.id === detail.payment?.id;
      });
    },
    [formik.values.payments]
  );

  const getOptions = useCallback(
    (
      detail: BankRemittanceReceiptFormFields["bankRemittanceReceiptDetailList"][number]
    ) => {
      const payments = formik.values.payments;
      const selectedPayments = formik.values.bankRemittanceReceiptDetailList
        .map((detail) => detail.payment?.id)
        .filter((id): id is number => id !== undefined);
      return payments
        .filter((payment) => {
          return (
            payment.id === detail.payment?.id ||
            !selectedPayments.includes(payment.id)
          );
        })
        .map((payment) => {
          return {
            value: {
              id: payment.id,
              name: payment.name,
            },
            name: `${payment.id} / ${payment.name}`,
          };
        });
    },
    [formik.values.bankRemittanceReceiptDetailList, formik.values.payments]
  );

  const total = useMemo(() => {
    const detailList = formik.values.bankRemittanceReceiptDetailList;
    const currency = InvoiceUtils.getCurrencyByParties({
      sender: formik.values.sender,
      receiver: formik.values.receiver,
    });
    return detailList
      .map(
        (
          detail
        ): {
          requiredAmount: PriceInputValue;
          unremittedAmount: PriceInputValue;
          remittedAmount: PriceInputValue;
        } => {
          const payment = getPayment(detail);
          return {
            requiredAmount: payment?.amount || { currency },
            unremittedAmount: payment?.unremittedAmount || { currency },
            remittedAmount: detail.amount || { currency },
          };
        }
      )
      .reduce(
        (acc, cur) => {
          return {
            requiredAmount: PriceUtils.sum(
              [acc.requiredAmount, cur.requiredAmount],
              currency
            ),
            unremittedAmount: PriceUtils.sum(
              [acc.unremittedAmount, cur.unremittedAmount],
              currency
            ),
            remittedAmount: PriceUtils.sum(
              [acc.remittedAmount, cur.remittedAmount],
              currency
            ),
          };
        },
        {
          requiredAmount: { currency },
          unremittedAmount: { currency },
          remittedAmount: { currency },
        }
      );
  }, [
    formik.values.bankRemittanceReceiptDetailList,
    formik.values.receiver,
    formik.values.sender,
    getPayment,
  ]);

  return (
    <AppPanel title={tTitle("common:invoice_figures")}>
      <Grid
        gridTemplateColumns={"repeat(4, 1fr)"}
        columnGap={"8px"}
        rowGap={"4px"}
        fontSize={"12px"}
      >
        <Box>{tTitle("payment")}</Box>
        <Box>{tTitle("common:required_amount")}</Box>
        <Box>{tTitle("common:unremitted_amount")}</Box>
        <Box>{tTitle("common:remitted_amount")}</Box>

        {formik.values.bankRemittanceReceiptDetailList.map((detail, index) => {
          const payment = getPayment(detail);
          return (
            <React.Fragment key={`detail_${index}`}>
              <Box>
                <AppSelect
                  width={"100%"}
                  name={tTitle("common:payment")}
                  idFunc={(option) => option.id}
                  options={getOptions(detail)}
                  value={detail.payment}
                  isReadOnly={!isEditing}
                  isDisabled={isEditing && formik.values.id !== -1}
                  onSelect={(value) => {
                    formik.setValues((prev) => {
                      const next = {
                        ...prev,
                        bankRemittanceReceiptDetailList:
                          prev.bankRemittanceReceiptDetailList.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                payment: value,
                              };
                            }
                            return d;
                          }),
                      };
                      return next;
                    });
                  }}
                />
              </Box>
              <Box>
                <AppPriceInput
                  value={payment?.amount}
                  isReadOnly={true}
                  isDisabled={isEditing}
                />
              </Box>
              <Box>
                <AppPriceInput
                  value={payment?.unremittedAmount}
                  isReadOnly={true}
                  isDisabled={isEditing}
                />
              </Box>
              <Box>
                <AppPriceInput
                  value={detail.amount}
                  isReadOnly={!isEditing}
                  isDisabled={isEditing && formik.values.id !== -1}
                  onChange={(value) => {
                    formik.setValues((prev) => {
                      const currency = InvoiceUtils.getCurrencyByParties(prev);

                      const next = {
                        ...prev,
                        bankRemittanceReceiptDetailList:
                          prev.bankRemittanceReceiptDetailList.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                amount: {
                                  ...value,
                                  currency,
                                },
                              };
                            }
                            return d;
                          }),
                      };

                      next.amount = PriceUtils.sum(
                        next.bankRemittanceReceiptDetailList.map(
                          (d) => d.amount
                        ),
                        currency
                      );

                      return next;
                    });
                  }}
                />
              </Box>
            </React.Fragment>
          );
        })}
        <Box></Box>
        <Box>{tTitle("common:total_remittance_required_amount.abbr")}</Box>
        <Box>{tTitle("common:total_unremitted_amount")}</Box>
        <Box>{tTitle("common:total_remitted_amount.abbr")}</Box>

        <Box></Box>
        <Box>
          <AppPriceInput value={total.requiredAmount} isReadOnly={true} />
        </Box>
        <Box>
          <AppPriceInput value={total.unremittedAmount} isReadOnly={true} />
        </Box>
        <Box>
          <AppPriceInput value={total.remittedAmount} isReadOnly={true} />
        </Box>
      </Grid>
    </AppPanel>
  );
}
