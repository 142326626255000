import { Text } from "@chakra-ui/react";
import { toLower } from "@/utils/case";
import { useTranslation } from "react-i18next";
import { OrderSheetStatus } from "@/features/order-sheets/order-sheet.type";

export interface ChangeOrderSheetStatusConfirmMessageProps {
  status?: OrderSheetStatus;
}
export default function ChangeOrderSheetStatusConfirmMessage({
  status,
}: ChangeOrderSheetStatusConfirmMessageProps) {
  const { t } = useTranslation();

  if (status) {
    const text = t("change_status_modal.message", {
      ns: "order_sheet_sets",
    });

    const i = text.indexOf("[");
    const j = text.indexOf("]");

    if (i > -1 && j > -1) {
      return (
        <>
          {text.substring(0, i)}
          <Text as={"b"}>[{t(`order_sheet.status.${toLower(status)}`)}]</Text>
          {text.substring(j + 1)}
        </>
      );
    }
  }

  return null;
}
