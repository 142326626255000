import {
  BoxProps,
  Button,
  ButtonProps,
  ChakraProps,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { MouseEventHandler, ReactElement, ReactNode } from "react";
import { produce } from "immer";
import { merge } from "lodash";

function calcVariantStyle({
  variant,
  isActive,
  isDisabled,
}: {
  variant: AppButtonVariant;
  isActive: boolean;
  isDisabled: boolean;
}): ChakraProps {
  if (variant === "normal") {
    if (isDisabled) {
      return {
        color: "#8F8F8C",
        cursor: "not-allowed",
        border: "0.5px solid var(--gray-groomy, #8F8F8C)",
        background: "var(--gray-light, #EFEFED)",
        boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: "4px",
        _hover: {},
      };
    } else if (isActive) {
      return {
        color: "#005AAD",
        border: "0.5px solid #005AAD",
        background: "#FCFCFB",
        boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.2)",
        _hover: {},
      };
    } else {
      return {
        color: "#444440",
        border: "0.5px solid #8F8F8C",
        background: "#FCFCFB",
        boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.1)",
        _hover: {
          color: "#1272EF",
          border: "0.5px solid #1272EF",
          background: "#FCFCFB",
          boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.2)",
        },
      };
    }
  } else if (variant === "gray") {
    if (isDisabled) {
      return {
        color: "#D4D4D1",
        cursor: "not-allowed",
        backgroundColor: "#F5F5F5",
        _hover: {},
      };
    } else if (isActive) {
      return {
        _hover: {},
      };
    } else {
      return {
        color: "#FFFFFF",
        background: "#444440",
        _hover: {
          background: "#22221F",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        },
      };
    }
  } else if (variant === "primary") {
    if (isDisabled) {
      return {
        color: "#D4D4D1",
        cursor: "not-allowed",
        border: "0.5px solid var(--gray-thick, #6F6F6C)",
        background: "var(--gray-groomy, #8F8F8C)",
        _hover: {},
      };
    } else if (isActive) {
      return {
        color: "#D4D4D1",
        border: "0.5px solid #00417D",
        boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        _hover: {},
      };
    } else {
      return {
        color: "#FFFFFF",
        border: "0.5px solid #005AAD",
        background: "#1272EF",
        boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.15)",
        _hover: {
          color: "#FFFFFF",
          border: "0.5px solid #00417D",
          background: "#005AAD",
          boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        },
      };
    }
  } else if (variant === "ghost") {
    if (isDisabled) {
      return {
        color: "#8F8F8C",
        cursor: "not-allowed",
        _hover: {},
      };
    } else if (isActive) {
      return {
        color: "#1272EF",
        borderRadius: "2px",
        border: "0.5px solid #1272EF",
        background: "#FFF",
        _hover: {},
      };
    } else {
      return {
        color: "#8F8F8C",
        border: "0.5px solid transparent",
        _hover: {
          color: "#22221F",
          border: "0.5px solid #8F8F8C",
          borderRadius: "2px",
          background: "#FFF",
        },
      };
    }
  } else if (variant === "white") {
    if (isDisabled) {
      return {
        cursor: "default",
        borderRadius: "4px",
        border: "0.5px solid #8F8F8C",
        background: "#EFEFED",
        color: "#8F8F8C",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.05)",
        _hover: {
          border: "0.5px solid #444440",
          background: "#EFEFED",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
        },
      };
    } else if (isActive) {
      return {
        borderRadius: "4px",
        border: "0.5px solid #1272EF",
        background: "#F7F9FA",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
        _hover: undefined,
      };
    } else {
      return {
        borderRadius: "4px",
        border: "0.5px solid #8F8F8C",
        background: "#FFF",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        _hover: {
          border: "0.5px solid #1272EF",
          background: "#F7F9FA",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
        },
      };
    }
  }
  return {};
}

function calcSizeStyle(size: AppButtonSize) {
  if (size === "medium") {
    return {
      p: "4px 32px",
      fontSize: "12px",
    };
  } else if (size === "small") {
    return {
      p: "4px 12px",
      fontSize: "10px",
    };
  } else if (size === "large") {
    return {
      p: "4px 48px",
      fontSize: "14px",
    };
  }
}

type AppButtonSize = "medium" | "small" | "large";
type AppButtonVariant = "gray" | "primary" | "normal" | "ghost" | "white";

export interface AppButtonProps {
  variant?: AppButtonVariant;
  size?: AppButtonSize;
  isBold?: boolean;
  children?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  chakraProps?: ButtonProps;
  type?: "submit" | "reset" | "button" | undefined;
  width?: string;
  height?: string;
}

export default function AppButton({
  variant = "normal",
  size = "medium",
  isBold = false,
  isActive = false,
  isDisabled = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  chakraProps,
  children,
  isLoading = false,
  type = "button",
  width,
  height = "28px",
  disabledMessage,
}: AppButtonProps) {
  let sizeStyle = calcSizeStyle(size);
  let variantStyle = calcVariantStyle({ variant, isActive, isDisabled });
  const style = merge({}, variantStyle, chakraProps);

  let boldStyle = {};
  if (isBold) {
    boldStyle = {
      fontWeight: "700",
    };
  } else {
    boldStyle = {
      fontWeight: "350",
    };
  }

  return (
    <Tooltip
      label={isDisabled && disabledMessage ? disabledMessage : undefined}
      placement={"top-start"}
      hasArrow={true}
      color={"#4E0000"}
      fontSize={"12px"}
      borderRadius={"4px"}
      border={"0.5px solid var(--danger, #EE7D7A)"}
      background={"var(--danger-light, #FFC9C9)"}
      boxShadow={"0px 4px 16px 0px rgba(0, 0, 0, 0.20)"}
    >
      <Button
        variant={"ghost"}
        borderRadius={"4px"}
        height={height}
        onClick={(event) => {
          if (onClick && !isLoading && !isDisabled) {
            onClick(event);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type={type}
        {...sizeStyle}
        {...boldStyle}
        {...style}
        width={width}
      >
        {isLoading ? <Spinner size={"sm"} /> : children}
      </Button>
    </Tooltip>
  );
}
