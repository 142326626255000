import { SWRConfiguration, SWRResponse } from "swr";
import { useCallback, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

export default function useWithSWROnDemand<Parameter, Request, Response>(
  myHook: (
    parameter?: Parameter,
    request?: Request,
    config?: SWRConfiguration<Response>
  ) => SWRResponse<Response>
) {
  const promiseRef = useRef<{
    resolve: (value: Response) => void;
    reject: (reason: any) => void;
  }>();
  const [key, setKey] = useState<{
    parameter: Parameter;
    request: Request;
    config?: SWRConfiguration<Response>;
  }>();

  const { mutate, data, error, ...rest } = myHook(
    key?.parameter,
    key?.request,
    key?.config
  );

  useEffect(() => {
    if (promiseRef.current) {
      const { resolve, reject } = promiseRef.current;
      if (data) {
        resolve(data);
      } else if (error) {
        // reject(error);
      }
    }
  }, [data, error]);

  const fire = useCallback(
    (
      parameter: Parameter,
      request: Request,
      config: SWRConfiguration<Response> = {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        //이걸 하면 에러가 나네;
        // revalidateOnMount: false,
      }
    ): Promise<Response> => {
      if (key && isEqual(key, { parameter, request, config })) {
        return new Promise((resolve, reject) => {
          mutate().then((result) => {
            resolve(result!);
          }, reject);
        });
      } else {
        return new Promise((resolve, reject) => {
          promiseRef.current = { resolve, reject };
          //같은걸 조회또누를수가 있겟구나...
          setKey((prev) => {
            //setState내에서 mutate 안됌
            // if (prev && isEqual(prev, { parameter, request, config })) {
            //   mutate().then((result) => {
            //     resolve(result!);
            //   }, reject);
            //   return prev;
            // }
            return { parameter, request, config };
          });
        });
      }
    },
    [key, mutate]
  );

  return {
    fire,
    data,
    error,
    mutate,
    ...rest,
    // isLoading: swrResponse.isLoading || swrResponse.isValidating,
  };
}
