import { DensePayment } from "@/features/invoices/payment/payment.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { CompanyType } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import useGetDensePaymentFetcher from "@/features/invoices/hooks/use-get-dense-payment-fetcher";

export interface GetDensePaymentRequest {}

export interface GetDensePaymentResponse extends DensePayment {}

export interface GetDensePaymentPathParameter {
  by: CompanyType;
  companyId: number;
  paymentId: number;
}

export const getDensePaymentTemplateUrl = `/:by/:companyId/dense-payments/:paymentId`;

export function getGetDensePaymentKey(
  parameter?: GetDensePaymentPathParameter,
  request?: GetDensePaymentRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDensePaymentTemplateUrl, parameter),
      params: request,
    };
  }

  return null;
}

export function parseGetDensePaymentKey(
  key: AxiosRequestConfig<GetDensePaymentRequest>
) {
  const urlParse = new UrlParser<GetDensePaymentPathParameter>(
    getDensePaymentTemplateUrl,

    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      paymentId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParse.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetDensePayment(
  parameter?: GetDensePaymentPathParameter,
  request?: GetDensePaymentRequest,
  config?: SWRConfiguration<GetDensePaymentResponse>
) {
  const fetcher = useGetDensePaymentFetcher();
  return useSWR(getGetDensePaymentKey(parameter, request), fetcher, config);
}
