import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";
import {
  reparseLineSheetSetKey,
  ReparseLineSheetSetRequest,
  ReparseLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set";

export default function useReparseLineSheetSetInfinite() {
  const fetcher = useFetcher<
    ReparseLineSheetSetRequest,
    ReparseLineSheetSetResponse
  >();
  return useYWRInfinite(reparseLineSheetSetKey, fetcher);
}
