import useFetcher from "@/utils/use-fetcher";
import {
  getListOrderSheetSetsKey,
  ListOrderSheetSetsRequest,
  ListOrderSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";
import {
  getListLineSheetSetsKey,
  ListLineSheetSetsRequest,
  ListLineSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDenseLineSheetSetsRequest,
  ListDenseLineSheetSetsResponse,
  parseListDenseLineSheetSetsKey,
} from "@/features/line-sheet-sets/hooks/use-list-dense-line-sheet-sets";
import { uniq } from "lodash";
import { DenseLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";

export default function useListDenseLineSheetSetsFetcher() {
  const listOrderSheetSetsFetcher = useFetcher<
    ListOrderSheetSetsRequest,
    ListOrderSheetSetsResponse
  >();

  const listLineSheetSetsFetcher = useFetcher<
    ListLineSheetSetsRequest,
    ListLineSheetSetsResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseLineSheetSetsRequest>
    ): Promise<ListDenseLineSheetSetsResponse> => {
      const { parameter, request } = parseListDenseLineSheetSetsKey(config);
      const listLineSheetSetsResponse = await listLineSheetSetsFetcher(
        getListLineSheetSetsKey(parameter, request)!
      );

      if (listLineSheetSetsResponse.lineSheetSets.length > 0) {
        const lineSheetSetIds = uniq(
          listLineSheetSetsResponse.lineSheetSets.map((i) => i.id)
        );

        const listOrderSheetSetsResponse = await listOrderSheetSetsFetcher(
          getListOrderSheetSetsKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
            },
            {
              "lineSheetSet.id__in": lineSheetSetIds,
              pageNumber: 1,
              pageSize: lineSheetSetIds.length,
            }
          )!
        );

        return Promise.resolve({
          totalCount: listLineSheetSetsResponse.totalCount,
          lineSheetSets: listLineSheetSetsResponse.lineSheetSets.map(
            (lineSheetSet) => {
              const { lineSheets: _lineSheets, ...rest } = lineSheetSet;
              const orderSheetSet =
                listOrderSheetSetsResponse.orderSheetSets.find(
                  (orderSheetSet) => {
                    return orderSheetSet.lineSheetSet.id === lineSheetSet.id;
                  }
                );

              let lineSheets: DenseLineSheet[] = [];

              if (orderSheetSet) {
                lineSheets = _lineSheets.map((_lineSheet): DenseLineSheet => {
                  const orderSheets = orderSheetSet.orderSheets.filter(
                    (orderSheet) => {
                      return orderSheet.lineSheet.id === _lineSheet.id;
                    }
                  );
                  return {
                    ..._lineSheet,
                    orderSheets,
                  };
                });
              } else {
                lineSheets = _lineSheets.map((_lineSheet) => {
                  return {
                    ..._lineSheet,
                    orderSheets: [],
                  };
                });
              }

              return {
                ...rest,
                lineSheets,
                orderSheetSet,
              };
            }
          ),
        });
      } else {
        return Promise.resolve({
          totalCount: 0,
          lineSheetSets: [],
        });
      }
    };
  }, [listLineSheetSetsFetcher, listOrderSheetSetsFetcher]);
}
