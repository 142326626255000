import useBulkCreateProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-bulk-create-proforma-invoices";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isFile, StoredObject } from "@/features/types";
import { useCallback } from "react";
import useUpdateProformaInvoice, {
  updateProformaInvoiceKey,
  UpdateProformaInvoicePathParameter,
  UpdateProformaInvoiceRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-update-proforma-invoice";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import {
  createProformaInvoiceKey,
  CreateProformaInvoicePathParameter,
  CreateProformaInvoiceRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-create-proforma-invoice";
import useCreateProformaInvoiceInfinite from "@/features/invoices/proforma-invoices/hooks/use-create-proforma-invoice-infinite";

function mutateProformaInvoices(proformaInvoiceId?: number) {
  if (proformaInvoiceId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers|boutiques)\/\d+\/dense-proforma-invoices\/${proformaInvoiceId}/`
      )
    );
  }
  mutateIfMatched(
    /\/(agencies|buyers|boutiques)\/\d+\/dense-proforma-invoices/
  );
}

export function useProformaInvoiceDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { fire: fireUpdateProformaInvoice } = useUpdateProformaInvoice();
  const { fire: fireCreateProformaInvoice } =
    useCreateProformaInvoiceInfinite();

  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const addCreate = useCallback(
    async (
      parameter: CreateProformaInvoicePathParameter,
      request: Omit<CreateProformaInvoiceRequest, "file" | "attachments"> & {
        file: File | Pick<StoredObject, "id" | "name">;
        attachments: (File | Pick<StoredObject, "id" | "name">)[];
      }
    ) => {
      addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          let files: File[] = [];
          if (isFile(request.file)) {
            files = [request.file];
          }

          files = [...files, ...request.attachments.filter(isFile)];

          return files;
        },
        convertFunc: (source, storeObjectResponses) => {
          let i = 0;
          let request = {
            ...source,
          };

          if (isFile(request.file)) {
            request.file = storeObjectResponses[i++];
          }

          request.attachments = request.attachments.map((attachment) => {
            if (isFile(attachment)) {
              return storeObjectResponses[i++];
            }
            return attachment;
          });

          return request as CreateProformaInvoiceRequest;
        },
        fire: fireCreateProformaInvoice,
        keyOrFunc: calcYWRUrl(createProformaInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateProformaInvoices();
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreateProformaInvoice, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdateProformaInvoicePathParameter,
      request: Omit<UpdateProformaInvoiceRequest, "file" | "attachments"> & {
        file: File | Pick<StoredObject, "id" | "name">;
        attachments: (File | Pick<StoredObject, "id" | "name">)[];
      }
    ) => {
      return addDumbTransactions({
        parameter: parameter,
        source: request,
        getFilesFunc: (request) => {
          let files: File[] = [];
          if (isFile(request.file)) {
            files = [request.file];
          }

          files = [...files, ...request.attachments.filter(isFile)];

          return files;
        },
        convertFunc: (source, storeObjectResponses) => {
          let i = 0;
          let request = {
            ...source,
          };

          if (isFile(request.file)) {
            request.file = storeObjectResponses[i++];
          }

          request.attachments = request.attachments.map((attachment) => {
            if (isFile(attachment)) {
              return storeObjectResponses[i++];
            }
            return attachment;
          });

          return request as UpdateProformaInvoiceRequest;
        },
        fire: fireUpdateProformaInvoice,
        keyOrFunc: calcYWRUrl(updateProformaInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateProformaInvoices(parameter.proformaInvoiceId);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireUpdateProformaInvoice, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateProformaInvoicePathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateProformaInvoiceKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate,
    addUpdate,
    isUpdateQueued,
  };
}
