import {
  Company,
  CompanyType,
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { getUrlPrefixByIdentityParameter } from "@/features/ui/helpers/zwr-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { BankInfo } from "@/features/invoices/triangular-trades/triangular-trade.type";
//
// export interface ListBankInfoListWithoutPagingRequest {
//   type__in?: CompanyType[];
// }

export interface ListBankInfoListRequest extends PageNumberBasedListRequest {}

export interface ListBankInfoListResponse extends PageNumberBasedListResponse {
  bankInfoList: BankInfo[];
}

export interface ListBankInfoListPathParameter extends IdentityParameter {}

export function getListBankInfoListPathParameter(
  identity: Identity | null
): ListBankInfoListPathParameter | null {
  return toIdentityParameter(identity);
}

export function getListBankInfoListKey(
  identityOrParameter?: Identity | ListBankInfoListPathParameter | null,
  request?: ListBankInfoListRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListBankInfoListPathParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    const prefix = getUrlPrefixByIdentityParameter(parameter);
    return {
      url: `${prefix}/bank_info`,
      params: request,
    };
  }
  return null;
}

export default function useListBankInfoList(
  parameter?: ListBankInfoListPathParameter | null,
  request?: ListBankInfoListRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListBankInfoListRequest,
    ListBankInfoListResponse
  >();
  return useSWR(getListBankInfoListKey(parameter, request), fetcher, config);
}
