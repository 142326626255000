import { Payment } from "@/features/invoices/payment/payment.type";
import { CompanyType } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadPaymentRequest {}

export interface ReadPaymentResponse extends Payment {}

export interface ReadPaymentPathParameter {
  by: CompanyType;
  companyId: number;
  paymentId: number;
}

export const readPaymentTemplateUrl =
  "/:by/:companyId/payments/:paymentId/read";

export const readPaymentKey: YWRKey<ReadPaymentPathParameter> = {
  url: (parameter: ReadPaymentPathParameter) => {
    return calcUrl(readPaymentTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReadPaymentKey(
  config: AxiosRequestConfig<ReadPaymentRequest>
) {
  const urlParser = new UrlParser<ReadPaymentPathParameter>(
    readPaymentTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      paymentId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(config.url!!),
    method: readPaymentKey.method,
  };
}

export function useReadPayment() {
  const fetcher = useFetcher<ReadPaymentRequest, ReadPaymentResponse>();
  return useYWR(readPaymentKey, fetcher);
}
