import { WritableDraft } from "immer/src/types/types-external";
import { produce } from "immer";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export type GridRowType =
  | "LineSheetSet"
  | "LineSheet"
  | "OrderSheetSet"
  | "OrderSheet"
  | "Attachment"
  | "Invoice"
  | "LinkedInvoice"
  | "OrderConfirmationInvoice"
  | "ProformaInvoice"
  | "EarningProformaInvoice"
  | "UsingProformaInvoice"
  | "CreditInvoice"
  | "EarnedCreditInvoice"
  | "UsedCreditInvoice"
  | "DepositInvoice"
  | "BankRemittanceReceipt"
  | "PublicComment"
  | "PrivateComment"
  | "InvoiceFigures"
  | "BalanceTransaction"
  | "Inventory"
  | "Gmail"
  | "LineSheetOrAttachment"
  | "Transportation"
  | "Payment"
  | "TriangularTrade";

export interface GridRow {
  _rowId: string;
  _rowType: GridRowType;
  id: number;
  isChecked: boolean;
  isCheckable: boolean;
  isCheckableOriginal?: boolean;
  isCollapsed: boolean;
  isHovered: boolean;
  isActive: boolean;
  isTooltipOpen?: boolean;
  children: GridRow[];
  isRead?: boolean;
}

export function gridRowFactory(
  parentRow: GridRow | null,
  rowType: GridRowType,
  resource: { id: number }
): GridRow {
  return {
    _rowId:
      (parentRow ? parentRow._rowId + "-" : "") + `${rowType}__${resource.id}`,
    _rowType: rowType,
    id: resource.id,
    isChecked: false,
    isCheckable: false,
    isCheckableOriginal: false,
    isCollapsed: true,
    isHovered: false,
    isActive: false,
    children: [],
  };
}

export function findDirectChildGridRow(
  candidate: GridRow | GridRow[] | undefined,
  type: GridRowType,
  resource: { id: number }
) {
  if (candidate) {
    let items: GridRow[] = [];
    if (Array.isArray(candidate)) {
      items = candidate;
    } else {
      items = candidate.children;
    }
    return items.find((c) => c._rowType === type && c.id === resource.id);
  }
}

export function findGridRowByRowId(rows: GridRow[], rowId: string) {
  let candidates = rows;
  const items = rowId.split("-");
  for (let i = 0; i < items.length; i++) {
    const [rowType, rawId] = items[i].split("__");
    const id = parseInt(rawId);
    const candidate = candidates.find((c) => {
      return c._rowType === rowType && c.id === id;
    });

    if (candidate) {
      if (i === items.length - 1) {
        return candidate;
      }
      candidates = candidate.children;
    } else {
      break;
    }
  }
}

export function findParentGridRow(row: GridRow, roots: GridRow[] = []) {
  let candidates = roots;
  let paths = row._rowId.split("-");
  paths = paths.slice(0, -1);
  let suffix = "";
  for (let i = 0; i < paths.length; i++) {
    const rowId = suffix + paths[i];
    const candidate = candidates.find((c) => c._rowId === rowId);
    suffix = rowId + "-";
    if (!candidate) {
      return undefined;
    } else if (i < paths.length - 1) {
      candidates = candidate.children;
    } else {
      return candidate;
    }
  }
}

export function findGridRowByPath(
  path: number[],
  draft: WritableDraft<GridRow[] | undefined>
) {
  if (draft) {
    let cursor: WritableDraft<GridRow[]> = draft;
    if (path.length > 1) {
      cursor = draft[path[0]].children!!;
      for (let i = 1; i < path.length - 1; i++) {
        cursor = cursor[path[i]].children!!;
      }
    }
    return cursor[path[path.length - 1]];
  }
}

export function findPathOfGridRow(
  rows: GridRow[],
  type: GridRowType,
  id: number,
  path: number[] = []
): number[] | null {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (row._rowType === type && row.id === id) {
      return [...path, i];
    }

    const foundInChildren = findPathOfGridRow(row.children, type, id, [
      ...path,
      i,
    ]);

    if (foundInChildren) {
      return foundInChildren;
    }
  }

  return null;
}

export function findPathOfGridRowByFind<T extends GridRow>(
  rows: T[],
  find: (row: T) => boolean,
  path: number[] = []
): number[] | null {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (find(row)) {
      return [...path, i];
    }

    const foundInChildren = findPathOfGridRowByFind(row.children as T[], find, [
      ...path,
      i,
    ]);

    if (foundInChildren) {
      return foundInChildren;
    }
  }
  return null;
}

export function getGridRowChildByType<T>(
  parent: GridRow,
  type: GridRowType
): T | undefined {
  return parent.children.find((c) => c._rowType === type) as T | undefined;
}

export function getGridRowChildrenByType<T>(
  parent: GridRow,
  type: GridRowType
): T[] {
  return parent.children.filter((c) => c._rowType === type) as T[];
}

export function getDescendantsByType<T>(
  parent: GridRow,
  type: GridRowType
): T[] {
  return parent.children.reduce<T[]>((acc, c) => {
    if (c._rowType === type) {
      acc.push(c as T);
    }
    return acc.concat(getDescendantsByType(c, type));
  }, []);
}

export function gridRowUpdater<T extends GridRow, R extends GridRow>(
  rows: T[] | undefined,
  resourceId: number,
  rowType: GridRowType,
  func: (prev: R) => R
): T[] | undefined {
  return produce(rows, (draft) => {
    if (draft && rows) {
      const path = findPathOfGridRow(rows, rowType, resourceId);
      if (path) {
        let cursor: WritableDraft<GridRow[]> = draft;
        for (let i = 0; i < path.length - 1; i++) {
          cursor = cursor[path[i]].children;
        }

        cursor[path[path.length - 1]] = func(
          cursor[path[path.length - 1]] as R
        );
      }
    }
  });
}

export function gridRowUpdaterWithMapper<
  T extends GridRow,
  R extends { id: number }
>(rows: T[] | undefined, resource: R, mapper: GridMapper<R, any>) {
  return produce(rows, (draft) => {
    if (rows && draft) {
      const path = findPathOfGridRow(rows, mapper.type, resource.id);
      if (path) {
        let cursor: WritableDraft<GridRow[]> = draft;
        let parent;

        for (let i = 0; i < path.length - 1; i++) {
          if (i === path.length - 2) {
            parent = cursor[path[i]];
          }

          cursor = cursor[path[i]].children;
        }

        cursor[path[path.length - 1]] = mapper.toRow(
          resource,
          cursor[path[path.length - 1]],
          parent
        );
      }
    }
  });
}

export function gridRowsUpdater<T extends GridRow, R>(
  rows: T[],
  resources: R[],
  withFind: (resource: R) => (row: T) => boolean,
  withUpdater: (resource: R) => (row: T) => T
): T[] {
  return produce(rows, (draft) => {
    resources.forEach((resource) => {
      const find = withFind(resource);
      const updater = withUpdater(resource);
      const path = findPathOfGridRowByFind(rows, find);
      if (path) {
        let cursor: WritableDraft<GridRow[]> = draft;
        for (let i = 0; i < path.length - 1; i++) {
          cursor = cursor[path[i]].children;
        }

        cursor[path[path.length - 1]] = updater(
          cursor[path[path.length - 1]] as T
        );
      }
    });
  });
}
