import { useEffect } from "react";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

export default function RedirectTo500WithRefreshing() {
  // @ts-ignore
  //window.isHandledByErrorBoundary = true;
  const { navigate } = useRouterHelper();
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      navigate("/500");
    } else {
      window.location.href = "/500";
    }
  }, [navigate]);

  return null;
}
