import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import {
  deleteOrderSheetUploadedFileKey,
  DeleteOrderSheetUploadedFileRequest,
  DeleteOrderSheetUploadedFileResponse,
} from "@/features/order-sheet-sets/hooks/use-delete-order-sheet-uploaded-file";
import useFetcher from "@/utils/use-fetcher";

export default function useDeleteOrderSheetUploadedFileInfinite() {
  const fetcher = useFetcher<
    DeleteOrderSheetUploadedFileRequest,
    DeleteOrderSheetUploadedFileResponse
  >();
  return useYWRInfinite(deleteOrderSheetUploadedFileKey, fetcher);
}
