import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormControl, FormLabel, Grid, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import { FtaSignature } from "@/features/invoices/triangular-trades/triangular-trade.type";
import enLocale from "i18n-iso-countries/langs/en.json";
import { isFile, StoredObject } from "@/features/types";
import AppFileController from "@/features/inventories/app-file-controller";
import { useFtaSignatureDumbTransaction } from "@/features/invoices/triangular-trades/hooks/use-fta-signature-dumb-transaction";
import AppTextArea from "@/features/ui/app-text-area";

export interface CreateFtaSignatureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (fta_signature: FtaSignature) => void;
}

interface FormFields {
  alias: string;
  emphasizedFtaCode: string;
  ftaCode: string;
  signatureFile?: Pick<StoredObject, "id" | "name"> | File;
}

export default function CreateFtaSignatureModal({
  isOpen,
  onClose,
  onCreate,
}: CreateFtaSignatureModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "common",
    prefix: "create_fta_signature_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      alias: Yup.string().required(withMessage.required),
      signatureFile: Yup.mixed<
        File | Pick<StoredObject, "id" | "name">
      >().required(withMessage.required),
    });
  }, [withMessage.required]);

  async function validate(values: FormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const { addCreate } = useFtaSignatureDumbTransaction();

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company && isFile(values.signatureFile!)) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity?.company?.id,
          },
          {
            alias: values.alias,
            emphasizedFtaCode: values.emphasizedFtaCode,
            ftaCode: values.ftaCode,
            signatureFile: values.signatureFile!,
          }
        );
      }

      onClose();
    },
    [addCreate, identity, onClose]
  );

  const options = useMemo(() => {
    if (!enLocale.countries) {
      return [];
    }
    return Object.entries(enLocale.countries).map(([key, value]) => ({
      name: t(`countries\:${key}`),
      value: key,
    }));
  }, [t]);
  options.sort((a, b) => a.name.localeCompare(b.name));
  options.sort((a, b) => {
    if (a.value === "KR") {
      return -1;
    }
    if (b.value === "KR") {
      return 1;
    }
    if (a.value === "IT") {
      return -1;
    }
    if (b.value === "IT") {
      return 1;
    }
    return 0;
  });

  return (
    <Formik<FormFields>
      initialValues={{
        alias: "",
        emphasizedFtaCode: "",
        ftaCode: "",
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={"create_fta_signature_form.title"}
        submitName={"add.do"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text fontSize={"12px"}>{t("create_departure_form.message")}</Text>

        <Form>
          <Grid
            templateColumns={"1fr"}
            templateRows={"64px 96px 96px 150px"}
            gap={"24px"}
          >
            <Field name="alias">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("alias")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("alias"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="emphasizedFtaCode">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("emphasized_fta_code")}
                    </FormLabel>
                    <AppTextArea
                      isResizeable={false}
                      flexGrow={"1"}
                      minHeight={"0"}
                      fontSize={"12px"}
                      rows={4}
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("emphasized_fta_code"),
                        }
                      )}
                      value={props.field.value || ""}
                      onChange={(e) => {
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="ftaCode">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("fta_code")}
                    </FormLabel>
                    <AppTextArea
                      isResizeable={false}
                      flexGrow={"1"}
                      minHeight={"0"}
                      fontSize={"12px"}
                      rows={4}
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("fta_code"),
                        }
                      )}
                      value={props.field.value || ""}
                      onChange={(e) => {
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"signatureFile"}>
              {(props: FieldProps<FormFields["signatureFile"], FormFields>) => {
                return (
                  <AppFileController
                    isMultiple={false}
                    value={props.field.value ? [props.field.value] : []}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value[0]);
                    }}
                  />
                );
              }}
            </Field>
          </Grid>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
