import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useCallback, useEffect, useMemo } from "react";
import { AppGridMeta } from "@/features/order-sheet-sets/app-grid";
import { GmailRow } from "@/features/gmails/gmail.types";
import useBulkCreateLineSheetSetInfinite from "@/features/gmails/hooks/use-create-line-sheet-set-infinite";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import { reportLineSheetKey } from "@/features/line-sheet-sets/hooks/use-report-line-sheet";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useFetchLineSheetSetsFromEmail from "@/features/line-sheet-sets/hooks/use-fetch-line-sheet-sets-from-email";

interface UseInvoiceGridProps {
  rows?: GridRow[];
  mutateRows: () => void;
}
export default function useGmailGrid({
  rows,
  mutateRows,
}: UseInvoiceGridProps) {
  const appGrid = useAppGrid();
  const { error: showError } = useAppToasts();
  const { t, tTitle, tLocalDateString, tLocalDateTimeString } = useI18nHelper();

  const {
    data: dataBulkCreateLineSheetSet,
    fire: fireBulkCreateLineSheetSet,
    clear: clearBulkCreateLineSheet,
  } = useBulkCreateLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      dataBulkCreateLineSheetSet,
      clearBulkCreateLineSheet,
      showError,
      mutateRows
    );
  }, [
    dataBulkCreateLineSheetSet,
    clearBulkCreateLineSheet,
    showError,
    mutateRows,
  ]);

  const {
    data: fetchLineSheetSetsFromEmailData,
    fire: fireFetchLineSheetSetsFromEmail,
    error: fetchLineSheetSetsFromEmailError,
  } = useFetchLineSheetSetsFromEmail();

  const withGmail = useCallback(
    (isBuyer: boolean = true): AppGridMeta => {
      return {
        rowType: "Gmail",
        columns: [
          {
            name: "수집일",
            width: "160px",
          },
          {
            name: "Gmail ID",
            width: "160px",
          },
          {
            name: tTitle("from"),
            width: "160px",
          },
          {
            name: tTitle("title"),
            width: "1fr",
          },
          {
            name: "라벨",
            width: "200px",
          },
          {
            name: "메일 수신일",
            width: "160px",
          },
          {
            name: "라인시트 생성 결과",
            width: "160px",
          },
        ],
        fields: [
          {
            name: "creadtedAt",
            chakraProps: (row) => ({
              fontWeight:
                new Date((row as GmailRow).createdAt).toDateString() ===
                new Date().toDateString()
                  ? "bold"
                  : "normal",
            }),
            value: (row) => {
              const gmailRow = row as GmailRow;
              if (
                new Date(gmailRow.createdAt).toDateString() ===
                new Date().toDateString()
              ) {
                return "오늘";
              }
              return tLocalDateString(gmailRow.createdAt);
            },
          },
          {
            name: "ID",
            value: (row) => {
              const gmailRow = row as GmailRow;
              return gmailRow.gmailId;
            },
          },
          {
            name: "from",
            chakraProps: (row) => ({
              color:
                (row as GmailRow).lineSheetSet.id === -1
                  ? "red.300"
                  : (row as GmailRow).lineSheetSet.id === -2
                  ? "blue.300"
                  : "black",
            }),
            value: (row) => {
              const gmailRow = row as GmailRow;
              if (gmailRow.boutique) {
                return gmailRow.boutique.name;
              } else {
                const senderName = gmailRow.sender
                  .match(/([^<]+)<[^>]+>/)?.[1]
                  ?.replace(/"/g, "");
                const senderEmail = gmailRow.sender.match(/<([^>]+)>/)?.[1];
                return (
                  <>
                    <div>{senderName}</div>
                    <div>{senderEmail}</div>
                  </>
                );
              }
            },
          },
          {
            name: "title",
            chakraProps: (row) => ({
              color:
                (row as GmailRow).lineSheetSet.id === -1
                  ? "red.300"
                  : (row as GmailRow).lineSheetSet.id === -2
                  ? "blue.300"
                  : "black",
            }),
            value: (row) => {
              const gmailRow = row as GmailRow;
              return gmailRow.title;
            },
          },
          {
            name: "labelNames",
            value: (row) => {
              const gmailRow = row as GmailRow;
              return gmailRow.labelNames.join(", ");
            },
          },
          {
            name: "receivedAt",
            value: (row) => {
              const gmailRow = row as GmailRow;
              return tLocalDateTimeString(gmailRow.receivedAt);
            },
          },
          {
            name: "lssResult",
            chakraProps: (row) => ({
              color:
                (row as GmailRow).lineSheetSet.id === -1
                  ? "red.300"
                  : (row as GmailRow).lineSheetSet.id === -2
                  ? "blue.300"
                  : "black",
            }),
            value: (row) => {
              const gmailRow = row as GmailRow;
              if (gmailRow.lineSheetSet.id === null) {
                return "";
              } else if (gmailRow.lineSheetSet.id > 0) {
                return gmailRow.lineSheetSet.id.toString();
              } else if (gmailRow.lineSheetSet.id === 0) {
                return "생성 중";
              } else if (gmailRow.lineSheetSet.id === -1) {
                return "알 수 없는 메일 주소";
              } else if (gmailRow.lineSheetSet.id === -2) {
                return "스킵 부티크";
              } else {
                return "X";
              }
            },
          },
        ],
        actions: [
          (_row: GridRow) => {
            const gmailRow = _row as GmailRow;
            return {
              name:
                gmailRow.lineSheetSet.id > 0
                  ? "라인시트 재생성"
                  : "라인시트 생성",
              isDisabled: false,
              isLoading: false,
              onClick: () => {
                fireBulkCreateLineSheetSet(
                  {},
                  {
                    gmailIds: [gmailRow.gmailId],
                    force: gmailRow.lineSheetSet.id > 0 ? true : false,
                  }
                );
              },
            };
          },
          (_row: GridRow) => {
            const gmailRow = _row as GmailRow;
            return {
              name: "Gmail 새로고침",
              isDisabled: false,
              isLoading: false,
              onClick: () => {
                fireFetchLineSheetSetsFromEmail(
                  {},
                  {
                    gmailId__in: [gmailRow.gmailId],
                    make_line_sheet_sets: false,
                    force: true,
                  }
                );
              },
            };
          },
        ],
      };
    },
    [t, tLocalDateString, tLocalDateTimeString, tTitle]
  );

  const metas = useMemo(() => {
    return {
      withGmail,
    };
  }, [withGmail]);

  return {
    metas,
  };
}
