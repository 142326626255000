import { CreditInvoiceFormFields } from "@/features/invoices/credit-invoices/upload-credit-invoice-page";
import { FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import BinarySplitView from "@/features/ui/binary-split-view";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import SimpleFigureFormControl from "@/features/invoices/deposit-invoices/simple-figure-form-control";
import CreditInvoiceInformationFormControl from "@/features/invoices/credit-invoices/credit-invoice-information-form-control";
import LinkedOrderConfirmationInvoicesFormControl from "@/features/invoices/proforma-invoices/linked-order-confirmation-invoices-form-control";
import { ListAllOrderConfirmationInvoicesRequest } from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import LinkedProformaInvoiceFormControl from "@/features/invoices/proforma-invoices/linked-proforma-invoice-form-control";
import { ListAllProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import IsolatedProformaInvoicePanel from "@/features/invoices/order-confirmation-invoices/isolated-proforma-invoice-panel";
import { AppUtils } from "@/features/ui/utils/app-utils";

interface CreditInvoiceFormProps {
  formik: FormikProps<CreditInvoiceFormFields>;
  isEditing?: boolean;
}

export default function CreditInvoiceForm({
  formik,
  isEditing,
}: CreditInvoiceFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listOrderConfirmationInvoicesRequest = useMemo(():
    | ListAllOrderConfirmationInvoicesRequest
    | undefined => {
    if (
      formik.values.type === "FIXING" &&
      formik.values.season &&
      formik.values.issuedBy &&
      formik.values.issuedThrough &&
      formik.values.orderedBy
    ) {
      return {
        season__eq: formik.values.season,
        "issuedByCompany.id__eq": formik.values.issuedBy.id,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        "issuedThroughCompany.id__eq": formik.values.issuedThrough.id,
        isRemittedToBoutique__eq: true,
        isPublic__eq: true,
        status__in: ["NORMAL", "COMPLETED"],
      };
    }
  }, [
    formik.values.type,
    formik.values.season,
    formik.values.issuedBy,
    formik.values.issuedThrough,
    formik.values.orderedBy,
  ]);

  const listProformaInvoicesRequest = useMemo(():
    | ListAllProformaInvoicesRequest
    | undefined => {
    if (
      formik.values.type === "FIXING" &&
      formik.values.season &&
      formik.values.issuedBy &&
      formik.values.orderedBy &&
      formik.values.issuedThrough
    ) {
      return {
        season__eq: formik.values.season,
        "issuedByCompany.id__eq": formik.values.issuedBy.id,
        "issuedToCompany.id__eq": formik.values.orderedBy.id,
        "issuedThroughCompany.id__eq": formik.values.issuedThrough.id,
        isRemittedToBoutique__eq: true,
        isPublic__eq: true,
        status__in: ["NORMAL", "COMPLETED"],
      };
    }
  }, [
    formik.values.type,
    formik.values.season,
    formik.values.issuedBy,
    formik.values.orderedBy,
    formik.values.issuedThrough,
  ]);

  const isDisabled = isEditing && formik.values.id !== -1;

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <CreditInvoiceInformationFormControl
              formik={formik}
              isEditing={isEditing}
            />
            <InvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            <SimpleFigureFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />

            <LinkedOrderConfirmationInvoicesFormControl
              formik={formik}
              request={listOrderConfirmationInvoicesRequest}
              isEditing={isEditing}
              isDisabled={isDisabled}
              onSelect={(value) => {
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    orderConfirmationInvoices: value,
                  };
                });
              }}
            />

            <LinkedProformaInvoiceFormControl
              formik={formik}
              request={listProformaInvoicesRequest}
              isEditing={isEditing}
              title={"earning_proforma_invoices"}
              fieldName={"earningProformaInvoices"}
              isDisabled={isDisabled}
              onSelect={(value) => {
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    earningProformaInvoices: value,
                  };
                });
              }}
            />

            {!isEditing && (
              <IsolatedProformaInvoicePanel
                title={"using_proforma_invoices"}
                proformaInvoiceIds={AppUtils.extractIds(
                  formik.values.usingProformaInvoices
                )}
              />
            )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={200}
      />
    </Box>
  );
}
