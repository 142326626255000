import { TriangularTrade } from "@/features/invoices/triangular-trades/triangular-trade.type";
import AppLabel, { AppLabelProps } from "@/features/ui/app-label";
import { toLower } from "@/utils/case";
import { useMemo } from "react";

interface TriangularTradeStatusLabelProps {
  value?: TriangularTrade["status"];
}

export default function TriangularTradeStatusLabel({
  value,
}: TriangularTradeStatusLabelProps) {
  const props = useMemo(() => {
    let level: AppLabelProps["level"];
    if (value) {
      switch (value) {
        case "COMPLETED":
          level = "SUCCESS";
          break;
        case "FAILED":
          level = "ERROR";
          break;
        default:
          level = "LIGHT";
          break;
      }
      return {
        nameKey: `triangular_trade.status.${toLower(value)}`,
        level,
      };
    }
  }, [value]);

  if (!props) {
    return null;
  } else {
    return <AppLabel {...props} size={"small"} />;
  }
}
