import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReparseLineSheetSetRequest {}
export interface ReparseLineSheetSetResponse {}
export interface ReparseLineSheetSetParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const reparseLineSheetSetTemplateUrl =
  "/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/reparse";

export const reparseLineSheetSetKey: YWRKey<ReparseLineSheetSetParameter> = {
  url: (parameter) => {
    return calcUrl(reparseLineSheetSetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReparseLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<ReparseLineSheetSetParameter>(
    reparseLineSheetSetTemplateUrl,
    {
      agencyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReparseLineSheetSet() {
  const fetcher = useFetcher<
    ReparseLineSheetSetRequest,
    ReparseLineSheetSetResponse
  >();
  return useYWR(reparseLineSheetSetKey, fetcher);
}
