import {
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface ListDepositInvoicesRequest extends PageNumberBasedListRequest {
  season__eq?: string;
  season__in?: string[];
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  "issuedByCompany.id__in"?: number[];
  issuedOn__eq?: Date;
  id__in?: number[];
}

export interface ListDepositInvoicesResponse
  extends PageNumberBasedListResponse {
  depositInvoices: DepositInvoice[];
}

export interface ListDepositInvoicesPathParameter extends IdentityParameter {}

export const listDepositInvoicesTemplateUrl = `/:by/:companyId/deposit-invoices`;

export function getListDepositInvoicesKey(
  parameter: ListDepositInvoicesPathParameter,
  request: ListDepositInvoicesRequest
) {
  return {
    url: calcUrl(listDepositInvoicesTemplateUrl, parameter),
    params: request,
  };
}

export const DEFAULT_LIST_CREDIT_INVOICES_PAGE_SIZE = 10;

export default function useListDepositInvoices(
  parameter: ListDepositInvoicesPathParameter,
  request: ListDepositInvoicesRequest,
  config?: SWRConfiguration<ListDepositInvoicesResponse>
) {
  const fetcher = useFetcher<
    ListDepositInvoicesRequest,
    ListDepositInvoicesResponse
  >();

  return useSWR(getListDepositInvoicesKey(parameter, request), fetcher, config);
}
