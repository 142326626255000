import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import * as Yup from "yup";

export default function useTriangularTradeFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const { withMessage } = useYupHelper({
    ns: "triangular_trades",
    prefix: "update_triangular_trade_form",
  });

  return useMemo(() => {
    return Yup.object({
      originallyIssuedBy: Yup.string().required(withMessage.required),
      // departure: Yup.object({
      //   id: Yup.number()
      //     .label("common:departure")
      //     .required(withMessage.required),
      // }).required(withMessage.required),
      issuedBy: Yup.object({
        id: Yup.number()
          .label("common:issued_by")
          .required(withMessage.required),
      }).required(withMessage.required),
      issuedTo: Yup.object({
        id: Yup.number()
          .label("common:issued_to")
          .required(withMessage.required),
      }).required(withMessage.required),
      bankInfo: Yup.object({
        id: Yup.number()
          .label("common:bank_info")
          .required(withMessage.required),
      }).required(withMessage.required),
      ftaSignature: Yup.object({
        id: Yup.number()
          .label("common:fta_signature")
          .required(withMessage.required),
      }).required(withMessage.required),
      forwarder: Yup.object({
        id: Yup.number()
          .label("common:forwarder")
          .required(withMessage.required),
      }).required(withMessage.required),
      invoiceNumber: Yup.string().required(withMessage.required),
      referenceInvoiceNumber: Yup.string().required(withMessage.required),
      importedFiles: Yup.array().min(1, withMessage.min),
    });
  }, [withMessage.min, withMessage.required]);
}
