import AppGrid from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import UploadOrderSheetsModal from "@/features/order-sheet-sets/upload-order-sheets-modal";
import { Flex, Text } from "@chakra-ui/react";
import { findParentGridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import useBuyerOrderSheetSetRows from "@/features/order-status/hooks/use-buyer-order-sheet-set-rows";
import OrderOrderSheetSetModal from "@/features/order-sheet-sets/order-order-sheet-set-modal";
import OrderSheetRowTooltipLabel from "@/features/order-sheet-sets/order-sheet-row-tooltip-label";
import {
  BuyerAttachmentRow,
  BuyerLineSheetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import {
  BuyerOrderSheetRow,
  BuyerOrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

export default function BuyerOrderSheetSetGrid() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const {
    rows,
    mutateRows,
    discloseTooltip,
    hover,
    toggleCollapseIfRoot,
    toggleCheck,
  } = useBuyerOrderSheetSetRows();
  const {
    t,
    tTitle,
    tLocalDateString,
    tLocalDateTimeString,
    tLocalDateTimeString2,
    tCurrencyString,
  } = useI18nHelper();

  const { navigateToOrderSheetSetPage } = useRouterHelper();

  const {
    disclosureUploadOrderSheetModal,
    disclosureOrderOrderSheetSetModal,
    fields,
    actions,
    extraContents,
  } = useLineSheetSetGrid({
    rows,
    discloseTooltip,
    mutateRows,
  });

  return (
    <>
      <AppGrid
        metas={[
          {
            rowType: "OrderSheetSet",
            gap: "4px",
            columns: [
              { name: tTitle("type"), width: "56px" },
              { name: tTitle("season"), width: "48px" },
              { name: tTitle("issued_by"), width: "144px" },
              { name: tTitle("title"), width: "minmax(224px, 1fr)" },
              {
                name: tTitle("ordered_amount.abbr"),
                width: "132px",
                textAlign: "end",
                marginRight: "24px",
              },
              {
                name: tTitle("confirmed_amount.abbr"),
                width: "132px",
                textAlign: "end",
                marginRight: "24px",
              },
              { name: tTitle("ordered_on"), width: "144px" },
              { name: tTitle("order_valid_until"), width: "144px" },
              { name: tTitle("status"), width: "160px" },
            ],
            fields: [
              fields.lineSheetSetType,
              {
                name: "season",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as BuyerOrderSheetSetRow).season;
                },
              },
              {
                name: "IssuedBy",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as BuyerOrderSheetSetRow).issuedByBoutiqueName;
                },
              },
              {
                name: "name",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as BuyerOrderSheetSetRow).name;
                },
              },
              {
                name: "OrderedAmount",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const orderedAmount = (r as BuyerOrderSheetSetRow)
                    .orderedAmount;
                  const orderedAmountWithoutCondition = (
                    r as BuyerOrderSheetSetRow
                  ).orderedAmountWithoutCondition;
                  const orderedConditionType = (r as BuyerOrderSheetSetRow)
                    .orderedConditionType;
                  let conditionStr = "";
                  if (orderedConditionType === "DISCOUNT") {
                    conditionStr = "R- ";
                  } else if (orderedConditionType === "MARKUP") {
                    conditionStr = "C+ ";
                  } else if (orderedConditionType === "MIX") {
                    conditionStr = "R/C ";
                  }
                  return (
                    <Flex
                      flexDirection={"column"}
                      fontSize={"12px"}
                      alignItems={"flex-end"}
                      marginRight={"24px"}
                    >
                      <Text>
                        {tCurrencyString(orderedAmountWithoutCondition)}
                      </Text>
                      <Text>
                        ({conditionStr}
                        {tCurrencyString(orderedAmount)})
                      </Text>
                    </Flex>
                  );
                },
              },
              {
                name: "ConfirmedAmount",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const value = (r as BuyerOrderSheetSetRow).confirmedAmount;
                  return (
                    <Text textAlign={"end"} marginEnd={"24px"}>
                      {tCurrencyString(value)}
                    </Text>
                  );
                },
              },
              {
                name: "orderedAt",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const value = (r as BuyerOrderSheetSetRow).submittedAt;
                  return value ? tLocalDateString(value) : "";
                },
              },
              {
                name: "validUntil",
                value: (r) => {
                  const value = (r as BuyerOrderSheetSetRow).validUntil;
                  return tLocalDateTimeString2(value);
                },
              },
              fields.withOrderSheetStatus(true),
            ],
            actions: [
              // actions.orderOrderSheetSet,
              // actions.cancelOrderSheetSet,
              actions.withDownloadUnoFile<BuyerOrderSheetSetRow>(
                (row) => row.lineSheetSet.compressedImportedFile
              ),
            ],
            indicator: (row) => {
              return (
                <GridRowIndicator
                  type={"VIEW"}
                  isHovered={row.isHovered}
                  isCollapsed={row.isCollapsed}
                />
              );
            },
            onClick: (row) => {
              const orderSheetSetRow = row as BuyerOrderSheetSetRow;
              navigateToOrderSheetSetPage(
                orderSheetSetRow.lineSheetSetId,
                row.id
              );
            },
          },
          {
            rowType: "LineSheet",
            columns: [
              { name: "type", width: "232px" },
              { name: "name", width: "1fr" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.withRowType("line_sheet"),
              {
                name: "name",
                chakraProps: {
                  fontWeight: 700,
                },
                value: (r) => {
                  return (r as BuyerLineSheetRow).name;
                },
              },
            ],
            // onClick: (row) => {
            // },
            actions: [
              actions.writeDraft,
              actions.writeFallbackDraft,
              actions.viewLineSheet,
              actions.withDownloadUnoFile<BuyerLineSheetRow>((row) => row.file),
            ],
            tooltip: (_row) => {
              const row = _row as BuyerLineSheetRow;
              if (row.isWritable) {
                return (
                  <OrderSheetRowTooltipLabel isFallback={row.isFallback} />
                );
              }
            },
          },
          {
            rowType: "Attachment",
            columns: [
              { name: "type", width: "232px" },
              { name: "name", width: "1fr" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.storedObjectField,
              {
                name: "name",
                value: (row) => {
                  const attachmentRow = row as BuyerAttachmentRow;
                  return attachmentRow.name;
                },
              },
            ],
            actions: [
              actions.withDownloadUnoFile<BuyerAttachmentRow>((row) => row),
            ],
          },
          {
            rowType: "OrderSheet",
            columns: [
              { name: "type", width: "152px" },
              { name: "name", width: "1fr" },
              { name: "orderedAmount", width: "240px" },
              { name: "boutiqueName ", width: "144px" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.withRowType("order_sheet"),
              {
                name: "name",
                chakraProps: {
                  fontWeight: 700,
                },
                value: (r) => {
                  const orderSheetRow = r as BuyerOrderSheetRow;
                  const lineSheetRow = findParentGridRow(
                    r,
                    rows
                  ) as BuyerLineSheetRow;
                  return lineSheetRow.name;
                },
              },
              {
                name: "OrderedAmount",
                value: (r) => {
                  const orderSheetRow = r as BuyerOrderSheetRow;
                  const orderedAmount = orderSheetRow.orderedAmount;
                  const orderedAmountWithoutCondition =
                    orderSheetRow.orderedAmountWithoutCondition;
                  const orderedConditionType =
                    orderSheetRow.orderedConditionType;
                  let conditionStr = "";
                  if (orderedConditionType === "DISCOUNT") {
                    conditionStr = "R- ";
                  } else if (orderedConditionType === "MARKUP") {
                    conditionStr = "C+ ";
                  } else if (orderedConditionType === "MIX") {
                    conditionStr = "R/C ";
                  }
                  return (
                    <Text textAlign={"end"} marginEnd={"80px"}>
                      {tCurrencyString(orderedAmountWithoutCondition)} (
                      {conditionStr}
                      {tCurrencyString(orderedAmount)})
                    </Text>
                  );
                },
              },
              {
                name: "boutiqueName",
                value: (r) => {
                  const orderSheetRow = r as BuyerOrderSheetRow;
                  return orderSheetRow.toBeSubmitted?.name || "";
                },
              },
              fields.withOrderSheetStatus(true),
            ],
            actions: [
              actions.editOrViewOrderSheet,
              actions.replaceOrUploadUploadedFile,
              actions.deleteUploadedFile,
              actions.withDownloadUnoFile<BuyerOrderSheetRow>(
                (row) => row.uploadedFile
              ),
            ],
            extraContents: extraContents.orderSheetExtraContents,
          },
        ]}
        rows={rows}
        onHover={(path, row, isHovered) => {
          hover(row, isHovered);
        }}
        onClick={(path, row) => {
          //toggleCollapseIfRoot(row);
        }}
        onCheck={(path, row, isChecked) => {
          toggleCheck(row);
        }}
      ></AppGrid>
      {disclosureUploadOrderSheetModal.data && (
        <UploadOrderSheetsModal
          {...disclosureUploadOrderSheetModal.data}
          isOpen={disclosureUploadOrderSheetModal.isOpen}
          onClose={disclosureUploadOrderSheetModal.onClose}
        />
      )}
      {disclosureOrderOrderSheetSetModal.data && (
        <OrderOrderSheetSetModal
          {...disclosureOrderOrderSheetSetModal.data}
          isOpen={disclosureOrderOrderSheetSetModal.isOpen}
          onClose={disclosureOrderOrderSheetSetModal.onClose}
        />
      )}
    </>
  );
}
