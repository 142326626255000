import {
  LineSheet,
  LineSheetSet,
  LineSheetSetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  isOrderedToAgency,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";

export function isFallbackLineSheet(lineSheet: {
  status: LineSheet["status"];
}) {
  return (
    lineSheet.status === "PARSING_SKIPPED" ||
    lineSheet.status === "PARSING_FAILED"
  );
}

export function isWritableLineSheet(
  lineSheet: { id: number },
  lineSheetSet: {
    type: LineSheetSetType;
    publishedAt: Date | null;
  },
  orderSheetSet?: {
    status: OrderSheetStatus;
    orderSheets: {
      lineSheet: { id: number };
    }[];
  }
) {
  if (!orderSheetSet) {
    return lineSheetSet.publishedAt !== null;
  }

  const isOrdered = isOrderedToAgency(orderSheetSet);
  if (!isOrdered) {
    return (
      lineSheetSet.type === "SEASON" ||
      orderSheetSet.orderSheets.filter((orderSheet) => {
        return orderSheet.lineSheet.id === lineSheet.id;
      }).length === 0
    );
  }
  return false;
}

export function isDraftOrderSheet(orderSheet: { status: OrderSheetStatus }) {
  return (
    orderSheet.status === "INITIAL_DRAFT" ||
    orderSheet.status === "WORKING_DRAFT"
  );
}
