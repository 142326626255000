import { atom } from "recoil";

import { AgencyOrderSheetSetRow } from "@/features/order-status/order-sheet-set-row.type";

export const agencyOrderSheetSetRowsState = atom<
  AgencyOrderSheetSetRow[] | undefined
>({
  key: "AgencyOrderSheetSetRow",
  default: undefined,
});

export const agencyOrderSheetSetRowsMutateId = atom<number>({
  key: "AgencyOrderSheetSetRowMutateId",
  default: 0,
});
