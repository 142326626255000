import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";

export interface BulkPublishLineSheetSetsRequest {
  lineSheetSets: { id: number }[];
  issuedToBuyers: { id: number }[];
}

export interface BulkPublishLineSheetSetsResponse {
  lineSheetSets: LightLineSheetSet[];
}

export interface BulkPublishLineSheetSetsPathParameter {
  agencyId: number;
}

export const bulkPublishLineSheetSetsKey: YWRKey<BulkPublishLineSheetSetsPathParameter> =
  {
    url: (parameter) => {
      const { agencyId } = parameter;
      return `/agencies/${agencyId}/line-sheet-sets/bulk-publish`;
    },
    method: "put",
  };

export const bulkPublishLineSheetSetsI18nKey = "bulk_publish_line_sheet_sets";

export default function useBulkPublishLineSheetSets() {
  const fetcher = useFetcher<
    BulkPublishLineSheetSetsRequest,
    BulkPublishLineSheetSetsResponse
  >();

  return useYWR(bulkPublishLineSheetSetsKey, fetcher);
}
