import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  getListProformaInvoicesKey,
  ListProformaInvoicesPathParameter,
  ListProformaInvoicesRequest,
  ListProformaInvoicesResponse,
} from "@/features/invoices/proforma-invoices/hooks/use-list-proforma-invoices";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

export interface ListAllProformaInvoicesRequest
  extends Omit<ListProformaInvoicesRequest, "pageSize" | "pageNumber"> {}
export type ListAllProformaInvoicesResponse = ProformaInvoice[];
export interface ListAllProformaInvoicesPathParameter
  extends ListProformaInvoicesPathParameter {}

export const listAllProformaInvoicesTemplateUrl = `/:by/:companyId/proforma-invoices/list-all`;

export function getListAllProformaInvoicesKey(
  parameter?: ListAllProformaInvoicesPathParameter,
  request?: ListAllProformaInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllProformaInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllProformaInvoicesKey(
  key: AxiosRequestConfig<ListAllProformaInvoicesRequest>
) {
  const urlParser = new UrlParser<ListAllProformaInvoicesPathParameter>(
    listAllProformaInvoicesTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllProformaInvoices(
  parameter?: ListAllProformaInvoicesPathParameter,
  request?: ListAllProformaInvoicesRequest,
  config?: SWRConfiguration<ListAllProformaInvoicesResponse>
) {
  const listProformaInvoicesFetcher = useFetcher<
    ListProformaInvoicesRequest,
    ListProformaInvoicesResponse
  >();

  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListProformaInvoicesRequest>) => {
        const { parameter, request } = parseListAllProformaInvoicesKey(config);

        return listProformaInvoicesFetcher(
          getListProformaInvoicesKey(parameter, request)!
        );
      },
      (response) => {
        return response.proformaInvoices;
      }
    );
  }, [listProformaInvoicesFetcher]);

  return useSWR(
    getListAllProformaInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
