import {
  DenseLineSheet,
  DenseLineSheetSet,
  LineSheetSet,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  DenseOrderSheet,
  isOrderedToAgency,
  LightOrderSheet,
} from "@/features/order-sheets/order-sheet.type";
import {
  BuyerAttachmentRow,
  BuyerLineSheetRow,
  BuyerLineSheetSetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { StoredObject } from "@/features/types";
import { boolean } from "yup";
import { BuyerOrderSheetRow } from "@/features/order-status/order-sheet-set-row.type";

export const buyerLineSheetSetRowsMapper = new GridMapper<
  DenseLineSheetSet,
  BuyerLineSheetSetRow
>(
  "LineSheetSet",
  (lineSheetSet) => {
    const orderSheetSet = lineSheetSet.orderSheetSet;
    return {
      season: lineSheetSet.season,
      name: lineSheetSet.name,
      status: lineSheetSet.status,
      type: lineSheetSet.type,
      publishedAt: lineSheetSet.publishedAt,
      validUntil: lineSheetSet.validUntil,
      issuedByBoutiqueName: lineSheetSet.issuedByBoutique.name,
      issuedByBrandName: lineSheetSet.issuedByBrand?.name,
      orderSheetSetId: orderSheetSet?.id || -1,
      orderSheetSetStatus: orderSheetSet?.status,
      isRead: lineSheetSet.isRead,
      orderedConditionType: orderSheetSet?.orderedConditionType,
      orderedAmountWithoutCondition:
        orderSheetSet?.orderedAmountWithoutCondition || 0,
      orderedAmount: orderSheetSet?.orderedAmount || 0,
      orderedQuantity: orderSheetSet?.orderedQuantity || 0,
      confirmedAmount: orderSheetSet?.confirmedAmount || 0,
      confirmedQuantity: orderSheetSet?.confirmedQuantity || 0,
      compressedImportedFile: lineSheetSet.compressedImportedFile,
    };
  },
  (row) => {
    let orderSheetSet;
    if (row.orderSheetSetId > -1) {
      orderSheetSet = {
        id: row.orderSheetSetId,
        status: row.orderSheetSetStatus,
      };
    }
    return {
      id: row.id,
      name: row.name,
      status: row.status,
      type: row.type,
      publishedAt: row.publishedAt,
      validUntil: row.validUntil,
      issuedByBoutique: {
        //todo 이거 -1이당
        id: -1,
        type: "BOUTIQUE",
        name: row.issuedByBoutiqueName,
      },
      isRead: row.isRead,
      compressedImportedFile: row.compressedImportedFile,
      orderSheetSet: orderSheetSet,
    };
  }
)
  .addChild(
    (lineSheetSet): (DenseLineSheet & { _isWritable: boolean })[] => {
      const isOrdered = lineSheetSet.orderSheetSet
        ? isOrderedToAgency(lineSheetSet.orderSheetSet)
        : false;

      return lineSheetSet.lineSheets.map((lineSheet) => {
        let isWritable = !isOrdered;
        if (!isOrdered) {
          isWritable =
            lineSheetSet.type === "SEASON" ||
            lineSheet.orderSheets.length === 0;
        }
        return {
          ...lineSheet,
          _isWritable: isWritable,
          _lineSheetSetType: lineSheetSet.type,
        };
      });
    },
    (parent, child) => {
      parent.lineSheets = child;
    },
    new GridMapper<
      DenseLineSheet & { _isWritable: boolean },
      BuyerLineSheetRow
    >(
      "LineSheet",
      (lineSheet) => {
        //isWritable;

        return {
          name: lineSheet.name,
          type: lineSheet.type,
          status: lineSheet.status,
          isFallback:
            lineSheet.status === "PARSING_SKIPPED" ||
            lineSheet.status === "PARSING_FAILED",
          isWritable: lineSheet._isWritable,
          file: lineSheet.file,
          updatedAt: lineSheet.updatedAt,
        };
      },
      (row) => {
        return {
          name: row.name,
          status: row.status,
          updatedAt: row.updatedAt,
        };
      }
    ).addChild(
      (lineSheet): (LightOrderSheet & { _lineSheet: DenseLineSheet })[] => {
        return lineSheet.orderSheets.map((orderSheet: LightOrderSheet) => {
          return {
            ...orderSheet,
            _lineSheet: lineSheet,
          };
        });
      },
      (parent, children) => {
        parent.orderSheets = children.length === 1 ? children[0] : null;
      },
      new GridMapper<
        LightOrderSheet & { _lineSheet: DenseLineSheet },
        BuyerOrderSheetRow
      >(
        "OrderSheet",
        (orderSheet) => {
          const lineSheet = orderSheet._lineSheet;

          return {
            name: lineSheet.name,
            boutiqueName: orderSheet.toBeSubmitted
              ? orderSheet.toBeSubmitted.name
              : "unassigned",
            isFallback: ["PARSING_SKIPPED", "PARSING_FAILED"].includes(
              orderSheet._lineSheet.status
            ),
            status: orderSheet.status,
            uploadedFile: orderSheet.uploadedFile,
            exportedFile: orderSheet.exportedFile,
            orderedConditionType: orderSheet.orderedConditionType,
            orderedAmountWithoutCondition:
              orderSheet.orderedAmountWithoutCondition,
            orderedAmount: orderSheet.orderedAmount,
            orderedQuantity: orderSheet.orderedQuantity,
            toBeSubmitted: orderSheet.toBeSubmitted,
            lineSheetId: lineSheet.id,
            confirmedAmount: orderSheet.confirmedAmount,
            confirmedQuantity: orderSheet.confirmedQuantity,
          };
        },
        (row) => {
          return {
            status: row.status,
            uploadedFile: row.uploadedFile,
            exportedFile: row.exportedFile,
            orderedConditionType: row.orderedConditionType,
            orderedAmountWithoutCondition: row.orderedAmountWithoutCondition,
            orderedAmount: row.orderedAmount,
            orderedQuantity: row.orderedQuantity,
          };
        }
      )
    )
  )
  .addChild(
    (lineSheetSet) => {
      return lineSheetSet.attachments;
    },
    (parent, child) => {},
    new GridMapper<StoredObject, BuyerAttachmentRow>(
      "Attachment",
      (resource) => {
        return {
          name: resource.name,
        };
      },
      (row) => {
        return {
          name: row.name,
        };
      }
    )
  );

export function createBuyerLineSheetSetRows(
  lineSheetSets?: DenseLineSheetSet[],
  prevRows?: BuyerLineSheetSetRow[]
) {
  return buyerLineSheetSetRowsMapper.toRows(lineSheetSets, prevRows);
}
