import {
  TriangularTrade,
  TriangularTradeRow,
} from "@/features/invoices/triangular-trades/triangular-trade.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export const triangularTradeRowMapper = new GridMapper<
  TriangularTrade,
  TriangularTradeRow
>(
  "TriangularTrade",
  (trade) => {
    return {
      ...trade,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
