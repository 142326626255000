import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";

export interface UpdateLineSheetSetOrderRulesRequest {
  orderRules: string[];
}
export interface UpdateLineSheetSetOrderRulesResponse
  extends LightLineSheetSet {}

export interface UpdateLineSheetSetOrderRulesPathParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const UpdateLineSheetSetOrderRulesTemplateUrl = `/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/order-rules`;

export const UpdateLineSheetSetOrderRulesKey: YWRKey<UpdateLineSheetSetOrderRulesPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(UpdateLineSheetSetOrderRulesTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateLineSheetSetOrderRules() {
  const fetcher = useFetcher<
    UpdateLineSheetSetOrderRulesRequest,
    UpdateLineSheetSetOrderRulesResponse
  >();

  return useYWR(UpdateLineSheetSetOrderRulesKey, fetcher);
}
