import { Flex, Text } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { DragEventHandler, useCallback, useRef, useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import { FileType, getAccept } from "@/features/types";

interface AppFileDragAndDropZoneProps {
  isMultiple?: boolean;
  onFilesAdd?: (value: File[] | File) => void;
  allowFileTypes?: FileType[];
}

export default function AppFileDragAndDropZone({
  isMultiple = true,
  onFilesAdd,
  allowFileTypes,
}: AppFileDragAndDropZoneProps) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const { warning: showWarning } = useAppToasts();
  const { t } = useTranslation();

  const handleOnDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
    handleBinariesAdd(event.dataTransfer.files);
  };

  const handleOnDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
  };

  const handleOnDragLeave: DragEventHandler<HTMLDivElement> = () => {
    setIsDragging(false);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleBinariesAdd = useCallback(
    (files: FileList | null) => {
      if (files) {
        if (files.length > 1 && !isMultiple) {
          showWarning("Only one file is allowed.");
          return;
        }

        if (onFilesAdd) {
          onFilesAdd(isMultiple ? Array.from(files) : files[0]);
        }
      }
    },
    [onFilesAdd, showWarning, isMultiple]
  );

  return (
    <Flex
      border={"0.5px dashed #8F8F8C"}
      borderColor={isDragging ? "#1272EF" : "#8F8F8C"}
      borderRadius={"4px"}
      padding={"12px 20px"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"96px"}
      onDrop={handleOnDrop}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDragOver={handleDragOver}
    >
      <Text fontSize={"12px"} textAlign={"center"}>
        {toTitle(t("drag_and_drop_file_here"))}
        <br />
        {toTitle(t("or"))}
      </Text>
      <AppButton
        onClick={() => {
          if (fileRef.current) {
            fileRef.current.click();
          }
        }}
      >
        {toTitle(t("choose_file"))}
      </AppButton>

      <input
        type={"file"}
        multiple={isMultiple}
        ref={fileRef}
        accept={getAccept(allowFileTypes)}
        style={{ display: "none" }}
        onChange={(e) => {
          handleBinariesAdd(e.target.files);
          e.target.value = "";
        }}
      />
    </Flex>
  );
}
