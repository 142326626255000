import { LineSheetTab } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import useListAllLineSheetTabsFetcher from "@/features/line-sheet-sets/hooks/use-list-all-line-sheet-tabs-fetcher";

export interface ListAllLineSheetTabsRequest {
  tabIndexes: number[];
}

export interface ListAllLineSheetTabsResponse {
  tabs: LineSheetTab[];
  _lineSheetSetId: number;
  _lineSheetId: number;
}

export interface ListAllLineSheetTabsPathParameter extends IdentityParameter {
  lineSheetSetId: number;
  lineSheetId: number;
}

export const listAllLineSheetTabsTemplateUrl =
  "/:by/:companyId/line-sheet-sets/:lineSheetSetId/line-sheets/:lineSheetId/tabs";

export function getListAllLineSheetTabsKey(
  parameter?: ListAllLineSheetTabsPathParameter,
  request?: ListAllLineSheetTabsRequest
): SWRObjectKey<ListAllLineSheetTabsRequest> | null {
  if (parameter && request) {
    return {
      url: calcUrl(listAllLineSheetTabsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllLineSheetTabsKey(
  key: AxiosRequestConfig<ListAllLineSheetTabsRequest>
): {
  parameter: ListAllLineSheetTabsPathParameter;
  request: ListAllLineSheetTabsRequest;
} {
  const urlParser = new UrlParser<ListAllLineSheetTabsPathParameter>(
    listAllLineSheetTabsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      lineSheetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params!!,
  };
}

export function useListAllLineSheetTabs(
  parameter?: ListAllLineSheetTabsPathParameter,
  request?: ListAllLineSheetTabsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useListAllLineSheetTabsFetcher();

  return useSWR(
    getListAllLineSheetTabsKey(parameter, request),
    fetcher,
    config
  );
}
