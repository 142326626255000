import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface BulkCreateLineSheetSetRequest {
  gmailIds: string[];
  force?: boolean;
}
export interface BulkCreateLineSheetSetResponse {}
export interface BulkCreateLineSheetSetParameter {
}

export const bulkCreateLineSheetSetTemplateUrl =
  "/gmails/bulk-create-line-sheet-set";

export const bulkCreateLineSheetSetKey: YWRKey<BulkCreateLineSheetSetParameter> = {
  url: (parameter) => {
    return calcUrl(bulkCreateLineSheetSetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseBulkCreateLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<BulkCreateLineSheetSetParameter>(
    bulkCreateLineSheetSetTemplateUrl,
    {
      gmailIds: StringTransformer.parseIds,
      force: StringTransformer.parseBoolean,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useBulkCreateLineSheetSet() {
  const fetcher = useFetcher<
    BulkCreateLineSheetSetRequest,
    BulkCreateLineSheetSetResponse
  >();
  return useYWR(bulkCreateLineSheetSetKey, fetcher);
}
