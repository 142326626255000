import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { CreditInvoiceRow } from "@/features/invoices/credit-invoices/credit-invoice-row.type";

export const creditInvoiceRowMapper = new GridMapper<
  CreditInvoice,
  CreditInvoiceRow
>(
  "CreditInvoice",
  (resource) => {
    return {
      ...resource,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
