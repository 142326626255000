import AppGrid from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { OrderConfirmationInvoiceRow } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-row.type";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { useEffect, useMemo, useState } from "react";
import { orderConfirmationRowMapper } from "@/features/invoices/utils/order-confirmation-invoice-row-mapper";
import useIdentity from "@/features/ui/hooks/use-identity";

interface OrderConfirmationInvoiceGridProps {
  resources?: OrderConfirmationInvoice[];
  mutateResources: () => void;
}

export default function OrderConfirmationInvoiceGrid({
  resources,
  mutateResources,
}: OrderConfirmationInvoiceGridProps) {
  const identity = useIdentity();
  const { navigate } = useRouterHelper();

  const [rows, setRows] = useState<OrderConfirmationInvoiceRow[]>();

  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: () => {
      mutateResources();
    },
  });

  const rowMapper = useMemo(() => {
    return orderConfirmationRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(
        identity?.company?.type !== "BUYER"
          ? resources?.map((resource) => {
              return {
                ...resource,
                isRead: false,
              };
            })
          : resources,

        prev
      );
    });
  }, [identity?.company?.type, resources, rowMapper]);

  if (identity?.company?.type === "AGENCY") {
    return (
      <>
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "OrderConfirmationInvoice",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.orderedBy.column,
                invoiceGrid.pairs.issuedBy.column,
                // invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.brand.column,
                invoiceGrid.pairs.totalAmount.column,
                invoiceGrid.pairs.deposit.column,
                invoiceGrid.pairs.status.column,
                invoiceGrid.pairs.remittanceStatus.column,
                invoiceGrid.pairs.exposedToBuyer.column,
                invoiceGrid.pairs.paidBy.column,
                invoiceGrid.pairs.paymentMethod.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.orderedBy.field,
                invoiceGrid.pairs.issuedBy.field,
                // invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.brand.field,
                invoiceGrid.pairs.totalAmount.field,
                invoiceGrid.pairs.deposit.field,
                invoiceGrid.pairs.status.field,
                invoiceGrid.pairs.remittanceStatus.field,
                invoiceGrid.pairs.exposedToBuyer.field,
                invoiceGrid.pairs.paidBy.field,
                invoiceGrid.pairs.paymentMethod.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => {
                return (
                  <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
                );
              },
              onClick: (row) => {
                navigate(`/order-confirmation-invoices/${row.id}`);
              },
            },
          ]}
        />
      </>
    );
  } else if (identity?.company?.type === "BOUTIQUE") {
    return (
      <>
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "OrderConfirmationInvoice",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.orderedBy.column,
                // invoiceGrid.pairs.issuedBy.column,
                // invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.title.column,
                // invoiceGrid.pairs.brand.column,
                invoiceGrid.pairs.totalAmount.column,
                invoiceGrid.pairs.deposit.column,
                invoiceGrid.pairs.remittanceStatus.column,
                // invoiceGrid.pairs.exposedToBuyer.column,
                // invoiceGrid.pairs.paidBy.column,
                // invoiceGrid.pairs.paymentMethod.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.orderedBy.field,
                // invoiceGrid.pairs.issuedBy.field,
                // invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.title.field,
                // invoiceGrid.pairs.brand.field,
                invoiceGrid.pairs.totalAmount.field,
                invoiceGrid.pairs.deposit.field,
                invoiceGrid.pairs.remittanceStatus.field,
                // invoiceGrid.pairs.exposedToBuyer.field,
                // invoiceGrid.pairs.paidBy.field,
                // invoiceGrid.pairs.paymentMethod.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => {
                return (
                  <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
                );
              },
              onClick: (row) => {
                navigate(`/order-confirmation-invoices/${row.id}`);
              },
            },
          ]}
        />
      </>
    );
  } else if (identity?.company?.type === "BUYER") {
    return (
      <>
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "OrderConfirmationInvoice",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.issuedBy.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.brand.column,
                invoiceGrid.pairs.totalAmount.column,
                invoiceGrid.pairs.deposit.column,
                invoiceGrid.pairs.remittanceStatus.column,
                invoiceGrid.pairs.paidBy.column,
                invoiceGrid.pairs.paymentMethod.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.issuedBy.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.brand.field,
                invoiceGrid.pairs.totalAmount.field,
                invoiceGrid.pairs.deposit.field,
                invoiceGrid.pairs.remittanceStatus.field,
                invoiceGrid.pairs.paidBy.field,
                invoiceGrid.pairs.paymentMethod.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => (
                <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
              ),
              onClick: (row) => {
                navigate(`/order-confirmation-invoices/${row.id}`);
              },
            },
          ]}
        />
      </>
    );
  }

  return null;
}
