import {
  RecoilState,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import {
  buyerOrderSheetSetRowsMutateId,
  buyerOrderSheetSetRowsState,
} from "@/features/order-status/recoils/buyer-order-sheet-set-rows-state";
import { createBuyerOrderSheetSetRows } from "@/features/order-status/helpers/buyer-order-sheet-set-row-helper";
import { BuyerOrderSheetSetRow } from "@/features/order-status/order-sheet-set-row.type";
import useAppGridMutate from "@/features/ui/hooks/use-app-grid-mutate";

export default function useBuyerOrderSheetSetRows() {
  const recoilState = buyerOrderSheetSetRowsState;

  const appGridMutate = useAppGridMutate(recoilState);
  const rows = useRecoilValue(recoilState);

  const setRows = useRecoilCallback(
    ({ set }) =>
      (orderSheetSets?: DenseOrderSheetSet[]) => {
        set(buyerOrderSheetSetRowsState, (prev) => {
          return createBuyerOrderSheetSetRows(orderSheetSets, prev);
        });
      },
    []
  );

  const mutateRows = useRecoilCallback(
    ({ set }) =>
      () => {
        set(buyerOrderSheetSetRowsMutateId, (prev) => {
          return prev + 1;
        });
      },
    []
  );

  return {
    rows,
    setRows,
    mutateRows,
    ...appGridMutate,
  };
}
