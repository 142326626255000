import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { FtaSignature } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType, StoredObject } from "@/features/types";

export interface CreateFtaSignatureRequest {
  alias: string;
  emphasizedFtaCode: string;
  ftaCode: string;
  signatureFile: Pick<StoredObject, "id" | "name">;
}

export interface CreateFtaSignatureResponse extends FtaSignature {}

export interface CreateFtaSignaturePathParameter {
  by: CompanyType;
  companyId: number;
}

export const createFtaSignatureTemplateUrl = `/:by/:companyId/fta_signature`;

export const createFtaSignatureKey: YWRKey<CreateFtaSignaturePathParameter> = {
  url: (parameters: CreateFtaSignaturePathParameter) => {
    return calcUrl(createFtaSignatureTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateFtaSignature() {
  const fetcher = useFetcher<
    CreateFtaSignatureRequest,
    CreateFtaSignatureResponse
  >();
  return useYWR(createFtaSignatureKey, fetcher);
}
