import {
  BankRemittanceReceipt,
  BankRemittanceReceiptDetail,
} from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { IdentityParameter, StoredObject } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";

export interface CreateBankRemittanceReceiptRequest
  extends Omit<
    BankRemittanceReceipt,
    | "id"
    | "createdAt"
    | "file"
    | "isRead"
    | "depositInvoice"
    | "bankRemittanceReceiptDetailList"
  > {
  file: Pick<StoredObject, "id">;
  bankRemittanceReceiptDetailList: Omit<BankRemittanceReceiptDetail, "id">[];
}

export interface CreateBankRemittanceReceiptResponse
  extends BankRemittanceReceipt {}

export interface CreateBankRemittanceReceiptPathParameter
  extends IdentityParameter {}

export const createBankRemittanceReceiptTemplateUrl = `/:by/:companyId/bank-remittance-receipts`;

export const createBankRemittanceReceiptKey: YWRKey<CreateBankRemittanceReceiptPathParameter> =
  {
    url: (parameters) => {
      return calcUrl(createBankRemittanceReceiptTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateBankRemittanceReceipt(
  config?: AxiosRequestConfig<CreateBankRemittanceReceiptRequest>
) {
  const fetcher = useFetcher<
    CreateBankRemittanceReceiptRequest,
    CreateBankRemittanceReceiptResponse
  >();

  return useYWR(createBankRemittanceReceiptKey, fetcher, config);
}
