import {
  calcIdentityUrlPrefix,
  parseYWRUrl,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import { IdentityParameter } from "@/features/types";
import { AxiosRequestConfig } from "axios";
import { singularizeCompanyType } from "@/features/ui/helpers/identity-helpers";

export interface GetOrderSheetRevisionRequest {
  lineSheetSetId: number;
  lineSheetId: number;
  sheetTabIndexes: number[];
}

export interface GetOrderSheetRevisionPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
  revisionNumber: number;
}

export function getOrderSheetRevisionKey(
  parameter: GetOrderSheetRevisionPathParameter,
  request: GetOrderSheetRevisionRequest
): SWRObjectKey<GetOrderSheetRevisionRequest> {
  const { orderSheetSetId, orderSheetId, revisionNumber } = parameter;
  let url = calcIdentityUrlPrefix(parameter);
  url += `/order-sheet-sets/${orderSheetSetId}/order-sheets/${orderSheetId}/revisions/${revisionNumber}`;
  return {
    url,
    params: request,
  };
}

// TODO: 이건 위 함수와 거의 비슷한 것 같은데 왜 분리해둔걸까?
export function parseGetOrderSheetRevisionKey(
  key: AxiosRequestConfig<GetOrderSheetRevisionRequest>
): {
  parameter: GetOrderSheetRevisionPathParameter;
  request: GetOrderSheetRevisionRequest;
} {
  const { url, params } = key;
  const obj = parseYWRUrl(
    "/:companyType/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/revisions/:revisionNumber",
    url!!
  );

  const parameter: GetOrderSheetRevisionPathParameter = {
    by: singularizeCompanyType(obj["companyType"]),
    companyId: parseInt(obj["companyId"]),
    orderSheetSetId: parseInt(obj["orderSheetSetId"]),
    orderSheetId: parseInt(obj["orderSheetId"]),
    revisionNumber: parseInt(obj["revisionNumber"]),
  };

  return {
    parameter,
    request: key.params!!,
  };
}
