import { ListProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-proforma-invoices";
import {
  IdentityParameter,
  PageNumberBasedListResponse,
} from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { DenseProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import useListDenseProformaInvoicesFetcher from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices-fetcher";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListDenseProformaInvoicesRequest
  extends ListProformaInvoicesRequest {}

export interface ListDenseProformaInvoicesResponse
  extends PageNumberBasedListResponse {
  proformaInvoices: DenseProformaInvoice[];
}

export interface ListDenseProformaInvoicesPathParameter
  extends IdentityParameter {}

export const listDenseProformaInvoicesTemplateUrl =
  "/:by/:companyId/dense-proforma-invoices";

export function getListDenseProformaInvoicesKey(
  parameter?: ListDenseProformaInvoicesPathParameter,
  request?: ListDenseProformaInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDenseProformaInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListDenseProformaInvoicesKey(
  key: AxiosRequestConfig<ListDenseProformaInvoicesRequest>
) {
  const urlParser = new UrlParser<ListDenseProformaInvoicesPathParameter>(
    listDenseProformaInvoicesTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function readDenseProformaInvoiceProactively(proformaInvoiceId: number) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/dense-proforma-invoices/,
    "proformaInvoices",
    proformaInvoiceId
  );
}

export default function useListDenseProformaInvoices(
  parameter?: ListDenseProformaInvoicesPathParameter,
  request?: ListDenseProformaInvoicesRequest,
  config?: SWRConfiguration<ListDenseProformaInvoicesResponse>
) {
  const fetcher = useListDenseProformaInvoicesFetcher();

  return useSWR(
    getListDenseProformaInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
