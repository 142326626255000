import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { uniq } from "lodash";
import {
  AttachmentRow,
  IsolatedLineSheetRow,
  IsolatedOrderSheetRow,
} from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import LineSheetFileTypeSelect from "@/features/line-sheet-sets/line-sheet-file-type-select";
import {
  isAttachmentRow,
  isLineSheetRow,
} from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import { LineSheetFile } from "@/features/line-sheet-sets/line-sheet-file-controller";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface LineSheetFileTypeGridHeaderCellProps {
  rows?: (IsolatedLineSheetRow | IsolatedOrderSheetRow | AttachmentRow)[];
  isEditing?: boolean;
  onSelect: (type: LineSheetFile["type"]) => void;
}

export default function LineSheetFileTypeGridHeaderCell({
  rows,
  isEditing = false,
  onSelect,
}: LineSheetFileTypeGridHeaderCellProps) {
  const { tTitle } = useI18nHelper();

  const values = useMemo(() => {
    if (isEditing && rows && rows.some((i) => i.isChecked)) {
      return uniq(
        rows
          .filter((row) => row.isChecked)
          .map((row) => {
            if (isLineSheetRow(row)) {
              const lineSheetRow = row as IsolatedLineSheetRow;
              if (lineSheetRow.type === "LINE_SHEET") {
                return "LINE_SHEET";
              } else {
                return "ORDER_FORM";
              }
            } else if (isAttachmentRow(row)) {
              return "ATTACHMENT";
            }
          })
          .filter((type) => type !== undefined)
      );
    }
  }, [isEditing, rows]);

  if (values) {
    return (
      <LineSheetFileTypeSelect
        isExcel={true}
        value={values.length === 1 ? values[0] : undefined}
        onSelect={onSelect}
      />
    );
  } else {
    return <>{tTitle("common:line_sheet_set.type")}</>;
  }
}
