import { Payment } from "@/features/invoices/payment/payment.type";
import { CompanyType } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface GetPaymentRequest {}

export interface GetPaymentResponse extends Payment {}

export interface GetPaymentPathParameter {
  by: CompanyType;
  companyId: number;
  paymentId: number;
}

export const getPaymentTemplateUrl = `/:by/:companyId/payments/:paymentId`;

export function getGetPaymentKey(
  parameter?: GetPaymentPathParameter,
  request?: GetPaymentRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getPaymentTemplateUrl, parameter),
      params: request,
    };
  }

  return null;
}

export default function useGetPayment(
  parameter?: GetPaymentPathParameter,
  request?: GetPaymentRequest,
  config?: SWRConfiguration<GetPaymentResponse>
) {
  const fetcher = useFetcher<GetPaymentRequest, GetPaymentResponse>();
  return useSWR(getGetPaymentKey(parameter, request), fetcher, config);
}
