import LineSheetSetDetailPage, {
  isAttachmentFormField,
  isLineSheetFormField,
  LineSheetFormField,
  LineSheetSetFormFields,
} from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import { Formik } from "formik";
import LineSheetSetDetailForm from "@/features/line-sheet-sets/line-sheet-set-detail-form";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import useLineSheetSetFormValidationSchema from "@/features/line-sheet-sets/detail/hooks/use-line-sheet-set-form-validation-schema";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useLineSheetSetDumbTransaction } from "@/features/line-sheet-sets/hooks/use-line-sheet-set-dumb-transaction";
import AppSubmitButton from "@/features/line-sheet-sets/app-submit-button";
import React, { useCallback } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useIdentity from "@/features/ui/hooks/use-identity";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";

export default function UploadLineSheetSetPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const validationSchema = useLineSheetSetFormValidationSchema();
  const { addCreateLineSheetSet } = useLineSheetSetDumbTransaction();
  const { tTitle } = useI18nHelper();

  async function validate(values: LineSheetSetFormFields) {
    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const handleSubmit = useCallback(
    async (values: LineSheetSetFormFields) => {
      if (identity?.company) {
        addCreateLineSheetSet(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            type: values.type!,
            season: values.season!,
            issuedByBoutique: {
              id: values.issuedByBoutique!.id,
            },
            issuedByBrand: values.issuedByBrand
              ? {
                  id: values.issuedByBrand.id,
                }
              : null,
            validUntil: values.validUntil!,
            orderRules: values.joinedOrderRules?.split("\n") || [],
            externalFileLinks: values.externalFileLinks || [],
            lineSheets: values.lineSheetOrAttachmentList
              .filter((i): i is LineSheetFormField => isLineSheetFormField(i))
              .map((lineSheet) => {
                return {
                  file: lineSheet.file,
                  type: lineSheet.type,
                };
              }),
            attachments: values.lineSheetOrAttachmentList
              .filter(isAttachmentFormField)
              .map((attachment) => {
                if (attachment.file) {
                  return attachment.file;
                } else {
                  return {
                    id: attachment.id,
                    name: attachment.name,
                  };
                }
              }),
          }
        );
        return true;
      }

      return false;
    },
    [addCreateLineSheetSet, identity]
  );

  return (
    <Formik<LineSheetSetFormFields>
      initialValues={{
        id: -1,
        isDeleted: false,
        season: DEFAULT_SEASON,
        type: "STOCK",
        validUntil: (() => {
          const validUntil = new Date();
          validUntil.setDate(validUntil.getDate() + 7);
          validUntil.setHours(18, 0, 0);
          return validUntil;
        })(),
        lineSheetOrAttachmentList: [],
        externalFileLinks: [],
        issuedByBoutique:
          identity?.company?.type === "BOUTIQUE"
            ? {
                id: identity.company.id,
                name: "sorry my bad",
              }
            : undefined,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={async (values, formikHelpers) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"header"} as={"h2"} fontSize={"14px"}>
                  {tTitle("line_sheet_sets:upload_line_sheet_sets")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppSubmitButton
                  key={"upload_and_add_another"}
                  addOther={true}
                  formik={props}
                />,
                <AppSubmitButton key={"upload"} formik={props} />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/line-sheets"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <LineSheetSetDetailForm
              formik={props}
              onPublishClick={() => {}}
              isEditing={true}
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
