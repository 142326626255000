import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";
import { CompanyType } from "@/features/types";

export interface DeleteLineSheetSetRequest {}

export interface DeleteLineSheetSetResponse {}

export interface DeleteLineSheetSetParameter {
  by: CompanyType;
  companyId: number;
  lineSheetSetId: number;
}

export const deleteLineSheetSetTemplateUrl =
  "/:by/:companyId/line-sheet-sets/:lineSheetSetId";

export const deleteLineSheetSetKey: YWRKey<DeleteLineSheetSetParameter> = {
  url: (parameter) => {
    return calcUrl(deleteLineSheetSetTemplateUrl, parameter);
  },
  method: "delete",
};

export function parseDeleteLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<DeleteLineSheetSetParameter>(
    deleteLineSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "delete",
  };
}

export default function useDeleteLineSheetSet() {
  const fetcher = useFetcher<
    DeleteLineSheetSetRequest,
    DeleteLineSheetSetResponse
  >();
  return useYWR(deleteLineSheetSetKey, fetcher);
}
