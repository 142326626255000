import AppButton from "@/features/line-sheet-sets/app-button";
import { Icon, Flex, Text, ChakraProps } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import { useMemo, useState } from "react";
interface AppCheckBoxProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  size?: "small" | "medium" | "large";
  alignSelf?: string;
  marginRight?: ChakraProps["marginRight"];
  name?: string;
  onChange?: (checked: boolean) => void;
}
export default function AppCheckBox({
  isChecked = false,
  isDisabled = false,
  alignSelf,
  marginRight,
  size = "medium",
  name,
  onChange,
}: AppCheckBoxProps) {
  const [isHovered, setIsHovered] = useState(false);

  const sizeStyle = useMemo((): ChakraProps | undefined => {
    if (size === "medium") {
      return {
        w: "20px",
        h: "20px",
      };
    } else if (size === "small") {
      return {
        w: "16px",
        h: "16px",
      };
    }
  }, [size]);

  const icon = useMemo(() => {
    if (isChecked) {
      return (
        <Icon
          as={SVG}
          src={"/icons/icon_check.svg"}
          boxSize={"12px"}
          color={isHovered ? "#1272EF" : "#444440"}
        />
      );
    }
  }, [isHovered, isChecked]);

  return (
    <Flex flexDirection={"row"} alignItems={"start"} gap={"8px"}>
      <AppButton
        size={"small"}
        isDisabled={isDisabled}
        variant={"white"}
        chakraProps={{
          p: "4px",
          minW: "0px",
          alignSelf: alignSelf,
          marginRight: marginRight,
          flexShrink: "0",
          ...sizeStyle,
        }}
        onClick={() => !isDisabled && onChange && onChange(!isChecked)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {icon}
      </AppButton>
      {name && <Text fontSize={"12px"}>{name}</Text>}
    </Flex>
  );
}
