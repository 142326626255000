import { useRecoilCallback } from "recoil";
import {
  AppToast,
  appToastsState,
} from "@/features/line-sheet-sets/recoils/app-toasts-state";
import { uniqueId } from "lodash";
import { useCallback, useEffect } from "react";
import { isErrorResponse } from "@/utils/use-fetcher";
import { ValidationError } from "yup";
import { toLower } from "@/utils/case";
import useMemoizedValue from "@/features/ui/hooks/use-memoized-value";
import { produce } from "immer";
import { useToast } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import {
  ErrorResponse,
  isLocalizedMessage,
  LocalizedMessage,
} from "@/utils/uno-axios";
import { TFunction } from "i18next/typescript/t";
import { i18n } from "i18next";

export function isValidationError(obj: any): obj is ValidationError {
  return (obj as ValidationError).inner != undefined;
}

interface ErrorOption {
  id?: string;
  i18n?: {
    keyPrefix: string;
    ns?: string;
  };
}

function calcErrorResponseMessage(
  response: ErrorResponse,
  t: TFunction<"translation", undefined>,
  i18n: i18n,
  option?: ErrorOption
) {
  if (option?.i18n) {
    const { keyPrefix, ns } = option.i18n;
    if (keyPrefix) {
      const key1 = `${keyPrefix}.${response.error.code}.${toLower(
        response.error.status
      )}`;

      const key2 = `${keyPrefix}.${response.error.code}`;

      const keys = [key1, key2];
      const key = keys.find((i) => i18n.exists(i, { ns: ns || "common" }));

      if (key) {
        return t(key, { ns: ns || "common" });
      }
    }
  }

  if (response.error.details && response.error.details.length > 0) {
    const myLng = i18n.resolvedLanguage || "en";
    const localizedMessage = response.error.details.find((detail) => {
      if (isLocalizedMessage(detail)) {
        const lng = detail.message.split("-")[0];
        return myLng === lng;
      }
    }) as LocalizedMessage | undefined;

    if (localizedMessage) {
      return localizedMessage.message;
    }
  }

  if (response.error.message.length > 0) {
    return response.error.message;
  }

  const key = `message.error.http.${response.error.code}`;
  if (i18n.exists(key)) {
    return t(key);
  }
}

export default function useAppToasts(props?: ErrorOption) {
  const { t, i18n } = useI18nHelper();
  const toast = useToast();

  const defaultOption = useMemoizedValue(props);

  const remove = useRecoilCallback(
    ({ set }) =>
      (id: string) => {
        if (toast.isActive(id)) {
          toast.close(id);
        }

        set(appToastsState, (prev) => {
          return prev.filter((i) => i.id !== id);
        });
      },
    [toast]
  );

  const add = useRecoilCallback(
    ({ set }) =>
      (appToast: Omit<AppToast, "id"> & { id?: string }) => {
        set(appToastsState, (prev) => {
          const id = appToast.id || uniqueId("appToast_");

          return produce(prev, (draft) => {
            const index = draft.findIndex((i) => i.id === id);
            if (index === -1) {
              draft.push({ ...appToast, id });
            } else {
              draft[index] = { ...appToast, id };
            }
          });
        });
      },
    []
  );

  const error = useCallback(
    (error: any, option?: ErrorOption) => {
      if (error) {
        console.error(error);
        let message;

        if (isErrorResponse(error)) {
          message = calcErrorResponseMessage(error, t, i18n, option);
        } else if (isValidationError(error) && error.inner.length > 0) {
          const first = error.inner.find((i) => i.errors.length > 0);
          if (first) {
            message = first.errors[0];
          }
        }

        if (!message) {
          message = t("message.error.unknown");
        }

        if (message.length > 0) {
          add({
            status: "error",
            message,
            id: option?.id || defaultOption?.id,
          });
        }
      }
    },
    [add, t, i18n, defaultOption]
  );

  const warning = useCallback(
    (message: string, id?: string) => {
      add({ status: "warning", message, id: id || defaultOption?.id });
    },
    [add, defaultOption]
  );

  const info = useCallback(
    (message: string, id?: string) => {
      add({ status: "success", message, id: id || defaultOption?.id });
    },
    [add, defaultOption]
  );

  const close = useCallback(
    (id?: string) => {
      const actualId = id || defaultOption?.id;
      if (actualId) {
        remove(actualId);
      }
    },
    [remove, defaultOption?.id]
  );

  useEffect(() => {
    if (defaultOption?.id) {
      return () => {
        if (defaultOption?.id) {
          remove(defaultOption.id);
        }
      };
    }
  }, [defaultOption?.id, remove]);

  return { remove, add, error, warning, info, close };
}
