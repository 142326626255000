import { IdentityParameter } from "@/features/types";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import {
  getGetOrderSheetSetKey,
  GetOrderSheetSetRequest,
  GetOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-set";
import {
  GetOrderSheetSetStatisticRequest,
  GetOrderSheetSetStatisticResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-set-statistic";
import {
  getGetOrderSheetStatisticKey,
  GetOrderSheetStatisticRequest,
  GetOrderSheetStatisticResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-statistic";
import { FooStatistic } from "@/features/order-sheets/order-sheet.type";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";

interface ListAllOrderSheetStatisticsRequest {}

type ListAllOrderSheetStatisticsResponse = ({
  _orderSheetId: number;
} & FooStatistic)[];

interface ListAllOrderSheetStatisticPathParameter extends IdentityParameter {
  orderSheetSetId: number;
}

export const listAllOrderSheetStatisticTemplateUrl =
  "/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheet-statistics";

export function getListAllOrderSheetStatisticKey(
  parameter?: ListAllOrderSheetStatisticPathParameter,
  request?: ListAllOrderSheetStatisticsRequest
): SWRObjectKey<ListAllOrderSheetStatisticsRequest> | null {
  if (parameter && request) {
    return {
      url: calcUrl(listAllOrderSheetStatisticTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllOrderSheetStatisticKey(
  key: SWRObjectKey<ListAllOrderSheetStatisticsRequest>
) {
  const urlParser = new UrlParser<ListAllOrderSheetStatisticPathParameter>(
    listAllOrderSheetStatisticTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url),
    request: key.params!!,
  };
}

function withFetcher(
  getOrderSheetSetFetcher: (
    config: AxiosRequestConfig<GetOrderSheetSetRequest>
  ) => Promise<GetOrderSheetSetResponse>,
  getOrderSheetStatisticFetcher: (
    config: AxiosRequestConfig<GetOrderSheetStatisticRequest>
  ) => Promise<GetOrderSheetStatisticResponse>
) {
  return async (
    key: SWRObjectKey<ListAllOrderSheetStatisticsRequest>
  ): Promise<ListAllOrderSheetStatisticsResponse> => {
    const { parameter, request } = parseListAllOrderSheetStatisticKey(key);

    const getOrderSheetSetResponse = await getOrderSheetSetFetcher(
      getGetOrderSheetSetKey(parameter, {})
    );

    if (getOrderSheetSetResponse) {
      const orderSheetSetId = getOrderSheetSetResponse.id;
      const getOrderSheetStatisticPromises =
        getOrderSheetSetResponse.orderSheets.map(
          (orderSheet): Promise<GetOrderSheetStatisticResponse> => {
            return getOrderSheetStatisticFetcher(
              getGetOrderSheetStatisticKey(
                {
                  by: parameter.by,
                  companyId: parameter.companyId,
                  orderSheetSetId,
                  orderSheetId: orderSheet.id,
                },
                {}
              )!!
            );
          }
        );

      const getOrderSheetStatisticResponses = await Promise.all(
        getOrderSheetStatisticPromises
      );

      return Promise.resolve(
        getOrderSheetStatisticResponses.map((response, index) => {
          return {
            _orderSheetId: getOrderSheetSetResponse.orderSheets[index].id,
            ...response,
          };
        })
      );
    }

    return Promise.resolve([]);
  };
}

export function useListAllOrderSheetStatistics(
  parameter?: ListAllOrderSheetStatisticPathParameter,
  request?: ListAllOrderSheetStatisticsRequest,
  config: SWRConfiguration = {
    revalidateOnFocus: false,
  }
) {
  const getOrderSheetSetFetcher = useFetcher<
    GetOrderSheetSetRequest,
    GetOrderSheetSetResponse
  >();
  const getOrderSheetStatisticFetcher = useFetcher<
    GetOrderSheetStatisticRequest,
    GetOrderSheetStatisticResponse
  >();

  return useSWR(
    getListAllOrderSheetStatisticKey(parameter, request),
    withFetcher(getOrderSheetSetFetcher, getOrderSheetStatisticFetcher),
    config
  );
}
