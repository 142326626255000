import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { IssuedTo } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";

interface CreateIssuedToRequest {
  companyName: string;
  businessRegistrationNumber: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  detailAddress: string;
  zipCode: string;
}

interface CreateIssuedToResponse extends IssuedTo {}

interface CreateIssuedToPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createIssuedToTemplateUrl = `/:by/:companyId/issued_to`;

export const createIssuedToKey: YWRKey<CreateIssuedToPathParameter> = {
  url: (parameters: CreateIssuedToPathParameter) => {
    return calcUrl(createIssuedToTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateIssuedTo() {
  const fetcher = useFetcher<CreateIssuedToRequest, CreateIssuedToResponse>();
  return useYWR(createIssuedToKey, fetcher);
}
