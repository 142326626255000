import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useRecoilValue } from "recoil";
import { useEffect, useMemo } from "react";
import useGetOrderSheetSetStatistic, {
  GetOrderSheetSetStatisticPathParameter,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-set-statistic";
import { toIdentityParameter } from "@/features/ui/helpers/identity-helpers";
import useGetOrderSheetStatistic, {
  GetOrderSheetStatisticPathParameter,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-statistic";
import useIdentity from "@/features/ui/hooks/use-identity";
import {
  ConditionType,
  FooStatistic,
  LightOrderSheet,
} from "@/features/order-sheets/order-sheet.type";
import {
  isOrderSheetFlatSheetKey,
  clientSheetStatisticFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { MathUtils } from "@/features/ui/utils/math-utils";

export default function useCalcOrderSheetSetStatistic({
  orderSheetSetId,
  orderSheetId,
  revisionNumber,
  orderSheets,
}: {
  orderSheetSetId: number;
  orderSheetId: number;
  revisionNumber: number;
  orderSheets: LightOrderSheet[];
}) {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId,
      orderSheetId,
      revisionNumber,
    };
  }, [orderSheetSetId, orderSheetId, revisionNumber]);

  const sheetStatisticState = useRecoilValue(
    clientSheetStatisticFamily(sheetKey)
  );

  const getOrderSheetSetStatisticParameter = useMemo(():
    | GetOrderSheetSetStatisticPathParameter
    | undefined => {
    if (orderSheetSetId > -1) {
      return {
        ...toIdentityParameter(identity)!!,
        orderSheetSetId,
      };
    }
    return undefined;
  }, [orderSheetSetId, identity]);

  const {
    isLoading: isGetOrderSheetSetStatisticLoading,
    isValidating: isGetOrderSheetSetStatisticValidating,
    data: getOrderSheetSetStatisticData,
    error: getOrderSheetSetStatisticError,
    mutate: mutateGetOrderSheetSetStatistic,
  } = useGetOrderSheetSetStatistic(
    getOrderSheetSetStatisticParameter,
    {},
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(getOrderSheetSetStatisticError);
  }, [showError, getOrderSheetSetStatisticError]);

  const getOrderSheetStatisticParameter = useMemo(():
    | GetOrderSheetStatisticPathParameter
    | undefined => {
    if (orderSheetSetId > -1) {
      return {
        ...toIdentityParameter(identity)!!,
        orderSheetSetId,
        orderSheetId,
      };
    }
    return undefined;
  }, [orderSheetSetId, orderSheetId, identity]);

  const {
    isLoading: isGetOrderSheetStatisticLoading,
    isValidating: isGetOrderSheetStatisticValidating,
    data: getOrderSheetStatisticData,
    error: getOrderSheetStatisticError,
  } = useGetOrderSheetStatistic(
    getOrderSheetStatisticParameter,
    {},
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(getOrderSheetStatisticError);
  }, [showError, getOrderSheetStatisticError]);

  useEffect(() => {
    if (orderSheetId > -1) {
      mutateGetOrderSheetSetStatistic();
    }
  }, [orderSheetId, mutateGetOrderSheetSetStatistic]);

  const statistic = useMemo((): FooStatistic => {
    if (
      sheetStatisticState &&
      getOrderSheetStatisticData &&
      !isGetOrderSheetSetStatisticLoading &&
      !isGetOrderSheetSetStatisticValidating &&
      getOrderSheetSetStatisticData &&
      !isGetOrderSheetStatisticLoading &&
      !isGetOrderSheetStatisticValidating
    ) {
      if (isOrderSheetFlatSheetKey(sheetStatisticState.key)) {
        if (orderSheetId === sheetStatisticState.key.orderSheetId) {
          const orderSheetSetStatistic = getOrderSheetSetStatisticData;
          const orderSheetStatistic = getOrderSheetStatisticData;
          const clientSheetStatistic = sheetStatisticState.statistic;

          const orderedTypes = orderSheets
            .filter((os) => {
              return os.orderedQuantity > 0;
            })
            .map((os) => {
              if (os.id === orderSheetId) {
                return clientSheetStatistic.orderedConditionType;
              } else {
                return os.orderedConditionType;
              }
            });

          /**
           * orderSheets가 항상 mutate되는게 아니여서, 연산은 하되 노출은 하지 않도록 변경
           */
          let orderedConditionType: ConditionType;

          if (orderedTypes.length > 0) {
            orderedConditionType = orderedTypes.every(
              (ot) => ot === orderedTypes[0]
            )
              ? orderedTypes[0]
              : "MIX";
          } else {
            orderedConditionType = "MIX";
          }

          return {
            firstOrderedAmount:
              orderSheetSetStatistic.firstOrderedAmount !== null
                ? MathUtils.trim(
                    orderSheetSetStatistic.firstOrderedAmount -
                      (orderSheetStatistic.firstOrderedAmount || 0) +
                      (sheetStatisticState.statistic.firstOrderedAmount || 0),
                    3
                  )
                : null,
            firstOrderedQuantity:
              orderSheetSetStatistic.firstOrderedQuantity !== null
                ? MathUtils.trim(
                    orderSheetSetStatistic.firstOrderedQuantity -
                      (orderSheetStatistic.firstOrderedQuantity || 0) +
                      (sheetStatisticState.statistic.firstOrderedQuantity || 0),
                    3
                  )
                : null,
            firstOrderedAmountWithoutCondition:
              orderSheetSetStatistic.firstOrderedAmountWithoutCondition !== null
                ? MathUtils.trim(
                    orderSheetSetStatistic.firstOrderedAmountWithoutCondition -
                      (orderSheetStatistic.firstOrderedAmountWithoutCondition ||
                        0) +
                      (sheetStatisticState.statistic
                        .firstOrderedAmountWithoutCondition || 0),
                    3
                  )
                : null,
            firstOrderedConditionType: null,
            totalOrderedAmount: MathUtils.trim(
              orderSheetSetStatistic.totalOrderedAmount -
                orderSheetStatistic.totalOrderedAmount +
                sheetStatisticState.statistic.totalOrderedAmount,
              3
            ),
            totalOrderedQuantity:
              orderSheetSetStatistic.totalOrderedQuantity -
              orderSheetStatistic.totalOrderedQuantity +
              sheetStatisticState.statistic.totalOrderedQuantity,
            totalOrderedAmountWithoutCondition: MathUtils.trim(
              orderSheetSetStatistic.totalOrderedAmountWithoutCondition -
                orderSheetStatistic.totalOrderedAmountWithoutCondition +
                sheetStatisticState.statistic
                  .totalOrderedAmountWithoutCondition,
              3
            ),
            orderedConditionType: orderedConditionType,
            totalConfirmedAmount:
              MathUtils.trim(
                (orderSheetSetStatistic.totalConfirmedAmount || 0) -
                  (orderSheetStatistic.totalConfirmedAmount || 0) +
                  (sheetStatisticState.statistic.totalConfirmedAmount || 0),
                3
              ) || null,
            totalConfirmedQuantity:
              (orderSheetSetStatistic.totalConfirmedQuantity || 0) -
                (orderSheetStatistic.totalConfirmedQuantity || 0) +
                (sheetStatisticState.statistic.totalConfirmedQuantity || 0) ||
              null,
            totalConfirmedAmountWithoutCondition:
              MathUtils.trim(
                (orderSheetSetStatistic.totalConfirmedAmountWithoutCondition ||
                  0) -
                  (orderSheetStatistic.totalConfirmedAmountWithoutCondition ||
                    0) +
                  (sheetStatisticState.statistic
                    .totalConfirmedAmountWithoutCondition || 0),
                3
              ) || null,
            confirmedConditionType: null,
          };
        }
      }
    }
    return {
      firstOrderedAmount: null,
      firstOrderedQuantity: null,
      firstOrderedAmountWithoutCondition: null,
      firstOrderedConditionType: null,

      totalOrderedQuantity: 0,
      totalOrderedAmount: 0,
      totalOrderedAmountWithoutCondition: 0,
      orderedConditionType: "MIX",

      totalConfirmedAmount: null,
      totalConfirmedQuantity: null,
      confirmedConditionType: null,
      totalConfirmedAmountWithoutCondition: null,
    };
  }, [
    sheetStatisticState,
    getOrderSheetStatisticData,
    isGetOrderSheetSetStatisticLoading,
    isGetOrderSheetSetStatisticValidating,
    getOrderSheetSetStatisticData,
    isGetOrderSheetStatisticLoading,
    isGetOrderSheetStatisticValidating,
    orderSheetId,
    orderSheets,
  ]);

  return {
    statistic,
  };
}
