import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { Field, FieldProps, FormikHelpers, FormikProps } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useCallback } from "react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import AppInput from "@/features/ui/app-input";
import AppSeasonSelect from "@/features/line-sheet-sets/app-season-select";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import {
  AbstractInvoice,
  PaymentMethod,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import AppPaymentMethodSelect from "@/features/ui/app-payment-method-select";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import AppPaidByAgencySelect from "@/features/ui/app-paid-by-agency-select";
import AppTextArea from "@/features/ui/app-text-area";
import AppRemittanceStatusLabel from "@/features/ui/app-remittance-status-label";
import AppInvoiceStatusSelect from "@/features/invoices/order-confirmation-invoices/app-invoice-status-select";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import CreateCompanyModal, {
  CreateCompanyModalProps,
} from "@/features/ui/create-company-modal";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppInvoiceStatusLabel from "@/features/ui/app-invoice-status-label";

interface ProformaInvoiceInformationFormControlProps {
  formik: FormikProps<FormFields>;
  isEditing?: boolean;
  isDisabled?: boolean;
}

interface FormFields extends ProformaInvoiceFormFields {}

export default function ProformaInvoiceInformationFormControl({
  formik,
  isEditing,
  isDisabled,
}: ProformaInvoiceInformationFormControlProps) {
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();

  type LINK_AFFECTING_KEYS =
    | "season"
    | "issuedBy"
    | "orderedBy"
    | "originallyIssuedBy"
    | "issuedThrough"
    | "paymentMethod"
    | "paidByAgency";

  const handleLinkAffectingFieldChange = useCallback(
    <T extends LINK_AFFECTING_KEYS>(
      key: LINK_AFFECTING_KEYS,
      value: FormFields[T],
      setValues: FormikHelpers<FormFields>["setValues"]
    ) => {
      return setValues((prev) => {
        let next = {
          ...prev,
          [key]: value,
          //orderSheetSets: [],
          //orderConfirmationInvoices: [],
        };

        if (
          key === "issuedBy" &&
          value &&
          (value as FormFields["issuedBy"])?.type === "BOUTIQUE" &&
          next.originallyIssuedBy === undefined
        ) {
          // @ts-ignore
          next.originallyIssuedBy = { ...value };
        } else if (
          key === "originallyIssuedBy" &&
          value &&
          next.orderedBy === undefined
        ) {
          // @ts-ignore
          next.orderedBy = { ...value };
        }

        const prevIssuedBy = prev.originallyIssuedBy || prev.issuedBy;
        const nextIssuedBy = next.originallyIssuedBy || next.issuedBy;

        if (key === "paymentMethod" && value === "LOAN") {
          next.paidByAgency = true;
        }

        if (
          !(
            prev.season === next.season &&
            prev.orderedBy?.id === next.orderedBy?.id &&
            prev.issuedThrough?.id === next.issuedThrough?.id &&
            prevIssuedBy?.id === nextIssuedBy?.id &&
            prev.paidByAgency === next.paidByAgency
          )
        ) {
          next.orderSheetSets = [];
          next.orderConfirmationInvoices = [];
          next.childProformaInvoices = [];
          next.proformaInvoiceDetailList = next.proformaInvoiceDetailList.map(
            (pDetail) => {
              return {
                ...pDetail,
                orderSheetSet: undefined,
                orderConfirmationInvoiceDetail: undefined,
              };
            }
          );
        }

        // next.orderConfirmationInvoiceDetailList =
        //   next.orderConfirmationInvoiceDetailList.map((ocDetail) => {
        //     return {
        //       ...ocDetail,
        //       brand: undefined,
        //       orderSheetSet: undefined,
        //     };
        //   });

        return next;
      });
    },
    []
  );

  const {
    isOpen: isCreateCompanyModelOpen,
    onClose: onCreateCompanyModalClose,
    data: createCompanyModalData,
    openWithData: openCreateCompanyModal,
  } = useDisclosureWithData<CreateCompanyModalProps>();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          rowGap={"8px"}
          gridTemplateRows={"repeat(7, 16px 24px) repeat(2, 16px 60px)"}
        >
          {(Array(18).fill(0) as number[]).map((_, index) => {
            return <Skeleton key={index} />;
          })}
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:invoice_information.abbr")}>
      <Grid rowGap={"8px"}>
        {!isEditing && (
          <>
            <Field name={"status"}>
              {(props: FieldProps<FormFields["status"], FormFields>) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("status"))}
                    </FormLabel>
                    <Spacer />
                    <AppInvoiceStatusLabel value={props.field.value} />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"isRemittedToBoutique"}>
              {(
                props: FieldProps<
                  FormFields["isRemittedToBoutique"],
                  FormFields
                >
              ) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("remittance_status_to_boutique"))}
                    </FormLabel>
                    <Spacer />
                    <AppRemittanceStatusLabel
                      value={
                        props.field.value !== undefined
                          ? props.field.value
                            ? "REMITTED"
                            : "UNREMITTED"
                          : undefined
                      }
                    />
                  </FormControl>
                );
              }}
            </Field>

            {formik.values.paidByAgency && (
              <Field name={"isRemittedToAgency"}>
                {(
                  props: FieldProps<
                    FormFields["isRemittedToAgency"],
                    FormFields
                  >
                ) => {
                  return (
                    <FormControl
                      display={"flex"}
                      flexDirection={"row"}
                      gap={"4px"}
                      isRequired={isEditing}
                      fontSize={"12px"}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        color={"#6F6F6C"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {toTitle(t("common:remittance_status_to_agency"))}
                      </FormLabel>
                      <Spacer />
                      <AppRemittanceStatusLabel
                        value={
                          props.field.value !== undefined
                            ? props.field.value
                              ? "REMITTED"
                              : "UNREMITTED"
                            : undefined
                        }
                      />
                    </FormControl>
                  );
                }}
              </Field>
            )}

            <Box
              height={"8px"}
              borderBottom={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
            ></Box>
          </>
        )}

        <Field name={"name"}>
          {(props: FieldProps<FormFields["name"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("title"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"number"}>
          {(props: FieldProps<FormFields["number"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("number"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"status"}>
            {(props: FieldProps<FormFields["status"], FormFields>) => {
              let excludeStatuses: AbstractInvoice["status"][] = [];
              if (props.form.values.id === -1) {
                excludeStatuses = ["CANCELED", "COMPLETED"];
              }

              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("status"))}
                  </FormLabel>
                  <AppInvoiceStatusSelect
                    value={props.field.value}
                    isReadOnly={!isEditing}
                    excludeStatuses={excludeStatuses}
                    onSelect={(value) => {
                      props.form.setValues((prev) => {
                        return {
                          ...prev,
                          status: value,
                        };
                      });
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        <Field name={"season"}>
          {(props: FieldProps<FormFields["season"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("season"))}
                </FormLabel>
                <AppSeasonSelect
                  width={"100%"}
                  value={props.field.value}
                  isDisabled={isDisabled}
                  readonly={!isEditing}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "season",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"lineSheetSetType"}>
            {(
              props: FieldProps<FormFields["lineSheetSetType"], FormFields>
            ) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("line_sheet_set_type"))}
                  </FormLabel>
                  <AppLineSheetTypeSelector
                    value={props.field.value}
                    width={"100%"}
                    isReadOnly={!isEditing}
                    isClearable={true}
                    onSelect={(value) => {
                      props.form.setValues({
                        ...props.form.values,
                        lineSheetSetType: value,
                      });
                    }}
                    isDisabled={
                      isDisabled &&
                      (props.form.values.orderSheetSets.length > 0 ||
                        props.form.values.orderConfirmationInvoices.length > 0)
                    }
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "BUYER" && (
          <Field name={"orderedBy"}>
            {(props: FieldProps<FormFields["orderedBy"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("ordered_by"))}
                  </FormLabel>
                  <AppCompanySelect
                    name={tTitle("ordered_by")}
                    types={["BUYER"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    isReadOnly={!isEditing}
                    isDisabled={isDisabled}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "orderedBy",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "AGENCY" &&
          identity?.company?.type !== "BOUTIQUE" && (
            <Field name={"issuedThrough"}>
              {(props: FieldProps<FormFields["issuedThrough"], FormFields>) => {
                return (
                  <Flex flexDirection={"row"} alignItems={"end"} gap={"8px"}>
                    <FormControl
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"4px"}
                      isRequired={isEditing}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        color={"#6F6F6C"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {toTitle(t("issued_through"))}
                      </FormLabel>

                      <AppCompanySelect
                        name={tTitle("issued_through")}
                        types={["AGENCY"]}
                        width={"100%"}
                        id={props.field.value?.id}
                        isReadOnly={!isEditing}
                        isDisabled={isDisabled}
                        onSelect={(value) => {
                          handleLinkAffectingFieldChange(
                            "issuedThrough",
                            value,
                            props.form.setValues
                          );
                        }}
                      />
                    </FormControl>
                    {isEditing && (
                      <AppButton
                        onClick={() => {
                          openCreateCompanyModal(() => {
                            return {
                              targetBy: "AGENCY",
                              onCreate: (value) => {
                                if (!isDisabled) {
                                  handleLinkAffectingFieldChange(
                                    "issuedThrough",
                                    value,
                                    props.form.setValues
                                  );
                                }
                              },
                            };
                          });
                        }}
                      >
                        {tTitle("add")}
                      </AppButton>
                    )}
                  </Flex>
                );
              }}
            </Field>
          )}

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"issuedBy"}>
            {(props: FieldProps<FormFields["issuedBy"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("issued_by"))}
                  </FormLabel>
                  <AppCompanySelect
                    name={toTitle(t("issued_by"))}
                    types={["BOUTIQUE", "CROSS_TRADER"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    isDisabled={isDisabled}
                    isReadOnly={!isEditing}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "issuedBy",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"originallyIssuedBy"}>
            {(
              props: FieldProps<FormFields["originallyIssuedBy"], FormFields>
            ) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  // isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("originally_issued_by"))}
                  </FormLabel>
                  <AppCompanySelect
                    name={toTitle(t("originally_issued_by"))}
                    types={["BOUTIQUE"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    isDisabled={isDisabled}
                    isReadOnly={!isEditing}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "originallyIssuedBy",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        <Field name={"issuedOn"}>
          {(props: FieldProps<Date, FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                isRequired={isEditing}
                gap={"4px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("issued_on"))}
                </FormLabel>
                <AppDatePicker
                  width={"100%"}
                  showTimeInput={false}
                  name={tTitle("issued_on")}
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  isDisabled={isDisabled}
                  onChange={(value) => {
                    props.form.setFieldValue("issuedOn", value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"paymentMethod"}>
            {(props: FieldProps<PaymentMethod | undefined, FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  isRequired={isEditing}
                  gap={"4px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("payment_method"))}
                  </FormLabel>
                  <AppPaymentMethodSelect
                    excludeSkip={true}
                    value={props.field.value}
                    isDisabled={isDisabled}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "paymentMethod",
                        value,
                        props.form.setValues
                      );
                    }}
                    isReadOnly={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type === "AGENCY" && (
          <Field name={"exposedToBuyer"}>
            {(props: FieldProps<FormFields["exposedToBuyer"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <AppCheckBox
                    isChecked={props.field.value}
                    isDisabled={!isEditing}
                    name={toTitle(t("exposed_to_buyer"))}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type !== "BOUTIQUE" && (
          <Field name={"paidByAgency"}>
            {(props: FieldProps<FormFields["paidByAgency"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("paid_by"))}
                  </FormLabel>
                  <AppPaidByAgencySelect
                    value={props.field.value}
                    isReadOnly={!isEditing}
                    isDisabled={
                      isDisabled &&
                      (props.form.values.isRemittedToBoutique ||
                        props.form.values.paymentMethod === "LOAN")
                    }
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "paidByAgency",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        <Field name={"publicComment"}>
          {(props: FieldProps<FormFields["publicComment"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {identity?.company?.type === "AGENCY"
                    ? `${toTitle(t("public_comment"))}(${t(
                        "public_comment.note"
                      )})`
                    : `${toTitle(t("comment"))}`}
                </FormLabel>
                <AppTextArea
                  flexGrow={"1"}
                  fontSize={"12px"}
                  rows={4}
                  placeholder={isEditing ? t("comment_placeholder") : undefined}
                  value={props.field.value || ""}
                  onChange={(e) => {
                    props.form.setFieldValue("publicComment", e.target.value);
                  }}
                  isReadOnly={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type === "AGENCY" && (
          <Field name={"privateComment"}>
            {(props: FieldProps<FormFields["privateComment"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    marginBottom={"4px"}
                  >
                    {toTitle(t("private_comment"))}
                  </FormLabel>
                  <AppTextArea
                    flexGrow={"1"}
                    minHeight={"0"}
                    fontSize={"12px"}
                    rows={4}
                    placeholder={
                      isEditing ? t("comment_placeholder") : undefined
                    }
                    value={props.field.value || ""}
                    onChange={(e) => {
                      props.form.setFieldValue(
                        "privateComment",
                        e.target.value
                      );
                    }}
                    isReadOnly={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}
      </Grid>
      {isCreateCompanyModelOpen && createCompanyModalData && (
        <CreateCompanyModal
          isOpen={isCreateCompanyModelOpen}
          onClose={onCreateCompanyModalClose}
          {...createCompanyModalData}
        />
      )}
    </AppPanel>
  );
}
