import { Company, Price } from "@/features/types";
import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export class InvoiceUtils {
  static getCurrencyByParties({
    sender,
    receiver,
  }: {
    sender?: Company;
    receiver?: Company;
  }): Price["currency"] {
    if (receiver?.type === "AGENCY") {
      return "KRW";
    } else {
      return "EUR";
    }
  }

  static getPaidByParties({
    sender,
    receiver,
  }: {
    sender?: Company;
    receiver?: Company;
  }): AbstractInvoice["paidBy"] | undefined {
    if (sender && receiver) {
      if (sender.type === "BUYER" && receiver.type === "BOUTIQUE") {
        return "BUYER";
      } else {
        return "AGENCY";
      }
    }
    return undefined;
  }
}
