import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { DenseOrderSheet } from "@/features/order-sheets/order-sheet.type";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useIdentity from "@/features/ui/hooks/use-identity";

// export type OrderSheetSelectValue = Pick<LineSheet, "name"> &
//   Pick<OrderSheet, "id"> & {
//     lineSheetId: number;
//   };

export type OrderSheetSelectValue = DenseOrderSheet | LightLineSheet;

export interface OrderSheetSelectorProps {
  values: OrderSheetSelectValue[];
  value?: OrderSheetSelectValue;
  onSelect: (value?: OrderSheetSelectValue) => void;
  width?: string;
  minWidth?: string;
}

function isDenseOrderSheet(
  value: DenseOrderSheet | LightLineSheet
): value is DenseOrderSheet {
  return (value as LightLineSheet).name === undefined;
}

export default function OrderSheetOrLineSheetSelector({
  values,
  value,
  onSelect,
  width,
  minWidth,
}: OrderSheetSelectorProps) {
  const identity = useIdentity();

  const { tTitle } = useI18nHelper();
  const options = values.map((value) => {
    if (isDenseOrderSheet(value)) {
      return {
        name:
          identity?.company?.type === "AGENCY"
            ? `#${value.id} ${value.lineSheet.name}`
            : value.lineSheet.name,
        suffix: value.suffix,
        value,
      };
    } else {
      return {
        name:
          identity?.company?.type === "AGENCY"
            ? `#${value.id} ${value.name}`
            : value.name,
        value,
      };
    }
  });

  return (
    <AppSelect<OrderSheetSelectValue>
      name={tTitle("line_sheet")}
      width={width}
      minWidth={minWidth}
      options={options}
      value={value}
      isClearable={false}
      isMultiple={false}
      idFunc={(value) => {
        if (isDenseOrderSheet(value)) {
          return `OrderSheetSelect_${value.id}_${value.lineSheet.id}`;
        } else {
          return `OrderSheetSelect_-1_${value.id}`;
        }
      }}
      onSelect={onSelect}
    />
  );
}
