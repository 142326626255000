import { Box, ChakraProps, Flex, Grid, Heading } from "@chakra-ui/react";
import React, { useMemo } from "react";

interface AppPanelProps {
  title?: string | React.ReactNode;
  variant?: "normal" | "ghost";
  isCompact?: boolean;
  children: React.ReactNode;
}

function calcStyle({
  variant,
  isCompact,
}: Pick<AppPanelProps, "variant" | "isCompact">) {
  let style: ChakraProps = {};

  if (!isCompact) {
    style = {
      padding: "16px 24px",
    };
  }

  if (variant === "normal") {
    return {
      ...style,
      borderRadius: "8px",
      border: "0.5px solid #8F8F8C",
      background: "#FFF",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
    };
  } else if (variant === "ghost") {
    return {
      ...style,
    };
  }

  return style;
}

export default function AppPanel({
  title,
  children,
  variant = "normal",
  isCompact = false,
}: AppPanelProps) {
  const titleComponent = useMemo(() => {
    if (title) {
      if (typeof title === "string") {
        return (
          <Heading as={"h3"} fontSize={"14px"}>
            {title}
          </Heading>
        );
      } else {
        return title;
      }
    }
    return null;
  }, [title]);

  return (
    <Flex flexDirection={"column"} gap={"8px"}>
      {titleComponent}
      <Box {...calcStyle({ variant, isCompact })}>{children}</Box>
    </Flex>
  );
}
