import { FlatProductImageUrl } from "@/features/line-sheet-sets/helpers/sheet-state";
import { Box, Grid, Image } from "@chakra-ui/react";
import AppLabel from "@/features/ui/app-label";
import { times } from "lodash";
import { useMemo } from "react";
import { toLower } from "@/utils/case";

interface ProductImageLabelProps {
  value: FlatProductImageUrl | FlatProductImageUrl[];
}

const COLUMN_COUNT = 4;
const UNIT = 24 * 3;

export default function ProductImageLabel({
  value: rawValue,
}: ProductImageLabelProps) {
  const value = useMemo(() => {
    if (Array.isArray(rawValue)) {
      return rawValue;
    } else {
      return [rawValue];
    }
  }, [rawValue]);

  return (
    <Grid
      border={"0.5px solid var(--gray-dark, #444440)"}
      gridTemplateColumns={`repeat(${
        value.length >= COLUMN_COUNT ? COLUMN_COUNT : value.length
      }, 1fr)`}
      gap={"1px"}
      gridAutoColumns={"1fr"}
    >
      {value.map((item, index) => (
        <Box
          key={item.url}
          position={"relative"}
          // border={"0.5px solid var(--gray-dark, #444440)"}
          // boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.20)"}
        >
          <Image
            loading={"lazy"}
            key={item.url}
            src={item.url}
            alt={"alt"}
            height={`${UNIT * 6}px`}
            width={`${UNIT * 4}px`}
            backgroundColor={"#fff"}
            objectFit={"contain"}
          />
          <Box position={"absolute"} top={"8px"} left={"8px"}>
            <AppLabel
              isToUpper={false}
              nameKey={`product_image.type.${toLower(item.type)}`}
              level={item.type === "ORIGINAL" ? "PRIMARY" : "SUCCESS"}
            />
          </Box>
        </Box>
      ))}

      {value.length > COLUMN_COUNT &&
        value.length % COLUMN_COUNT !== 0 &&
        times(COLUMN_COUNT - (value.length % COLUMN_COUNT)).map((value) => {
          return (
            <Box
              key={value}
              height={`${UNIT * 6}px`}
              width={`${UNIT * 4}px`}
              backgroundColor={"#fff"}
            ></Box>
          );
        })}
    </Grid>
  );
}
