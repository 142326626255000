import { useNavigate } from "react-router";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { clearTokens } from "@/utils/jwt";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

export default function AppMyAccountContextMenu() {
  const { tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const actions = [
    {
      name: tTitle("common:settings"),
      onClick: () => {
        navigate("/my-account");
      },
    },
    {
      name: tTitle("sign_out.do"),
      onClick: () => {
        clearTokens();

        sessionStorage.setItem("signOut", "true");

        navigate("/auth/sign-in", {
          replace: true,
        });
      },
    },
  ];

  return (
    <Popover
      placement={"bottom-start"}
      arrowShadowColor={"#8F8F8C"}
      trigger={"hover"}
    >
      <PopoverTrigger>
        <Button
          variant={"ghost"}
          p={"0"}
          m={"0"}
          height={"32px"}
          width={"32px"}
          color={"#8F8F8C"}
          _hover={{
            borderColor: "#1272EF",
            borderLeftColor: "transparent",
            color: "#1272EF",
          }}
        >
          <SVG
            src={"/icons/icon_ellipsis.svg"}
            width={"20px"}
            height={"14px"}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent width={"192px"}>
        <PopoverArrow />
        <PopoverBody p={"0"}>
          <Flex
            flexDirection={"column"}
            width={"100%"}
            boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.15)"}
          >
            {actions.map((action, index) => {
              const isFirst = index === 0;
              const isLast = index === actions.length - 1;

              let _hover = {
                color: "#1272EF",
                backgroundColor: "#F7F7F7",
              };

              return (
                <Button
                  key={index}
                  size={"medium"}
                  onClick={() => {
                    action.onClick();
                  }}
                  width={"100%"}
                  fontSize={"12px"}
                  padding={"12px 24px"}
                  backgroundColor={"#fff"}
                  border={"0.5px solid #8F8F8C"}
                  borderRadius={
                    isFirst ? "4px 4px 0 0" : isLast ? "0 0 4px 4px" : "0"
                  }
                  opacity={"1.0 !important"}
                  borderBottomColor={!isLast ? "transparent" : "#8F8F8C"}
                  _hover={_hover}
                >
                  {action.name}
                </Button>
              );
            })}
            {/*<Button*/}
            {/*  size={"medium"}*/}
            {/*  onClick={() => {*/}
            {/*    navigate("/my-account")*/}
            {/*  }}*/}
            {/*  width={"100%"}*/}
            {/*  fontSize={"12px"}*/}
            {/*  padding={"8px 24px"}*/}
            {/*  backgroundColor={"#fff"}*/}
            {/*  border={"0.5px solid #8F8F8C"}*/}
            {/*  borderRadius={*/}
            {/*    "4px 4px 0 0"*/}
            {/*  }*/}
            {/*  opacity={"1.0 !important"}*/}
            {/*  borderBottomColor={"transparent"}*/}
            {/*  // color={"#D4D4D1"}*/}
            {/*>*/}
            {/*  {tTitle("common\:settings")}*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  size={"medium"}*/}
            {/*  onClick={() => {*/}
            {/*    clearTokens();*/}
            {/*    navigateTo("/auth/sign-in", {*/}
            {/*      replace: true,*/}
            {/*    });*/}
            {/*  }}*/}
            {/*  width={"100%"}*/}
            {/*  fontSize={"12px"}*/}
            {/*  padding={"8px 24px"}*/}
            {/*  backgroundColor={"#fff"}*/}
            {/*  border={"0.5px solid #8F8F8C"}*/}
            {/*  borderRadius={*/}
            {/*    "0 0 4px 4px"*/}
            {/*  }*/}
            {/*  opacity={"1.0 !important"}*/}
            {/*  borderBottomColor={"#8F8F8C"}*/}
            {/*  // color={"#D4D4D1"}*/}
            {/*>*/}
            {/*  {tTitle("sign_out.do")}*/}
            {/*</Button>*/}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
