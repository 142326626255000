import { TriangularTrade } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface GetTriangularTradeRequest {}

export interface GetTriangularTradeResponse extends TriangularTrade {}

export interface GetTriangularTradePathParameter {
  by: CompanyType;
  companyId: number;
  triangularTradeId: number;
}

export const getTriangularTradeTemplateUrl = `/:by/:companyId/triangular-trades/:triangularTradeId`;

export function getGetTriangularTradeKey(
  parameter?: GetTriangularTradePathParameter,
  request?: GetTriangularTradeRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getTriangularTradeTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useGetTriangularTrade(
  parameter?: GetTriangularTradePathParameter,
  request?: GetTriangularTradeRequest,
  config?: SWRConfiguration<GetTriangularTradeResponse>
) {
  const fetcher = useFetcher<
    GetTriangularTradeRequest,
    GetTriangularTradeResponse
  >();
  return useSWR(getGetTriangularTradeKey(parameter, request), fetcher, config);
}
