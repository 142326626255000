import { Flex, Input } from "@chakra-ui/react";
import React, { useCallback, useMemo, useRef } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import useIdentity from "@/features/ui/hooks/use-identity";
import * as Yup from "yup";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppFormModal from "@/features/ui/app-form-modal";
import useFetchLineSheetSetsFromEmail from "@/features/line-sheet-sets/hooks/use-fetch-line-sheet-sets-from-email";
import AppSelect from "@/features/line-sheet-sets/app-select";

interface SyncGmailModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormFields {
  requestType: "period" | "gmailIds";
  value: string;
}

export default function SyncGmailModal({
  isOpen,
  onClose,
}: SyncGmailModalProps) {
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const formikRef = useRef<FormikProps<FormFields>>(null);

  const { withMessage } = useYupHelper({
    prefix: "upload_inventory_form",
    ns: "inventories",
  });
  const { error: showError } = useAppToasts({
    id: "UPLOAD_INVENTORY_FORM",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      value: Yup.string().label("value").required(withMessage.required),
    });
  }, [withMessage]);

  const validate = useCallback(
    async (values: FormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e: any) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const {
    data: fetchLineSheetSetsFromEmailData,
    fire: fireFetchLineSheetSetsFromEmail,
    error: fetchLineSheetSetsFromEmailError,
  } = useFetchLineSheetSetsFromEmail();

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      const requestType = values.requestType;
      const value = values.value;

      fireFetchLineSheetSetsFromEmail(
        {},
        {
          period: requestType === "period" ? parseInt(value) : undefined,
          gmailId__in:
            requestType === "gmailIds" ? value.split(",") : undefined,
          make_line_sheet_sets: false,
          force: true,
        }
      );
      onClose();
    },
    [fireFetchLineSheetSetsFromEmail, onClose]
  );

  return (
    <Formik<FormFields>
      initialValues={{
        requestType: "period",
        value: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={(values, formikHelpers) => {
        return handleSubmit(values);
      }}
      innerRef={formikRef}
    >
      <AppFormModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Gmail Sync"}
        submitName={"가져오기"}
      >
        <Form>
          <Flex flexDirection={"row"} fontSize={"12px"} gap={"8px"}>
            <Field name={"requestType"}>
              {({ field }: FieldProps) => (
                <AppSelect
                  name={t("type")}
                  options={[
                    { name: "Period", value: "period" },
                    { name: "Gmail IDs", value: "gmailIds" },
                  ]}
                  width={"300px"}
                  value={field.value}
                  onSelect={(v) => {
                    formikRef.current?.setFieldValue("requestType", v);
                  }}
                  isClearable={false}
                />
              )}
            </Field>
            <Field name={"value"}>
              {({ field }: FieldProps) => (
                <Input
                  mt={"4px"}
                  borderRadius={"4px"}
                  fontSize={"12px"}
                  height={"auto"}
                  p={"4px 12px"}
                  autoComplete={"off"}
                  padding={"0 12px"}
                  margin={"0"}
                  {...field}
                />
              )}
            </Field>
          </Flex>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
