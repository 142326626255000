import useListTriangularTrades, {
  ListTriangularTradesRequest,
} from "@/features/invoices/triangular-trades/hooks/use-list-triangular-trades";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";
import { TriangularTradeRow } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { triangularTradeRowMapper } from "@/features/invoices/triangular-trades/utils/triangular-trade-row-mapper";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import AppButton from "@/features/line-sheet-sets/app-button";
import TriangularTradeStatusLabel from "@/features/invoices/triangular-trades/triangular-trade-status-label";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";

export interface PersistedTriangularTradeListPageQuery
  extends Partial<ListTriangularTradesRequest> {}

export default function TriangularTradeListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { t, tCurrencyString, tTitle, tLocalDateString } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const [query, setQuery] =
    useTypedSearchParams<PersistedTriangularTradeListPageQuery>();
  const listTriangularTradesKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_INVOICES_PAGE_SIZE,
        },
      };
    }
  }, [identity, query]);

  const {
    data: listTriangularTradesData,
    error: listTriangularTradesError,
    mutate: mutateListTriangularTrades,
  } = useListTriangularTrades(
    listTriangularTradesKey?.parameter,
    listTriangularTradesKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (listTriangularTradesError) {
      showError(listTriangularTradesError);
    }
  }, [listTriangularTradesError, showError]);

  const [rows, setRows] = useState<TriangularTradeRow[]>();
  const rowMapper = useMemo(() => {
    return triangularTradeRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(listTriangularTradesData?.triangularTrades, prev);
    });
  }, [listTriangularTradesData, rowMapper]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    async function refresh() {
      setIsRefreshing(true);
      await mutateListTriangularTrades();
      setIsRefreshing(false);
    }

    let intervalId: NodeJS.Timer | null = null;

    if (
      listTriangularTradesData &&
      listTriangularTradesData.triangularTrades.some(
        (triangularTrade) => triangularTrade.status === "PENDING"
      )
    ) {
      intervalId = setInterval(refresh, 5 * 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [listTriangularTradesData, mutateListTriangularTrades]);

  const appGrid = useAppGrid();

  return (
    <>
      <Helmet>
        <title>Pathrade | {tTitle("common:triangular_trades")}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("triangular_trades")}`}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[]}
          actions={[
            () => {
              if (identity?.company?.type === "BOUTIQUE") {
                return (
                  <AppButton
                    key="upload"
                    variant={"primary"}
                    size={"medium"}
                    onClick={() => {
                      navigate("/triangular-trades/upload");
                    }}
                  >
                    {tTitle("upload_triangular_trade")}
                  </AppButton>
                );
              }
            },
          ]}
        />

        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <AppGrid
            rows={rows}
            metas={[
              {
                gap: "8px",
                rowType: "TriangularTrade",
                columns: [
                  {
                    name: tTitle("common:originally_issued_by.abbr"),
                    width: "144px",
                  },
                  {
                    name: tTitle("original_file_name"),
                    width: "1fr",
                  },
                  {
                    name: tTitle("status"),
                    width: "144px",
                  },
                  {
                    name: tTitle("uploaded_on"),
                    width: "144px",
                  },
                ],
                fields: [
                  {
                    name: "originally_issued_by",
                    value: (_row) => {
                      const row = _row as TriangularTradeRow;
                      return row.originallyIssuedBy;
                    },
                  },
                  {
                    name: "original_file_name",
                    value: (_row) => {
                      const row = _row as TriangularTradeRow;
                      const totalFiles = row.importedFiles?.length || 0;
                      return row.importedFiles?.[0]?.name + (totalFiles > 1 ? ` +${totalFiles - 1}` : "");
                    },
                  },
                  {
                    name: "status",
                    value: (_row) => {
                      const row = _row as TriangularTradeRow;
                      if (isRefreshing && row.status === "PENDING") {
                        return <Spinner size={"sm"} />;
                      }
                      return <TriangularTradeStatusLabel value={row.status} />;
                    },
                  },
                  {
                    name: "uploaded_on",
                    value: (_row) => {
                      const row = _row as TriangularTradeRow;
                      return tLocalDateString(row.createdAt);
                    },
                  },
                ],
                actions: [
                  appGrid.actions.withDownloadUnoFile<TriangularTradeRow>(
                    (row) => row.exportedFile
                  ),
                ],
                onClick: (row) => {
                  navigate(`/triangular-trades/${row.id}`);
                },
              },
            ]}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listTriangularTradesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) => {
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              });
            }}
          />
        </Flex>
      </Flex>
    </>
  );
}
