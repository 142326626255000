import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { AppGridContext } from "@/features/order-sheet-sets/app-grid";
import AppStatusLabel from "@/features/ui/app-status-label";
import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

interface AppRemittanceStatusByRowLabelProps<T> {
  row: T;
  context: AppGridContext;
}

export default function AppRemittanceStatusByRowLabel<
  T extends GridRow & {
    isRead?: boolean;
    isRemitted: boolean;
    paymentMethod: AbstractInvoice["paymentMethod"];
  }
>({ row, context }: AppRemittanceStatusByRowLabelProps<T>) {
  let status = row.isRemitted ? "REMITTED" : "REMITTANCE_REQUIRED";
  if (row.paymentMethod === "SKIP") {
    status = "REMITTANCE_SKIPPED";
  }
  return (
    <AppStatusLabel<T>
      i18nPrefix={"remittance_status"}
      keyOrFunc={(row) => status}
      row={row}
      context={context}
      isSuccessPredicate={(row) => !row.isRemitted}
    />
  );
}
