import { StoredObject } from "@/features/types";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import AppTable from "@/features/line-sheet-sets/app-table";
import StoredObjectCell from "@/features/inventories/stored-object-cell";
import { useCallback } from "react";
import { findYWRInfiniteSegment } from "@/features/ui/helpers/ywr-helpers";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";

interface StoredObjectExtraContentProps {
  storedObjects: StoredObject[];
}
export default function StoredObjectExtraContent({
  storedObjects,
}: StoredObjectExtraContentProps) {
  const { fire: fireDownloadUnoFile, data: downloadUnoFileData } =
    useDownloadUnoFileInfinite();

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  return (
    <AppTable
      isHeadless={true}
      headers={[
        {
          value: "name",
          width: "1fr",
        },
      ]}
      rowHeight={"32px"}
      rows={storedObjects.map((storedObject) => {
        const segment = findDownloadUnoFileSegment(storedObject.id);

        return [
          {
            value: (
              <StoredObjectCell
                storageObject={storedObject}
                isLoading={segment?.isLoading || false}
                onClick={() => {
                  fireDownloadUnoFile(
                    {
                      unoFileId: storedObject.id,
                    },
                    {}
                  );
                }}
              />
            ),
          },
        ];
      })}
      gap={"8px"}
    />
  );
}
