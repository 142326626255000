import { useMemo } from "react";
import AppInstructionTooltipLabel from "@/features/ui/app-toottip-label";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface OrderSheetTooltipRowLabelProps {
  isFallback?: boolean;
}
export default function OrderSheetRowTooltipLabel({
  isFallback = false,
}: OrderSheetTooltipRowLabelProps) {
  const { t, tTitle } = useI18nHelper();
  const { header, body, instructions } = useMemo(() => {
    const header = tTitle(isFallback ? "write_fallback_draft" : "write_draft");
    const text = t(isFallback ? "how_to_order.fallback" : "how_to_order").split(
      "\n"
    );
    return {
      header,
      body: text[0],
      instructions: text.slice(1),
    };
  }, [t, tTitle, isFallback]);

  return (
    <AppInstructionTooltipLabel
      header={header}
      body={body}
      instructions={instructions}
    />
  );
}
