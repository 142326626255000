import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import React, { useCallback, useEffect, useMemo } from "react";
import { isStoredObject } from "@/features/types";
import { DepositInvoiceFormFields } from "@/features/invoices/deposit-invoices/upload-deposit-invoice-upload-page";
import useGetDepositInvoice, {
  GetDepositInvoicePathParameter,
  GetDepositInvoiceRequest,
} from "@/features/invoices/deposit-invoices/hooks/use-get-deposit-invoice";
import { Formik, FormikProps } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import DepositInvoiceForm from "@/features/invoices/deposit-invoices/deposit-invoice-form";
import AppButton from "@/features/line-sheet-sets/app-button";
import useDepositInvoiceDumbTransaction from "@/features/invoices/deposit-invoices/hooks/use-deposit-invoice-dumb-transaction";
import useUpdateDepositInvoice, {
  UpdateDepositInvoiceRequest,
} from "@/features/invoices/deposit-invoices/hooks/use-update-deposit-invoice";
import useDepositInvoiceFormValidationSchema from "@/features/invoices/deposit-invoices/hooks/use-deposit-invoice-form-validation-schema";
import AppDownloadButton from "@/features/invoices/app-download-button";
import PriceUtils from "@/features/ui/utils/price-utils";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";
import useReadResource from "@/features/invoices/proforma-invoices/hooks/use-read-resource";
import useReadDepositInvoice from "@/features/invoices/deposit-invoices/hooks/use-read-deposit-invoice";

interface DepositInvoiceDetailPageProps {
  isEditing?: boolean;
}

export default function DepositInvoiceDetailPage({
  isEditing = false,
}: DepositInvoiceDetailPageProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { navigate } = useRouterHelper();
  const validateSchema = useDepositInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: DepositInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { depositInvoiceId } = useRequiredParams<{
    depositInvoiceId: number;
  }>();

  const key = useMemo(():
    | {
        parameter: GetDepositInvoicePathParameter;
        request: GetDepositInvoiceRequest;
      }
    | undefined => {
    if (identity?.company && depositInvoiceId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          depositInvoiceId,
        },
        request: {},
      };
    }
    return undefined;
  }, [depositInvoiceId, identity]);

  const {
    data: depositInvoice,
    error: getDepositInvoiceError,
    mutate: mutateGetDepositInvoice,
  } = useGetDepositInvoice(key?.parameter, key?.request);

  useEffect(() => {
    if (getDepositInvoiceError) {
      showError(getDepositInvoiceError);
    }
  }, [getDepositInvoiceError, showError]);

  const initialValues = useMemo((): DepositInvoiceFormFields => {
    if (depositInvoice) {
      return {
        id: depositInvoice.id,
        number: depositInvoice.number,
        name: depositInvoice.name,
        season: depositInvoice.season,
        orderedBy: depositInvoice.issuedToCompany,
        issuedBy: depositInvoice.issuedByCompany,
        issuedThrough: depositInvoice.issuedThroughCompany,
        issuedOn: depositInvoice.issuedOn,
        publicComment: depositInvoice.publicComment || undefined,
        privateComment: depositInvoice.privateComment || undefined,
        file: depositInvoice.file || undefined,
        amount: depositInvoice.amount,
        bankRemittanceReceipt: depositInvoice.bankRemittanceReceipt,
        exposedToBuyer: true,
      };
    }

    return {
      exposedToBuyer: true,
    };
  }, [depositInvoice]);

  const { fire: fireUpdateDepositInvoice, clear: clearUpdateDepositInvoice } =
    useUpdateDepositInvoice();

  const { handleSubmit, isUpdateQueued } = useDumbMixedUpdate({
    dumbFunction: useDepositInvoiceDumbTransaction,
    smartFunction: useUpdateDepositInvoice,
    isSmart: (request): request is UpdateDepositInvoiceRequest => {
      return isStoredObject(request.file);
    },
    toRequest: (values: DepositInvoiceFormFields) => {
      return {
        id: values.id!,
        number: values.number!,
        name: values.name!,
        season: values.season!,
        issuedToCompany: values.orderedBy!,
        issuedByCompany: values.issuedBy!,
        issuedThroughCompany: values.issuedThrough!,
        issuedOn: values.issuedOn!,
        publicComment: values.publicComment || null,
        privateComment: values.privateComment || null,
        amount: PriceUtils.placeholder(values.amount!, "EUR"),
        bankRemittanceReceipt: values.bankRemittanceReceipt!,
        file: values.file!,
      };
    },
    mutate: mutateGetDepositInvoice,
    defaultRedirectUrl: `/deposit-invoices/${depositInvoiceId}`,
  });

  const createHeaderActions = useCallback(
    (props: FormikProps<DepositInvoiceFormFields>) => {
      if (isEditing) {
        return [
          <AppButton
            key={"Save"}
            variant={"primary"}
            isLoading={props.isSubmitting}
            isDisabled={props.isSubmitting || !props.dirty}
            onClick={() => {
              props.submitForm();
            }}
          >
            {tTitle("common:save")}
          </AppButton>,
        ];
      } else {
        let isLoading = false;

        if (identity?.company && depositInvoiceId) {
          isLoading = isUpdateQueued({
            by: identity.company.type,
            companyId: identity.company.id,
            depositInvoiceId,
          });
        }

        return [
          <AppDownloadButton
            key={"Download"}
            value={
              initialValues.file && isStoredObject(initialValues.file)
                ? initialValues.file.id
                : undefined
            }
          />,
          <AppButton
            key={"Edit"}
            isLoading={isLoading}
            onClick={() => {
              navigate(`/deposit-invoices/${depositInvoiceId}/edit`);
            }}
          >
            {tTitle("common:edit")}
          </AppButton>,
        ];
      }
    },
    [
      initialValues.file,
      depositInvoiceId,
      identity,
      isEditing,
      isUpdateQueued,
      navigate,
      tTitle,
    ]
  );

  useReadResource({
    ywrFunc: useReadDepositInvoice,
    resource: depositInvoice,
    when: () => identity?.company?.type === "BUYER",
    mutate: mutateGetDepositInvoice,
  });

  return (
    <Formik<DepositInvoiceFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          return await handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              depositInvoiceId: values.id!,
            },
            values,
            formikHelpers
          );
        }
        return false;
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"OrderConfirmationInvoice"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("deposit_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={createHeaderActions(props)}
              showNavigateToBack={true}
              emptyStackPage={"/deposit-invoices"}
              blockNavigateToBack={props.dirty && isEditing}
            />
            <DepositInvoiceForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
