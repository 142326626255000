import { IdentityParameter } from "@/features/types";
import {
  calcIdentityUrlPrefix,
  calcUrl,
  SWRObjectKey,
} from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";
import {
  ConditionType,
  FooStatistic,
} from "@/features/order-sheets/order-sheet.type";
import { useCallback } from "react";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface GetOrderSheetStatisticRequest {}

export interface GetOrderSheetStatisticResponse extends FooStatistic {}

export interface GetOrderSheetStatisticPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
}

export const getOrderSheetStatisticTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/statistic`;

export function getGetOrderSheetStatisticKey(
  parameter?: GetOrderSheetStatisticPathParameter,
  request?: GetOrderSheetStatisticRequest
): SWRObjectKey<GetOrderSheetStatisticRequest> | null {
  if (parameter) {
    return {
      url: calcUrl(getOrderSheetStatisticTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetOrderSheetStatisticKey(
  key: AxiosRequestConfig<GetOrderSheetStatisticRequest>
) {
  const urlParser = new UrlParser<GetOrderSheetStatisticPathParameter>(
    getOrderSheetStatisticTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      orderSheetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetOrderSheetStatistic(
  parameter?: GetOrderSheetStatisticPathParameter,
  request?: GetOrderSheetStatisticRequest,
  config?: SWRConfiguration
) {
  const vanillaFetcher = useFetcher<
    GetOrderSheetStatisticRequest,
    GetOrderSheetStatisticResponse
  >();

  const fetcher = useCallback(
    async (
      config: AxiosRequestConfig<GetOrderSheetStatisticRequest>
    ): Promise<GetOrderSheetStatisticResponse> => {
      const { parameter, request } = parseGetOrderSheetStatisticKey(config);
      if (parameter.orderSheetSetId > 0 && parameter.orderSheetId === -1) {
        return Promise.resolve({
          firstOrderedQuantity: null,
          firstOrderedAmountWithoutCondition: null,
          firstOrderedAmount: null,
          firstOrderedConditionType: null,

          orderedConditionType: "MIX",
          totalOrderedAmount: 0,
          totalOrderedAmountWithoutCondition: 0,
          totalOrderedQuantity: 0,

          totalConfirmedAmount: null,
          totalConfirmedAmountWithoutCondition: null,
          totalConfirmedQuantity: null,
          confirmedConditionType: null,
        });
      } else {
        return vanillaFetcher(config);
      }
    },
    [vanillaFetcher]
  );

  return useSWR(
    getGetOrderSheetStatisticKey(parameter, request),
    fetcher,
    config
  );
}
