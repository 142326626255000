import { client } from "@/utils/uno-axios";
import Cookies from "js-cookie";

interface Executor<Data = any, Error = any> {
  resolve: (data: Data) => void;
  reject: (err: Error) => void;
}

export class TokenRefresher {
  private _isRefreshing = false;
  private queue: Executor[] = [];

  public async refresh() {
    if (this._isRefreshing) {
      return new Promise((resolve, reject) => {
        this.queue.push({ resolve, reject });
      });
    }

    try {
      this._isRefreshing = true;
      await this._refresh();
      this.queue.forEach((value) => {
        value.resolve({});
      });
    } catch (e) {
      this.queue.forEach((value) => {
        value.reject(e);
      });
      throw e;
    } finally {
      this.queue.length = 0;
      this._isRefreshing = false;
    }
  }

  private async _refresh() {
    return client.post("/auth/refresh", {
      refreshToken: Cookies.get("refresh_token"),
    });
  }
}
