import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import * as Yup from "yup";
import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";

export default function useTransportationFormValidationSchema() {
  const { t } = useI18nHelper();
  const { withMessage, formT } = useYupHelper({
    ns: "transportations",
    prefix: "transportation_form",
  });

  return useMemo(() => {
    return Yup.object<TransportationFormFields>({
      name: Yup.string().required(withMessage.required),
      shippedFromList: Yup.array()
        .required(withMessage.required)
        .min(1, withMessage.min),
      shippedTo: Yup.object().required(withMessage.required),
      shippedToAddress: Yup.string().required(withMessage.required),
      forwarderInvoiceNumbers: Yup.array()
        .of(
          Yup.string()
            .label("forwarder_invoice_number")
            .required(withMessage.required)
        )
        .required(withMessage.required)
        .min(1, withMessage.min),
      houseBlNumbers: Yup.array()
        .of(
          Yup.string().label("house_bl_number").required(withMessage.required)
        )
        .required(withMessage.required)
        .min(1, withMessage.min),
      flightNumber: Yup.string()
        .required(withMessage.required)
        .trim()
        .matches(/^[a-zA-Z]+\d{1,7}$/, t("messages.invalid_flight_number")),
      flightEtd: Yup.date().required(withMessage.required),
      flightEta: Yup.date().required(withMessage.required),
      flightDepartureAirport: Yup.string().required(withMessage.required),
      flightArrivalAirport: Yup.string().required(withMessage.required),
      finalEta: Yup.date(),
      cost: Yup.number().label("transportation_cost"),
      files: Yup.array().required(withMessage.required).min(1, withMessage.min),
      proformaInvoices: Yup.array().min(1, withMessage.required),
    });
  }, [withMessage.min, withMessage.required, t]);
}
