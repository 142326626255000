import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { useCallback, useMemo } from "react";
import { InvoiceCommentRow } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-row.type";
import {
  AppGridColumn,
  AppGridContext,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import { AgencyLinkedOrderSheetRow } from "@/features/invoices/agency-invoice-row.type";
import { toLower, toTitle, toUpper } from "@/utils/case";
import AppRemittanceStatusByRowLabel from "@/features/ui/app-remittance-status-by-row-label";
import { Box, Flex } from "@chakra-ui/react";
import { ProformaInvoiceDetail } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { BuyerLinkedOrderSheetSetRow } from "@/features/invoices/buyer-invoice-row.type";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import {
  AbstractInvoice,
  OrderConfirmationInvoiceDetail,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppLegacyInvoiceStatusLabel from "@/features/ui/app-legacy-invoice-status-label";
import AppGridCell from "@/features/order-sheet-sets/app-grid-cell";
import AppRemittanceStatusLabel from "@/features/ui/app-remittance-status-label";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { Price, StoredObject } from "@/features/types";
import AppInvoiceStatusLabel from "@/features/ui/app-invoice-status-label";

interface UseInvoiceGridProps {
  rows: GridRow[] | undefined;
  mutateRows: () => void;
}

export default function useInvoiceGrid({
  rows,
  mutateRows,
}: UseInvoiceGridProps) {
  const appGrid = useAppGrid();
  const orderSheetGrid = useLineSheetSetGrid({ rows, mutateRows });
  const { navigate } = useRouterHelper();

  const { t, tCurrencyString, tLocalDateString, tNumberString } =
    useI18nHelper();
  const identity = useIdentity();

  const isIdentityAgency = useMemo(() => {
    return identity?.company?.type === "AGENCY";
  }, [identity?.company?.type]);

  const statuses = useMemo(() => {
    return {
      name: "status",
      value: (_row: GridRow, context: AppGridContext) => {
        const statusRow = _row as GridRow & {
          status: AbstractInvoice["status"];
        };
        const remittanceStatusRow = _row as GridRow & {
          isRemitted: boolean;
          isRead?: boolean;
          paymentMethod: AbstractInvoice["paymentMethod"];
        };
        return (
          <Flex flexDirection={"column"} gap={"2px"}>
            <AppRemittanceStatusByRowLabel
              row={remittanceStatusRow}
              context={context}
            />
            <AppLegacyInvoiceStatusLabel row={statusRow} context={context} />
          </Flex>
        );
      },
    };
  }, []);

  const paidByAgency = useMemo(() => {
    return {
      name: "Paid By Agency",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { paidBy: string };
        const key = `paid_by_agency.${
          row.paidBy === "AGENCY" ? "agency_paid" : "buyer_paid"
        }`;
        return t(key);
      },
    };
  }, [t]);

  const paymentMethod = useMemo(() => {
    return {
      name: "Payment Method",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { paymentMethod: string };
        const key = `payment_method.${toLower(row.paymentMethod)}`;
        return t(key);
      },
    };
  }, [t]);

  const exposedToBuyer = useMemo(() => {
    return {
      name: "Exposed To Buyer",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { isPublic: boolean };
        return t(
          `exposed_to_buyer.${row.isPublic ? "exposed" : "not_exposed"}`
        );
      },
    };
  }, [t]);

  const creditInvoiceRowType = useMemo(() => {
    return {
      name: "type",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { type: string };
        return toTitle(
          t(
            row.type === "FIXING"
              ? "fixing_credit_invoice"
              : "season_credit_invoice"
          )
        );
      },
    };
  }, [t]);

  const fields = useMemo(() => {
    return {
      statuses,
      paidByAgency,
      paymentMethod,
      exposedToBuyer,
      creditInvoiceRowType,
    };
  }, [
    statuses,
    paidByAgency,
    paymentMethod,
    exposedToBuyer,
    creditInvoiceRowType,
  ]);

  type InvoiceGridKeys =
    | "totalAmount"
    | "title"
    | "deposit"
    | "totalRequiredAmount"
    // | "totalUnremittedAmount"
    | "remittanceStatus"
    | "paidBy"
    | "issuedBy"
    | "issuedThrough"
    | "orderedBy"
    | "tatr"
    | "issuedOn"
    | "amount"
    | "creditInvoiceType"
    | "number"
    | "season"
    | "brand"
    | "paymentMethod"
    | "exposedToBuyer"
    | "receiver"
    | "sender"
    | "remittedAmount"
    | "unremittedAmount"
    | "status"
    | "requestedOn";

  //pairs
  const pairs = useMemo((): {
    [key in InvoiceGridKeys]: {
      column: AppGridColumn;
      field: AppGridField;
    };
  } => {
    return {
      exposedToBuyer: {
        column: {
          name: toTitle(t("exposed_to_buyer")),
          width: "104px",
        },
        field: {
          name: "exposed_to_buyer",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { isPublic: boolean };
            return t(
              `exposed_to_buyer.${row.isPublic ? "exposed" : "not_exposed"}`
            );
          },
        },
      },
      status: {
        column: {
          name: toTitle(t("status")),
          width: "72px",
        },
        field: {
          name: "status",
          value: (_row: GridRow, context: AppGridContext) => {
            const row = _row as GridRow & {
              status: AbstractInvoice["status"];
            };

            return <AppInvoiceStatusLabel value={row.status} />;
          },
        },
      },
      title: {
        column: {
          name: toTitle(t("title")),
          width: "minmax(120px, 1fr)",
        },
        field: {
          name: "title",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { name: string; number?: string };
            return `${row.number ? `[${row.number}]` : ""}${row.name}`;
          },
        },
      },
      season: {
        column: {
          name: toTitle(t("season")),
          width: "64px",
        },
        field: {
          name: "season",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { season: string };
            return row.season;
          },
        },
      },
      brand: {
        column: {
          name: toTitle(t("brand")),
          width: "104px",
        },
        field: {
          name: "brand",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              orderConfirmationInvoiceDetailList?: OrderConfirmationInvoiceDetail[];
              proformaInvoiceDetailList?: ProformaInvoiceDetail[];
            };

            const names = (
              row.orderConfirmationInvoiceDetailList ||
              row.proformaInvoiceDetailList ||
              []
            ).map((detail) => detail.brand.name);

            if (names.length === 1) {
              return names[0];
            } else if (names.length > 0) {
              return `${names[0]}(+${names.length - 1})`;
            }
            return "";
          },
        },
      },
      paymentMethod: {
        column: {
          name: toTitle(t("payment_method")),
          width: "104px",
        },
        field: {
          name: "payment_method",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { paymentMethod: string };
            return toTitle(t(`payment_method.${toLower(row.paymentMethod)}`));
          },
        },
      },
      totalAmount: {
        column: {
          name: toTitle(t("total_amount")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "total_amount",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & { totalAmount: number };
            return tCurrencyString(row.totalAmount);
          },
        },
      },
      deposit: {
        column: {
          name: toTitle(t("deposit")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "deposit",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              totalRequiredAmount: number;
            };
            return `${tCurrencyString(row.totalRequiredAmount)}`;
          },
        },
      },
      totalRequiredAmount: {
        column: {
          name: toTitle(t("common:required_amount")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "deposit",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              totalRequiredAmount: number;
            };
            return `${tCurrencyString(row.totalRequiredAmount)}`;
          },
        },
      },
      remittanceStatus: {
        column: {
          name: toTitle(t("remittance_status")),
          width: "152px",
        },
        field: {
          name: "remittance_status",
          value: (_row: GridRow, context) => {
            const row = _row as GridRow & {
              isRemitted: boolean;
              isRead?: boolean;
              isRemittedToAgency?: boolean;
              isRemittedToBoutique?: boolean;
              paymentMethod?: AbstractInvoice["paymentMethod"];
              paidBy?: AbstractInvoice["paidBy"];
            };

            if (row.paidBy === "BUYER") {
              return (
                <AppRemittanceStatusLabel
                  to={"BOUTIQUE"}
                  value={
                    row.paymentMethod === "SKIP"
                      ? "SKIPPED"
                      : row.isRemittedToBoutique
                      ? "REMITTED"
                      : "UNREMITTED"
                  }
                />
              );
            } else if (row.paidBy === "AGENCY") {
              return (
                <AppGridCell
                  value={[
                    <AppRemittanceStatusLabel
                      key={"to_boutique"}
                      to={"BOUTIQUE"}
                      value={
                        row.paymentMethod === "SKIP"
                          ? "SKIPPED"
                          : row.isRemittedToBoutique
                          ? "REMITTED"
                          : "UNREMITTED"
                      }
                    />,
                    <AppRemittanceStatusLabel
                      key={"to_agency"}
                      to={"AGENCY"}
                      value={
                        row.paymentMethod === "SKIP"
                          ? "SKIPPED"
                          : row.isRemittedToAgency
                          ? "REMITTED"
                          : "UNREMITTED"
                      }
                    />,
                  ]}
                />
              );
            } else {
              return (
                <AppRemittanceStatusLabel
                  value={
                    row.paymentMethod === "SKIP"
                      ? "SKIPPED"
                      : row.isRemitted
                      ? "REMITTED"
                      : "UNREMITTED"
                  }
                />
              );
            }
          },
        },
      },
      paidBy: {
        column: {
          name: toTitle(t("paid_by")),
          width: "104px",
        },
        field: {
          name: "paid_by",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { paidBy: string };
            return toTitle(t(toLower(row.paidBy)));
          },
        },
      },
      issuedBy: {
        column: {
          name: toTitle(t("issued_by")),
          width: "104px",
        },
        field: {
          name: "issued_by",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              issuedByCompany: { name: string };
            };
            return row.issuedByCompany.name;
          },
        },
      },
      issuedThrough: {
        column: {
          name: toTitle(t("issued_through")),
          width: "104px",
        },
        field: {
          name: "issued_through",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              issuedThroughCompany: { name: string };
            };
            return row.issuedThroughCompany.name;
          },
        },
      },
      orderedBy: {
        column: {
          name: toTitle(t("ordered_by")),
          width: "104px",
        },
        field: {
          name: "ordered_by",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              issuedToCompany: { name: string };
            };
            return row.issuedToCompany.name;
          },
        },
      },
      tatr: {
        column: {
          name: "tatr",
          width: "132px",
          value: (
            <AppGridCell
              alignItems={"flex-end"}
              value={[
                toTitle(t("total_amount")),
                toTitle(t("common:total_remittance_required_amount.abbr")),
              ]}
            />
          ),
        },
        field: {
          name: "tatr",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              totalAmount: number;
              totalRequiredAmount: number;
              totalBuyerToBoutiqueAmount: number;
              totalBuyerToAgencyAmount: number;
            };
            return (
              <AppGridCell
                alignItems={"flex-end"}
                value={[
                  tCurrencyString(row.totalAmount),
                  tCurrencyString(
                    row.totalRequiredAmount -
                      row.totalBuyerToBoutiqueAmount -
                      row.totalBuyerToAgencyAmount
                  ),
                ]}
              />
            );
          },
        },
      },
      issuedOn: {
        column: {
          name: toTitle(t("issued_on")),
          width: "64px",
        },
        field: {
          name: "issued_on",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { issuedOn: Date };
            return tLocalDateString(row.issuedOn);
          },
        },
      },
      amount: {
        column: {
          name: toTitle(t("amount")),
          width: "114px",
          textAlign: "end",
        },
        field: {
          name: "amount",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & { amount: number };
            return tCurrencyString(row.amount);
          },
        },
      },
      creditInvoiceType: {
        column: {
          name: toTitle(t("type")),
          width: "64px",
        },
        field: {
          name: "type",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { type: string };
            return toUpper(t(`credit_invoice.type.${toLower(row.type)}`));
          },
        },
      },
      number: {
        column: {
          name: toTitle(t("number")),
          width: "minmax(120px, 1fr)",
        },
        field: {
          name: "number",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { number: string };
            return row.number;
          },
        },
      },
      sender: {
        column: {
          name: toTitle(t("sender")),
          width: "104px",
        },
        field: {
          name: "sender",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              sender: { name: string };
            };
            return row.sender.name;
          },
        },
      },
      receiver: {
        column: {
          name: toTitle(t("receiver")),
          width: "104px",
        },
        field: {
          name: "receiver",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              receiver: { name: string };
            };
            return row.receiver.name;
          },
        },
      },
      remittedAmount: {
        column: {
          name: toTitle(t("remitted_amount")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "remitted_amount",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & { remittedAmount: Price };
            return tCurrencyString(row.remittedAmount);
          },
        },
      },
      unremittedAmount: {
        column: {
          name: toTitle(t("unremitted_amount")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "unremitted_amount",
          chakraProps: {
            textAlign: "end",
          },
          value: (_row: GridRow) => {
            const row = _row as GridRow & { unremittedAmount: Price };
            return tCurrencyString(row.unremittedAmount);
          },
        },
      },
      requestedOn: {
        column: {
          name: toTitle(t("requested_on")),
          width: "104px",
        },
        field: {
          name: "requested_on",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { createdAt: Date };
            return tLocalDateString(row.createdAt);
          },
        },
      },
    };
  }, [t, tCurrencyString, tLocalDateString]);

  //actions

  const downloadInvoice = useMemo(() => {
    interface Foo extends GridRow {
      file: Pick<StoredObject, "id" | "name"> | null;
      compressedFile: Pick<StoredObject, "id" | "name"> | null;
    }

    return appGrid.actions.withDownloadUnoFile<Foo>(
      (row) => row.file || row.compressedFile
    );
  }, [appGrid.actions]);

  const actions = useMemo(() => {
    return {
      downloadInvoice,
    };
  }, [downloadInvoice]);

  //extraContents

  // const invoiceFigureExtraContents = useCallback(
  //   (_row: GridRow, meta: AppGridMeta) => {
  //     const row = _row as InvoiceFiguresRow;
  //
  //     const rightSpacerWidth = meta.actions ? "96px" : undefined;
  //
  //     const extraComponents = [];
  //
  //     const alternativeCurrencyUsage = row.figuresByBrandList
  //       .map((figuresByBrand) => {
  //         return {
  //           usedCredit: figuresByBrand.usedCredit,
  //           usedDeposit: figuresByBrand.usedDeposit,
  //         };
  //       })
  //       .reduce(
  //         (acc, curr) => {
  //           return {
  //             usedCredit: acc.usedCredit + curr.usedCredit,
  //             usedDeposit: acc.usedDeposit + curr.usedDeposit,
  //           };
  //         },
  //         {
  //           usedCredit: 0,
  //           usedDeposit: 0,
  //         }
  //       );
  //
  //     if (
  //       alternativeCurrencyUsage.usedCredit > 0 ||
  //       alternativeCurrencyUsage.usedDeposit > 0
  //     ) {
  //       const orderConfirmationRow = findParentGridRow(row, rows) as GridRow & {
  //         season: string;
  //       };
  //
  //       extraComponents.push(
  //         <InvoiceAlternativeCurrencyUsageTable
  //           key={"InvoiceAlternativeCurrencyUsageTable_" + row.id}
  //           leftSpacerWidth={"1fr"}
  //           rightSpacerWidth={rightSpacerWidth}
  //           usage={{
  //             credit: alternativeCurrencyUsage.usedCredit * -1,
  //             deposit: alternativeCurrencyUsage.usedDeposit * -1,
  //           }}
  //           season={orderConfirmationRow.season}
  //         />
  //       );
  //     }
  //
  //     extraComponents.push(
  //       <InvoiceFiguresByBrandListTable
  //         key={"InvoiceFiguresByBrandListTable_" + row.id}
  //         leftSpacerWidth={"1fr"}
  //         rightSpacerWidth={rightSpacerWidth}
  //         figuresByBrand={row.figuresByBrandList}
  //       />
  //     );
  //
  //     return extraComponents;
  //   },
  //   [rows]
  // );

  const invoiceCommentExtraContents = useCallback((_row: GridRow) => {
    const row = _row as InvoiceCommentRow;
    return [<Box key={"InvoicePublicComment_" + row.id}>{row.content}</Box>];
  }, []);

  const extraContents = useMemo(() => {
    return {
      invoiceCommentExtraContents,
    };
  }, [invoiceCommentExtraContents]);

  const withPublicComment = useCallback(
    (isBuyer: boolean = true): AppGridMeta => {
      return {
        rowType: "PublicComment",
        columns: [{ name: "RowType", width: "1fr" }],
        fields: [
          {
            name: "RowType",
            value: (_row: GridRow) => {
              if (isBuyer) {
                return `${toTitle(t("comment"))}`;
              } else {
                return `${toTitle(t("public_comment"))} (${t(
                  "public_comment.note"
                )})`;
              }
            },
          },
        ],
        extraContents: invoiceCommentExtraContents,
        actions: [],
      };
    },
    [invoiceCommentExtraContents, t]
  );

  const privateComment = useMemo((): AppGridMeta => {
    return {
      rowType: "PrivateComment",
      columns: [{ name: "RowType", width: "1fr" }],
      fields: [
        {
          name: "RowType",
          value: (_row: GridRow) => {
            return `${toTitle(t("private_comment"))}`;
          },
        },
      ],
      extraContents: invoiceCommentExtraContents,
      actions: [],
    };
  }, [invoiceCommentExtraContents, t]);

  function insertPartials(
    meta: AppGridMeta,
    partials: { field: AppGridField; column: AppGridColumn }[],
    location?: {
      after: string;
    }
  ): AppGridMeta {
    const i = location
      ? meta.columns.findIndex((c) => c.name === location.after)
      : 0;
    if (i > -1) {
      const columns = [
        ...meta.columns.slice(0, i + 1),
        ...partials.map((t) => t.column),
        ...meta.columns.slice(i + 1),
      ];
      const fields = [
        ...meta.fields.slice(0, i + 1),
        ...partials.map((t) => t.field),
        ...meta.fields.slice(i + 1),
      ];
      return {
        ...meta,
        columns,
        fields,
      };
    }

    return {
      ...meta,
    };
  }

  const orderSheetSet = useMemo((): AppGridMeta => {
    return {
      rowType: "OrderSheetSet",
      columns: [
        { name: "RowType", width: "1fr" },
        { name: "Name", width: "1fr" },
        { name: "Status", width: "1fr" },
      ],
      fields: [
        appGrid.fields.withRowType("order_sheet_set"),
        {
          name: "Name",
          value: (_row) => {
            const row = _row as BuyerLinkedOrderSheetSetRow;
            return row.name;
          },
        },
        orderSheetGrid.fields.withOrderSheetStatus(),
      ],
      indicator: (_row) => {
        return <GridRowIndicator type={"EMPTY"} isHovered={_row.isHovered} />;
      },
      actions: [
        appGrid.actions.withDownloadUnoFile<BuyerLinkedOrderSheetSetRow>(
          (row) => row.compressedExportedFile
        ),
      ],
    };
  }, [appGrid.actions, appGrid.fields, orderSheetGrid.fields]);

  const orderSheet = useMemo((): AppGridMeta => {
    return {
      rowType: "OrderSheet",
      columns: [
        { name: "RowType", width: "1fr" },
        { name: "Name", width: "1fr" },
        { name: "Status", width: "1fr" },
      ],
      fields: [
        appGrid.fields.withRowType("order_sheet"),
        {
          name: "Name",
          value: (_row) => {
            const row = _row as AgencyLinkedOrderSheetRow;
            return row.name;
          },
        },
        orderSheetGrid.fields.withOrderSheetStatus(),
      ],
      actions: [
        orderSheetGrid.actions.editOrViewOrderSheet,
        appGrid.actions.withDownloadUnoFile<AgencyLinkedOrderSheetRow>(
          (row) => row.exportedFile || row.uploadedFile
        ),
      ],
      extraContents: orderSheetGrid.extraContents.orderSheetExtraContents,
    };
  }, [
    appGrid.actions,
    appGrid.fields,
    orderSheetGrid.actions.editOrViewOrderSheet,
    orderSheetGrid.extraContents.orderSheetExtraContents,
    orderSheetGrid.fields,
  ]);

  const metas = useMemo(() => {
    return {
      withPublicComment,
      privateComment,
      orderSheetSet,
      orderSheet,
    };
  }, [withPublicComment, privateComment, orderSheetSet, orderSheet]);

  return {
    actions,
    extraContents,
    fields,
    metas,
    pairs,
  };
}
