import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { ProformaInvoiceRow } from "@/features/invoices/proforma-invoices/proforma-invoice-row.type";

export const proformaInvoiceRowMapper = new GridMapper<
  ProformaInvoice,
  ProformaInvoiceRow
>(
  "ProformaInvoice",
  (invoice) => {
    return {
      name: invoice.name,
      number: invoice.number,
      season: invoice.season,
      lineSheetSetType: invoice.lineSheetSetType,
      issuedByCompany: invoice.issuedByCompany,
      issuedToCompany: invoice.issuedToCompany,
      issuedThroughCompany: invoice.issuedThroughCompany,
      paidBy: invoice.paidBy,
      totalAmount: invoice.totalAmount,
      totalRequiredAmount: invoice.totalRequiredAmount,
      isPublic: invoice.isPublic,
      issuedOn: invoice.issuedOn,
      usedCredit: invoice.usedCredit,
      usedDeposit: invoice.usedDeposit,
      status: invoice.status,
      file: invoice.file,
      proformaInvoiceDetailList: invoice.proformaInvoiceDetailList,
      paymentMethod: invoice.paymentMethod,
      isRead: invoice.isRead,
      isRemittedToAgency: invoice.isRemittedToAgency,
      isRemittedToBoutique: invoice.isRemittedToBoutique,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
