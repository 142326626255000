import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import UploadInventoryModal from "@/features/inventories/upload-inventory-modal";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo } from "react";
import useListInventories, {
  ListInventoriesPathParameter,
  ListInventoriesRequest,
} from "@/features/inventories/hooks/use-list-inventories";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useBuyerInventoryRows from "@/features/inventories/hooks/use-buyer-inventory-rows";
import BuyerInventoryGrid from "@/features/inventories/buyer-inventory-grid";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { PersistedInventoryListPageQuery } from "@/features/inventories/agency-inventory-list-page";

export default function BuyerInventoryListPage() {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "BuyerInventoryListPage" });
  const { setRows } = useBuyerInventoryRows();
  const [query, setQuery] =
    useTypedSearchParams<PersistedInventoryListPageQuery>();
  const listInventoriesKey = useMemo(():
    | {
        parameter: ListInventoriesPathParameter;
        request: ListInventoriesRequest;
      }
    | undefined => {
    if (identity?.company?.type === "BUYER") {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
        },
      };
    }
    return undefined;
  }, [identity, query]);

  const {
    data: listInventoriesData,
    error: listInventoriesError,
    mutate: mutateListInventories,
  } = useListInventories(
    listInventoriesKey?.parameter,
    listInventoriesKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    setRows(listInventoriesData?.inventories);
  }, [listInventoriesData, setRows]);

  useEffect(() => {
    return () => {
      mutateListInventories((prev) => prev, false);
    };
  }, [mutateListInventories]);

  useEffect(() => {
    if (listInventoriesError) {
      showError(listInventoriesError);
    }
  }, [showError, listInventoriesError]);

  const {
    isOpen: isUploadInventoryModalOpen,
    onClose: onUploadInventoryModalClose,
    onOpen: onUploadInventoryModalOpen,
  } = useDisclosure();

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[]}
          actions={[
            <AppButton
              key={"BuyerInventoryList__UploadInventory"}
              variant={"primary"}
              onClick={onUploadInventoryModalOpen}
            >
              {tTitle("upload_inventory")}
            </AppButton>,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <BuyerInventoryGrid mutateRows={mutateListInventories} />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
            totalCount={listInventoriesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              })
            }
          />
        </Flex>
      </Flex>
      {isUploadInventoryModalOpen && (
        <UploadInventoryModal
          isOpen={isUploadInventoryModalOpen}
          onClose={onUploadInventoryModalClose}
        />
      )}
    </>
  );
}
