import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadBankRemittanceReceiptRequest {}
export interface ReadBankRemittanceReceiptResponse extends LightLineSheet {}

interface ReadBankRemittanceReceiptPathParameter extends IdentityParameter {
  bankRemittanceReceiptId: number;
}

export const readBankRemittanceReceiptTemplateUrl =
  "/:by/:companyId/bank-remittance-receipts/:bankRemittanceReceiptId/read";

export const readBankRemittanceReceiptKey: YWRKey<ReadBankRemittanceReceiptPathParameter> =
  {
    url: (parameter: ReadBankRemittanceReceiptPathParameter) => {
      return calcUrl(readBankRemittanceReceiptTemplateUrl, parameter);
    },
    method: "post",
  };

export function parseReadBankRemittanceReceiptKey(url: string) {
  const urlParser = new UrlParser<ReadBankRemittanceReceiptPathParameter>(
    readBankRemittanceReceiptTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      bankRemittanceReceiptId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadBankRemittanceReceipt() {
  const fetcher = useFetcher<
    ReadBankRemittanceReceiptRequest,
    ReadBankRemittanceReceiptResponse
  >();
  return useYWR(readBankRemittanceReceiptKey, fetcher);
}
