import AppDetailBrandSelect from "@/features/invoices/app-detail-brand-select";
import { Box, Flex, Grid } from "@chakra-ui/react";
import AppPriceInput from "@/features/invoices/app-price-input";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import React from "react";
import { useFormikContext } from "formik";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";

interface ProformaInvoiceDetailInputProps {
  // value: Partial<ProformaInvoice["proformaInvoiceDetailList"][number]> & {
  //   id: number;
  // };

  value: ProformaInvoiceFormFields["proformaInvoiceDetailList"][number];
  isDisabled: boolean;
  isReadOnly: boolean;
  onChange: (
    value?: ProformaInvoiceFormFields["proformaInvoiceDetailList"][number]
  ) => void;
}

export default function ProformaInvoiceDetailInput({
  value,
  isReadOnly,
  isDisabled,
  onChange,
}: ProformaInvoiceDetailInputProps) {
  const { values, initialValues } =
    useFormikContext<ProformaInvoiceFormFields>();

  // if (!isReadOnly && values.id !== -1) {
  //   if (value.orderConfirmationInvoiceDetail) {
  //     value.orderConfirmationInvoiceDetail.expectedRemainingDepositAmount;
  //     value.usedDepositAmount;
  //   }
  // }

  return (
    <Grid gridTemplateColumns={"2fr repeat(5, 1fr) 12px"} columnGap={"8px"}>
      <AppDetailBrandSelect
        value={value}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSearchable={true}
        onSelect={(detailBrand) => {
          onChange({
            ...value,
            ...detailBrand,
          });
        }}
      />
      <AppPriceInput
        value={value.amount}
        onChange={(amount) => {
          onChange({ ...value, amount: amount });
        }}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
      />
      <AppPriceInput
        // value={value.reamingDepositAmount}
        value={
          value.orderConfirmationInvoiceDetail
            ?.expectedRemainingDepositAmount || { currency: "EUR" }
        }
        isDisabled={!isReadOnly}
        isReadOnly={isReadOnly}
      />
      <AppPriceInput
        value={value.usedDepositAmount}
        isDisabled={
          !isReadOnly && (isDisabled || !values.isDetailsEditedManually)
        }
        isReadOnly={isReadOnly}
        onChange={(usedDepositAmount) => {
          onChange({ ...value, usedDepositAmount });
          // withAmountChange(index, "usedDepositAmount")(value);
        }}
      />

      <AppPriceInput
        value={value.usedCreditAmount}
        isDisabled={!isReadOnly}
        isReadOnly={isReadOnly}
      />

      <AppPriceInput
        value={value.requiredAmount}
        isDisabled={!isReadOnly}
        isReadOnly={isReadOnly}
      />
      {isDisabled || isReadOnly ? (
        <Box></Box>
      ) : (
        <Flex
          height={"24px"}
          flexDirection={"row"}
          alignSelf={"end"}
          alignItems={"center"}
        >
          <AppIconButton
            ariaLabel={"delete"}
            icon={"x"}
            size={"small"}
            onClick={() => {
              onChange(undefined);
            }}
          />
        </Flex>
      )}
    </Grid>
  );
}
