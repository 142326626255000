import {
  createProformaInvoiceKey,
  CreateProformaInvoiceRequest,
  CreateProformaInvoiceResponse,
} from "@/features/invoices/proforma-invoices/hooks/use-create-proforma-invoice";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";

export default function useCreateProformaInvoiceInfinite() {
  const fetcher = useFetcher<
    CreateProformaInvoiceRequest,
    CreateProformaInvoiceResponse
  >();
  return useYWRInfinite(createProformaInvoiceKey, fetcher);
}
