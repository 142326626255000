import {
  Condition,
  ConditionCategories,
  ConditionTypes,
} from "@/features/order-sheets/order-sheet.type";
import React, { useMemo } from "react";
import { Grid } from "@chakra-ui/react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { withWithHandleChange } from "@/utils/form";
import AppPriceInput from "@/features/invoices/app-price-input";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { Company } from "@/features/types";

export interface PriceConditionOption {
  category?: string;
  brand?: Pick<Company, "id" | "name">;
  gender?: string;
  type?: Condition["type"];
  value?: number;
}

interface PriceConditionSelectorProps {
  value: PriceConditionOption;
  onSelect: (value: PriceConditionOption) => void;
  onDelete?: () => void;
  excludeCategories?: string[];
  width?: string;
}

export default function PriceConditionSelector({
  value,
  onSelect,
  onDelete,
  excludeCategories = [],
  width,
}: PriceConditionSelectorProps) {
  const withHandleChange = withWithHandleChange(value, onSelect);
  const { t } = useTranslation();

  const categoryOptions = useMemo((): AppSelectOption<string>[] => {
    return ConditionCategories.filter(
      (category) => !excludeCategories?.includes(category)
    ).map((category) => {
      return {
        name: category,
        value: category,
      };
    });
  }, [excludeCategories]);

  const genderOptions = useMemo((): AppSelectOption<string>[] => {
    return ["ALL", "M", "F"].map((gender) => {
      return {
        name: gender,
        value: gender,
      };
    });
  }, []);

  const typeOptions = useMemo((): AppSelectOption<Condition["type"]>[] => {
    return ConditionTypes.map((type) => {
      return {
        name: type,
        value: type,
      };
    });
  }, []);

  return (
    <Grid
      gridTemplateColumns={"repeat(5, 1fr) 12px"}
      gap={"8px"}
      alignItems={"center"}
    >
      <AppCompanySelect
        name={toTitle(t("brand"))}
        types={["BRAND"]}
        id={value.brand?.id}
        onSelect={withHandleChange("brand")}
      />
      <AppSelect
        name={toTitle(t("category"))}
        value={value.category}
        isSearchable={true}
        options={categoryOptions}
        onSelect={withHandleChange("category")}
      />
      <AppSelect
        name={toTitle(t("gender"))}
        options={genderOptions}
        value={value.gender}
        onSelect={withHandleChange("gender")}
      />
      <AppSelect
        name={toTitle(t("type"))}
        options={typeOptions}
        value={value.type}
        onSelect={withHandleChange("type")}
      />
      <AppPriceInput
        currency={"%"}
        value={{
          value: value.value,
          currency: "%",
        }}
        onChange={(value1) => {
          withHandleChange("value")(value1?.value);
        }}
      />
      <AppIconButton
        ariaLabel={"delete"}
        icon={"x"}
        size={"small"}
        onClick={onDelete}
      />
    </Grid>
  );
}
