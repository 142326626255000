import { Box, Flex, Grid, GridItem, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import { useBeforeUnload } from "react-router-dom";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppListHeaderProps {
  showNavigateToBack?: boolean;
  blockNavigateToBack?: boolean;
  emptyStackPage?: string;
  headers?: JSX.Element[];
  filters: (JSX.Element | (() => JSX.Element | undefined))[];
  actions?: (JSX.Element | (() => JSX.Element | undefined) | undefined)[];
  figures?: { name: string; value: string }[];
}

export default function AppListHeader({
  showNavigateToBack = false,
  emptyStackPage = "/",
  blockNavigateToBack = false,
  headers = [],
  filters,
  actions = [],
  figures,
}: AppListHeaderProps) {
  const { navigate } = useRouterHelper();
  const { openDialog } = useAppDialog();
  const { t, tTitle } = useI18nHelper();
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      padding={"0px 16px"}
      gap={"12px"}
      minHeight={"48px"}
      background={"#FFFFFF"}
      borderBottom={"0.5px solid #D4D4D1"}
    >
      {showNavigateToBack && (
        <AppIconButton
          variant={"ghost"}
          ariaLabel={"go to previous page"}
          size={"medium"}
          icon={"left"}
          onClick={async () => {
            let result = true;

            if (blockNavigateToBack) {
              result = await openDialog({
                title: tTitle("discard_changes"),
                message: tTitle("discard_changes.message"),
                confirmName: tTitle("continue"),
              });
            }

            if (result) {
              navigate(-1, { defaultPage: emptyStackPage });
            }

            //navigate(-1, { defaultPage: emptyStackPage });
            // navigate(-1, "/line-sheets");
          }}
        />
      )}
      {headers?.length > 0 && headers?.map((header, index) => header)}
      {filters.length > 0 && (
        <Text flexShrink={"0"} flexGrow={"0"} fontSize={"12px"} padding={"8px"}>
          {toTitle(t("filter"))}
        </Text>
      )}
      {filters.map((filter) => {
        if (typeof filter === "function") {
          return filter();
        }
        return filter;
      })}
      <Spacer />
      {figures && figures.length > 0 && (
        <Flex
          width={
            figures.length <= 2 ? `${160 + 120}px` : `${(160 + 120) * 2 + 8}px`
          }
          columnGap={"8px"}
          wrap={"wrap"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          flexGrow={"0"}
        >
          {figures.map((figure, index) => {
            return (
              <Grid
                key={`"AppListHeaderFigure_${index}`}
                gridTemplateColumns={"160px 120px"}
              >
                <Box fontSize={"12px"} color={"#6F6F6C"}>
                  {figure.name}
                </Box>
                <Box fontSize={"12px"} textAlign={"end"}>
                  {figure.value}
                </Box>
              </Grid>
            );
          })}
        </Flex>
      )}
      {actions
        ?.map((action) => {
          if (typeof action === "function") {
            return action();
          }
          return action;
        })
        .filter((action): action is JSX.Element => {
          return action !== undefined;
        })}
    </Flex>
  );
}
