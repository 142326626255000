import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { BalanceTransactionStatisticByBoutique } from "@/features/dashboard/dashboard.type";

export interface ListBalanceTransactionStatisticsByBoutiqueRequest {
  "madeByCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  season__gte?: string;
  season__lte?: string;
  season__eq?: string;
  season__in?: string[];
  type__in?: string[];
  transactionAmount__gt?: number;
  transactionAmount__lt?: number;
}
export interface ListBalanceTransactionStatisticsByBoutiqueResponse {
  totalBalanceTransactionStatistic: Omit<
    BalanceTransactionStatisticByBoutique,
    "boutique"
  >;
  balanceTransactionStatisticsByBoutique: BalanceTransactionStatisticByBoutique[];
}
export interface ListBalanceTransactionStatisticsByBoutiquePathParameter
  extends IdentityParameter {}

const listBalanceTransactionStatisticsByBoutiqueTemplateUrl = `/:by/:companyId/balance-transaction-statistics-by-boutique`;

export function getListBalanceTransactionStatisticsByBoutiqueKey(
  parameter?: ListBalanceTransactionStatisticsByBoutiquePathParameter,
  request?: ListBalanceTransactionStatisticsByBoutiqueRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(
        listBalanceTransactionStatisticsByBoutiqueTemplateUrl,
        parameter
      ),
      params: request,
    };
  }
  return null;
}

export function useListBalanceTransactionStatisticsByBoutique(
  parameters?: ListBalanceTransactionStatisticsByBoutiquePathParameter,
  request?: ListBalanceTransactionStatisticsByBoutiqueRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListBalanceTransactionStatisticsByBoutiqueRequest,
    ListBalanceTransactionStatisticsByBoutiqueResponse
  >();

  return useSWR(
    getListBalanceTransactionStatisticsByBoutiqueKey(parameters, request),
    fetcher,
    config
  );
}
