import useFetcher from "@/utils/use-fetcher";
import {
  GetBankRemittanceReceiptRequest,
  GetBankRemittanceReceiptResponse,
  getGetBankRemittanceReceiptKey,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-get-bank-remittance-receipt";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseBankRemittanceReceiptRequest,
  GetDenseBankRemittanceReceiptResponse,
  parseGetDenseBankRemittanceReceiptKey,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-get-dense-bank-remittance-receipt";
import useCondensePayments from "@/features/invoices/payment/hooks/use-condense-payments";
import useIdentity from "@/features/ui/hooks/use-identity";

export default function useGetDenseBankRemittanceReceiptFetcher() {
  const getBankRemittanceReceiptFetcher = useFetcher<
    GetBankRemittanceReceiptRequest,
    GetBankRemittanceReceiptResponse
  >();

  const identity = useIdentity();
  const condensePayments = useCondensePayments();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseBankRemittanceReceiptRequest>
    ): Promise<GetDenseBankRemittanceReceiptResponse> => {
      const { parameter, request } =
        parseGetDenseBankRemittanceReceiptKey(config);

      const getBankRemittanceReceiptKey = getGetBankRemittanceReceiptKey(
        parameter,
        request
      );

      if (!getBankRemittanceReceiptKey) {
        throw {
          code: 404,
          message: "Not Found",
        };
      }

      const getBankRemittanceReceiptResponse =
        await getBankRemittanceReceiptFetcher(getBankRemittanceReceiptKey);

      if (getBankRemittanceReceiptResponse) {
        await Promise.all([
          identity?.company?.type !== "BOUTIQUE"
            ? condensePayments(
                [getBankRemittanceReceiptResponse],
                (i) => i.payments,
                (item, value) => {
                  if (Array.isArray(value)) {
                    item.payments = value;
                  }
                },
                {
                  by: parameter.by,
                  companyId: parameter.companyId,
                }
              )
            : Promise.resolve(),
        ]);
      }

      // @ts-ignore
      return Promise.resolve(getBankRemittanceReceiptResponse);
    };
  }, [getBankRemittanceReceiptFetcher]);
}
