import useFetcher from "@/utils/use-fetcher";
import useZWR from "@/utils/use-zwr";

interface IsValidEmailRequest {
  email: string;
}

interface IsValidEmailResponse {
  valid: boolean;
}

export default function useIsValidEmail() {
  const fetcher = useFetcher<IsValidEmailRequest, IsValidEmailResponse>();
  return useZWR<IsValidEmailRequest, IsValidEmailResponse>(
    {
      url: "/users/email/validation",
      method: "get",
    },
    fetcher
  );
}
