import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { IdentityParameter } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { OrderSheetRevision } from "@/features/order-sheet-sets/order-sheet-revision.type";
import {
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useListOrderSheetRevisionsFetcher from "./use-list-order-sheet-revisions-fetcher";
import { AxiosRequestConfig } from "axios";

export const DEFAULT_LIST_REVISIONS_PAGE_SIZE = 10;

export interface ListOrderSheetRevisionsRequest
  extends PageNumberBasedListRequest {
  submitted__eq: boolean;
}

export interface ListOrderSheetRevisionsResponse
  extends PageNumberBasedListResponse {
  revisions: OrderSheetRevision[];
}

export interface ListOrderSheetRevisionsPathParameter
  extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
}

export const getListOrderSheetRevisionsTemplateUrl =
  "/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/revisions";

export function parseListOrderSheetRevisionsKey(
  key: AxiosRequestConfig<ListOrderSheetRevisionsRequest>
) {
  const urlParser = new UrlParser<ListOrderSheetRevisionsPathParameter>(
    getListOrderSheetRevisionsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      orderSheetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params!!,
  };
}

export function getListOrderSheetRevisionsKey(
  parameter: ListOrderSheetRevisionsPathParameter,
  request?: ListOrderSheetRevisionsRequest
): AxiosRequestConfig<ListOrderSheetRevisionsRequest> {
  return {
    url: calcUrl(getListOrderSheetRevisionsTemplateUrl, parameter),
    params: request,
  };
}

export default function useListOrderSheetRevisions(
  parameter: ListOrderSheetRevisionsPathParameter,
  request?: ListOrderSheetRevisionsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useListOrderSheetRevisionsFetcher();

  return useSWR(
    getListOrderSheetRevisionsKey(parameter, request),
    fetcher,
    config
  );
}
