import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { Boutique, StoredObject } from "@/features/types";
import {
  DenseOrderSheet,
  DenseOrderSheetSet,
  isOrderedToAgency,
} from "@/features/order-sheets/order-sheet.type";
import {
  BuyerAttachmentRow,
  BuyerLineSheetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import {
  BuyerOrderSheetRow,
  BuyerOrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export const buyerOrderSheetSetRowsMapper = new GridMapper<
  DenseOrderSheetSet,
  BuyerOrderSheetSetRow
>(
  "OrderSheetSet",
  (orderSheetSet) => {
    const { lineSheetSet } = orderSheetSet;
    return {
      season: lineSheetSet.season,
      submittedAt: orderSheetSet.submittedAt,
      issuedByBoutiqueName: lineSheetSet.issuedByBoutique.name,
      validUntil: lineSheetSet.validUntil,
      name: lineSheetSet.name,
      status: orderSheetSet.status,
      type: lineSheetSet.type,
      lineSheetSetId: lineSheetSet?.id || -1,
      orderedByBuyerName: orderSheetSet.createdByCompany.name,
      orderedConditionType: orderSheetSet.orderedConditionType,
      orderedAmountWithoutCondition:
        orderSheetSet.orderedAmountWithoutCondition,
      orderedAmount: orderSheetSet.orderedAmount,
      orderedQuantity: orderSheetSet.orderedQuantity,
      confirmedAmount: orderSheetSet.confirmedAmount,
      confirmedQuantity: orderSheetSet.confirmedQuantity,
      lineSheetSet: {
        id: lineSheetSet.id,
        compressedImportedFile: lineSheetSet.compressedImportedFile,
      },
      isRead: lineSheetSet.isRead,
      compressedExportedFile: orderSheetSet.compressedExportedFile,
      issuedByBoutique: lineSheetSet.issuedByBoutique,
    };
  },
  (row) => {
    let lineSheetSet;
    if (row.lineSheetSetId > -1) {
      lineSheetSet = {
        id: row.lineSheetSetId,
        type: row.type,
        name: row.name,
        compressedImportedFile: row.lineSheetSet.compressedImportedFile,
      };
    }

    return {
      submittedAt: row.submittedAt,
      issuedByBoutique: {
        id: -1,
        type: "BOUTIQUE",
        name: row.issuedByBoutiqueName,
      },
      validUntil: row.validUntil,
      status: row.status,
      lineSheetSet,
      createdByCompany: {
        id: -1,
        type: "BUYER",
        name: row.orderedByBuyerName,
      },
    };
  }
)
  .addChild(
    (
      orderSheetSet
    ): (LightLineSheet & {
      _isWritable: boolean;
      _orderSheets: DenseOrderSheet[];
    })[] => {
      const { lineSheetSet } = orderSheetSet;
      const isOrdered = isOrderedToAgency(orderSheetSet);

      return orderSheetSet.lineSheetSet.lineSheets.map((lineSheet) => {
        const orderSheets = orderSheetSet.orderSheets.filter(
          (orderSheet) => orderSheet.lineSheet.id === lineSheet.id
        );

        let isWritable = !isOrdered;

        if (!isOrdered) {
          isWritable =
            lineSheetSet.type === "SEASON" || orderSheets.length === 0;
        }

        return {
          ...lineSheet,
          _isWritable: isWritable,
          _orderSheets: orderSheets,
        };
      });
    },
    (parent, child) => {
      parent.lineSheetSet!.lineSheets = child;
    },
    new GridMapper<
      LightLineSheet & {
        _isWritable: boolean;
        _orderSheets: DenseOrderSheet[];
      },
      BuyerLineSheetRow
    >(
      "LineSheet",
      (lineSheet) => {
        return {
          name: lineSheet.name,
          type: lineSheet.type,
          isFallback:
            lineSheet.status === "PARSING_SKIPPED" ||
            lineSheet.status === "PARSING_FAILED",
          status: lineSheet.status,
          isWritable: lineSheet._isWritable,
          file: lineSheet.file,
          updatedAt: lineSheet.updatedAt,
        };
      },
      (row) => {
        return {
          name: row.name,
          type: row.type,
          status: row.status,
          file: row.file,
          updatedAt: row.updatedAt,
        };
      }
    ).addChild(
      (lineSheet) => {
        return lineSheet._orderSheets;
      },
      (parent, child) => {},
      new GridMapper<DenseOrderSheet, BuyerOrderSheetRow>(
        "OrderSheet",
        (orderSheet) => {
          const { lineSheet } = orderSheet;
          return {
            name: lineSheet.name,
            boutiqueName: orderSheet.toBeSubmitted
              ? orderSheet.toBeSubmitted.name
              : "unassigned",
            isFallback:
              lineSheet.status === "PARSING_SKIPPED" ||
              lineSheet.status === "PARSING_FAILED",
            status: orderSheet.status,
            uploadedFile: orderSheet.uploadedFile,
            exportedFile: orderSheet.exportedFile,
            orderedConditionType: orderSheet.orderedConditionType,
            orderedAmountWithoutCondition:
              orderSheet.orderedAmountWithoutCondition,
            orderedAmount: orderSheet.orderedAmount,
            orderedQuantity: orderSheet.orderedQuantity,
            confirmedAmount: orderSheet.confirmedAmount,
            confirmedQuantity: orderSheet.confirmedQuantity,
            toBeSubmitted: orderSheet.toBeSubmitted,
            lineSheetId: lineSheet.id,
          };
        },
        (row) => {
          return {
            name: row.name,
            toBeSubmitted: row.toBeSubmitted,
            status: row.status,
            uploadedFile: row.uploadedFile,
            exportedFile: row.exportedFile,
          };
        }
      )
    )
  )
  .addChild(
    (orderSheetSet) => {
      return orderSheetSet.lineSheetSet.attachments;
    },
    (parent, child) => {
      parent.lineSheetSet!.attachments = child;
    },
    new GridMapper<StoredObject, BuyerAttachmentRow>(
      "Attachment",
      (attachment) => {
        return {
          name: attachment.name,
        };
      },
      (row) => {
        return {
          name: row.name,
        };
      }
    )
  );

export function createBuyerOrderSheetSetRows(
  orderSheetSets?: DenseOrderSheetSet[],
  prevRows?: BuyerOrderSheetSetRow[]
) {
  return buyerOrderSheetSetRowsMapper.toRows(orderSheetSets, prevRows);
}
