import { useEffect, useState } from "react";
import useListLineSheetSets, {
  ListLineSheetSetsRequest,
  ListLineSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import { KeyedMutator } from "swr";

interface Parameter {
  data: ListLineSheetSetsResponse | undefined;
  mutate: KeyedMutator<ListLineSheetSetsResponse>;
}

export default function useRefreshLineSheetSet({ data, mutate }: Parameter) {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  useEffect(() => {
    async function refresh() {
      setIsRefreshing(true);
      await mutate();
      setIsRefreshing(false);
    }

    let intervalId: NodeJS.Timer | null = null;

    if (data) {
      if (
        data &&
        data.lineSheetSets.some((lineSheetSet) => {
          return (
            !lineSheetSet.isDeleted &&
            (lineSheetSet.status === "PARSING_PENDING" ||
              lineSheetSet.compressionStatus === null ||
              lineSheetSet.compressionStatus === "PENDING")
          );
        })
      ) {
        intervalId = setInterval(refresh, 5 * 1000);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [data, mutate]);

  return [isRefreshing] as const;
}
