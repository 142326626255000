import { useTranslation } from "react-i18next";
import useIdentity from "@/features/ui/hooks/use-identity";
import { Helmet } from "react-helmet-async";
import BuyerInventoryListPage from "@/features/inventories/buyer-inventory-list-page";
import AgencyInventoryListPage from "@/features/inventories/agency-inventory-list-page";

export default function InventoryListPage() {
  const identity = useIdentity();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Pathrade | {t("inventories")}</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content={`Pathrade | ${t("inventories")}`} />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      {identity?.company?.type === "AGENCY" ? (
        <AgencyInventoryListPage />
      ) : identity?.company?.type === "BUYER" ? (
        <BuyerInventoryListPage />
      ) : (
        <></>
      )}
    </>
  );
}
