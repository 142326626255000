import useFetcher from "@/utils/use-fetcher";
import {
  createDepositInvoiceKey,
  CreateDepositInvoiceRequest,
  CreateDepositInvoiceResponse,
} from "./use-create-deposit-invoice";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import { AxiosRequestConfig } from "axios";

export default function useCreateDepositInvoiceInfinite(
  config?: AxiosRequestConfig<CreateDepositInvoiceRequest>
) {
  const fetcher = useFetcher<
    CreateDepositInvoiceRequest,
    CreateDepositInvoiceResponse
  >();

  return useYWRInfinite(createDepositInvoiceKey, fetcher, config);
}
