import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";
import { CompanyType } from "@/features/types";

export interface RestoreLineSheetSetRequest {}

export interface RestoreLineSheetSetResponse {}

export interface RestoreLineSheetSetParameter {
  by: CompanyType;
  companyId: number;
  lineSheetSetId: number;
}

export const restoreLineSheetSetTemplateUrl =
  "/:by/:companyId/line-sheet-sets/:lineSheetSetId/restore";

export const restoreLineSheetSetKey: YWRKey<RestoreLineSheetSetParameter> = {
  url: (parameter) => {
    return calcUrl(restoreLineSheetSetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseRestoreLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<RestoreLineSheetSetParameter>(
    restoreLineSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useRestoreLineSheetSet() {
  const fetcher = useFetcher<
    RestoreLineSheetSetRequest,
    RestoreLineSheetSetResponse
  >();
  return useYWR(restoreLineSheetSetKey, fetcher);
}
