import AppButton, {
  AppButtonProps,
} from "@/features/line-sheet-sets/app-button";
import { useLocation, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { getLocation } from "graphql/language";

const defaultButtonProps: AppButtonProps = {
  size: "large",
  variant: "ghost",
  chakraProps: {
    p: "0",
    minW: "20px",
    h: "20px",
    as: Link,
  },
};

function getChild(pageNumber: number | AppPaginationArrow) {
  if (typeof pageNumber === "number") {
    return pageNumber;
  } else {
    let svg;
    switch (pageNumber) {
      case "double-left":
        svg = "icon_chevron_double_left.svg";
        break;
      case "left":
        svg = "icon_chevron_left.svg";
        break;
      case "right":
        svg = "icon_chevron_right.svg";
        break;
      default:
        svg = "icon_chevron_double_right.svg";
    }
    return <SVG src={`/icons/${svg}`} height={"12"} />;
  }
}

export type AppPaginationArrow =
  | "double-left"
  | "left"
  | "right"
  | "double-right";

interface AppPaginationButtonProps {
  pageNumber: number | AppPaginationArrow;
  href?: string;
  isActive?: boolean;
}

export default function AppPaginationButton({
  pageNumber,
  isActive = false,
  href,
}: AppPaginationButtonProps) {
  const { chakraProps: defaultChakraProps, ...appProps } = defaultButtonProps;
  const isDisabled = href === undefined;

  const chakraProps = {
    ...defaultChakraProps,
    to: href,
  };

  return (
    <AppButton
      {...appProps}
      isDisabled={isDisabled}
      isActive={isActive}
      chakraProps={chakraProps}
    >
      {getChild(pageNumber)}
    </AppButton>
  );
}
