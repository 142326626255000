import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { SearchParamsTransformer } from "@/features/ui/helpers/search-params-transformer";
import { NavigateOptions } from "react-router";

interface usePersistedQueryParameter<T> {
  entries: {
    [K in keyof T & string]: {
      parse: (rawValue: string | string[]) => T[K] | undefined;
      stringify: (value: T[K]) => string | string[] | undefined;
    };
  };
}

function serializeURLSearchParams(searchParams: URLSearchParams) {
  let obj: { [key: string]: string | string[] } = {};

  for (let [key, value] of searchParams) {
    if (obj[key]) {
      if (Array.isArray(obj[key])) {
        (obj[key] as string[]).push(value);
      } else {
        obj[key] = [obj[key] as string, value];
      }
    } else {
      obj[key] = value;
    }
  }

  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = (obj[key] as string[]).sort();
    }
  }
  return JSON.stringify(obj);
}

export function usePersistedQuery<T extends {}>({
  entries: _entries,
}: usePersistedQueryParameter<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const entriesRef = useRef(_entries);

  const transformer = useMemo(() => {
    return new SearchParamsTransformer<T>(setSearchParams, entriesRef.current);
  }, [setSearchParams]);

  const patch = useCallback(
    (_key: keyof T & string, _value: T[keyof T & string]) => {
      transformer.patch(_key, _value);
    },
    [transformer]
  );

  const patchWithReset = useCallback(
    (
      _key: keyof T & string,
      _value: T[keyof T & string],
      resetObj: Partial<T>
    ) => {
      transformer.patch(_key, _value);
      transformer.set((prev) => {
        return {
          ...prev,
          ...resetObj,
        };
      });
    },
    [transformer]
  );

  const keyRef = useRef<string | undefined>();

  const value = useMemo(() => {
    return transformer.parse(searchParams);
  }, [searchParams, transformer]);

  const set = useCallback(
    (updater: (prev: T) => T, navigateOpts?: NavigateOptions) => {
      transformer.set(updater, navigateOpts);
    },
    [transformer]
  );

  const clear = useCallback(() => {
    transformer.clear();
  }, [transformer]);

  return { value, patch, patchWithReset, set, clear };
}
