import {
  DenseOrderSheet,
  DenseOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import {
  OrderSheetRow,
  OrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import { DeepPartial, GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { Boutique } from "@/features/types";
import { GridMapperAddChildParameters } from "@/features/invoices/order-confirmation-invoices/helpers/agency-order-confirmation-invoice-row-helper";

export const orderSheetSetRowMapper = new GridMapper<
  DenseOrderSheetSet,
  OrderSheetSetRow
>(
  "OrderSheetSet",
  (orderSheetSet) => {
    const { lineSheetSet } = orderSheetSet;
    return {
      season: lineSheetSet.season,
      submittedAt: orderSheetSet.submittedAt,
      issuedByBoutiqueName: lineSheetSet.issuedByBoutique.name,
      validUntil: lineSheetSet.validUntil,
      name: lineSheetSet.name,
      status: orderSheetSet.status,
      type: lineSheetSet.type,
      lineSheetSetId: lineSheetSet?.id || -1,
      orderedByBuyerName: orderSheetSet.createdByCompany.name,
      orderedConditionType: orderSheetSet.orderedConditionType,
      orderedAmountWithoutCondition:
        orderSheetSet.orderedAmountWithoutCondition,
      orderedAmount: orderSheetSet.orderedAmount,
      orderedQuantity: orderSheetSet.orderedQuantity,
      confirmedAmount: orderSheetSet.confirmedAmount,
      confirmedQuantity: orderSheetSet.confirmedQuantity,
      lineSheetSet: {
        id: lineSheetSet.id,
        compressedImportedFile: lineSheetSet.compressedImportedFile,
      },
      isRead: lineSheetSet.isRead,
      compressedExportedFile: orderSheetSet.compressedExportedFile,
      issuedByBoutique: lineSheetSet.issuedByBoutique,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);

export const orderSheetRowMapper = new GridMapper<
  DenseOrderSheet,
  OrderSheetRow
>(
  "OrderSheet",
  (orderSheet) => {
    const { lineSheet } = orderSheet;
    return {
      name: lineSheet.name,
      boutiqueName: orderSheet.toBeSubmitted
        ? orderSheet.toBeSubmitted.name
        : "unassigned",
      isFallback:
        lineSheet.status === "PARSING_SKIPPED" ||
        lineSheet.status === "PARSING_FAILED",
      status: orderSheet.status,
      uploadedFile: orderSheet.uploadedFile,
      exportedFile: orderSheet.exportedFile,
      orderedConditionType: orderSheet.orderedConditionType,
      orderedAmountWithoutCondition: orderSheet.orderedAmountWithoutCondition,
      orderedAmount: orderSheet.orderedAmount,
      orderedQuantity: orderSheet.orderedQuantity,
      confirmedAmount: orderSheet.confirmedAmount,
      confirmedQuantity: orderSheet.confirmedQuantity,
      toBeSubmitted: orderSheet.toBeSubmitted,
      lineSheetId: lineSheet.id,
    };
  },
  (row) => {
    return {
      name: row.name,
      toBeSubmitted: row.toBeSubmitted,
      status: row.status,
      uploadedFile: row.uploadedFile,
      exportedFile: row.exportedFile,
    };
  }
);

export function createAddOrderSheetParameter<T>(
  getter: (source: T) => DenseOrderSheet | DenseOrderSheet[] | undefined,
  integrator: (parent: DeepPartial<T>, children: DenseOrderSheet[]) => void
): GridMapperAddChildParameters<T, DenseOrderSheet, OrderSheetRow> {
  return [getter, integrator, orderSheetRowMapper];
}
