import { Box, ChakraProps, Flex, Input, Text } from "@chakra-ui/react";
import { InputProps } from "@chakra-ui/input/dist/input";
import { ReactElement, ReactNode } from "react";
import SVG from "react-inlinesvg";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { AppIconProps } from "@/features/ui/app-icon";

interface AppInputProps extends Omit<InputProps, "onChange"> {
  value?: string;
  onChange?: (value?: string) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  leftIcon?: ReactElement<AppIconProps>;
  rightText?: string;
  isLiteral?: boolean;
}

function calcCurrencyStyle({
  isDisabled,
}: Pick<AppInputProps, "isDisabled">): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: "0 4px 4px 0",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#8F8F8C",
    backgroundColor: "#FCFCFB",
  };

  if (isDisabled) {
    return {
      ...defaultStyle,
      borderColor: "#8F8F8C",
      backgroundColor: "#EFEFED",
      color: "#8F8F8C",
    };
  }

  return defaultStyle;
}

function calcInputStyle({
  isDisabled,
  rightText,
}: {
  isDisabled?: boolean;
  rightText?: string;
}): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: rightText ? "4px 0px 0px 4px" : "4px",
    border: "0.5px solid var(--Gray-Groomy, #8F8F8C)",
    borderWidth: rightText ? "0.5px 0px 0.5px 0.5px" : "0.5px",
    background: "var(--White, #FFF)",
  };

  if (isDisabled) {
    return {
      ...defaultStyle,
      background: "var(--pathrade-gray-light, #EFEFED)",
      color: "#8F8F8C",
    };
  }

  return defaultStyle;
}

function calcStyle(props: AppInputProps) {
  let style: ChakraProps = {
    borderRadius: "4px",
    border: "0.5px solid var(--Gray-Groomy, #8F8F8C)",
    backgroundColor: "var(--White, #FFF)",
  };

  const { isDisabled, isReadOnly } = props;

  if (isReadOnly) {
    style = {
      ...style,
      borderColor: "#6F6F6C",
      backgroundColor: "#FFF",
      color: "#6F6F6C",
      cursor: "default",
    };
  } else if (isDisabled) {
    style = {
      ...style,
      color: "#8F8F8C",
      backgroundColor: "var(--pathrade-gray-light, #EFEFED)",
    };
  }

  if (props.leftIcon) {
    style = {
      ...style,
      paddingLeft: "32px",
    };
  }

  if (props.isClearable) {
    style = {
      ...style,
      paddingRight: "32px",
    };
  }

  return style;
}

export default function AppInput(props: AppInputProps) {
  const {
    value,
    isDisabled,
    leftIcon,
    rightText,
    isClearable,
    onChange,
    isLiteral,
    autoComplete = "off",
    ...rest
  } = props;
  const style = calcStyle(props);

  if (props.isLiteral) {
    return <Text>{props.value}</Text>;
  }

  return (
    <Box position={"relative"}>
      <Flex
        height={"28px"}
        fontSize={"12px"}
        flexDirection={"row"}
        // width={width}
      >
        <Input
          height={"28px"}
          fontSize={"12px"}
          padding={"0 8px"}
          margin={"0"}
          minHeight={"0"}
          // height={"auto"}
          // fontSize={"inherit"}
          value={value || ""}
          isDisabled={isDisabled}
          autoComplete={autoComplete}
          {...rest}
          {...style}
          {...calcInputStyle({ isDisabled, rightText })}
          onChange={(event) => {
            onChange?.(event.target.value);
            //onChange(parseFloat(event.target.value));
          }}
        />
        {rightText && (
          <Text
            padding={"0 8px"}
            alignSelf={"stretch"}
            display={"inline-flex"}
            alignItems={"center"}
            minWidth={"30px"}
            // width={currencyWidth}
            {...calcCurrencyStyle({ isDisabled })}
          >
            {rightText}
          </Text>
        )}
      </Flex>

      {props.leftIcon && (
        <Flex
          position={"absolute"}
          top={"0"}
          left={"8px"}
          height={"100%"}
          // backgroundColor={"red.200"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={2}
        >
          {props.leftIcon}
        </Flex>
      )}

      {props.isClearable && value && (
        <Flex
          position={"absolute"}
          top={"0"}
          right={"8px"}
          height={"100%"}
          // backgroundColor={"red.200"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={2}
        >
          <AppIconButton
            ariaLabel={"delete"}
            icon={"x"}
            size={"small"}
            onClick={() => {
              onChange?.(undefined);
            }}
          />
        </Flex>
      )}
    </Box>
  );
}
