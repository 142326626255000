import {
  LineSheetSet,
  LineSheetSetStatus,
  LineSheetSetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  IdentityParameter,
  OrderByItem,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface ListLineSheetSetsRequest extends PageNumberBasedListRequest {
  id__in?: number[];
  status__in?: LineSheetSetStatus[];
  status__eq?: LineSheetSetStatus;
  validUntil__gt?: Date;
  isValid__eq?: boolean;
  type__eq?: LineSheetSetType;
  "issuedByCompany.id__in"?: number[];
  "issuedByCompany.id__eq"?: number;
  "madeByCompany.id__in"?: number[];
  "madeByCompany.id__eq"?: number;
  season__eq?: string;
  season__in?: string[];
  query?: string;
  orderBy: OrderByItem[];
  isPublished__eq?: boolean;
  isDeleted__eq?: boolean;
}

export interface ListLineSheetSetsResponse extends PageNumberBasedListResponse {
  lineSheetSets: LineSheetSet[];
}

export interface ListLineSheetSetsPathParameter extends IdentityParameter {}

export function getListLineSheetSetsParameter(
  identity: Identity | null
): ListLineSheetSetsPathParameter | null {
  return toIdentityParameter(identity);
}

export const listLineSheetSetsTemplateUrl = (() => {
  let url = "";
  url += "/:by/:companyId/line-sheet-sets";
  return url;
})();

export function getListLineSheetSetsKey(
  identityOrParameter?: Identity | ListLineSheetSetsPathParameter | null,
  request?: ListLineSheetSetsRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListLineSheetSetsParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    return {
      url: calcUrl(listLineSheetSetsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListLineSheetSets(
  parameter?: ListLineSheetSetsPathParameter,
  request?: ListLineSheetSetsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListLineSheetSetsRequest,
    ListLineSheetSetsResponse
  >();

  const { mutate, ...rest } = useSWR(
    getListLineSheetSetsKey(parameter, request),
    fetcher,
    config
  );

  return {
    mutate,
    ...rest,
  };
}
