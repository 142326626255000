import { useMemo } from "react";
import { PriceAdjustedBy } from "@/features/line-sheet-sets/line-sheet-set.types";
import { produce } from "immer";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Text } from "@chakra-ui/react";

interface PriceAdjustedByTypeSelectProps {
  value: PriceAdjustedBy["by"] | undefined;
  onSelect: (value?: PriceAdjustedBy["by"]) => void;
  width?: string;
  height?: string;
  isDisabled?: boolean;
}

export default function PriceAdjustedByTypeSelect({
  value,
  onSelect,
  isDisabled = false,
  width,
  height,
}: PriceAdjustedByTypeSelectProps) {
  const { tTitle } = useI18nHelper();

  const options = useMemo((): AppSelectOption<PriceAdjustedBy["by"]>[] => {
    const values: PriceAdjustedBy["by"][] = ["MARKUP", "DISCOUNT"];
    return values.map((value) => {
      return {
        name: value.substring(0, 1) + value.substring(1).toLowerCase(),
        value: value,
      };
    });
  }, []);

  return (
    <AppSelect<PriceAdjustedBy["by"]>
      width={width}
      height={height}
      name={tTitle("condition")}
      value={value}
      options={options}
      isClearable={false}
      onSelect={onSelect}
      isDisabled={isDisabled}
    />
  );
}
