import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export interface ToBeUpdatedOrderSheet {
  id: number;
  name: string;
  suffix?: string;
  orderedBy?: string;
  issuedBy: string;
  toBeSubmitted: { id: number, name: string } | null;
}

interface ToBeUpdatedOrderSheetsPartialProps {
  header: JSX.Element;
  orderSheets: ToBeUpdatedOrderSheet[];
}
export default function ToBeUpdatedOrderSheetList({
  header,
  orderSheets,
}: ToBeUpdatedOrderSheetsPartialProps) {
  const { t } = useTranslation();
  const isOrderedByVisible = useMemo(() => {
    return orderSheets.length > 0 && orderSheets[0].orderedBy !== undefined;
  }, [orderSheets]);

  return (
    <Flex
      flexDirection={"column"}
      fontSize={"12px"}
      gap={"16px"}
      color={"#444440"}
    >
      <Heading as={"h3"} fontSize={"inherit"} fontWeight={"400"}>
        {header}
      </Heading>
      <Grid
        gridTemplateColumns={
          isOrderedByVisible ? "0.4fr 0.4fr 1fr" : "0.4fr 1fr"
        }
        gridAutoRows={"24px"}
        alignItems={"end"}
        justifyItems={"stretch"}
      >
        {isOrderedByVisible && (
          <GridItem
            borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
            justifySelf={"stretch"}
          >
            {toTitle(t("ordered_by"))}
          </GridItem>
        )}

        <GridItem
          borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
          justifySelf={"stretch"}
        >
          {toTitle(t("issued_by"))}
        </GridItem>
        <GridItem
          borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
          justifySelf={"stretch"}
        >
          {toTitle(t("title"))}
        </GridItem>
        {orderSheets.map((orderSheet) => {
          return (
            <React.Fragment key={`ToBeUpdatedOrderSheetList_${orderSheet.id}`}>
              {isOrderedByVisible && <Box>{orderSheet.orderedBy}</Box>}
              <Box>{orderSheet.toBeSubmitted?.name}</Box>
              <Box
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                title={orderSheet.name}
              >
                {orderSheet.name}
              </Box>
            </React.Fragment>
          );
        })}
      </Grid>
    </Flex>
  );
}
