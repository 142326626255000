import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useListAllSheetTabs, {
  ListAllSheetTabsPathParameter,
  ListAllSheetTabsRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs";
import { useSetRecoilState } from "recoil";
import { inflatedTabsFamily } from "@/features/line-sheet-sets/helpers/sheet-state";
import {
  isProductFiltered,
  SheetTabFilterQuery,
} from "@/features/order-sheet-sets/sheet-tab-filter";
import { AppUtils } from "@/features/ui/utils/app-utils";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import useGetListOrderSheetTabsWithRevision from "@/features/line-sheet-sets/hooks/use-list-all-sheet-tabs-revision";
import { CompanyType } from "@/features/types";
import {
  GetOrderSheetRevisionPathParameter,
  GetOrderSheetRevisionRequest,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-revision";

interface UseFetchAndSetOrderSheetTabsParameter {
  data?: {
    buyerCompanyId: number;
    orderSheetSetId: number;
    orderSheetId: number;
    revisionNumber: number;
    latestRevisionNumber: number;
    lineSheetSetId: number;
    lineSheetId: number;
    tabIndexes: number[];
  };
  query?: SheetTabFilterQuery;
}

export default function useFetchAndSetOrderSheetTabs({
  data,
  query,
}: UseFetchAndSetOrderSheetTabsParameter) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { error: showError } = useAppToasts();

  const listAllSheetTabsKey = useMemo(():
    | {
        parameter: ListAllSheetTabsPathParameter;
        request: ListAllSheetTabsRequest;
      }
    | undefined => {
    if (data && company) {
      return {
        parameter: {
          by: company.type,
          companyId: company.id,
          orderSheetSetId: data.orderSheetSetId,
          orderSheetId: data.orderSheetId,
        },
        request: {
          lineSheetSetId: data.lineSheetSetId,
          lineSheetId: data.lineSheetId,
          sheetTabIndexes: data.tabIndexes,
        },
      };
    }
  }, [company, data]);

  const {
    isLoading: isListAllSheetTabsLoading,
    isValidating: isListAllSheetTabsValidating,
    data: listAllSheetTabsData,
    error: listAllSheetTabsError,
    mutate: mutateListAllSheetTabs,
  } = useListAllSheetTabs(
    listAllSheetTabsKey?.parameter,
    listAllSheetTabsKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  const listAllSheetTabsRevisionKey = useMemo((): {
    parameter: GetOrderSheetRevisionPathParameter;
    request: GetOrderSheetRevisionRequest;
  } => {
    if (data && company) {
      return {
        parameter: {
          by: "BUYER" as CompanyType,
          companyId: data.buyerCompanyId,
          orderSheetSetId: data.orderSheetSetId,
          orderSheetId: data.orderSheetId,
          revisionNumber: data.revisionNumber,
        },
        request: {
          lineSheetSetId: data.lineSheetSetId,
          lineSheetId: data.lineSheetId,
          sheetTabIndexes: data.tabIndexes,
        },
      };
    }
    return {
      parameter: {
        by: "BUYER" as CompanyType,
        companyId: -1,
        orderSheetSetId: -1,
        orderSheetId: -1,
        revisionNumber: -1,
      },
      request: {
        lineSheetSetId: -1,
        lineSheetId: -1,
        sheetTabIndexes: [],
      },
    };
  }, [company, data]);

  const {
    isLoading: isGetListAllSheetTabsWithRevisionLoading,
    isValidating: isGetListAllSheetTabsWithRevisionValidating,
    data: getListAllSheetTabsWithRevisionData,
    error: getListAllSheetTabsWithRevisionError,
    mutate: mutateGetListAllSheetTabsWithRevision,
  } = useGetListOrderSheetTabsWithRevision(
    listAllSheetTabsRevisionKey.parameter,
    listAllSheetTabsRevisionKey.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(listAllSheetTabsError);
  }, [showError, listAllSheetTabsError]);

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId: data?.orderSheetSetId || -1,
      orderSheetId: data?.orderSheetId || -1,
      revisionNumber: data?.revisionNumber || -1,
    };
  }, [data?.orderSheetId, data?.orderSheetSetId, data?.revisionNumber]);

  const setInflatedTabsFamily = useSetRecoilState(inflatedTabsFamily(sheetKey));
  const { filterInflatedProducts, resetInflatedSheet } = useInflatedTabs();
  //query가 변경되면 반복 실행되어버림..
  //tabIndex, brandName__eq같은 filter가 변경되어도..
  useEffect(() => {
    if (
      !isListAllSheetTabsLoading &&
      !isListAllSheetTabsValidating &&
      listAllSheetTabsData &&
      sheetKey
    ) {
      setInflatedTabsFamily((prev) => {
        const sheetTabs =
          data?.latestRevisionNumber &&
          data?.latestRevisionNumber > -1 &&
          sheetKey.revisionNumber &&
          sheetKey.revisionNumber > -1 &&
          getListAllSheetTabsWithRevisionData &&
          getListAllSheetTabsWithRevisionData.sheetTabs &&
          data?.latestRevisionNumber !== sheetKey.revisionNumber
            ? getListAllSheetTabsWithRevisionData.sheetTabs
            : listAllSheetTabsData.sheetTabs;

        // Save 후 다시 불러오는 상황이면, isDirty를 false로 초기화해주어야 함.
        // mutateListAllSheetTabs가 save 후에만 호출되고 있으므로, prev?.tabs를 사용하는 경우에도 isDirty가 false로 초기화되어야 함.
        const tabs =
          prev?.tabs.map((tab) => {
            return {
              ...tab,
              isDirty: false,
            };
          }) ||
          sheetTabs.map((tab) => {
            return {
              ...tab,
              products: tab.products.map((product) => {
                return {
                  ...product,
                  priceAdjustedBy: {
                    ...product.priceAdjustedBy,
                    value: AppUtils.trimFloat(product.priceAdjustedBy.value),
                  },
                  // isFiltered: isProductFiltered(product, query),
                };
              }),
              isDirty: false,
            };
          });

        return {
          key: sheetKey,
          tabs: tabs.map((tab) => {
            return {
              ...tab,
              products: tab.products.map((product) => {
                return {
                  ...product,
                  isFiltered: isProductFiltered(product, query),
                };
              }),
            };
          }),
          // isDirty: false,
        };
      });
    }
  }, [
    isListAllSheetTabsValidating,
    isListAllSheetTabsLoading,
    listAllSheetTabsData,
    getListAllSheetTabsWithRevisionData,
    setInflatedTabsFamily,
    query,
    sheetKey,
    data?.latestRevisionNumber,
  ]);

  useEffect(() => {
    return () => {
      resetInflatedSheet(sheetKey);
    };
  }, [resetInflatedSheet, sheetKey]);

  const setInflatedTabsClean = useCallback(() => {
    if (data) {
      setInflatedTabsFamily((prev) => {
        if (prev) {
          return {
            ...prev,
            tabs: prev.tabs.map((tab) => {
              return {
                ...tab,
                isDirty: false,
              };
            }),
          };
        } else {
          return prev;
        }
      });
    }
  }, [setInflatedTabsFamily, data]);

  return {
    mutateListAllSheetTabs,
    mutateListAllSheetTabsRevision: mutateGetListAllSheetTabsWithRevision,
    setInflatedTabsClean,
  };
}
