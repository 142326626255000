import { DenseCreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useGetDenseCreditFetcher from "@/features/invoices/credit-invoices/hooks/use-get-dense-credit-fetcher";
import useSWR, { SWRConfiguration } from "swr";

export interface GetDenseCreditInvoiceParameter extends IdentityParameter {
  creditInvoiceId: number;
}
export interface GetDenseCreditInvoiceRequest {}
export interface GetDenseOrderDenseConfirmationInvoiceResponse
  extends DenseCreditInvoice {}

export const getDenseCreditInvoiceTemplateUrl = `/:by/:companyId/dense-credit-invoices/:creditInvoiceId`;

export function getGetDenseCreditInvoiceKey(
  parameter?: GetDenseCreditInvoiceParameter,
  request?: GetDenseCreditInvoiceRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDenseCreditInvoiceTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetDenseCreditInvoiceKey(
  key: AxiosRequestConfig<GetDenseCreditInvoiceRequest>
) {
  const urlParser = new UrlParser<GetDenseCreditInvoiceParameter>(
    getDenseCreditInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      creditInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetDenseCreditInvoice(
  parameter?: GetDenseCreditInvoiceParameter,
  request?: GetDenseCreditInvoiceRequest,
  config?: SWRConfiguration<GetDenseOrderDenseConfirmationInvoiceResponse>
) {
  const fetcher = useGetDenseCreditFetcher();
  return useSWR(
    getGetDenseCreditInvoiceKey(parameter, request),
    fetcher,
    config
  );
}
