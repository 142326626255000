import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export interface GetOrderConfirmationInvoiceParameter
  extends IdentityParameter {
  orderConfirmationInvoiceId: number;
}

export interface GetOrderConfirmationInvoiceRequest {}
export interface GetOrderConfirmationInvoiceResponse
  extends OrderConfirmationInvoice {}

export const getOrderConfirmationInvoiceTemplateUrl =
  "/:by/:companyId/order-confirmation-invoices/:orderConfirmationInvoiceId";
export function getGetOrderConfirmationInvoiceKey(
  parameter: GetOrderConfirmationInvoiceParameter,
  request: GetOrderConfirmationInvoiceRequest
) {
  return {
    url: calcUrl(getOrderConfirmationInvoiceTemplateUrl, parameter),
    params: request,
  };
}

export default function useGetOrderConfirmationInvoice(
  parameter: GetOrderConfirmationInvoiceParameter,
  request: GetOrderConfirmationInvoiceRequest,
  config?: SWRConfiguration<GetOrderConfirmationInvoiceResponse>
) {
  const fetcher = useFetcher<
    GetOrderConfirmationInvoiceRequest,
    GetOrderConfirmationInvoiceResponse
  >();
  return useSWR(
    getGetOrderConfirmationInvoiceKey(parameter, request),
    fetcher,
    config
  );
}
