import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { OrderSheetTab } from "@/features/order-sheets/order-sheet.type";
import { OrderSheetRevision } from "@/features/order-sheet-sets/order-sheet-revision.type";
import { CompanyType } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface CreateOrderSheetRevisionRequest {
  orderSheetTabs: OrderSheetTab[];
}

export interface CreateOrderSheetRevisionResponse extends OrderSheetRevision {}

export interface CreateOrderSheetRevisionPathParameter {
  by: CompanyType;
  companyId: number;
  orderSheetSetId: number;
  orderSheetId: number;
}

export const createOrderSheetRevisionTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/revisions`;

export const createOrderSheetRevisionKey: YWRKey<CreateOrderSheetRevisionPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(createOrderSheetRevisionTemplateUrl, parameter);
    },
    method: "post",
  };

export default function useCreateOrderSheetRevision() {
  const fetcher = useFetcher<
    CreateOrderSheetRevisionRequest,
    CreateOrderSheetRevisionResponse
  >();
  return useYWR(createOrderSheetRevisionKey, fetcher);
}
