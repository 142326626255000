import {
  getGetLineSheetSetKey,
  GetLineSheetSetRequest,
  GetLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-get-line-sheet-set";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseLineSheetSetRequest,
  GetDenseLineSheetSetResponse,
  parseGetDenseLineSheetSetKey,
} from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import { DenseLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  getGetOrderSheetSetKey,
  GetOrderSheetSetRequest,
  GetOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-get-order-sheet-set";
import {
  getListOrderSheetSetsKey,
  ListOrderSheetSetsRequest,
  ListOrderSheetSetsResponse,
} from "@/features/line-sheet-sets/hooks/use-list-order-sheet-sets";

export default function useGetDenseLineSheetSetFetcher() {
  const getLineSheetSetFetcher = useFetcher<
    GetLineSheetSetRequest,
    GetLineSheetSetResponse
  >();

  const getOrderSheetSetFetcher = useFetcher<
    GetOrderSheetSetRequest,
    GetOrderSheetSetResponse
  >();

  const listOrderSheetSetsFetcher = useFetcher<
    ListOrderSheetSetsRequest,
    ListOrderSheetSetsResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseLineSheetSetRequest>
    ): Promise<GetDenseLineSheetSetResponse> => {
      const { parameter, request } = parseGetDenseLineSheetSetKey(config);

      const getLineSheetSetResponse = await getLineSheetSetFetcher(
        getGetLineSheetSetKey(parameter, request)!
      );

      if (parameter.orderSheetSetId) {
        const getOrderSheetSetResponse = await getOrderSheetSetFetcher(
          getGetOrderSheetSetKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
              orderSheetSetId: parameter.orderSheetSetId,
            },
            {}
          )
        );

        return Promise.resolve({
          ...getLineSheetSetResponse,
          orderSheetSet: getOrderSheetSetResponse,
          lineSheets: getLineSheetSetResponse.lineSheets.map(
            (lineSheet): DenseLineSheet => {
              return {
                ...lineSheet,
                orderSheets: getOrderSheetSetResponse.orderSheets.filter(
                  (i) => i.lineSheet.id === lineSheet.id
                ),
              };
            }
          ),
        });
      } else if (
        parameter.infer &&
        getLineSheetSetResponse.publishedAt !== null
      ) {
        //buyer면 infer하자
        //lss상세 -> os작성 -> 뒤로가기 하면 lss상세에 ?oss_id가없음; infer해야할듯?
        //아니야 생각해보면, 애는 상세페이지랑 독립적이어야 하자나..
        const listOrderSheetSetsResponse = await listOrderSheetSetsFetcher(
          getListOrderSheetSetsKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
            },
            {
              pageNumber: 1,
              pageSize: 1,
              "lineSheetSet.id__in": [getLineSheetSetResponse.id],
            }
          )!
        );

        if (listOrderSheetSetsResponse.orderSheetSets.length === 1) {
          const orderSheetSet = listOrderSheetSetsResponse.orderSheetSets[0];
          return Promise.resolve({
            ...getLineSheetSetResponse,
            orderSheetSet: orderSheetSet,
            lineSheets: getLineSheetSetResponse.lineSheets.map(
              (lineSheet): DenseLineSheet => {
                return {
                  ...lineSheet,
                  orderSheets: orderSheetSet.orderSheets.filter(
                    (i) => i.lineSheet.id === lineSheet.id
                  ),
                };
              }
            ),
          });
        }
      }

      return Promise.resolve({
        ...getLineSheetSetResponse,
        lineSheets: getLineSheetSetResponse.lineSheets.map(
          (lineSheet): DenseLineSheet => {
            return {
              ...lineSheet,
              orderSheets: [],
            };
          }
        ),
      });
    };
  }, [
    getLineSheetSetFetcher,
    getOrderSheetSetFetcher,
    listOrderSheetSetsFetcher,
  ]);
}
