import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import {
  LightOrderSheet,
  LightOrderSheetSet,
} from "@/features/order-sheets/order-sheet.type";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useMemo } from "react";
import AppTable, { AppTableCell } from "@/features/line-sheet-sets/app-table";
import { Text } from "@chakra-ui/react";

interface ConfirmedConditionPanelProps {
  orderSheetSet?: LightOrderSheetSet;
}

export default function ConfirmedConditionPanel({
  orderSheetSet,
}: ConfirmedConditionPanelProps) {
  const { tTitle } = useI18nHelper();

  const rows = useMemo(() => {
    if (
      orderSheetSet &&
      orderSheetSet.boutiqueConfirmationMetadataList &&
      orderSheetSet.boutiqueConfirmationMetadataList.length > 0
    ) {
      const { boutiqueConfirmationMetadataList: metadataList } = orderSheetSet;

      return metadataList?.flatMap((metadata): AppTableCell<any>[][] => {
        const toBeSubmittedName = metadata.toBeSubmitted.name;

        return metadata.conditions.map((condition) => {
          return [
            {
              value: toBeSubmittedName,
            },
            {
              value: condition.category,
            },
            {
              value: condition.type,
            },
            {
              value: (
                <Text textAlign={"right"} paddingRight={"16px"}>
                  {" "}
                  {condition.value}
                </Text>
              ),
            },
            {
              value: "%",
            },
          ];
        });
      });
    }
  }, [orderSheetSet]);

  if (!orderSheetSet || !rows) {
    return null;
  }

  return (
    <AppPanel title={tTitle("condition")} isCompact={true}>
      <AppTable
        headers={[
          {
            value: tTitle("submit_to"),
            width: "minmax(88px, 1fr)",
          },
          {
            value: tTitle("category"),
            width: "minmax(88px, 144px)",
          },
          {
            value: tTitle("type"),
            width: "minmax(88px, 144px)",
          },
          {
            value: tTitle("final_condition"),
            width: "minmax(88px, 144px)",
          },
          {
            value: "",
            width: "min(16px, 64px)",
          },
        ]}
        rows={rows}
        rowHeight={"32px"}
        gap={"8px"}
        padding={"0 32px 16px 32px"}
      />
    </AppPanel>
  );
}
