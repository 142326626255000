import { YWRResponse } from "@/features/ui/hooks/use-ywr";
import { useCallback } from "react";
import { FormikHelpers } from "formik";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface Foo<Parameter, Request, DumbRequest, Response, FormFields> {
  dumbFunction: () => {
    addUpdate: (parameter: Parameter, request: DumbRequest) => Promise<void>;
    isUpdateQueued: (parameter: Parameter) => boolean;
  };
  smartFunction: () => {
    fire: (parameter: Parameter, request: Request) => Promise<Response>;
  } & YWRResponse<Parameter, Request, Response>;
  isSmart: (request: Request | DumbRequest) => request is Request;
  toRequest: (values: FormFields) => Request | DumbRequest;
  mutate: () => void;
  defaultRedirectUrl?: string;
}

export default function useDumbMixedUpdate<
  Parameter,
  Request,
  DumbRequest,
  Response,
  FormFields
>({
  dumbFunction,
  smartFunction,
  isSmart,
  toRequest,
  mutate,
  defaultRedirectUrl,
}: Foo<Parameter, Request, DumbRequest, Response, FormFields>) {
  const { addUpdate, isUpdateQueued } = dumbFunction();
  const { fire, clear } = smartFunction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { navigate } = useRouterHelper();

  const addOrFire = useCallback(
    async (parameter: Parameter, request: Request | DumbRequest) => {
      let result = false;
      if (isSmart(request)) {
        const response = await fire(parameter, request);
        await mutate();
        return response;
      } else {
        addUpdate(parameter, request);
        result = true;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(result);
        }, 500);
      });
    },
    [addUpdate, fire, isSmart, mutate]
  );

  const handleSubmit = useCallback(
    async (
      parameter: Parameter,
      values: FormFields,
      formikHelpers: FormikHelpers<FormFields>
    ) => {
      formikHelpers.setSubmitting(true);
      const request = toRequest(values);
      let result;
      try {
        result = await addOrFire(parameter, request);
      } catch (e) {
        showError(e);
      } finally {
        formikHelpers.setSubmitting(false);
      }

      if (result) {
        if (typeof result === "boolean") {
          clear();
        }

        navigate(-1, {
          replace: true,
          defaultPage: defaultRedirectUrl,
        });
      }
    },
    [toRequest, addOrFire, showError, navigate, defaultRedirectUrl, clear]
  );

  return {
    handleSubmit,
    isUpdateQueued,
  };
}
