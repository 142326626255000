import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";
import { Company } from "@/features/types";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  getListBankInfoListKey,
  ListBankInfoListPathParameter,
  ListBankInfoListRequest,
  ListBankInfoListResponse,
} from "@/features/invoices/triangular-trades/hooks/use-list-bank-info";

export interface ListAllBankInfoListRequest
  extends Omit<ListBankInfoListRequest, "pageSize" | "pageNumber"> {}
type ListAllBankInfoListResponse = Company[];
export interface ListAllBankInfoListPathParameter
  extends ListBankInfoListPathParameter {}

export const listAllBankInfoListTemplateUrl = `/:by/:companyId/bank_info`;

export function getListAllBankInfoListKey(
  parameter?: ListAllBankInfoListPathParameter,
  request?: ListAllBankInfoListRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllBankInfoListTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllBankInfoListKey(
  key: AxiosRequestConfig<ListAllBankInfoListRequest>
) {
  const urlParser = new UrlParser<ListAllBankInfoListPathParameter>(
    listAllBankInfoListTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllBankInfoList(
  parameter?: ListAllBankInfoListPathParameter,
  request?: ListAllBankInfoListRequest,
  config: SWRConfiguration = { revalidateOnFocus: false }
) {
  const listFetcher = useFetcher<
    ListBankInfoListRequest,
    ListBankInfoListResponse
  >();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListBankInfoListRequest>) => {
        const { parameter, request } = parseListAllBankInfoListKey(config);
        return listFetcher(getListBankInfoListKey(parameter, request)!);
      },
      (response) => {
        return response.bankInfoList;
      }
    );
  }, [listFetcher]);

  return useSWR(getListAllBankInfoListKey(parameter, request), fetcher, config);
}
