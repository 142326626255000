import useFetcher from "@/utils/use-fetcher";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import { withPreSignedFetcher } from "@/features/ui/helpers/fetcher-helpers";
import {
  downloadUnoFileKey,
  DownloadUnoFileRequest,
  DownloadUnoFileResponse,
} from "@/hooks/use-download-uno-file";
import { useMemo } from "react";

export default function useDownloadUnoFileInfinite() {
  const vanillaFetcher = useFetcher<
    DownloadUnoFileRequest,
    DownloadUnoFileResponse
  >();
  const preSignedFetcher = useMemo(() => {
    return withPreSignedFetcher(vanillaFetcher);
  }, [vanillaFetcher]);

  return useYWRInfinite(downloadUnoFileKey, preSignedFetcher);
}
