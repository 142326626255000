import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";

export interface CreateCreditInvoiceRequest
  extends Omit<
    CreditInvoice,
    "id" | "isUsed" | "createdAt" | "isRead" | "usingProformaInvoices"
  > {}

export interface CreateCreditInvoiceResponse extends CreditInvoice {}

export interface CreateCreditInvoicePathParameter extends IdentityParameter {}

export const createCreditInvoiceTemplateUrl = `/:by/:companyId/credit-invoices`;

export const createCreditInvoiceKey: YWRKey<CreateCreditInvoicePathParameter> =
  {
    url: (parameters: CreateCreditInvoicePathParameter) => {
      return calcUrl(createCreditInvoiceTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateCreditInvoice(
  config?: AxiosRequestConfig<CreateCreditInvoiceRequest>
) {
  const fetcher = useFetcher<
    CreateCreditInvoiceRequest,
    CreateCreditInvoiceResponse
  >();
  return useYWR(createCreditInvoiceKey, fetcher, config);
}
