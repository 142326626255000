import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useCallback, useMemo } from "react";
import { AppGridMeta } from "@/features/order-sheet-sets/app-grid";
import { InventoryRow, Inventory } from "@/features/inventories/inventory.type";
import AppTable from "@/features/line-sheet-sets/app-table";
import { StoredObject } from "@/features/types";
import StoredObjectExtraContent from "@/features/inventories/stored-objects-table";
import { buyerInventoryRowsMapper } from "@/features/inventories/hooks/use-buyer-inventory-rows";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import { UpdateInventoryModalProps } from "@/features/inventories/update-inventory-modal";

interface UseInvoiceGridProps {
  rows?: GridRow[];
  mutateRows: () => void;
}
export default function useInventoryGrid({
  rows,
  mutateRows,
}: UseInvoiceGridProps) {
  const appGrid = useAppGrid();
  const { t, tTitle, tLocalDateString, tLocalDateTimeString2 } =
    useI18nHelper();

  const disclosureUpdateInventoryModal =
    useDisclosureWithData<UpdateInventoryModalProps>();

  const withInventory = useCallback(
    (isBuyer: boolean = true): AppGridMeta => {
      const buyers: AppGridMeta = {
        rowType: "Inventory",
        columns: [
          {
            name: tTitle("updated_by"),
            width: "144px",
          },
          {
            name: tTitle("title"),
            width: "1fr",
          },
          {
            name: tTitle("organized_on"),
            width: "136px",
          },
          {
            name: tTitle("uploaded_at"),
            width: "136px",
          },
          {
            name: tTitle("comment"),
            width: "240px",
          },
        ],
        fields: [
          {
            name: "updatedBy",
            value: (row) => {
              const inventoryRow = row as InventoryRow;
              return `[${inventoryRow.organizedBy.name}] ${inventoryRow.updatedBy.name}`;
            },
          },
          {
            name: "title",
            value: (row) => {
              const inventoryRow = row as InventoryRow;
              return inventoryRow.name;
            },
          },
          {
            name: "organizedOn",
            value: (row) => {
              const inventoryRow = row as InventoryRow;
              return tLocalDateString(inventoryRow.organizedOn);
            },
          },
          {
            name: "uploadedAt",
            value: (row) => {
              const inventoryRow = row as InventoryRow;
              return tLocalDateTimeString2(inventoryRow.createdAt);
            },
          },
          {
            name: "comment",
            value: (row) => {
              const inventoryRow = row as InventoryRow;
              return inventoryRow.comment || "";
            },
          },
        ],
        actions: [
          appGrid.actions.withDownloadUnoFile<InventoryRow>(
            (row) => row.compressedFile
          ),
          (row) => {
            const inventoryRow = row as InventoryRow;
            const inventory = buyerInventoryRowsMapper.toResource(
              inventoryRow
            ) as Inventory;
            return {
              name: tTitle("edit"),
              onClick: () => {
                disclosureUpdateInventoryModal.openWithData((prev) => {
                  return {
                    source: inventory,
                    mutate: mutateRows,
                  };
                });
              },
            };
          },
        ],
        extraContents: (row: GridRow, meta: AppGridMeta) => {
          const inventoryRow = row as InventoryRow;
          return [
            <StoredObjectExtraContent
              key="stored-object-extra-content"
              storedObjects={inventoryRow.files}
            />,
          ];
        },
      };

      if (isBuyer) {
        return buyers;
      } else {
        const i = buyers.columns.findIndex(
          (c) => c.name === tTitle("organized_on")
        );
        return {
          ...buyers,
          columns: [
            {
              name: tTitle("buyer"),
              width: "136px",
            },
            ...buyers.columns,
          ],
          fields: [
            {
              name: "buyer",
              value: (row) => {
                const inventoryRow = row as InventoryRow;
                return inventoryRow.organizedBy.name;
              },
            },
            ...buyers.fields,
          ],
        };
      }
    },
    [
      appGrid.actions,
      disclosureUpdateInventoryModal,
      mutateRows,
      tLocalDateString,
      tLocalDateTimeString2,
      tTitle,
    ]
  );

  const metas = useMemo(() => {
    return {
      withInventory,
    };
  }, [withInventory]);

  return {
    metas,
    disclosureUpdateInventoryModal,
  };
}
