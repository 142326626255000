import useIdentity, { Identity } from "@/features/ui/hooks/use-identity";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Flex } from "@chakra-ui/react";
import AppNavigationLink from "@/features/ui/app-navigation/app-navigation-link";
import { useLocation } from "react-router-dom";
import type { Location } from "@remix-run/router";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { TransportationStatuses } from "@/features/transportations/transportation.type";

export interface LiteralItem {
  pathname: string;
  query?: { [key: string]: string | string[] | null | boolean };
  icon: string;
  children?: LiteralItem[];
  predicates?: ((literal: LiteralItem, location: Location) => boolean)[];
  key: string;
}

export interface Item extends Omit<LiteralItem, "children"> {
  children: Item[];
  isCollapsed: boolean;
  isActive: boolean;
}

function getLiterals(identity: Identity | null): LiteralItem[] {
  const transportations = {
    key: "common:navigation.transportations",
    pathname: "/transportations",
    icon: "/icons/icon_airplane.svg",
    children: [
      {
        key: "common:navigation.all_transportations",
        pathname: "/transportations",
        icon: "/icons/icon_airplane.svg",
        query: {
          status__in: null,
        },
        predicates: [AppUtils.isContainsLiteral],
      },
      {
        key: "common:navigation.overseas_transportations",
        pathname: "/transportations",
        icon: "/icons/icon_airplane.svg",
        query: {
          status__in: AppUtils.subList(
            TransportationStatuses,
            "WAYBILL_ISSUED",
            "FLIGHT_ARRIVED"
          ),
        },
        predicates: [AppUtils.isContainsLiteral],
      },
      {
        key: "common:navigation.customs",
        pathname: "/transportations",
        icon: "/icons/icon_airplane.svg",
        query: {
          status__in: AppUtils.subList(
            TransportationStatuses,
            "CUSTOMS_DECLARED",
            "CUSTOMS_EXPORT_DECLARED"
          ),
        },
        predicates: [AppUtils.isContainsLiteral],
      },
      {
        key: "common:navigation.domestic_transportations",
        pathname: "/transportations",
        icon: "/icons/icon_airplane.svg",
        query: {
          status__in: AppUtils.subList(
            TransportationStatuses,
            "DOMESTIC_DISPATCHING",
            "DOMESTIC_SHIPPING_COMPLETED"
          ),
        },
        predicates: [AppUtils.isContainsLiteral],
      },
      {
        key: "common:navigation.completed_transportations",
        pathname: "/transportations",
        icon: "/icons/icon_airplane.svg",
        query: {
          status__in: AppUtils.subList(
            TransportationStatuses,
            "SETTLEMENT_REQUESTED",
            "SETTLEMENT_COMPLETED"
          ),
        },
        predicates: [AppUtils.isContainsLiteral],
      },
    ],
  };

  const invoices = {
    key: "common:navigation.invoices",
    pathname: "/invoices",
    icon: "/icons/icon_featured_playlist.svg",
    children: [
      {
        key: "order_confirms",
        pathname: "/order-confirmation-invoices",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
      {
        key: "proformas",
        pathname: "/proforma-invoices",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
      {
        key: "payments",
        pathname: "/payments",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
      {
        key: "bank_receipts",
        pathname: "/bank-remittance-receipts",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
      {
        key: "deposit_invoices",
        pathname: "/deposit-invoices",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
      {
        key: "credits",
        pathname: "/credit-invoices",
        icon: "/icons/icon_featured_playlist.svg",
        predicates: [
          AppUtils.isContainsLiteral,
          AppUtils.isLiteralEditOrUpload,
        ],
      },
    ],
  };

  const depositStatus = {
    key: "deposit_status",
    pathname: "/deposit-status",
    icon: "/icons/icon_wallet.svg",
  };

  const inventories = {
    key: "inventories",
    pathname: "/inventories",
    icon: "/icons/icon_layer.svg",
  };

  if (identity?.company?.type === "BUYER") {
    return [
      {
        key: "dashboard",
        pathname: "/dashboard",
        icon: "/icons/icon_chart.svg",
      },
      {
        key: "navigation.buyer.order",
        pathname: "/line-sheets",
        icon: "/icons/icon_copy.svg",
        children: [
          {
            key: "common:pre_order",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              status__in: null,
              type__eq: "SEASON",
            },
          },
          {
            key: "common:stock",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              status__in: null,
              type__eq: "STOCK",
            },
          },
        ],
      },
      {
        key: "navigation.buyer.my_order",
        pathname: "/order-sheets",
        icon: "/icons/icon_copy.svg",
        children: [
          {
            key: "common:navigation.order_list",
            pathname: "/order-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              status__in: [
                "WORKING_DRAFT",
                "ORDERED_TO_AGENCY",
                "AGENCY_CONFIRMED",
                "ORDERED_TO_BOUTIQUE",
                "RESUBMIT_REQUIRED",
                "RESUBMIT_COMPLETED",
                "MODIFICATION_REQUIRED",
                "MODIFICATION_COMPLETED",
                "BOUTIQUE_CONFIRMED",
                "COMPLETED",
              ],
            },
          },
          {
            key: "navigation.season_order_confirmation",
            pathname: "/order-confirmation-invoices",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
      {
        key: "common:proforma",
        pathname: "/invoices",
        icon: "/icons/icon_featured_playlist.svg",
        children: [
          {
            key: "common:pre_order",
            pathname: "/proforma-invoices",
            icon: "/icons/icon_featured_playlist.svg",
            query: {
              line_sheet_set_type__eq: "SEASON",
            },
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "common:stock",
            pathname: "/proforma-invoices",
            icon: "/icons/icon_featured_playlist.svg",
            query: {
              line_sheet_set_type__eq: "STOCK",
            },
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
      {
        key: "common:payment",
        pathname: "/invoices",
        icon: "/icons/icon_featured_playlist.svg",
        children: [
          {
            key: "navigation.manage_payment",
            pathname: "/payments",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "bank_receipt",
            pathname: "/bank-remittance-receipts",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
      {
        key: "common:navigation.balance_transaction",
        pathname: "/deposit-status",
        icon: "/icons/icon_wallet.svg",
        children: [
          {
            key: "navigation.deposit_invoice",
            pathname: "/deposit-invoices",
            icon: "/icons/icon_wallet.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "credit_invoice",
            pathname: "/credit-invoices",
            icon: "/icons/icon_wallet.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "navigation.balance_transaction_list",
            pathname: "/deposit-status",
            icon: "/icons/icon_wallet.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
      {
        key: "navigation.buyer.my_stock",
        pathname: "/inventories",
        icon: "/icons/icon_layer.svg",
      },
      // transportations,
    ];
  } else if (identity?.company?.type === "AGENCY") {
    return [
      {
        key: "navigation.line_sheets",
        pathname: "/line-sheets",
        icon: "/icons/icon_copy.svg",
        children: [
          {
            key: "common:navigation.all_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_published__eq: null,
              is_deleted__eq: null,
            },
          },
          {
            key: "common:navigation.not_published_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_published__eq: false,
              is_deleted__eq: false,
            },
          },
          {
            key: "common:navigation.published_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_published__eq: true,
              is_deleted__eq: false,
            },
          },
          {
            key: "common:navigation.deleted_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_deleted__eq: true,
            },
          },
        ],
      },
      {
        key: "navigation.ordered_line_sheets",
        pathname: "/order-sheets",
        icon: "/icons/icon_clipboard.svg",
        children: [
          {
            key: "navigation.all_ordered_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: [
                "ORDERED_TO_AGENCY",
                "AGENCY_CONFIRMED",
                "ORDERED_TO_BOUTIQUE",
                "RESUBMIT_REQUIRED",
                "RESUBMIT_COMPLETED",
                "CANCELED",
                "MODIFICATION_REQUIRED",
                "MODIFICATION_COMPLETED",
                "BOUTIQUE_CONFIRMED",
                "COMPLETED",
              ],
            },
          },
          {
            key: "common:navigation.ordered_to_agency_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["ORDERED_TO_AGENCY"],
            },
          },
          {
            key: "common:navigation.agency_confirmed_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["AGENCY_CONFIRMED"],
            },
          },
          {
            key: "common:navigation.ordered_to_boutique_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["ORDERED_TO_BOUTIQUE"],
            },
          },
          {
            key: "common:navigation.resubmit_required_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["RESUBMIT_REQUIRED"],
            },
          },
          {
            key: "common:navigation.resubmit_completed_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["RESUBMIT_COMPLETED"],
            },
          },
          {
            key: "common:navigation.boutique_confirmed_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["BOUTIQUE_CONFIRMED"],
            },
          },
          {
            key: "common:navigation.completed_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["COMPLETED"],
            },
          },
          {
            key: "common:navigation.canceled_line_sheets",
            pathname: "/order-sheets",
            icon: "/icons/icon_clipboard.svg",
            query: {
              status__in: ["CANCELED"],
            },
          },
        ],
      },
      {
        ...invoices,
        children: [
          ...invoices.children,
          {
            key: "triangular_trades",
            pathname: "/triangular-trades",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
      depositStatus,
      inventories,
      transportations,
      {
        key: "common:navigation.gmail",
        pathname: "/gmails",
        icon: "/icons/icon_mail.svg",
      },
    ];
  } else if (identity?.company?.type === "BOUTIQUE") {
    return [
      {
        key: "navigation.line_sheets",
        pathname: "/line-sheets",
        icon: "/icons/icon_copy.svg",
        children: [
          {
            key: "common:navigation.all_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_published__eq: null,
              is_deleted__eq: null,
            },
          },
          {
            key: "common:navigation.deleted_line_sheets",
            pathname: "/line-sheets",
            icon: "/icons/icon_copy.svg",
            query: {
              is_deleted__eq: true,
            },
          },
        ],
      },
      {
        key: "common:navigation.invoices",
        pathname: "/invoices",
        icon: "/icons/icon_featured_playlist.svg",
        children: [
          {
            key: "order_confirms",
            pathname: "/order-confirmation-invoices",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "proformas",
            pathname: "/proforma-invoices",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "bank_receipts",
            pathname: "/bank-remittance-receipts",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
          {
            key: "triangular_trades",
            pathname: "/triangular-trades",
            icon: "/icons/icon_featured_playlist.svg",
            predicates: [
              AppUtils.isContainsLiteral,
              AppUtils.isLiteralEditOrUpload,
            ],
          },
        ],
      },
    ];
  }

  return [];
}

function calc(literal: LiteralItem, location: Location, prev?: Item): Item {
  const item: Item = {
    ...literal,
    children: [],
    isActive: false,
    isCollapsed: prev ? prev.isCollapsed : true,
  };
  //
  // if (literal.pathname === "/transportations") {
  //   debugger;
  // }

  const predicates =
    literal.predicates && literal.predicates.length > 0
      ? [...literal.predicates]
      : [AppUtils.isContainsLiteral];
  const referer = AppUtils.getReferer(location);

  if (referer) {
    [...predicates].forEach((predicate) => {
      predicates.push((literal, location) => {
        return predicate(literal, referer);
      });
    });
  }

  item.isActive = predicates.some((predicate) => predicate(literal, location));

  item.children = (literal.children || []).map((literalChild) => {
    return calc(
      literalChild,
      location,
      AppUtils.findDirectChild(
        prev?.children || [],
        (i) => i.key === literalChild.key
      )
    );
  });

  const isLeaf = item.children.length === 0;
  if (!isLeaf && item.isCollapsed && item.children.some((i) => i.isActive)) {
    item.isCollapsed = false;
  }

  return item;
}

export default function AppNavigation() {
  const identity = useIdentity();
  const [items, setItems] = useState<Item[]>([]);
  const location = useLocation();

  useEffect(() => {
    setItems((prev) => {
      return getLiterals(identity).map((literal) => {
        return calc(
          literal,
          location,
          AppUtils.findDirectChild(prev, (i) => i.key === literal.key)
        );
      });
    });
  }, [identity, location]);

  const handleClick = useCallback((item: Item) => {
    if (item.children.length > 0) {
      setItems((prev) => {
        return AppUtils.updateNestedObjects(prev, [
          {
            predicate: (i) => i.key === item.key,
            updater: (i) => {
              return {
                ...i,
                isCollapsed: !i.isCollapsed,
              };
            },
          },
        ]);
      });
    }
  }, []);

  return (
    <Flex
      direction={"column"}
      gap={"12px"}
      justifyContent={"stretch"}
      paddingTop={"12px"}
    >
      {/*<AppNavigationToggle collapsed={isDense} onToggle={handleToggle} />*/}
      {items.map((item, index) => (
        <AppNavigationLink
          item={item}
          key={`AppNavigationLink_${index}`}
          onClick={handleClick}
        />
      ))}
    </Flex>
  );
}
