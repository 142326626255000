import { BankRemittanceReceiptFormFields } from "@/features/invoices/bank-remittance-receipts/upload-bank-remittance-receipt-page";
import { FormikProps } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { useBeforeUnload } from "react-router-dom";
import BinarySplitView from "@/features/ui/binary-split-view";
import { Box, Flex } from "@chakra-ui/react";
import InvoiceFileFormControl from "@/features/invoices/order-confirmation-invoices/invoice-file-form-control";
import BankRemittanceReceiptInformationFormControl from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-information-form-control";
import BankRemittanceReceiptFigureFormControl from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-figure-form-control";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import LinkedPaymentFormControl from "@/features/invoices/payment/linked-payment-form-control";
import { ListAllDensePaymentsRequest } from "@/features/invoices/payment/hooks/use-list-all-dense-payments";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import { times } from "lodash";
import { InvoiceUtils } from "@/features/invoices/invoice-utils";
import IsolatedDepositInvoicePanel from "@/features/invoices/deposit-invoices/isolated-deposit-invoice-panel";
import useIdentity from "@/features/ui/hooks/use-identity";

interface BankRemittanceReceiptFormProps {
  formik: FormikProps<BankRemittanceReceiptFormFields>;
  isEditing?: boolean;
}

export default function BankRemittanceReceiptForm({
  formik,
  isEditing = false,
}: BankRemittanceReceiptFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const { t, tTitle, tCurrencyString } = useI18nHelper();

  const identity = useIdentity();

  const listPaymentsRequest = useMemo(():
    | ListAllDensePaymentsRequest
    | undefined => {
    const sender = formik.values.sender;
    const receiver = formik.values.receiver;
    // const issuedBy = formik.values.issuedBy;
    const orderedBy = formik.values.orderedBy;
    const issuedThrough = formik.values.issuedThrough;

    if (sender && receiver && orderedBy && issuedThrough) {
      return {
        "sender.id__eq": sender.id,
        "receiver.id__eq": receiver.id,
        isRemitted__eq: false,
        status__eq: "NORMAL",
        // "issuedByCompany.id__eq": issuedBy.id,
        "issuedThroughCompany.id__eq": issuedThrough.id,
        "issuedToCompany.id__eq": orderedBy.id,
      };
    }
    return undefined;
  }, [
    // formik.values.issuedBy,
    formik.values.issuedThrough,
    formik.values.orderedBy,
    formik.values.receiver,
    formik.values.sender,
  ]);

  const negativeSequencer = useMemo(() => {
    return new NegativeSequencer();
  }, []);

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <BankRemittanceReceiptInformationFormControl
              formik={formik}
              isEditing={isEditing}
            />
            <InvoiceFileFormControl
              formik={formik}
              isEditing={isEditing}
              isDisabled={isEditing && formik.values.id !== -1}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            <BankRemittanceReceiptFigureFormControl
              formik={formik}
              isEditing={isEditing}
            />

            {identity?.company?.type !== "BOUTIQUE" && (
              <LinkedPaymentFormControl
                formik={formik}
                isEditing={isEditing}
                isDisabled={isEditing && formik.values.id !== -1}
                request={listPaymentsRequest}
                fieldName={"payments"}
                onSelect={(value) => {
                  formik.setValues((prev) => {
                    const next = {
                      ...prev,
                      payments: value,
                    };

                    const linkedPayments = next.payments.map((payment) => {
                      return {
                        ...payment,
                      };
                    });

                    next.bankRemittanceReceiptDetailList =
                      next.bankRemittanceReceiptDetailList.filter((detail) => {
                        if (detail.payment === undefined) {
                          return true;
                        } else {
                          const i = linkedPayments.findIndex(
                            (linkedPayment) =>
                              linkedPayment.id === detail.payment?.id
                          );
                          if (i > -1) {
                            linkedPayments.splice(i, 1);
                            return true;
                          }
                        }
                        return false;
                      });

                    if (
                      next.bankRemittanceReceiptDetailList.length <
                      next.payments.length
                    ) {
                      const currency = InvoiceUtils.getCurrencyByParties(next);
                      next.bankRemittanceReceiptDetailList.push(
                        ...times(
                          next.payments.length -
                            next.bankRemittanceReceiptDetailList.length
                        ).map(() => {
                          const payment = linkedPayments.splice(
                            linkedPayments.length - 1,
                            1
                          )[0];
                          return {
                            id: negativeSequencer.next(),
                            amount: {
                              currency,
                            },
                            payment: payment
                              ? { id: payment?.id, name: payment?.name }
                              : undefined,
                          };
                        })
                      );
                    } else if (
                      next.bankRemittanceReceiptDetailList.length >
                      next.payments.length
                    ) {
                      let shouldRemoveCount =
                        next.bankRemittanceReceiptDetailList.length -
                        next.payments.length;
                      next.bankRemittanceReceiptDetailList =
                        next.bankRemittanceReceiptDetailList.filter(
                          (detail) => {
                            if (
                              shouldRemoveCount > 0 &&
                              detail.payment === undefined
                            ) {
                              shouldRemoveCount--;
                              return false;
                            }
                            return true;
                          }
                        );
                    }

                    return next;
                  });
                }}
              />
            )}

            {!isEditing && identity?.company?.type !== "BOUTIQUE" && (
              <IsolatedDepositInvoicePanel
                depositInvoiceIds={
                  formik.values.depositInvoice
                    ? [formik.values.depositInvoice.id]
                    : undefined
                }
              />
            )}
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={400}
      />
    </Box>
  );
}
