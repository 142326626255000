import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import * as Yup from "yup";
import { CreditInvoiceType } from "@/features/invoices/credit-invoices/credit-invoice.type";
import { Company, LightCompany, StoredObject } from "@/features/types";

export default function useCreditInvoiceFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const { withMessage } = useYupHelper({
    ns: "credit_invoices",
    prefix: "upload_credit_invoices_form",
  });

  return useMemo(() => {
    return Yup.object({
      season: Yup.string().required(withMessage.required),
      emailThreadMessageId: Yup.string().test(
        "requiredIfNotSendAsNewMail",
        t(
          "credit_invoices:upload_credit_invoices_form.messages.error.empty_email_message_thread_id_if_do_not_send_as_new_email"
        ),
        (value, context) => {
          const { sendAsNewEmail } = context.parent;

          if (!sendAsNewEmail) {
            return (value || "").trim().length > 0;
          }
          return true;
        }
      ),
      type: Yup.mixed<CreditInvoiceType>()
        .required()
        .test(
          "neitherOrderConfirmationNorEarningProformaInvoices",
          t(
            "credit_invoices:upload_credit_invoices_form.messages.error.neither_order_confirm_invoice_nor_proforma_invoice_if_type_is_season"
          ),
          (value, context) => {
            const { orderConfirmationInvoices, earningProformaInvoices } =
              context.parent;
            const isFixing = value === "FIXING";
            const ocLength = (orderConfirmationInvoices || []).length;
            const epLength = (earningProformaInvoices || []).length;
            if (!isFixing) {
              return ocLength === 0 && epLength === 0;
            }
            return true;
          }
        )
        .test(
          "eitherOrderConfirmationOrEarningProformaInvoices",
          t(
            "credit_invoices:upload_credit_invoices_form.messages.error.either_order_confirm_invoice_or_proforma_invoice_if_type_is_fixing"
          ),
          (value, context) => {
            const { orderConfirmationInvoices, earningProformaInvoices } =
              context.parent;
            const isFixing = value === "FIXING";
            const ocLength = (orderConfirmationInvoices || []).length;
            const epLength = (earningProformaInvoices || []).length;

            if (isFixing) {
              return (
                (ocLength > 0 && epLength === 0) ||
                (ocLength === 0 && epLength > 0)
              );
            }
            return true;
          }
        ),
      orderedBy: Yup.mixed<LightCompany>().required(withMessage.required),
      issuedBy: Yup.mixed<LightCompany>().required(withMessage.required),
      issuedThrough: Yup.mixed<LightCompany>().required(withMessage.required),
      issuedOn: Yup.date().required(withMessage.required),
      orderConfirmationInvoices: Yup.array().of(
        Yup.object<{
          id: number;
          name: string;
          totalRequiredAmount: number;
        }>({
          id: Yup.number().positive().required(),
          name: Yup.string().required(),
        })
      ),
      earningProformaInvoices: Yup.array().of(
        Yup.object<{ id: number; name: string; totalRequiredAmount: number }>({
          id: Yup.number().positive().required(),
          name: Yup.string().required(),
        })
      ),
      file: Yup.mixed<File | Pick<StoredObject, "id" | "name">>().required(
        withMessage.required
      ),
      amount: Yup.object({
        value: Yup.number()
          .positive(withMessage.positive)
          .required(withMessage.required),
        currency: Yup.string().required(withMessage.required),
      }).required(withMessage.required),
    });
  }, [withMessage, t]);
}
