import { SWRResponse } from "swr";
import { useMemo, useRef } from "react";
import { use } from "i18next";

function concatenateData<Data>(
  acc: Data | undefined,
  cur: Data | undefined
): Data | undefined {
  if (acc === undefined && cur === undefined) {
    return undefined;
  }

  if (acc === undefined) {
    return cur;
  } else if (cur === undefined) {
    return acc;
  } else if (Array.isArray(acc) && Array.isArray(cur)) {
    return [...acc, ...cur] as Data;
  }
  return undefined;
}

//varagrs로 합치면 dependency가 동적이라 memo를 못함;
export default function useBiSWRResponse<Data, Error, SWROptions>(
  response1: SWRResponse<Data, Error, SWROptions>,
  response2: SWRResponse<Data, Error, SWROptions>,
  func: (data1: Data | undefined, data2: Data | undefined) => Data | undefined
) {
  const initialFunc = useRef(func);

  const datList = useMemo(() => {
    return response1.data || response2.data
      ? initialFunc.current(response1.data, response2.data)
      : undefined;
  }, [response1.data, response2.data]);

  const isLoading = response1.isLoading || response2.isLoading;

  const isValidating = response1.isValidating || response2.isValidating;

  const error = response1.error || response2.error;

  const mutate = useMemo(() => {
    const mutate1 = response1.mutate;
    const mutate2 = response2.mutate;
    return async () => {
      await mutate1();
      await mutate2();
    };
  }, [response1.mutate, response2.mutate]);

  return {
    data: datList,
    error,
    isValidating,
    isLoading,
    mutate,
  };
}
