import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import * as Yup from "yup";
import { Company, StoredObject } from "@/features/types";

export default function useDepositInvoiceFormValidationSchema() {
  const { withMessage } = useYupHelper({
    ns: "deposit_invoices",
    prefix: "upload_deposit_invoices_form",
  });

  return useMemo(() => {
    return Yup.object({
      name: Yup.string().required(withMessage.required),
      number: Yup.string()
        .label("invoice_number")
        .required(withMessage.required),
      season: Yup.string().required(withMessage.required),
      orderedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedThrough: Yup.mixed<Company>().required(withMessage.required),
      issuedOn: Yup.date().required(withMessage.required),
      file: Yup.mixed<File | Pick<StoredObject, "id" | "name">>()
        .label("invoice_file")
        .required(withMessage.required),
      amount: Yup.object({
        value: Yup.number()
          .label("remitted_amount")
          .positive(withMessage.positive)
          .required(withMessage.required),
        currency: Yup.string().required(withMessage.required),
      }),
      bankRemittanceReceipt: Yup.object<{
        id: number;
        name: string;
        amount: number;
      }>({
        // id: Yup.number().positive().required(),
        // name: Yup.string().required(),
      })
        .label("related_bank_remittance_receipt")
        .required(withMessage.required),
    });
  }, [withMessage]);
}
