import useFetcher from "@/utils/use-fetcher";
import {
  getListCreditInvoicesKey,
  ListCreditInvoicesRequest,
  ListCreditInvoicesResponse,
} from "@/features/invoices/credit-invoices/hooks/use-list-credit-invoices";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDenseCreditInvoicesRequest,
  ListDenseCreditInvoicesResponse,
  parseListDenseCreditInvoicesKey,
} from "@/features/invoices/credit-invoices/hooks/use-list-dense-credit-invoices";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-condense-proforma-invoices";

export default function useListDenseCreditInvoicesFetcher() {
  const listCreditInvoicesFetcher = useFetcher<
    ListCreditInvoicesRequest,
    ListCreditInvoicesResponse
  >();

  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();

  const condenseProformaInvoices = useCondenseProformaInvoices();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseCreditInvoicesRequest>
    ): Promise<ListDenseCreditInvoicesResponse> => {
      const { parameter, request } = parseListDenseCreditInvoicesKey(config);
      const listCreditInvoicesResponse = await listCreditInvoicesFetcher(
        getListCreditInvoicesKey(parameter, request)
      );

      if (listCreditInvoicesResponse.creditInvoices.length > 0) {
        const promise1 = condenseOrderConfirmationInvoices(
          listCreditInvoicesResponse.creditInvoices,
          (item) => item.orderConfirmationInvoices,
          (item, value) => {
            if (Array.isArray(value)) {
              item.orderConfirmationInvoices = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        const promise2 = condenseProformaInvoices(
          listCreditInvoicesResponse.creditInvoices,
          (item) => item.earningProformaInvoices,
          (item, value) => {
            if (Array.isArray(value)) {
              item.earningProformaInvoices = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        const promise3 = condenseProformaInvoices(
          listCreditInvoicesResponse.creditInvoices,
          (item) => item.usingProformaInvoices,
          (item, value) => {
            if (Array.isArray(value)) {
              item.usingProformaInvoices = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );
        await Promise.all([promise1, promise2, promise3]);
      }

      // @ts-ignore
      return Promise.resolve(listCreditInvoicesResponse);
    };
  }, [
    listCreditInvoicesFetcher,
    condenseOrderConfirmationInvoices,
    condenseProformaInvoices,
  ]);
}
