import { useNavigate, useParams } from "react-router";
import { useEffect, useRef } from "react";
import { CompanyType } from "@/features/types";
import { getIdentity } from "@/features/ui/helpers/identity-helpers";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

export interface Identity {
  id: number;
  company: {
    id: number;
    type: CompanyType;
  } | null;
}

export default function useIdentity() {
  const { companyId: rawCompanyId } = useParams();
  const identityRef = useRef<Identity | null>(getIdentity(rawCompanyId));
  const navigate = useNavigate();
  const { warning: showWarning } = useAppToasts();
  const identity = getIdentity(rawCompanyId);

  useEffect(() => {
    if (rawCompanyId && identity === null) {
      // showWarning(
      //   "You've been signed out because your account was signed out elsewhere. Please sign in again to proceed.",
      //   "RedirectToSignIn"
      // );

      navigate("/auth/sign-in", { replace: true });
    }
  }, [rawCompanyId, identity, navigate, showWarning]);

  if (identityRef.current?.id === identity?.id) {
    return identityRef.current;
  } else {
    identityRef.current = identity;
    return identity;
  }
}
