import SVG from "react-inlinesvg";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback, useContext } from "react";
import { Box, Flex, Grid } from "@chakra-ui/react";
import { Brand } from "@/features/types";
import { produce } from "immer";
import { toTitle } from "@/utils/case";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import OrderConfirmationInvoiceDetailInput from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-input";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import {
  DetailBrandContext,
  DetailBrandContextType,
} from "@/features/invoices/proforma-invoices/detail-brand-provider";

interface OrderConfirmationInvoiceDetailsInputProps {
  value: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"];
  onChange: (
    value: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"]
  ) => void;
  negativeSequencer: NegativeSequencer;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export default function OrderConfirmationInvoiceDetailsInput({
  value,
  onChange,
  negativeSequencer,
  isDisabled = false,
  isReadOnly = false,
}: OrderConfirmationInvoiceDetailsInputProps) {
  const { t } = useI18nHelper();

  const handleAddBrandClick = useCallback(() => {
    onChange([
      ...value,
      {
        id: negativeSequencer.next(),
        amount: {
          currency: "EUR",
        },
      },
    ]);
  }, [value, negativeSequencer, onChange]);

  const { items } = useContext<DetailBrandContextType>(DetailBrandContext);

  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      <Grid
        gridTemplateColumns={"repeat(3, 1fr) 12px"}
        gridColumnGap={"8px"}
        gridRowGap={"12px"}
        alignItems={"end"}
      >
        {value.map((detail, index) => {
          return (
            <OrderConfirmationInvoiceDetailInput
              key={`OrderConfirmationInvoiceDetailInput_${index}`}
              value={detail}
              isReadOnly={isReadOnly}
              isDisabled={isDisabled}
              onChange={(detail) => {
                onChange(
                  produce(value, (draft) => {
                    if (detail) {
                      draft[index] = detail;
                    } else {
                      draft.splice(index, 1);
                    }
                  })
                );
              }}
              index={index}
            />
          );
        })}
        {!isReadOnly && (
          <Box gridColumn={"1/4"}>
            <AppButton
              width={"100%"}
              onClick={handleAddBrandClick}
              isDisabled={isDisabled || (items || []).length <= value.length}
            >
              <SVG
                src={"/icons/icon_add.svg"}
                width={"14px"}
                height={"14px"}
                style={{ marginRight: "8px" }}
              />
              {toTitle(t("add_brand"))}
            </AppButton>
          </Box>
        )}
      </Grid>
    </Flex>
  );
}
