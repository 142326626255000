import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import UploadInventoryModal from "@/features/inventories/upload-inventory-modal";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo } from "react";
import useListInventories, {
  ListInventoriesPathParameter,
  ListInventoriesRequest,
} from "@/features/inventories/hooks/use-list-inventories";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useAgencyInventoryRows from "@/features/inventories/hooks/use-buyer-inventory-rows";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { toTitle } from "@/utils/case";
import AgencyInventoryGrid from "@/features/inventories/agency-inventory-grid";
import AppDateRangePicker from "@/features/ui/app-date-range-picker";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";

export interface PersistedInventoryListPageQuery
  extends Partial<ListInventoriesRequest> {}

export default function AgencyInventoryListPage() {
  const { t, tTitle } = useI18nHelper();
  const [query, setQuery] =
    useTypedSearchParams<PersistedInventoryListPageQuery>();
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "AgencyInventoryListPage" });
  const { setRows } = useAgencyInventoryRows();
  const listInventoriesKey = useMemo(():
    | {
        parameter: ListInventoriesPathParameter;
        request: ListInventoriesRequest;
      }
    | undefined => {
    if (identity?.company?.type === "AGENCY") {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
        },
      };
    }
    return undefined;
  }, [identity, query]);

  const {
    data: listInventoriesData,
    error: listInventoriesError,
    mutate: mutateListInventories,
  } = useListInventories(
    listInventoriesKey?.parameter,
    listInventoriesKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    setRows(listInventoriesData?.inventories);
  }, [listInventoriesData, setRows]);

  useEffect(() => {
    return () => {
      mutateListInventories((prev) => prev, false);
    };
  }, [mutateListInventories]);

  useEffect(() => {
    if (listInventoriesError) {
      showError(listInventoriesError);
    }
  }, [showError, listInventoriesError]);

  const {
    isOpen: isUploadInventoryModalOpen,
    onClose: onUploadInventoryModalClose,
    onOpen: onUploadInventoryModalOpen,
  } = useDisclosure();

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppCompanySelect
              key={"AgencyInventoryListFilter__OrganizedBy"}
              name={tTitle("organized_by")}
              types={["BUYER"]}
              id={query["organizedBy.id__eq"]}
              onSelect={(company) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "organizedBy.id__eq": company?.id,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppDateRangePicker
              key={"AgencyInventoryListFilter__OrganizedOn"}
              width={"144px"}
              names={[tTitle("organized_from"), tTitle("organized_to")]}
              value={{
                from: query["organizedOn__gte"],
                to: query["organizedOn__lte"],
              }}
              onChange={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    organizedOn__gte: value?.from,
                    organizedOn__lte: value?.to,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppDateRangePicker
              key={"AgencyInventoryListFilter__CreatedOn"}
              width={"144px"}
              names={[tTitle("uploaded_from"), tTitle("uploaded_to")]}
              value={{
                from: query["createdAt__gte"],
                to: (() => {
                  if (query["createdAt__lt"]) {
                    return new Date(
                      query["createdAt__lt"].getTime() - 1000 * 60 * 60 * 24
                    );
                  }
                })(),
              }}
              onChange={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    createdAt__gte: value?.from,
                    createdAt__lt: (() => {
                      if (value?.to) {
                        return new Date(
                          value?.to.getTime() + 1000 * 60 * 60 * 24
                        );
                      }
                    })(),
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"AgencyInventoryListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <AppButton
              key={"AgencyInventoryListAction__UploadInventory"}
              variant={"primary"}
              onClick={onUploadInventoryModalOpen}
            >
              {tTitle("upload_inventory")}
            </AppButton>,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <AgencyInventoryGrid mutateRows={mutateListInventories} />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
            totalCount={listInventoriesData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              })
            }
          />
        </Flex>
      </Flex>
      {isUploadInventoryModalOpen && (
        <UploadInventoryModal
          isOpen={isUploadInventoryModalOpen}
          onClose={onUploadInventoryModalClose}
        />
      )}
    </>
  );
}
