import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import {
  legacyCreateLineSheetSetKey,
  LegacyCreateLineSheetSetPathParameter,
  LegacyCreateLineSheetSetRequest,
  LegacyCreateLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-legacy-create-line-sheet-set";
import { mutate } from "swr";
import { AxiosRequestConfig, Method } from "axios";
import { useCallback } from "react";
import {
  updateFallbackOrderSheetKey,
  UpdateFallbackOrderSheetPathParameter,
  UpdateFallbackOrderSheetRequest,
  UpdateFallbackOrderSheetResponse,
} from "@/features/line-sheet-sets/hooks/use-update-fallback-order-sheet";
import {
  createFallbackOrderSheetSeamlesslyKey,
  CreateFallbackOrderSheetSeamlesslyParameter,
  CreateFallbackOrderSheetSeamlesslyRequest,
  CreateFallbackOrderSheetSeamlesslyResponse,
} from "@/features/line-sheet-sets/hooks/use-create-fallback-order-sheet-seamlessly";
import useCreateFallbackOrderSheetSeamlesslyFetcher from "@/features/line-sheet-sets/hooks/use-create-fallback-order-sheet-seamlessly-fetcher";
import {
  updateLineSheetSetKey,
  UpdateLineSheetSetPathParameter,
  UpdateLineSheetSetRequest,
  UpdateLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set";
import { StoredObject } from "@/features/types";

export interface DumbRequest {
  _v: number;
}

export interface DumbPathParameter {
  url: string;
}

export interface DumbTransactionState<REQ = any, RES = any> {
  url: string;
  method: Method;
  request: REQ;
  response?: RES;
  isLoading: boolean;
  error?: any;
  progress?: number;
  files: { file: File; type?: string }[];
  postProcess: (response: RES) => void;
  fetcher?: (config: AxiosRequestConfig<REQ>) => Promise<RES>;
}

export function isCompleted(tx: DumbTransactionState) {
  return !tx.isLoading && (tx.error !== undefined || tx.response !== undefined);
}

export function isIdle(tx: DumbTransactionState) {
  return !tx.isLoading && tx.error === undefined && tx.response === undefined;
}

export const dumbTransactionsState = atom<DumbTransactionState[]>({
  key: "dumbTransactionsState",
  default: [],
});

function mutateIfMatched(regexp: RegExp) {
  mutate((key) => {
    if (key !== null && typeof key === "object" && "url" in key) {
      const result = key.url.match(regexp);
      return result != null;
    }
    return false;
  });
}

export function useDumbTransactions() {
  const addUpdateLineSheetSet = useRecoilCallback(
    ({ set }) =>
      (
        parameter: UpdateLineSheetSetPathParameter,
        request: UpdateLineSheetSetRequest
      ) => {
        set(dumbTransactionsState, (prev) => {
          function isFile(
            file: File | Pick<StoredObject, "id" | "name">
          ): file is File {
            return file instanceof File;
          }

          return [
            ...prev,
            {
              url: calcYWRUrl(updateLineSheetSetKey, parameter),
              method: updateLineSheetSetKey.method,
              request,
              isLoading: false,
              files: (() => {
                return [
                  ...request.lineSheetFiles.filter(isFile).map((file) => ({
                    file,
                    type: "LINE SHEET",
                  })),
                  ...request.attachments.filter(isFile).map((file) => ({
                    file,
                    type: `ATTACHMENT/${(
                      file.name.split(".").pop() || ""
                    ).toUpperCase()}`,
                  })),
                  ...request.orderFormFiles.filter(isFile).map((file) => ({
                    file,
                    type: "ORDER FORM",
                  })),
                ];
              })(),
              postProcess: (response: UpdateLineSheetSetResponse) => {
                mutateIfMatched(/\/(agencies|buyers)\/\d+\/line-sheet-sets/);
              },
            },
          ];
        });
      },
    []
  );

  const addCreateLineSheetSet = useRecoilCallback(
    ({ set }) =>
      (
        parameter: LegacyCreateLineSheetSetPathParameter,
        request: LegacyCreateLineSheetSetRequest
      ) => {
        function getFileExtension(file: File) {
          const name = file.name;
          const lastDotIndex = name.lastIndexOf(".");
          if (lastDotIndex === -1) {
            return undefined;
          }
          return name.substring(lastDotIndex + 1).toUpperCase();
        }

        function filesGetter(request: LegacyCreateLineSheetSetRequest) {
          return [
            ...request.lineSheetFiles.map((file) => ({
              file,
              type: "LINE SHEET",
            })),
            ...request.attachments.map((file) => ({
              file,
              type: `ATTACHMENT/${getFileExtension(file)}`,
            })),
            ...request.orderFormFiles.map((file) => ({
              file,
              type: "ORDER FORM",
            })),
          ];
        }

        function postProcess(response: LegacyCreateLineSheetSetResponse) {
          mutateIfMatched(/\/(agencies|buyers)\/\d+\/line-sheet-sets/);
        }

        const url = calcYWRUrl(legacyCreateLineSheetSetKey, parameter);
        const method = legacyCreateLineSheetSetKey.method;
        set(dumbTransactionsState, (prev) => {
          return [
            ...prev,
            {
              url,
              method,
              request,
              isLoading: false,
              files: filesGetter(request),
              postProcess,
            },
          ];
        });
      },
    []
  );

  const createFallbackOrderSheetSeamlesslyFetcher =
    useCreateFallbackOrderSheetSeamlesslyFetcher();

  const addCreateFallbackOrderSheetsSeamlessly = useRecoilCallback(
    ({ set }) =>
      (
        tuples: [
          CreateFallbackOrderSheetSeamlesslyParameter,
          CreateFallbackOrderSheetSeamlesslyRequest
        ][]
      ) => {
        const transactions = tuples.map(([parameter, request]) => {
          return {
            url: calcYWRUrl(createFallbackOrderSheetSeamlesslyKey, parameter),
            method: createFallbackOrderSheetSeamlesslyKey.method,
            request,
            isLoading: false,
            files: [{ file: request.file }],
            fetcher: createFallbackOrderSheetSeamlesslyFetcher,
            postProcess: (
              response: CreateFallbackOrderSheetSeamlesslyResponse
            ) => {
              mutateIfMatched(
                /\/(agencies|buyers)\/\d+\/(order-sheet-sets|line-sheet-sets|dense-line-sheet-sets|dense-order-sheet-sets)/
              );
            },
          };
        });

        set(dumbTransactionsState, (prev) => {
          return [...prev, ...transactions];
        });
      },
    [createFallbackOrderSheetSeamlesslyFetcher]
  );

  // const mutateOrderSheetRowByUpdateFallbackOrderSheet = useRecoilCallback(
  //   ({ set }) =>
  //     (orderSheetId: number, response: UpdateFallbackOrderSheetResponse) => {
  //       function updater<T extends GridRow>(prev: T[]): T[] {
  //         return produce(prev, (draft) => {
  //           const path = findPathOfGridRow(prev, "OrderSheet", orderSheetId);
  //           if (path) {
  //             let cursor: WritableDraft<GridRow[]> = draft;
  //             for (let i = 0; i < path.length - 1; i++) {
  //               cursor = cursor[path[i]].children;
  //             }
  //             //이런식으로 접근하면 변경이 안됨;
  //             //const target = cursor[path[path.length - 1]];
  //             const partial: Partial<BuyerOrderSheetRow> = {
  //               uploadedFile: response,
  //               status: "WORKING_DRAFT",
  //             };
  //             cursor[path[path.length - 1]] = {
  //               ...cursor[path[path.length - 1]],
  //               ...partial,
  //             };
  //           }
  //         });
  //       }
  //
  //       set(buyerOrderSheetSetRowsState, (prev) => updater(prev));
  //       set(buyerLineSheetSetRowsState, (prev) => updater(prev));
  //     },
  //   []
  // );

  const addUpdateFallbackOrderSheet = useRecoilCallback(
    ({ set }) =>
      (
        parameter: UpdateFallbackOrderSheetPathParameter,
        request: UpdateFallbackOrderSheetRequest
      ) => {
        set(dumbTransactionsState, (prev) => {
          return [
            ...prev,
            {
              url: calcYWRUrl(updateFallbackOrderSheetKey, parameter),
              method: updateFallbackOrderSheetKey.method,
              request,
              isLoading: false,
              files: [{ file: request.uploadedFile }],
              postProcess: (response: UpdateFallbackOrderSheetResponse) => {
                //todo orderSheetSet은 blueprint적용이 안되있어서 변경하기가 힘들어서 일단 전체 페이지 mutate로 작동하도록 함
                mutateIfMatched(
                  /\/?(agencies|buyers)\/\d+\/(order-sheet-sets|line-sheet-sets|line-sheet-set-pairs|order-sheet-set-pairs|dense-order-sheet-sets|dense-line-sheet-sets)/
                );
                // mutateOrderSheetRowByUpdateFallbackOrderSheet(
                //   parameter.orderSheetId,
                //   response
                // );
              },
            },
          ];
        });
      },
    []
  );

  const transactions = useRecoilValue(dumbTransactionsState);

  const findCreateFallbackOrderSheetSeamlesslyTransaction = useCallback(
    (parameter: CreateFallbackOrderSheetSeamlesslyParameter) => {
      return transactions.find((tx) => {
        const key = calcYWRUrl(
          createFallbackOrderSheetSeamlesslyKey,
          parameter
        );
        return tx.url === key;
      });
    },
    [transactions]
  );

  const findUpdateFallbackOrderSheetTransaction = useCallback(
    (parameter: UpdateFallbackOrderSheetPathParameter) => {
      return transactions.find((tx) => {
        const key = calcYWRUrl(updateFallbackOrderSheetKey, parameter);
        return tx.url === key;
      });
    },
    [transactions]
  );

  const filterUpdateFallbackOrderSheetTransactionByOrderSheetId = useCallback(
    (orderSheetId: number) => {
      return transactions.filter((tx) => {
        const regexp = new RegExp(
          `/(agencies|buyers)/\\d+/order-sheet-sets/\\d+/order-sheets/${orderSheetId}/uploaded-file`
        );
        return tx.url.match(regexp) != null;
      });
    },
    [transactions]
  );

  const findUpdateLineSheetSetTransaction = useCallback(
    (parameter: UpdateLineSheetSetPathParameter) => {
      return transactions.find((tx) => {
        const key = calcYWRUrl(updateLineSheetSetKey, parameter);
        return tx.url === key;
      });
    },
    [transactions]
  );

  return {
    addCreateLineSheetSet,
    addUpdateLineSheetSet,
    findUpdateLineSheetSetTransaction,
    addCreateFallbackOrderSheetsSeamlessly,
    findCreateFallbackOrderSheetSeamlesslyTransaction,
    findUpdateFallbackOrderSheetTransaction,
    addUpdateFallbackOrderSheet,
    filterUpdateFallbackOrderSheetTransactionByOrderSheetId,
  };
}
