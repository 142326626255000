import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useEffect, useMemo, useState } from "react";
import useListDenseDepositInvoices, {
  ListDenseDepositInvoicesPathParameter,
  ListDenseDepositInvoicesRequest,
} from "@/features/invoices/deposit-invoices/hooks/use-list-dense-deposit-invoices";
import { depositInvoiceRowMapper } from "@/features/invoices/utils/deposit-invoice-row-mapper";
import { DepositInvoiceRow } from "@/features/invoices/deposit-invoices/deposit-invoice-row.type";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import IsolatedPanelSkeleton from "@/features/invoices/isolated-panel-skeleton";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import AppGrid, {
  AppGridColumn,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";

interface IsolatedDepositInvoicePanelProps {
  depositInvoiceIds?: number[];
}

export default function IsolatedDepositInvoicePanel({
  depositInvoiceIds,
}: IsolatedDepositInvoicePanelProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle, tCurrencyString } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const key = useMemo(():
    | {
        parameter: ListDenseDepositInvoicesPathParameter;
        request: ListDenseDepositInvoicesRequest;
      }
    | undefined => {
    if (
      identity?.company?.type &&
      depositInvoiceIds &&
      depositInvoiceIds.length > 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: depositInvoiceIds,
          pageNumber: 1,
          pageSize: depositInvoiceIds.length,
        },
      };
    }
    return undefined;
  }, [depositInvoiceIds, identity]);

  const {
    data: resources,
    error: listResourcesError,
    mutate: mutateListResources,
  } = useListDenseDepositInvoices(key?.parameter, key?.request);

  useEffect(() => {
    if (listResourcesError) {
      showError(listResourcesError);
    }
  }, [listResourcesError, showError]);

  const rowMapper = useMemo(() => {
    return depositInvoiceRowMapper.copy();
  }, []);

  const [rows, setRows] = useState<DepositInvoiceRow[]>();

  useEffect(() => {
    if (resources) {
      setRows((prev) => {
        return rowMapper.toRows(
          resources.depositInvoices.map((dInvoice) => {
            return {
              ...dInvoice,
              isRead: false,
            };
          }),
          prev
        );
      });
    }
  }, [resources, rowMapper]);

  const appGrid = useAppGrid();
  const invoiceGrid = useInvoiceGrid({ rows, mutateRows: mutateListResources });

  const metas = useMemo((): AppGridMeta[] => {
    let pairs: { column: AppGridColumn; field: AppGridField }[] = [];

    if (identity?.company?.type === "BUYER") {
      pairs = [
        appGrid.pairs.season,
        invoiceGrid.pairs.issuedBy,
        invoiceGrid.pairs.issuedThrough,
        // invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "AGENCY") {
      pairs = [
        appGrid.pairs.season,
        invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "BOUTIQUE") {
      pairs = [
        appGrid.pairs.season,
        // invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    }

    return [
      {
        rowType: "DepositInvoice",
        gap: "8px",
        columns: pairs.map((pair) => pair.column),
        fields: pairs.map((pair) => pair.field),
        indicator: (row: GridRow) => {
          return (
            <GridRowIndicator
              type={"VIEW"}
              isCollapsed={row.isCollapsed}
              isHovered={row.isHovered}
            />
          );
        },
        onClick: (row: GridRow) => {
          navigate(`/deposit-invoices/${row.id}`);
        },
      },
    ];
  }, [
    appGrid.pairs.season,
    appGrid.pairs.title,
    identity?.company?.type,
    invoiceGrid.pairs.amount,
    invoiceGrid.pairs.issuedBy,
    invoiceGrid.pairs.issuedOn,
    invoiceGrid.pairs.issuedThrough,
    invoiceGrid.pairs.orderedBy,
    navigate,
  ]);

  if (key === undefined) {
    return null;
  } else if (rows === undefined) {
    return <IsolatedPanelSkeleton />;
  }

  return (
    <AppPanel title={tTitle("related_deposit_invoices")} variant={"ghost"}>
      <AppGrid isAnchored={false} rows={rows} metas={metas} />
    </AppPanel>
  );
}
