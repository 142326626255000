import { Boutique, Buyer, CompanyType, StoredObject } from "@/features/types";
import {
  Transportation,
  TransportationDetail,
  TransportationStatus,
} from "@/features/transportations/transportation.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { DateTimeString } from "@/features/ui/utils/date-utils";

export interface CreateTransportationRequest {
  name: string;
  shippedFromList: { id: number }[];
  shippedTo: { id: number };
  shippedToAddress: string;
  forwarderInvoiceNumbers: string[];

  houseBlNumbers: string[];

  flightNumber: string;
  flightDepartureAirport: string;
  flightEtd: DateTimeString;
  flightAtd: DateTimeString | null;
  flightEta: DateTimeString;
  flightAta: DateTimeString | null;
  flightArrivalAirport: string;
  finalEta: Date;
  cost: number;
  files: StoredObject[];

  proformaInvoices: { id: number }[];
}

export interface CreateTransportationResponse extends Transportation {}

export interface CreateTransportationPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createTransportationTemplateUrl = `/:by/:companyId/transportations`;

export const createTransportationKey: YWRKey<CreateTransportationPathParameter> =
  {
    url: (parameters: CreateTransportationPathParameter) => {
      return calcUrl(createTransportationTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateTransportation() {
  const fetcher = useFetcher<
    CreateTransportationRequest,
    CreateTransportationResponse
  >();

  return useYWR(createTransportationKey, fetcher);
}
