import { PageNumberBasedListResponse } from "@/features/types";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface Airport {
  name: string;
  iataCode: string;
  timeZone: string;
  country: string;
  offset: string;
}

interface ListAirportsParameter {}

interface ListAirportsRequest {}

interface ListAirportsResponse extends PageNumberBasedListResponse {
  airports: Airport[];
}

export function getListAirportsKey(
  parameter?: ListAirportsParameter,
  request?: ListAirportsRequest
) {
  if (parameter && request) {
    return {
      url: "/airports",
      params: request,
    };
  }

  return null;
}

export default function useListAirports(
  parameter?: ListAirportsParameter,
  request?: ListAirportsRequest,
  config?: SWRConfiguration<ListAirportsResponse>
) {
  const fetcher = useFetcher<ListAirportsRequest, ListAirportsResponse>();
  return useSWR(getListAirportsKey(parameter, request), fetcher, config);
}
