import useFetcher from "@/utils/use-fetcher";
import {
  getGetPaymentKey,
  GetPaymentRequest,
  GetPaymentResponse,
} from "@/features/invoices/hooks/use-get-payment";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-condense-proforma-invoices";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDensePaymentRequest,
  GetDensePaymentResponse,
  parseGetDensePaymentKey,
} from "@/features/invoices/hooks/use-get-dense-payment";

export default function useGetDensePaymentFetcher() {
  const getPaymentFetcher = useFetcher<GetPaymentRequest, GetPaymentResponse>();

  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();
  const condenseProformaInvoices = useCondenseProformaInvoices();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDensePaymentRequest>
    ): Promise<GetDensePaymentResponse> => {
      const { parameter, request } = parseGetDensePaymentKey(config);
      const getPaymentKey = getGetPaymentKey(parameter, request);

      if (!getPaymentKey) {
        throw {
          code: 404,
          message: "Not Found",
        };
      }

      const getPaymentResponse = await getPaymentFetcher(getPaymentKey);

      if (getPaymentResponse) {
        const promises = [
          condenseOrderConfirmationInvoices(
            [getPaymentResponse],
            (item) => {
              return item.orderConfirmationInvoices;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.orderConfirmationInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          condenseProformaInvoices(
            [getPaymentResponse],
            (item) => {
              return item.proformaInvoices;
            },
            (item, value) => {
              if (Array.isArray(value)) {
                item.proformaInvoices = value;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
        ];

        await Promise.all(promises);
      }

      // @ts-ignore
      return Promise.resolve(getPaymentResponse);
    };
  }, [
    condenseOrderConfirmationInvoices,
    condenseProformaInvoices,
    getPaymentFetcher,
  ]);
}
