import { DensePayment } from "@/features/invoices/payment/payment.type";
import useListAllDensePayments, {
  ListAllDensePaymentsRequest,
} from "@/features/invoices/payment/hooks/use-list-all-dense-payments";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useEffect, useMemo } from "react";
import useListAllDenseProformaInvoices from "@/features/invoices/proforma-invoices/hooks/use-list-all-dense-proforma-invoices-fetcher";
import useBiSWRResponse from "@/features/invoices/deposit-invoices/use-bi-swr-response";
import { uniqBy } from "lodash";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import { DenseProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

interface AppPaymentsExclusivePaymentSelectProps {
  request?: ListAllDensePaymentsRequest;
  onSelect: (value: DensePayment[]) => void;
  isDisabled?: boolean;
  id?: number | number[];
  nameFunc?: (payment: DensePayment) => string;
}

export default function AppPaymentsExclusivePaymentSelect({
  request,
  onSelect,
  isDisabled,
  id,
  nameFunc,
}: AppPaymentsExclusivePaymentSelectProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle } = useI18nHelper();

  const key1 = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const key2 = useMemo(() => {
    if (identity?.company && id) {
      const id__in = Array.isArray(id) ? id : [id];
      if (id__in.length > 0) {
        return {
          parameter: {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          request: {
            id__in: Array.isArray(id) ? id : [id],
          },
        };
      }
    }
  }, [id, identity]);

  const response1 = useListAllDensePayments(key1?.parameter, key1?.request);
  const response2 = useListAllDensePayments(key2?.parameter, key2?.request);

  const {
    data: resources,
    isLoading: isListResourcesLoading,
    error: isListResourcesError,
  } = useBiSWRResponse(response1, response2, (data1, data2) => {
    return uniqBy([...(data1 || []), ...(data2 || [])], (i) => i.id);
  });

  useEffect(() => {
    if (isListResourcesError) {
      showError(isListResourcesError);
    }
  }, [isListResourcesError, showError]);

  const value = useMemo(() => {
    if (resources && id) {
      const ids = Array.isArray(id) ? id : [id];
      return resources.filter((i) => ids.includes(i.id));
    }
    return [];
  }, [resources, id]);

  return (
    <AppExclusiveSelect<DensePayment>
      name={"payment"}
      values={value}
      options={resources || []}
      optionFunc={(payment) => {
        let name = "";
        if (nameFunc) {
          name = nameFunc(payment);
        } else {
          name = `${payment.id} / ${payment.name}`;
        }

        return {
          value: payment,
          name: name,
        };
      }}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isLoading={isListResourcesLoading}
    />
  );
}
