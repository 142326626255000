import { LightOrderSheet } from "@/features/order-sheets/order-sheet.type";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadOrderSheetSetRequest {}
export interface ReadOrderSheetSetResponse extends LightOrderSheet {}

interface ReadOrderSheetSetPathParameter extends IdentityParameter {
  orderSheetSetId: number;
}

export const readOrderSheetSetTemplateUrl =
  "/:by/:companyId/order-sheet-sets/:orderSheetSetId/read";

export const readOrderSheetSetKey: YWRKey<ReadOrderSheetSetPathParameter> = {
  url: (parameter: ReadOrderSheetSetPathParameter) => {
    return calcUrl(readOrderSheetSetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReadOrderSheetSetKey(url: string) {
  const urlParser = new UrlParser<ReadOrderSheetSetPathParameter>(
    readOrderSheetSetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadOrderSheetSet() {
  const fetcher = useFetcher<
    ReadOrderSheetSetRequest,
    ReadOrderSheetSetResponse
  >();
  return useYWR(readOrderSheetSetKey, fetcher);
}
