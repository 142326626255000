import {
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import {
  Invoice,
  InvoiceType,
  RemittanceStatus,
} from "@/features/invoices/invoice.type";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface ListInvoicesRequest extends PageNumberBasedListRequest {
  season__eq?: string;
  type__in?: InvoiceType[];
  remittanceStatus__in?: RemittanceStatus[];
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  issuedOn__eq?: Date;
  "orderSheetSets.lineSheet.type__eq"?: LineSheetSetType;
}

export interface ListInvoicesResponse extends PageNumberBasedListResponse {
  invoices: Invoice[];
}
export interface ListInvoicesPathParameter extends IdentityParameter {}

export const listInvoicesTemplateUrl = (() => {
  let url = "";
  url += "/:by/:companyId/invoices";
  return url;
})();

export function getListInvoicesKey(
  parameter: ListInvoicesPathParameter,
  request: ListInvoicesRequest
): SWRObjectKey<ListInvoicesRequest> {
  return {
    url: calcUrl(listInvoicesTemplateUrl, parameter),
    params: request,
  };
}

const urlParser = new UrlParser<ListInvoicesPathParameter>(
  listInvoicesTemplateUrl,
  {
    by: StringTransformer.parseBy,
    companyId: StringTransformer.parseId,
  }
);

export function parseListInvoicesKey(key: SWRObjectKey<ListInvoicesRequest>): {
  parameter: ListInvoicesPathParameter;
  request: ListInvoicesRequest;
} {
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params!!,
  };
}

export const DEFAULT_LIST_INVOICES_PAGE_SIZE = 10;

export default function useListInvoices(
  parameter: ListInvoicesPathParameter,
  request: ListInvoicesRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<ListInvoicesRequest, ListInvoicesResponse>();
  return useSWR(getListInvoicesKey(parameter, request), fetcher, config);
}
