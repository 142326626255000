import { IHeaderParams } from "ag-grid-community";
import { Box, Flex } from "@chakra-ui/react";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import { useEffect, useState } from "react";

interface SheetCheckBoxHeaderProps extends IHeaderParams {
  items?: string[];
}

export default function SheetCheckBoxHeader({ api }: SheetCheckBoxHeaderProps) {
  const { t } = useI18nHelper();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const updateSelectionState = () => {
      // Check if all rows are selected
      const allRowsSelected =
        api.getDisplayedRowCount() > 0 &&
        api.getDisplayedRowCount() === api.getSelectedRows().length;
      setChecked(allRowsSelected);
    };

    updateSelectionState();

    api.addEventListener("selectionChanged", updateSelectionState);

    return () => {
      api.removeEventListener("selectionChanged", updateSelectionState);
    };
  }, [api]);

  return (
    <AppCheckBox
      size={"small"}
      isChecked={checked}
      onChange={(value) => {
        if (value) {
          api.selectAll();
        } else {
          api.deselectAll();
        }
      }}
    />
  );
}
