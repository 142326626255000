import { Box, Button, Flex, IconButton } from "@chakra-ui/react";
import { times } from "lodash";
import SVG from "react-inlinesvg";
import React, { useCallback } from "react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import entry from "next/dist/server/typescript/rules/entry";

export interface AppTabSelectValue {
  index: number;
  name: string;
}

export interface AppTabSelectProps {
  name: string;
  values: AppTabSelectValue[];
  onSelect?: (value?: AppTabSelectValue) => void;
  value?: AppTabSelectValue;
}
export default function AppTabSelect({
  name,
  value,
  values,
  onSelect,
}: AppTabSelectProps) {
  const gap = 4;
  const fallbackBarSize = 1200;
  const selectSize = 40;
  const buttonSize = 80;
  const indicatorSize = 120;
  const [left, setLeft] = useState<number>(
    -(fallbackBarSize - selectSize - gap)
  );

  const manuallyPlacedTabIndexRef = useRef<number>(-1);

  useEffect(() => {
    manuallyPlacedTabIndexRef.current = -1;
  }, [values, value]);

  const indicatorsRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const leftRangeRef = useRef<{ min: number; max: number }>({ min: 0, max: 0 });
  const domRef = useRef<{
    anchor?: DOMRectReadOnly;
    indicators?: DOMRectReadOnly;
  }>();

  useEffect(() => {
    setLeft(-(fallbackBarSize - selectSize - gap));
  }, [values]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.id === "app-tab-select-anchor") {
          domRef.current = {
            ...domRef.current,
            anchor: entry.contentRect,
          };
        } else if (entry.target.id === "app-tab-select-indicators") {
          domRef.current = {
            ...domRef.current,
            indicators: entry.contentRect,
          };
        }
      });

      if (domRef.current) {
        const { anchor, indicators } = domRef.current!!;

        if (anchor && indicators) {
          //길이가 짧은경우 min,max가 역전이 되는 경우도 있구낭ㅇㅋ

          let w = anchor.width - buttonSize - selectSize - 8;
          const max = -(fallbackBarSize - selectSize - gap);
          const min = max - (indicators.width - fallbackBarSize * 2) + w;
          leftRangeRef.current = { min: Math.min(min, max), max };
        }
      }
    });

    if (anchorRef.current && indicatorsRef.current) {
      resizeObserver.observe(anchorRef.current);
      resizeObserver.observe(indicatorsRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const move = useCallback(
    (next: number) => {
      const { min, max } = leftRangeRef.current;
      if (next <= min) {
        setLeft(min);
      } else if (next >= max) {
        setLeft(max);
      } else {
        setLeft(next);
      }
    },
    [setLeft]
  );

  useEffect(() => {
    if (values && value) {
      if (manuallyPlacedTabIndexRef.current !== value.index) {
        const index = values.findIndex(
          (_value) => _value.index === value?.index
        );
        if (index > -1 && domRef.current && domRef.current?.anchor) {
          const { min, max } = leftRangeRef.current;

          //이건 왼쪽에 쫙 붙었을때구나
          const blockLeftMax = max - index * (indicatorSize + gap);
          // const blockLeftMin = blockLeftMax - (indicatorSize + gap);
          const blockLeftMin = blockLeftMax - (indicatorSize + gap);

          const blockRightMin =
            min + (values.length - index - 1) * (indicatorSize + gap);
          const blockRightMax = blockRightMin + (indicatorSize + gap);

          if (left < blockLeftMax) {
            move(blockLeftMax);
          } else if (left > blockRightMin) {
            move(blockRightMin);
          }
        }
      }
      manuallyPlacedTabIndexRef.current = value.index;
    }
  }, [value, values, left, move]);

  return (
    <Box
      position={"relative"}
      flexGrow={1}
      h={"40px"}
      ref={anchorRef}
      id={"app-tab-select-anchor"}
    >
      <Flex
        position={"relative"}
        zIndex={"2"}
        backgroundColor={"#FCFCFE"}
        w={"40px"}
        height={"32.5px"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        pt={"4px"}
        borderRight={"0.5px solid var(--gray-groomy, #8F8F8C)"}
      >
        <AppSelect<AppTabSelectValue>
          variant={"icon"}
          // width={"160px"}
          name={name}
          idFunc={(value) => {
            return "AppTabSelect" + value.index;
          }}
          value={value}
          options={values.map((tab) => {
            return {
              name: tab.name,
              value: tab,
            };
          })}
          onSelect={onSelect}
        />
      </Flex>
      <Flex
        position={"absolute"}
        top={"8px"}
        left={`${left}px`}
        zIndex={"0"}
        ref={indicatorsRef}
        id={"app-tab-select-indicators"}
      >
        <Box
          w={`${fallbackBarSize}px`}
          borderBottom={"0.5px solid var(--gray-groomy, #8F8F8C)"}
        />
        {values.map((tab, index) => {
          const isSelected = tab.index === value?.index;
          return (
            <React.Fragment key={"AppTab_" + tab.index}>
              <Button
                variant={"ghost"}
                borderRadius={"4px 4px 0px 0px"}
                border={"0.5px solid var(--gray-groomy, #8F8F8C)"}
                borderBottom={
                  isSelected
                    ? "none"
                    : "0.5px solid var(--gray-groomy, #8F8F8C)"
                }
                p={"0px 4px"}
                m={"0"}
                w={`${indicatorSize}px`}
                h={"24.5px"}
                background={"background: var(--white, #FFF)"}
                fontSize={"12px"}
                color={isSelected ? "#1272EF" : "#22221F"}
                borderColor={isSelected ? "#1272EF" : "#22221F"}
                fontWeight={"400"}
                overflow={"hidden"}
                _hover={{ color: "#1272EF", borderColor: "#1272EF" }}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                justifyContent={"flex-start"}
                onClick={() => {
                  if (onSelect) {
                    onSelect(tab);
                  }
                }}
              >
                {tab.name}
              </Button>
              {index !== values.length - 1 && (
                <Box
                  w={"4px"}
                  h={"24.5px"}
                  borderBottom={"0.5px solid var(--gray-groomy, #8F8F8C)"}
                />
              )}
            </React.Fragment>
          );
        })}
        <Box
          w={`${fallbackBarSize}px`}
          h={"24.5px"}
          borderBottom={"0.5px solid var(--gray-groomy, #8F8F8C)"}
        />
      </Flex>
      <Flex
        position={"absolute"}
        right={"0"}
        top={"0"}
        width={`${buttonSize}px`}
        gap={"8px"}
        height={"32.5px"}
        pt={"8px"}
        flexShrink={"0"}
        background={"#FCFCFE"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        borderLeft={"0.5px solid var(--gray-groomy, #8F8F8C)"}
      >
        <AppIconButton
          variant={"ghost"}
          ariaLabel={"move tab left"}
          icon={"left"}
          onClick={() => move(left - 32)}
        />
        <AppIconButton
          variant={"ghost"}
          ariaLabel={"move tab right"}
          icon={"right"}
          onClick={() => {
            move(left + 32);
          }}
        />
      </Flex>
    </Box>
  );
}
