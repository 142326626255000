import {
  updateOrderSheetSetStatusKey,
  UpdateOrderSheetSetStatusRequest,
  UpdateOrderSheetSetStatusResponse,
} from "@/features/order-status/hooks/use-update-order-sheet-set-status";
import useFetcher from "@/utils/use-fetcher";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";

export default function useUpdateOrderSheetSetStatusInfinite() {
  const fetcher = useFetcher<
    UpdateOrderSheetSetStatusRequest,
    UpdateOrderSheetSetStatusResponse
  >();

  return useYWRInfinite(updateOrderSheetSetStatusKey, fetcher);
}
