import AppSelect from "@/features/line-sheet-sets/app-select";
import useListAirports, {
  Airport,
} from "@/features/transportations/hooks/use-list-airports";
import { useEffect, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

interface AppAirportSelectProps {
  id?: string;
  name?: string;
  onSelect: (value?: Airport) => void;
  isLiteral?: boolean;
  isDisabled?: boolean;
}

export default function AppAirportSelect({
  id,
  name,
  onSelect,
  isLiteral,
  isDisabled,
}: AppAirportSelectProps) {
  const { tTitle, i18n } = useI18nHelper();
  const { error: showError } = useAppToasts();

  const { data, mutate, error } = useListAirports(
    {},
    {},
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );

  useEffect(() => {
    mutate();
  }, [mutate, i18n.language]);

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  const options = useMemo(() => {
    if (data) {
      return data.airports.map((airport) => {
        return {
          name: `${airport.iataCode}/${airport.name}`,
          value: airport,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <AppSelect<Airport>
      name={name || tTitle("airport")}
      idFunc={(airport) => airport.iataCode}
      value={
        data && id
          ? data.airports.find((airport) => airport.iataCode === id)
          : undefined
      }
      options={options}
      onSelect={onSelect}
      isLiteral={isLiteral}
      isDisabled={isDisabled}
    />
  );
}
