import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import { AxiosRequestConfig } from "axios";
import {
  createCreditInvoiceKey,
  CreateCreditInvoiceRequest,
  CreateCreditInvoiceResponse,
} from "@/features/invoices/credit-invoices/hooks/use-create-credit-invoice";
import useFetcher from "@/utils/use-fetcher";

export default function useCreateCreditInvoiceInfinite(
  config?: AxiosRequestConfig<CreateCreditInvoiceRequest>
) {
  const fetcher = useFetcher<
    CreateCreditInvoiceRequest,
    CreateCreditInvoiceResponse
  >();

  return useYWRInfinite(createCreditInvoiceKey, fetcher, config);
}
