import { Field, FieldProps, FormikProps } from "formik";
import { DensePayment } from "@/features/invoices/payment/payment.type";
import { ListAllDensePaymentsRequest } from "@/features/invoices/payment/hooks/use-list-all-dense-payments";
import React, { useMemo } from "react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, FormControl, FormLabel, Skeleton } from "@chakra-ui/react";
import IsolatedPaymentPanel from "@/features/invoices/payment/isolated-payment-panel";
import AppPaymentsExclusivePaymentSelect from "@/features/invoices/payment/app-payments-exclusive-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

type KeysWithIdArray<T> = {
  [K in keyof T]: T[K] extends { id: number }[] ? K : never;
}[keyof T];

interface LinkedPaymentFormControlProps<
  K extends KeysWithIdArray<T>,
  T extends { [key: string]: any }
> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
  onSelect: (value: DensePayment[]) => void;
  request?: ListAllDensePaymentsRequest;
  title?: string;
  fieldName: K;
  nameFunc?: (payment: DensePayment) => string;
}

export default function LinkedPaymentFormControl<
  T extends { [key: string]: any },
  K extends KeysWithIdArray<T>
>({
  formik,
  isEditing,
  isDisabled,
  onSelect,
  request,
  title,
  fieldName,
  nameFunc,
}: LinkedPaymentFormControlProps<K, T>) {
  const { tTitle } = useI18nHelper();
  const ids = useMemo(() => {
    const candidate = formik.values[fieldName] as { id: number }[];
    return candidate?.map((payment) => payment.id);
  }, [fieldName, formik.values]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Skeleton height={"16px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
        </Flex>
      </AppPanel>
    );
  }

  if (!isEditing) {
    return (
      <IsolatedPaymentPanel
        title={title || tTitle("related_payments")}
        paymentIds={ids}
      />
    );
  }

  return (
    <AppPanel title={title || tTitle("common:related_payments")}>
      <Field name={fieldName}>
        {(props: FieldProps<T[K], T>) => {
          return (
            <FormControl display={"flex"} flexDirection={"column"} gap={"4px"}>
              <FormLabel
                fontSize={"12px"}
                color={"#6F6F6C"}
                padding={"0"}
                margin={"0"}
                display={"none"}
              >
                {title}
              </FormLabel>

              <AppPaymentsExclusivePaymentSelect
                nameFunc={nameFunc}
                id={ids}
                request={request}
                isDisabled={isDisabled}
                onSelect={(value) => {
                  onSelect(value);
                }}
              />
            </FormControl>
          );
        }}
      </Field>
    </AppPanel>
  );
}
