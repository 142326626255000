import { atom } from "recoil";
import { ModalProps } from "@chakra-ui/modal";
import { ReactElement } from "react";
import { AppButtonProps } from "@/features/line-sheet-sets/app-button";

export interface AppDialogProps {
  id: string;
  title: string;
  message: string | JSX.Element;
  confirmName: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
  isDisabled?: boolean;
  size?: ModalProps["size"];
  resolve: (response: any) => void;
}

export const appDialogState = atom<AppDialogProps[]>({
  key: "AppDialog",
  default: [],
});
