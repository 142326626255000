import { Flex, Icon, Image, Input, Progress } from "@chakra-ui/react";
import SVG from "react-inlinesvg";

export interface FileUploadProgressProps {
  file: File;
  type?: string;
  isLoading: boolean;
  isError: boolean;
  isComplete: boolean;
  progress?: number;
}

export default function FileUploadProgress({
  file,
  type,
  isLoading,
  isError,
  isComplete,
  progress,
}: FileUploadProgressProps) {
  const icon = (() => {
    if (isComplete) {
      return <Icon as={SVG} src={"/icons/icon_check.svg"} />;
    } else if (isError) {
      return (
        <Icon as={SVG} src={"/icons/icon_x_letter.svg"} color={"#EE7D7A"} />
      );
    } else {
      return (
        <Icon
          as={Image}
          src={"/icons/icon_upload_animation.gif"}
          boxSize={"20px"}
        />
      );
    }
  })();

  return (
    <Flex flexDirection={"column"}>
      <Flex flexDirection={"row"} w={"100%"} alignItems={"center"} gap={"8px"}>
        <Input
          flexGrow={"1"}
          value={file.name}
          fontSize={"12px"}
          p={"4px 12px"}
          m={"0"}
          h={"auto"}
          color={"#8F8F8C"}
          borderColor={"#8F8F8C"}
          disabled={true}
          cursor={"default"}
          title={file.name}
          _hover={{ cursor: "default" }}
        />
        {type && (
          <Input
            value={type}
            fontSize={"12px"}
            w={"160px"}
            p={"4px 12px"}
            m={"0"}
            h={"auto"}
            color={"#8F8F8C"}
            borderColor={"#8F8F8C"}
            disabled={true}
            cursor={"default"}
            title={type}
            _hover={{ cursor: "default" }}
          />
        )}
        {icon}
      </Flex>
      <Progress value={progress} />
    </Flex>
  );
}
