import { Field, FieldProps, FormikProps } from "formik";
import { TriangularTradeFormFields } from "@/features/invoices/triangular-trades/upload-triangular-trade-page";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Spacer,
  Spinner,
  Image,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { toTitle } from "@/utils/case";
import AppInput from "@/features/ui/app-input";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { invoiceParsableIssuedByList } from "@/features/invoices/triangular-trades/triangular-trade.type";
import TriangularTradeStatusLabel from "@/features/invoices/triangular-trades/triangular-trade-status-label";
import useListAllDepartureList, {
  ListAllDepartureListPathParameter,
  ListAllDepartureListRequest,
} from "@/features/invoices/triangular-trades/hooks/use-list-all-depature";
import AppButton from "@/features/line-sheet-sets/app-button";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import CreateDepartureModal, {
  CreateDepartureModalProps,
} from "@/features/invoices/triangular-trades/create-departure-modal";
import useListAllIssuedByList, {
  ListAllIssuedByListPathParameter,
  ListAllIssuedByListRequest,
} from "@/features/invoices/triangular-trades/hooks/use-list-all-issued-by";
import CreateIssuedByModal, {
  CreateIssuedByModalProps,
} from "@/features/invoices/triangular-trades/create-issued-by-modal";
import CreateIssuedToModal, {
  CreateIssuedToModalProps,
} from "@/features/invoices/triangular-trades/create-issued-to-modal";
import CreateBankInfoModal, {
  CreateBankInfoModalProps,
} from "@/features/invoices/triangular-trades/create-bank-info-modal";
import useListAllIssuedToList, {
  ListAllIssuedToListPathParameter,
  ListAllIssuedToListRequest,
} from "@/features/invoices/triangular-trades/hooks/use-list-all-issued-to";
import useListAllBankInfoList from "@/features/invoices/triangular-trades/hooks/use-list-all-bank-info";
import useListAllForwarderList from "@/features/invoices/triangular-trades/hooks/use-list-all-forwarder";
import CreateForwarderModal, {
  CreateForwarderModalProps,
} from "@/features/invoices/triangular-trades/create-forwarder-modal";
import useListAllFtaSignatureList from "@/features/invoices/triangular-trades/hooks/use-list-all-fta-signature";
import CreateFtaSignatureModal, {
  CreateFtaSignatureModalProps,
} from "@/features/invoices/triangular-trades/create-fta-signature-modal";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppTextArea from "@/features/ui/app-text-area";

interface TriangularTradeInformationFormControlProps {
  formik: FormikProps<TriangularTradeFormFields>;
  isEditing?: boolean;
  isRefreshing?: boolean;
}

export default function TriangularTradeInformationFormControl({
  formik,
  isEditing,
  isRefreshing,
}: TriangularTradeInformationFormControlProps) {
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();

  // const departureKey = useMemo(():
  //   | {
  //       parameter: ListAllDepartureListPathParameter;
  //       request: ListAllDepartureListRequest;
  //     }
  //   | undefined => {
  //   let request: ListAllDepartureListRequest = {};
  //   if (request && identity?.company) {
  //     return {
  //       parameter: {
  //         by: identity.company.type,
  //         companyId: identity.company.id,
  //       },
  //       request,
  //     };
  //   }
  // }, [identity]);

  const issuedByKey = useMemo(():
    | {
        parameter: ListAllIssuedByListPathParameter;
        request: ListAllIssuedByListRequest;
      }
    | undefined => {
    let request: ListAllIssuedByListRequest = {};
    if (request && identity?.company) {
      return {
        parameter: {
          by: 'BOUTIQUE',
          companyId: formik.initialValues.boutique?.id || identity.company.id,
        },
        request,
      };
    }
  }, [formik.initialValues.boutique?.id, identity]);

  const issuedToKey = useMemo(():
    | {
        parameter: ListAllIssuedToListPathParameter;
        request: ListAllIssuedToListRequest;
      }
    | undefined => {
    let request: ListAllIssuedToListRequest = {};
    if (request && identity?.company) {
      return {
        parameter: {
          by: 'BOUTIQUE',
          companyId: formik.initialValues.boutique?.id || identity.company.id,
        },
        request,
      };
    }
  }, [formik.initialValues.boutique?.id, identity?.company]);

  const bankInfoKey = useMemo(():
    | {
        parameter: ListAllIssuedToListPathParameter;
        request: ListAllIssuedToListRequest;
      }
    | undefined => {
    let request: ListAllIssuedToListRequest = {};
    if (request && identity?.company) {
      return {
        parameter: {
          by: 'BOUTIQUE',
          companyId: formik.initialValues.boutique?.id || identity.company.id,
        },
        request,
      };
    }
  }, [formik.initialValues.boutique?.id, identity?.company]);

  const forwarderKey = useMemo(():
    | {
        parameter: ListAllIssuedToListPathParameter;
        request: ListAllIssuedToListRequest;
      }
    | undefined => {
    let request: ListAllIssuedToListRequest = {};
    if (request && identity?.company) {
      return {
        parameter: {
          by: 'BOUTIQUE',
          companyId: formik.initialValues.boutique?.id || identity.company.id,
        },
        request,
      };
    }
  }, [formik.initialValues.boutique?.id, identity?.company]);

  const ftaSignatureKey = useMemo(():
    | {
        parameter: ListAllIssuedToListPathParameter;
        request: ListAllIssuedToListRequest;
      }
    | undefined => {
    let request: ListAllIssuedToListRequest = {};
    if (request && identity?.company) {
      return {
        parameter: {
          by: 'BOUTIQUE',
          companyId: formik.initialValues.boutique?.id || identity.company.id,
        },
        request,
      };
    }
  }, [formik.initialValues.boutique?.id, identity?.company]);

  // let {
  //   isLoading: isDepartureLoading,
  //   data: departureList,
  //   mutate: mutateDepartureList,
  //   error: departureError,
  // } = useListAllDepartureList(departureKey?.parameter, departureKey?.request);

  let {
    isLoading: isIssuedByLoading,
    data: issuedByList,
    mutate: mutateIssuedByList,
    error: issuedByError,
  } = useListAllIssuedByList(issuedByKey?.parameter, issuedByKey?.request);

  let {
    isLoading: isIssuedToLoading,
    data: issuedToList,
    mutate: mutateIssuedToList,
    error: issuedToError,
  } = useListAllIssuedToList(issuedToKey?.parameter, issuedToKey?.request);

  let {
    isLoading: isBankInfoLoading,
    data: bankInfoList,
    mutate: mutateBankInfoList,
    error: bankInfoError,
  } = useListAllBankInfoList(bankInfoKey?.parameter, bankInfoKey?.request);

  let {
    isLoading: isForwarderLoading,
    data: forwarderList,
    mutate: mutateForwarderList,
    error: forwarderError,
  } = useListAllForwarderList(forwarderKey?.parameter, forwarderKey?.request);

  let {
    isLoading: isFtaSignatureLoading,
    data: ftaSignatureList,
    mutate: mutateFtaSignatureList,
    error: ftaSignatureError,
  } = useListAllFtaSignatureList(
    ftaSignatureKey?.parameter,
    ftaSignatureKey?.request
  );

  // const departureOptions = useMemo(() => {
  //   if (!departureList) {
  //     return [];
  //   }
  //   return departureList.map((value) => {
  //     return {
  //       name: value.alias,
  //       value: value,
  //     };
  //   });
  // }, [departureList]);

  const issuedByOptions = useMemo(() => {
    if (!issuedByList) {
      return [];
    }
    return issuedByList.map((value) => {
      return {
        name: value.companyName,
        value: value,
      };
    });
  }, [issuedByList]);

  const issuedToOptions = useMemo(() => {
    if (!issuedToList) {
      return [];
    }
    return issuedToList.map((value) => {
      return {
        name: value.companyName,
        value: value,
      };
    });
  }, [issuedToList]);

  const bankInfoOptions = useMemo(() => {
    if (!bankInfoList) {
      return [];
    }
    return bankInfoList.map((value) => {
      return {
        name: value.alias,
        value: value,
      };
    });
  }, [bankInfoList]);

  const forwarderOptions = useMemo(() => {
    if (!forwarderList) {
      return [];
    }
    return forwarderList.map((value) => {
      return {
        name: value.name,
        value: value,
      };
    });
  }, [forwarderList]);

  const ftaSignatureOptions = useMemo(() => {
    if (!ftaSignatureList) {
      return [];
    }
    return ftaSignatureList.map((value) => {
      return {
        name: value.alias,
        value: value,
      };
    });
  }, [ftaSignatureList]);

  const {
    isOpen: isCreateDepartureModelOpen,
    onClose: onCreateDepartureModalClose,
    data: createDepartureModalData,
    openWithData: openCreateDepartureModal,
  } = useDisclosureWithData<CreateDepartureModalProps>();

  const {
    isOpen: isCreateIssuedByModelOpen,
    onClose: onCreateIssuedByModalClose,
    data: createIssuedByModalData,
    openWithData: openCreateIssuedByModal,
  } = useDisclosureWithData<CreateIssuedByModalProps>();

  const {
    isOpen: isCreateIssuedToModelOpen,
    onClose: onCreateIssuedToModalClose,
    data: createIssuedToModalData,
    openWithData: openCreateIssuedToModal,
  } = useDisclosureWithData<CreateIssuedToModalProps>();

  const {
    isOpen: isCreateBankInfoModelOpen,
    onClose: onCreateBankInfoModalClose,
    data: createBankInfoModalData,
    openWithData: openCreateBankInfoModal,
  } = useDisclosureWithData<CreateBankInfoModalProps>();

  const {
    isOpen: isCreateForwarderModelOpen,
    onClose: onCreateForwarderModalClose,
    data: createForwarderModalData,
    openWithData: openCreateForwarderModal,
  } = useDisclosureWithData<CreateForwarderModalProps>();

  const {
    isOpen: isCreateFtaSignatureModelOpen,
    onClose: onCreateFtaSignatureModalClose,
    data: createFtaSignatureModalData,
    openWithData: openCreateFtaSignatureModal,
  } = useDisclosureWithData<CreateFtaSignatureModalProps>();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          rowGap={"8px"}
          gridTemplateRows={"repeat(7, 16px 24px) repeat(2, 16px 60px)"}
        >
          {(Array(18).fill(0) as number[]).map((_, index) => {
            return <Skeleton key={index} />;
          })}
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:invoice_information.abbr")}>
      <Grid rowGap={"8px"}>
        {!isEditing && (
          <>
            <Field name={"status"}>
              {(
                props: FieldProps<
                  TriangularTradeFormFields["status"],
                  TriangularTradeFormFields
                >
              ) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("status"))}
                    </FormLabel>
                    <Spacer />
                    {isRefreshing ? (
                      <Spinner size={"sm"} />
                    ) : (
                      <TriangularTradeStatusLabel value={props.field.value} />
                    )}
                  </FormControl>
                );
              }}
            </Field>

            <Box
              height={"8px"}
              borderBottom={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
              marginBottom={"8px"}
            ></Box>
          </>
        )}

        <Field name={"originallyIssuedBy"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["originallyIssuedBy"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("originally_issued_by"))}
                </FormLabel>
                <Box
                  borderBottom={"1px solid black"}
                  marginBottom={"8px"}
                ></Box>
                <AppSelect
                  name={toTitle(t("common:originally_issued_by"))}
                  value={props.field.value}
                  options={invoiceParsableIssuedByList.map((value) => {
                    return {
                      name: value,
                      value,
                    };
                  })}
                  isReadOnly={!isEditing}
                  popoverStrategy={"fixed"}
                  onSelect={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {/*<Field name={"departure"}>*/}
        {/*  {(*/}
        {/*    props: FieldProps<*/}
        {/*      TriangularTradeFormFields["departure"],*/}
        {/*      TriangularTradeFormFields*/}
        {/*    >*/}
        {/*  ) => {*/}
        {/*    return (*/}
        {/*      <Flex flexDirection={"column"}>*/}
        {/*        <Flex*/}
        {/*          borderBottom={"1px"}*/}
        {/*          marginBottom={"10px"}*/}
        {/*          paddingBottom={"4px"}*/}
        {/*        >*/}
        {/*          <FormControl isRequired={isEditing}>*/}
        {/*            <FormLabel*/}
        {/*              fontWeight={"bold"}*/}
        {/*              fontSize={"14px"}*/}
        {/*              color={"black"}*/}
        {/*              padding={"0"}*/}
        {/*              margin={"0"}*/}
        {/*              paddingTop={"8px"}*/}
        {/*            >*/}
        {/*              {toTitle(t("common:departure"))}*/}
        {/*            </FormLabel>*/}
        {/*          </FormControl>*/}
        {/*        </Flex>*/}
        {/*        <Flex*/}
        {/*          alignItems={"end"}*/}
        {/*          gap={"8px"}*/}
        {/*          marginBottom={"8px"}*/}
        {/*          flexDirection={"row"}*/}
        {/*        >*/}
        {/*          <FormControl*/}
        {/*            display={"flex"}*/}
        {/*            flexDirection={"column"}*/}
        {/*            gap={"4px"}*/}
        {/*            isRequired={isEditing}*/}
        {/*            fontSize={"12px"}*/}
        {/*          >*/}
        {/*            <AppSelect*/}
        {/*              idFunc={(value) => value?.id?.toString()}*/}
        {/*              name={toTitle(t("common:departure"))}*/}
        {/*              value={props.field.value}*/}
        {/*              options={departureOptions}*/}
        {/*              isReadOnly={!isEditing}*/}
        {/*              popoverStrategy={"fixed"}*/}
        {/*              onSelect={(value) => {*/}
        {/*                props.form.setFieldValue(props.field.name, value);*/}
        {/*              }}*/}
        {/*            />*/}
        {/*          </FormControl>*/}
        {/*          {isEditing && (*/}
        {/*            <AppButton*/}
        {/*              onClick={() => {*/}
        {/*                openCreateDepartureModal(() => {*/}
        {/*                  return {*/}
        {/*                    targetBy: "AGENCY",*/}
        {/*                    onCreate: (_) => {*/}
        {/*                      mutateDepartureList();*/}
        {/*                    },*/}
        {/*                  };*/}
        {/*                });*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {tTitle("add")}*/}
        {/*            </AppButton>*/}
        {/*          )}*/}
        {/*        </Flex>*/}
        {/*        {props.field.value &&*/}
        {/*          [*/}
        {/*            {*/}
        {/*              label: tTitle(*/}
        {/*                "create_departure_form.field.country.label"*/}
        {/*              ),*/}
        {/*              value: props.field.value.country,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              label: tTitle("create_departure_form.field.state.label"),*/}
        {/*              value: props.field.value.state,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              label: tTitle("create_departure_form.field.city.label"),*/}
        {/*              value: props.field.value.city,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              label: tTitle(*/}
        {/*                "common:create_departure_form.field.street_address.label"*/}
        {/*              ),*/}
        {/*              value: props.field.value.streetAddress,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              label: tTitle(*/}
        {/*                "common:create_departure_form.field.detail_address.label"*/}
        {/*              ),*/}
        {/*              value: props.field.value.detailAddress,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              label: tTitle(*/}
        {/*                "common:create_departure_form.field.zip_code.label"*/}
        {/*              ),*/}
        {/*              value: props.field.value.zipCode,*/}
        {/*            },*/}
        {/*          ].map((field, index) => (*/}
        {/*            <Flex*/}
        {/*              key={index}*/}
        {/*              style={{*/}
        {/*                color: "gray",*/}
        {/*                fontSize: "12px",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              [{field.label}] {field.value}*/}
        {/*            </Flex>*/}
        {/*          ))}*/}
        {/*      </Flex>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*</Field>*/}

        <Field name={"issuedBy"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["issuedBy"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"}>
                <Flex
                  borderBottom={"1px"}
                  marginBottom={"10px"}
                  paddingBottom={"4px"}
                >
                  <FormControl isRequired={isEditing}>
                    <FormLabel
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"black"}
                      padding={"0"}
                      margin={"0"}
                      paddingTop={"8px"}
                    >
                      {toTitle(t("issued_by"))}
                    </FormLabel>
                  </FormControl>
                </Flex>
                <Flex
                  alignItems={"end"}
                  gap={"8px"}
                  marginBottom={"8px"}
                  flexDirection={"row"}
                >
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <AppSelect
                      idFunc={(value) => value?.companyName}
                      name={toTitle(t("common:issued_by"))}
                      value={props.field.value}
                      options={issuedByOptions}
                      isReadOnly={!isEditing}
                      popoverStrategy={"fixed"}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      onClick={() => {
                        openCreateIssuedByModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (_) => {
                              mutateIssuedByList();
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
                {props.field.value &&
                  [
                    {
                      label: tTitle("common:business_registration_number"),
                      value: props.field.value.businessRegistrationNumber,
                    },
                    {
                      label: tTitle("country"),
                      value: props.field.value.country,
                    },
                    {
                      label: tTitle("state"),
                      value: props.field.value.state,
                    },
                    {
                      label: tTitle("city"),
                      value: props.field.value.city,
                    },
                    {
                      label: tTitle("street_address"),
                      value: props.field.value.streetAddress,
                    },
                    {
                      label: tTitle("detail_address"),
                      value: props.field.value.detailAddress,
                    },
                    {
                      label: tTitle("zip_code"),
                      value: props.field.value.zipCode,
                    },
                    {
                      label: tTitle("common:representative_name"),
                      value: props.field.value.representativeName,
                    },
                    {
                      label: tTitle("common:representative_email"),
                      value: props.field.value.representativeEmail,
                    },
                    {
                      label: tTitle("common:pec"),
                      value: props.field.value.pec,
                    },
                    {
                      label: tTitle("common:representative_mobile_number"),
                      value: props.field.value.representativeMobileNumber,
                    },
                    {
                      label: tTitle("common:rea"),
                      value: props.field.value.rea,
                    },
                    {
                      label: tTitle("common:e_invoice_unique_code"),
                      value: props.field.value.eInvoiceUniqueCode,
                    },
                    {
                      label: tTitle("common:capital"),
                      value: props.field.value.capital,
                    },
                  ].map((field, index) => (
                    <Flex
                      key={index}
                      style={{
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      [{field.label}] {field.value}
                    </Flex>
                  ))}
              </Flex>
            );
          }}
        </Field>

        <Field name={"issuedTo"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["issuedTo"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"}>
                <Flex
                  borderBottom={"1px"}
                  marginBottom={"10px"}
                  paddingBottom={"4px"}
                >
                  <FormControl isRequired={isEditing}>
                    <FormLabel
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"black"}
                      padding={"0"}
                      margin={"0"}
                      paddingTop={"8px"}
                    >
                      {toTitle(t("issued_to"))}
                    </FormLabel>
                  </FormControl>
                </Flex>
                <Flex
                  alignItems={"end"}
                  gap={"8px"}
                  marginBottom={"8px"}
                  flexDirection={"row"}
                >
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <AppSelect
                      idFunc={(value) => value?.companyName}
                      name={toTitle(t("common:issued_to"))}
                      value={props.field.value}
                      options={issuedToOptions}
                      isReadOnly={!isEditing}
                      popoverStrategy={"fixed"}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      onClick={() => {
                        openCreateIssuedToModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (_) => {
                              mutateIssuedToList();
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
                {props.field.value &&
                  [
                    {
                      label: tTitle("common:business_registration_number"),
                      value: props.field.value.businessRegistrationNumber,
                    },
                    {
                      label: tTitle("country"),
                      value: props.field.value.country,
                    },
                    {
                      label: tTitle("state"),
                      value: props.field.value.state,
                    },
                    {
                      label: tTitle("city"),
                      value: props.field.value.city,
                    },
                    {
                      label: tTitle("street_address"),
                      value: props.field.value.streetAddress,
                    },
                    {
                      label: tTitle("detail_address"),
                      value: props.field.value.detailAddress,
                    },
                    {
                      label: tTitle("zip_code"),
                      value: props.field.value.zipCode,
                    },
                  ].map((field, index) => (
                    <Flex
                      key={index}
                      style={{
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      [{field.label}] {field.value}
                    </Flex>
                  ))}
              </Flex>
            );
          }}
        </Field>

        <Field name={"bankInfo"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["bankInfo"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"}>
                <Flex
                  borderBottom={"1px"}
                  marginBottom={"10px"}
                  paddingBottom={"4px"}
                >
                  <FormControl isRequired={isEditing}>
                    <FormLabel
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"black"}
                      padding={"0"}
                      margin={"0"}
                      paddingTop={"8px"}
                    >
                      {toTitle(t("bank_info"))}
                    </FormLabel>
                  </FormControl>
                </Flex>
                <Flex
                  alignItems={"end"}
                  gap={"8px"}
                  marginBottom={"8px"}
                  flexDirection={"row"}
                >
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <AppSelect
                      idFunc={(value) => value?.alias}
                      name={toTitle(t("common:bank_info"))}
                      value={props.field.value}
                      options={bankInfoOptions}
                      isReadOnly={!isEditing}
                      popoverStrategy={"fixed"}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      onClick={() => {
                        openCreateBankInfoModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (_) => {
                              mutateBankInfoList();
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
                {props.field.value &&
                  [
                    {
                      label: tTitle("common:bank_name"),
                      value: props.field.value?.bankName,
                    },
                    {
                      label: tTitle("common:iban"),
                      value: props.field.value?.iban,
                    },
                    {
                      label: tTitle("common:swift_code"),
                      value: props.field.value?.swiftCode,
                    },
                  ].map((field, index) => (
                    <Flex
                      key={index}
                      style={{
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      [{field.label}] {field.value}
                    </Flex>
                  ))}
              </Flex>
            );
          }}
        </Field>

        <Field name={"ftaSignature"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["ftaSignature"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"}>
                <Flex
                  borderBottom={"1px"}
                  marginBottom={"10px"}
                  paddingBottom={"4px"}
                >
                  <FormControl isRequired={isEditing}>
                    <FormLabel
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"black"}
                      padding={"0"}
                      margin={"0"}
                      paddingTop={"8px"}
                    >
                      {toTitle(t("common:fta_signature"))}
                    </FormLabel>
                  </FormControl>
                </Flex>
                <Flex
                  alignItems={"end"}
                  gap={"8px"}
                  marginBottom={"8px"}
                  flexDirection={"row"}
                >
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <AppSelect
                      idFunc={(value) => value?.alias}
                      name={toTitle(t("common:fta_signature"))}
                      value={props.field.value}
                      options={ftaSignatureOptions}
                      isReadOnly={!isEditing}
                      popoverStrategy={"fixed"}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      onClick={() => {
                        openCreateFtaSignatureModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (_) => {
                              mutateFtaSignatureList();
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
                {props.field.value &&
                  [
                    {
                      label: tTitle("emphasized_fta_code"),
                      value: props.field.value.emphasizedFtaCode,
                      type: "text",
                    },
                    {
                      label: tTitle("common:fta_code"),
                      value: props.field.value.ftaCode,
                      type: "text",
                    },
                    {
                      label: tTitle("common:fta_signature"),
                      value: props.field.value.signatureFile.url,
                      type: "image",
                    },
                  ].map((field, index) => (
                    <>
                      {field.type === "image" ? (
                        <Flex
                          key={index}
                          style={{
                            color: "gray",
                            fontSize: "12px",
                          }}
                          flexDirection={"column"}
                        >
                          [{field.label}]
                          <Image src={field.value!!} />
                        </Flex>
                      ) : (
                        <>
                          <Flex
                            key={index}
                            style={{
                              color: "gray",
                              fontSize: "12px",
                            }}
                          >
                            [{field.label}] {field.value}
                          </Flex>
                        </>
                      )}
                    </>
                  ))}
              </Flex>
            );
          }}
        </Field>

        <Field name={"forwarder"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["forwarder"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"}>
                <Flex
                  borderBottom={"1px"}
                  marginBottom={"10px"}
                  paddingBottom={"4px"}
                >
                  <FormControl isRequired={isEditing}>
                    <FormLabel
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"black"}
                      padding={"0"}
                      margin={"0"}
                      paddingTop={"8px"}
                    >
                      {toTitle(t("forwarder"))}
                    </FormLabel>
                  </FormControl>
                </Flex>
                <Flex
                  alignItems={"end"}
                  gap={"8px"}
                  marginBottom={"8px"}
                  flexDirection={"row"}
                >
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                    fontSize={"12px"}
                  >
                    <AppSelect
                      idFunc={(value) => value?.name}
                      name={toTitle(t("common:forwarder"))}
                      value={props.field.value}
                      options={forwarderOptions}
                      isReadOnly={!isEditing}
                      popoverStrategy={"fixed"}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      onClick={() => {
                        openCreateForwarderModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (_) => {
                              mutateForwarderList();
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
                {props.field.value &&
                  [
                    {
                      label: tTitle(
                        "common:create_forwarder_form.field.name.label"
                      ),
                      value: props.field.value.name,
                    },
                  ].map((field, index) => (
                    <Flex
                      key={index}
                      style={{
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      [{field.label}] {field.value}
                    </Flex>
                  ))}
              </Flex>
            );
          }}
        </Field>

        <Field name={"incoterms"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["incoterms"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
                marginBottom={"8px"}
                marginTop={"4px"}
              >
                <FormLabel
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("incoterms"))}
                </FormLabel>
                <Box
                  borderBottom={"1px solid black"}
                  marginBottom={"8px"}
                ></Box>
                <AppInput value={"EX WORKS"} isDisabled={true} />
              </FormControl>
            );
          }}
        </Field>
        <Field name={"ETC"}>
          {() => {
            return (
              <Flex flexDirection={"column"} gap={"4px"}>
                <FormLabel
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  color={"black"}
                  margin={"0"}
                >
                  {toTitle(t("etc"))}
                </FormLabel>
                <Box
                  borderBottom={"1px solid black"}
                  marginBottom={"8px"}
                ></Box>
              </Flex>
            );
          }}
        </Field>

        <Field name={"issuedOn"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["issuedOn"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("issued_on"))}
                </FormLabel>
                <AppDatePicker
                  name={toTitle(t("common:issued_on"))}
                  value={props.field.value || new Date()}
                  showTimeInput={false}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"invoiceNumber"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["invoiceNumber"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("invoice_number"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"referenceInvoiceNumber"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["referenceInvoiceNumber"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("reference_invoice_number"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"markup"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["markup"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("markup"))}
                </FormLabel>
                <AppInput
                  value={props.field.value?.toString() || ""}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={!isEditing}
                  rightText={"%"}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"packageInfo"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["packageInfo"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("package_info"))}
                </FormLabel>
                <AppInput
                  value={props.field.value?.toString() || ""}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  isDisabled={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"comment"}>
          {(
            props: FieldProps<
              TriangularTradeFormFields["comment"],
              TriangularTradeFormFields
            >
          ) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                fontSize={"12px"}
                marginBottom={"8px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"black"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("comment"))}
                </FormLabel>
                <AppTextArea
                  flexGrow={"1"}
                  fontSize={"12px"}
                  rows={4}
                  placeholder={isEditing ? t("comment_placeholder") : undefined}
                  value={props.field.value || ""}
                  onChange={(e) => {
                    props.form.setFieldValue(props.field.name, e.target.value);
                  }}
                  isReadOnly={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>
      </Grid>
      {isCreateDepartureModelOpen && createDepartureModalData && (
        <CreateDepartureModal
          isOpen={isCreateDepartureModelOpen}
          onClose={onCreateDepartureModalClose}
          {...createDepartureModalData}
        />
      )}
      {isCreateIssuedByModelOpen && createIssuedByModalData && (
        <CreateIssuedByModal
          isOpen={isCreateIssuedByModelOpen}
          onClose={onCreateIssuedByModalClose}
          {...createIssuedByModalData}
        />
      )}
      {isCreateIssuedToModelOpen && createIssuedToModalData && (
        <CreateIssuedToModal
          isOpen={isCreateIssuedToModelOpen}
          onClose={onCreateIssuedToModalClose}
          {...createIssuedToModalData}
        />
      )}
      {isCreateBankInfoModelOpen && createBankInfoModalData && (
        <CreateBankInfoModal
          isOpen={isCreateBankInfoModelOpen}
          onClose={onCreateBankInfoModalClose}
          {...createBankInfoModalData}
        />
      )}
      {isCreateForwarderModelOpen && createForwarderModalData && (
        <CreateForwarderModal
          isOpen={isCreateForwarderModelOpen}
          onClose={onCreateForwarderModalClose}
          {...createForwarderModalData}
        />
      )}
      {isCreateFtaSignatureModelOpen && createFtaSignatureModalData && (
        <CreateFtaSignatureModal
          isOpen={isCreateFtaSignatureModelOpen}
          onClose={onCreateFtaSignatureModalClose}
          {...createFtaSignatureModalData}
        />
      )}
    </AppPanel>
  );
}
