import { CellEditRequestEvent, ICellRendererParams } from "ag-grid-community";
import {
  FlatProduct,
  isOverlappedValue,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import {
  isPriceAdjustedByType,
  PriceAdjustedBy,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { useCallback, useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Text } from "@chakra-ui/react";
import { AgGridUtils } from "@/features/ui/utils/ag-grid-utils";

export interface ConditionTypeCellProps {
  isEditable?: boolean;
}

export default function OverlappedConditionTypeCell(
  props: ConditionTypeCellProps &
    ICellRendererParams<
      FlatProduct,
      PriceAdjustedBy["by"] | OverlappedValue<PriceAdjustedBy["by"]>
    >
) {
  const { value, isEditable } = props;
  const { tTitle } = useI18nHelper();
  const [top, under] = useMemo(() => {
    if (isOverlappedValue(value, isPriceAdjustedByType)) {
      return [value.top, value.under] as const;
    } else {
      return [value, undefined];
    }
  }, [value]);

  const isContentOverlapped = under !== undefined && top != under;

  const options = useMemo((): AppSelectOption<PriceAdjustedBy["by"]>[] => {
    const values: PriceAdjustedBy["by"][] = ["MARKUP", "DISCOUNT"];
    return values.map((item) => {
      const str = item.substring(0, 1) + item.substring(1).toLowerCase();
      return {
        name: str,
        component:
          item != under && under ? (
            <Text textAlign={"center"} color={"#CC1D1D"}>
              {str}
            </Text>
          ) : (
            <Text textAlign={"center"}>{str}</Text>
          ),
        value: item,
      };
    });
  }, [under]);

  const handleSelect = useCallback(
    (newValue?: PriceAdjustedBy["by"]) => {
      AgGridUtils.dispatchCellEditEvent(newValue, props);
    },
    [props]
  );

  return (
    <AppSelect<PriceAdjustedBy["by"]>
      height={"22px"}
      name={tTitle("common:condition")}
      value={top}
      isLiteral={!isEditable}
      options={options}
      onSelect={handleSelect}
      isClearable={false}
    />
  );
}
