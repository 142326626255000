import {
  CompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { ProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";

export interface ListProformaInvoicesRequest
  extends PageNumberBasedListRequest {
  id__in?: number[];
  season__eq?: string;
  season__in?: string[];
  isRemittedToBoutique__eq?: boolean;
  isRemittedToAgency__eq?: boolean;
  validPaymentToBoutique__exists?: boolean;
  validPaymentToAgency__exists?: boolean;
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  "issuedByCompany.id__in"?: number[];
  // issuedByCompany__in?: CompanyId[];
  // issuedByCompany__eq?: CompanyId;
  "issuedThroughCompany.id__eq"?: number;
  issuedOn__eq?: Date;
  isPublic__eq?: boolean;
  unremitted_amount__gt?: number;
  status__eq?: ProformaInvoice["status"];
  status__in?: ProformaInvoice["status"][];
  "originallyIssuedByCompany.id__eq"?: number;
  lineSheetSetType__eq?: LineSheetSetType;
  "proformaInvoiceDetailList.brand.id__eq"?: number;
  "issuedByCompany.id__or__originallyIssuedByCompany.id__in"?: number[];
  paidBy__eq?: ProformaInvoice["paidBy"];
}

export interface ListProformaInvoicesResponse
  extends PageNumberBasedListResponse {
  proformaInvoices: ProformaInvoice[];
}

export interface ListProformaInvoicesPathParameter {
  by: CompanyType;
  companyId: number;
}

export const listProformaInvoicesTemplateUrl = `/:by/:companyId/proforma-invoices`;

export function getListProformaInvoicesKey(
  parameter?: ListProformaInvoicesPathParameter,
  request?: ListProformaInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listProformaInvoicesTemplateUrl, parameter),
      params: request,
    };
  }

  return null;
}

export const DEFAULT_LIST_PROFORMA_INVOICES_PAGE_SIZE = 10;

export default function useListProformaInvoices(
  parameter?: ListProformaInvoicesPathParameter,
  request?: ListProformaInvoicesRequest,
  config?: SWRConfiguration<ListProformaInvoicesResponse>
) {
  const fetcher = useFetcher<
    ListProformaInvoicesRequest,
    ListProformaInvoicesResponse
  >();
  return useSWR(
    getListProformaInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
