import {
  Box,
  Button,
  ChakraProps,
  Flex,
  Grid,
  GridItem,
  Spinner,
  SystemStyleObject,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import AppFlatCardSpacer from "@/features/line-sheet-sets/app-flat-card-spacer";
import AppFlatCardContextMenu from "@/features/ui/app-flat-card-context-menu";
import AppInstructionTooltipLabel from "@/features/ui/app-toottip-label";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

type AppLineCardSize = "medium" | "small";
type AppLineCardVariant = "normal";

export interface AppFlatCardProps {
  level?: number;
  variant?: AppLineCardVariant;
  size?: "medium" | "small";
  isActive?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  chakraProps?: ChakraProps;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  actions?: AppLineCardAction[];
  checkbox?: {
    isChecked: boolean;
    isDisabled?: boolean;
    onChange: (checked: boolean) => void;
  };
  width?: string;
  extraContents?: ReactNode[];
  tooltip?: ReactElement;
  isTooltipOpen?: boolean;
}

export const CARD_CHECKBOX_SIZE = "20px";
export const CARD_MAIN_CONTENT_HEIGHT = "34px";
export const CARD_SPACER_WIDTH = "80px";

export const CARD_ACTION_WIDTH = "120px";
export const CARD_HEIGHT = "minmax(48px, auto)";

export interface AppLineCardAction {
  name: string;
  iconSrc?: string;
  isDisabled?: boolean;
  isMajor?: boolean;
  isLoading?: boolean;
  disabledMessage?: string;
  onClick: () => void;
  onHover?: (isEnter: boolean, self: AppLineCardAction) => void;
}

interface AppFlatCardState {}

function join(items: (string | undefined | string[])[]) {
  return items
    .flatMap((i) => {
      if (Array.isArray(i) && i.length > 0) {
        return i;
      } else if (i !== undefined) {
        return [i];
      }
      return undefined;
    })
    .filter((i) => i !== undefined)
    .join(" ");
}

function reduce(items: ((() => boolean) | any)[], start = 1): number {
  return items
    .filter((i): boolean => {
      if (typeof i === "function") {
        return i();
      } else {
        return i !== undefined && i !== null;
      }
    })
    .reduce((acc, i) => {
      return acc + 1;
    }, start);
}

export default function AppFlatCard({
  level = 1,
  isActive = false,
  chakraProps,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  actions = [],
  checkbox,
  extraContents,
  tooltip,
  isTooltipOpen,
}: AppFlatCardProps) {
  const [isMainContentHovered, setMainContentHovered] =
    useState<boolean>(false);

  const outerGridTemplates = useMemo(() => {
    return {
      columns: join([
        level > 1 ? (level - 1) * 80 + "px" : undefined,
        checkbox ? CARD_CHECKBOX_SIZE : undefined,
        "1fr",
      ]),
      rows: join([
        CARD_HEIGHT,
        extraContents && extraContents.length > 0 ? "auto" : undefined,
      ]),
    };
  }, [level, checkbox, extraContents]);

  const innerGridTemplates = useMemo(() => {
    return {
      // columns: join(["1fr", actions ? actions.map((i) => "96px") : undefined]),
      columns: join([
        "1fr",
        actions && actions.length > 0 ? CARD_ACTION_WIDTH : undefined,
      ]),
      rows: join([
        CARD_HEIGHT,
        extraContents && extraContents.length > 0 ? "auto" : undefined,
      ]),
    };
  }, [actions, extraContents]);

  const innerGridPosition = useMemo(() => {
    const columnStart = reduce([checkbox, () => level > 1]);

    return {
      column: `${columnStart} / -1`,
      row: "1 / 3",
    };
  }, [checkbox, level]);

  const handleMainContentMouseEnter = useMemo(() => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setMainContentHovered(true);
      if (onMouseEnter) {
        onMouseEnter(event);
      }
    };
  }, [setMainContentHovered, onMouseEnter]);

  const handleMainContentMouseLeave = useMemo(() => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setMainContentHovered(false);
      if (onMouseLeave) {
        onMouseLeave(event);
      }
    };
  }, [setMainContentHovered, onMouseLeave]);

  const hasExtraContents =
    extraContents !== undefined && extraContents.length > 0;

  const hasActions = actions && actions.length > 0;

  return (
    <Grid
      gridTemplateColumns={outerGridTemplates.columns}
      gridTemplateRows={outerGridTemplates.rows}
    >
      <AppFlatCardSpacer level={level} />
      {checkbox && (
        <AppCheckBox
          alignSelf={"center"}
          isDisabled={checkbox.isDisabled}
          isChecked={checkbox.isChecked}
          onChange={checkbox.onChange}
        />
      )}
      <Grid
        gridColumn={innerGridPosition.column}
        gridRow={innerGridPosition.row}
        gridTemplateColumns={innerGridTemplates.columns}
        gridTemplateRows={innerGridTemplates.rows}
        //gridTemplateRows={"auto"}
        marginLeft={checkbox ? "12px" : "0"}
        boxShadow={
          isMainContentHovered || isActive
            ? "0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
            : "0px 2px 4px 0px rgba(0, 0, 0, 0.10)"
        }
        borderTopLeftRadius={"8px"}
        borderTopRightRadius={hasActions ? "0" : "8px"}
        borderBottomRightRadius={hasExtraContents ? "8px" : "8px"}
        borderBottomLeftRadius={hasExtraContents ? "8px" : "8px"}
      >
        <Tooltip
          label={tooltip ? tooltip : null}
          isOpen={isTooltipOpen || false}
          padding={"0"}
          placement={"bottom-start"}
          overflow={"hidden"}
        >
          <Flex
            flexDirection={"row"}
            // justifyContent={"center"}
            fontSize={"12px"}
            alignItems={"center"}
            padding={"8px"}
            cursor={"pointer"}
            border={
              isMainContentHovered || isActive
                ? "0.5px solid #1272EF"
                : "0.5px solid #8F8F8C"
            }
            // borderRadius={
            //   extraContents && extraContents.length > 0
            //     ? "4px 4px 0 0"
            //     : "4px 0 0 4px"
            // }
            borderTopLeftRadius={"8px"}
            borderTopRightRadius={hasActions ? "0" : "8px"}
            borderBottomRightRadius={hasActions ? "0" : "8px"}
            borderBottomLeftRadius={hasExtraContents ? "0" : "8px"}
            background={isMainContentHovered || isActive ? "#F7F9FA" : "#FFF"}
            {...chakraProps}
            onClick={onClick}
            onMouseEnter={handleMainContentMouseEnter}
            onMouseLeave={handleMainContentMouseLeave}
          >
            {children}
          </Flex>
        </Tooltip>
        {/*{actionComponents}*/}
        {actions && actions.length > 0 && (
          <AppFlatCardContextMenu
            actions={actions}
            hasExtraContents={extraContents && extraContents.length > 0}
          />
        )}
        {extraContents && extraContents.length > 0 && (
          <Flex
            fontSize={"12px"}
            whiteSpace={"pre-line"}
            gridColumn={"1/-1"}
            background={"var(--White-Snow, #FCFCFB)"}
            flexDirection={"column"}
            borderTopWidth={"0"}
            onMouseEnter={handleMainContentMouseEnter}
            onMouseLeave={handleMainContentMouseLeave}
            borderBottomRightRadius={"8px"}
            borderBottomLeftRadius={"8px"}
          >
            {extraContents.map((extraContent, index) => {
              const isLast = index === extraContents.length - 1;
              const borderRadius = isLast ? "0 0 8px 8px" : "0";
              const border = `0.5px solid ${
                isMainContentHovered || isActive ? "#1272EF" : "#8F8F8C"
              }`;
              return (
                <Box
                  key={"AppFlatCardExtraContent_" + index}
                  padding={"8px 8px 8px 28px"}
                  //borderTopWidth={"0"}
                  borderBottom={border}
                  borderLeft={border}
                  borderRight={border}
                  borderRadius={borderRadius}
                >
                  {extraContent}
                </Box>
              );
            })}
          </Flex>
        )}
      </Grid>
    </Grid>
  );
}
