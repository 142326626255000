import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";

export interface ReadProformaInvoiceRequest {}

export interface ReadProformaInvoiceResponse extends LightLineSheet {}

interface ReadProformaInvoicePathParameter extends IdentityParameter {
  proformaInvoiceId: number;
}

export const readProformaInvoiceTemplateUrl =
  "/:by/:companyId/proforma-invoices/:proformaInvoiceId/read";

export const readProformaInvoiceKey: YWRKey<ReadProformaInvoicePathParameter> =
  {
    url: (parameter: ReadProformaInvoicePathParameter) => {
      return calcUrl(readProformaInvoiceTemplateUrl, parameter);
    },
    method: "post",
  };

export function parseReadProformaInvoiceKey(
  config: AxiosRequestConfig<ReadProformaInvoiceRequest>
) {
  const urlParser = new UrlParser<ReadProformaInvoicePathParameter>(
    readProformaInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      proformaInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(config.url!!),
    method: readProformaInvoiceKey.method,
  };
}

export default function useReadProformaInvoice() {
  const fetcher = useFetcher<
    ReadProformaInvoiceRequest,
    ReadProformaInvoiceResponse
  >();
  return useYWR(readProformaInvoiceKey, fetcher);
}
