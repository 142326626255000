import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { Departure } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";

interface CreateDepartureRequest {
  alias: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  detailAddress: string;
  zipCode: string;
}

interface CreateDepartureResponse extends Departure {}

interface CreateDeparturePathParameter {
  by: CompanyType;
  companyId: number;
}

export const createDepartureTemplateUrl = `/:by/:companyId/departure`;

export const createDepartureKey: YWRKey<CreateDeparturePathParameter> = {
  url: (parameters: CreateDeparturePathParameter) => {
    return calcUrl(createDepartureTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateDeparture() {
  const fetcher = useFetcher<CreateDepartureRequest, CreateDepartureResponse>();
  return useYWR(createDepartureKey, fetcher);
}
