import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import {
  ListDenseOrderSheetSetsPathParameter,
  ListDenseOrderSheetSetsRequest,
} from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import useSWR, { SWRConfiguration } from "swr";
import useListDenseOrderSheetSetsFetcher from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-sets-fetcher";
import { useMemo } from "react";

export interface ListAllDenseOrderSheetSetsRequest
  extends Omit<ListDenseOrderSheetSetsRequest, "pageSize" | "pageNumber"> {}
export type ListAllDenseOrderSheetSetsResponse = DenseOrderSheetSet[];
export interface ListAllDenseOrderSheetSetsPathParameter
  extends ListDenseOrderSheetSetsPathParameter {}

export const listAllDenseOrderSheetSetsTemplateUrl = (() => {
  let url = "";
  url += `/:by/:companyId/dense-order-sheet-sets`;
  return url;
})();

export function getListAllDenseOrderSheetSetsKey(
  parameter?: ListAllDenseOrderSheetSetsPathParameter,
  request?: ListAllDenseOrderSheetSetsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllDenseOrderSheetSetsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListAllDenseOrderSheetSets(
  parameter?: ListAllDenseOrderSheetSetsPathParameter,
  request?: ListAllDenseOrderSheetSetsRequest,
  config?: SWRConfiguration
) {
  const listDenseOrderSheetSetsFetcher = useListDenseOrderSheetSetsFetcher();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      listDenseOrderSheetSetsFetcher,
      (response) => response.orderSheetSets
    );
  }, [listDenseOrderSheetSetsFetcher]);

  return useSWR(
    getListAllDenseOrderSheetSetsKey(parameter, request),
    fetcher,
    config
  );
}
