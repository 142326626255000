import { PriceInputValue } from "@/features/invoices/app-price-input";
import { MathUtils } from "@/features/ui/utils/math-utils";
import { Price } from "@/features/types";

export default class PriceUtils {
  static distribute<T extends {}>(
    total: PriceInputValue | undefined,
    items: T[],
    getter: (item: T) => PriceInputValue | undefined,
    setter: (item: T, value: PriceInputValue | undefined) => void,
    currency: Price["currency"]
  ): T[] {
    const length = items.length;
    const values = items.map(getter);
    const totalSum = PriceUtils.sum(values, currency);

    if (total?.value === undefined) {
      return items.map((item) => {
        let copy = {
          ...item,
        };
        const value = { currency };
        setter(copy, value);
        return copy;
      });
    } else if (totalSum.value === 0 || totalSum.value === undefined) {
      const share = MathUtils.trim(total.value / length);
      let actualSum = 0;
      return items.map((item, index) => {
        let copy = {
          ...item,
        };

        if (index === length - 1) {
          //last
          setter(copy, {
            value: MathUtils.trim((total.value || 0) - actualSum),
            currency: total.currency,
          });
        } else {
          const value = MathUtils.trim(share);
          setter(copy, { value, currency: total.currency });
          actualSum += value;
        }
        return copy;
      });
    } else {
      let actualSum = 0;
      return items.map((item, index) => {
        const copy = {
          ...item,
        };

        if (index === length - 1) {
          //last
          setter(copy, {
            value: MathUtils.trim((total.value || 0) - actualSum),
            currency: total.currency,
          });
        } else {
          const value = MathUtils.trim(
            ((total.value || 0) * (values[index]?.value || 0)) / totalSum.value!
          );
          setter(copy, { value, currency: total.currency });
          actualSum += value;
        }
        return copy;
      });
    }
  }

  static sum(
    items: (PriceInputValue | undefined)[],
    currency: Price["currency"]
  ): PriceInputValue {
    if (items.length === 0) {
      return {
        currency,
      };
    }

    let sum = 0;
    let allUndefined = true;
    let initialCurrency: Price["currency"] | "EA" | "%" | undefined;

    for (const item of items) {
      if (item === undefined || item.value === undefined) {
      } else {
        allUndefined = false;

        if (initialCurrency === undefined) {
          initialCurrency = item.currency!;
        } else if (initialCurrency !== item.currency) {
          //throw new Error("currency mismatch");
          console.warn("currency mismatch");
        }

        sum += item.value;
      }
    }

    if (allUndefined) {
      return { value: undefined, currency };
    }

    return { value: MathUtils.trim(sum), currency };
  }

  static minus(
    items: (PriceInputValue | undefined)[],
    currency: Price["currency"]
  ): PriceInputValue {
    if (items.length === 0) {
      return {
        currency,
      };
    }

    const initialCurrency =
      items.find((item) => item !== undefined)?.currency || currency;

    const result = items[0] ? { ...items[0] } : { currency: initialCurrency };

    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      if (!item) {
        continue;
      }

      if (currency !== item.currency) {
        // throw new Error("currency mismatch");
        console.warn("currency mismatch");
      }

      if (result?.value === undefined && item.value === undefined) {
        result.value = undefined;
      } else {
        result.value = MathUtils.trim((result?.value || 0) - (item.value || 0));
      }
    }

    return result;
  }

  static placeholder(
    value: PriceInputValue | undefined,
    currency: Price["currency"]
  ): Price {
    return value
      ? ({
          value: value.value || 0,
          currency: value.currency,
        } as Price)
      : {
          value: 0,
          currency,
        };
  }
}
