import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";
import { CreateDepositInvoiceRequest } from "@/features/invoices/credit-invoices/hooks/use-create-deposit-invoice";
import { IdentityParameter } from "@/features/types";

export interface BulkCreateDepositInvoicesRequest {
  depositInvoices: CreateDepositInvoiceRequest[];
}

export interface BulkCreateDepositInvoicesResponse {
  depositInvoices: DepositInvoice[];
}

export interface BulkCreateDepositInvoicesPathParameter
  extends IdentityParameter {}

export const bulkCreateDepositInvoicesTemplateUrl = `/:by/:companyId/deposit-invoices/bulk-create`;

export const bulkCreateDepositInvoicesKey: YWRKey<BulkCreateDepositInvoicesPathParameter> =
  {
    url: (parameters) => {
      return calcUrl(bulkCreateDepositInvoicesTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useBulkCreateDepositInvoices(
  config?: AxiosRequestConfig<BulkCreateDepositInvoicesRequest>
) {
  const fetcher = useFetcher<
    BulkCreateDepositInvoicesRequest,
    BulkCreateDepositInvoicesResponse
  >();

  return useYWR(bulkCreateDepositInvoicesKey, fetcher, config);
}
