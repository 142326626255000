import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReportLineSheetRequest {}
export interface ReportLineSheetResponse extends LightLineSheetSet {}

export interface ReportLineSheetParameter {
  agencyId: number;
  lineSheetSetId: number;
  lineSheetId: number;
}

export const reportLineSheetTemplateUrl =
  "/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/line-sheets/:lineSheetId/force-failure";

export const reportLineSheetKey: YWRKey<ReportLineSheetParameter> = {
  url: (parameter) => calcUrl(reportLineSheetTemplateUrl, parameter),
  method: "post",
};

export function parseReportLineSheetKey(url: string) {
  const urlParser = new UrlParser<ReportLineSheetParameter>(
    reportLineSheetTemplateUrl,
    {
      agencyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      lineSheetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReportLineSheet() {
  const fetcher = useFetcher<ReportLineSheetRequest, ReportLineSheetResponse>();
  return useYWR(reportLineSheetKey, fetcher);
}
