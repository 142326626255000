import { IdentityParameter, StoredObject } from "@/features/types";
import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import useFetcher from "@/utils/use-fetcher";
import { CreateDepositInvoiceRequest } from "@/features/invoices/credit-invoices/hooks/use-create-deposit-invoice";

export interface UpdateDepositInvoiceRequest
  extends CreateDepositInvoiceRequest {
  id: number;
}
export interface UpdateDepositInvoiceResponse extends DepositInvoice {}
export interface UpdateDepositInvoicePathParameter extends IdentityParameter {
  depositInvoiceId: number;
}
export const updateDepositInvoiceTemplateUrl = `/:by/:companyId/deposit-invoices/:depositInvoiceId`;

export const updateDepositInvoiceKey: YWRKey<UpdateDepositInvoicePathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateDepositInvoiceTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateDepositInvoice(
  config?: AxiosRequestConfig<UpdateDepositInvoiceRequest>
) {
  const fetcher = useFetcher<
    UpdateDepositInvoiceRequest,
    UpdateDepositInvoiceResponse
  >();
  return useYWR(updateDepositInvoiceKey, fetcher, config);
}
