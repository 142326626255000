export class MathUtils {
  static distribute<T extends {}>(
    total: number | undefined,
    items: T[],
    getter: (item: T) => number | undefined,
    setter: (item: T, value: number | undefined) => void
  ) {
    const length = items.length;
    const values = items.map(getter);
    const totalSum = MathUtils.trim(
      values
        .map((value) => value || 0)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0)
    );

    if (total === undefined) {
      return items.map((item, index) => {
        let copy = {
          ...item,
        };
        const value = undefined;
        setter(copy, value);
        return copy;
      });
    } else if (totalSum === 0) {
      const share = MathUtils.trim(total / length);
      return items.map((item, index) => {
        let copy = {
          ...item,
        };
        const value = values[index];
        setter(copy, value === undefined ? undefined : share);
        return copy;
      });
    } else {
      let actualSum = 0;
      return items.map((item, index) => {
        let copy = {
          ...item,
        };

        if (index === length - 1) {
          //last
          setter(copy, MathUtils.trim(total - actualSum));
        } else {
          const value = MathUtils.trim(
            (total * (values[index] || 0)) / totalSum
          );
          setter(copy, value);
          actualSum += value;
        }

        return copy;
      });
    }
  }

  static sum(items: (number | undefined)[]) {
    const value = items.reduce((acc, cur) => {
      return acc !== undefined || cur !== undefined
        ? (acc || 0) + (cur || 0)
        : undefined;
    }, undefined);

    if (value !== undefined) {
      return MathUtils.trim(value);
    }

    return value;
  }

  static trim(aNumber: number, digits: number = 2) {
    const factor = Math.pow(10, digits);
    return Math.round(aNumber * factor) / factor;
  }

  static trim2<T extends {}>(raw: T): T {
    const result = {} as T;
    for (const key in raw) {
      if (typeof raw[key] === "number") {
        // @ts-ignore
        result[key] = MathUtils.trim(raw[key] as number);
      } else {
        result[key] = raw[key];
      }
    }
    return result;
  }
}
