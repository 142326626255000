import { RecoilState, useRecoilCallback } from "recoil";
import {
  GridRow,
  gridRowUpdater,
} from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

export default function useAppGridMutate<T extends GridRow>(
  recoilState: RecoilState<T[] | undefined>
) {
  const discloseTooltip = useRecoilCallback(
    ({ set }) =>
      (row: GridRow, isOpen: boolean) => {
        set(recoilState, (draft) => {
          return gridRowUpdater(draft, row.id, row._rowType, (row) => {
            return {
              ...row,
              isTooltipOpen: isOpen,
            };
          });
        });
      },
    [recoilState]
  );

  const toggleCollapse = useRecoilCallback(
    ({ set }) =>
      (row: GridRow) => {
        set(recoilState, (draft) => {
          return gridRowUpdater(draft, row.id, row._rowType, (row) => {
            return {
              ...row,
              isCollapsed: !row.isCollapsed,
              isActive: !row.isActive,
            };
          });
        });
      },
    [recoilState]
  );

  const toggleCollapseIfRoot = useRecoilCallback(
    () => (row: GridRow) => {
      const isRoot = row._rowId.split("__").length - 1 === 1;
      if (isRoot) {
        toggleCollapse(row);
      }
    },
    [toggleCollapse]
  );

  const toggleCheck = useRecoilCallback(
    ({ set }) =>
      (row: GridRow) => {
        set(recoilState, (draft) => {
          return gridRowUpdater(draft, row.id, row._rowType, (row) => {
            return {
              ...row,
              isChecked: !row.isChecked,
            };
          });
        });
      },
    [recoilState]
  );

  const hover = useRecoilCallback(
    ({ set }) =>
      (row: GridRow, isEnter: boolean) => {
        set(recoilState, (draft) => {
          return gridRowUpdater(draft, row.id, row._rowType, (row) => {
            return {
              ...row,
              isHovered: isEnter,
            };
          });
        });
      },
    [recoilState]
  );

  return {
    discloseTooltip,
    toggleCollapse,
    toggleCollapseIfRoot,
    toggleCheck,
    hover,
  };
}
