import {
  isOverlappedValue,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { useMemo } from "react";
import { Flex, Text } from "@chakra-ui/react";

interface OverlappedBillBoardCellProps {
  value: OverlappedValue<number | string> | number | string;
}

export default function OverlappedBillBoardCell({
  value,
}: OverlappedBillBoardCellProps) {
  const [top, under] = useMemo(() => {
    if (isOverlappedValue(value, (item) => item !== undefined)) {
      return [value.top, value.under] as const;
    } else {
      return [value, undefined];
    }
  }, [value]);

  const isContentOverlapped = under !== undefined && top != under;

  return (
    <Flex flexDirection={"row"} justifyContent={"end"} overflow={"hidden"}>
      <Text
        flexGrow={"1"}
        fontWeight={"700"}
        textOverflow={"ellipsis"}
        whiteSpace={"nowrap"}
        textAlign={"right"}
        color={isContentOverlapped ? "#CC1D1D" : undefined}
      >
        {top}
      </Text>
      {isContentOverlapped && (
        <>
          <Text color={"#8F8F8C"}>(</Text>
          <Text
            as={"del"}
            color={"#8F8F8C"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
          >
            {under}
          </Text>
          <Text color={"#8F8F8C"}>)</Text>
        </>
      )}
    </Flex>
  );
}
