import { Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import useIdentity from "@/features/ui/hooks/use-identity";
import { getDefaultPageByIdentity } from "@/pages";

export default function AppNavigationLogo({
  collapsed,
}: {
  collapsed: boolean;
}) {
  const identity = useIdentity();
  const to = getDefaultPageByIdentity(identity);

  const src = collapsed ? "/logo__collapsed.svg" : "/logo.svg";
  return (
    <Link to={to}>
      <Image
        src={src}
        height={"24px"}
        width={"128px"}
        paddingLeft={"12px"}
        alt="Pathrade Logo"
      />
    </Link>
  );
}
