import { usePersistedQuery } from "@/features/order-sheet-sets/hooks/use-persisted-query";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { useCallback } from "react";
import { ListGmailsRequest } from "@/features/gmails/hooks/use-list-gmails";

type PersistedQuery = Omit<ListGmailsRequest, "pageSize">;
export default function usePersistedGmailListQuery() {
  const { value, set, clear } = usePersistedQuery<PersistedQuery>({
    entries: {
      pageNumber: {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
    },
  });

  const patch = useCallback(
    <T>(key: keyof T, value: T[keyof T]) => {
      set((prev) => {
        return {
          ...prev,
          [key]: value,
          pageNumber: 1,
        };
      });
    },
    [set]
  );

  return { value, patch, set, clear };
}
