import { useMemo } from "react";
import { PaymentMethod } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";

interface AppPaymentMethodSelectProps {
  value?: PaymentMethod;
  onSelect?: (value?: PaymentMethod) => void;
  isDisabled?: boolean;
  excludeSkip?: boolean;
  isReadOnly?: boolean;
}

export default function AppPaymentMethodSelect({
  value,
  onSelect,
  isDisabled = false,
  excludeSkip = false,
  isReadOnly = false,
}: AppPaymentMethodSelectProps) {
  const { tTitle } = useI18nHelper();
  const options = useMemo((): AppSelectOption<PaymentMethod>[] => {
    const values: PaymentMethod[] = ["NORMAL", "LOAN", "SKIP"];
    return values
      .filter((value) => !(excludeSkip && value === "SKIP"))
      .map((value) => {
        return {
          name: tTitle(`payment_method.${toLower(value)}`),
          value,
        };
      });
  }, [tTitle, excludeSkip]);

  return (
    <AppSelect<PaymentMethod>
      width={"100%"}
      name={tTitle("payment_method")}
      isDisabled={isDisabled}
      options={options}
      value={value}
      onSelect={onSelect}
      isReadOnly={isReadOnly}
    />
  );
}
