import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper, {
  YupMessageParameter,
} from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import * as Yup from "yup";
import { Company, StoredObject } from "@/features/types";
import { PaymentMethod } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { LinkedOrderSheetSet } from "@/features/invoices/app-order-sheet-set-selector";
import { differenceBy } from "lodash";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import useIdentity from "@/features/ui/hooks/use-identity";

export default function useOrderConfirmationInvoiceFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const { withMessage } = useYupHelper({
    ns: "order_confirmation_invoices",
    prefix: "upload_order_confirmation_invoices_form",
  });

  const identity = useIdentity();

  return useMemo(() => {
    return Yup.object<OrderConfirmationInvoiceFormFields>({
      name: Yup.string().required(withMessage.required),
      number: Yup.string().required(withMessage.required),
      season: Yup.string().required(withMessage.required),
      orderedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedBy: Yup.mixed<Company>().required(withMessage.required),
      issuedThrough: Yup.mixed<Company>().when({
        is: () => {
          const type = identity?.company?.type;
          return type === "AGENCY" || type === "BUYER";
        },
        then: (schema) => schema.required(withMessage.required),
      }),
      issuedOn: Yup.date().required(withMessage.required),
      paymentMethod: Yup.mixed<PaymentMethod>().required(withMessage.required),
      exposedToBuyer: Yup.boolean().required(withMessage.required),
      paidByAgency: Yup.boolean().required(withMessage.required),
      orderSheetSets: Yup.array<LinkedOrderSheetSet>().test(
        (value, context) => {
          if (value && value.length > 0) {
            const orderSheetSets =
              value as OrderConfirmationInvoiceFormFields["orderSheetSets"];

            const { orderConfirmationInvoiceDetailList } =
              context.parent as OrderConfirmationInvoiceFormFields;

            const diff = differenceBy(
              orderSheetSets.map((oss) => oss.id),
              orderConfirmationInvoiceDetailList.map(
                (ocDetail) => ocDetail.orderSheetSet?.id
              )
            );
            if (diff.length > 0) {
              return context.createError({
                message: t(
                  "proforma_invoices:upload_proforma_invoices_form.messages.error.unlinked_order_sheet_set",
                  { id: diff[0] }
                ),
              });
            }
          }
          return true;
        }
      ),
      orderConfirmationInvoiceDetailList: Yup.array()
        .of(
          Yup.object<
            OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"]
          >({
            brand: Yup.mixed<Company>()
              .label("brand")
              .required(withMessage.required),
            amount: Yup.object({
              value: Yup.number()
                .required(withMessage.required)
                .min(0, withMessage.min),
              currency: Yup.string().required(withMessage.required),
            })
              .label("amount_by_brand")
              .required(withMessage.required),
            requiredAmount: Yup.object({
              value: Yup.number()
                .label("deposit_required_amount_by_brand.abbr")
                .required(withMessage.required)
                .min(0, withMessage.min),
              currency: Yup.string().required(withMessage.required),
            })
              .label("deposit_required_amount_by_brand")
              .test(
                "exceeding_required_deposit",
                t(
                  "upload_order_confirmation_invoices_form.messages.error.exceeding_required_deposit",
                  {
                    ns: "order_confirmation_invoices",
                  }
                ),
                (value, context) => {
                  if (value) {
                    const { amount } =
                      context.parent as OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"][number];
                    return value.value <= (amount?.value || 0);
                  }
                  return true;
                }
              ),
          })
        )
        .label("invoice_figures")
        .when({
          is: () => {
            return identity?.company?.type !== "BOUTIQUE";
          },
          then: (schema) =>
            schema.required(withMessage.required).min(1, withMessage.min),
        }),
      file: Yup.mixed<File | Pick<StoredObject, "id" | "name">>()
        .label("invoice_file")
        .required(withMessage.required),
    });
  }, [withMessage.required, withMessage.min, t, identity?.company?.type]);
}
