import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import useSWR, { SWRConfiguration } from "swr";
import { FooStatistic } from "@/features/order-sheets/order-sheet.type";
import useListOrderSheetStatisticsFetcher from "@/features/line-sheet-sets/hooks/use-list-order-sheet-statistics-fetcher";

export interface ListOrderSheetStatisticsRequest {
  orderSheetIds: number[];
}

export interface ListOrderSheetStatisticsResponse {
  orderSheetStatistics: ({
    orderSheetId: number;
  } & FooStatistic)[];
}

export interface ListOrderSheetStatisticsPathParameter
  extends IdentityParameter {
  orderSheetSetId: number;
}

export const listOrderSheetStatisticsTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheet-statistics`;

export function getListOrderSheetStatisticsKey(
  parameter?: ListOrderSheetStatisticsPathParameter,
  request?: ListOrderSheetStatisticsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listOrderSheetStatisticsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListOrderSheetStatisticsKey(
  key: AxiosRequestConfig<ListOrderSheetStatisticsRequest>
): {
  parameter: ListOrderSheetStatisticsPathParameter;
  request: ListOrderSheetStatisticsRequest;
} {
  const urlParser = new UrlParser<ListOrderSheetStatisticsPathParameter>(
    listOrderSheetStatisticsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export default function useListOrderSheetStatistics(
  parameter?: ListOrderSheetStatisticsPathParameter,
  request?: ListOrderSheetStatisticsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useListOrderSheetStatisticsFetcher();

  return useSWR(
    getListOrderSheetStatisticsKey(parameter, request),
    fetcher,
    config
  );
}
