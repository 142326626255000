import { ChakraProps, Flex, Text } from "@chakra-ui/react";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { AppGridContext } from "@/features/order-sheet-sets/app-grid";
import { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower, toUpper } from "@/utils/case";

function haveMajorAction(row: GridRow, context: AppGridContext): boolean {
  const meta = context.getMeta(row._rowType);
  if (meta) {
    const isMajor =
      meta.actions
        ?.map((func) => func(row))
        .find((i) => i?.isMajor === true && i?.isDisabled === false) !==
      undefined;
    if (!isMajor) {
      if (row.children.length > 0) {
        return row.children.some((i) => haveMajorAction(i, context));
      }
    }
    return isMajor;
  }
  return false;
}

interface AppLabelProps<T> {
  i18nPrefix?: string;
  keyOrFunc: keyof T | ((row: T) => string);
  row: T;
  isSuccessPredicate?: (row: T) => boolean;
  isFailedPredicate?: (row: T) => boolean;
  context: AppGridContext;
}

export default function AppStatusLabel<
  T extends GridRow & { isRead?: boolean }
>({
  i18nPrefix,
  keyOrFunc,
  row,
  context,
  isFailedPredicate,
  isSuccessPredicate,
}: AppLabelProps<T>) {
  const { tWithPrefix } = useI18nHelper();
  const t = row as T;
  const { isRead: _isRead, isActive } = t;
  const isRead = _isRead && !isActive;

  const value = useMemo(() => {
    let raw = "";

    if (typeof keyOrFunc === "function") {
      raw = keyOrFunc(t) || "";
    } else {
      raw = (t[keyOrFunc] as string) || "";
    }

    if (i18nPrefix && raw) {
      return tWithPrefix(i18nPrefix, toLower(raw), toUpper);
    } else {
      return raw;
    }
  }, [keyOrFunc, t, i18nPrefix, tWithPrefix]);

  const isFailed = isFailedPredicate ? isFailedPredicate(t) : false;
  let isSuccess = false;
  if (isSuccessPredicate) {
    isSuccess = isSuccessPredicate(t);
  } else {
    isSuccess = haveMajorAction(t, context);
  }

  const style: ChakraProps = {
    borderRadius: "2px",
    borderWidth: "1px",
    borderStyle: "solid",
  };

  if (isFailed) {
    if (!isRead) {
      style.borderColor = "#EE7D7A";
      style.backgroundColor = "#FFEEEE";
      style.color = "#444440";
    } else {
      style.borderColor = "#FFC9C9";
      style.backgroundColor = "#FCFCFB";
      style.color = "#6F6F6C";
    }
  } else if (isSuccess) {
    if (!isRead) {
      style.borderColor = "#16B41C";
      style.backgroundColor = "#F0FAEF";
      style.color = "#444440";
    } else {
      style.borderColor = "#82C284";
      style.backgroundColor = "#FCFCFB";
      style.color = "#6F6F6C";
    }
  } else {
    if (!isRead) {
      style.borderColor = "#8F8F8C";
      style.color = "#444440";
    } else {
      style.borderColor = "#D4D4D1";
      style.backgroundColor = "#FCFCFB";
      style.color = "#6F6F6C";
    }
  }

  if (!value) {
    return null;
  }

  return (
    <Flex flexDirection={"row"} alignItems={"center"} justifyContent={"start"}>
      <Text padding={"0 8px"} fontSize={"10px"} {...style}>
        {value}
      </Text>
    </Flex>
  );
}
