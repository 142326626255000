import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";
import {
  restoreLineSheetSetKey,
  RestoreLineSheetSetRequest,
  RestoreLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-restore-line-sheet-set";

export default function useRestoreLineSheetSetInfinite() {
  const fetcher = useFetcher<
    RestoreLineSheetSetRequest,
    RestoreLineSheetSetResponse
  >();
  return useYWRInfinite(restoreLineSheetSetKey, fetcher);
}
