import { mutate } from "swr";
import { produce } from "immer";

export function readProactively<T>(regex: RegExp, field: keyof T, id: number) {
  mutate(
    (key) => {
      if (key !== null && typeof key === "object" && "url" in key) {
        return key.url.match(regex);
      }
      return false;
    },
    (prev: T | undefined) => {
      if (prev) {
        return produce(prev, (draft) => {
          // @ts-ignore
          const items = draft[field];
          if (Array.isArray(items)) {
            const index = items.findIndex((item) => item.id === id);
            if (index > -1) {
              // @ts-ignore
              draft[field][index].isRead = true;
            }
          }
        });
      }
      return prev;
    },
    false
  );
}
