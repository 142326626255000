import {
  LineSheetSetStatus,
  LineSheetStatus,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { toLower } from "@/utils/case";
import AppLabel from "@/features/ui/app-label";

interface AppLineSheetStatusLabelProps {
  value: LineSheetSetStatus;
}
export default function AppLineSheetSetStatusLabel({
  value,
}: AppLineSheetStatusLabelProps) {
  return (
    <AppLabel
      nameKey={`line_sheet_set.status.${toLower(value)}`}
      level={(() => {
        if (value === "PARSING_FAILED") {
          return "ERROR";
        } else if (value === "PARSING_COMPLETED") {
          return "SUCCESS";
        }
        return "LIGHT";
      })()}
    />
  );
}
