import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Box, Flex, Grid, GridItem, Skeleton } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import AppPriceInput, {
  PriceInputValue,
} from "@/features/invoices/app-price-input";
import React, { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import OrderConfirmationInvoiceDetailsInput from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-details-input";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import { Field, FieldProps, FormikProps } from "formik";
import DetailBrandProvider from "@/features/invoices/proforma-invoices/detail-brand-provider";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import PriceUtils from "@/features/ui/utils/price-utils";

interface OrderConfirmationInvoiceFigureFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
}

interface FormFields {
  id?: number;
  totalRequiredAmount?: PriceInputValue;
  orderConfirmationInvoiceDetailList: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"];
  isRemitted?: boolean;
  orderSheetSets?: OrderConfirmationInvoiceFormFields["orderSheetSets"];
  issuedBy?: OrderConfirmationInvoiceFormFields["issuedBy"];
}

export default function OrderConfirmationInvoiceFigureFormControl<
  T extends FormFields
>({
  formik,
  isEditing = false,
  isDisabled = false,
}: OrderConfirmationInvoiceFigureFormControlProps<T>) {
  const { t, tTitle } = useI18nHelper();
  const negativeSequencer = useMemo(() => {
    return new NegativeSequencer();
  }, []);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          paddingRight={"12px"}
          gridTemplateColumns={"repeat(3, 1fr)"}
          gridTemplateRows={"20px 24px repeat(2, 20px 24px)"}
          gridColumnGap={"8px"}
          gridRowGap={"4px"}
        >
          <Box />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton gridColumn={"1/4"} />
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:invoice_figures")}>
      <Flex gap={"12px"} flexDirection={"column"}>
        <Field name={"orderConfirmationInvoiceDetailList"}>
          {(
            props: FieldProps<
              FormFields["orderConfirmationInvoiceDetailList"],
              FormFields
            >
          ) => {
            return (
              <DetailBrandProvider
                orderConfirmationInvoices={[]}
                orderSheetSets={props.form.values.orderSheetSets || []}
                issuedBy={props.form.values.issuedBy}
                negativeSequencer={negativeSequencer}
              >
                <OrderConfirmationInvoiceDetailsInput
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  isDisabled={isDisabled}
                  onChange={(value) => {
                    props.form.setValues((prev) => {
                      return {
                        ...prev,
                        orderConfirmationInvoiceDetailList: value,
                        // orderConfirmationInvoiceDetailList:
                        //   PriceUtils.distribute(
                        //     prev.totalRequiredAmount || { currency: "EUR" },
                        //     value,
                        //     (item) => item.amount || { currency: "EUR" },
                        //     (item, value) => {
                        //       item.requiredAmount = value;
                        //     },
                        //     "EUR"
                        //   ),
                      };
                    });
                  }}
                  negativeSequencer={negativeSequencer}
                />
              </DetailBrandProvider>
            );
          }}
        </Field>
        <Grid
          gridTemplateColumns={"repeat(3, 1fr) 12px"}
          gridTemplateRows={"20px 24px"}
          gridColumnGap={"8px"}
          gridRowGap={"4px"}
        >
          <Box></Box>
          <GridItem fontSize={"12px"}>{toTitle(t("total_amount"))}</GridItem>
          <GridItem fontSize={"12px"}>
            {toTitle(t("total_deposit_required_amount.abbr"))}
          </GridItem>
          <Box></Box>

          <Box></Box>
          <GridItem>
            <AppPriceInput
              value={PriceUtils.sum(
                formik.values.orderConfirmationInvoiceDetailList?.map(
                  (ocDetail) => ocDetail.amount
                ),
                "EUR"
              )}
              currency={"EUR"}
              isDisabled={isEditing}
              isReadOnly={!isEditing}
            />
          </GridItem>
          <GridItem>
            <Field name={"totalRequiredAmount"}>
              {(
                props: FieldProps<FormFields["totalRequiredAmount"], FormFields>
              ) => {
                return (
                  <AppPriceInput
                    value={props.field.value}
                    onChange={(value) => {
                      props.form.setValues((prev) => {
                        return {
                          ...prev,
                          totalRequiredAmount: value,
                        };
                      });
                    }}
                    isReadOnly={!isEditing}
                    isDisabled={isDisabled}
                  />
                );
              }}
            </Field>
          </GridItem>
        </Grid>
      </Flex>
    </AppPanel>
  );
}
