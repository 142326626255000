import useFetcher from "@/utils/use-fetcher";
import {
  getListProformaInvoicesKey,
  ListProformaInvoicesRequest,
  ListProformaInvoicesResponse,
} from "@/features/invoices/proforma-invoices/hooks/use-list-proforma-invoices";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDenseProformaInvoicesRequest,
  ListDenseProformaInvoicesResponse,
  parseListDenseProformaInvoicesKey,
} from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";
import useCondenseBankRemittanceReceipts from "@/features/invoices/order-confirmation-invoices/hooks/use-condense-bank-remittance-receipts";

export default function useListDenseProformaInvoicesFetcher() {
  const listProformaInvoicesFetcher = useFetcher<
    ListProformaInvoicesRequest,
    ListProformaInvoicesResponse
  >();

  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();
  const condenseOrderSheetSets = useCondenseOrderSheetSets();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseProformaInvoicesRequest>
    ): Promise<ListDenseProformaInvoicesResponse> => {
      const { parameter, request } = parseListDenseProformaInvoicesKey(config);
      const listProformasResponse = await listProformaInvoicesFetcher(
        getListProformaInvoicesKey(parameter, request)!
      );

      if (listProformasResponse.proformaInvoices.length > 0) {
        const promise1 = condenseOrderConfirmationInvoices(
          listProformasResponse.proformaInvoices,
          (item) => item.orderConfirmationInvoices,
          (item, value) => {
            if (Array.isArray(value)) {
              item.orderConfirmationInvoices = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        const promise2 = condenseOrderSheetSets(
          listProformasResponse.proformaInvoices,
          (item) => item.orderSheetSets,
          (item, value) => {
            if (Array.isArray(value)) {
              item.orderSheetSets = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        await Promise.all([promise1, promise2]);
      }

      // @ts-ignore
      return Promise.resolve(listProformasResponse);
    };
  }, [
    listProformaInvoicesFetcher,
    condenseOrderConfirmationInvoices,
    condenseOrderSheetSets,
  ]);
}
