import {
  Company,
  CompanyType,
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { getUrlPrefixByIdentityParameter } from "@/features/ui/helpers/zwr-helpers";
import useSWR, { SWRConfiguration } from "swr";
//
// export interface ListCompaniesWithoutPagingRequest {
//   type__in?: CompanyType[];
// }

export interface ListCompaniesRequest extends PageNumberBasedListRequest {
  type__in?: CompanyType[];
}

export interface ListCompaniesResponse extends PageNumberBasedListResponse {
  companies: Company[];
}

export interface ListCompaniesPathParameter extends IdentityParameter {}

export function getListCompaniesPathParameter(
  identity: Identity | null
): ListCompaniesPathParameter | null {
  return toIdentityParameter(identity);
}

export function getListCompaniesKey(
  identityOrParameter?: Identity | ListCompaniesPathParameter | null,
  request?: ListCompaniesRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListCompaniesPathParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    const prefix = getUrlPrefixByIdentityParameter(parameter);
    return {
      url: `${prefix}/companies`,
      params: request,
    };
  }
  return null;
}

export default function useListCompanies(
  parameter?: ListCompaniesPathParameter | null,
  request?: ListCompaniesRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<ListCompaniesRequest, ListCompaniesResponse>();
  return useSWR(getListCompaniesKey(parameter, request), fetcher, config);
}
