import {
  getListCreditInvoicesKey,
  ListCreditInvoicesPathParameter,
  ListCreditInvoicesRequest,
  ListCreditInvoicesResponse,
} from "@/features/invoices/credit-invoices/hooks/use-list-credit-invoices";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { ListProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-proforma-invoices";

export interface ListAllCreditInvoicesRequest
  extends Omit<ListCreditInvoicesRequest, "pageSize" | "pageNumber"> {}
export type ListAllCreditInvoicesResponse = CreditInvoice[];
export interface ListAllCreditInvoicesPathParameter
  extends ListCreditInvoicesPathParameter {}
export const listAllCreditInvoicesTemplateUrl = `/:by/:companyId/credit-invoices/list-all`;
export function getListAllCreditInvoicesKey(
  parameter?: ListAllCreditInvoicesPathParameter,
  request?: ListAllCreditInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllCreditInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
}

export function parseListAllCreditInvoicesKey(
  key: AxiosRequestConfig<ListAllCreditInvoicesRequest>
) {
  const urlParser = new UrlParser<ListAllCreditInvoicesPathParameter>(
    listAllCreditInvoicesTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllCreditInvoices(
  parameter?: ListAllCreditInvoicesPathParameter,
  request?: ListAllCreditInvoicesRequest,
  config?: SWRConfiguration<ListAllCreditInvoicesResponse>
) {
  const listCreditInvoicesFetcher = useFetcher<
    ListCreditInvoicesRequest,
    ListCreditInvoicesResponse
  >();

  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListProformaInvoicesRequest>) => {
        const { parameter, request } = parseListAllCreditInvoicesKey(config);
        return listCreditInvoicesFetcher(
          getListCreditInvoicesKey(parameter, request)
        );
      },
      (response) => {
        return response.creditInvoices;
      }
    );
  }, [listCreditInvoicesFetcher]);

  return useSWR(
    getListAllCreditInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
