import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import React, { useMemo } from "react";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { OrderByItem } from "@/features/types";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Box, Link, Text, TextProps } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import SVG from "react-inlinesvg";

interface AppLinkProps<T> {
  title: number | string;
  pathname: string;
  query: T;
}

export default function AppLink<
  T extends {
    [key: string]: any;
    pageNumber?: number;
  }
>({ pathname, query, title: rawTitle }: AppLinkProps<T>) {
  const { calcNavigateTo } = useRouterHelper();
  const { tCurrencyString } = useI18nHelper();
  const to = useMemo(() => {
    return calcNavigateTo(AppUtils.calcPureTo(pathname, query));
  }, [calcNavigateTo, pathname, query]);

  const title = useMemo(() => {
    if (typeof rawTitle === "number") {
      return tCurrencyString(rawTitle);
    }
    return rawTitle;
  }, [rawTitle, tCurrencyString]);

  const textProps = useMemo((): TextProps => {
    if (typeof rawTitle === "number" || title === "-") {
      return { textAlign: "end" };
    }
    return {};
  }, [rawTitle, title]);

  if (title === "-") {
    return <Box marginRight={"16px"}>{title}</Box>;
  }

  return (
    <Link
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      gap={"4px"}
      as={ReactRouterLink}
      to={to}
      _hover={{ "> SVG": { opacity: 1 }, color: "#1272EF" }}
    >
      <Text textDecoration={"underline"} {...textProps}>
        {title}
      </Text>
      <SVG
        src={"/icons/icon_arrow_circle_right.svg"}
        opacity={"0"}
        width={"1em"}
        height={"1em"}
        color={"#1272EF"}
      />
    </Link>
  );
}
