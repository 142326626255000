import { Helmet } from "react-helmet-async";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Link } from "react-router-dom";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { getDefaultPageByIdentity } from "@/pages";
import useIdentity from "../ui/hooks/use-identity";
import { getJwtPayload } from "@/utils/jwt";
import LandingNavigation from "./landing-navigation";
import LandingFooter from "./landing-footer";
import landingStyle from "./landing.module.scss";

export default function LandingPage() {
  const { t, i18n, Trans } = useI18nHelper();
  const date1 = new Date("08/10/2018");
  const date2 = new Date();
  const diffInTime = date2.getTime() - date1.getTime();
  const daysFromFoundingDate = Math.floor(diffInTime / (1000 * 3600 * 24));
  const days = daysFromFoundingDate.toLocaleString("en-US");
  const { calcNavigateTo } = useRouterHelper();
  const identity = useIdentity();
  const jwtPayload = getJwtPayload();
  const firstScope = jwtPayload?.scopes[0];
  let nextPageName: string, signedInClass: string | null;
  if (firstScope?.id) {
    nextPageName = "Dashboard";
    signedInClass = "signedIn";
  } else {
    nextPageName = "Sign In";
    signedInClass = null;
  }
  const contactUsLink =
    i18n.language === "ko"
      ? "https://pf.kakao.com/_CbLsG"
      : "mailto:support@unotrading.co.kr";
  return (
    <>
      <Helmet>
        <title>{t("landing:title")}</title>
        <meta property="og:title" content={t("landing:title")} />
        <meta name="description" content={t("landing:meta.description")} />
        <meta
          property="og:description"
          content={t("landing:meta.description")}
        />
        <meta property="twitter:title" content={t("landing:meta.title")} />
        <meta
          property="twitter:description"
          content={t("landing:meta.description")}
        />
      </Helmet>

      <div className={`landingWrapper ${landingStyle.landingWrapper}`}>
        <LandingNavigation
          nextPageName={nextPageName}
          signedInClass={signedInClass}
        />
        <header className={`${landingStyle.landingHeader}`}>
          <div className={`${landingStyle.headerContainer}`}>
            <div className={`${landingStyle.title}`}>
              <h1>
                <Trans i18nKey="landing:header.title" />
              </h1>
            </div>
            <div className={`${landingStyle.subtitle}`}>
              <p>
                <Trans i18nKey="landing:header.subtitle" />
              </p>
            </div>
            <section className={`${landingStyle.ctaSection}`}>
              <Link to={contactUsLink} className={"btn bgColorMain"}>
                <span className="shape"></span>
                <span className="slot">
                  {t("landing:header.cta.talk_to_sales")}
                </span>
              </Link>
              <Link
                to={calcNavigateTo(getDefaultPageByIdentity(identity, true))}
                className={"btn bgColorWhite"}
              >
                <span className="shape"></span>
                <span className="slot">{t("landing:header.cta.start")}</span>
              </Link>
            </section>
          </div>
        </header>
        <main>
          <div className={`${landingStyle.mainContainer}`}>
            <section>
              <h2>
                <Trans
                  i18nKey="landing:section1.title"
                  values={{ days }}
                  components={{
                    1: (
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(90deg, #5CC3FA, #9E6EE6)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      />
                    ),
                  }}
                />
              </h2>
              <h3>{daysFromFoundingDate.toLocaleString("en-US")}</h3>
              <p>
                <Trans i18nKey="landing:section1.body" />
              </p>
            </section>
            <section>
              <h2>
                <Trans
                  i18nKey="landing:section2.title"
                  values={{ days }}
                  components={{
                    1: (
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(90deg, #FACC69, #FF91A5)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      />
                    ),
                  }}
                />
              </h2>
              <p>
                <Trans i18nKey="landing:section2.body" />
              </p>
            </section>
            <section>
              <h2>
                <Trans
                  i18nKey="landing:section3.title"
                  values={{ days }}
                  components={{
                    1: (
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(90deg, #FA75F8, #5CC3FA)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      />
                    ),
                  }}
                />
              </h2>
              <p>
                <Trans i18nKey="landing:section3.body" />
              </p>
            </section>
            <section>
              <h2>
                <Trans
                  i18nKey="landing:section4.title"
                  values={{ days }}
                  components={{
                    1: (
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(90deg, #FF91A5, #5CC3FA)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      />
                    ),
                  }}
                />
              </h2>
              <p>
                <Trans i18nKey="landing:section4.body" />
              </p>
            </section>
            <section className={`${landingStyle.ctaSection}`}>
              <Link to={contactUsLink} className={"btn bgColorMain"}>
                <span className="shape"></span>
                <span className="slot">
                  {t("landing:header.cta.talk_to_sales")}
                </span>
              </Link>
              <Link
                to={calcNavigateTo(getDefaultPageByIdentity(identity, true))}
                className={"btn bgColorWhite"}
              >
                <span className="shape"></span>
                <span className="slot">{t("landing:header.cta.start")}</span>
              </Link>
            </section>
          </div>
        </main>
        <LandingFooter />
      </div>
    </>
  );
}
