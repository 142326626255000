import { BoutiqueConfirmationMetadata, LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface ConfirmByBoutiqueOrderSheetSetRequest {
  boutiqueConfirmationMetadataList: BoutiqueConfirmationMetadata[];
}
export interface ConfirmByBoutiqueOrderSheetSetResponse
  extends LightOrderSheetSet {}

export interface ConfirmByBoutiqueOrderSheetSetPathParameter {
  orderSheetSetId: number;
  agencyId: number;
}

export const confirmByBoutiqueOrderSheetSetsTemplateUrl =
  "/agencies/:agencyId/order-sheet-sets/:orderSheetSetId/confirm-by-boutique";

export const confirmByBoutiqueOrderSheetSetsKey: YWRKey<ConfirmByBoutiqueOrderSheetSetPathParameter> =
  {
    url: (parameter) => {
      return calcUrl(confirmByBoutiqueOrderSheetSetsTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useConfirmByBoutiqueOrderSheetSet() {
  const fetcher = useFetcher<
    ConfirmByBoutiqueOrderSheetSetRequest,
    ConfirmByBoutiqueOrderSheetSetResponse
  >();

  return useYWR(confirmByBoutiqueOrderSheetSetsKey, fetcher);
}
