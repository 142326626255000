import { IdentityParameter } from "@/features/types";
import { LineSheetTab } from "@/features/line-sheet-sets/line-sheet-set.types";
import { calcIdentityUrlPrefix } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface GetLineSheetTabRequest {}

export interface GetLineSheetTabResponse extends LineSheetTab {}

export interface GetLineSheetTabPathParameter extends IdentityParameter {
  lineSheetSetId: number;
  lineSheetId: number;
  lineSheetTabIndex: number;
}

export function getGetLineSheetTabKey(
  parameter: GetLineSheetTabPathParameter | null,
  request?: GetLineSheetTabRequest
) {
  if (parameter) {
    const { lineSheetSetId, lineSheetId, lineSheetTabIndex } = parameter;
    let url = calcIdentityUrlPrefix(parameter);
    url += `/line-sheet-sets/${lineSheetSetId}/line-sheets/${lineSheetId}/line-sheet-tabs/${lineSheetTabIndex}`;
    return {
      url,
      params: request,
    };
  }
  return null;
}

export default function useGetLineSheetTab(
  parameter: GetLineSheetTabPathParameter | null,
  request: GetLineSheetTabRequest = {},
  config: SWRConfiguration = {
    revalidateOnFocus: false,
  }
) {
  const fetcher = useFetcher<GetLineSheetTabRequest, GetLineSheetTabResponse>();
  return useSWR(getGetLineSheetTabKey(parameter, request), fetcher, config);
}
