import { usePersistedQuery } from "@/features/order-sheet-sets/hooks/use-persisted-query";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

interface InvoiceStatisticQuery {
  "invoiceStatisticIssuedByCompany.id__in"?: number[];
  "invoiceStatisticMadeByCompany.id__eq"?: number;
  invoiceStatisticSeason__in?: string[];
}

interface OrderStatisticQuery {
  "orderStatisticMadeByCompany.id__eq"?: number;
  orderStatisticSeason__in?: string[];
}

interface BalanceTransactionStatisticQuery {
  "balanceTransactionStatisticMadeByCompany.id__eq"?: number;
  balanceTransactionStatisticSeason__in?: string[];
}

export interface DashboardPagePersistedQuery
  extends InvoiceStatisticQuery,
    OrderStatisticQuery,
    BalanceTransactionStatisticQuery {}

export interface DashboardPagePersistedQuery extends InvoiceStatisticQuery {}

export default function useDashboardPagePersistedQuery() {
  const { value, set } = usePersistedQuery<DashboardPagePersistedQuery>({
    entries: {
      "invoiceStatisticIssuedByCompany.id__in": {
        parse: StringTransformer.parseIds,
        stringify: StringTransformer.stringifyIds,
      },
      "invoiceStatisticMadeByCompany.id__eq": {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      invoiceStatisticSeason__in: {
        parse: StringTransformer.parseStrings,
        stringify: StringTransformer.stringifyStrings,
      },

      //OrderStatistic
      "orderStatisticMadeByCompany.id__eq": {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      orderStatisticSeason__in: {
        parse: StringTransformer.parseStrings,
        stringify: StringTransformer.stringifyStrings,
      },

      //BalanceTransactionStatistic
      "balanceTransactionStatisticMadeByCompany.id__eq": {
        parse: StringTransformer.parseId,
        stringify: StringTransformer.stringifyId,
      },
      balanceTransactionStatisticSeason__in: {
        parse: StringTransformer.parseStrings,
        stringify: StringTransformer.stringifyStrings,
      },
    },
  });

  return {
    value,
    set,
  };
}
