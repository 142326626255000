import { Company, CompanyType, getCompanySuffix } from "@/features/types";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { useEffect, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { Deserializable, Serializable } from "@/features/ui/app.type";
import { boolean, string } from "yup";

export class CompanyId extends Deserializable implements Serializable {
  private readonly _id: number;
  private readonly _type: CompanyType;

  constructor(id: number, type: CompanyType) {
    super();
    this._id = id;
    this._type = type;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  serialize(): string {
    return `${this._id}:${this._type}`;
  }

  isDeserializable(str: string): boolean {
    return /^[0-9]+:[A-Z_]+$/.test(str);
  }
}

function isCompanyId(candidate: any): candidate is CompanyId {
  return candidate && candidate.id && candidate.type;
}

function isCompanyIdList(candidate: any): candidate is CompanyId[] {
  return Array.isArray(candidate) && candidate.every(isCompanyId);
}

interface BaseCompanySelectProps {
  name: string;
  types: CompanyType[];
  width?: string;
  isNullable?: boolean;
  excludeIds?: number[];
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isReadOnly?: boolean;
  isLiteral?: boolean;
  isMultiple?: boolean;
}

interface SingleCompanySelectProps extends BaseCompanySelectProps {
  id?: number | CompanyId;
  isMultiple?: false;
  onSelect: (value?: Company) => void;
}

interface MultipleCompanySelectProps extends BaseCompanySelectProps {
  id?: number[] | CompanyId[];
  isMultiple: true;
  onSelect: (value?: Company[]) => void;
}

type CompanySelectProps = SingleCompanySelectProps | MultipleCompanySelectProps;

export function getSelectedCompanyValue(
  data?: Company[],
  id?: CompanySelectProps["id"]
) {
  let predicate: ((company: Company) => boolean) | undefined;
  if (data && id) {
    if (Array.isArray(id)) {
      if (isCompanyIdList(id)) {
        predicate = (company) =>
          id.some(
            (candidate) =>
              candidate.id === company.id && candidate.type === company.type
          );
      } else {
        predicate = (company) => id.includes(company.id);
      }
      return data.filter(predicate);
    } else {
      if (isCompanyId(id)) {
        predicate = (company) =>
          company.id === id.id && company.type === id.type;
      } else {
        predicate = (company) => company.id === id;
      }

      return data.find(predicate);
    }
  }
}

export function withSelectCompanyIdFunc(types?: CompanyType[]) {
  if (!types || types.length > 1) {
    return (company: Company) => `${company.id}__${company.type}`;
  }
  return (company: Company) => company.id;
}

export default function AppCompanySelect({
  types,
  id,
  onSelect,
  width = "144px",
  name,
  isMultiple = false,
  isNullable = false,
  excludeIds = [],
  isDisabled = false,
  isClearable,
  isSearchable = true,
  isReadOnly = false,
  isLiteral = false,
}: CompanySelectProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const key = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (identity?.company) {
      let request: ListAllCompaniesRequest = {};
      if (types) {
        request.type__in = types;
      }
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
    return undefined;
  }, [identity, types]);

  let { isLoading, data, mutate, error } = useListAllCompanies(
    key?.parameter,
    key?.request
  );

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  const { t } = useI18nHelper();

  // let companies: AppSelectOption<Company>[] = [];
  // if (!isLoading && data) {
  //   if (isNullable) {
  //     data = [
  //       {
  //         id: -1,
  //         name: t("unassigned"),
  //         type: "BOUTIQUE" as CompanyType,
  //       },
  //     ].concat(data);
  //   }
  //   companies = data
  //     .filter((c) => !excludeIds?.includes(c.id))
  //     .map(
  //       (c): AppSelectOption<Company> => ({
  //         name: c.name,
  //         value: c,
  //       })
  //     );
  // }

  const options = useMemo(() => {
    if (data) {
      const addSuffix =
        process.env.NODE_ENV === "development" && (!types || types.length > 1);

      const items = data
        .filter((c) => !excludeIds?.includes(c.id))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

      return items.map((item, index) => {
        let suffix = "";
        if (addSuffix) {
          const previousItem = index > 0 ? items[index - 1] : null;
          const nextItem = index < items.length - 1 ? items[index + 1] : null;

          if (
            (previousItem && previousItem.name === item.name) ||
            (nextItem && nextItem.name === item.name) ||
            true
          ) {
            suffix = `(${getCompanySuffix(item.type)})`;
          }
        }
        return {
          name: `${item.name}${suffix}`,
          value: item,
        };
      });
    }
    return [];
  }, [data, types, excludeIds]);

  const idFunc = useMemo(() => withSelectCompanyIdFunc(types), [types]);

  const appSelectProps = {
    name,
    options,
    idFunc,
    isSearchable,
    width,
    isDisabled,
    isClearable,
    isReadOnly,
    isLiteral,
  };

  if (isMultiple) {
    return (
      <AppSelect<Company>
        {...appSelectProps}
        onSelect={onSelect as (value?: Company[]) => void}
        value={getSelectedCompanyValue(data, id) as Company[]}
        isMultiple={true}
      />
    );
  } else {
    return (
      <AppSelect<Company>
        {...appSelectProps}
        onSelect={onSelect as (value?: Company) => void}
        isMultiple={false}
        value={getSelectedCompanyValue(data, id) as Company}
      />
    );
  }
}
