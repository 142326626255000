import { Flex, Icon, IconButton, Input } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import AppSelect from "@/features/line-sheet-sets/app-select";
import React, { ChangeEventHandler, useMemo, useRef } from "react";
import { TransientFile } from "@/features/types";
import { useTranslation } from "react-i18next";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";

interface AppFileInputProps<Type> {
  handleDelete: (file: TransientFile<Type>) => void;
  handleChange: (next: TransientFile<Type>) => void;
  transientFile: TransientFile<Type>;
  types: Type[];
  number1?: boolean;
}
export default function AppFileWithTypeInput<Type>({
  transientFile,
  handleDelete,
  handleChange,
  types,
  number1 = false,
}: AppFileInputProps<Type>) {
  const { tWithFuc } = useI18nHelper();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBinaryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const binary = event.target.files?.item(0);
    if (binary) {
      handleChange({ ...transientFile, binary });
    }
  };

  const handleTypeChange = (type: Type | undefined) => {
    handleChange({ ...transientFile, type });
  };

  const handleNumber1Change: ChangeEventHandler<HTMLInputElement> = (event) => {
    const number1 = parseInt(event.target.value, 10);
    handleChange({
      ...transientFile,
      number1: isNaN(number1) ? "" : number1,
    });
  };

  return (
    <Flex
      flexDirection={"row"}
      gap={"8px"}
      alignItems={"center"}
      height={"24px"}
    >
      <input
        type={"file"}
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleBinaryChange}
      />
      <Icon as={SVG} src={"/icons/icon_download.svg"} />
      <Input
        fontSize={"12px"}
        height={"100%"}
        value={transientFile.binary.name}
        isReadOnly={true}
        onClick={() => inputRef && inputRef.current && inputRef.current.click()}
      />
      {types.length > 0 && (
        <AppSelect<Type>
          width={"160px"}
          name={tWithFuc("type")}
          value={transientFile.type}
          options={types.map((type) => ({
            name: tWithFuc(toLower(type as string)),
            value: type,
          }))}
          onSelect={handleTypeChange}
        />
      )}
      {number1 && (
        <Input
          height={"100%"}
          width={"160px"}
          fontSize={"12px"}
          value={transientFile.number1}
          type={"number"}
          onChange={handleNumber1Change}
          sx={{
            "::-webkit-inner-spin-button": { appearance: "none", margin: "0" },
            "::-webkit-outer-spin-button": { appearance: "none", margin: "0" },
            "::-moz-inner-spin-button": { appearance: "none", margin: "0" },
            "::-moz-outer-spin-button": { appearance: "none", margin: "0" },
            "-mozAppearance": "textfield",
          }}
        />
      )}
      <IconButton
        flexShrink={"0"}
        aria-label={"de;ete"}
        minW={"0"}
        minH={"0"}
        w={"12px"}
        h={"12px"}
        boxSize={"12px"}
        background={"transparent"}
        _hover={{ background: "transparent" }}
        icon={
          <SVG
            src={"/icons/icon_x_letter.svg"}
            color={"#8F8F8C"}
            width={"12px"}
            height={"12px"}
          />
        }
        onClick={() => {
          handleDelete(transientFile);
        }}
      />
    </Flex>
  );
}
