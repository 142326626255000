import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { Payment } from "@/features/invoices/payment/payment.type";
import { PaymentRow } from "@/features/invoices/payment/payment-invoice-row.type";

export const paymentRowMapper = new GridMapper<Payment, PaymentRow>(
  "Payment",
  (payment) => {
    return {
      ...payment,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
