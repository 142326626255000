import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormControl, FormLabel, Grid, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import React, { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useCreateBankInfo from "@/features/invoices/triangular-trades/hooks/use-create-bank-info";
import { BankInfo } from "@/features/invoices/triangular-trades/triangular-trade.type";
import enLocale from "i18n-iso-countries/langs/en.json";

export interface CreateBankInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (bank_info: BankInfo) => void;
}

interface FormFields {
  alias: string;
  bankName: string;
  iban: string;
  swiftCode: string;
  branchName: string;
  branchAddress: string;
}

export default function CreateBankInfoModal({
  isOpen,
  onClose,
  onCreate,
}: CreateBankInfoModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "common",
    prefix: "create_bank_info_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      alias: Yup.string().label("alias").required(withMessage.required),
      bankName: Yup.string().label("bank_name").required(withMessage.required),
      iban: Yup.string().label("iban").required(withMessage.required),
      swiftCode: Yup.string()
        .label("swift_code")
        .required(withMessage.required),
    });
  }, [withMessage.required]);

  async function validate(values: FormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const {
    fire: fireCreateBankInfo,
    error: createBankInfoError,
    data: createBankInfoData,
  } = useCreateBankInfo();

  useEffect(() => {
    if (createBankInfoError) {
      showError(createBankInfoError);
    }
  }, [createBankInfoError, showError]);

  useEffect(() => {
    if (createBankInfoData) {
      onCreate?.(createBankInfoData);
      onClose();
    }
  }, [createBankInfoData, onClose, onCreate]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company) {
        return fireCreateBankInfo(
          {
            by: identity.company.type,
            companyId: identity?.company?.id,
          },
          {
            alias: values.alias,
            bankName: values.bankName,
            iban: values.iban,
            swiftCode: values.swiftCode,
            branchName: values.branchName,
            branchAddress: values.branchAddress,
          }
        );
      }
    },
    [fireCreateBankInfo, identity]
  );

  const options = useMemo(() => {
    if (!enLocale.countries) {
      return [];
    }
    return Object.entries(enLocale.countries).map(([key, value]) => ({
      name: t(`countries\:${key}`),
      value: key,
    }));
  }, [t]);
  options.sort((a, b) => a.name.localeCompare(b.name));
  options.sort((a, b) => {
    if (a.value === "KR") {
      return -1;
    }
    if (b.value === "KR") {
      return 1;
    }
    if (a.value === "IT") {
      return -1;
    }
    if (b.value === "IT") {
      return 1;
    }
    return 0;
  });

  return (
    <Formik<FormFields>
      initialValues={{
        alias: "",
        bankName: "",
        iban: "",
        swiftCode: "",
        branchName: "",
        branchAddress: "",
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={"create_bank_info_form.title"}
        submitName={"add.do"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text fontSize={"12px"}>{t("create_departure_form.message")}</Text>

        <Form>
          <Grid
            templateColumns={"1fr 1fr"}
            templateRows={"repeat(4, 64px)"}
            gap={"8px"}
          >
            <Field name="alias">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    gridColumn={"span 2"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("alias")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("alias"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="bankName">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    gridColumn={"span 2"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("bank_name")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("bank_name"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="iban">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("iban")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("iban"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="swiftCode">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("swift_code")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("swift_code"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
            <Field name="branchName">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("branch_name")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("branch_name"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name="branchAddress">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("branch_address")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle("branch_address"),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
          </Grid>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
