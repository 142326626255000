import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { BankRemittanceReceiptRow } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-row.type";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";

export const bankRemittanceReceiptRowMapper = new GridMapper<
  BankRemittanceReceipt,
  BankRemittanceReceiptRow
>(
  "BankRemittanceReceipt",
  (resource) => {
    return {
      ...resource,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
