import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo, useState } from "react";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import { OrderSheetSetRow } from "@/features/order-status/order-sheet-set-row.type";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useListDenseOrderSheetSet, {
  ListDenseOrderSheetSetsPathParameter,
  ListDenseOrderSheetSetsRequest,
} from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import { orderSheetSetRowMapper } from "@/features/invoices/utils/order-sheet-set-row-mapper";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import IsolatedPanelSkeleton from "@/features/invoices/isolated-panel-skeleton";

interface UseIsolatedOrderSheetSetGridProps {
  orderSheetSetIds?: number[];
}

const rowMapper = orderSheetSetRowMapper.copy();

export default function IsolatedOrderSheetSetsPanel({
  orderSheetSetIds,
}: UseIsolatedOrderSheetSetGridProps) {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const [rows, setRows] = useState<OrderSheetSetRow[] | undefined>(undefined);

  const listOrderSheetSetsKey = useMemo(():
    | {
        parameter: ListDenseOrderSheetSetsPathParameter;
        request: ListDenseOrderSheetSetsRequest;
      }
    | undefined => {
    if (
      identity?.company?.type &&
      orderSheetSetIds &&
      orderSheetSetIds.length > 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: orderSheetSetIds,
          pageNumber: 1,
          pageSize: orderSheetSetIds.length,
        },
      };
    }

    return undefined;
  }, [identity, orderSheetSetIds]);

  const { error: showError } = useAppToasts();

  const {
    data: listOrderSheetSets,
    error: listOrderSheetSetsError,
    mutate: mutateListOrderSheetSets,
  } = useListDenseOrderSheetSet(
    listOrderSheetSetsKey?.parameter,
    listOrderSheetSetsKey?.request
  );

  useEffect(() => {
    if (listOrderSheetSetsError) {
      showError(listOrderSheetSetsError);
    }
  }, [listOrderSheetSetsError, showError]);

  useEffect(() => {
    if (listOrderSheetSets) {
      setRows((prev) => {
        return rowMapper.toRows(
          listOrderSheetSets.orderSheetSets.map((oss) => {
            return {
              ...oss,
              lineSheetSet: {
                ...oss.lineSheetSet,
                isRead: false,
              },
            };
          }),
          prev
        );
      });
    }
  }, [listOrderSheetSets]);

  const orderSheetGrid = useLineSheetSetGrid({
    rows,
    mutateRows: mutateListOrderSheetSets,
  });

  if (listOrderSheetSetsKey === undefined) {
    return null;
  } else if (rows === undefined) {
    return <IsolatedPanelSkeleton />;
  }

  return (
    <AppPanel title={tTitle("common:related_order_sheets")} variant={"ghost"}>
      <AppGrid
        isAnchored={false}
        rows={rows}
        metas={[
          {
            rowType: "OrderSheetSet",
            gap: "8px",
            columns: [
              orderSheetGrid.pairs.tsi.column,
              orderSheetGrid.pairs.title.column,
              orderSheetGrid.pairs.oc.column,
              orderSheetGrid.pairs.status.column,
            ],
            fields: [
              orderSheetGrid.pairs.tsi.field,
              orderSheetGrid.pairs.title.field,
              orderSheetGrid.pairs.oc.field,
              orderSheetGrid.pairs.status.field,
            ],
            indicator: (_row) => {
              return (
                <GridRowIndicator type={"VIEW"} isHovered={_row.isHovered} />
              );
            },
            actions: [orderSheetGrid.actions.goToOrderSheetSetPage],
            onClick: (_row) => {
              //todo 여기서 가면 어느 navigation을 활성화 해야하는거지?;
              orderSheetGrid.actions.goToOrderSheetSetPage(_row).onClick();
            },
          },
        ]}
      />
    </AppPanel>
  );
}
