import { GridMapper } from "@/features/ui/grid-row/gird-mapper";
import { LightLineSheetSet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { AgencyLineSheetSetRow } from "@/features/order-sheet-sets/line-sheet-set-row.type";

export const lineSheetSetMapper = new GridMapper<
  LightLineSheetSet,
  AgencyLineSheetSetRow
>(
  "LineSheetSet",
  (lineSheetSet) => {
    return {
      id: lineSheetSet.id,
      season: lineSheetSet.season,
      name: lineSheetSet.name,
      status: lineSheetSet.status,
      reviewStatus: lineSheetSet.reviewStatus,
      compressionStatus: lineSheetSet.compressionStatus,
      type: lineSheetSet.type,
      publishedAt: lineSheetSet.publishedAt,
      validUntil: lineSheetSet.validUntil,
      createdAt: lineSheetSet.createdAt,
      issuedByBoutique: lineSheetSet.issuedByBoutique,
      issuedByBrand: lineSheetSet.issuedByBrand,
      compressedImportedFile: lineSheetSet.compressedImportedFile,
      confirmedAmount: 0,
      confirmedQuantity: 0,
      orderedAmount: 0,
      orderedQuantity: 0,
      orderRules: lineSheetSet.orderRules,
      externalFileLinks: lineSheetSet.externalFileLinks,
      isDeleted: lineSheetSet.isDeleted,
    };
  },
  (lineSheetSetRow) => {
    return {
      id: lineSheetSetRow.id,
      season: lineSheetSetRow.season,
      name: lineSheetSetRow.name,
      status: lineSheetSetRow.status,
      reviewStatus: lineSheetSetRow.reviewStatus,
      compressionStatus: lineSheetSetRow.compressionStatus,
      type: lineSheetSetRow.type,
      publishedAt: lineSheetSetRow.publishedAt,
      validUntil: lineSheetSetRow.validUntil,
      createdAt: lineSheetSetRow.createdAt,
      issuedByBoutique: lineSheetSetRow.issuedByBoutique,
      issuedByBrand: lineSheetSetRow.issuedByBrand,
      compressedImportedFile: lineSheetSetRow.compressedImportedFile,
      orderRules: lineSheetSetRow.orderRules,
      externalFileLinks: lineSheetSetRow.externalFileLinks,
      isDeleted: lineSheetSetRow.isDeleted,
    };
  }
);
