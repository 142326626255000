import {
  ProformaInvoice,
  ProformaInvoiceDetail,
} from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { IdentityParameter, StoredObject } from "@/features/types";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";

export interface CreateProformaInvoiceRequest
  extends Omit<
    ProformaInvoice,
    | "id"
    | "file"
    | "isRemitted"
    | "isRemittedToAgency"
    | "isRemittedToBoutique"
    | "isRead"
    | "status"
    | "bankRemittanceReceipts"
    | "earnedCreditInvoices"
    | "usedCreditInvoices"
    | "proformaInvoiceDetailList"
    | "lineSheetSetType"
    | "createdAt"
    | "totalRemittedAmount"
    | "totalBuyerToAgencyAmount"
    | "totalBuyerToBoutiqueAmount"
    | "totalAgencyToBoutiqueAmount"
    | "parentProformaInvoices"
    | "childProformaInvoices"
    | "issuedThroughCompany"
    | "payments"
  > {
  file: Pick<StoredObject, "id">;
  lineSheetSetType: LineSheetSetType | null;
  usedCreditInvoices: { id: number }[];
  proformaInvoiceDetailList: (Omit<
    ProformaInvoiceDetail,
    "id" | "orderConfirmationInvoiceDetail"
  > & {
    orderConfirmationInvoiceDetail: { id: number } | null;
  })[];
  childProformaInvoices: { id: number }[];
  issuedThroughCompany: { id: number } | null;
}

export interface CreateProformaInvoiceResponse extends ProformaInvoice {}

export interface CreateProformaInvoicePathParameter extends IdentityParameter {}

export const createProformaInvoiceTemplateUrl = `/:by/:companyId/proforma-invoices`;

export const createProformaInvoiceKey: YWRKey<CreateProformaInvoicePathParameter> =
  {
    url: (parameters: CreateProformaInvoicePathParameter) => {
      return calcUrl(createProformaInvoiceTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateProformaInvoice() {
  const fetcher = useFetcher<
    CreateProformaInvoiceRequest,
    CreateProformaInvoiceResponse
  >();
  return useYWR(createProformaInvoiceKey, fetcher);
}
