import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useIdentity from "@/features/ui/hooks/use-identity";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { useMemo } from "react";
import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import * as Yup from "yup";
import { uniq } from "lodash";
import { InvoiceUtils } from "@/features/invoices/invoice-utils";

export default function usePaymentFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { withMessage, formT } = useYupHelper({
    ns: "payments",
    prefix: "upload_payment_form",
  });

  return useMemo(() => {
    return Yup.object<PaymentFormFields>({
      // name: Yup.string().required(withMessage.required),
      status: Yup.mixed().required(withMessage.required),
      amount: Yup.object({
        value: Yup.number()
          .label("common:total_remittance_required_amount.abbr")
          .required(withMessage.required),
        currency: Yup.string(),
      })
        .label("common:total_remittance_required_amount.abbr")
        .required(withMessage.required)
        .test(
          "remitted_amount_exceeds_required_amount",
          t(
            "common:upload_payment_form.form.messages.error.remitted_amount_exceeds_required_amount"
          ),
          (value, context) => {
            const { remittedAmount } = context.parent as PaymentFormFields;
            return (value.value || 0) >= (remittedAmount?.value || 0);
          }
        ),
      season: Yup.string().required(withMessage.required),
      orderedBy: Yup.mixed().required(withMessage.required),
      // issuedBy: Yup.mixed().required(withMessage.required),
      issuedThrough: Yup.mixed().required(withMessage.required),
      sender: Yup.mixed().required(withMessage.required),
      receiver: Yup.mixed().required(withMessage.required),
      krwInvoiceFiles: Yup.array()
        .label("payment_files")
        .of(
          Yup.object({
            invoices: Yup.array(),
            file: Yup.mixed().required(withMessage.required),
          })
        )
        .test(
          "",
          t("common:upload_payment_form.form.messages.error.unlinked_invoice"),
          (value, context) => {
            const { proformaInvoices, orderConfirmationInvoices } =
              context.parent;
            if ((value || []).length > 0) {
              let uniqueInvoiceCount = uniq(
                value?.flatMap(
                  (krwInvoice) =>
                    krwInvoice.invoices?.map((invoice) => `${invoice.type}_${invoice.id}`)
                )
              ).length;
              return (
                uniqueInvoiceCount ===
                proformaInvoices.length + orderConfirmationInvoices.length
              );
            }
            return true;
          }
        ),
    });
  }, [t, withMessage.required]);
}
