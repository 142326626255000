import useYWR from "@/features/ui/hooks/use-ywr";
import { useEffect, useMemo, useRef } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";

interface Props<P, REQ, RES> {
  ywrFunc: () => ReturnType<typeof useYWR<P, REQ, RES>>;
  resource?: { id: number; isRead?: boolean };
  when?: (() => boolean) | boolean;
  mutate?: () => void;
}

export default function useReadResource<P, REQ, RES>({
  ywrFunc,
  resource,
  when,
  mutate,
}: Props<P, REQ, RES>) {
  const hookRef = useRef(ywrFunc());
  const predicatedRef = useRef(when);
  const identity = useIdentity();

  const isMet = useMemo(() => {
    const booleanOrFunc = predicatedRef.current || true;
    return typeof booleanOrFunc === "function"
      ? booleanOrFunc()
      : booleanOrFunc;
  }, []);

  useEffect(() => {
    if (resource && !resource.isRead && identity?.company?.type) {
      if (isMet) {
        let url: string;
        const urlOrFunc = hookRef.current.ywrKey.url;
        let parameter = {
          by: identity.company.type,
          companyId: identity.company.id,
        } as P;
        if (typeof urlOrFunc === "function") {
          url = urlOrFunc(parameter);
        } else {
          url = urlOrFunc;
        }
        //여기에서 resourceId를 찾자
        url = url.replace("/:(w+Id)/", String(resource.id));
        const result = url.match(/:\w+Id/g);
        if (result && result.length === 1) {
          parameter = {
            ...parameter,
            [result[0].substring(1)]: resource.id,
          };

          hookRef.current.fire(parameter, {} as REQ).then(() => {
            mutate?.();
          });
        }
      }
    }
  }, [identity, isMet, mutate, resource]);

  return {
    fire: hookRef.current.fire,
  };
}
