import useFetcher from "@/utils/use-fetcher";
import {
  getListBankRemittanceReceiptsKey,
  ListBankRemittanceReceiptsPathParameter,
  ListBankRemittanceReceiptsRequest,
  ListBankRemittanceReceiptsResponse,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-list-bank-remittance-receipts";
import { useMemo } from "react";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { keyBy, uniq } from "lodash";

export default function useCondenseBankRemittanceReceipts() {
  const listBankRemittancesFetcher = useFetcher<
    ListBankRemittanceReceiptsRequest,
    ListBankRemittanceReceiptsResponse
  >();
  return useMemo(() => {
    return async <T>(
      items: T[],
      getter: (item: T) => ({ id: number } | null)[] | { id: number } | null,
      setter: (
        item: T,
        value: BankRemittanceReceipt[] | BankRemittanceReceipt
      ) => void,
      parameter: ListBankRemittanceReceiptsPathParameter
    ) => {
      const ids = uniq(
        items
          .flatMap((item) => {
            const value = getter(item);
            if (Array.isArray(value)) {
              return value.map((i) => (i !== null ? i.id : null));
            } else if (value != null) {
              return [value.id];
            } else {
              return [null];
            }
          })
          .filter((i): i is number => i !== null)
      );

      if (ids.length > 0) {
        const bankRemittancesResponse = await listBankRemittancesFetcher(
          getListBankRemittanceReceiptsKey(parameter, {
            pageSize: ids.length,
            pageNumber: 1,
            id__in: ids,
          })!
        );

        const bankRemittancesById = keyBy(
          bankRemittancesResponse.bankRemittanceReceipts,
          "id"
        );

        items.forEach((item) => {
          const lightValue = getter(item);
          if (Array.isArray(lightValue)) {
            setter(
              item,
              lightValue
                .filter((i): i is { id: number } => i !== null)
                .map((i) => bankRemittancesById[i.id])
            );
          } else if (lightValue != null) {
            setter(item, bankRemittancesById[lightValue.id]);
          }
        });
      }
    };
  }, [listBankRemittancesFetcher]);
}
