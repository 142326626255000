import { Grid, Heading } from "@chakra-ui/react";
import { Formik } from "formik";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback } from "react";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useOrderConfirmationInvoiceDumbTransaction } from "@/features/invoices/order-confirmation-invoices/hooks/use-order-confirmation-invoice-dumb-transaction";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import OrderConfirmationInvoiceForm from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-form";
import useOrderConfirmationInvoiceFormValidationSchema from "@/features/invoices/order-confirmation-invoices/hooks/use-order-confirmation-invoice-form-validation-schema";
import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import { MathUtils } from "@/features/ui/utils/math-utils";
import PriceUtils from "@/features/ui/utils/price-utils";
import { Price } from "@/features/types";

export default function UploadOrderConfirmationInvoicePage() {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();

  const validateSchema = useOrderConfirmationInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: OrderConfirmationInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [showError, validateSchema]
  );

  const { navigate } = useRouterHelper();

  const { addCreate } = useOrderConfirmationInvoiceDumbTransaction();

  const handleSubmit = useCallback(
    (values: OrderConfirmationInvoiceFormFields) => {
      if (identity?.company?.type) {
        const total = MathUtils.trim2(
          values.orderConfirmationInvoiceDetailList
            .map((ocDetail) => {
              return {
                amount: ocDetail.amount,
                requiredAmount: ocDetail.requiredAmount,
              };
            })
            .reduce(
              (acc, cur) => {
                return {
                  amount: PriceUtils.sum([acc.amount, cur.amount], "EUR"),
                  requiredAmount: PriceUtils.sum(
                    [acc.requiredAmount, cur.requiredAmount],
                    "EUR"
                  ),
                };
              },
              {
                amount: {
                  currency: "EUR",
                  value: 0,
                },
                requiredAmount: {
                  currency: "EUR",
                  value: 0,
                },
              }
            )
        );

        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            number: values.number!,
            season: values.season!,
            name: values.name!,
            issuedToCompany: values.orderedBy!,
            issuedByCompany: values.issuedBy!,
            issuedThroughCompany: values.issuedThrough!,
            issuedOn: values.issuedOn!,
            paidBy: values.paidByAgency ? "AGENCY" : "BUYER",
            paymentMethod: values.paymentMethod!,
            totalAmount: total.amount as Price,
            totalRequiredAmount: total.requiredAmount as Price,
            orderConfirmationInvoiceDetailList:
              values.orderConfirmationInvoiceDetailList.map((ocDetail) => {
                return {
                  amount: ocDetail.amount as Price,
                  requiredAmount: ocDetail.requiredAmount,
                  brand: ocDetail.brand!,
                  orderSheetSet: ocDetail.orderSheetSet || null,
                };
              }),
            orderSheetSets: values.orderSheetSets.map((oss) => {
              return {
                id: oss.id,
              };
            }),
            isPublic: values.exposedToBuyer,
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            file: values.file!,
          }
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    [addCreate, identity]
  );

  return (
    <Formik<OrderConfirmationInvoiceFormFields>
      initialValues={{
        id: -1,
        status: "NORMAL",
        season: DEFAULT_SEASON,
        issuedOn: (() => {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          return date;
        })(),
        exposedToBuyer: true,
        paymentMethod: "NORMAL",
        paidByAgency: false,
        publicComment: "",
        privateComment: "",
        orderSheetSets: [],
        orderConfirmationInvoiceDetailList: [
          //이걸 왜 넣은거지?
          // {
          //   id: -1,
          // },
        ],
        proformaInvoices: [],
        creditInvoices: [],
        payments: [],
        orderedBy:
          identity?.company?.type === "BUYER"
            ? {
                id: identity?.company?.id!,
                name: "*ignored",
              }
            : undefined,
        issuedThrough:
          identity?.company?.type === "AGENCY"
            ? {
                id: identity?.company?.id!,
                name: "*ignored",
              }
            : undefined,
        issuedBy:
          identity?.company?.type === "BOUTIQUE"
            ? {
                id: identity?.company?.id!,
                name: "*ignored",
                type: "BOUTIQUE",
              }
            : undefined,
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={async (values, formikHelpers) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading
                  key={"UploadOrderConfirmationInvoice"}
                  as={"h2"}
                  fontSize={"14px"}
                >
                  {tTitle("upload_order_confirmation_invoice")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppButton
                  key={`UploadAndAddAnother`}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      props.resetForm();
                    }
                  }}
                >
                  {tTitle("upload_and_add_another.do")}
                </AppButton>,
                <AppButton
                  key={`Upload`}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  onClick={async () => {
                    const result = await props.submitForm();
                    // @ts-ignore
                    if (result) {
                      navigate("/order-confirmation-invoices");
                    }
                  }}
                >
                  {tTitle("upload")}
                </AppButton>,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/order-confirmation-invoices"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <OrderConfirmationInvoiceForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
