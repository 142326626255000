import { BoutiqueConfirmationMetadata } from "@/features/order-sheets/order-sheet.type";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { BoutiqueConfirmationMetadataFormField } from "@/features/order-status/modals/confirm-by-boutique-order-sheet-set-modal";
import AppPriceInput from "@/features/invoices/app-price-input";
import PriceConditionExclusiveSelector from "@/features/order-status/price-condition-exclusive-selector";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface BoutiqueConfirmationMetadataInputProps {
  value: BoutiqueConfirmationMetadataFormField;
  onChange: (value: BoutiqueConfirmationMetadataFormField) => void;
}

export default function BoutiqueConfirmationMetadataInput({
  value,
  onChange,
}: BoutiqueConfirmationMetadataInputProps) {
  const { tTitle } = useI18nHelper();
  const editable = value.orderSheets.map(
    (orderSheet, index) => {
      return orderSheet.lineSheet.status !== "PARSED"
    }
  ).includes(true);
  return (
    <Flex
      borderRadius={"4px"}
      border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
      background={"var(--Pathrade-Primary-Bright, #F7F9FA)"}
      flexDirection={"column"}
      fontSize={"12px"}
      padding={"12px"}
      gap={"8px"}
    >
      <Grid
        gridTemplateColumns={"1fr 3fr"}
        alignItems={"center"}
        rowGap={"8px"}
      >
        <Text borderBottom={"0.5px solid var(--Pathrade-Gray, #D4D4D1)"}>
          Ordered To
        </Text>
        <Text borderBottom={"0.5px solid var(--Pathrade-Gray, #D4D4D1)"}>
          Order Sheet Title
        </Text>
        <Text alignSelf={"start"}>{value.toBeSubmitted.name}</Text>
        <Flex flexDirection={"column"}>
          {value.orderSheets.map((orderSheet, index) => {
            return (
              <Text
                key={`BoutiqueConfirmationMetadataInput_orderSheet_${orderSheet.id}`}
              >
                {orderSheet.name}
              </Text>
            );
          })}
        </Flex>
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 1fr"}
        gridTemplateRows={"auto auto"}
        paddingRight={"20px"}
        rowGap={"4px"}
        columnGap={"8px"}
      >
        <Text fontSize={"10px"}>{tTitle("common:total_confirmed_amount")}</Text>
        <Text fontSize={"10px"}>
          {tTitle("common:total_confirmed_quantity")}
        </Text>
        <AppPriceInput
          isReadOnly={!editable}
          isDisabled={!editable}
          currency={"EUR"}
          value={{
            value: value.totalAmount,
            currency: "EUR",
          }}
          onChange={(newTotalAmount) => {
            onChange({
              ...value,
              totalAmount: newTotalAmount?.value || 0,
            });
          }}
        />
        <AppPriceInput
          isReadOnly={!editable}
          isDisabled={!editable}
          currency={"EA"}
          value={{
            value: value.totalQuantity,
            currency: "EA",
          }}
          onChange={(newTotalQuantity) => {
            onChange({
              ...value,
              totalQuantity: newTotalQuantity?.value || 0,
            });
          }}
        />
      </Grid>
      {editable &&
        <Grid
          gridTemplateColumns={"1fr"}
          gridTemplateRows={"auto auto"}
          rowGap={"4px"}
          columnGap={"8px"}
        >
          <Text fontSize={"10px"}>{tTitle("confirmed_conditions")}</Text>
          <PriceConditionExclusiveSelector
            value={value.conditions}
            onSelect={(newConditions) => {
              onChange({
                ...value,
                conditions: newConditions
              });
            }}
          />
        </Grid>
      }
    </Flex>
  );
}
