import {
  InvoiceStatisticByType,
  InvoiceStatisticType,
} from "@/features/dashboard/dashboard.type";
import { IdentityParameter } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { useCallback } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListInvoiceStatisticsByTypeRequest,
  ListInvoiceStatisticsByTypeResponse,
} from "@/features/dashboard/hooks/use-list-invoice-statistics-by-type";
import { ErrorResponse } from "@/utils/uno-axios";

export interface GetInvoiceStatisticByTypeRequest
  extends ListInvoiceStatisticsByTypeRequest {}

export interface GetInvoiceStatisticByTypeResponse
  extends InvoiceStatisticByType {}

export interface GetInvoiceStatisticByTypePathParameter
  extends IdentityParameter {
  type: InvoiceStatisticType;
}

const GetInvoiceStatisticByTypeTemplateUrl = `/:by/:companyId/invoice-statistics-by-type`;

function getGetInvoiceStatisticByTypeKey(
  parameter?: GetInvoiceStatisticByTypePathParameter,
  request?: GetInvoiceStatisticByTypeRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(GetInvoiceStatisticByTypeTemplateUrl, parameter),
      params: {
        ...request,
        type__eq: parameter.type,
      },
    };
  }
  return null;
}

export function useGetInvoiceStatisticByType(
  parameter?: GetInvoiceStatisticByTypePathParameter,
  request?: GetInvoiceStatisticByTypeRequest,
  config?: SWRConfiguration
) {
  const listFetcher = useFetcher<
    ListInvoiceStatisticsByTypeRequest,
    ListInvoiceStatisticsByTypeResponse
  >();

  const fetcher = useCallback(
    async (
      config: AxiosRequestConfig<GetInvoiceStatisticByTypeRequest>
    ): Promise<GetInvoiceStatisticByTypeResponse> => {
      const listResponse = await listFetcher(config);
      if (listResponse.invoiceStatisticsByType.length > 0) {
        return listResponse.invoiceStatisticsByType[0];
      }
      throw {
        error: {
          code: 404,
          status: "Not Found",
          message: "",
        },
      };
    },
    [listFetcher]
  );

  return useSWR(
    getGetInvoiceStatisticByTypeKey(parameter, request),
    fetcher,
    config
  );
}
