import { FlatProductImageUrl } from "@/features/line-sheet-sets/helpers/sheet-state";
import ProductImageLabel from "@/features/order-sheet-sets/product-image-label";
import { Box, Image, Tooltip } from "@chakra-ui/react";
import { AppUtils } from "@/features/ui/utils/app-utils";
import AppLabel from "@/features/ui/app-label";
import { PRODUCT_IMAGE_SIZE } from "@/features/order-sheet-sets/product-image-cell";
import { toLower } from "@/utils/case";

interface ProductImageProps {
  value: FlatProductImageUrl;
}

export default function ProductImage({ value }: ProductImageProps) {
  return (
    <Tooltip
      placement={"right"}
      backgroundColor={`var(--gray-dark, #444440)`}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.20)"}
      maxWidth={"auto"}
      hasArrow={true}
      p={"0"}
      m={"0"}
      label={<ProductImageLabel value={value} />}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ]}
    >
      <Box position={"relative"}>
        <Image
          borderRadius={"8px"}
          border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
          background={"#fff"}
          position={"relative"}
          flexGrow={"1"}
          alt={"alt"}
          src={AppUtils.getThumbnailUrl(value.url)}
          fallbackSrc={value.url}
          boxSize={`${PRODUCT_IMAGE_SIZE}px`}
          objectFit={"contain"}
          zIndex={"10"}
        />
        <Box position={"absolute"} top={"4px"} right={"4px"} zIndex={"11"}>
          <AppLabel
            isToUpper={false}
            size={"small"}
            nameKey={`product_image.type.${toLower(value.type)}.abbr`}
            level={value.type === "ORIGINAL" ? "PRIMARY" : "SUCCESS"}
          />
        </Box>
      </Box>
    </Tooltip>
  );
}
