import { StoredObject } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";

export interface StoreObjectRequest {
  file: File;
}

export interface StoreObjectResponse extends StoredObject {}

interface StoreObjectPathParameter {
  _v?: number;
}

export const storeObjectKey: YWRKey<StoreObjectPathParameter> = {
  url: (parameter: StoreObjectPathParameter) => {
    let url = `/uno-files`;
    if (parameter._v !== undefined) {
      url += `?_v=${parameter._v}`;
    }
    return url;
  },
  method: "post",
};

export default function useStoreObject() {
  const fetcher = useFetcher<StoreObjectRequest, StoreObjectResponse>();
  return useYWR(storeObjectKey, fetcher, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
