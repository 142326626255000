import { toCamel } from "@/utils/case";
import { ParseString } from "@/features/ui/helpers/string-transformer";

function isStringArray(value: string | string[]): value is string[] {
  return Array.isArray(value);
}

export class UrlParser<T> {
  private readonly templateUrl: string;
  private readonly entries: Record<keyof T & string, ParseString<T>>;

  constructor(
    templateUrl: string,
    entries: Record<keyof T & string, ParseString<T>>
  ) {
    this.templateUrl = templateUrl;
    this.entries = entries;
  }

  private getDumbObject(actualUrl: string): {
    [key: string]: string | string[];
  } {
    let dumbObject: { [key: string]: string | string[] } = {};
    const [templatePath] = this.templateUrl.split("?");
    const [actualPath, actualQueryString = ""] = actualUrl.split("?");

    if (actualQueryString.length > 0) {
      const searchParams = new URLSearchParams(actualQueryString);
      const keys: string[] = [];
      for (let [key] of searchParams.entries()) {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      }

      keys.forEach((_key) => {
        const value = searchParams.getAll(_key);
        const key = toCamel(_key);
        if (value.length === 1) {
          dumbObject[key] = value[0];
        } else {
          dumbObject[key] = value;
        }
      });
    }

    const templateItems = templatePath.split("/");
    const actualItems = actualPath.split("/");
    const length = Math.min(templateItems.length, actualItems.length);
    for (let i = 0; i < length; i++) {
      const key = templateItems[i];
      const value = actualItems[i];
      if (key.startsWith(":")) {
        dumbObject[key.substring(1)] = value;
      }
    }

    return dumbObject;
  }

  parse(actualUrl: string): T {
    const dumbObject = this.getDumbObject(actualUrl);
    const object = {} as T;
    for (const _key in this.entries) {
      //왜 ts가 type을 잃어 버리는 걸까?
      const key = _key as keyof T & string;
      if (this.entries.hasOwnProperty(key) && dumbObject.hasOwnProperty(key)) {
        const func = this.entries[key];
        const value = func(dumbObject[key]);
        if (value) {
          object[key] = value;
        }
      }
    }

    return object;
  }
}
