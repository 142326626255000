import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import {
  isAttachmentRow,
  isLineSheetRow,
  LineSheetableRow,
} from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import { LineSheetFile } from "@/features/line-sheet-sets/line-sheet-file-controller";
import {
  AttachmentRow,
  IsolatedLineSheetRow,
} from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import {
  getFileNameWithoutExtension,
  isFile,
  isStoredObject,
} from "@/features/types";
import { isLineSheetFormField } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";

export class LineSheetableRowConverter {
  private sequencer: NegativeSequencer;

  constructor(sequencer: NegativeSequencer) {
    this.sequencer = sequencer;
  }

  fromLineSheetToOrderForm(
    from: IsolatedLineSheetRow,
    file?: LineSheetFile["file"]
  ): IsolatedLineSheetRow {
    const next: IsolatedLineSheetRow = {
      ...from,
      type: "ORDER_FORM",
      file: file || from.file,
      name: file ? getFileNameWithoutExtension(file) : from.name,
      reviewStatus: null,
      status: "PARSING_SKIPPED",
    };

    return next;
  }

  fromLineSheetToAttachment(
    from: IsolatedLineSheetRow,
    file?: LineSheetFile["file"]
  ): AttachmentRow {
    const nextFile = file || from.file;
    const nextId = isFile(nextFile) ? this.sequencer.next() : nextFile.id;
    return {
      ...from,
      id: nextId,
      name: nextFile.name,
      file: isFile(nextFile) ? nextFile : undefined,
      _rowType: "Attachment",
      _rowId: `Attachment__${nextId}`,
    };
  }

  fromOrderFormToLineSheet(
    from: IsolatedLineSheetRow,
    file?: LineSheetFile["file"]
  ): IsolatedLineSheetRow {
    if (from.source) {
      if (isLineSheetFormField(from.source)) {
        if (
          from.source.type === "LINE_SHEET" &&
          isStoredObject(from.source.file) &&
          isStoredObject(from.file) &&
          from.source.file.id === from.file.id
        ) {
          return {
            ...from,
            type: "LINE_SHEET",
            reviewStatus: from.source.reviewStatus,
            status: from.source.status,
          };
        }
      }
    }

    return {
      ...from,
      type: "LINE_SHEET",
      file: file || from.file,
      name: file ? getFileNameWithoutExtension(file) : from.name,
      status: undefined,
      reviewStatus: null,
    };
  }

  fromOrderFormToAttachment(
    from: IsolatedLineSheetRow,
    file?: LineSheetFile["file"]
  ): AttachmentRow {
    const nextFile = file || from.file;
    const nextId = isFile(nextFile) ? this.sequencer.next() : nextFile.id;
    return {
      ...from,
      id: nextId,
      name: nextFile.name,
      file: isFile(nextFile) ? nextFile : undefined,
      _rowType: "Attachment",
      _rowId: `Attachment__${nextId}`,
    };
  }

  fromAttachmentToLineSheet(
    from: AttachmentRow,
    file?: LineSheetFile["file"]
  ): IsolatedLineSheetRow {
    if (from.source) {
      if (isLineSheetFormField(from.source)) {
        if (
          from.source.type === "LINE_SHEET" &&
          isStoredObject(from.source.file) &&
          from.source.file.id === from.id
        ) {
          return {
            ...from,
            id: from.source.id,
            file: from.source.file,
            type: "LINE_SHEET",
            _rowType: "LineSheet",
            _rowId: `LineSheet__${from.source.id}`,
            status: from.source.status,
            reviewStatus: from.source.reviewStatus,
            updatedAt: from.source.updatedAt,
          };
        }
      }
    }

    const nextFile = file || from.file || { id: from.id, name: from.name };
    const nextId = isFile(nextFile) ? this.sequencer.next() : nextFile.id;

    return {
      ...from,
      id: nextId,
      type: "LINE_SHEET",
      _rowType: "LineSheet",
      _rowId: `LineSheet__${nextId}`,
      file: nextFile,
      name: getFileNameWithoutExtension(nextFile),
      reviewStatus: null,
      status: undefined,
      updatedAt: new Date(),
    };
  }

  fromAttachmentToOrderForm(
    from: AttachmentRow,
    file?: LineSheetFile["file"]
  ): IsolatedLineSheetRow {
    if (from.source) {
      if (isLineSheetFormField(from.source)) {
        if (
          from.source.type === "ORDER_FORM" &&
          isStoredObject(from.source.file) &&
          from.source.file.id === from.id
        ) {
          return {
            ...from,
            id: from.source.id,
            name: from.source.name,
            file: from.source.file,
            type: "ORDER_FORM",
            _rowType: "LineSheet",
            _rowId: `LineSheet__${from.source.id}`,
            status: from.source.status,
            reviewStatus: from.source.reviewStatus,
            updatedAt: from.source.updatedAt,
          };
        }
      }
    }

    const nextFile = file || from.file || { id: from.id, name: from.name };
    const nextId = isFile(nextFile) ? this.sequencer.next() : nextFile.id;
    return {
      ...from,
      id: nextId,
      type: "ORDER_FORM",
      _rowType: "LineSheet",
      _rowId: `LineSheet__${nextId}`,
      file: nextFile,
      name: getFileNameWithoutExtension(nextFile),
      reviewStatus: null,
      status: undefined,
      updatedAt: new Date(),
    };
  }

  getConvertFunction(
    from: LineSheetableRow,
    nextPartial: {
      type?: LineSheetFile["type"];
      file?: LineSheetFile["file"];
    }
  ): (
    from: LineSheetableRow,
    file?: LineSheetFile["file"]
  ) => LineSheetableRow {
    if (isLineSheetRow(from)) {
      const next = {
        ...from,
        type: nextPartial.type || from.type,
        file: nextPartial.file || from.file,
      };

      if (from.type === "LINE_SHEET") {
        if (next.type === "LINE_SHEET") {
          //lss to lss
          // @ts-ignore
        } else if (next.type === "ORDER_FORM") {
          //lss to order form
          // @ts-ignore
          return this.fromLineSheetToOrderForm;
        } else {
          //lss to attachment
          // @ts-ignore
          return this.fromLineSheetToAttachment;
        }
      } else {
        if (next.type === "LINE_SHEET") {
          //order form to lss
          // @ts-ignore
          return this.fromOrderFormToLineSheet;
        } else if (next.type === "ORDER_FORM") {
          //order form to order form
        } else {
          //order form to attachment
          // @ts-ignore
          return this.fromOrderFormToAttachment;
        }
      }

      // @ts-ignore
      return (row: IsolatedLineSheetRow) => {
        if (from.file === next.file && from.type === next.type) {
          return row;
        }
        return {
          ...row,
          id: this.sequencer.next(),
          name: getFileNameWithoutExtension(next.file),
          type: next.type,
          file: next.file,
          reviewStatus: null,
          status: undefined,
        } as IsolatedLineSheetRow;
      };
    } else if (isAttachmentRow(from)) {
      const next = {
        type: nextPartial.type || "ATTACHMENT",
        file: nextPartial.file || from.file,
      };

      if (next.type === "ATTACHMENT") {
        //attachment to attachment
      } else if (next.type === "LINE_SHEET") {
        //attachment to lss
        // @ts-ignore
        return this.fromAttachmentToLineSheet;
      } else {
        //attachment to order form
        // @ts-ignore
        return this.fromAttachmentToOrderForm;
      }

      // @ts-ignore
      return (row: AttachmentRow): AttachmentRow => {
        if (from.file === next.file) {
          return row;
        }

        if (next.file) {
          const nextId = isFile(next.file)
            ? this.sequencer.next()
            : next.file.id;
          return {
            ...row,
            id: nextId,
            name: next.file.name,
            //@ts-ignore
            file: next.file,
          };
        } else {
          throw {
            message: "unimplemented conversion",
          };
        }
      };
    }

    throw {
      message: "unimplemented conversion",
    };
  }

  convert(
    from: LineSheetableRow,
    nextPartial: {
      type?: LineSheetFile["type"];
      file?: LineSheetFile["file"];
    }
  ) {
    const convertFunction = this.getConvertFunction(from, nextPartial).bind(
      this
    );
    return convertFunction(from, nextPartial.file);
  }
}
