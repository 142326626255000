import {
  findDirectChildGridRow,
  GridRow,
  GridRowType,
} from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

export interface GridMapperToRowOption {
  discardIsChecked?: boolean;
}

export type GridRowPayload<T extends GridRow> = Omit<
  T,
  | "_rowId"
  | "_rowType"
  | "id"
  | "isChecked"
  | "isCheckable"
  | "isCheckableOriginal"
  | "isCollapsed"
  | "isHovered"
  | "isActive"
  | "isTooltipOpen"
  | "children"
>;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]> | null;
};

export class GridMapper<T extends { id: number }, R extends GridRow> {
  readonly type: GridRowType;
  private readonly toRowFunc: (resource: T) => GridRowPayload<R>;
  private readonly toResourceFunc: (row: R) => DeepPartial<T>;

  private childMappers: {
    getter: (parent: T) => any;
    integrate: (parent: DeepPartial<T>, children: any[]) => void;
    mapper: GridMapper<any, any>;
  }[] = [];

  constructor(
    type: GridRowType,
    toRowFunc: (resource: T) => GridRowPayload<R>,
    toResourceFunc: (row: R) => DeepPartial<T>
  ) {
    this.type = type;
    this.toRowFunc = toRowFunc;
    this.toResourceFunc = toResourceFunc;
  }

  addChild<C = any>(
    getter: (parent: T) => any,
    integrate: (parent: DeepPartial<T>, children: C[]) => void,
    mapper: GridMapper<any, any>
  ) {
    this.childMappers.push({
      getter,
      integrate,
      mapper,
    });
    return this;
  }

  subMapper(type: GridRowType): GridMapper<any, any> | null {
    if (this.type == type) {
      return this;
    }

    for (const child of this.childMappers) {
      const subBlueprint = child.mapper.subMapper(type);
      if (subBlueprint) {
        return subBlueprint;
      }
    }

    return null;
  }

  toRow(
    resource: T,
    prevRow?: R,
    parentRow?: GridRow,
    option?: GridMapperToRowOption
  ): R {
    // @ts-ignore
    const row: R = {
      _rowType: this.type,
      _rowId:
        (parentRow ? parentRow._rowId + "-" : "") +
        `${this.type}__${resource.id}`,
      id: resource.id,
      isChecked: false,
      isCheckable: false,
      isCheckableOriginal: false,
      isCollapsed: !parentRow,
      isHovered: false,
      isActive: false,
      children: [],
      ...this.toRowFunc(resource),
    };

    if (prevRow) {
      row.isCollapsed = prevRow.isCollapsed;
      row.isHovered = prevRow.isHovered;
      row.isActive = prevRow.isActive;
      row.isTooltipOpen = prevRow.isTooltipOpen;
      if (option?.discardIsChecked) {
        row.isChecked = false;
      } else {
        row.isChecked = row.isCheckable ? prevRow.isChecked : false;
      }
      // row.isChecked = prevRow.isChecked;
    }

    row.children = this.childMappers.flatMap((child) => {
      const childResource = child.getter(resource);
      if (childResource) {
        if (Array.isArray(childResource)) {
          return childResource.map((childResource) => {
            return child.mapper.toRow(
              childResource,
              findDirectChildGridRow(prevRow, child.mapper.type, childResource),
              row,
              option
            );
          });
        } else {
          return [
            child.mapper.toRow(
              childResource,
              findDirectChildGridRow(prevRow, child.mapper.type, childResource),
              row,
              option
            ),
          ];
        }
      }
      return [];
    });

    return row;
  }

  toRows(
    resources: T[] | undefined,
    prevRows?: R[],
    parentRow?: GridRow,
    option?: GridMapperToRowOption
  ): R[] | undefined {
    if (resources) {
      return resources.map((resource) => {
        return this.toRow(
          resource,
          prevRows?.find((r) => r.id === resource.id),
          parentRow,
          option
        );
      });
    }
    return undefined;
  }

  toResource(row: R): DeepPartial<T> {
    const resource = this.toResourceFunc(row);
    resource.id = row.id;

    this.childMappers.forEach((childMapper) => {
      const childResource = row.children
        .filter((childRow) => childRow._rowType === childMapper.mapper.type)
        .map((childRow) => {
          return childMapper.mapper.toResource(childRow);
        });
      childMapper.integrate(resource as T, childResource);
    });

    return resource;
  }

  copy(rowType?: GridRowType) {
    const copy = new GridMapper(
      rowType || this.type,
      this.toRowFunc,
      this.toResourceFunc
    );
    copy.childMappers = this.childMappers.map((child) => {
      return {
        getter: child.getter,
        integrate: child.integrate,
        mapper: child.mapper.copy(),
      };
    });
    return copy;
  }
}
