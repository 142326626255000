import useListDensePaymentsFetcher from "@/features/invoices/payment/hooks/use-list-dense-payments-fetcher";
import { useMemo } from "react";
import { condenseResourcesById } from "@/features/ui/helpers/condense-helper";
import {
  getListDensePaymentsKey,
  ListDensePaymentsPathParameter,
} from "@/features/invoices/payment/hooks/use-list-dense-payments";
import { Payment } from "@/features/invoices/payment/payment.type";

export default function useCondensePayments() {
  const listDensePaymentsFetcher = useListDensePaymentsFetcher();

  return useMemo(() => {
    return async <T>(
      items: T[],
      getter: (item: T) => ({ id: number } | null)[] | { id: number } | null,
      setter: (item: T, value: Payment[] | Payment) => void,
      parameter: ListDensePaymentsPathParameter
    ) => {
      await condenseResourcesById(
        items,
        getter,
        setter,
        async (ids: number[]) => {
          const response = await listDensePaymentsFetcher(
            getListDensePaymentsKey(parameter, {
              pageSize: ids.length,
              pageNumber: 1,
              id__in: ids,
            })!
          );
          return response.payments;
        }
      );
    };
  }, [listDensePaymentsFetcher]);
}
