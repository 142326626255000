import AuthLayout from "@/features/auth/auth-layout";
import { Spinner, Text } from "@chakra-ui/react";
import Link from "next/link";
import { Helmet } from "react-helmet-async";
import useGetMySelf from "@/features/auth/use-get-my-self";
import useSignOut from "@/features/auth/use-sign-out";
import { MouseEventHandler, useEffect } from "react";
import { useNavigate } from "react-router";
import { getDefaultPageByIdentity } from "@/pages";
import { getIdentity } from "@/features/ui/helpers/identity-helpers";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export default function SignUpPendingPage() {
  const navigate = useNavigate();
  const { isLoading, data: user, error } = useGetMySelf();
  const { isLoading: isSignOutLoading, fire, data: signOutData } = useSignOut();
  const { t, tTitle, tSentence } = useI18nHelper();

  useEffect(() => {
    if (!isSignOutLoading && signOutData) {
      navigate("/auth/sign-in", { replace: true });
    }
  }, [navigate, isSignOutLoading, signOutData]);

  useEffect(() => {
    if (user && user.companies.length > 0) {
      navigate(getDefaultPageByIdentity(getIdentity()));
    }
  }, [user, navigate]);

  if (isLoading) {
    return <>loading...</>;
  }

  if (error) {
    return <>error</>;
  }

  const handleSignOut: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    if (!isSignOutLoading) {
      fire();
    }
  };

  return (
    <AuthLayout
      title={tTitle("auth:welcome")}
      tail={
        <>
          {isSignOutLoading ? (
            <Spinner as="div" />
          ) : (
            <Link href="/" onClick={handleSignOut}>
              <b>
                <u>{tTitle("sign_out.do")}</u>
              </b>
            </Link>
          )}
        </>
      }
    >
      <Helmet>
        <title>Pathrade | Sign Up</title>
        <meta
          name="description"
          content="Sign up for the Pathrade to use a B2B Fashion Platform tailored for the global luxury fashion landscape with a seamless ordering experience and insightful analytics."
        />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:title"
          content="Pathrade | Sign up to the Luxury Fashion B2B Platform & Saas."
        />
        <meta
          property="og:description"
          content="Sign up for the Pathrade to use a B2B Fashion Platform tailored for the global luxury fashion landscape with a seamless ordering experience and insightful analytics."
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Text textAlign="center">
        {t("auth:pending_message", {
          mobileNumber: user?.mobileNumber || "",
          email: "support@unotrading.co.kr",
        })
          .split("\n")
          .map((line, index) => (
            <Text key={index}>
              {line}
              <br />
            </Text>
          ))}
      </Text>
    </AuthLayout>
  );
}
