import AppPaidBySelect, {
  AppPaidBySelectProps,
} from "@/features/ui/app-paid-by-select";
import { useMemo } from "react";

interface AppPaidByAgencySelectProps
  extends Omit<AppPaidBySelectProps, "value" | "onSelect"> {
  value?: boolean;
  onSelect?: (value?: boolean) => void;
}

export default function AppPaidByAgencySelect(
  props: AppPaidByAgencySelectProps
) {
  const { value, onSelect, ...rest } = props;

  const convertedValue = useMemo(() => {
    if (value !== undefined) {
      return value ? "AGENCY" : "BUYER";
    }
    return undefined;
  }, [value]);

  return (
    <AppPaidBySelect
      value={convertedValue}
      onSelect={(value) => {
        onSelect?.(value !== undefined ? value === "AGENCY" : undefined);
      }}
      {...rest}
    />
  );
}
