import useFetcher from "@/utils/use-fetcher";
import {
  getListDepositInvoicesKey,
  ListDepositInvoicesRequest,
  ListDepositInvoicesResponse,
} from "@/features/invoices/deposit-invoices/hooks/use-list-deposit-invoices";
import { useMemo } from "react";
import {
  ListDenseDepositInvoicesRequest,
  ListDenseDepositInvoicesResponse,
  parseListDenseDepositInvoicesKey,
} from "@/features/invoices/deposit-invoices/hooks/use-list-dense-deposit-invoices";
import { AxiosRequestConfig } from "axios";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import useCondenseBankRemittanceReceipts
  from "@/features/invoices/order-confirmation-invoices/hooks/use-condense-bank-remittance-receipts";

export default function useListDenseDepositInvoicesFetcher() {
  const listDepositInvoicesFetcher = useFetcher<
    ListDepositInvoicesRequest,
    ListDepositInvoicesResponse
  >();

  const condenseBankRemittanceReceipts = useCondenseBankRemittanceReceipts();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDenseDepositInvoicesRequest>
    ): Promise<ListDenseDepositInvoicesResponse> => {
      const { parameter, request } = parseListDenseDepositInvoicesKey(config);

      const listDepositInvoicesResponse = await listDepositInvoicesFetcher(
        getListDepositInvoicesKey(parameter, request)
      );

      if (listDepositInvoicesResponse.depositInvoices.length > 0) {
        const promise1 = condenseBankRemittanceReceipts(
          listDepositInvoicesResponse.depositInvoices,
          (item) => item.bankRemittanceReceipt,
          (item, value) => {
            if (!Array.isArray(value)) {
              item.bankRemittanceReceipt = value;
            }
          },
          {
            by: parameter.by,
            companyId: parameter.companyId,
          }
        );

        await Promise.all([promise1]);
      }

      // @ts-ignore
      return Promise.resolve(listDepositInvoicesResponse);
    };
  }, [listDepositInvoicesFetcher, condenseBankRemittanceReceipts]);
}
