import {
  Company,
  CompanyType,
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { Identity } from "@/features/ui/hooks/use-identity";
import {
  isIdentity,
  toIdentityParameter,
} from "@/features/ui/helpers/identity-helpers";
import { getUrlPrefixByIdentityParameter } from "@/features/ui/helpers/zwr-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { Forwarder } from "@/features/invoices/triangular-trades/triangular-trade.type";
//
// export interface ListForwarderListWithoutPagingRequest {
//   type__in?: CompanyType[];
// }

export interface ListForwarderListRequest extends PageNumberBasedListRequest {}

export interface ListForwarderListResponse extends PageNumberBasedListResponse {
  forwarderList: Forwarder[];
}

export interface ListForwarderListPathParameter extends IdentityParameter {}

export function getListForwarderListPathParameter(
  identity: Identity | null
): ListForwarderListPathParameter | null {
  return toIdentityParameter(identity);
}

export function getListForwarderListKey(
  identityOrParameter?: Identity | ListForwarderListPathParameter | null,
  request?: ListForwarderListRequest
) {
  if (identityOrParameter) {
    let parameter;
    if (isIdentity(identityOrParameter)) {
      parameter = getListForwarderListPathParameter(identityOrParameter)!!;
    } else {
      parameter = identityOrParameter;
    }
    const prefix = getUrlPrefixByIdentityParameter(parameter);
    return {
      url: `${prefix}/forwarder`,
      params: request,
    };
  }
  return null;
}

export default function useListForwarderList(
  parameter?: ListForwarderListPathParameter | null,
  request?: ListForwarderListRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListForwarderListRequest,
    ListForwarderListResponse
  >();
  return useSWR(getListForwarderListKey(parameter, request), fetcher, config);
}
