import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useCreateTriangularTrade, {
  createTriangularTradeKey,
  CreateTriangularTradePathParameter,
  CreateTriangularTradeRequest,
} from "@/features/invoices/triangular-trades/hooks/use-create-triangular-trade";
import { useCallback } from "react";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import { mutateResource } from "@/features/ui/utils/swr-utils";

function mutateTriangularTrades(triangularTradeId?: number) {
  mutateResource("triangular-trades", triangularTradeId);
}

export function useTriangularTradeDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { fire: fireCreateTriangularTrade } = useCreateTriangularTrade();

  const addCreate = useCallback(
    async (
      parameter: CreateTriangularTradePathParameter,
      request: Omit<CreateTriangularTradeRequest, "importedFiles"> & {
        importedFiles: File[];
      }
    ) => {
      addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return request.importedFiles;
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            importedFiles: storeObjectResponses,
          };
        },
        fire: fireCreateTriangularTrade,
        keyOrFunc: calcYWRUrl(createTriangularTradeKey, parameter),
        onPostFunc: () => {
          mutateTriangularTrades();
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreateTriangularTrade, showError]
  );

  return {
    addCreate,
  };
}
