import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useEffect, useMemo, useState } from "react";
import {
  ListPaymentsPathParameter,
  ListPaymentsRequest,
  useListPayments,
} from "@/features/invoices/payment/hooks/use-list-payments";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { PaymentRow } from "@/features/invoices/payment/payment-invoice-row.type";
import { paymentRowMapper } from "@/features/invoices/utils/payment-row-mapper";
import AppGrid, { AppGridMeta } from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import IsolatedPanelSkeleton from "@/features/invoices/isolated-panel-skeleton";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";

interface IsolatedPaymentPanelProps {
  paymentIds?: number[];
  title?: string;
}

export default function IsolatedPaymentPanel({
  title,
  paymentIds,
}: IsolatedPaymentPanelProps) {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const { error: showError } = useAppToasts();

  const key = useMemo(():
    | { parameter: ListPaymentsPathParameter; request: ListPaymentsRequest }
    | undefined => {
    if (identity?.company && paymentIds && paymentIds.length > 0) {
      const request: ListPaymentsRequest = {
        id__in: paymentIds,
        pageNumber: 1,
        pageSize: paymentIds.length,
      };

      if (identity?.company?.type === "BUYER") {
        request.status__in = ["NORMAL", "COMPLETED"];
      }

      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, paymentIds, identity]);

  const {
    data: listPaymentsData,
    error: listPaymentsError,
    mutate: mutateListPayments,
  } = useListPayments(key?.parameter, key?.request, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (listPaymentsError) {
      showError(listPaymentsError);
    }
  }, [listPaymentsError, showError]);

  const [rows, setRows] = useState<PaymentRow[] | undefined>();

  const rowMapper = useMemo(() => {
    return paymentRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(
        listPaymentsData?.payments.map((payment) => {
          return {
            ...payment,
            isRead: false,
          };
        }),
        prev
      );
    });
  }, [listPaymentsData, rowMapper]);

  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: mutateListPayments,
  });
  const metas = useMemo((): AppGridMeta[] | undefined => {
    switch (identity?.company?.type) {
      case "AGENCY": {
        return [
          {
            rowType: "Payment",
            gap: "8px",
            columns: [
              invoiceGrid.pairs.season.column,
              invoiceGrid.pairs.sender.column,
              invoiceGrid.pairs.receiver.column,
              invoiceGrid.pairs.title.column,
              invoiceGrid.pairs.unremittedAmount.column,
              invoiceGrid.pairs.remittedAmount.column,
              invoiceGrid.pairs.status.column,
              invoiceGrid.pairs.requestedOn.column,
            ],
            fields: [
              invoiceGrid.pairs.season.field,
              invoiceGrid.pairs.sender.field,
              invoiceGrid.pairs.receiver.field,
              invoiceGrid.pairs.title.field,
              invoiceGrid.pairs.unremittedAmount.field,
              invoiceGrid.pairs.remittedAmount.field,
              invoiceGrid.pairs.status.field,
              invoiceGrid.pairs.requestedOn.field,
            ],
            indicator: (row) => (
              <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
            ),
            onClick: (row) => {
              navigate(`/payments/${row.id}`);
            },
          },
        ];
      }
      case "BUYER": {
        return [
          {
            rowType: "Payment",
            gap: "8px",
            columns: [
              invoiceGrid.pairs.season.column,
              invoiceGrid.pairs.sender.column,
              invoiceGrid.pairs.receiver.column,
              invoiceGrid.pairs.title.column,
              invoiceGrid.pairs.unremittedAmount.column,
              invoiceGrid.pairs.remittedAmount.column,
              invoiceGrid.pairs.status.column,
              invoiceGrid.pairs.requestedOn.column,
            ],
            fields: [
              invoiceGrid.pairs.season.field,
              invoiceGrid.pairs.sender.field,
              invoiceGrid.pairs.receiver.field,
              invoiceGrid.pairs.title.field,
              invoiceGrid.pairs.unremittedAmount.field,
              invoiceGrid.pairs.remittedAmount.field,
              invoiceGrid.pairs.status.field,
              invoiceGrid.pairs.requestedOn.field,
            ],
            indicator: (row) => (
              <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
            ),
            onClick: (row) => {
              navigate(`/payments/${row.id}`);
            },
          },
        ];
      }
      case "BOUTIQUE": {
        return [
          {
            rowType: "Payment",
            gap: "8px",
            columns: [
              invoiceGrid.pairs.season.column,
              invoiceGrid.pairs.sender.column,
              invoiceGrid.pairs.receiver.column,
              invoiceGrid.pairs.title.column,
              invoiceGrid.pairs.unremittedAmount.column,
              invoiceGrid.pairs.remittedAmount.column,
              invoiceGrid.pairs.status.column,
              invoiceGrid.pairs.requestedOn.column,
            ],
            fields: [
              invoiceGrid.pairs.season.field,
              invoiceGrid.pairs.sender.field,
              invoiceGrid.pairs.receiver.field,
              invoiceGrid.pairs.title.field,
              invoiceGrid.pairs.unremittedAmount.field,
              invoiceGrid.pairs.remittedAmount.field,
              invoiceGrid.pairs.status.field,
              invoiceGrid.pairs.requestedOn.field,
            ],
            indicator: (row) => (
              <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
            ),
            onClick: (row) => {
              navigate(`/payments/${row.id}`);
            },
          },
        ];
      }
    }
  }, [
    identity?.company?.type,
    invoiceGrid.actions.downloadInvoice,
    invoiceGrid.pairs.receiver.column,
    invoiceGrid.pairs.receiver.field,
    invoiceGrid.pairs.remittedAmount.column,
    invoiceGrid.pairs.remittedAmount.field,
    invoiceGrid.pairs.requestedOn.column,
    invoiceGrid.pairs.requestedOn.field,
    invoiceGrid.pairs.season.column,
    invoiceGrid.pairs.season.field,
    invoiceGrid.pairs.sender.column,
    invoiceGrid.pairs.sender.field,
    invoiceGrid.pairs.title.column,
    invoiceGrid.pairs.title.field,
    invoiceGrid.pairs.unremittedAmount.column,
    invoiceGrid.pairs.unremittedAmount.field,
    navigate,
  ]);

  if (key === undefined || metas === undefined) {
    return null;
  } else if (rows === undefined) {
    return <IsolatedPanelSkeleton />;
  }

  return (
    <AppPanel
      title={tTitle(title || "common:related_payments")}
      variant={"ghost"}
    >
      <AppGrid isAnchored={false} rows={rows} metas={metas} />
    </AppPanel>
  );
}
