import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormControl, FormLabel, Grid, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import React, { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useCreateForwarder from "@/features/invoices/triangular-trades/hooks/use-create-forwarder";
import { Forwarder } from "@/features/invoices/triangular-trades/triangular-trade.type";
import enLocale from "i18n-iso-countries/langs/en.json";

export interface CreateForwarderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (forwarder: Forwarder) => void;
}

interface FormFields {
  name: string;
}

export default function CreateForwarderModal({
  isOpen,
  onClose,
  onCreate,
}: CreateForwarderModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "common",
    prefix: "create_forwarder_form",
  });

  const validateSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().label("name").required(withMessage.required),
    });
  }, [withMessage.required]);

  async function validate(values: FormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const {
    fire: fireCreateForwarder,
    error: createForwarderError,
    data: createForwarderData,
  } = useCreateForwarder();

  useEffect(() => {
    if (createForwarderError) {
      showError(createForwarderError);
    }
  }, [createForwarderError, showError]);

  useEffect(() => {
    if (createForwarderData) {
      onCreate?.(createForwarderData);
      onClose();
    }
  }, [createForwarderData, onClose, onCreate]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company) {
        return fireCreateForwarder(
          {
            by: identity.company.type,
            companyId: identity?.company?.id,
          },
          {
            name: values.name,
          }
        );
      }
    },
    [fireCreateForwarder, identity]
  );

  const options = useMemo(() => {
    if (!enLocale.countries) {
      return [];
    }
    return Object.entries(enLocale.countries).map(([key, value]) => ({
      name: t(`countries\:${key}`),
      value: key,
    }));
  }, [t]);
  options.sort((a, b) => a.name.localeCompare(b.name));
  options.sort((a, b) => {
    if (a.value === "KR") {
      return -1;
    }
    if (b.value === "KR") {
      return 1;
    }
    if (a.value === "IT") {
      return -1;
    }
    if (b.value === "IT") {
      return 1;
    }
    return 0;
  });

  return (
    <Formik<FormFields>
      initialValues={{
        name: "",
      }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={"create_forwarder_form.title"}
        submitName={"add.do"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text fontSize={"12px"}>{t("create_departure_form.message")}</Text>

        <Form>
          <Grid
            templateColumns={"1fr 1fr"}
            templateRows={"repeat(1, 64px)"}
            gap={"8px"}
          >
            <Field name="name">
              {(props: FieldProps) => {
                return (
                  <FormControl
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridTemplateRows={"16px 40px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"18px"}
                    gridColumn={"span 2"}
                    isRequired
                  >
                    <FormLabel
                      m={"0"}
                      p={"0"}
                      fontSize={"12px"}
                      flexShrink={"0"}
                    >
                      {tTitle("create_forwarder_form.field.name.label")}
                    </FormLabel>
                    <AppInput
                      placeholder={t(
                        "common:create_departure_form.field.placeholder",
                        {
                          fieldName: tTitle(
                            "create_forwarder_form.field.name.label"
                          ),
                        }
                      )}
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
          </Grid>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
