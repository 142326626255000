import { Formik } from "formik";
import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback, useEffect, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import TransportationForm from "@/features/transportations/transportation-form";
import useTransportationFormValidationSchema from "@/features/transportations/hooks/use-transportation-form-validation";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import useIdentity from "@/features/ui/hooks/use-identity";
import useGetTransportation, {
  GetTransportationParameter,
  GetTransportationRequest,
} from "@/features/transportations/hooks/use-get-transportation";
import { DateUtils } from "@/features/ui/utils/date-utils";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import {
  TransportationDetail,
  TransportationStatuses,
} from "@/features/transportations/transportation.type";
import AppEditOrSaveButton from "@/features/transportations/app-edit-or-save-button";
import { useTransportationDumbTransaction } from "@/features/transportations/hooks/use-transportation-dumb-transaction";
import useUpdateTransportation, {
  UpdateTransportationRequest,
} from "@/features/transportations/hooks/use-update-transportation";
import { isFile, isStoredObject } from "@/features/types";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";
import useListAllProformaInvoices, {
  ListAllProformaInvoicesPathParameter,
  ListAllProformaInvoicesRequest,
} from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";

interface TransportationDetailPageProps {
  isEditing?: boolean;
}

export default function TransportationDetailPage({
  isEditing = false,
}: TransportationDetailPageProps) {
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const { transportationId } = useRequiredParams<{
    transportationId: number;
  }>();
  const identity = useIdentity();
  const { navigate } = useRouterHelper();

  const getTransportationKey = useMemo(():
    | {
        parameter: GetTransportationParameter;
        request: GetTransportationRequest;
      }
    | undefined => {
    if (identity?.company && transportationId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          transportationId,
        },
        request: {},
      };
    }
  }, [identity, transportationId]);

  const {
    data: transportation,
    error: getTransportationError,
    mutate: mutateGetTransportation,
  } = useGetTransportation(
    getTransportationKey?.parameter,
    getTransportationKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (getTransportationError) {
      showError(getTransportationError);
    }
  }, [getTransportationError, showError]);

  const { isUpdateQueued, handleSubmit } = useDumbMixedUpdate({
    dumbFunction: useTransportationDumbTransaction,
    smartFunction: useUpdateTransportation,
    isSmart: (request): request is UpdateTransportationRequest => {
      return request.files.every(isStoredObject);
    },
    toRequest: (values: TransportationFormFields) => {
      const request: Omit<UpdateTransportationRequest, "files"> = {
        id: values.id!,
        status: values.status!,
        name: values.name!,
        shippedFromList: values.shippedFromList!,
        shippedTo: values.shippedTo!,
        shippedToAddress: values.shippedToAddress!,
        forwarderInvoiceNumbers: values.forwarderInvoiceNumbers!,
        houseBlNumbers: values.houseBlNumbers!,
        cost: values.cost!,
        finalEta: values.finalEta!,
        flightArrivalAirport: values.flightArrivalAirport!,
        flightEta: DateUtils.toDateTimeString(values.flightEta!),
        flightAta: values.flightAta
          ? DateUtils.toDateTimeString(values.flightAta)
          : null,
        flightDepartureAirport: values.flightDepartureAirport!,
        flightEtd: DateUtils.toDateTimeString(values.flightEtd!),
        flightAtd: values.flightAtd
          ? DateUtils.toDateTimeString(values.flightAtd)
          : null,
        flightNumber: values.flightNumber!,
        proformaInvoices: values.proformaInvoices.map((pInvoice) => {
          return {
            id: pInvoice.id,
          };
        })!,
      };

      if (values.files.some(isFile)) {
        return {
          ...request,
          files: values.files,
        };
      } else {
        return {
          ...request,
          files: values.files.filter(isStoredObject),
        };
      }
    },
    mutate: mutateGetTransportation,
    defaultRedirectUrl: `/transportations/${transportationId}`,
  });

  const validateSchema = useTransportationFormValidationSchema();
  const validate = useCallback(
    async (values: TransportationFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const listProformaInvoicesKey = useMemo(():
    | {
        parameter: ListAllProformaInvoicesPathParameter;
        request: ListAllProformaInvoicesRequest;
      }
    | undefined => {
    if (
      identity?.company &&
      transportation &&
      transportation.proformaInvoices.length > 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: transportation.proformaInvoices.map(
            (pInvoice) => pInvoice.id
          ),
        },
      };
    }
  }, [identity, transportation]);

  const { data: proformaInvoices, error: listProformaInvoicesError } =
    useListAllProformaInvoices(
      listProformaInvoicesKey?.parameter,
      listProformaInvoicesKey?.request
    );

  useEffect(() => {
    if (listProformaInvoicesError) {
      showError(listProformaInvoicesError);
    }
  }, [listProformaInvoicesError, showError]);

  const initialValues = useMemo((): TransportationFormFields => {
    if (transportation && proformaInvoices) {
      let details: TransportationDetail[] = [];
      if (transportation.details.length > 0) {
        const i = TransportationStatuses.findIndex(
          (status) =>
            transportation.details[transportation.details.length - 1].status ===
            status
        );
        details = [
          ...transportation.details.map((detail, index) => {
            return {
              ...detail,
            };
          }),
          ...TransportationStatuses.slice(i + 1).map((status) => {
            return {
              status,
              createdAt: null,
            };
          }),
        ];
      }

      return {
        id: transportation.id,
        name: transportation.name,
        status: transportation.status,
        shippedFromList: transportation.shippedFromList,
        shippedTo: transportation.shippedTo,
        shippedToAddress: transportation.shippedToAddress,
        forwarderInvoiceNumbers: transportation.forwarderInvoiceNumbers,
        houseBlNumbers: transportation.houseBlNumbers,
        flightNumber: transportation.flightNumber,
        flightEtd: DateUtils.toDate(transportation.flightEtd),
        flightAta: transportation.flightAta
          ? DateUtils.toDate(transportation.flightAta)
          : undefined,
        flightDepartureAirport: transportation.flightDepartureAirport,
        flightEta: DateUtils.toDate(transportation.flightEta),
        flightAtd: transportation.flightAtd
          ? DateUtils.toDate(transportation.flightAtd)
          : undefined,
        flightArrivalAirport: transportation.flightArrivalAirport,
        finalEta: transportation.finalEta || undefined,
        cost: transportation.cost,
        details: details,
        files: transportation.files,
        proformaInvoices: proformaInvoices,
      };
    }
    return {
      details: [],
      shippedFromList: [],
      proformaInvoices: [],
      forwarderInvoiceNumbers: [],
      houseBlNumbers: [],
      files: [],
    };
  }, [proformaInvoices, transportation]);

  return (
    <Formik<TransportationFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          await handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              transportationId: values.id!,
            },
            values,
            formikHelpers
          );
        } else {
          return false;
        }
      }}
    >
      {(props) => {
        const isEditQueued =
          identity?.company && props.values.id
            ? isUpdateQueued({
                by: identity.company.type,
                companyId: identity.company.id,
                transportationId: props.values.id,
              })
            : false;
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"Transportation"} as={"h2"} fontSize={"14px"}>
                  {tTitle("transportation")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppEditOrSaveButton
                  key={"editOrSave"}
                  formik={props}
                  isEditQueued={isEditQueued}
                />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/transportations"}
              blockNavigateToBack={props.dirty && isEditing}
            />
            <TransportationForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
