import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";

export interface FetchLineSheetSetsFromEmailRequest {
  period?: number;
  gmailId__in?: string[];
  make_line_sheet_sets: boolean;
  force: boolean;
}

export interface FetchLineSheetSetsFromEmailResponse {
  message: string;
}

export interface FetchLineSheetSetsFromEmailParameter {}

export const fetchLineSheetSetsFromEmailKey: YWRKey<FetchLineSheetSetsFromEmailParameter> =
  {
    url: (parameter) => {
      return `/gmails/sync`;
    },
    method: "post",
  };

export default function useFetchLineSheetSetsFromEmail() {
  const fetcher = useFetcher<
    FetchLineSheetSetsFromEmailRequest,
    FetchLineSheetSetsFromEmailResponse
  >();

  return useYWR(fetchLineSheetSetsFromEmailKey, fetcher);
}
