import { Field, FieldProps, FormikProps } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toTitle, toUpper } from "@/utils/case";
import AppPriceInput from "@/features/invoices/app-price-input";
import ProformaInvoiceDetailsInput from "@/features/invoices/proforma-invoices/proforma-invoice-details-input";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import AppInput from "@/features/ui/app-input";
import AppCreditInvoiceSelector from "@/features/invoices/app-credit-invoice-selector";
import AppTagController from "@/features/invoices/app-tag-controller";
import { ListAllCreditInvoicesRequest } from "@/features/invoices/credit-invoices/hooks/use-list-all-credit-invoices";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import DetailBrandProvider from "@/features/invoices/proforma-invoices/detail-brand-provider";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { CreditInvoice } from "@/features/invoices/credit-invoices/credit-invoice.type";
import PriceUtils from "@/features/ui/utils/price-utils";

interface ProformaInvoiceFigureFormControlProps<T> {
  formik: FormikProps<T>;
  isEditing?: boolean;
  isDisabled?: boolean;
}

interface FormFields extends ProformaInvoiceFormFields {}

export default function ProformaInvoiceFigureFormControl<T extends FormFields>({
  formik,
  isEditing,
  isDisabled,
}: ProformaInvoiceFigureFormControlProps<T>) {
  const { t, tTitle, tCurrencyString } = useI18nHelper();
  const negativeSequencer = useMemo(() => new NegativeSequencer(), []);

  const total = useMemo(() => {
    const amount = PriceUtils.sum(
      formik.values.proformaInvoiceDetailList.map((pDetail) => pDetail.amount),
      "EUR"
    );

    const usedCredit = PriceUtils.sum(
      formik.values.usedCreditInvoices.map((cDetail) => cDetail.amount),
      "EUR"
    );

    const usedDeposit = formik.values.usedDeposit;

    const requiredAmount = PriceUtils.minus(
      [amount, usedCredit, usedDeposit],
      "EUR"
    );

    return {
      amount,
      usedCredit,
      usedDeposit,
      requiredAmount,
    };
  }, [
    formik.values.proformaInvoiceDetailList,
    formik.values.usedCreditInvoices,
    formik.values.usedDeposit,
  ]);

  const handleCreditInvoiceChange = useCallback(
    (next: Pick<CreditInvoice, "id" | "season" | "amount">[]) => {
      formik.setValues((prev) => {
        return {
          ...prev,
          usedCreditInvoices: next,
        };
      });
    },
    [formik]
  );

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          paddingRight={"12px"}
          gridTemplateColumns={"repeat(3, 1fr)"}
          gridTemplateRows={"20px 24px repeat(2, 20px 24px)"}
          gridColumnGap={"8px"}
          gridRowGap={"4px"}
        >
          <Box />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton />
          <Skeleton />
          <Skeleton />

          <Skeleton gridColumn={"1/4"} />
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:invoice_figures")}>
      <Flex gap={"12px"} flexDirection={"column"}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Box fontSize={"12px"} gridColumn={"1/-1"}>
            {toUpper(t("deposit"))}
          </Box>
          <Grid
            gridTemplateColumns={"repeat(3, 1fr) 12px"}
            gridColumnGap={"8px"}
            gridRowGap={"4px"}
          >
            <Field name={"expectedRemainingDepositAmount"}>
              {(
                props: FieldProps<
                  FormFields["expectedRemainingDepositAmount"],
                  FormFields
                >
              ) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    gap={"4px"}
                  >
                    <FormLabel
                      margin={"0"}
                      padding={"0"}
                      minHeight={"0"}
                      minWidth={"0"}
                      fontSize={"12px"}
                      color={"#444440"}
                    >
                      {toTitle(t("total_remaining"))}:
                    </FormLabel>
                    <AppPriceInput
                      value={props.field.value}
                      isDisabled={isDisabled}
                      isReadOnly={!isEditing}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"usedDeposit"}>
              {(props: FieldProps<FormFields["usedDeposit"], FormFields>) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    gap={"4px"}
                  >
                    <FormLabel
                      margin={"0"}
                      padding={"0"}
                      minHeight={"0"}
                      minWidth={"0"}
                      fontSize={"12px"}
                      color={"#444440"}
                    >
                      {toTitle(t("total_using"))}:
                    </FormLabel>
                    <AppPriceInput
                      value={props.field.value}
                      onChange={(value) => {
                        props.form.setValues((prev) => {
                          return {
                            ...prev,
                            usedDeposit: value,
                          };
                        });
                      }}
                      isDisabled={
                        isDisabled ||
                        props.form.values.isDetailsEditedManually ||
                        (props.form.values.expectedRemainingDepositAmount
                          ?.value || 0) === 0
                      }
                      isReadOnly={!isEditing}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"usedDepositComment"}>
              {(
                props: FieldProps<FormFields["usedDepositComment"], FormFields>
              ) => {
                return (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"end"}
                  >
                    <AppInput
                      value={props.field.value}
                      placeholder={t("comment")}
                      isReadOnly={!isEditing}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </Box>
                );
              }}
            </Field>
          </Grid>
        </Flex>

        <Flex flexDirection={"column"} gap={"4px"}>
          <Box fontSize={"12px"}>{toUpper(t("credit"))}</Box>
          <Grid
            gridTemplateColumns={"repeat(3, 1fr) 12px"}
            gridTemplateRows={"auto 28px"}
            gridColumnGap={"8px"}
            gridRowGap={"8px"}
          >
            <Field name={"usedCreditInvoices"}>
              {(
                props: FieldProps<FormFields["usedCreditInvoices"], FormFields>
              ) => {
                const {
                  issuedBy,
                  orderedBy,
                  issuedThrough,
                  originallyIssuedBy,
                } = props.form.values;
                let request: ListAllCreditInvoicesRequest | undefined =
                  undefined;

                if (
                  issuedThrough &&
                  orderedBy &&
                  (issuedBy || originallyIssuedBy)
                ) {
                  request = {
                    isUsed__eq: false,
                    "issuedToCompany.id__eq": orderedBy.id,
                    "issuedByCompany.id__in": AppUtils.filterDefined([
                      issuedBy?.id,
                      originallyIssuedBy?.id,
                    ]),
                    "issuedThroughCompany.id__eq": issuedThrough.id,
                  };
                }

                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    gap={"4px"}
                  >
                    <FormLabel
                      margin={"0"}
                      padding={"0"}
                      minHeight={"0"}
                      minWidth={"0"}
                      fontSize={"12px"}
                      color={"#444440"}
                    >
                      {toTitle(t("using"))}:
                    </FormLabel>
                    <Box alignSelf={"stretch"}>
                      <AppCreditInvoiceSelector
                        width={"100%"}
                        excludeIds={formik.values.usedCreditInvoices.map(
                          (c) => c.id
                        )}
                        request={request}
                        isReadOnly={!isEditing}
                        isDisabled={isDisabled}
                        onSelect={(value) => {
                          // props.form.setValues((prev) => {
                          //   return {
                          //     ...prev,
                          //     usedCreditInvoices: [
                          //       ...prev.usedCreditInvoices,
                          //       value,
                          //     ],
                          //   };
                          // });
                          handleCreditInvoiceChange([
                            ...props.field.value,
                            value,
                          ]);
                        }}
                      />
                    </Box>
                  </FormControl>
                );
              }}
            </Field>

            <Flex
              display={"flex"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"end"}
              gap={"4px"}
            >
              <Text
                margin={"0"}
                padding={"0"}
                minHeight={"0"}
                minWidth={"0"}
                fontSize={"12px"}
                color={"#444440"}
              >
                {toTitle(t("total_using"))}:
              </Text>
              <AppPriceInput
                isDisabled={isEditing}
                isReadOnly={!isEditing}
                value={PriceUtils.sum(
                  formik.values.usedCreditInvoices.map(
                    (creditInvoice) => creditInvoice.amount
                  ),
                  "EUR"
                )}
              />
            </Flex>

            <Field name={"usedCreditComment"}>
              {(
                props: FieldProps<FormFields["usedCreditComment"], FormFields>
              ) => {
                return (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"end"}
                  >
                    <AppInput
                      value={props.field.value}
                      isReadOnly={isEditing}
                      placeholder={t("comment")}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </Box>
                );
              }}
            </Field>

            <GridItem gridColumn={"1/-2"} alignSelf={"stretch"}>
              <AppTagController
                values={formik.values.usedCreditInvoices.map((credit) => {
                  return {
                    id: credit.id,
                    name: `${credit.season} / ${tCurrencyString(
                      credit.amount
                    )}`,
                  };
                })}
                isDisabled={!isEditing}
                onChange={(tags) => {
                  handleCreditInvoiceChange(
                    formik.values.usedCreditInvoices.filter((credit) => {
                      return tags.some((tag) => tag.id === credit.id);
                    })
                  );
                }}
              />
            </GridItem>
          </Grid>
        </Flex>

        <Box position={"relative"}>
          <Field name={"proformaInvoiceDetailList"}>
            {(
              props: FieldProps<
                FormFields["proformaInvoiceDetailList"],
                FormFields
              >
            ) => {
              return (
                <DetailBrandProvider
                  orderConfirmationInvoices={
                    props.form.values.orderConfirmationInvoices
                  }
                  orderSheetSets={props.form.values.orderSheetSets}
                  issuedBy={
                    props.form.values.originallyIssuedBy ||
                    props.form.values.issuedBy
                  }
                  negativeSequencer={negativeSequencer}
                >
                  <ProformaInvoiceDetailsInput
                    value={props.field.value}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    negativeSequencer={negativeSequencer}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                </DetailBrandProvider>
              );
            }}
          </Field>
        </Box>

        <Grid
          gridTemplateColumns={"repeat(3, 1fr) 12px"}
          gridColumnGap={"8px"}
          gridRowGap={"4px"}
        >
          <Box></Box>
          <Flex flexDirection={"column"} alignItems={"start"} gap={"4px"}>
            <Text
              margin={"0"}
              padding={"0"}
              minHeight={"0"}
              minWidth={"0"}
              fontSize={"12px"}
              color={"#444440"}
            >
              {toTitle(t("total_amount"))}:
            </Text>
            <AppPriceInput
              value={total.amount}
              isDisabled={isEditing}
              isReadOnly={!isEditing}
            />
          </Flex>

          <Flex flexDirection={"column"} alignItems={"start"} gap={"4px"}>
            <Text
              margin={"0"}
              padding={"0"}
              minHeight={"0"}
              minWidth={"0"}
              fontSize={"12px"}
              color={"#444440"}
            >
              {toTitle(t("common:total_remittance_required_amount.abbr"))}:
            </Text>
            <AppPriceInput
              value={total.requiredAmount}
              isDisabled={isEditing}
              isReadOnly={!isEditing}
            />
          </Flex>
        </Grid>
      </Flex>
    </AppPanel>
  );
}
