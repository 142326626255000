import React, { useMemo } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { Direction } from "@/features/ui/resizable-box";

interface BoxHandlerProps {
  direction: Direction;
  onMouseDown: React.MouseEventHandler<HTMLDivElement>;
  isDisabled?: boolean;
}

export default function BoxHandler({
  direction,
  onMouseDown,
  isDisabled,
}: BoxHandlerProps) {
  const boxProps = useMemo(() => {
    const size = "12px";
    let props: BoxProps = {};

    switch (direction) {
      case "TOP":
        props = {
          ...props,
          width: "100%",
          height: size,
          cursor: "ns-resize",
          top: 0,
        };
        break;
      case "BOTTOM":
        props = {
          ...props,
          width: "100%",
          height: size,
          cursor: "ns-resize",
          bottom: 0,
        };
        break;
      case "LEFT":
        props = {
          ...props,
          width: size,
          height: "100%",
          cursor: "ew-resize",
          left: 0,
          top: 0,
        };
        break;
      case "RIGHT":
        props = {
          ...props,
          width: size,
          height: "100%",
          cursor: "ew-resize",
          right: 0,
          top: 0,
        };
        break;
      case "TOP_LEFT":
        props = {
          ...props,
          width: size,
          height: size,
          cursor: "nwse-resize",
          top: 0,
          left: 0,
        };
        break;
      case "TOP_RIGHT":
        props = {
          ...props,
          width: size,
          height: size,
          cursor: "nesw-resize",
          top: 0,
          right: 0,
        };
        break;
      case "BOTTOM_LEFT":
        props = {
          ...props,
          width: size,
          height: size,
          cursor: "nesw-resize",
          bottom: 0,
          left: 0,
        };
        break;
      case "BOTTOM_RIGHT":
        props = {
          ...props,
          width: size,
          height: size,
          cursor: "nwse-resize",
          bottom: 0,
          right: 0,
        };
        break;
    }

    return props;
  }, [direction]);
  return (
    <Box position={"absolute"} {...boxProps} onMouseDown={onMouseDown}></Box>
  );
}
