import { useRef } from "react";
import { isEqual } from "lodash";

export default function useMemoizedValue<T>(value: T) {
  const ref = useRef<T>(value);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}
