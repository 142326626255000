import {
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import {
  CreditInvoice,
  CreditInvoiceType,
} from "@/features/invoices/credit-invoices/credit-invoice.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface ListCreditInvoicesRequest extends PageNumberBasedListRequest {
  season__eq?: string;
  season__in?: string[];
  type__eq?: CreditInvoiceType;
  "issuedToCompany.id__eq"?: number;
  // "issuedByCompany.id__eq"?: number;
  "issuedByCompany.id__in"?: number[];
  // issuedByCompany__in?: CompanyId[];
  "issuedThroughCompany.id__eq"?: number;
  issuedOn__eq?: Date;
  id__in?: number[];
  isUsed__eq?: boolean;
  type__in?: CreditInvoiceType[];
}

export interface ListCreditInvoicesResponse
  extends PageNumberBasedListResponse {
  creditInvoices: CreditInvoice[];
}

export interface ListCreditInvoicesPathParameter extends IdentityParameter {}

export const listCreditInvoicesTemplateUrl = `/:by/:companyId/credit-invoices`;

export function getListCreditInvoicesKey(
  parameter: ListCreditInvoicesPathParameter,
  request: ListCreditInvoicesRequest
) {
  return {
    url: calcUrl(listCreditInvoicesTemplateUrl, parameter),
    params: request,
  };
}

export const DEFAULT_LIST_CREDIT_INVOICES_PAGE_SIZE = 10;

export default function useListCreditInvoices(
  parameter: ListCreditInvoicesPathParameter,
  request: ListCreditInvoicesRequest,
  config?: SWRConfiguration<ListCreditInvoicesResponse>
) {
  const fetcher = useFetcher<
    ListCreditInvoicesRequest,
    ListCreditInvoicesResponse
  >();

  return useSWR(getListCreditInvoicesKey(parameter, request), fetcher, config);
}
