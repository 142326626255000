import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { LightOrderSheet } from "@/features/order-sheets/order-sheet.type";
import { AxiosRequestConfig } from "axios";
import {
  createOrderSheetSetKey,
  CreateOrderSheetSetRequest,
  CreateOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-set";
import RequestQueue from "@/features/order-sheet-sets/helpers/request-queue";
import { IdentityParameter } from "@/features/types";
import {
  calcIdentityUrlPrefix,
  calcUrl,
  calcYWRUrl,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface CreateOrderSheetResponse extends LightOrderSheet {
  _orderSheetSetId?: number;
}
export interface CreateOrderSheetRequest {
  lineSheet: {
    id: number;
  };
}

export interface CreateOrderSheetPathParameter extends IdentityParameter {
  orderSheetSetId: number;
  // _lineSheetSetId: number;
  // _lineSheetId: number;
}

export const createOrderSheetTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets`;

export const createOrderSheetKey: YWRKey<CreateOrderSheetPathParameter> = {
  url: (parameter) => {
    return calcUrl(createOrderSheetTemplateUrl, parameter);
  },
  method: "post",
};

export function parseCreateOrderSheetKey(url: string) {
  const urlParser = new UrlParser<CreateOrderSheetPathParameter>(
    createOrderSheetTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useCreateOrderSheet() {
  const createOrderSheetFetcher = useFetcher<
    CreateOrderSheetRequest,
    CreateOrderSheetResponse
  >();

  return useYWR(createOrderSheetKey, createOrderSheetFetcher);
}
