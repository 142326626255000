import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import { IdentityParameter } from "@/features/types";
import { calcIdentityUrlPrefix } from "@/features/ui/helpers/fetcher-helpers";

export interface CreateOrderSheetSetResponse extends LightOrderSheetSet {}

export interface CreateOrderSheetSetRequest {
  lineSheetSet: {
    id: number;
  };
}

export interface CreateOrderSheetSetPathParameter extends IdentityParameter {}

export const createOrderSheetSetKey: YWRKey<CreateOrderSheetSetPathParameter> =
  {
    url: (parameter) => {
      let url = calcIdentityUrlPrefix(parameter);
      url += `/order-sheet-sets`;
      return url;
    },
    method: "post",
  };

export default function useCreateOrderSheetSet() {
  const fetcher = useFetcher<
    CreateOrderSheetSetRequest,
    CreateOrderSheetSetResponse
  >();

  return useYWR(createOrderSheetSetKey, fetcher);
}
