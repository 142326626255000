import {
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ICellEditorParams } from "ag-grid-community";
import {
  FlatProduct,
  isOverlappedValue,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { Box, Flex, Grid, Input, Text } from "@chakra-ui/react";

interface OverlappedNumberEditorProps {
  unit?: string;
}

function OverlappedNumberEditor(
  props: OverlappedNumberEditorProps &
    ICellEditorParams<FlatProduct, number | OverlappedValue<number>>,
  ref: ForwardedRef<any>
) {
  const KEY_BACKSPACE = "Backspace";
  const KEY_F2 = "F2";
  const KEY_ENTER = "Enter";
  const KEY_TAB = "Tab";

  const [top, under] = useMemo(() => {
    if (isOverlappedValue(props.value, (item) => item !== undefined)) {
      return [props.value.top, props.value.under] as const;
    } else {
      return [props.value, undefined];
    }
  }, [props.value]);

  const isContentOverlapped = under !== undefined && top != under;

  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value

      if (typeof props.value === "number") {
        startValue = props.value;
      } else if (isOverlappedValue(props.value, (item) => item !== undefined)) {
        startValue = props.value.top;
      }
      if (props.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
    initialState.highlightAllOnFocus
  );

  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const eInput = refInput.current;

    if (eInput) {
      eInput.focus();

      if (highlightAllOnFocus) {
        eInput.select();
        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0) {
          eInput.setSelectionRange(length, length);
        }
      }
    }
  }, [highlightAllOnFocus]);

  const cancelBeforeStart = props.charPress && !/\d/.test(props.charPress);

  const isLeftOrRight = (event: any) => {
    return ["ArrowLeft", "ArrowLeft"].indexOf(event.key) > -1;
  };

  const isCharNumeric = (charStr: string) => {
    return !!/\d/.test(charStr);
  };

  const isKeyPressedNumeric = (event: any) => {
    const charStr = event.key;
    return isCharNumeric(charStr) || charStr === ".";
  };

  const isBackspace = (event: any) => {
    return event.key === KEY_BACKSPACE;
  };

  const finishedEditingPressed = (event: any) => {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (isLeftOrRight(event) || isBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }

    if (finishedEditingPressed(event)) {
      props.stopEditing();
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (value === undefined) {
          return undefined;
        } else if (typeof value === "number") {
          return value;
        } else {
          return parseFloat(value);
        }
      },
      isCancelBeforeStart() {
        return cancelBeforeStart;
      },
      // isCancelAfterEnd() {
      //   return value > 10000000;
      // },
    };
  });

  return (
    //이걸 ag grid가 무조건 flex로 바꾸네;
    <Flex
      width={"100%"}
      padding={"0 4px"}
      gap={"4px"}
      // backgroundColor={"red.50"}
    >
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        flexGrow={"1"}
        flexBasis={"40px"}
      >
        <Input
          ref={refInput}
          value={value}
          textAlign={"center"}
          paddingLeft={"var(--ag-grid-size)"}
          fontSize={"12px"}
          h={"auto"}
          minH={"0"}
          minW={"0"}
          p={"0"}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          // border={"1px solid red !important"}
        />
        {props.unit}
      </Flex>
      {isContentOverlapped && (
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          color={"#8F8F8C"}
          justifySelf={"start"}
          flexGrow={"1"}
          flexBasis={"40px"}
        >
          (
          <Text
            textAlign={"right"}
            fontFamily={"Inter"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={400}
            lineHeight={"normal"}
            textDecorationLine={"line-through"}
          >
            {under}
            {props.unit}
          </Text>
          )
        </Flex>
      )}
    </Flex>
  );
}

export default forwardRef(OverlappedNumberEditor);
