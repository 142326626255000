import {
  TransportationStatus,
  TransportationStatuses,
} from "@/features/transportations/transportation.type";
import { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";
import AppSelect from "@/features/line-sheet-sets/app-select";

interface AppTransportationSelectProps {
  value?: TransportationStatus;
  onSelect?: (value?: TransportationStatus) => void;
  //include
  from?: TransportationStatus;
}

export default function AppTransportationSelect({
  value,
  onSelect,
  from,
}: AppTransportationSelectProps) {
  const { tUpper, tTitle } = useI18nHelper();
  const options = useMemo(() => {
    let statuses = TransportationStatuses;
    if (from) {
      const i = TransportationStatuses.indexOf(from);
      statuses = TransportationStatuses.slice(i);
    }

    return statuses.map((status) => {
      return {
        name: tUpper(`transportation.status.${toLower(status)}`),
        value: status,
      };
    });
  }, [from, tUpper]);

  return (
    <AppSelect<TransportationStatus>
      name={tTitle("status")}
      options={options}
      value={value}
      onSelect={onSelect}
    />
  );
}
