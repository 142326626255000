import { Flex } from "@chakra-ui/react";
import AppButton, {
  AppButtonProps,
} from "@/features/line-sheet-sets/app-button";
import SVG from "react-inlinesvg";
import AppPaginationButton, {
  AppPaginationArrow,
} from "@/features/line-sheet-sets/app-pagination-button";
import { useLocation } from "react-router";
import { Location } from "@remix-run/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ca } from "date-fns/locale";
import { range } from "lodash";
import { toSnake } from "@/utils/case";
import { useSearchParams } from "react-router-dom";
interface AppPaginationProps {
  capacity: number;
  pageSize: number;
  totalCount?: number;
  pageNumber?: number;
  setPageNumber: (pageNumber: number) => void;
}

export default function AppPagination({
  capacity,
  pageSize,
  totalCount: _totalCount,
  pageNumber = 1,
}: AppPaginationProps) {
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const key = useMemo(() => {
    return searchParams.toString().replace(/page_number=\d+/, "");
  }, [searchParams]);
  const [cachedTotalCount, setCachedTotalCount] = useState<number>(0);

  useEffect(() => {
    setCachedTotalCount(0);
  }, [key]);

  useEffect(() => {
    if (_totalCount !== undefined) {
      setCachedTotalCount(_totalCount);
    }
  }, [_totalCount, key]);

  const components = useMemo(() => {
    const maxPageNumber = Math.ceil(cachedTotalCount / pageSize);
    const minPageNumber = 1;
    function func(nextPageNumber: number) {
      if (nextPageNumber >= minPageNumber && nextPageNumber <= maxPageNumber) {
        return {
          pageNumber: nextPageNumber,
          isActive: pageNumber === nextPageNumber,
        };
      }
    }

    function getPageNumberRange() {
      let start;
      if (pageNumber % capacity === 0) {
        start = (Math.floor(pageNumber / capacity) - 1) * capacity + 1;
      } else {
        start = Math.floor(pageNumber / capacity) * capacity + 1;
      }

      const end = start + capacity - 1;
      return [start, end];
    }

    const tuples: [
      AppPaginationArrow | number,
      { pageNumber: number | undefined; isActive: boolean } | undefined
    ][] = [
      ["double-left", func(pageNumber - capacity)],
      ["left", func(pageNumber - 1)],
    ];

    const [start, end] = getPageNumberRange();
    range(start, end + 1).forEach((i) => {
      tuples.push([i, func(i)]);
    });

    tuples.push(["right", func(pageNumber + 1)]);
    tuples.push(["double-right", func(pageNumber + capacity)]);

    return tuples
      .filter(
        (
          i
        ): i is [
          AppPaginationArrow | number,
          { pageNumber: number | undefined; isActive: boolean }
        ] => i[1] !== undefined
      )
      .map(([pageNumberOrSymbol, query]) => {
        const { isActive, pageNumber } = query;

        const url = location.pathname + location.search;
        let nextUrl;
        if (url.includes("page_number=")) {
          nextUrl = url.replace(/page_number=\d+/, `page_number=${pageNumber}`);
        } else if (url.includes("?")) {
          nextUrl = url + `&page_number=${pageNumber}`;
        } else {
          nextUrl = url + `?page_number=${pageNumber}`;
        }

        //const href = location.pathname + "?" + next.toString();
        return (
          <AppPaginationButton
            key={pageNumberOrSymbol}
            pageNumber={pageNumberOrSymbol}
            isActive={isActive}
            href={nextUrl}
          />
        );
      });
  }, [
    cachedTotalCount,
    pageSize,
    pageNumber,
    capacity,
    location.pathname,
    location.search,
  ]);

  return (
    <Flex flexDirection={"row"} gap={"8px"}>
      {components}
    </Flex>
  );
}
