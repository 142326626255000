import {
  LightLineSheetSet,
  LineSheetSet,
  LineSheetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { CompanyType, StoredObject } from "@/features/types";

export interface CreateLineSheetSetRequest
  extends Omit<
    LineSheetSet,
    | "id"
    | "issuedAt"
    | "createdAt"
    | "issuedToBuyers"
    | "status"
    | "reviewStatus"
    | "compressionStatus"
    | "createdByCompany"
    | "updatedAt"
    | "publishedAt"
    | "compressedImportedFile"
    | "isRead"
    | "isDeleted"
    | "lineSheets"
    | "attachments"
    | "issuedByBoutique"
    | "issuedByBrand"
  > {
  lineSheets: {
    type: LineSheetType;
    file: Pick<StoredObject, "id">;
  }[];
  attachments: Pick<StoredObject, "id">[];
  issuedByBoutique: {
    id: number;
  };
  issuedByBrand: {
    id: number;
  } | null;
}

export interface CreateLineSheetSetResponse extends LightLineSheetSet {}

export interface CreateLineSheetSetPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createLineSheetSetTemplateUrl = `/:by/:companyId/line-sheet-sets-v2`;

export const createLineSheetSetKey: YWRKey<CreateLineSheetSetPathParameter> = {
  url: (parameters: CreateLineSheetSetPathParameter) => {
    return calcUrl(createLineSheetSetTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateLineSheetSet() {
  const fetcher = useFetcher<
    CreateLineSheetSetRequest,
    CreateLineSheetSetResponse
  >();

  return useYWR(createLineSheetSetKey, fetcher);
}
