import useSWR, { SWRConfiguration } from "swr";
import {
  ListAllOrderConfirmationInvoicesPathParameter,
  ListAllOrderConfirmationInvoicesRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-all-order-confirmation-invoices";
import useListDenseOrderConfirmationInvoicesFetcher from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices-fetcher";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";

export const listAllDenseOrderConfirmationInvoicesTemplateUrl = `/:by/:companyId/dense-order-confirmation-invoices`;

export function getListAllDenseOrderConfirmationInvoicesKey(
  parameter?: ListAllOrderConfirmationInvoicesPathParameter,
  request?: ListAllOrderConfirmationInvoicesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllDenseOrderConfirmationInvoicesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}
export default function useListAllDenseOrderConfirmationInvoices(
  parameter?: ListAllOrderConfirmationInvoicesPathParameter,
  request?: ListAllOrderConfirmationInvoicesRequest,
  config?: SWRConfiguration
) {
  const listOrderConfirmationInvoicesFetcher =
    useListDenseOrderConfirmationInvoicesFetcher();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      listOrderConfirmationInvoicesFetcher,
      (response) => response.orderConfirmationInvoices
    );
  }, [listOrderConfirmationInvoicesFetcher]);

  return useSWR(
    getListAllDenseOrderConfirmationInvoicesKey(parameter, request),
    fetcher,
    config
  );
}
