import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  AppDialogProps,
  appDialogState,
} from "@/features/ui/recoils/app-dialog-state";
import { uniqueId } from "lodash";

export default function useAppDialog() {
  const appDialogs = useRecoilValue(appDialogState);
  const addDialog = useRecoilCallback(
    ({ set }) =>
      (
        dialog: Omit<AppDialogProps, "id" | "resolve"> & { id?: string }
      ): Promise<boolean> => {
        const id = dialog.id || uniqueId("AppDialog_");

        return new Promise((resolve, reject) => {
          set(appDialogState, (prev) => {
            return [...prev, { id, ...dialog, resolve }];
          });
        });
      },
    []
  );

  const removeDialog = useRecoilCallback(
    ({ set }) =>
      (id: string) => {
        set(appDialogState, (prev) => {
          return prev.filter((i) => i.id !== id);
        });
      },
    []
  );

  return {
    openDialog: addDialog,
    closeDialog: removeDialog,
    dialogs: appDialogs,
  };
}
