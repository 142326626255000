import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

interface AppLineSheetTypeSelectorProps {
  value?: LineSheetSetType;
  onSelect?: (type?: LineSheetSetType) => void;
  isClearable?: boolean;
  width?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export default function AppLineSheetTypeSelector({
  value,
  onSelect,
  width = "144px",
  isClearable,
  isDisabled,
  isReadOnly,
}: AppLineSheetTypeSelectorProps) {
  const { t } = useTranslation();
  return (
    <AppSelect<LineSheetSetType>
      width={width}
      name={toTitle(t("line_sheet_set.type"))}
      options={[
        {
          name: t("line_sheet_set.type.season").toUpperCase(),
          value: "SEASON",
        },
        { name: t("line_sheet_set.type.stock").toUpperCase(), value: "STOCK" },
      ]}
      value={value}
      onSelect={onSelect}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    />
  );
}
