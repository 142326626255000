import { DumbTransactionState } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import {
  storeObjectKey,
  StoreObjectRequest,
  StoreObjectResponse,
} from "@/features/ui/hooks/use-store-object";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";

export function createStoreObjectDumbTransaction(files: File[]) {
  return files
    .map(
      (
        file,
        index
      ): [
        DumbTransactionState<StoreObjectRequest, StoreObjectResponse>,
        Promise<StoreObjectResponse>
      ] => {
        let dumbTransaction: DumbTransactionState<
          StoreObjectRequest,
          StoreObjectResponse
        > = {
          url: calcYWRUrl(storeObjectKey, {
            _v: parseInt(String(new Date().getTime() + index)),
          }),
          method: storeObjectKey.method,
          request: { file },
          isLoading: false,
          files: [{ file: file }],
          postProcess: (response: StoreObjectResponse) => {},
        };

        const promise: Promise<StoreObjectResponse> = new Promise(
          (resolve, reject) => {
            dumbTransaction.postProcess = (response: StoreObjectResponse) => {
              resolve(response);
            };
          }
        );

        return [dumbTransaction, promise];
      }
    )
    .reduce(
      (acc, [dumbTransaction, promise]) => {
        acc[0].push(dumbTransaction);
        acc[1].push(promise);
        return acc;
      },
      [[], []] as [
        DumbTransactionState<StoreObjectRequest, StoreObjectResponse>[],
        Promise<StoreObjectResponse>[]
      ]
    );
}
