import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";

export default function useDisclosureWithData<
  T extends { isOpen: boolean; onClose: () => void },
  R = Omit<T, "isOpen" | "onClose">
>(initialData?: R) {
  const { onOpen, ...rest } = useDisclosure();
  const [data, setData] = useState<R | undefined>(initialData);

  const openWithData = useCallback(
    (update: (prev: R | undefined) => R) => {
      setData((prev) => {
        return update(prev);
      });
      onOpen();
    },
    [setData, onOpen]
  );

  return {
    onOpen,
    ...rest,
    data,
    setData,
    openWithData,
  };
}
