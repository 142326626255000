import { IdentityParameter } from "@/features/types";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { CreateOrderSheetResponse } from "@/features/line-sheet-sets/hooks/use-create-order-sheet";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useCreateOrderSheetSeamlesslyFetcher from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface CreateOrderSheetSeamlesslyRequest {}
export interface CreateOrderSheetSeamlesslyResponse
  extends CreateOrderSheetResponse {
  _orderSheetSetId: number;
}

/**
 * create 가 여러개 발생하면 시즌 오더의 경우, ossId/lssId/lsId 만으로는 구분이 안됌
 * 그래서 index를 넣음, (같은 ls에서 몇번째 os인지)
 */
export interface CreateOrderSheetSeamlesslyParameter extends IdentityParameter {
  orderSheetSetId: number;
  lineSheetSetId: number;
  lineSheetId: number;
  index: number;
}

export const createOrderSheetSeamlesslyTemplateUrl = `/:by/:companyId/create-order-sheet-seamlessly?orderSheetSetId=:orderSheetSetId&lineSheetSetId=:lineSheetSetId&lineSheetId=:lineSheetId&index=:index`;

export const createOrderSheetSeamlesslyKey: YWRKey<CreateOrderSheetSeamlesslyParameter> =
  {
    url: (parameter) => {
      return calcUrl(createOrderSheetSeamlesslyTemplateUrl, parameter);
    },
    method: "post",
  };

export function parseCreateOrderSheetSeamlesslyKey(url: string) {
  const urlParser = new UrlParser<CreateOrderSheetSeamlesslyParameter>(
    createOrderSheetSeamlesslyTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
      lineSheetId: StringTransformer.parseId,
      index: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useCreateOrderSheetSeamlessly() {
  const fetcher = useCreateOrderSheetSeamlesslyFetcher();

  return useYWR(createOrderSheetSeamlesslyKey, fetcher);
}
