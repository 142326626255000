import { IHeaderParams } from "ag-grid-community";
import { Box, Flex } from "@chakra-ui/react";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import { useEffect, useState } from "react";

interface SheetHeaderProps extends IHeaderParams {
  items?: string[];
}

export default function SheetHeader({
  items,
  displayName,
  column,
  api,
}: SheetHeaderProps) {
  const { t, tTitle } = useI18nHelper();

  return (
    <Flex flexDirection={"column"} gap={"8px"} width={"100%"}>
      {items &&
        items.map((item, index) => {
          return <Box key={index}>{tTitle(item)}</Box>;
        })}
    </Flex>
  );
}
