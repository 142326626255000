import { YWRResponseSegment } from "@/features/ui/hooks/use-ywr-inifite";
import { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";

export function postProcessYWRInfiniteData<PARAMETER, REQ, RES>(
  data: YWRResponseSegment<REQ, RES>[],
  clear: (keyOrKeys: string | string[]) => void,
  handleErrors?: (errors: any[]) => void,
  handleSuccess?: (responses: RES[]) => void
) {
  const errorPartials: { key: string; error: any }[] = [];
  const successPartials: { key: string; response: RES }[] = [];

  data
    .filter((datum) => !datum.isLoading)
    .forEach((datum) => {
      if (datum.response) {
        successPartials.push({ key: datum.key, response: datum.response });
      } else if (datum.error) {
        errorPartials.push({ key: datum.key, error: datum.error });
      }
    });

  const resolveKeys = [
    ...errorPartials.map((p) => p.key),
    ...successPartials.map((p) => p.key),
  ];

  if (resolveKeys.length > 0) {
    clear(resolveKeys);
  }

  if (handleErrors && errorPartials.length > 0) {
    handleErrors(errorPartials.map((p) => p.error));
  }

  if (handleSuccess && successPartials.length > 0) {
    handleSuccess(successPartials.map((p) => p.response));
  }
}

export function findYWRInfiniteSegment<PARAMETER, REQ, RES>(
  data: YWRResponseSegment<REQ, RES>[],
  key: YWRKey<PARAMETER>,
  parameter: PARAMETER
) {
  const url = calcYWRUrl(key, parameter);
  return data.find((d) => d.key === url);
}
