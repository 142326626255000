import { TextareaProps } from "@chakra-ui/textarea/dist/textarea";
import { Text, Textarea } from "@chakra-ui/react";
import React from "react";

interface AppTextAreaProps extends TextareaProps {
  isLiteral?: boolean;
  isResizeable?: boolean;
}

export default function AppTextArea(props: AppTextAreaProps) {
  const { isLiteral, isResizeable = true, ...rest } = props;
  let style: TextareaProps = {
    borderRadius: "4px",
    border: "0.5px solid #8F8F8C",
    padding: "8px",
    margin: "0",
  };

  if (props.isReadOnly) {
    style = {
      ...style,
      // borderColor: "transparent",
      // padding: "0",
    };
  }

  if (isLiteral) {
    return <Text>{props.value}</Text>;
  }

  return (
    <Textarea
      minHeight={"0"}
      fontSize={"12px"}
      resize={isResizeable ? "vertical" : "none"}
      {...rest}
      {...style}
    ></Textarea>
  );
}
