import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import {
  Field,
  FieldInputProps,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import useSignUp from "@/features/auth/sign-up/use-sign-up";
import { useNavigate } from "react-router";
import { SignUpFormValues } from "@/features/auth/sign-up/sign-up-page";
import AppSelect from "@/features/line-sheet-sets/app-select";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import AppButton from "@/features/line-sheet-sets/app-button";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

export interface SignUpStep2FormFields {
  email?: string;
  userInputedCompanyName: string;
  name: string;
  countryCode: string;
  mobileNumber: string;
  agreeToAll: boolean;
  agreeToTermsOfUse: boolean;
  agreeToCollectPersonalInformation: boolean;
  agreeToProvidePersonalInformation: boolean;
  over14: boolean;
  agreeToMarketing: boolean;
}

interface SignUpStep2FormProps {
  initialValue: SignUpFormValues;
  bySocial: boolean;
}

export default function SignUpStep2Form({
  initialValue,
  bySocial = false,
}: SignUpStep2FormProps) {
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+1");
  const {
    fire: fireSignUp,
    isLoading: isSignUpLoading,
    data: signUpData,
    error: signUpError,
  } = useSignUp();
  const { error: showError } = useAppToasts();
  const { t, tTitle, tSentence } = useI18nHelper();
  const { withMessage, formT } = useYupHelper({
    ns: "auth",
    prefix: "sign_up_form",
  });

  const formikRef = useRef<FormikProps<SignUpStep2FormFields>>(null);

  useEffect(() => {
    if (signUpError) {
      showError(signUpError, {
        id: "SignUpForm",
      });
    }
  }, [showError, signUpError]);

  useEffect(() => {
    if (signUpData) {
      navigate("/auth/sign-up-pending", { replace: true });
    }
  }, [navigate, signUpData]);

  const validateSchema = useMemo(() => {
    return Yup.object({
      userInputedCompanyName: Yup.string().required(withMessage.required),
      name: Yup.string().label("full_name").required(withMessage.required),
      mobileNumber: Yup.string()
        .required(withMessage.required)
        .min(10, withMessage.min),
      agreeToTermsOfUse: Yup.boolean().isTrue(),
      agreeToCollectPersonalInformation: Yup.boolean().isTrue(),
      agreeToProvidePersonalInformation: Yup.boolean().isTrue(),
      over14: Yup.boolean().isTrue(),
    });
  }, [withMessage]);

  return (
    <Formik
      initialValues={initialValue as SignUpStep2FormFields}
      validationSchema={validateSchema}
      innerRef={formikRef}
      onSubmit={async (values, actions) => {
        await fireSignUp(
          {},
          {
            ...values,
            email: initialValue.email,
            password: initialValue.password,
          }
        );
        actions.setSubmitting(false);
        // if (!isLoading) {
        //   const payload: SignUpRequest = { ...initialValue, ...values };
        //   fire([], payload);
        //   return;
        // }
        // actions.setSubmitting(true);
      }}
    >
      {(props: FormikProps<SignUpStep2FormFields>) => {
        // if (error && props.isSubmitting) {
        //   props.setSubmitting(false);
        // }

        function setAgreement(name: string, value: any) {
          props.setValues((prev) => {
            const next = {
              ...prev,
              [name]: value,
            };

            if (name === "agreeToAll") {
              return {
                ...next,
                agreeToTermsOfUse: value,
                agreeToCollectPersonalInformation: value,
                agreeToProvidePersonalInformation: value,
                over14: value,
                agreeToMarketing: value,
              };
            } else {
              next.agreeToAll =
                next.agreeToTermsOfUse &&
                next.agreeToCollectPersonalInformation &&
                next.agreeToProvidePersonalInformation &&
                next.over14 &&
                next.agreeToMarketing;
              return next;
            }
          });
        }

        return (
          <Form>
            {bySocial && <Field name="email"></Field>}
            <Field name="userInputedCompanyName">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<SignUpStep2FormFields>;
              }) => (
                <FormControl
                  isRequired
                  isInvalid={
                    !!form.errors.userInputedCompanyName &&
                    form.touched.userInputedCompanyName
                  }
                >
                  <FormLabel display="flex" m={"0"}>
                    <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                      {tTitle("sign_up_form.field.user_inputed_company_name", {
                        ns: "auth",
                      })}
                    </Text>
                  </FormLabel>
                  <Input {...field} fontSize={"12px"} />
                  <Flex h="24px" flexDirection="row" alignItems={"center"}>
                    <FormErrorMessage m="0" fontSize={"12px"}>
                      {form.errors.userInputedCompanyName}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              )}
            </Field>

            <Field name="name">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<SignUpStep2FormFields>;
              }) => (
                <FormControl
                  isRequired
                  isInvalid={!!form.errors.name && form.touched.name}
                >
                  <FormLabel display="flex" m={"0"}>
                    <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                      {tTitle("sign_up_form.field.full_name", {
                        ns: "auth",
                      })}
                    </Text>
                  </FormLabel>
                  <Input {...field} fontSize={"12px"} />
                  <Flex h="24px" flexDirection="row" alignItems={"center"}>
                    <FormErrorMessage m="0" fontSize={"12px"}>
                      {form.errors.name}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              )}
            </Field>

            <Field name="mobileNumber">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<SignUpStep2FormFields>;
              }) => (
                <FormControl
                  isRequired
                  isInvalid={
                    !!form.errors.mobileNumber && form.touched.mobileNumber
                  }
                >
                  <FormLabel display="flex" m={"0"}>
                    <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                      {tTitle("sign_up_form.field.mobile_number", {
                        ns: "auth",
                      })}
                    </Text>
                  </FormLabel>

                  <FormControl>
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <AppSelect<string>
                        value={form.values.countryCode}
                        name={"KR"}
                        width={"64px"}
                        height={"40px"}
                        options={[
                          { value: "+82", name: "KR" },
                          { value: "+39", name: "IT" },
                        ]}
                        isClearable={false}
                        onSelect={(value) => {
                          form.setFieldValue("countryCode", value);
                        }}
                      />

                      <Input
                        {...field}
                        type={"tel"}
                        inputMode={"numeric"}
                        onChange={(e) => {
                          form.setFieldValue(
                            field.name,
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        fontSize={"12px"}
                      />
                    </Flex>
                  </FormControl>
                  <Flex h="24px" flexDirection="row" alignItems={"center"}>
                    <FormErrorMessage m="0" fontSize={"12px"}>
                      {form.errors.mobileNumber}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              )}
            </Field>

            <Field type={"checkbox"} name={"agreeToAll"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl>
                    <AppCheckBox
                      name={t("auth:sign_up_form.field.agree_to_all.label")}
                      isChecked={props.field.value}
                      onChange={(value) => {
                        setAgreement(props.field.name, value);
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Divider margin={"12px 0"} />

            <Field type={"checkbox"} name={"agreeToTermsOfUse"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl
                    as={Flex}
                    flexDirection={"column"}
                    isInvalid={
                      !!props.form.errors.agreeToTermsOfUse &&
                      props.form.touched.agreeToTermsOfUse
                    }
                  >
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <Box flexGrow={"1"}>
                        <AppCheckBox
                          name={`[${tTitle("auth:mandatory")}] ${t(
                            "auth:sign_up_form.field.agree_to_terms_of_use.label"
                          )}`}
                          isChecked={props.field.value}
                          onChange={(value) => {
                            setAgreement(props.field.name, value);
                          }}
                        />
                      </Box>
                      <Link
                        flexShrink={"0"}
                        isExternal={true}
                        href={
                          "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Service-Agreement-v1.0-20231208.pdf"
                        }
                        fontSize={"12px"}
                      >
                        [{tTitle("view")}]
                      </Link>
                    </Flex>
                    <Flex h="24px" flexDirection="row" alignItems={"center"}>
                      <FormErrorMessage m="0" fontSize={"12px"}>
                        {props.form.errors.agreeToTermsOfUse}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                );
              }}
            </Field>

            <Field type={"checkbox"} name={"agreeToCollectPersonalInformation"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl
                    as={Flex}
                    flexDirection={"column"}
                    isInvalid={
                      !!props.form.errors.agreeToCollectPersonalInformation &&
                      props.form.touched.agreeToCollectPersonalInformation
                    }
                  >
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <Box flexGrow={"1"}>
                        <AppCheckBox
                          name={`[${tTitle("auth:mandatory")}] ${t(
                            "auth:sign_up_form.field.agree_to_collect_personal_information.label"
                          )}`}
                          isChecked={props.field.value}
                          onChange={(value) =>
                            setAgreement(props.field.name, value)
                          }
                        />
                      </Box>
                      <Link
                        flexShrink={"0"}
                        isExternal={true}
                        href={
                          "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Personal-information-collection-and-use-consent-form-v1.0-20231208.pdf"
                        }
                        fontSize={"12px"}
                      >
                        [{tTitle("view")}]
                      </Link>
                    </Flex>
                    <Flex h="24px" flexDirection="row" alignItems={"center"}>
                      <FormErrorMessage m="0" fontSize={"12px"}>
                        {props.form.errors.agreeToCollectPersonalInformation}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                );
              }}
            </Field>

            <Field type={"checkbox"} name={"agreeToProvidePersonalInformation"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl
                    as={Flex}
                    flexDirection={"column"}
                    isInvalid={
                      !!props.form.errors.agreeToProvidePersonalInformation &&
                      props.form.touched.agreeToProvidePersonalInformation
                    }
                  >
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <Box flexGrow={"1"}>
                        <AppCheckBox
                          name={`[${tTitle("auth:mandatory")}] ${t(
                            "auth:sign_up_form.field.agree_to_provide_personal_information.label"
                          )}`}
                          isChecked={props.field.value}
                          onChange={(value) =>
                            setAgreement(props.field.name, value)
                          }
                        />
                      </Box>
                      <Link
                        flexShrink={"0"}
                        isExternal={true}
                        href={
                          "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Consent-to-provide-personal-information-to-third-parties-v1.0-20231208.pdf"
                        }
                        fontSize={"12px"}
                      >
                        [{tTitle("view")}]
                      </Link>
                    </Flex>
                    <Flex h="24px" flexDirection="row" alignItems={"center"}>
                      <FormErrorMessage m="0" fontSize={"12px"}>
                        {props.form.errors.agreeToProvidePersonalInformation}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                );
              }}
            </Field>

            <Field type={"checkbox"} name={"over14"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl
                    as={Flex}
                    flexDirection={"column"}
                    isInvalid={
                      !!props.form.errors.over14 && props.form.touched.over14
                    }
                  >
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <Box flexGrow={"1"}>
                        <AppCheckBox
                          name={`[${tTitle("auth:mandatory")}] ${t(
                            "auth:sign_up_form.field.over_14.label"
                          )}`}
                          isChecked={props.field.value}
                          onChange={(value) =>
                            setAgreement(props.field.name, value)
                          }
                        />
                      </Box>
                    </Flex>
                    <Flex h="24px" flexDirection="row" alignItems={"center"}>
                      <FormErrorMessage m="0" fontSize={"12px"}>
                        {props.form.errors.agreeToProvidePersonalInformation}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                );
              }}
            </Field>

            <Field type={"checkbox"} name={"agreeToMarketing"}>
              {(props: FieldProps<boolean, SignUpStep2FormFields>) => {
                return (
                  <FormControl
                    as={Flex}
                    flexDirection={"column"}
                    isInvalid={
                      !!props.form.errors.agreeToMarketing &&
                      props.form.touched.agreeToMarketing
                    }
                  >
                    <Flex flexDirection={"row"} gap={"8px"}>
                      <Box flexGrow={"1"}>
                        <AppCheckBox
                          name={`[${tTitle("auth:optional")}] ${t(
                            "auth:sign_up_form.field.agree_to_marketing.label"
                          )}`}
                          isChecked={props.field.value}
                          onChange={(value) =>
                            setAgreement(props.field.name, value)
                          }
                        />
                      </Box>
                      <Link
                        flexShrink={"0"}
                        isExternal={true}
                        href={
                          "https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Consent-to-receive-advertising-information-and-use-personal-information-for-marketing-v1.0-20231208.pdf"
                        }
                        fontSize={"12px"}
                      >
                        [{tTitle("view")}]
                      </Link>
                    </Flex>
                    <Flex h="24px" flexDirection="row" alignItems={"center"}>
                      <FormErrorMessage m="0" fontSize={"12px"}>
                        {props.form.errors.agreeToMarketing}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                );
              }}
            </Field>

            <AppButton
              variant={"gray"}
              size={"large"}
              width={"100%"}
              chakraProps={{ height: "40px" }}
              type={"submit"}
              isDisabled={!(props.isValid && props.dirty)}
              isLoading={props.isSubmitting}
            >
              <Text fontSize={"14px"}>
                {tTitle("sign_up.do", { ns: "auth" })}
              </Text>
            </AppButton>
          </Form>
        );
      }}
    </Formik>
  );
}
