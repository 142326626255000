import {
  CompanyType,
  CompanyTypes,
  isOrderBy,
  OrderByItem,
  SortOrder,
} from "@/features/types";
import {
  pluralizeCompanyType,
  singularizeCompanyType,
} from "@/features/ui/helpers/identity-helpers";
import { snakeCase } from "lodash";
import { toCamel } from "@/utils/case";

export type ParseString<T> = (
  rawValue: string | string[]
) => T[keyof T & string] | undefined;

export class StringTransformer {
  static isStringArray(value: string | string[]): value is string[] {
    return Array.isArray(value);
  }

  static parseId(rawValue: string | string[]): number | undefined {
    if (
      !StringTransformer.isStringArray(rawValue) &&
      rawValue &&
      /\d+/.test(rawValue)
    ) {
      return parseInt(rawValue);
    }
  }

  static stringifyId(value: number | undefined): string | undefined {
    return value !== undefined ? String(value) : undefined;
  }

  static withParseId(fallback: number) {
    return (rawValue: string | string[]) => {
      const value = StringTransformer.parseId(rawValue);
      return value !== undefined ? value : fallback;
    };
  }

  static withStringifyId(fallback: number) {
    return (value: number | undefined) => {
      return value !== undefined
        ? StringTransformer.stringifyId(value)
        : String(fallback);
    };
  }

  static parseBy(rawValue: string | string[]): CompanyType | undefined {
    if (!StringTransformer.isStringArray(rawValue) && rawValue) {
      const candidates = CompanyTypes.map((i) =>
        pluralizeCompanyType(i).toLowerCase()
      );
      if (candidates.includes(rawValue)) {
        return singularizeCompanyType(rawValue) as CompanyType;
      }
    }
  }

  static withParseStringLiterals<T>(
    candidates: T[]
  ): (rawValue: string | string[]) => T[] | undefined {
    return (rawValue: string | string[]) => {
      if (rawValue) {
        if (!StringTransformer.isStringArray(rawValue)) {
          if (candidates.includes(rawValue as T)) {
            return [rawValue as T];
          }
        } else {
          return rawValue.filter((i) => candidates.includes(i as T)) as T[];
        }
      }
    };
  }

  static stringifyStringLiterals<T>(rawValue: T[] | undefined): string[] {
    return (rawValue || []).map((i) => String(i));
  }

  static withParseStringLiteral<T>(
    candidates: T[]
  ): (rawValue: string | string[]) => T | undefined {
    return (rawValue: string | string[]) => {
      if (rawValue) {
        if (!StringTransformer.isStringArray(rawValue)) {
          if (candidates.includes(rawValue as T)) {
            return rawValue as T;
          }
        }
      }
    };
  }

  static stringifyStringLiteral<T>(rawValue: T): string {
    return String(rawValue);
  }

  static parseDate(rawValue: string | string[]): Date | undefined {
    if (!StringTransformer.isStringArray(rawValue) && rawValue) {
      const date = new Date(rawValue);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
  }

  static stringifyDate(rawValue: Date | undefined): string | undefined {
    return rawValue ? rawValue.toISOString() : undefined;
  }

  static parseBoolean(rawValue: string | string[]): boolean | undefined {
    if (
      !StringTransformer.isStringArray(rawValue) &&
      (rawValue === "true" || rawValue === "false")
    ) {
      return rawValue === "true";
    }
  }

  static stringifyBoolean(rawValue: boolean | undefined): string | undefined {
    return rawValue !== undefined ? String(rawValue) : undefined;
  }

  static parseIds(rawValue: string | string[]): number[] | undefined {
    const candidates = StringTransformer.isStringArray(rawValue)
      ? rawValue
      : [rawValue];
    return candidates
      .map((i) => {
        if (/\d+/.test(i)) {
          return parseInt(i);
        }
        return -1;
      })
      .filter((i) => i > -1);
  }

  static stringifyIds(rawValue: number[] | undefined): string[] | undefined {
    return rawValue ? rawValue.map((i) => String(i)) : undefined;
  }

  static stringifyOrderBy(
    rawValue: OrderByItem[] | undefined
  ): string[] | undefined {
    const func = (obj: OrderByItem) => {
      let prefix = "";
      if (obj.sortOrder === "descend") {
        prefix = "-";
      }
      return `${prefix}${snakeCase(obj.field)}`;
    };

    return rawValue?.map(func);
  }

  static parseString(rawValue: string | string[]): string | undefined {
    if (!StringTransformer.isStringArray(rawValue) && rawValue) {
      return rawValue;
    }
  }

  static stringifyString(rawValue: string | undefined): string | undefined {
    return rawValue;
  }

  static parseStrings(rawValue: string | string[]): string[] | undefined {
    return StringTransformer.isStringArray(rawValue) ? rawValue : [rawValue];
  }

  static stringifyStrings(
    rawValue: string[] | undefined
  ): string[] | undefined {
    return rawValue;
  }

  static parseObject<T>(
    rawValue: string | string[],
    isT?: (value: any) => value is T
  ): T | undefined {
    if (!Array.isArray(rawValue)) {
      try {
        const t = JSON.parse(rawValue);
        if (isT) {
          if (!isT(t)) {
            return undefined;
          }
        }
        return t as T;
      } catch {}
    }
    return undefined;
  }

  static stringifyObject<T>(rawValue: T | undefined): string | undefined {
    return rawValue ? JSON.stringify(rawValue) : undefined;
  }

  static parseObjects<T>(
    rawValue: string | string[],
    isT?: (value: any) => value is T
  ): T[] | undefined {
    if (StringTransformer.isStringArray(rawValue)) {
      return rawValue
        .map((i) => StringTransformer.parseObject(i, isT))
        .filter((i) => i !== undefined) as T[];
    } else {
      return [StringTransformer.parseObject(rawValue, isT)].filter(
        (i) => i !== undefined
      ) as T[];
    }
  }

  static stringifyObjects<T>(rawValue: T[] | undefined): string[] | undefined {
    return rawValue?.map((i) => JSON.stringify(i));
  }

  static stringifyAny(rawValue: any): string | string[] | undefined {
    if (rawValue !== null && rawValue !== undefined) {
      if (isOrderBy(rawValue)) {
        return StringTransformer.stringifyOrderBy(rawValue);
      } else if (Array.isArray(rawValue)) {
        return rawValue
          .flatMap((i) => StringTransformer.stringifyAny(i))
          .filter((i): i is string => i !== undefined);
      } else if (typeof rawValue === "string") {
        return rawValue;
      } else if (typeof rawValue === "number") {
        return StringTransformer.stringifyId(rawValue);
      } else if (typeof rawValue === "boolean") {
        return StringTransformer.stringifyBoolean(rawValue);
      } else if (rawValue instanceof Date) {
        return StringTransformer.stringifyDate(rawValue);
      } else if (typeof rawValue === "object") {
        return StringTransformer.stringifyObject(rawValue);
      } else {
        return String(rawValue);
      }
    }
  }
}
