import useFetcher from "@/utils/use-fetcher";
import {
  getListPaymentsKey,
  ListPaymentsRequest,
  ListPaymentsResponse,
} from "@/features/invoices/payment/hooks/use-list-payments";
import useCondenseProformaInvoices from "../../proforma-invoices/hooks/use-condense-proforma-invoices";
import useCondenseOrderConfirmationInvoices from "@/features/invoices/order-confirmation-invoices/hooks/use-condese-order-confirmation-invoices";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListDensePaymentsRequest,
  ListDensePaymentsResponse,
  parseListDensePaymentsKey,
} from "@/features/invoices/payment/hooks/use-list-dense-payments";

export default function useListDensePaymentsFetcher() {
  const listPaymentsFetcher = useFetcher<
    ListPaymentsRequest,
    ListPaymentsResponse
  >();
  const condenseOrderConfirmationInvoices =
    useCondenseOrderConfirmationInvoices();
  const condenseProformaInvoices = useCondenseProformaInvoices();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListDensePaymentsRequest>
    ): Promise<ListDensePaymentsResponse> => {
      const { parameter, request } = parseListDensePaymentsKey(config);

      const listPaymentsKey = getListPaymentsKey(parameter, request);

      const listPaymentsResponse = await listPaymentsFetcher(listPaymentsKey!);

      if (listPaymentsResponse.payments.length > 0) {
        const promises = [
          condenseOrderConfirmationInvoices(
            listPaymentsResponse.payments,
            (item) => {
              return item.orderConfirmationInvoices;
            },
            (item, values) => {
              if (Array.isArray(values)) {
                item.orderConfirmationInvoices = values;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
          condenseProformaInvoices(
            listPaymentsResponse.payments,
            (item) => {
              return item.proformaInvoices;
            },
            (item, values) => {
              if (Array.isArray(values)) {
                item.proformaInvoices = values;
              }
            },
            {
              by: parameter.by,
              companyId: parameter.companyId,
            }
          ),
        ];

        await Promise.all(promises);
      }

      //@ts-ignore
      return Promise.resolve(listPaymentsResponse);
    };
  }, [
    condenseOrderConfirmationInvoices,
    condenseProformaInvoices,
    listPaymentsFetcher,
  ]);
}
