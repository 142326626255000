import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { User } from "@/features/auth/use-get-my-self";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

interface UpdateUserRequest {
  name: string;
  // email: string;
  // mobile_number: string;
  // company_ids: number[];
  // agree_to_marketing: boolean;
}

interface UpdateUserResponse extends User {}

export interface UpdateUserPathParameter {
  userId: number;
}

export const updateUserUrl = "/users/:userId";
export const updateUserKey: YWRKey<UpdateUserPathParameter> = {
  url: (parameter) => {
    return calcUrl(updateUserUrl, parameter);
  },
  method: "put",
};

export default function useUpdateUser() {
  const fetcher = useFetcher<UpdateUserRequest, UpdateUserResponse>();

  return useYWR(updateUserKey, fetcher);
}
