import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";
import { Field, FieldProps, FormikHelpers, FormikProps } from "formik";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Box, FormControl, FormLabel, Grid, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import React, { useCallback, useMemo } from "react";
import TransportationStatusLabel from "@/features/transportations/transportation-status-label";
import AppTextArea from "@/features/ui/app-text-area";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppPriceInput from "@/features/invoices/app-price-input";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppListFormControl from "@/features/transportations/app-list-form-control";
import AppAirportSelect from "@/features/transportations/app-airport-select";
import AppTransportationSelect from "@/features/transportations/app-transportation-status-select";
import FlightTime from "@/features/transportations/flight-time";
import AppExclusiveCompanySelect from "@/features/transportations/app-exclusive-company-select";
import { TransportationStatuses } from "@/features/transportations/transportation.type";
import { Buyer } from "@/features/types";
import FlightSearchButton from "@/features/transportations/flight-search-button";

interface TransportationInformationFormControlProps {
  formik: FormikProps<TransportationFormFields>;
  isEditing?: boolean;
}

export default function TransportationInformationFormControl({
  formik,
  isEditing = false,
}: TransportationInformationFormControlProps) {
  const { t, tTitle } = useI18nHelper();

  const handleLinkAffectingFieldChange = useCallback(
    <K extends keyof TransportationFormFields>(
      key: K,
      value: TransportationFormFields[K],
      setValues: FormikHelpers<TransportationFormFields>["setValues"]
    ) => {
      return setValues((prev) => {
        let next = {
          ...prev,
          [key]: value,
        };

        next.proformaInvoices = next.proformaInvoices.filter((pInvoice) => {
          return (
            pInvoice.issuedToCompany.id === next.shippedTo?.id &&
            next.shippedFromList.some(
              (company) => company.id === pInvoice.issuedByCompany.id
            )
          );
        });

        return next;
      });
    },
    []
  );

  const isFlightDeparted = useMemo(() => {
    const status = formik.initialValues.status;
    if (status) {
      const i = TransportationStatuses.indexOf("FLIGHT_DEPARTED");
      const j = TransportationStatuses.indexOf(status);
      return i <= j;
    }
    return false;
  }, [formik.initialValues.status]);

  return (
    <AppPanel title={tTitle("transportation_information.abbr")}>
      <Grid rowGap={"12px"}>
        <Field name={"name"}>
          {(
            props: FieldProps<
              TransportationFormFields["name"],
              TransportationFormFields
            >
          ) => {
            return (
              <FormControl fontSize={"14px"}>
                <AppInput
                  value={props.field.value}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                  fontSize={"14px"}
                  isLiteral={!isEditing}
                  placeholder={t("common:title_placeholder")}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"status"}>
          {(
            props: FieldProps<
              TransportationFormFields["status"],
              TransportationFormFields
            >
          ) => {
            return (
              <FormControl>
                {isEditing && props.form.values.id !== -1 ? (
                  <Grid gridTemplateColumns={"120px 1fr"}>
                    <FormLabel
                      fontSize={"12px"}
                      fontWeight={"700"}
                      color={"#22221F"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {tTitle("status")}
                    </FormLabel>
                    <AppTransportationSelect
                      value={props.field.value}
                      from={props.form.initialValues.status}
                      onSelect={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </Grid>
                ) : (
                  <TransportationStatusLabel value={props.field.value} />
                )}
              </FormControl>
            );
          }}
        </Field>

        <Box
          height={"8px"}
          borderBottom={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
        ></Box>

        <Grid rowGap={"8px"}>
          <Field name={"shippedFromList"}>
            {(
              props: FieldProps<
                TransportationFormFields["shippedFromList"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("shipped_from")}
                  </FormLabel>
                  <AppExclusiveCompanySelect
                    types={["BOUTIQUE", "CROSS_TRADER"]}
                    name={"shipped_from"}
                    ids={props.field.value.map((company) => company.id)}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "shippedFromList",
                        value,
                        props.form.setValues
                      );
                    }}
                    isLiteral={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"shippedTo"}>
            {(
              props: FieldProps<
                TransportationFormFields["shippedTo"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("shipped_to")}
                  </FormLabel>
                  <AppCompanySelect
                    name={tTitle("shipped_to")}
                    types={["BUYER"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "shippedTo",
                        value as Buyer,
                        props.form.setValues
                      );
                    }}
                    isLiteral={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"shippedToAddress"}>
            {(
              props: FieldProps<
                TransportationFormFields["shippedToAddress"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  isRequired={isEditing}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                    visibility={"hidden"}
                  >
                    {tTitle("shipped_to_address")}
                  </FormLabel>
                  <AppTextArea
                    value={props.field.value}
                    onChange={(e) => {
                      props.form.setFieldValue(
                        props.field.name,
                        e.target.value
                      );
                    }}
                    placeholder={t("shipped_to_address.placeholder")}
                    rows={2}
                    isLiteral={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"forwarderInvoiceNumbers"}>
            {(
              props: FieldProps<
                TransportationFormFields["forwarderInvoiceNumbers"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("forwarder_invoice_number.abbr")}
                  </FormLabel>
                  <AppListFormControl
                    isLiteral={!isEditing}
                    value={props.field.value}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                    isDisabled={isFlightDeparted}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"houseBlNumbers"}>
            {(
              props: FieldProps<
                TransportationFormFields["houseBlNumbers"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("house_bl_number.abbr")}
                  </FormLabel>
                  <AppListFormControl
                    value={props.field.value}
                    isLiteral={!isEditing}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                    isDisabled={isFlightDeparted}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"flightNumber"}>
            {(
              props: FieldProps<
                TransportationFormFields["flightNumber"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr 96px"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("flight_number")}
                  </FormLabel>
                  <AppInput
                    isLiteral={!isEditing}
                    isDisabled={isFlightDeparted}
                    value={props.field.value}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                  {isEditing && (
                    <Box marginLeft={"8px"}>
                      <FlightSearchButton
                        fieldProps={props}
                        isEditing={isEditing}
                      />
                    </Box>
                  )}
                </FormControl>
              );
            }}
          </Field>

          <Field name={"flightDepartureAirport"}>
            {(
              props: FieldProps<
                TransportationFormFields["flightDepartureAirport"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("common:flight_departure_airport")}
                  </FormLabel>
                  <AppAirportSelect
                    id={props.field.value}
                    isLiteral={!isEditing}
                    isDisabled={isFlightDeparted}
                    name={tTitle("common:flight_departure_airport")}
                    onSelect={(value) => {
                      props.form.setFieldValue(
                        props.field.name,
                        value?.iataCode
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>

          {isEditing ? (
            <>
              <Field name={"flightEtd"}>
                {(
                  props: FieldProps<
                    TransportationFormFields["flightEtd"],
                    TransportationFormFields
                  >
                ) => {
                  return (
                    <FormControl
                      as={Grid}
                      gridTemplateColumns={"120px 1fr"}
                      fontSize={"12px"}
                      isRequired={isEditing}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        fontWeight={"700"}
                        color={"#22221F"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {tTitle("common:flight_etd")}
                      </FormLabel>
                      <AppDatePicker
                        isLiteral={!isEditing}
                        isDisabled={isFlightDeparted}
                        value={props.field.value}
                        name={tTitle("common:flight_etd")}
                        showTimeInput={false}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    </FormControl>
                  );
                }}
              </Field>

              <Field name={"flightAtd"}>
                {(
                  props: FieldProps<
                    TransportationFormFields["flightAtd"],
                    TransportationFormFields
                  >
                ) => {
                  return (
                    <FormControl
                      as={Grid}
                      gridTemplateColumns={"120px 1fr"}
                      fontSize={"12px"}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        fontWeight={"700"}
                        color={"#22221F"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {tTitle("common:flight_atd")}
                      </FormLabel>
                      <AppDatePicker
                        isLiteral={!isEditing}
                        isDisabled={isFlightDeparted}
                        value={props.field.value}
                        name={tTitle("common:flight_atd")}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    </FormControl>
                  );
                }}
              </Field>
            </>
          ) : (
            <Grid gridTemplateColumns={"120px 1fr"} fontSize={"12px"}>
              <Text fontSize={"12px"} fontWeight={"700"} color={"#22221F"}>
                {tTitle("flight_departed_at")}
              </Text>
              <FlightTime
                estimated={formik.values.flightEtd!}
                actual={formik.values.flightAtd || null}
                direction={"column"}
              />
            </Grid>
          )}

          <Field name={"flightArrivalAirport"}>
            {(
              props: FieldProps<
                TransportationFormFields["flightArrivalAirport"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("common:flight_arrival_airport")}
                  </FormLabel>
                  <AppAirportSelect
                    id={props.field.value}
                    name={tTitle("common:flight_arrival_airport")}
                    onSelect={(value) => {
                      props.form.setFieldValue(
                        props.field.name,
                        value?.iataCode
                      );
                    }}
                    isLiteral={!isEditing}
                    isDisabled={isFlightDeparted}
                  />
                </FormControl>
              );
            }}
          </Field>

          {isEditing ? (
            <>
              <Field name={"flightEta"}>
                {(
                  props: FieldProps<
                    TransportationFormFields["flightEta"],
                    TransportationFormFields
                  >
                ) => {
                  return (
                    <FormControl
                      as={Grid}
                      gridTemplateColumns={"120px 1fr"}
                      fontSize={"12px"}
                      isRequired={isEditing}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        fontWeight={"700"}
                        color={"#22221F"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {tTitle("flight_eta")}
                      </FormLabel>
                      <AppDatePicker
                        value={props.field.value}
                        name={tTitle("flight_eta")}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                        isLiteral={!isEditing}
                        isDisabled={isFlightDeparted}
                      />
                    </FormControl>
                  );
                }}
              </Field>

              <Field name={"flightAta"}>
                {(
                  props: FieldProps<
                    TransportationFormFields["flightAta"],
                    TransportationFormFields
                  >
                ) => {
                  return (
                    <FormControl
                      as={Grid}
                      gridTemplateColumns={"120px 1fr"}
                      fontSize={"12px"}
                    >
                      <FormLabel
                        fontSize={"12px"}
                        fontWeight={"700"}
                        color={"#22221F"}
                        padding={"0"}
                        margin={"0"}
                      >
                        {tTitle("flight_ata")}
                      </FormLabel>
                      <AppDatePicker
                        value={props.field.value}
                        name={tTitle("flight_ata")}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                        isLiteral={!isEditing}
                        isDisabled={isFlightDeparted}
                      />
                    </FormControl>
                  );
                }}
              </Field>
            </>
          ) : (
            <Grid gridTemplateColumns={"120px 1fr"} fontSize={"12px"}>
              <Text fontSize={"12px"} fontWeight={"700"} color={"#22221F"}>
                {tTitle("flight_arrived_at")}
              </Text>
              <FlightTime
                estimated={formik.values.flightEta!}
                actual={formik.values.flightAta || null}
                direction={"column"}
              />
            </Grid>
          )}

          <Field name={"finalEta"}>
            {(
              props: FieldProps<
                TransportationFormFields["finalEta"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl
                  as={Grid}
                  gridTemplateColumns={"120px 1fr"}
                  fontSize={"12px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("final_destination_eta.abbr")}
                  </FormLabel>
                  <AppDatePicker
                    value={props.field.value}
                    showTimeInput={false}
                    name={tTitle("final_destination_eta.abbr")}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                    isLiteral={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>

          <Field name={"cost"}>
            {(
              props: FieldProps<
                TransportationFormFields["cost"],
                TransportationFormFields
              >
            ) => {
              return (
                <FormControl as={Grid} gridTemplateColumns={"120px 1fr"}>
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={"700"}
                    color={"#22221F"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {tTitle("transportation_cost.abbr")}
                  </FormLabel>
                  <AppPriceInput
                    currency={"KRW"}
                    value={{
                      value: props.field.value,
                      currency: "KRW",
                    }}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value?.value);
                    }}
                    isLiteral={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </AppPanel>
  );
}
