import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { LightOrderSheet } from "@/features/order-sheets/order-sheet.type";
import useFetcher from "@/utils/use-fetcher";

export interface DeleteOrderSheetUploadedFileRequest {}
export interface DeleteOrderSheetUploadedFileResponse extends LightOrderSheet {}
export interface DeleteOrderSheetUploadedFilePathParameter
  extends IdentityParameter {
  orderSheetSetId: number;
  orderSheetId: number;
}

const deleteOrderSheetUploadedFileTemplateUrl = `/:by/:companyId/order-sheet-sets/:orderSheetSetId/order-sheets/:orderSheetId/uploaded-file`;

export const deleteOrderSheetUploadedFileKey: YWRKey<DeleteOrderSheetUploadedFilePathParameter> =
  {
    url: (parameter: DeleteOrderSheetUploadedFilePathParameter) => {
      return calcUrl(deleteOrderSheetUploadedFileTemplateUrl, parameter);
    },
    method: "delete",
  };

export function parseDeleteOrderSheetUploadedFileKey(url: string) {
  const parser = new UrlParser<DeleteOrderSheetUploadedFilePathParameter>(
    deleteOrderSheetUploadedFileTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderSheetSetId: StringTransformer.parseId,
      orderSheetId: StringTransformer.parseId,
    }
  );
  return {
    parameter: parser.parse(url),
    method: "delete",
  };
}

export default function useDeleteOrderSheetUploadedFile() {
  const fetcher = useFetcher<
    DeleteOrderSheetUploadedFileRequest,
    DeleteOrderSheetUploadedFileResponse
  >();

  return useYWR(deleteOrderSheetUploadedFileKey, fetcher);
}
