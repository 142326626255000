import { Company } from "@/features/types";
import { FieldProps, useFormikContext } from "formik";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import useListAllCompanies, {
  ListAllCompaniesPathParameter,
  ListAllCompaniesRequest,
} from "@/features/line-sheet-sets/hooks/use-list-all-companies";
import useIdentity from "@/features/ui/hooks/use-identity";
import React, { useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface FormFields {
  receiver?: Company;
  sender?: Company;
  issuedThrough?: { id: number; name: string };
}

interface InvoiceReceiverProps {
  value?: Company;
  onSelect: (value?: Company) => void;
  isEditing?: boolean;
  isDisabled?: boolean;
}

export default function InvoiceReceiverSelect({
  value,
  onSelect,
  isEditing,
  isDisabled,
}: InvoiceReceiverProps) {
  const { values } = useFormikContext<FormFields>();
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const listAllBoutiquesKey = useMemo(():
    | {
        parameter: ListAllCompaniesPathParameter;
        request: ListAllCompaniesRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          type__in: ["BOUTIQUE"],
        },
      };
    }
  }, [identity]);

  const { data: listAllBoutiquesData, error: listAllBoutiquesError } =
    useListAllCompanies(
      listAllBoutiquesKey?.parameter,
      listAllBoutiquesKey?.request
    );

  useEffect(() => {
    if (listAllBoutiquesError) {
      showError(listAllBoutiquesError);
    }
  }, [listAllBoutiquesError, showError]);

  const { issuedThrough, sender } = values;

  const options = useMemo((): AppSelectOption<Company>[] => {
    const options: AppSelectOption<Company>[] = [];
    if (issuedThrough && sender?.type !== "AGENCY") {
      options.push({
        name: issuedThrough.name,
        value: {
          ...issuedThrough,
          type: "AGENCY",
        },
      });
    }

    options.push(
      ...(listAllBoutiquesData?.map((company) => {
        return {
          name: company.name,
          value: company,
        };
      }) || [])
    );

    return options;
  }, [issuedThrough, listAllBoutiquesData, sender?.type]);

  return (
    <AppSelect<Company>
      name={tTitle("receiver")}
      options={options}
      isMultiple={false}
      value={value}
      isDisabled={isDisabled}
      isReadOnly={!isEditing}
      idFunc={(option) => `${option.type}_${option.id}`}
      isClearable={false}
      onSelect={(value) => {
        onSelect(value);
      }}
    />
  );
}
