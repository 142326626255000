import { Helmet } from "react-helmet-async";
import useGetDenseOrderSheetSet, {
  GetDenseOrderSheetSetPathParameter,
} from "@/features/order-sheet-sets/hooks/use-get-dense-order-sheet-set";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useParams } from "react-router";
import AppButton from "@/features/line-sheet-sets/app-button";
import {
  DenseOrderSheet,
  DenseOrderSheetTab,
  isOrderedToAgency,
} from "@/features/order-sheets/order-sheet.type";
import { useRecoilState, useRecoilValue } from "recoil";
import OrderSheetSetSaveButton from "@/features/order-sheet-sets/order-sheet-set-save-button";
import OrderSheetOrLineSheetSelector from "@/features/order-sheet-sets/order-sheet-or-line-sheet-selector";
import AppTabSelect from "@/features/line-sheet-sets/app-tap-select";
import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import OrderSheetSetBillboard from "@/features/order-sheet-sets/order-sheet-set-billboard";
import useCreateOrderSheet from "@/features/line-sheet-sets/hooks/use-create-order-sheet";
import OrderSheetTab from "@/features/order-sheet-sets/order-sheet-tab";
import { SubmitOrderSheetSetResponse } from "@/features/order-sheet-sets/hooks/use-submit-order-sheet-set";
import OrderSheetStatusUpdater, {
  isBulkUpdateOrderSheetStatusResponse,
  OrderSheetStatusUpdaterProps,
  UpdateOrderSheetStatusItem,
} from "@/features/order-status/order-sheet-status-updater";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import { CreateOrderSheetRevisionResponse } from "@/features/line-sheet-sets/hooks/use-create-order-sheet-revision";
import OrderSheetSetOrderButton from "@/features/order-sheet-sets/order-sheet-set-order-button";
import { useListAllOrderSheetStatistics } from "@/features/line-sheet-sets/hooks/use-list-all-order-sheet-statistics";
import OrderSheetAdjustPriceFragment from "@/features/order-sheet-sets/order-sheet-adjust-price-fragment";
import SheetTabFilter, {
  EMPTY_SHEET_TAB_FILTER_QUERY,
  isProductFiltered,
  SheetTabFilterQuery,
} from "@/features/order-sheet-sets/sheet-tab-filter";
import useFetchAndSetOrderSheetTabs from "@/features/order-sheet-sets/hooks/use-fetch-and-set-order-sheet-tabs";
import useOrderSheetSetPagePersistedQuery from "@/features/order-sheet-sets/hooks/use-order-sheet-set-page-persisted-query";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import OrderRulesPanel from "@/features/order-sheet-sets/order-rules-panel";
import { produce } from "immer";
import {
  clientSheetStatisticFamily,
  FlatProduct,
  FlatSheetKey,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { UpdateLineSheetSetOrderRulesResponse } from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-order-rules";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useBeforeUnload } from "react-router-dom";
import LineSheetTab from "@/features/line-sheet-sets/line-sheet-tab";
import useFetchAndSetLineSheetTabs from "@/features/line-sheet-sets/use-fetch-and-set-line-sheet-tabs";
import OrderSheetRevisionSelector from "./order-sheet-revision-selector";
import useListOrderSheetRevisions, {
  DEFAULT_LIST_REVISIONS_PAGE_SIZE,
  ListOrderSheetRevisionsPathParameter,
} from "./hooks/use-list-order-sheet-revisions";
import { OrderSheetRevision } from "./order-sheet-revision.type";
import { CompanyType } from "../types";
import { env } from "process";

export function isDenseOrderSheet(
  value: DenseOrderSheet | LightLineSheet
): value is DenseOrderSheet {
  return (value as LightLineSheet).name === undefined;
}

export function isLineSheet(
  value: DenseOrderSheet | LightLineSheet
): value is LightLineSheet {
  return !isDenseOrderSheet(value);
}

function OrderSheetSetPage() {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { orderSheetSetId: rawOrderSheetSetId } = useParams();
  const orderSheetSetId = useMemo(() => {
    let candidate;
    if (rawOrderSheetSetId) {
      candidate = parseInt(rawOrderSheetSetId);
    }
    return candidate || -1;
  }, [rawOrderSheetSetId]);

  const { t, tTitle } = useI18nHelper();

  const { navigate } = useRouterHelper();
  const { error: showError } = useAppToasts();
  const { openDialog } = useAppDialog();
  const gridRef = useRef<{
    api: GridApi<FlatProduct>;
    columnApi: ColumnApi;
  } | null>(null);

  const handleGridReady = useCallback((event: GridReadyEvent<FlatProduct>) => {
    gridRef.current = {
      api: event.api,
      columnApi: event.columnApi,
    };
  }, []);

  const getDenseOrderSheetSetParameter = useMemo(():
    | GetDenseOrderSheetSetPathParameter
    | undefined => {
    if (company && orderSheetSetId > -1) {
      return {
        by: company.type,
        companyId: company.id,
        orderSheetSetId,
      };
    }
  }, [company, orderSheetSetId]);

  const {
    isLoading: isGetDenseOrderSheetSetLoading,
    isValidating: isGetDenseOrderSheetSetValidating,
    error: getDenseOrderSheetSetError,
    data: getDenseOrderSheetSetData,
    mutate: mutateGetDenseOrderSheetSet,
  } = useGetDenseOrderSheetSet(
    getDenseOrderSheetSetParameter,
    {},
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnMount: true,
      dedupingInterval: 0,
    }
  );

  useEffect(() => {
    showError(getDenseOrderSheetSetError);
  }, [showError, getDenseOrderSheetSetError]);

  //order-sheet-set-select -->
  const { value: query, set: setQuery } = useOrderSheetSetPagePersistedQuery();

  const selectedOrderSheetOrLineSheet = useMemo(() => {
    if (getDenseOrderSheetSetData) {
      if (query.orderSheetId) {
        return getDenseOrderSheetSetData.orderSheets.find(
          (orderSheet) => orderSheet.id === query.orderSheetId
        );
      }
      if (query.lineSheetId) {
        return getDenseOrderSheetSetData.lineSheetSet.lineSheets.find(
          (lineSheet) => lineSheet.id === query.lineSheetId
        );
      }
    }
  }, [getDenseOrderSheetSetData, query.orderSheetId, query.lineSheetId]);

  const orderSheetOrLineSheetSelectValues = useMemo((): (
    | DenseOrderSheet
    | LightLineSheet
  )[] => {
    if (getDenseOrderSheetSetData) {
      const lineSheetSet = getDenseOrderSheetSetData.lineSheetSet;
      const options = lineSheetSet.lineSheets
        .flatMap((lineSheet): (DenseOrderSheet | LightLineSheet)[] => {
          const orderSheets = getDenseOrderSheetSetData.orderSheets.filter(
            (orderSheet) => {
              return orderSheet.lineSheet.id === lineSheet.id;
            }
          );

          if (orderSheets.length > 0) {
            return [...orderSheets];
          } else {
            return [{ ...lineSheet }];
          }
        })
        .filter((item) => {
          let lineSheet;
          if (isLineSheet(item)) {
            if (company?.type === "AGENCY") {
              return false;
            }
            // else if (
            //   company?.type === "BUYER" &&
            //   isOrderedToAgency(getDenseOrderSheetSetData)
            // ) {
            //   return false;
            // }
            lineSheet = item;
          } else {
            lineSheet = item.lineSheet;
          }

          return lineSheet.status === "PARSED";
        });

      const getOrder = (item: DenseOrderSheet | LightLineSheet) => {
        if (isDenseOrderSheet(item)) {
          if (item.orderedQuantity > 0) {
            return 1;
          } else {
            return 2;
          }
        } else {
          return 2;
        }
      };

      options.sort((a, b) => {
        return getOrder(a) - getOrder(b);
      });

      // if (isOrderedToAgency(getDenseOrderSheetSetData)) {
      //   options.sort((left, right) => {
      //     if (isDenseOrderSheet(left) && isLineSheet(right)) {
      //       return -1;
      //     } else if (isLineSheet(left) && isDenseOrderSheet(right)) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   });
      // }

      return options;
    }
    return [];
  }, [getDenseOrderSheetSetData, company]);

  const orderSheetTabOrLineSheetTabSelectValues = useMemo(() => {
    if (getDenseOrderSheetSetData && selectedOrderSheetOrLineSheet) {
      if (isDenseOrderSheet(selectedOrderSheetOrLineSheet)) {
        return selectedOrderSheetOrLineSheet.orderSheetTabs;
      } else {
        return selectedOrderSheetOrLineSheet.lineSheetTabs;
      }
    }
    return [];
  }, [selectedOrderSheetOrLineSheet, getDenseOrderSheetSetData]);

  const selectedOrderSheetTabOrLineSheetTab = useMemo(() => {
    if (selectedOrderSheetOrLineSheet) {
      if (isDenseOrderSheet(selectedOrderSheetOrLineSheet)) {
        return selectedOrderSheetOrLineSheet.orderSheetTabs.find(
          (i) => i.index === query.orderSheetTabIndex
        );
      } else if (isLineSheet(selectedOrderSheetOrLineSheet)) {
        return selectedOrderSheetOrLineSheet.lineSheetTabs.find(
          (i) => i.index == query.lineSheetTabIndex
        );
      }
    }
  }, [
    selectedOrderSheetOrLineSheet,
    query.orderSheetTabIndex,
    query.lineSheetTabIndex,
  ]);

  //--> order-sheet-set-select

  /* order-sheet-revision-selector */

  const getOrderSheetRivisionsParameter =
    useMemo((): ListOrderSheetRevisionsPathParameter => {
      if (
        selectedOrderSheetOrLineSheet &&
        orderSheetSetId > -1 &&
        getDenseOrderSheetSetData &&
        isDenseOrderSheet(selectedOrderSheetOrLineSheet)
      ) {
        return {
          orderSheetSetId: orderSheetSetId,
          orderSheetId: selectedOrderSheetOrLineSheet.id,
          by: "BUYER" as CompanyType,
          companyId: getDenseOrderSheetSetData?.createdByCompany.id,
        };
      }
      return {
        orderSheetSetId: -1,
        orderSheetId: -1,
        by: "BUYER" as CompanyType,
        companyId: -1,
      };
    }, [selectedOrderSheetOrLineSheet, orderSheetSetId, company]);

  const {
    isLoading: isGetOrderSheetRevisionsLoading,
    isValidating: isGetOrderSheetRevisionsValidating,
    error: getOrderSheetRevisionsError,
    data: getOrderSheetRevisionsData,
    mutate: mutateGetOrderSheetRevisions,
  } = useListOrderSheetRevisions(
    getOrderSheetRivisionsParameter,
    {
      pageNumber: 1,
      pageSize: DEFAULT_LIST_REVISIONS_PAGE_SIZE,
      submitted__eq: true,
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnMount: true,
      dedupingInterval: 0,
    }
  );

  const latestRevision = useMemo(() => {
    if (
      getOrderSheetRevisionsData &&
      getOrderSheetRevisionsData.revisions &&
      getOrderSheetRevisionsData.revisions.length > 0
    ) {
      return getOrderSheetRevisionsData.revisions.reduce(
        (prev: OrderSheetRevision, current: OrderSheetRevision) => {
          return prev.revisionNumber > current.revisionNumber ? prev : current;
        }
      );
    }
    return {
      revisionNumber: -1,
      createdAt: new Date(),
      createdBy: {
        id: -1,
        name: "",
        email: "",
      },
      createdByCompany: {
        id: -1,
        name: "",
        type: "BUYER" as CompanyType,
      },
    };
  }, [getOrderSheetRivisionsParameter, getOrderSheetRevisionsData]);

  const selectedOrderSheetRevision = useMemo(() => {
    if (
      getOrderSheetRevisionsData &&
      getOrderSheetRevisionsData.revisions.length > 0 &&
      typeof query.revisionNumber === "number" &&
      query.revisionNumber > 0
    ) {
      const revision = getOrderSheetRevisionsData.revisions.find(
        (revision) => revision.revisionNumber === query.revisionNumber
      );
      if (revision) {
        return revision;
      }
    }
    return latestRevision;
  }, [getOrderSheetRevisionsData, query.revisionNumber, latestRevision]);

  const orderSheetRevisionSelectValues = useMemo((): OrderSheetRevision[] => {
    if (getOrderSheetRevisionsData) {
      const revisions = getOrderSheetRevisionsData.revisions;
      if (revisions.length > 0) {
        return revisions;
      }
    }
    return [];
  }, [getOrderSheetRevisionsData]);

  //기본값 채워주기
  useEffect(() => {
    if (selectedOrderSheetOrLineSheet && getDenseOrderSheetSetData) {
      if (
        getOrderSheetRevisionsData &&
        isDenseOrderSheet(selectedOrderSheetOrLineSheet) &&
        query.orderSheetTabIndex === undefined
      ) {
        setQuery(
          (prev) => {
            return {
              ...prev,
              orderSheetTabIndex:
                selectedOrderSheetOrLineSheet.orderSheetTabs.length > 0
                  ? selectedOrderSheetOrLineSheet.orderSheetTabs[0].index
                  : undefined,
              revisionNumber: latestRevision.revisionNumber,
            };
          },
          {
            replace: true,
          }
        );
      } else if (
        isOrderedToAgency(getDenseOrderSheetSetData) &&
        isLineSheet(selectedOrderSheetOrLineSheet) &&
        query.lineSheetTabIndex === undefined
      ) {
        setQuery(
          (prev) => {
            return {
              ...prev,
              lineSheetTabIndex:
                selectedOrderSheetOrLineSheet.lineSheetTabs.length > 0
                  ? selectedOrderSheetOrLineSheet.lineSheetTabs[0].index
                  : undefined,
            };
          },
          {
            replace: true,
          }
        );
      }
    }
  }, [
    selectedOrderSheetOrLineSheet,
    query,
    setQuery,
    getDenseOrderSheetSetData,
    getOrderSheetRevisionsData,
    latestRevision,
  ]);

  //--> order-sheet-revision-selector

  const { filterInflatedProducts, resetInflatedSheet } = useInflatedTabs();

  const _selectedOrderSheetId = useMemo(() => {
    if (
      selectedOrderSheetOrLineSheet &&
      isDenseOrderSheet(selectedOrderSheetOrLineSheet)
    ) {
      return selectedOrderSheetOrLineSheet.id;
    }
    return -1;
  }, [selectedOrderSheetOrLineSheet]);

  const sheetKey = useMemo((): FlatSheetKey => {
    if (getDenseOrderSheetSetData && selectedOrderSheetOrLineSheet) {
      if (
        isOrderedToAgency(getDenseOrderSheetSetData) &&
        isLineSheet(selectedOrderSheetOrLineSheet)
      ) {
        return {
          lineSheetSetId: getDenseOrderSheetSetData.lineSheetSet.id,
          lineSheetId: selectedOrderSheetOrLineSheet.id,
        };
      } else if (getOrderSheetRevisionsData) {
        return {
          orderSheetSetId: getDenseOrderSheetSetData.id,
          orderSheetId: selectedOrderSheetOrLineSheet.id,
          revisionNumber: selectedOrderSheetRevision.revisionNumber,
        };
      } else {
        return {
          orderSheetSetId: getDenseOrderSheetSetData.id,
          orderSheetId: selectedOrderSheetOrLineSheet.id,
          revisionNumber: -1,
        };
      }
    }

    return {
      orderSheetSetId: -1,
      orderSheetId: -1,
      revisionNumber: -1,
    };
  }, [
    getDenseOrderSheetSetData,
    selectedOrderSheetOrLineSheet,
    getOrderSheetRevisionsData,
    selectedOrderSheetRevision,
  ]);

  const { mutate: mutateListAllOrderSheetStatistics } =
    useListAllOrderSheetStatistics(getDenseOrderSheetSetParameter, {});

  const [isOrderSheetEditing, setIsOrderSheetEditing] =
    useState<boolean>(false);

  const [inflatedTabs, setInflatedTabs] = useRecoilState(
    inflatedTabsFamily(sheetKey)
  );

  const sheetStatisticState = useRecoilValue(
    clientSheetStatisticFamily(sheetKey)
  );

  const isOrderSheetDirty = useMemo(() => {
    if (inflatedTabs) {
      const dirty = inflatedTabs.tabs.find((i) => i.isDirty) !== undefined;
      return dirty;
    }
    return false;
  }, [inflatedTabs]);

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isOrderSheetEditing || isOrderSheetDirty) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [isOrderSheetEditing, isOrderSheetDirty]
  );

  useBeforeUnload(handleBeforeUnload);

  const confirmDiscardChanges = useCallback(() => {
    if (isOrderSheetEditing || isOrderSheetDirty) {
      return openDialog({
        title: tTitle("common:discard_changes"),
        message: t("common:discard_changes.message"),
        confirmName: tTitle("continue"),
      });
    }
    return Promise.resolve(true);
  }, [isOrderSheetDirty, isOrderSheetEditing, openDialog, t, tTitle]);

  const handleSelectOrderSheetOrLineSheet = useCallback(
    async (value?: DenseOrderSheet | LightLineSheet) => {
      if (selectedOrderSheetOrLineSheet === value) {
        return;
      }

      if (value) {
        if (isOrderSheetEditing || isOrderSheetDirty) {
          const result = await confirmDiscardChanges();

          if (!result) {
            return false;
          }
        }
        if (isDenseOrderSheet(value)) {
          setQuery(
            (prev) => {
              if (prev.orderSheetId !== undefined) {
                resetInflatedSheet({
                  orderSheetSetId,
                  orderSheetId: prev.orderSheetId,
                  revisionNumber: prev.revisionNumber,
                });
              }

              return {
                ...prev,
                orderSheetId: value.id,
                orderSheetTabIndex:
                  value.orderSheetTabs.length > 0
                    ? value.orderSheetTabs[0].index
                    : undefined,
                revisionNumber: undefined,
                lineSheetTabIndex: undefined,
                lineSheetId: undefined,
                ...EMPTY_SHEET_TAB_FILTER_QUERY,
              };
            },
            { replace: true }
          );
        } else {
          setQuery(
            (prev) => {
              return {
                ...prev,
                orderSheetId: undefined,
                orderSheetTabIndex: undefined,
                revisionNumber: undefined,
                lineSheetTabIndex:
                  value.lineSheetTabs.length > 0
                    ? value.lineSheetTabs[0].index
                    : undefined,
                lineSheetId: value.id,
                ...EMPTY_SHEET_TAB_FILTER_QUERY,
              };
            },
            { replace: true }
          );
        }
      }
    },
    [
      selectedOrderSheetOrLineSheet,
      isOrderSheetEditing,
      isOrderSheetDirty,
      confirmDiscardChanges,
      setQuery,
      resetInflatedSheet,
      orderSheetSetId,
    ]
  );

  const handleSelectOrderSheetRevision = useCallback(
    async (value?: OrderSheetRevision) => {
      if (selectedOrderSheetRevision === value) {
        return;
      }

      if (value) {
        if (isOrderSheetEditing || isOrderSheetDirty) {
          const result = await confirmDiscardChanges();

          if (!result) {
            return false;
          }
        }
        setQuery(
          (prev) => {
            return {
              ...prev,
              revisionNumber: value.revisionNumber,
            };
          },
          { replace: true }
        );
      }
    },
    [
      selectedOrderSheetRevision,
      isOrderSheetEditing,
      isOrderSheetDirty,
      setQuery,
      confirmDiscardChanges,
    ]
  );

  const handleSelectOrderSheetTabOrLineSheetTab = useCallback(
    (value?: DenseOrderSheetTab) => {
      if (getDenseOrderSheetSetData && selectedOrderSheetOrLineSheet) {
        if (isDenseOrderSheet(selectedOrderSheetOrLineSheet)) {
          setQuery((prev) => {
            return {
              ...prev,
              orderSheetTabIndex: value?.index,
              ...EMPTY_SHEET_TAB_FILTER_QUERY,
            };
          });
        } else if (
          isOrderedToAgency(getDenseOrderSheetSetData) &&
          isLineSheet(selectedOrderSheetOrLineSheet)
        ) {
          setQuery((prev) => {
            return {
              ...prev,
              lineSheetTabIndex: value?.index,
              ...EMPTY_SHEET_TAB_FILTER_QUERY,
            };
          });
        }
      }
    },
    [getDenseOrderSheetSetData, selectedOrderSheetOrLineSheet, setQuery]
  );

  const {
    isLoading: isCreateOrderSheetLoading,
    data: createOrderSheetData,
    error: createOrderSheetError,
    fire: fireCreateOrderSheet,
    clear: clearCreateOrderSheet,
  } = useCreateOrderSheet();

  useEffect(() => {
    showError(createOrderSheetError);
  }, [showError, createOrderSheetError]);

  useEffect(() => {
    if (
      !isCreateOrderSheetLoading &&
      createOrderSheetData === undefined &&
      createOrderSheetError === undefined &&
      selectedOrderSheetOrLineSheet &&
      isLineSheet(selectedOrderSheetOrLineSheet) &&
      getDenseOrderSheetSetData &&
      company
    ) {
      fireCreateOrderSheet(
        {
          orderSheetSetId: getDenseOrderSheetSetData.id,
          by: company.type,
          companyId: company.id,
          //_lineSheetSetId: getDenseOrderSheetSetData.lineSheetSet.id,
          //_lineSheetId: selectedOrderSheetOrLineSheet.id,
        },
        {
          lineSheet: {
            id: selectedOrderSheetOrLineSheet.id,
          },
        }
      );
    }
  }, [
    isCreateOrderSheetLoading,
    createOrderSheetData,
    createOrderSheetError,
    selectedOrderSheetOrLineSheet,
    company,
    getDenseOrderSheetSetData,
    fireCreateOrderSheet,
  ]);

  useEffect(() => {
    if (createOrderSheetData) {
      const orderSheetId = createOrderSheetData.id;
      setQuery(
        (prev) => {
          return {
            ...prev,
            orderSheetId,
            orderSheetTabIndex: createOrderSheetData.orderSheetTabs[0].index,
            lineSheetId: undefined,
          };
        },
        {
          replace: true,
        }
      );
      mutateGetDenseOrderSheetSet().then(() => {
        clearCreateOrderSheet();
      });
    }
  }, [
    createOrderSheetData,
    setQuery,
    mutateGetDenseOrderSheetSet,
    clearCreateOrderSheet,
  ]);

  const useFetchAndSetOrderSheetTabsParameter = useMemo(() => {
    if (
      getDenseOrderSheetSetData &&
      selectedOrderSheetOrLineSheet &&
      getOrderSheetRevisionsData &&
      selectedOrderSheetRevision &&
      isDenseOrderSheet(selectedOrderSheetOrLineSheet)
    ) {
      const orderSheetSet = getDenseOrderSheetSetData;
      const orderSheet = selectedOrderSheetOrLineSheet;
      return {
        data: {
          buyerCompanyId: orderSheetSet.createdByCompany.id,
          orderSheetSetId: getDenseOrderSheetSetData.id,
          orderSheetId: orderSheet.id,
          revisionNumber: selectedOrderSheetRevision.revisionNumber,
          latestRevisionNumber: latestRevision.revisionNumber,
          lineSheetSetId: orderSheetSet.lineSheetSet.id,
          lineSheetId: orderSheet.lineSheet.id,
          tabIndexes: orderSheet.orderSheetTabs.map((i) => i.index),
        },
        query,
      };
    }
    return {
      data: undefined,
    };
  }, [
    getDenseOrderSheetSetData,
    selectedOrderSheetOrLineSheet,
    getOrderSheetRevisionsData,
    selectedOrderSheetRevision,
    latestRevision,
    query,
  ]);

  const {
    mutateListAllSheetTabs,
    mutateListAllSheetTabsRevision,
    setInflatedTabsClean,
  } = useFetchAndSetOrderSheetTabs(useFetchAndSetOrderSheetTabsParameter);

  const useFetchAndSetLineSheetTabsParameter = useMemo(() => {
    if (
      getDenseOrderSheetSetData &&
      selectedOrderSheetOrLineSheet &&
      isOrderedToAgency(getDenseOrderSheetSetData) &&
      isLineSheet(selectedOrderSheetOrLineSheet)
    ) {
      return {
        data: {
          lineSheetSetId: getDenseOrderSheetSetData.lineSheetSet.id,
          lineSheetId: selectedOrderSheetOrLineSheet.id,
          tabIndexes: selectedOrderSheetOrLineSheet.lineSheetTabs.map(
            (i) => i.index
          ),
        },
        query,
      };
    }
    return {
      data: undefined,
    };
  }, [getDenseOrderSheetSetData, query, selectedOrderSheetOrLineSheet]);

  useFetchAndSetLineSheetTabs(useFetchAndSetLineSheetTabsParameter);

  const [isOrderSheetSaving, setIsOrderSheetSaving] = useState<boolean>(false);

  const [
    isUpdatingInflatedProductCompleted,
    setIsUpdatingInflatedProductCompleted,
  ] = useState(false);

  const orderSheetSetSaveButtonRef = useRef<{
    handleSaveOrderSheetSet: () => void;
  }>(null);

  const [
    isOrderSheetSetSaveButtonClicked,
    setIsOrderSheetSetSaveButtonClicked,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (isUpdatingInflatedProductCompleted) {
      setIsUpdatingInflatedProductCompleted(false);

      /* 1. save 버튼을 클릭해서 호출된 경우 order-sheet-set-page.tsx에서 save 버튼이 클릭되었다는 상태값을 true로 변경
       * 2-1. save버튼이 클릭되었다는 상태값이 true인 경우 handleSaveOrderSheetSet()을 실행하고 상태값을 false로 변경
       * 2-2. save버튼이 클릭되었다는 상태값이 false인 경우 아무것도 하지 않음
       */
      if (
        orderSheetSetSaveButtonRef.current &&
        isOrderSheetSetSaveButtonClicked
      ) {
        orderSheetSetSaveButtonRef.current.handleSaveOrderSheetSet();
      }
    }
  }, [
    isUpdatingInflatedProductCompleted,
    setIsUpdatingInflatedProductCompleted,
    isOrderSheetSetSaveButtonClicked,
  ]);

  const handleAfterSaveOrderSheetSet = useCallback(
    async (response: CreateOrderSheetRevisionResponse) => {
      await Promise.all([
        mutateGetDenseOrderSheetSet(),
        mutateListAllOrderSheetStatistics(),
      ]);

      //sheetKey 변경 -> reset inflatedTabs -> tab mutate하기!
      //isDirty초기화는 의미가 없음 어차피 reset으로 다 날리니까

      await mutateListAllSheetTabs();
      await mutateListAllSheetTabsRevision();
      //setInflatedTabsClean();
    },
    [
      mutateGetDenseOrderSheetSet,
      mutateListAllOrderSheetStatistics,
      mutateListAllSheetTabs,
      mutateListAllSheetTabsRevision,
      //setInflatedTabsClean,
    ]
  );

  const handleOrderSheetSetSubmit = useCallback(
    (response: SubmitOrderSheetSetResponse) => {
      mutateGetDenseOrderSheetSet();
      mutateListAllOrderSheetStatistics();

      //이제 여기에서 상세로 돌아가라는 말이지?

      navigate(-1, {
        defaultPage: "/line-sheets",
      });
    },
    [mutateGetDenseOrderSheetSet, mutateListAllOrderSheetStatistics, navigate]
  );

  //orderSheetSetStatusUpdater
  const updateOrderSheetStatusItems =
    useMemo((): UpdateOrderSheetStatusItem[] => {
      if (getDenseOrderSheetSetData && selectedOrderSheetOrLineSheet) {
        if (isDenseOrderSheet(selectedOrderSheetOrLineSheet)) {
          const orderSheetSet = getDenseOrderSheetSetData;
          const lineSheetSet = orderSheetSet.lineSheetSet;
          const orderSheet = selectedOrderSheetOrLineSheet;
          const lineSheet = orderSheet.lineSheet;
          return [
            {
              orderSheetSetId: orderSheetSet.id,
              id: orderSheet.id,
              name: lineSheet.name,
              suffix: orderSheet.suffix,
              status: orderSheet.status,
              createdByCompany: orderSheetSet.createdByCompany,
              issuedBy: lineSheetSet.issuedByBoutique,
              lineSheet: {
                id: lineSheet.id,
                status: lineSheet.status,
              },
              toBeSubmitted: orderSheet.toBeSubmitted,
            },
          ];
        }
      }
      return [];
    }, [getDenseOrderSheetSetData, selectedOrderSheetOrLineSheet]);

  const isLoading =
    isGetDenseOrderSheetSetLoading ||
    isGetDenseOrderSheetSetValidating ||
    isCreateOrderSheetLoading ||
    isOrderSheetSaving ||
    isGetOrderSheetRevisionsLoading;

  const isOrderSheetEditable = useMemo(() => {
    if (
      company?.type === "BUYER" &&
      selectedOrderSheetOrLineSheet &&
      isDenseOrderSheet(selectedOrderSheetOrLineSheet)
    ) {
      if (
        selectedOrderSheetRevision &&
        latestRevision &&
        // 최신 리비전인 경우에만 편집 가능
        latestRevision.revisionNumber >
          selectedOrderSheetRevision.revisionNumber
      ) {
        return false;
      }
      return ["INITIAL_DRAFT", "WORKING_DRAFT", "RESUBMIT_REQUIRED"].includes(
        selectedOrderSheetOrLineSheet.status
      );
    }
    return false;
  }, [
    company?.type,
    selectedOrderSheetOrLineSheet,
    selectedOrderSheetRevision,
    latestRevision,
  ]);

  const isOrderSheetSetOrderable = useMemo(() => {
    if (getDenseOrderSheetSetData) {
      return getDenseOrderSheetSetData.orderSheets.some((orderSheet) => {
        return ["INITIAL_DRAFT", "WORKING_DRAFT", "RESUBMIT_REQUIRED"].includes(
          orderSheet.status
        );
      });
    }
    return false;
  }, [getDenseOrderSheetSetData]);

  const handleOrderSheetSetEditing = useCallback((isEditing: boolean) => {
    setIsOrderSheetEditing(isEditing);
  }, []);

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  const handleFilterSelect = useCallback(
    (query: SheetTabFilterQuery) => {
      setQuery(
        (prev) => {
          const next = {
            ...prev,
            ...query,
          };
          return next;
        },
        {
          replace: true,
        }
      );
    },
    [setQuery]
  );

  const handleSheetTabFilterClear = useCallback(() => {
    setQuery(
      (prev) => {
        return {
          ...prev,
          ...EMPTY_SHEET_TAB_FILTER_QUERY,
        };
      },
      {
        replace: true,
      }
    );
  }, [setQuery]);

  useEffect(() => {
    if (selectedOrderSheetOrLineSheet && selectedOrderSheetTabOrLineSheetTab) {
      filterInflatedProducts(
        {
          orderSheetSetId,
          orderSheetId: selectedOrderSheetOrLineSheet.id,
          revisionNumber: selectedOrderSheetRevision.revisionNumber,
        },
        selectedOrderSheetTabOrLineSheetTab.index,
        (products) => {
          return isProductFiltered(products, query);
        }
      );
    }
  }, [
    orderSheetSetId,
    selectedOrderSheetOrLineSheet,
    selectedOrderSheetTabOrLineSheetTab,
    selectedOrderSheetRevision,
    filterInflatedProducts,
    query,
  ]);

  const [isOrderRulesPanelOpen, setIsOrderRulesPanelOpen] =
    useState<boolean>(false);

  const handleOrderRulesToggle = useCallback(() => {
    setIsOrderRulesPanelOpen((prev) => !prev);
  }, [setIsOrderRulesPanelOpen]);

  const handleOrderRulesSave = useCallback(
    (response: UpdateLineSheetSetOrderRulesResponse) => {
      mutateGetDenseOrderSheetSet(
        (prev) => {
          if (prev) {
            return produce(prev, (draft) => {
              draft.lineSheetSet.orderRules = response.orderRules;
            });
          }
        },
        { revalidate: false }
      );
    },
    [mutateGetDenseOrderSheetSet]
  );

  const isConfirmedEditable = useMemo(() => {
    return (
      company?.type === "AGENCY" &&
      selectedOrderSheetOrLineSheet !== undefined &&
      isDenseOrderSheet(selectedOrderSheetOrLineSheet) &&
      selectedOrderSheetOrLineSheet.status === "ORDERED_TO_BOUTIQUE"
    );
  }, [company?.type, selectedOrderSheetOrLineSheet]);

  const isConditionEditable = useMemo(() => {
    return (
      (isOrderSheetEditable &&
        getDenseOrderSheetSetData?.status !== "RESUBMIT_REQUIRED") ||
      isConfirmedEditable
    );
  }, [
    getDenseOrderSheetSetData?.status,
    isConfirmedEditable,
    isOrderSheetEditable,
  ]);

  const handleUpdateOrderSheetStatus: OrderSheetStatusUpdaterProps["onUpdate"] =
    useCallback(
      (data) => {
        if (isBulkUpdateOrderSheetStatusResponse(data)) {
          mutateGetDenseOrderSheetSet();
        }
      },
      [mutateGetDenseOrderSheetSet]
    );

  return (
    <>
      <Helmet>
        <title>Pathrade | Order Sheets</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content="Pathrade | Order Sheets" />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex
        flexDirection={"column"}
        height={"100%"}
        onClick={() => {
          gridRef.current?.api.stopEditing();
        }}
      >
        <Flex
          flexDirection={"row"}
          p={"8px 16px 8px 16px"}
          gap={"12px"}
          alignItems={"start"}
          borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
          background={"var(--white, #FFF)"}
        >
          <Box flexShrink={"0"} flexGrow={"0"} alignSelf={"start"} pt={"1.5px"}>
            <AppIconButton
              variant={"ghost"}
              ariaLabel={"go to previous page"}
              size={"medium"}
              icon={"left"}
              onClick={async () => {
                const result = await confirmDiscardChanges();
                if (result) {
                  navigate(-1, {
                    defaultPage: "/line-sheets",
                  });
                }
              }}
            />
          </Box>
          <OrderSheetSetBillboard
            orderSheetSet={getDenseOrderSheetSetData}
            selectedOrderSheetId={
              selectedOrderSheetOrLineSheet &&
              isDenseOrderSheet(selectedOrderSheetOrLineSheet)
                ? selectedOrderSheetOrLineSheet.id
                : -1
            }
            revisionNumber={selectedOrderSheetRevision?.revisionNumber}
          />
          <Spacer />
          <Flex
            flexDirection={"row"}
            gap={"8px"}
            wrap={"wrap"}
            flexGrow={"0"}
            width={"248px"}
            minWidth={"248px"}
          >
            {(company?.type === "BUYER" ||
              (company?.type === "AGENCY" &&
                selectedOrderSheetOrLineSheet &&
                isDenseOrderSheet(selectedOrderSheetOrLineSheet) &&
                selectedOrderSheetOrLineSheet.status ===
                  "ORDERED_TO_BOUTIQUE")) && (
              <>
                <Flex
                  flexDirection={"row"}
                  gap={"8px"}
                  wrap={"wrap"}
                  flexGrow={"0"}
                >
                  {isOrderSheetEditable && (
                    <AppButton
                      width={"120px"}
                      isDisabled={
                        sheetStatisticState?.statistic.totalOrderedQuantity ===
                        0
                      }
                      onClick={() => {
                        gridRef.current?.api.stopEditing(true);
                        if (!inflatedTabs) {
                          return;
                        }
                        setInflatedTabs((prev) => {
                          if (!prev) {
                            return;
                          }
                          return {
                            ...prev,
                            tabs: prev.tabs.map((tab) => {
                              return {
                                ...tab,
                                isDirty: true,
                                products: tab.products.map((product) => {
                                  return {
                                    ...product,
                                    orderQuantityWithOptionList:
                                      product.orderQuantityWithOptionList.map(
                                        (orderQuantityWithOption) => {
                                          return {
                                            ...orderQuantityWithOption,
                                            quantity: 0,
                                          };
                                        }
                                      ),
                                  };
                                }),
                              };
                            }),
                          };
                        });
                      }}
                    >
                      {tTitle("common:reset_order")}
                    </AppButton>
                  )}
                  <OrderSheetSetSaveButton
                    orderSheetSetId={orderSheetSetId}
                    orderSheet={
                      selectedOrderSheetOrLineSheet &&
                      isDenseOrderSheet(selectedOrderSheetOrLineSheet)
                        ? selectedOrderSheetOrLineSheet
                        : undefined
                    }
                    revisionNumber={selectedOrderSheetRevision?.revisionNumber}
                    isOrderSheetEditable={isOrderSheetEditable}
                    isOrderSheetEditing={isOrderSheetEditing}
                    isAllOrderSheetsOrdered={
                      getDenseOrderSheetSetData
                        ? getDenseOrderSheetSetData.orderSheets.every(
                            isOrderedToAgency
                          )
                        : false
                    }
                    isConfirmedEditable={isConfirmedEditable}
                    onUpdatingInflatedProductCompleted={
                      setIsUpdatingInflatedProductCompleted
                    }
                    onOrderSheetSetSaveButtonClicked={
                      setIsOrderSheetSetSaveButtonClicked
                    }
                    onSaveLoading={setIsOrderSheetSaving}
                    onAfterSave={handleAfterSaveOrderSheetSet}
                    gridRef={gridRef}
                    ref={orderSheetSetSaveButtonRef}
                  />
                  {/*<OrderSheetSetOrderButton*/}
                  {/*  isOrderSheetEditing={isOrderSheetEditing}*/}
                  {/*  isAllOrderSheetsOrdered={*/}
                  {/*    getDenseOrderSheetSetData*/}
                  {/*      ? getDenseOrderSheetSetData.orderSheets.every(*/}
                  {/*          isOrderedToAgency*/}
                  {/*        )*/}
                  {/*      : false*/}
                  {/*  }*/}
                  {/*  isOrderSheetSetOrderable={isOrderSheetSetOrderable}*/}
                  {/*  orderSheetSet={getDenseOrderSheetSetData}*/}
                  {/*  orderSheet={*/}
                  {/*    selectedOrderSheetOrLineSheet !== undefined &&*/}
                  {/*    isDenseOrderSheet(selectedOrderSheetOrLineSheet)*/}
                  {/*      ? selectedOrderSheetOrLineSheet*/}
                  {/*      : undefined*/}
                  {/*  }*/}
                  {/*  revisionNumber={selectedOrderSheetRevision?.revisionNumber}*/}
                  {/*  onSave={handleOrderSheetSetSave}*/}
                  {/*  onSubmit={handleOrderSheetSetSubmit}*/}
                  {/*/>*/}
                </Flex>
                <OrderSheetRevisionSelector
                  minWidth={"100%"}
                  values={orderSheetRevisionSelectValues}
                  value={selectedOrderSheetRevision}
                  onSelect={handleSelectOrderSheetRevision}
                />
              </>
            )}
            {company?.type === "AGENCY" &&
              getDenseOrderSheetSetData?.status !== "ORDERED_TO_BOUTIQUE" && (
                <>
                  <OrderSheetStatusUpdater
                    items={updateOrderSheetStatusItems}
                    onUpdate={handleUpdateOrderSheetStatus}
                  />
                </>
              )}
          </Flex>
        </Flex>
        <Flex>
          <Flex
            position={"relative"}
            zIndex={"2"}
            background={"#FCFCFE"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"652px"}
            gap={"8px"}
            pl={"16px"}
          >
            <Box
              fontSize={"13px"}
              fontWeight={"700"}
              lineHeight={"16px"}
              flexGrow={"1"}
            >
              {identity?.company?.type === "AGENCY"
                ? `#${getDenseOrderSheetSetData?.id} ${getDenseOrderSheetSetData?.lineSheetSet.name}`
                : getDenseOrderSheetSetData?.lineSheetSet.name}
            </Box>
            <OrderSheetOrLineSheetSelector
              minWidth={"326px"}
              values={orderSheetOrLineSheetSelectValues}
              value={selectedOrderSheetOrLineSheet}
              onSelect={handleSelectOrderSheetOrLineSheet}
            />
          </Flex>
          <AppTabSelect
            name={tTitle("line_sheet_tab")}
            value={selectedOrderSheetTabOrLineSheetTab}
            values={orderSheetTabOrLineSheetTabSelectValues}
            onSelect={handleSelectOrderSheetTabOrLineSheetTab}
          />
        </Flex>

        <Flex flexGrow={"1"} flexDirection={"column"}>
          <Flex
            flexDirection={"row"}
            padding={"2px 12px 8px 16px"}
            alignItems={"center"}
            gap={"8px"}
          >
            <AppButton variant={"normal"} onClick={handleOrderRulesToggle}>
              {tTitle("order_rules")}
            </AppButton>
            {company?.type === "BUYER" && (
              <>
                <OrderSheetAdjustPriceFragment
                  orderSheetSetId={orderSheetSetId}
                  orderSheetId={
                    selectedOrderSheetOrLineSheet &&
                    isDenseOrderSheet(selectedOrderSheetOrLineSheet)
                      ? selectedOrderSheetOrLineSheet.id
                      : -1
                  }
                  revisionNumber={selectedOrderSheetRevision.revisionNumber}
                  tabIndex={
                    selectedOrderSheetTabOrLineSheetTab
                      ? selectedOrderSheetTabOrLineSheetTab.index
                      : -1
                  }
                  selectedProductIds={selectedProductIds}
                  isEditable={isOrderSheetEditable}
                />
              </>
            )}
            <Spacer />
            <SheetTabFilter
              sheetKey={sheetKey}
              tabIndex={
                selectedOrderSheetTabOrLineSheetTab
                  ? selectedOrderSheetTabOrLineSheetTab.index
                  : -1
              }
              query={query}
              onFilterSelect={handleFilterSelect}
              onClear={handleSheetTabFilterClear}
            />
          </Flex>
          <Flex flexGrow={1} flexDirection={"row"} gap={"8px"}>
            {isOrderRulesPanelOpen && (
              <Box>
                <OrderRulesPanel
                  isEditable={false}
                  onClose={handleOrderRulesToggle}
                  orderRules={
                    getDenseOrderSheetSetData?.lineSheetSet.orderRules || []
                  }
                  lineSheetSetId={
                    getDenseOrderSheetSetData
                      ? getDenseOrderSheetSetData.lineSheetSet.id
                      : -1
                  }
                  onUpdate={handleOrderRulesSave}
                />
              </Box>
            )}
            {selectedOrderSheetOrLineSheet &&
            getDenseOrderSheetSetData &&
            isOrderedToAgency(getDenseOrderSheetSetData) &&
            isLineSheet(selectedOrderSheetOrLineSheet) ? (
              <LineSheetTab
                lineSheetSetId={
                  getDenseOrderSheetSetData?.lineSheetSet?.id || -1
                }
                lineSheetId={selectedOrderSheetOrLineSheet.id}
                tabIndex={
                  selectedOrderSheetTabOrLineSheetTab
                    ? selectedOrderSheetTabOrLineSheetTab.index
                    : -1
                }
                isLoading={isLoading}
                onSelectedProductIdsChange={setSelectedProductIds}
                onGridReady={handleGridReady}
              />
            ) : (
              <OrderSheetTab
                isLoading={isLoading}
                orderSheetSetId={getDenseOrderSheetSetData?.id || -1}
                orderSheetId={
                  selectedOrderSheetOrLineSheet &&
                  isDenseOrderSheet(selectedOrderSheetOrLineSheet)
                    ? selectedOrderSheetOrLineSheet.id
                    : -1
                }
                revisionNumber={selectedOrderSheetRevision.revisionNumber}
                tabIndex={
                  selectedOrderSheetTabOrLineSheetTab
                    ? selectedOrderSheetTabOrLineSheetTab.index
                    : -1
                }
                isOrderEditable={isOrderSheetEditable}
                isConfirmedEditable={isConfirmedEditable}
                isConditionEditable={isConditionEditable}
                onEditing={handleOrderSheetSetEditing}
                onSelectedProductIdsChange={setSelectedProductIds}
                onGridReady={handleGridReady}
                onUpdatingInflatedProductComplete={
                  setIsUpdatingInflatedProductCompleted
                }
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

// OrderSheetSetPage.whyDidYouRender = true;

export default OrderSheetSetPage;
