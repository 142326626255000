import { Company, StoredObject } from "@/features/types";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback } from "react";
import AppSubmitButton from "@/features/line-sheet-sets/app-submit-button";
import TriangularTradeForm from "@/features/invoices/triangular-trades/triangular-trade-form";
import { useTriangularTradeDumbTransaction } from "@/features/invoices/triangular-trades/hooks/use-triangular-trade-dumb-transaction";
import useTriangularTradeFormValidationSchema from "@/features/invoices/triangular-trades/hooks/use-triangular-trade-form-validation-schema";
import {
  BankInfo,
  Departure,
  Forwarder,
  FtaSignature,
  IssuedBy,
  IssuedTo,
  TriangularTrade,
} from "@/features/invoices/triangular-trades/triangular-trade.type";

export interface TriangularTradeFormFields {
  id?: number;
  boutique?: Pick<Company, "id" | "name">;
  originallyIssuedBy?: string;
  // departure?: Departure;
  issuedBy?: IssuedBy;
  issuedTo?: IssuedTo;
  bankInfo?: BankInfo;
  forwarder?: Forwarder;
  incoterms?: string;
  ftaSignature?: FtaSignature;
  issuedOn?: Date;
  invoiceNumber?: string;
  referenceInvoiceNumber?: string;
  markup?: number;
  packageInfo?: string;
  comment?: string;
  status?: TriangularTrade["status"];
  importedFiles?: (Pick<StoredObject, "id" | "name"> | File)[];
  exportedFile?: Pick<StoredObject, "id" | "name"> | File;
}

export default function UploadTriangularTradePage() {
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();

  const validateSchema = useTriangularTradeFormValidationSchema();
  const validate = useCallback(
    async (values: TriangularTradeFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [showError, validateSchema]
  );

  const { addCreate } = useTriangularTradeDumbTransaction();

  const handleSubmit = useCallback(
    async (values: TriangularTradeFormFields) => {
      if (identity?.company) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            importedFiles: values.importedFiles! as File[],
            originallyIssuedBy: values.originallyIssuedBy!,
            // departure: values.departure!,
            issuedBy: values.issuedBy!,
            issuedTo: values.issuedTo!,
            bankInfo: values.bankInfo!,
            forwarder: values.forwarder!,
            incoterms: values.incoterms || "EX WORKS",
            ftaSignature: values.ftaSignature!,
            issuedOn: values.issuedOn || new Date(),
            invoiceNumber: values.invoiceNumber!,
            referenceInvoiceNumber: values.referenceInvoiceNumber!,
            markup: values.markup!,
            packageInfo: values.packageInfo!,
            comment: values.comment!,
          }
        );

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 500);
        });
      }

      return false;
    },
    [addCreate, identity]
  );

  return (
    <Formik<TriangularTradeFormFields>
      initialValues={{
        id: -1,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={(values, formikHelpers) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"header"} as={"h2"} fontSize={"14px"}>
                  {tTitle("upload_triangular_trade")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppSubmitButton
                  key={"upload_and_add_another"}
                  addOther={true}
                  formik={props}
                />,
                <AppSubmitButton key={"upload"} formik={props} />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/triangular-trades"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <TriangularTradeForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
