import {
  CompanyType,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { TriangularTrade } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";

export interface ListTriangularTradesRequest
  extends PageNumberBasedListRequest {}

export interface ListTriangularTradesResponse
  extends PageNumberBasedListResponse {
  triangularTrades: TriangularTrade[];
}

export interface ListTriangularTradesPathParameter {
  by: CompanyType;
  companyId: number;
}

export const listTriangularTradesTemplateUrl = `/:by/:companyId/triangular-trades`;

export function getListTriangularTradesKey(
  parameter?: ListTriangularTradesPathParameter,
  request?: ListTriangularTradesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listTriangularTradesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export default function useListTriangularTrades(
  parameter?: ListTriangularTradesPathParameter,
  request?: ListTriangularTradesRequest,
  config?: SWRConfiguration<ListTriangularTradesResponse>
) {
  const fetcher = useFetcher<
    ListTriangularTradesRequest,
    ListTriangularTradesResponse
  >();

  return useSWR(
    getListTriangularTradesKey(parameter, request),
    fetcher,
    config
  );
}
