import { Box, Flex, Heading } from "@chakra-ui/react";

interface AppMyAccountCardProps {
  title: string;
  children: JSX.Element;
}
export default function AppMyAccountCard({
  title,
  children,
}: AppMyAccountCardProps) {
  return (
    <Flex
      width={"100%"}
      flexDirection={"column"}
      borderRadius={"8px"}
      border={"0.5px solid var(--pathrade-primary, #D4D4D1)"}
      background={"var(--pathrade-white, #FFF)"}
      boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.10)"}
      _hover={{
        borderColor: "#1272EF",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
      }}
    >
      <Heading
        as={"h3"}
        fontSize={"18px"}
        borderBottom={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
        padding={"16px 24px"}
      >
        {title}
      </Heading>
      <Box padding={"16px 24px"}>{children}</Box>
      <Box
        borderTop={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
        height={"24px"}
      ></Box>
    </Flex>
  );
}
