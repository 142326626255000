import AppFormModal from "@/features/ui/app-form-modal";
import { Field, FieldProps, Form, Formik } from "formik";
import { Company } from "@/features/types";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import AppInput from "@/features/ui/app-input";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import { toLower } from "@/utils/case";
import { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useCreateCompany from "@/features/ui/hooks/use-create-company";
import useIdentity from "@/features/ui/hooks/use-identity";

export interface CreateCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetBy: Company["type"];
  onCreate?: (company: Company) => void;
}

interface FormFields {
  name?: string;
  targetBy: Company["type"];
}

export default function CreateCompanyModal({
  isOpen,
  onClose,
  targetBy,
  onCreate,
}: CreateCompanyModalProps) {
  const { t, tTitle } = useI18nHelper();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "common",
    prefix: "create_company_form",
  });

  const companyType = toLower(targetBy);
  const validateSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().label("name").required(withMessage.required),
    });
  }, [withMessage.required]);

  async function validate(values: FormFields) {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      showError(e);
      return e;
    }
  }

  const {
    fire: fireCreateCompany,
    error: createCompanyError,
    data: createCompanyData,
  } = useCreateCompany();

  useEffect(() => {
    if (createCompanyError) {
      showError(createCompanyError);
    }
  }, [createCompanyError, showError]);

  useEffect(() => {
    if (createCompanyData) {
      onCreate?.(createCompanyData);
      onClose();
    }
  }, [createCompanyData, onClose, onCreate]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company) {
        return fireCreateCompany(
          {
            by: identity.company.type,
            companyId: identity.company.id,
            targetBy: values.targetBy,
          },
          {
            name: values.name!,
          }
        );
      }

      return false;
    },
    [fireCreateCompany, identity]
  );

  return (
    <Formik<FormFields>
      initialValues={{ targetBy }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      <AppFormModal
        title={tTitle("create_company_form.title", {
          companyType: t(companyType),
        })}
        submitName={"add.do"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text fontSize={"12px"}>
          {t("create_company_form.message", {
            companyType: t(companyType),
          })}
        </Text>

        <Form>
          <Field name="name">
            {(props: FieldProps) => {
              return (
                <FormControl
                  display={"flex"}
                  gap={"8px"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginTop={"18px"}
                >
                  <FormLabel m={"0"} p={"0"} fontSize={"12px"} flexShrink={"0"}>
                    {tTitle("create_company_form.field.name.label", {
                      companyType: t(companyType),
                    })}
                  </FormLabel>
                  <AppInput
                    placeholder={t(
                      "common:create_company_form.field.name.placeholder"
                    )}
                    value={props.field.value}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
