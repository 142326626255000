import { Inventory } from "@/features/inventories/inventory.type";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

export interface UpdateInventoryRequest {
  name: string;
  comment: string | null;
  organizedOn: Date;
  organizedBy: { id: number };
  files: { id: number }[];
}

export interface UpdateInventoryResponse extends Inventory {}

export interface UpdateInventoryPathParameter extends IdentityParameter {
  inventoryId: number;
}

export const updateInventoryKey: YWRKey<UpdateInventoryPathParameter> = {
  url: (parameter: UpdateInventoryPathParameter) => {
    return calcUrl("/:by/:companyId/inventories/:inventoryId", parameter);
  },
  method: "put",
};

export default function useUpdateInventory() {
  const fetcher = useFetcher<UpdateInventoryRequest, UpdateInventoryResponse>();
  return useYWR(updateInventoryKey, fetcher);
}
