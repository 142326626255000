import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useListBalanceTransactions, {
  DEFAULT_LIST_BALANCE_TRANSACTIONS_PAGE_SIZE,
  ListBalanceTransactionsPathParameter,
  ListBalanceTransactionsRequest,
} from "@/features/balance-transaction-status/hooks/use-list-balance-transactions";
import { Box, Flex } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_INVOICES_PAGE_SIZE } from "@/features/invoices/hooks/use-list-invoices";
import AgencyBalanceTransactionGrid from "@/features/balance-transaction-status/agency-balance-transaction-grid";
import { useCallback, useEffect, useMemo } from "react";
import AppBalanceTransactionTypeSelector from "@/features/balance-transaction-status/app-balance-transaction-type-selector";
import AppBalanceTransactionAddUseSelector from "@/features/balance-transaction-status/app-balance-transaction-add-use-selector";
import { toTitle } from "@/utils/case";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import DownloadDepositStateModal, {
  DownloadDepositStateModalProps,
} from "@/features/balance-transaction-status/download-deposit-state-modal";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";

export interface PersistedBalanceTransactionListPageQuery
  extends Partial<ListBalanceTransactionsRequest> {}

export default function AgencyBalanceTransactionListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();

  const [query, setQuery] =
    useTypedSearchParams<PersistedBalanceTransactionListPageQuery>();

  const listBalanceTransactionsKey = useMemo(():
    | {
        parameter: ListBalanceTransactionsPathParameter;
        request: ListBalanceTransactionsRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_BALANCE_TRANSACTIONS_PAGE_SIZE,
        },
      };
    }
  }, [identity, query]);

  const {
    isLoading: isListBalanceTransactionsLoading,
    data: listBalanceTransactionsData,
    error: listBalanceTransactionsError,
    mutate: mutateListBalanceTransactions,
  } = useListBalanceTransactions(
    listBalanceTransactionsKey?.parameter,
    listBalanceTransactionsKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    showError(listBalanceTransactionsError);
  }, [showError, listBalanceTransactionsError]);

  const {
    isOpen: isDownloadDepositStateModalOpen,
    onOpen: onDownloadDepositStateModalOpen,
    onClose: onDownloadDepositStateModalClose,
    openWithData: openDownloadDepositStateModal,
    data: downloadDepositStateModalData,
  } = useDisclosureWithData<DownloadDepositStateModalProps>();

  const handleDownloadDepositStateClick = useCallback(() => {
    openDownloadDepositStateModal((prev) => {
      return {
        ...prev,
        initialValues: {},
      };
    });
  }, [openDownloadDepositStateModal]);

  return (
    <>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        gap={"12px"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppSeasonMultiSelect
              key={"season"}
              value={query.season__in}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"AgencyInvoiceListFilter_OrderedBy"}
              name={toTitle(t("issued_to"))}
              types={["BUYER"]}
              width={"144px"}
              id={query["issuedToCompany.id__eq"]}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "issuedToCompany.id__eq": value?.id,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"AgencyInvoiceListFilter_IssuedBy"}
              name={toTitle(t("issued_by"))}
              types={["BOUTIQUE"]}
              width={"144px"}
              id={query["issuedByCompany.id__eq"]}
              onSelect={(value) => {
                return {
                  ...query,
                  "issuedByCompany.id__eq": value?.id,
                  pageNumber: 1,
                };
              }}
            />,
            <AppBalanceTransactionTypeSelector
              key={"AgencyBalanceTransactionListFilter_BalanceTransactionType"}
              value={query.type__eq}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    type__eq: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppBalanceTransactionAddUseSelector
              key={"AgencyBalanceTransactionListFilter_AddUse"}
              value={(() => {
                if (query.transactionAmount__gt === 0) {
                  return "Add";
                } else if (query.transactionAmount__lt === 0) {
                  return "Use";
                }
                return undefined;
              })()}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    transactionAmount__gt: value === "Add" ? 0 : undefined,
                    transactionAmount__lt: value === "Use" ? 0 : undefined,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"AgencyInvoiceListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
          ]}
          actions={[
            <AppButton
              key={"download"}
              onClick={handleDownloadDepositStateClick}
            >
              {tTitle("download")}
            </AppButton>,
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <AgencyBalanceTransactionGrid
            resources={listBalanceTransactionsData?.balanceTransactions}
            mutateResources={mutateListBalanceTransactions}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_INVOICES_PAGE_SIZE}
            totalCount={listBalanceTransactionsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(value) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber: value,
                };
              })
            }
          />
        </Flex>
        {isDownloadDepositStateModalOpen && (
          <DownloadDepositStateModal
            isOpen={isDownloadDepositStateModalOpen}
            onClose={onDownloadDepositStateModalClose}
            {...downloadDepositStateModalData}
          />
        )}
      </Flex>
    </>
  );
}
