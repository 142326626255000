import {
  OrderConfirmationInvoice,
  OrderConfirmationInvoiceDetail,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { IdentityParameter, StoredObject } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

export interface CreateOrderConfirmationInvoiceRequest
  extends Omit<
    OrderConfirmationInvoice,
    | "id"
    | "file"
    | "isRemitted"
    | "isRemittedToBoutique"
    | "isRemittedToAgency"
    | "isRead"
    | "status"
    | "actualRemainingDepositAmount"
    | "expectedRemainingDepositAmount"
    | "payments"
    | "proformaInvoices"
    | "creditInvoices"
    | "totalRemittedAmount"
    | "totalAgencyToBoutiqueAmount"
    | "totalBuyerToAgencyAmount"
    | "totalBuyerToBoutiqueAmount"
    | "depositInvoice"
    | "createdAt"
    | "orderConfirmationInvoiceDetailList"
  > {
  file: Pick<StoredObject, "id">;
  orderConfirmationInvoiceDetailList: Omit<
    OrderConfirmationInvoiceDetail,
    | "id"
    | "remittedAmount"
    | "actualRemainingDepositAmount"
    | "expectedRemainingDepositAmount"
  >[];
}

export interface CreateOrderConfirmationInvoiceResponse
  extends OrderConfirmationInvoice {}

export interface CreateOrderConfirmationInvoicePathParameter
  extends IdentityParameter {}

export const createOrderConfirmationInvoiceTemplateUrl = `/:by/:companyId/order-confirmation-invoices`;

export const createOrderConfirmationInvoiceKey: YWRKey<CreateOrderConfirmationInvoicePathParameter> =
  {
    url: (parameters: CreateOrderConfirmationInvoicePathParameter) => {
      return calcUrl(createOrderConfirmationInvoiceTemplateUrl, parameters);
    },
    method: "post",
  };

export default function useCreateOrderConfirmationInvoice() {
  const fetcher = useFetcher<
    CreateOrderConfirmationInvoiceRequest,
    CreateOrderConfirmationInvoiceResponse
  >();

  return useYWR(createOrderConfirmationInvoiceKey, fetcher);
}
