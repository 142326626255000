import {
  LightLineSheetSet,
  LineSheetSetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";

export interface LegacyCreateLineSheetSetRequest {
  name: string;
  season: string;
  type: LineSheetSetType;
  "issuedByBrand.id"?: number;
  "issuedByBoutique.id": number;
  validUntil: Date;
  lineSheetFiles: File[];
  orderFormFiles: File[];
  attachments: File[];
  orderRules: string[];
  externalFileLinks: string[];
}

export interface LegacyCreateLineSheetSetResponse extends LightLineSheetSet {}

export interface LegacyCreateLineSheetSetPathParameter {
  agencyId: number;
  _v?: number;
}

export const legacyCreateLineSheetSetKey: YWRKey<LegacyCreateLineSheetSetPathParameter> =
  {
    url: (parameter) => {
      const { agencyId, _v } = parameter;
      let url = "";
      url += `/agencies/${agencyId}/line-sheet-sets`;
      if (_v !== undefined) {
        url += `?_v=${_v}`;
      }
      return url;
    },
    method: "post",
  };

export default function useLegacyCreateLineSheetSet() {
  const fetcher = useFetcher<
    LegacyCreateLineSheetSetRequest,
    LegacyCreateLineSheetSetResponse
  >();

  return useYWR(legacyCreateLineSheetSetKey, fetcher, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
