import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { User } from "@/features/auth/use-get-my-self";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";

interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

interface ChangePasswordResponse extends User {}

export interface ChangePasswordPathParameter {
  userId: number;
}

export const changePasswordUrl = "/users/:userId/change_password";
export const changePasswordKey: YWRKey<ChangePasswordPathParameter> = {
  url: (parameter) => {
    return calcUrl(changePasswordUrl, parameter);
  },
  method: "put",
};

export default function useChangePassword() {
  const fetcher = useFetcher<ChangePasswordRequest, ChangePasswordResponse>();

  return useYWR(changePasswordKey, fetcher);
}
