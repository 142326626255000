import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheet, LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";

export interface DeleteOrderSheetRequest {}

export interface DeleteOrderSheetResponse {}

export interface DeleteOrderSheetsPathParameter {
  buyerId: number;
  orderSheetSetId: number;
  orderSheetId: number;
}

export const deleteOrderSheetKey: YWRKey<DeleteOrderSheetsPathParameter> =
  {
    url: (parameter) => {
      const { buyerId, orderSheetSetId, orderSheetId } = parameter;
      return `/buyers/${buyerId}/order-sheet-sets/${orderSheetSetId}/order-sheets/${orderSheetId}`;
    },
    method: "delete",
  };

export default function useDeleteOrderSheet() {
  const fetcher = useFetcher<
    DeleteOrderSheetRequest,
    DeleteOrderSheetResponse
  >();

  return useYWR(deleteOrderSheetKey, fetcher);
}
