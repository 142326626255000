import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useCallback } from "react";
import {
  createOrderConfirmationInvoiceKey,
  CreateOrderConfirmationInvoicePathParameter,
  CreateOrderConfirmationInvoiceRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-create-order-confirmation-invoice";
import { isFile, StoredObject } from "@/features/types";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import useUpdateOrderConfirmationInvoice, {
  updateOrderConfirmationInvoiceKey,
  UpdateOrderConfirmationInvoicePathParameter,
  UpdateOrderConfirmationInvoiceRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-update-order-confirmation-invoice";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import useCreateOrderConfirmationInvoiceInfinite from "@/features/invoices/order-confirmation-invoices/hooks/use-create-order-confirmation-invoice-infinite";

function mutateOrderConfirmationInvoices(orderConfirmationInvoiceId?: number) {
  if (orderConfirmationInvoiceId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers|boutiques)\/\d+\/dense-order-confirmation-invoices\/${orderConfirmationInvoiceId}/`
      )
    );
  }
  mutateIfMatched(
    /\/(agencies|buyers|boutiques)\/\d+\/dense-order-confirmation-invoices/
  );
}

export function useOrderConfirmationInvoiceDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();

  const { fire: fireUpdateOrderConfirmationInvoice } =
    useUpdateOrderConfirmationInvoice();

  const { fire: fireCreateOrderConfirmationInvoice } =
    useCreateOrderConfirmationInvoiceInfinite();

  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const addCreate = useCallback(
    (
      parameter: CreateOrderConfirmationInvoicePathParameter,
      request: Omit<CreateOrderConfirmationInvoiceRequest, "file"> & {
        file: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      addDumbTransactions({
        parameter: parameter,
        source: request,
        getFilesFunc: (request) => {
          if (isFile(request.file)) {
            return [request.file];
          }
          return [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: storeObjectResponses[0],
          };
        },
        fire: fireCreateOrderConfirmationInvoice,
        keyOrFunc: calcYWRUrl(createOrderConfirmationInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateOrderConfirmationInvoices();
        },
        onErrorFunc: (error) => {
          showError(error);
        },
      });
    },
    [addDumbTransactions, fireCreateOrderConfirmationInvoice, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdateOrderConfirmationInvoicePathParameter,
      request: Omit<UpdateOrderConfirmationInvoiceRequest, "file"> & {
        file: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      return addDumbTransactions({
        parameter: parameter,
        source: request,
        getFilesFunc: (request) => {
          if (isFile(request.file)) {
            return [request.file];
          }
          return [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: storeObjectResponses[0],
          };
        },
        fire: fireUpdateOrderConfirmationInvoice,
        keyOrFunc: calcYWRUrl(updateOrderConfirmationInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateOrderConfirmationInvoices(parameter.orderConfirmationInvoiceId);
        },
        onErrorFunc: (error) => {
          showError(error);
        },
      });
    },
    [addDumbTransactions, fireUpdateOrderConfirmationInvoice, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateOrderConfirmationInvoicePathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateOrderConfirmationInvoiceKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate,
    addUpdate,
    isUpdateQueued,
  };
}
