import { ChakraProps, Tooltip, TooltipProps } from "@chakra-ui/react";
import { CSSProperties, useMemo } from "react";

type Level = "SUCCESS" | "ERROR" | "PRIMARY" | "LIGHT";

export interface AppToolTipProps extends TooltipProps {
  level?: Level;
}

export default function AppToolTip({
  level,
  children,
  ...rest
}: AppToolTipProps) {
  const style = useMemo(() => {
    const style: ChakraProps = {
      borderRadius: "4px",
      borderWidth: "0.5px",
      borderStyle: "solid",
      fontSize: "12px",
      padding: "0 8px",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.20)",
      // fontWeight: "700",
    };

    switch (level) {
      case "SUCCESS":
        style.borderColor = "#16B41C";
        style.backgroundColor = "#F0FAEF";
        style.color = "#444440";

        break;
      case "ERROR":
        style.borderColor = "#EE7D7A";
        style.backgroundColor = "#FFEEEE";
        style.color = "#444440";
        break;
      case "PRIMARY":
        style.borderColor = "#1272EF";
        style.backgroundColor = "#F7F9FA";
        style.color = "#444440";
        break;
      case "LIGHT":
        style.borderColor = "#8F8F8C";
        style.color = "#444440";
        break;
    }
    return style;
  }, [level]);
  
  return (
    <Tooltip
      {...rest}
      label={rest.label}
      arrowShadowColor={rest.arrowShadowColor || (style.borderColor as string)}
      {...style}
    >
      {children}
    </Tooltip>
  );
}
