import { atom } from "recoil";

import { BuyerLineSheetSetRow } from "@/features/order-sheet-sets/line-sheet-set-row.type";

export const buyerLineSheetSetRowsState = atom<
  BuyerLineSheetSetRow[] | undefined
>({
  key: "BuyerLineSheetSetRow",
  default: undefined,
});

export const buyerLineSheetSetRowsMutateId = atom<number>({
  key: "BuyerLineSheetSetRowMutateId",
  default: 0,
});
