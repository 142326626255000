import useIdentity from "@/features/ui/hooks/use-identity";
import { Box, Flex } from "@chakra-ui/react";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import { useEffect, useMemo } from "react";
import useListLineSheetSets, {
  ListLineSheetSetsPathParameter,
  ListLineSheetSetsRequest,
} from "@/features/line-sheet-sets/hooks/use-list-line-sheet-sets";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppListHeader from "@/features/ui/app-list-header";
import AppQueryController from "@/features/ui/app-query-controller";
import { toTitle } from "@/utils/case";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { OrderByItem } from "@/features/types";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import BoutiqueLineSheetSetGrid from "@/features/order-sheet-sets/boutique-line-sheet-set-grid";
import { PersistedLineSheetSetListQuery } from "@/features/order-sheet-sets/agency-line-sheet-set-list-page";

export default function BoutiqueLineSheetSetListPage() {
  const identity = useIdentity();
  const {
    error: showError,
    warning: showWarning,
    info: showInfo,
  } = useAppToasts({ id: "GLOBAL" });

  const [query, setQuery] =
    useTypedSearchParams<PersistedLineSheetSetListQuery>();
  const { t } = useI18nHelper();
  const { navigate } = useRouterHelper();

  const listLineSheetSetsKey = useMemo(():
    | {
        parameter: ListLineSheetSetsPathParameter;
        request: ListLineSheetSetsRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
          orderBy: [new OrderByItem("createdAt", "descend")],
        },
      };
    }
  }, [identity, query]);

  const {
    isLoading: isListLineSheetSetsLoading,
    data: listLineSheetSetsData,
    mutate: mutateListLineSheetSetsData,
    isValidating: isListLineSheetSetsValidating,
    error: listLineSheetSetsError,
  } = useListLineSheetSets(
    listLineSheetSetsKey?.parameter,
    listLineSheetSetsKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (listLineSheetSetsError) {
      showError(listLineSheetSetsError);
    }
  }, [showError, listLineSheetSetsError]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppLineSheetTypeSelector
              key={"AgencyLineSheetSetListFilter_Type"}
              value={query.type__eq}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    type__eq: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppSeasonMultiSelect
              key={"season"}
              value={query.season__in}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    season__in: value,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppCompanySelect
              key={"AgencyLineSheetSetListFilter_Brand"}
              name={toTitle(t("common:brand"))}
              types={["BRAND"]}
              id={query["madeByCompany.id__eq"]}
              onSelect={(company) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    "madeByCompany.id__eq": company?.id,
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"AgencyLineSheetSetListFilter_ResetFilter"}
              variant={"normal"}
              size={"medium"}
              onClick={() => {
                setQuery({
                  pageNumber: 1,
                });
              }}
            >
              {toTitle(t("reset_filter"))}
            </AppButton>,
            <AppQueryController
              key={"AgencyLineSheetSetListFilter_Query"}
              value={query.query}
              onChange={(value) =>
                setQuery((prev) => {
                  return {
                    ...prev,
                    query: value,
                    pageNumber: 1,
                  };
                })
              }
            />,
          ]}
          actions={[
            (() => {
              if (query.isDeleted__eq !== true) {
                return (
                  <AppButton
                    key={"AgencyLineSheetSetListAction__UploadLineSheetSets"}
                    variant={"primary"}
                    onClick={() => {
                      navigate("/line-sheet-sets/upload", {
                        appendReferer: true,
                      });
                    }}
                  >
                    {toTitle(
                      t("upload_line_sheet_sets", { ns: "line_sheet_sets" })
                    )}
                  </AppButton>
                );
              }
              return undefined;
            })(),
          ]}
        />
        <Box
          flexGrow={1}
          overflow={"auto"}
          style={{
            scrollbarGutter: "stable",
          }}
        >
          <BoutiqueLineSheetSetGrid
            resources={listLineSheetSetsData?.lineSheetSets}
            mutateResources={mutateListLineSheetSetsData}
          />
        </Box>
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
            totalCount={listLineSheetSetsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(pageNumber) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber,
                };
              })
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
