import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useEffect, useMemo, useState } from "react";
import useListDenseOrderConfirmationInvoices, {
  ListDenseOrderConfirmationInvoicesPathParameter,
  ListDenseOrderConfirmationInvoicesRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-list-dense-order-confirmation-invoices";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { orderConfirmationRowMapper } from "@/features/invoices/utils/order-confirmation-invoice-row-mapper";
import { OrderConfirmationInvoiceRow } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-row.type";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import AppGrid, {
  AppGridColumn,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import IsolatedPanelSkeleton from "@/features/invoices/isolated-panel-skeleton";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface IsolatedOrderConfirmationInvoicePanelProps {
  orderConfirmationInvoiceIds?: number[];
}

const rowMapper = orderConfirmationRowMapper.copy();

export default function IsolatedOrderConfirmationInvoicePanel({
  orderConfirmationInvoiceIds,
}: IsolatedOrderConfirmationInvoicePanelProps) {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const [rows, setRows] = useState<OrderConfirmationInvoiceRow[] | undefined>();

  const listOrderConfirmationInvoicesKey = useMemo(():
    | {
        parameter: ListDenseOrderConfirmationInvoicesPathParameter;
        request: ListDenseOrderConfirmationInvoicesRequest;
      }
    | undefined => {
    if (
      identity?.company?.type &&
      orderConfirmationInvoiceIds &&
      orderConfirmationInvoiceIds.length > 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: orderConfirmationInvoiceIds,
          pageNumber: 1,
          pageSize: orderConfirmationInvoiceIds.length,
        },
      };
    }

    return undefined;
  }, [identity, orderConfirmationInvoiceIds]);

  const { error: showError } = useAppToasts();

  const {
    data: listOrderConfirmationInvoicesData,
    error: listOrderConfirmationInvoicesError,
    mutate: mutateListOrderConfirmationInvoices,
  } = useListDenseOrderConfirmationInvoices(
    listOrderConfirmationInvoicesKey?.parameter,
    listOrderConfirmationInvoicesKey?.request
  );

  useEffect(() => {
    if (listOrderConfirmationInvoicesError) {
      showError(listOrderConfirmationInvoicesError);
    }
  }, [listOrderConfirmationInvoicesError, showError]);

  useEffect(() => {
    if (listOrderConfirmationInvoicesData) {
      setRows((prev) => {
        return rowMapper.toRows(
          listOrderConfirmationInvoicesData.orderConfirmationInvoices.map(
            (ocInvoice) => {
              return {
                ...ocInvoice,
                isRead: false,
              };
            }
          ),
          prev
        );
      });
    }
  }, [listOrderConfirmationInvoicesData]);

  const appGrid = useAppGrid();
  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: mutateListOrderConfirmationInvoices,
  });

  const metas = useMemo((): AppGridMeta[] => {
    let pairs: { column: AppGridColumn; field: AppGridField }[] = [];

    if (identity?.company?.type === "BUYER") {
      pairs = [
        appGrid.pairs.season,
        invoiceGrid.pairs.issuedBy,
        invoiceGrid.pairs.issuedThrough,
        // invoiceGrid.pairs.orderedBy,
        invoiceGrid.pairs.title,
        invoiceGrid.pairs.deposit,
        invoiceGrid.pairs.remittanceStatus,
        invoiceGrid.pairs.paidBy,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "AGENCY") {
      pairs = [
        appGrid.pairs.season,
        invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        invoiceGrid.pairs.title,
        invoiceGrid.pairs.deposit,
        invoiceGrid.pairs.remittanceStatus,
        invoiceGrid.pairs.paidBy,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "BOUTIQUE") {
      pairs = [
        appGrid.pairs.season,
        // invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        invoiceGrid.pairs.title,
        invoiceGrid.pairs.deposit,
        invoiceGrid.pairs.remittanceStatus,
        invoiceGrid.pairs.paidBy,
        invoiceGrid.pairs.issuedOn,
      ];
    }

    return [
      {
        rowType: "OrderConfirmationInvoice",
        gap: "8px",
        columns: pairs.map((pair) => pair.column),
        fields: pairs.map((pair) => pair.field),
        indicator: (row) => {
          return <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />;
        },
        onClick: (row) => {
          navigate(`/order-confirmation-invoices/${row.id}`);
        },
      },
    ];
  }, [
    appGrid.pairs.season,
    identity?.company?.type,
    invoiceGrid.actions,
    invoiceGrid.pairs.deposit,
    invoiceGrid.pairs.issuedBy,
    invoiceGrid.pairs.issuedOn,
    invoiceGrid.pairs.issuedThrough,
    invoiceGrid.pairs.orderedBy,
    invoiceGrid.pairs.paidBy,
    invoiceGrid.pairs.remittanceStatus,
    invoiceGrid.pairs.title,
  ]);

  if (listOrderConfirmationInvoicesKey === undefined) {
    return null;
  } else if (rows == undefined) {
    return <IsolatedPanelSkeleton />;
  }

  return (
    <AppPanel
      title={tTitle("common:related_order_confirmation_invoices")}
      variant={"ghost"}
    >
      <AppGrid isAnchored={false} rows={rows} metas={metas} />
    </AppPanel>
  );
}
