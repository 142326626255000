import { Text, Link } from "@chakra-ui/react";

interface AppHtmlToLinkProps {
  htmlString: string;
}

export default function AppHtmlToLink({ htmlString }: AppHtmlToLinkProps) {
  const linkRegex = /<a href="([^"]+)">([^<]+)<\/a>/g;

  const parts = [];
  let lastIndex = 0;

  let match;
  let index = 0;

  function calcKey() {
    return `AppHtmlToLink_${index++}`;
  }

  while ((match = linkRegex.exec(htmlString)) !== null) {
    // Push the text before the link
    parts.push(
      <Text key={calcKey()}>{htmlString.slice(lastIndex, match.index)}</Text>
    );

    // Push the link as a Chakra Link component
    parts.push(
      <Link key={calcKey()} href={match[1]} isExternal={true}>
        {match[2]}
      </Link>
    );

    lastIndex = linkRegex.lastIndex;
  }

  // Push any remaining text after the last link
  parts.push(<Text key={calcKey()}>{htmlString.slice(lastIndex)}</Text>);

  return <>{parts}</>;
}
