import AppDashboardCard from "@/features/dashboard/app-dashboard-card";
import { Flex, Grid, GridItem, Spinner } from "@chakra-ui/react";
import useDashboardPagePersistedQuery from "@/features/dashboard/hooks/use-dashboard-page-persisted-query";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import React, { useEffect, useMemo } from "react";
import {
  ListBalanceTransactionStatisticsByBoutiqueRequest,
  useListBalanceTransactionStatisticsByBoutique,
} from "@/features/dashboard/hooks/use-list-balance-transaction-statistics-by-boutique";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import {
  CURRENT_SEASON,
  getSeason,
} from "@/features/line-sheet-sets/app-season-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppLink from "@/features/dashboard/app-link";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import { PersistedBalanceTransactionListPageQuery } from "@/features/balance-transaction-status/agency-balance-transaction-list-page";

export default function BalanceTransactionCard() {
  const { value: queryValue, set: setQuery } = useDashboardPagePersistedQuery();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle, tCurrencyString } = useI18nHelper();
  const listBalanceTransactionStatisticsKey = useMemo(() => {
    if (identity?.company?.type === "BUYER") {
      const request: ListBalanceTransactionStatisticsByBoutiqueRequest = {
        season__in: queryValue.balanceTransactionStatisticSeason__in,
      };

      if (queryValue["balanceTransactionStatisticMadeByCompany.id__eq"]) {
        request["madeByCompany.id__eq"] =
          queryValue["balanceTransactionStatisticMadeByCompany.id__eq"];
      }

      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: request,
      };
    }
  }, [identity, queryValue]);

  const {
    isLoading: isListBalanceTransactionStatisticsByBoutiqueLoading,
    data: listBalanceTransactionStatisticsByBoutiqueData,
    error: listBalanceTransactionStatisticsByBoutiqueError,
  } = useListBalanceTransactionStatisticsByBoutique(
    listBalanceTransactionStatisticsKey?.parameter,
    listBalanceTransactionStatisticsKey?.request,
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (listBalanceTransactionStatisticsByBoutiqueError) {
      showError(listBalanceTransactionStatisticsByBoutiqueError);
    }
  }, [showError, listBalanceTransactionStatisticsByBoutiqueError]);

  const skeleton = useMemo(() => {
    return (
      <Flex height={"120px"} alignItems={"center"} justifyContent={"center"}>
        <Spinner />
      </Flex>
    );
  }, []);

  const items = useMemo(() => {
    if (listBalanceTransactionStatisticsByBoutiqueData) {
      return (
        <Grid
          gridTemplateColumns={"repeat(auto-fill, minmax(240px, 1fr))"}
          gap={"16px"}
          padding={"16px 24px"}
        >
          {listBalanceTransactionStatisticsByBoutiqueData.balanceTransactionStatisticsByBoutique.map(
            (item) => {
              return (
                <Grid
                  key={"BalanceTransactionByBoutique_" + item.boutique.id}
                  borderRadius={"4px"}
                  border={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
                  boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.20)"}
                  gridTemplateColumns={"repeat(2, 1fr)"}
                  gridTemplateRows={"32px repeat(6, 32px)"}
                  alignItems={"center"}
                  _hover={{
                    borderColor: "#1272EF",
                    "> div:first-of-type": {
                      borderColor: "#1272EF",
                    },
                  }}
                >
                  <GridItem
                    gridColumn={"1/-1"}
                    borderBottom={"0.5px solid var(--pathrade-gray, #D4D4D1)"}
                    fontSize={"14px"}
                    padding={"8px"}
                    fontWeight={"700"}
                  >
                    <AppLink<PersistedBalanceTransactionListPageQuery>
                      title={item.boutique.name}
                      pathname={"/deposit-status"}
                      query={{
                        pageNumber: 1,
                        "issuedByCompany.id__eq": item.boutique.id,
                        season__in:
                          queryValue.balanceTransactionStatisticSeason__in,
                      }}
                    />
                  </GridItem>
                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("remitted_deposit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.remittedDeposit)}
                  </GridItem>

                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("used_deposit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.usedDeposit)}
                  </GridItem>

                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("remaining_deposit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.remainingDeposit)}
                  </GridItem>

                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("issued_credit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.issuedCredit)}
                  </GridItem>

                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("used_credit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.usedCredit)}
                  </GridItem>

                  <GridItem paddingLeft={"8px"} color={"#6F6F6C"}>
                    {tTitle("remaining_credit")}
                  </GridItem>
                  <GridItem justifySelf={"end"} paddingRight={"8px"}>
                    {tCurrencyString(item.remainingCredit)}
                  </GridItem>
                </Grid>
              );
            }
          )}
        </Grid>
      );
    }
  }, [
    listBalanceTransactionStatisticsByBoutiqueData,
    queryValue.balanceTransactionStatisticSeason__in,
    tCurrencyString,
    tTitle,
  ]);

  return (
    <AppDashboardCard
      title={tTitle("balance_transaction")}
      filters={[
        <AppCompanySelect
          key={"DepositStatusFilter_MadeBy"}
          name={tTitle("brand")}
          types={["BRAND"]}
          id={queryValue?.["balanceTransactionStatisticMadeByCompany.id__eq"]}
          onSelect={(company) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  "balanceTransactionStatisticMadeByCompany.id__eq":
                    company?.id,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppSeasonMultiSelect
          key={"DepositStatusFilter_Season"}
          value={queryValue.balanceTransactionStatisticSeason__in}
          onSelect={(value) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  balanceTransactionStatisticSeason__in: value,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,

        <AppButton
          key={"DepositStatusFilter_Reset"}
          variant={"normal"}
          size={"medium"}
          width={"144px"}
          onClick={() => {
            setQuery((prev) => {
              return {
                ...prev,
                "balanceTransactionStatisticMadeByCompany.id__eq": undefined,
                balanceTransactionStatisticSeason__in: [CURRENT_SEASON],
              };
            });
          }}
        >
          {tTitle("reset_filter")}
        </AppButton>,
      ]}
      totalStatistics={[
        {
          name: tTitle("total_remitted_deposit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.remittedDeposit || 0
          ),
        },
        {
          name: tTitle("total_used_deposit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.usedDeposit || 0
          ),
        },
        {
          name: tTitle("total_remaining_deposit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.remainingDeposit || 0
          ),
        },

        {
          name: tTitle("total_issued_credit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.issuedCredit || 0
          ),
        },
        {
          name: tTitle("total_used_credit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.usedCredit || 0
          ),
        },

        {
          name: tTitle("total_remaining_credit"),
          value: tCurrencyString(
            listBalanceTransactionStatisticsByBoutiqueData
              ?.totalBalanceTransactionStatistic.remainingCredit || 0
          ),
        },
      ]}
    >
      {items ? items : skeleton}
    </AppDashboardCard>
  );
}
