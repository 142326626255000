import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { LightOrderSheetSet } from "@/features/order-sheets/order-sheet.type";

export interface SubmitOrderSheetSetRequest {
  orderSheetIds: number[];
}

export interface SubmitOrderSheetSetResponse {
  orderSheetSet: LightOrderSheetSet;
}

export interface SubmitOrderSheetSetsPathParameter {
  buyerId: number;
  orderSheetSetId: number;
}

export const submitOrderSheetSetKey: YWRKey<SubmitOrderSheetSetsPathParameter> =
  {
    url: (parameter) => {
      const { buyerId, orderSheetSetId } = parameter;
      return `/buyers/${buyerId}/order-sheet-sets/${orderSheetSetId}/submit`;
    },
    method: "post",
  };

export default function useSubmitOrderSheetSet() {
  const fetcher = useFetcher<
    SubmitOrderSheetSetRequest,
    SubmitOrderSheetSetResponse
  >();

  return useYWR(submitOrderSheetSetKey, fetcher);
}
