import AppGrid from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import { Box } from "@chakra-ui/react";
import { toLower, toTitle } from "@/utils/case";
import { useTranslation } from "react-i18next";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useEffect, useMemo, useState } from "react";
import {
  BalanceTransaction,
  BalanceTransactionRow,
} from "@/features/balance-transaction-status/balance-transaction.type";
import { balanceTransactionRowMapper } from "@/features/invoices/utils/balance-transaction-row-mapper";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

interface BuyerBalanceTransactionGridProps {
  resources?: BalanceTransaction[];
  mutateResources: () => void;
}

export default function BuyerBalanceTransactionGrid({
  resources,
  mutateResources,
}: BuyerBalanceTransactionGridProps) {
  const [rows, setRows] = useState<BalanceTransactionRow[]>();
  const rowMapper = useMemo(() => {
    return balanceTransactionRowMapper.copy();
  }, []);

  useEffect(() => {
    if (resources) {
      setRows((prev) => {
        return rowMapper.toRows(resources, prev);
      });
    }
  }, [resources, rowMapper]);

  const { t } = useTranslation();
  const { tLocalDateString, tCurrencyString } = useI18nHelper();

  return (
    <Box width={"100%"} height={"100%"}>
      <AppGrid
        rows={rows}
        metas={[
          {
            gap: "8px",
            rowType: "BalanceTransaction",
            columns: [
              { name: toTitle(t("season")), width: "64px" },
              { name: toTitle(t("issued_through")), width: "104px" },
              { name: toTitle(t("issued_by")), width: "104px" },
              { name: toTitle(t("type")), width: "104px" },
              { name: toTitle(t("invoice_number")), width: "104px" },
              { name: toTitle(t("add_or_use")), width: "104px" },
              {
                name: toTitle(t("transaction_amount")),
                width: "208px",
                textAlign: "end",
              },
              {
                name: toTitle(t("remaining_amount")),
                width: "208px",
                textAlign: "end",
              },
              { name: toTitle(t("transacted_on")), width: "104px" },
              { name: toTitle(t("transacted_by")), width: "minmax(144px,1fr)" },
            ],
            fields: [
              {
                name: "season",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return row.season;
                },
              },
              {
                name: "issued_through",
                value: (_row) => {
                  const row = _row as GridRow & {
                    issuedThroughCompany: { name: string };
                  };
                  return row.issuedThroughCompany.name;
                },
              },
              {
                name: "issuedBy",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return row.issuedByCompany.name;
                },
              },

              {
                name: "type",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return row.type;
                },
              },
              {
                name: "invoice_number",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return row.invoiceNumber;
                },
              },
              {
                name: "addOrUse",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  const addOrUser = row.transactionAmount > 0 ? "add" : "use";
                  return toTitle(t(`add_or_use.${addOrUser}`));
                },
              },
              {
                name: "transactionAmount",
                chakraProps: {
                  textAlign: "end",
                },
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return tCurrencyString(row.transactionAmount);
                },
              },
              {
                name: "remainingAmount",
                chakraProps: {
                  textAlign: "end",
                },
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return tCurrencyString(row.remainingAmount);
                },
              },
              {
                name: "transactedOn",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return tLocalDateString(row.issuedOn);
                },
              },
              {
                name: "transactedBy",
                value: (_row) => {
                  const row = _row as BalanceTransactionRow;
                  return t(
                    `balance_transaction.transacted_by.${toLower(
                      row.transactedBy
                    )}`
                  );
                },
              },
            ],
            indicator: (_row) => {
              return (
                <GridRowIndicator type={"EMPTY"} isHovered={_row.isHovered} />
              );
            },
            onClick: (_row) => {},
          },
        ]}
      />
    </Box>
  );
}
