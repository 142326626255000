import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReportLineSheetSetRequest {}
export interface ReportLineSheetSetResponse {}
export interface ReportLineSheetSetParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const reportLineSheetSetTemplateUrl =
  "/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/force-failure";

export const reportLineSheetSetKey: YWRKey<ReportLineSheetSetParameter> = {
  url: (parameter) => calcUrl(reportLineSheetSetTemplateUrl, parameter),
  method: "post",
};

export function parseReportLineSheetSetKey(url: string) {
  const urlParser = new UrlParser<ReportLineSheetSetParameter>(
    reportLineSheetSetTemplateUrl,
    {
      agencyId: StringTransformer.parseId,
      lineSheetSetId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReportLineSheetSet() {
  const fetcher = useFetcher<
    ReportLineSheetSetRequest,
    ReportLineSheetSetResponse
  >();

  return useYWR(reportLineSheetSetKey, fetcher);
}
