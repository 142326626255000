import React from "react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { clearTokens } from "@/utils/jwt";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export default function AppSignOutButton() {
  const { navigate } = useRouterHelper();
  const { t } = useTranslation();

  return (
    <AppButton
      variant={"ghost"}
      onClick={(event) => {
        event.preventDefault();
        clearTokens();

        sessionStorage.setItem("signOut", "true");

        navigate("/auth/sign-in", {
          replace: true,
        });
      }}
    >
      {toTitle(t("sign_out"))}
    </AppButton>
  );
}
