import useUpdateOrderSheet, {
  UpdateOrderSheetResponse,
} from "@/features/order-status/hooks/use-update-order-sheet";
import { Company } from "@/features/types";
import ToBeUpdatedOrderSheetList, {
  ToBeUpdatedOrderSheet,
} from "@/features/order-status/modals/to-be-updated-order-sheet-list";
import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AppButton from "@/features/line-sheet-sets/app-button";
import useAppToast from "@/features/line-sheet-sets/hooks/use-app-toasts";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import useUpdateOrderSheetToBeSubmitted from "@/features/order-status/hooks/use-update-order-sheet-to-be-submitted";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export interface UpdateOrderSheetToBeSubmittedModalProps {
  agencyId: number;
  orderSheetSetId: number;
  orderSheetId: number;
  value: Company | null;
  toBeUpdateOrderSheet: ToBeUpdatedOrderSheet;
  excludeCompanyIds: number[];
  onUpdate: (response: UpdateOrderSheetResponse) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function UpdateOrderSheetToBeSubmittedModal({
  agencyId,
  orderSheetSetId,
  orderSheetId,
  value: _value,
  toBeUpdateOrderSheet,
  excludeCompanyIds,
  onUpdate,
  isOpen,
  onClose,
}: UpdateOrderSheetToBeSubmittedModalProps) {
  const [value, setValue] = useState<Company | null>(_value);
  const { t } = useTranslation();
  const { error: showError } = useAppToast();

  const {
    isLoading: isUpdateOrderSheetToBeSubmittedLoading,
    error: updateOrderSheetToBeSubmittedError,
    data: updateOrderSheetToBeSubmittedData,
    clear: clearUpdateOrderSheetToBeSubmitted,
    fire: fireUpdateOrderSheetToBeSubmitted,
  } = useUpdateOrderSheetToBeSubmitted();

  useEffect(() => {
    showError(updateOrderSheetToBeSubmittedError);
  }, [showError, updateOrderSheetToBeSubmittedError]);

  useEffect(() => {
    if (updateOrderSheetToBeSubmittedData) {
      onUpdate(updateOrderSheetToBeSubmittedData);
      clearUpdateOrderSheetToBeSubmitted();
      onClose();
    }
  }, [
    clearUpdateOrderSheetToBeSubmitted,
    onUpdate,
    onClose,
    updateOrderSheetToBeSubmittedData,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {toTitle(t("common:edit_to_be_submitted"))}
        </ModalHeader>
        <ModalBody>
          <ToBeUpdatedOrderSheetList
            header={
              <>
                {t("edit_order_sheet_to_be_submitted_modal.message", {
                  ns: "order_sheet_sets",
                })}
              </>
            }
            orderSheets={[toBeUpdateOrderSheet]}
          />
          <Grid
            marginTop={"16px"}
            gridTemplateColumns={"0.2fr 1fr"}
            gridAutoRows={"24px"}
            gap={"16px"}
            fontSize={"12px"}
          >
            {/*<Box gridColumn={"1/3"}>Adjust the below items, please.</Box>*/}
            <Box>{toTitle(t("submit_to"))}</Box>
            <Box gridColumn={"2/3"}>
              <AppCompanySelect
                width={"100%"}
                name={toTitle(t("submit_to"))}
                types={["BOUTIQUE"]}
                id={value ? value.id : -1}
                isClearable={false}
                onSelect={(selectedCompany) => {
                  if (selectedCompany) {
                    setValue(selectedCompany);
                  }
                }}
                isNullable={true}
                excludeIds={excludeCompanyIds}
              />
            </Box>
          </Grid>
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            width={"144px"}
            onClick={() => {
              if (value !== _value) {
                setValue(_value);
              }
              onClose();
            }}
          >
            {toTitle(t("not_now"))}
          </AppButton>
          <AppButton
            width={"144px"}
            variant={"primary"}
            isLoading={isUpdateOrderSheetToBeSubmittedLoading}
            onClick={() => {
              fireUpdateOrderSheetToBeSubmitted(
                {
                  agencyId: agencyId,
                  orderSheetSetId,
                  orderSheetId,
                },
                {
                  toBeSubmitted: value
                    ? {
                        id: value.id,
                      }
                    : null,
                }
              );
            }}
          >
            {toTitle(t("edit.do"))}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
