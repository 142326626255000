import {
  CompanyType,
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { readProactively } from "@/features/ui/helpers/swr-helper";

export interface ListBankRemittanceReceiptsRequest
  extends PageNumberBasedListRequest {
  season__eq?: string;
  season__in?: string[];
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  "issuedThroughCompany.id__eq"?: number;
  // issuedByCompany__in?: CompanyId[];
  "issuedByCompany.id__in"?: number[];
  issuedOn__eq?: Date;
  id__in?: number[];

  //애내만 씁니다
  "payments.orderConfirmationInvoices__exists"?: boolean;
  depositInvoice__isNull?: boolean;
  "receiver.type__eq"?: CompanyType;
  "sender.id__eq"?: number;
  "receiver.id__eq"?: number;
}

export interface ListBankRemittanceReceiptsResponse
  extends PageNumberBasedListResponse {
  bankRemittanceReceipts: BankRemittanceReceipt[];
}

export interface ListBankRemittanceReceiptsPathParameter
  extends IdentityParameter {}

export const listBankRemittanceReceiptsTemplateUrl = `/:by/:companyId/bank-remittance-receipts`;

export function getListBankRemittanceReceiptsKey(
  parameter?: ListBankRemittanceReceiptsPathParameter,
  request?: ListBankRemittanceReceiptsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listBankRemittanceReceiptsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export const DEFAULT_LIST_BANK_REMITTANCE_RECEIPTS_PAGE_SIZE = 10;

export function readBankRemittanceReceiptProactively(
  bankRemittanceReceiptId: number
) {
  readProactively(
    /\/(agencies|buyers)\/\d+\/bank-remittance-receipts/,
    "bankRemittanceReceipts",
    bankRemittanceReceiptId
  );
}

export default function useListBankRemittanceReceipts(
  parameter?: ListBankRemittanceReceiptsPathParameter,
  request?: ListBankRemittanceReceiptsRequest,
  config?: SWRConfiguration<ListBankRemittanceReceiptsResponse>
) {
  const fetcher = useFetcher<
    ListBankRemittanceReceiptsRequest,
    ListBankRemittanceReceiptsResponse
  >();

  return useSWR(
    getListBankRemittanceReceiptsKey(parameter, request),
    fetcher,
    config
  );
}
