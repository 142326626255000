import useAgencyInventoryRows from "@/features/inventories/hooks/use-buyer-inventory-rows";
import useInventoryGrid from "@/features/inventories/hooks/use-inventory-grid";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import UpdateInventoryModal from "@/features/inventories/update-inventory-modal";

interface AgencyInventoryGridProps {
  mutateRows: () => void;
}
export default function AgencyInventoryGrid({
  mutateRows,
}: AgencyInventoryGridProps) {
  const { rows } = useAgencyInventoryRows();
  const inventoryGrid = useInventoryGrid({ rows, mutateRows });
  const {
    isOpen: isUpdateInventoryModalOpen,
    onClose: onCloseUpdateInventoryModal,
    data: updateInventoryModalData,
  } = inventoryGrid.disclosureUpdateInventoryModal;

  return (
    <>
      <AppGrid rows={rows} metas={[inventoryGrid.metas.withInventory(false)]} />
      {updateInventoryModalData && (
        <UpdateInventoryModal
          isOpen={isUpdateInventoryModalOpen}
          onClose={onCloseUpdateInventoryModal}
          {...updateInventoryModalData}
        />
      )}
    </>
  );
}
