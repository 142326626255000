import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppInput from "@/features/ui/app-input";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { ReactNode } from "react";
import { produce } from "immer";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface AppListFormControlProps<T> {
  value: T[];
  onChange: (value: T[]) => void;
  inflate?: (value?: string) => T;
  deflate?: (value: T) => string;
  addKey?: string;
  viewFunc?: (props: ViewInflaterProps<T>) => ReactNode;
  isLiteral?: boolean;
  isDeletable?: (value: T) => boolean;
  isDisabled?: boolean;
}

export interface ViewInflaterProps<T> {
  value: T;
  isLiteral?: boolean;
  isDisabled?: boolean;
  onChange: (value?: T) => void;
  valueDeflater?: (value: T) => string;
  valueInflater?: (value?: string) => T;
}

function StringViewInflater<T>({
  value,
  isLiteral,
  isDisabled,
  onChange,
  valueInflater = (value) => value as T,
  valueDeflater = (value) => value as string,
}: ViewInflaterProps<T>) {
  if (!isLiteral) {
    return (
      <AppInput
        value={valueDeflater(value)}
        isClearable={!isDisabled}
        isDisabled={isDisabled}
        onChange={(value) => {
          onChange(valueInflater(value));
        }}
      />
    );
  } else {
    return (
      <Box overflow={"auto"} whiteSpace={"nowrap"}>
        {valueDeflater(value)}
      </Box>
    );
  }
}

export default function AppListFormControl<T>({
  value,
  onChange,
  deflate = (value) => value as string,
  inflate = (value) => value as T,
  addKey = "add.do",
  viewFunc = StringViewInflater,
  isLiteral = false,
  isDeletable = () => true,
  isDisabled = false,
}: AppListFormControlProps<T>) {
  const { tTitle } = useI18nHelper();

  return (
    <Flex flexDirection={"column"} gap={"8px"}>
      {value.length > 0 &&
        value.map((item, index) => {
          const props: ViewInflaterProps<T> = {
            value: item,
            isLiteral,
            isDisabled,
            onChange: (newValue) => {
              onChange(
                produce(value, (draft) => {
                  // @ts-ignore
                  draft[index] = newValue;
                })
              );
            },
          };

          return (
            <Grid
              gridTemplateColumns={!isLiteral ? "1fr 16px" : "1fr"}
              gap={"8px"}
              key={index}
              alignItems={"center"}
            >
              {viewFunc(props)}
              {!isLiteral && (
                <Flex>
                  {isDeletable(item) && (
                    <AppIconButton
                      ariaLabel={"delete"}
                      icon={"x"}
                      isDisabled={isDisabled}
                      size={"small"}
                      onClick={() => {
                        onChange(
                          produce(value, (draft) => {
                            draft.splice(index, 1);
                          })
                        );
                      }}
                    />
                  )}
                </Flex>
              )}
            </Grid>
          );
        })}

      {!isLiteral && (
        <AppButton
          onClick={() => {
            onChange([...value, inflate()]);
          }}
          isDisabled={isDisabled}
        >
          <SVG
            src={"/icons/icon_add.svg"}
            width={"14px"}
            height={"14px"}
            style={{ marginRight: "8px" }}
          />
          {tTitle(addKey)}
        </AppButton>
      )}
    </Flex>
  );
}
