import {
  LightLineSheetSet,
  LineSheetSetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios/index";
import { StoredObject } from "@/features/types";

export interface UpdateLineSheetSetRequest {
  name: string;
  season: string;
  type: LineSheetSetType;
  "issuedByBrand.id"?: number;
  "issuedByBoutique.id": number;
  validUntil: Date;
  lineSheetFiles: (File | Pick<StoredObject, "id" | "name">)[];
  orderFormFiles: (File | Pick<StoredObject, "id" | "name">)[];
  attachments: (File | Pick<StoredObject, "id" | "name">)[];
  orderRules: string[];
  externalFileLinks: string[];
}
export interface UpdateLineSheetSetResponse extends LightLineSheetSet {}

export interface UpdateLineSheetSetPathParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const updateLineSheetSetTemplateUrl = `/agencies/:agencyId/line-sheet-sets/:lineSheetSetId`;

export const updateLineSheetSetKey: YWRKey<UpdateLineSheetSetPathParameter> = {
  url: (parameter) => {
    return calcUrl(updateLineSheetSetTemplateUrl, parameter);
  },
  method: "put",
};

export default function useUpdateLineSheetSet(
  config?: AxiosRequestConfig<UpdateLineSheetSetRequest>
) {
  const fetcher = useFetcher<
    UpdateLineSheetSetRequest,
    UpdateLineSheetSetResponse
  >();

  return useYWR(updateLineSheetSetKey, fetcher, {
    ...config,
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 60 * 60 * 1000,
  });
}
