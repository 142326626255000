import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { bg } from "date-fns/locale";

interface SocialButtonProps {
  borderColor?: string;
  bgColor: string;
  padding?: string;
  src: string;
  name: string;
}
export default function SocialButton({
  borderColor,
  bgColor,
  padding = "8px",
  src,
  name,
}: SocialButtonProps) {
  const border = borderColor
    ? {
        border: `1px solid ${borderColor}`,
      }
    : {};

  return (
    <Flex flexDirection="column" alignItems="center" as="a" href="#">
      <Box
        {...border}
        bgColor={bgColor}
        boxSize="48px"
        padding={padding}
        display="flex"
        justifyContent="center"
        borderRadius="full"
      >
        <Image
          boxSize="100%"
          src={src}
          alt="sign in with facebookx"
          borderRadius="full"
        />
      </Box>
      <Text color="gray" fontSize="sm">
        {name}
      </Text>
    </Flex>
  );
}
