import { StoredObject } from "@/features/types";
import { Flex, Icon, Input } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import React, { useRef } from "react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";

interface AppFileInputProps {
  value: File | Pick<StoredObject, "id" | "name">;
  onChange: (value: File) => void;
  onDelete: () => void;
  isDisabled?: boolean;
}
export default function AppFileInput({
  value,
  onChange,
  onDelete,
  isDisabled,
}: AppFileInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <Flex
      height={"24px"}
      gap={"8px"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      <input
        type={"file"}
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            onChange(event.target.files[0]);
          }
        }}
      />
      <Icon as={SVG} src={"/icons/icon_download.svg"} />
      <Input
        fontSize={"12px"}
        height={"100%"}
        value={value.name}
        isReadOnly={true}
        onClick={() => {
          if (!isDisabled) {
            fileInputRef.current?.click();
          }
        }}
      />
      {!isDisabled && (
        <Flex flexShrink={"0"}>
          <AppIconButton
            ariaLabel={"delete"}
            icon={"x"}
            onClick={() => {
              onDelete();
            }}
            size={"small"}
          />
        </Flex>
      )}
    </Flex>
  );
}
