import { Box, Button, Flex, Spacer, Spinner } from "@chakra-ui/react";
import SVG from "react-inlinesvg";

interface AppGridRowAttachmentExtraContentProps {
  name: string;
  onClick?: () => void;
  isLoading?: boolean;
}
export default function AppGridRowAttachmentExtraContent({
  name,
  isLoading = false,
  onClick,
}: AppGridRowAttachmentExtraContentProps) {
  return (
    <Flex height={"32px"} width={"100%"}>
      <Flex
        as={Button}
        backgroundColor={"transparent"}
        _hover={{
          backgroundColor: "transparent",
          textDecoration: "underline",
        }}
        borderRadius={"0"}
        gap={"8px"}
        flexDirection={"row"}
        height={"auto"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        fontSize={"12px"}
        fontWeight={"400"}
        borderBottom={"0.5px solid var(--Gray, #D4D4D1)"}
        onClick={() => {
          if (!isLoading && onClick) {
            onClick();
          }
        }}
      >
        <SVG src={"/icons/icon_excel.svg"} />
        {isLoading ? <Spinner size={"sm"} /> : name}
      </Flex>
      <Spacer borderBottom={"0.5px solid var(--Gray, #D4D4D1)"} />
      <Box width={"16px"}></Box>
    </Flex>
  );
}
