import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { toCamel, toSnake } from "@/utils/case";
import { TOptions } from "i18next/typescript/options";
import * as Yup from "yup";
import { Schema } from "yup";
import useIdentity from "@/features/ui/hooks/use-identity";

export interface YupMessageParameter {
  path: string;
  label?: string;
  value?: any;
}

interface YupHelperParameter {
  ns: string;
  prefix: string;
}

export default function useYupHelper({ ns, prefix }: YupHelperParameter) {
  const { t, i18n } = useTranslation();
  const identity = useIdentity();

  const withMessage = useMemo(() => {
    function getField(parameter: YupMessageParameter) {
      const { path, label } = parameter;

      const tuples: [string, TOptions?][] = [];

      function createTuples(str: string): [string, TOptions?][] {
        return [[`${prefix}.field.${toSnake(str)}`, { ns }], [toSnake(str)]];
      }

      const source = label || path;
      tuples.push(...createTuples(source));
      tuples.push(...createTuples(`${source}.abbr`));

      if (source.includes(".")) {
        const i = source.lastIndexOf(".");
        if (i > -1) {
          tuples.push(...createTuples(source.substring(i + 1)));
          tuples.push(...createTuples(`${source.substring(i + 1)}.abbr`));
        }
      }

      const tuple = tuples.find(([key, option]) => {
        return i18n.exists(key, option);
      });

      if (tuple) {
        return t(tuple[0], tuple[1]);
      }

      return "";
    }

    return {
      required: (parameter: YupMessageParameter) => {
        return t(`form.messages.error.required`, {
          field: getField(parameter),
        });
      },
      min: (parameter: { min: number } & YupMessageParameter) => {
        const { min, value } = parameter;

        if (value && typeof value === "string") {
          return t(`form.messages.error.min.string`, {
            field: getField(parameter),
            min,
          });
        } else if (value && typeof value === "number") {
          return t(`form.messages.error.min.number`, {
            field: getField(parameter),
            min,
          });
        }

        return t(`form.messages.error.min`, {
          field: getField(parameter),
          min,
        });
      },
      max: (parameter: { max: number } & YupMessageParameter) => {
        const { max, value } = parameter;

        if (value && typeof value === "string") {
          return t(`form.messages.error.max.string`, {
            field: getField(parameter),
            max,
          });
        }

        if (value && typeof value === "number") {
          return t(`form.messages.error.max.number`, {
            field: getField(parameter),
            max,
          });
        }

        return t(`form.messages.error.max`, {
          field: getField(parameter),
          max,
        });
      },
      positive: (parameter: YupMessageParameter) => {
        return t(`form.messages.error.positive`, {
          field: getField(parameter),
        });
      },
      integer: (parameter: YupMessageParameter) => {
        return t(`form.messages.error.integer`, {
          field: getField(parameter),
        });
      },
      email: (parameter: YupMessageParameter) => {
        return t(`form.messages.error.email`, {
          field: getField(parameter),
        });
      },
    };
  }, [i18n, t, ns, prefix]);

  const formT = useCallback(
    (key: string, func?: (source: string) => string) => {
      const r = t(`${prefix}.${key}`, { ns });
      return func ? func(r) : r;
    },
    [t, prefix, ns]
  );

  const withRequiredIf = useMemo(() => {
    return {
      isAgency: {
        is: () => {
          return identity?.company?.type === "AGENCY";
        },
        then: (schema: Schema) => schema.required(),
        message: withMessage.required,
      },
      isBoutique: {
        is: () => {
          return identity?.company?.type === "BOUTIQUE";
        },
        then: (schema: Schema) => schema.required(),
        message: withMessage.required,
      },
    };
  }, [identity?.company?.type, withMessage.required]);

  return {
    withMessage,
    formT,
    withRequiredIf,
  };
}
