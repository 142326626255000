import { useRecoilCallback, useRecoilValue } from "recoil";
import { SheetProduct } from "@/features/order-sheets/sheet.type";
import {
  FlatSheetKey,
  InflatedProduct,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { produce } from "immer";
import { ProductPrice } from "@/features/types";
import { PriceAdjustedBy } from "@/features/line-sheet-sets/line-sheet-set.types";

function calcSupply(product: SheetProduct): {
  confirmedPrice: ProductPrice | null;
  supplyPrice: ProductPrice | null;
} {
  let confirmedPrice = null;
  let supplyPrice = null;
  if (product.confirmedPriceAdjustedBy) {
    confirmedPrice = calcPrice(
      product.confirmedPriceAdjustedBy,
      product.costPrice,
      product.retailPrice
    );
  }

  if (product.priceAdjustedBy) {
    supplyPrice = calcPrice(
      product.priceAdjustedBy,
      product.costPrice,
      product.retailPrice
    );
  }

  return {
    confirmedPrice,
    supplyPrice,
  };
}

function calcPrice(
  condition: PriceAdjustedBy | null,
  costPrice: ProductPrice | null,
  retailPrice: ProductPrice | null
) {
  if (condition) {
    const { by, value } = condition;
    let supplyPrice;
    let iva = 1.22;
    if (value === 0) {
      iva = 1;
    }
    if (by === "MARKUP" && costPrice) {
      supplyPrice = {
        amount: Math.round(costPrice.amount * (1 + value / 100) * 1000) / 1000,
        currency: costPrice.currency,
      };
    } else if (by === "DISCOUNT" && retailPrice) {
      supplyPrice = {
        amount:
          Math.round(((retailPrice.amount * (1 - value / 100)) / iva) * 1000) /
          1000,
        currency: retailPrice.currency,
      };
    } else {
      supplyPrice = null;
    }
    return supplyPrice;
  }
  return null;
}

export default function useInflatedTabs() {
  const updateInflatedProduct = useRecoilCallback(
    ({ set }) =>
      (key: FlatSheetKey, tabIndex: number, nextProduct: InflatedProduct) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState && tabIndex > -1) {
            return produce(prevState, (draft) => {
              const draftTabIndex = draft.tabs.findIndex(
                (tab) => tab.index === tabIndex
              );

              if (draftTabIndex > -1) {
                const productIndex = draft.tabs[
                  draftTabIndex
                ].products.findIndex(
                  (product) => product.id === nextProduct.id
                );

                if (productIndex > -1) {
                  draft.tabs[draftTabIndex].products[productIndex] = {
                    ...nextProduct,
                    ...calcSupply(nextProduct),
                  };
                }
                draft.tabs[draftTabIndex].isDirty = true;
              }
            });
          }

          return prevState;
        });
      },
    []
  );

  const updateInflatedProducts = useRecoilCallback(
    ({ set }) =>
      (
        key: FlatSheetKey,
        tabIndex: number,
        nextProducts: InflatedProduct[]
      ) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState && tabIndex > -1) {
            return produce(prevState, (draft) => {
              const draftTabIndex = draft.tabs.findIndex(
                (tab) => tab.index === tabIndex
              );

              if (draftTabIndex > -1) {
                nextProducts.forEach((nextProduct) => {
                  const productIndex = draft.tabs[
                    draftTabIndex
                  ].products.findIndex(
                    (product) => product.id === nextProduct.id
                  );
                  if (productIndex > -1) {
                    draft.tabs[draftTabIndex].products[productIndex] = {
                      ...nextProduct,
                      ...calcSupply(nextProduct),
                    };
                  }
                });
                draft.tabs[draftTabIndex].isDirty = true;
              }
            });
          }
          return prevState;
        });
      },
    []
  );

  const getInflatedProductById = useRecoilCallback(
    ({ snapshot }) =>
      (key: FlatSheetKey, tabIndex: number, id: number) => {
        const inflatedTabsLodable = snapshot.getLoadable(
          inflatedTabsFamily(key)
        );
        if (inflatedTabsLodable.state === "hasValue") {
          const inflatedTabsState = inflatedTabsLodable.contents;
          if (inflatedTabsState) {
            const tab = inflatedTabsState.tabs.find(
              (t) => t.index === tabIndex
            );
            if (tab) {
              return tab.products.find((p) => p.id === id);
            }
          }
        } else {
          throw {
            code: -1,
            status: "SORRY_MY_BAD",
            message: "I thought synchronous atomFamily always has value",
          };
        }
      },
    []
  );

  //여기서도 filter하고 useFetchAndSetLineSheetTab에서도 필터하는구낭..
  //filter작업이 두곳에서 일어나는구만... 합치는게 좋은데..
  const filterInflatedProducts = useRecoilCallback(
    ({ set }) =>
      (
        key: FlatSheetKey,
        tabIndex: number,
        filter: (product: SheetProduct) => boolean
      ) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState) {
            return produce(prevState, (draft) => {
              if (tabIndex !== -1) {
                const draftTabIndex = draft.tabs.findIndex(
                  (tab) => tab.index === tabIndex
                );

                if (draft.tabs[draftTabIndex]) {
                  draft.tabs[draftTabIndex].products = draft.tabs[
                    draftTabIndex
                  ].products.map((product) => {
                    return {
                      ...product,
                      isFiltered: filter(product),
                    };
                  });
                }
              }
            });
          }
          return prevState;
        });
      },
    []
  );

  const resetInflatedSheet = useRecoilCallback(
    ({ reset }) =>
      (key: FlatSheetKey) => {
        reset(inflatedTabsFamily(key));
      },
    []
  );

  return {
    updateInflatedProduct,
    updateInflatedProducts,
    getInflatedProductById,
    filterInflatedProducts,
    resetInflatedSheet,
  };
}
