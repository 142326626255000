import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { getJwtPayload } from "@/utils/jwt";
import { Company } from "@/features/types";

export interface User {
  id: number;
  email: string;
  userInputedCompanyName: string;
  name: string;
  mobileNumber: string;
  companies: Company[];
}

interface GetMySelfResponse extends User {}

interface GetMySelfRequest {}

export function getMySelfId() {
  const payload = getJwtPayload();
  if (payload) {
    return payload.sub;
  }
}

export default function useGetMySelf(
  config: SWRConfiguration = { revalidateOnFocus: false }
) {
  const fetcher = useFetcher<GetMySelfRequest, GetMySelfResponse>();
  const userId = getMySelfId();

  return useSWR<GetMySelfResponse>(
    userId ? { url: `/users/${userId}` } : null,
    fetcher,
    config
  );
}
