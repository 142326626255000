import {
  Box,
  ChakraProps,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import AppFlatCard, {
  AppFlatCardProps,
  AppLineCardAction,
  CARD_ACTION_WIDTH,
} from "@/features/line-sheet-sets/app-flat-card";
import {
  GridRow,
  GridRowType,
} from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";

export interface AppGridContext {
  getMeta: (rowType: GridRowType) => AppGridMeta | undefined;
  metas: AppGridMeta[];
}

type AppGridConfig = {
  rows: GridRow[] | undefined;
  onHover?: (path: number[], row: GridRow, isHovered: boolean) => void;
  onClick?: (path: number[], row: GridRow) => void;
  onCheck?: (path: number[], row: GridRow, isChecked: boolean) => void;
  metas: AppGridMeta[];
};

export interface AppGridMeta {
  isCheckable?: boolean;
  isChecked?: boolean;
  isCheckDisabled?: boolean;
  rowType: GridRowType;
  columns: AppGridColumn[];
  fields: AppGridField[];
  actions?: ((r: GridRow) => AppLineCardAction | undefined)[];
  indicator?: (row: GridRow) => ReactElement;
  onClick?: (r: GridRow) => void;
  onCheck?: (r: GridRow, isChecked: boolean) => void;
  // extraContent?: (_row: GridRow, meta: AppGridMeta) => ReactElement;
  extraContents?: (_row: GridRow, meta: AppGridMeta) => ReactElement[];
  tooltip?: (row: GridRow) => ReactElement | undefined;
  gap?: string;
  onCheckAll?: (isChecked: boolean) => void;
  showCheckAll?: boolean;
}

interface AppGridProps extends AppGridConfig {
  isHeadless?: boolean;
  isAnchored?: boolean;
  showNoRows?: boolean;
}

export interface AppGridColumn {
  name: string;
  width: string;
  textAlign?: ChakraProps["textAlign"];
  marginRight?: ChakraProps["marginRight"];
  value?: ReactElement | string;
}

export interface AppGridField {
  name: string;
  chakraProps?: ChakraProps | ((row: GridRow) => ChakraProps);
  isBold?: boolean;
  value: (_row: GridRow, context: AppGridContext) => ReactElement | string;
}

export default function AppGrid({
  isHeadless,
  isAnchored = true,
  showNoRows = true,
  rows,
  onHover,
  onClick,
  onCheck,
  metas,
}: AppGridProps) {
  const { tSentence } = useI18nHelper();
  const context = useMemo((): AppGridContext => {
    return {
      getMeta: (rowType: GridRowType) => {
        return metas.find((meta) => meta.rowType === rowType);
      },
      metas,
    };
  }, [metas]);

  const isAllRowChecked = useMemo(() => {
    if (!rows || rows.length === 0 || rows.filter((row) => row.isCheckable).length === 0) {
      return false;
    }

    if (metas.length > 0 && rows) {
      return metas
        .filter((meta) => meta.isCheckable)
        .every((meta) => {
          return rows
            .filter((row) => row._rowType === meta.rowType)
            .filter((row) => row.isCheckable)
            .every((row) => row.isChecked);
        });
    }
    return false;
  }, [metas, rows]);

  const isAnyRowCheckable = useMemo(() => {
    if (
      metas.some((meta) => meta.isCheckable) &&
      (!rows || rows.length === 0)
    ) {
      return true;
    }

    if (metas.length > 0 && rows) {
      return metas
        .filter((meta) => meta.isCheckable)
        .some((meta) => {
          return rows
            .filter((row) => row._rowType === meta.rowType)
            .some((row) => row.isCheckable);
        });
    }
    return false;
  }, [metas, rows]);

  const templatesByRowType = useMemo(() => {
    let value: Record<string, { header: string; body: string }> = {};
    metas.forEach((meta) => {
      let items = [];
      const indicatorSpace = 24;
      //const actionSpace = meta.actions ? CARD_ACTION_WIDTH : "0";

      const bodyItems = [
        `${indicatorSpace}px`,
        ...meta.columns.map((i) => i.width),
      ];
      const headerItems = [...bodyItems];

      value[meta.rowType] = {
        header: headerItems.join(" "),
        body: bodyItems.join(" "),
      };
    });
    return value;
  }, [metas]);

  function createAppGridRow(
    rows: GridRow[],
    path: number[] = []
  ): JSX.Element[] {
    return rows.map((row, index) => {
      const meta = metas.find((meta) => meta.rowType === row._rowType);
      if (!meta) {
        return <></>;
      }
      const childPath = [...path, index];

      function isAppLineCardAction(
        value: AppLineCardAction | undefined
      ): value is AppLineCardAction {
        return value !== undefined;
      }

      const actions = ((row: GridRow): AppLineCardAction[] => {
        if (meta.actions) {
          return meta.actions
            .map((action) => action(row))
            .filter(isAppLineCardAction);
        }
        return [];
      })(row);

      const checkbox: AppFlatCardProps["checkbox"] = (() => {
        if (meta.isCheckable) {
          return {
            isChecked: row.isChecked,
            isDisabled: !row.isCheckable,
            onChange: (value: boolean) => {
              if (meta.onCheck) {
                meta.onCheck(row, value);
              }

              if (onCheck) {
                onCheck(childPath, row, value);
              }
            },
          };
        }
        return undefined;
      })();

      return (
        <Flex key={`${row._rowId}`} flexDirection={"column"} gap={"12px"}>
          <AppFlatCard
            isActive={row.isActive}
            checkbox={checkbox}
            onMouseEnter={
              onHover ? () => onHover(childPath, row, true) : undefined
            }
            onMouseLeave={
              onHover ? () => onHover(childPath, row, false) : undefined
            }
            actions={actions}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (onClick) {
                onClick(childPath, row);
              }

              if (meta.onClick) {
                meta.onClick(row);
              }
            }}
            level={path.length + 1}
            extraContents={
              meta.extraContents ? meta.extraContents(row, meta) : undefined
            }
            tooltip={meta.tooltip?.(row)}
            isTooltipOpen={row.isHovered || row.isTooltipOpen}
          >
            <Grid
              flexGrow={1}
              gridTemplateColumns={templatesByRowType[row._rowType].body}
              color={"#444440"}
              alignItems={"center"}
              gap={meta.gap || "0"}
            >
              {meta.indicator ? (
                meta.indicator(row)
              ) : (
                <GridRowIndicator
                  type={row.children.length > 0 ? "COLLAPSE" : "EMPTY"}
                  isHovered={false}
                  isCollapsed={row.isCollapsed}
                />
              )}

              {meta.fields.map((field) => {
                const value = field.value(row, context);
                let title: string | undefined;
                if (typeof value === "string") {
                  title = value;
                }

                let gridItemProps: ChakraProps = {};

                if (typeof field.chakraProps === "function") {
                  gridItemProps = field.chakraProps(row);
                } else if (field.chakraProps) {
                  gridItemProps = { ...field.chakraProps };
                }

                if (field.isBold) {
                  gridItemProps.fontWeight = "700";
                }

                if ((row as { isRead?: boolean }).isRead && row.isCollapsed) {
                  gridItemProps.color = "#8F8F8C";
                } else {
                  gridItemProps.color = gridItemProps.color || "#444440";
                }

                return (
                  <GridItem
                    key={`${row._rowId}_${field.name}`}
                    className={`${row._rowId}_${field.name}`}
                    // maxH={"40px"}
                    // alignSelf={"stretch"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    // whiteSpace={"nowrap"}
                    title={title}
                    {...gridItemProps}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {/*{bar(field, row)}*/}
                    {value}
                  </GridItem>
                );
              })}
            </Grid>
          </AppFlatCard>
          {!row.isCollapsed &&
            row.children &&
            createAppGridRow(row.children, childPath)}
        </Flex>
      );
    });
  }

  const header = useMemo(() => {
    const meta = metas[0];
    const gridTemplateColumns = templatesByRowType[meta.rowType].header;

    return (
      <Flex
        // height={"40px"}
        minHeight={"36px"}
        boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.10)"}
        borderTop={"0.5px solid #8F8F8C"}
        borderBottom={"0.5px solid #8F8F8C"}
        border={"0.5px solid rgb(143, 143, 140)"}
        borderLeftWidth={isAnchored ? "0" : "0.5px"}
        borderRadius={isAnchored ? "0 8px 8px 0" : "8px"}
        background={"#FFF"}
        padding={"0 16px"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        {/*{meta.isCheckable && <Box width={"20px"}></Box>}*/}
        {meta.isCheckable &&
          (meta.showCheckAll === undefined ? true : meta.showCheckAll) && (
            <Box width={"20px"}>
              <AppCheckBox
                isChecked={isAllRowChecked}
                isDisabled={!isAnyRowCheckable}
                onChange={(value) => {
                  if (meta.onCheckAll) {
                    meta.onCheckAll(value);
                  }
                }}
              />
            </Box>
          )}
        <Grid
          flexGrow={"1"}
          marginLeft={meta.isCheckable ? "12px" : "0"}
          // height={"100%"}
          gridTemplateColumns={gridTemplateColumns}
          alignItems={"center"}
          padding={"0 8px"}
          fontSize={"12px"}
          gap={meta.gap || "0"}
        >
          <GridItem></GridItem>
          {meta.columns.map((column) => {
            return (
              <GridItem
                key={`"AppGridHeaderColumn_${meta.rowType}_${column.name}`}
                textAlign={column.textAlign}
                marginRight={column.marginRight}
                fontWeight={"700"}
              >
                {column.value || column.name}
              </GridItem>
            );
          })}
        </Grid>
        {meta.actions && meta.actions.length > 0 && (
          <Box width={CARD_ACTION_WIDTH}></Box>
        )}
      </Flex>
    );
  }, [
    metas,
    templatesByRowType,
    isAnchored,
    isAllRowChecked,
    isAnyRowCheckable,
  ]);

  return (
    <Grid
      height={"100%"}
      gridTemplateColumns={"1fr"}
      gridTemplateRows={"auto 1fr"}
    >
      {!isHeadless && header}
      <Flex
        w={"100%"}
        flexDirection={"column"}
        gap={"16px"}
        p={isHeadless ? "0" : "12px 16px"}
      >
        {rows && rows.length > 0 && createAppGridRow(rows)}
        {rows && rows.length === 0 && showNoRows && (
          <Flex
            w={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <Text fontSize={"12px"}>{tSentence("no_rows_to_show")}</Text>
          </Flex>
        )}
        {!rows && (
          <Flex
            w={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <Spinner size={"md"} />
          </Flex>
        )}
      </Flex>
    </Grid>
  );
}
