import { DenseOrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useGetDenseOrderConfirmationFetcher from "@/features/invoices/order-confirmation-invoices/hooks/use-get-dense-order-confirmation-fetcher";
import useSWR, { SWRConfiguration } from "swr";

export interface GetDenseOrderConfirmationInvoiceParameter
  extends IdentityParameter {
  orderConfirmationInvoiceId: number;
}
export interface GetDenseOrderConfirmationInvoiceRequest {}
export interface GetDenseOrderDenseConfirmationInvoiceResponse
  extends DenseOrderConfirmationInvoice {}

export const getDenseOrderConfirmationInvoiceTemplateUrl = `/:by/:companyId/dense-order-confirmation-invoices/:orderConfirmationInvoiceId`;

export function getGetDenseOrderConfirmationInvoiceKey(
  parameter?: GetDenseOrderConfirmationInvoiceParameter,
  request?: GetDenseOrderConfirmationInvoiceRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(getDenseOrderConfirmationInvoiceTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseGetDenseOrderConfirmationInvoiceKey(
  key: AxiosRequestConfig<GetDenseOrderConfirmationInvoiceRequest>
) {
  const urlParser = new UrlParser<GetDenseOrderConfirmationInvoiceParameter>(
    getDenseOrderConfirmationInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderConfirmationInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export default function useGetDenseOrderConfirmationInvoice(
  parameter?: GetDenseOrderConfirmationInvoiceParameter,
  request?: GetDenseOrderConfirmationInvoiceRequest,
  config?: SWRConfiguration<GetDenseOrderDenseConfirmationInvoiceResponse>
) {
  const fetcher = useGetDenseOrderConfirmationFetcher();
  return useSWR(
    getGetDenseOrderConfirmationInvoiceKey(parameter, request),
    fetcher,
    config
  );
}
