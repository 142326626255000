import useUpdatePayment, {
  updatePaymentKey,
  UpdatePaymentParameter,
  UpdatePaymentRequest,
} from "@/features/invoices/payment/hooks/use-update-payment";
import useCreatePayment, {
  createPaymentKey,
  CreatePaymentParameter,
  CreatePaymentRequest,
} from "@/features/invoices/payment/hooks/use-create-payment";
import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useCallback } from "react";
import { isFile, StoredObject } from "@/features/types";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";

function mutatePayments(paymentId?: number) {
  if (paymentId) {
    mutateIfMatched(
      new RegExp(`/\/(agencies|buyers)\/\d+\/(dense-)?payments\/${paymentId}/`)
    );
  }
  mutateIfMatched(/\/(agencies|buyers)\/\d+\/(dense-)?payments/);
}

export function usePaymentDumbTransaction() {
  const { fire: fireUpdatePayment, error: updatePaymentError } =
    useUpdatePayment();
  const { fire: fireCreatePayment } = useCreatePayment();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();

  const addCreate = useCallback(
    (
      parameter: CreatePaymentParameter,
      request: Omit<CreatePaymentRequest, "krwInvoiceFiles"> & {
        krwInvoiceFiles:
          | {
              invoices: {
                type: "PROFORMA_INVOICE" | "ORDER_CONFIRMATION_INVOICE";
                id: number;
              }[] | null;
              file: File;
            }[]
          | null;
      }
    ) => {
      addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (source) =>
          (source.krwInvoiceFiles || []).map((f) => f.file).filter(isFile),
        convertFunc: (source, storeObjectResponses) => {
          let i = 0;
          return {
            ...source,
            krwInvoiceFiles:
              (source.krwInvoiceFiles || []).map((f) => {
                return {
                  ...f,
                  file: isFile(f.file) ? storeObjectResponses[i++] : f.file,
                };
              }) || null,
          };
        },
        fire: fireCreatePayment,
        keyOrFunc: calcYWRUrl(createPaymentKey, parameter),
        onPostFunc: (res) => {
          mutatePayments(res.id);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreatePayment, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdatePaymentParameter,
      request: Omit<UpdatePaymentRequest, "krwInvoiceFiles"> & {
        krwInvoiceFiles:
          | {
              invoices: {
                type: "PROFORMA_INVOICE" | "ORDER_CONFIRMATION_INVOICE";
                id: number;
              }[] | null;
              file: File | Pick<StoredObject, "id" | "name" | "createdAt">;
            }[]
          | null;
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return (request.krwInvoiceFiles || [])
            .map((f) => f.file)
            .filter(isFile);
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            krwInvoiceFiles: (source.krwInvoiceFiles || []).map((f, i) => ({
              ...f,
              file: isFile(f.file) ? storeObjectResponses[i] : f.file,
            })),
          };
        },
        fire: fireUpdatePayment,
        keyOrFunc: calcYWRUrl(updatePaymentKey, parameter),
        onPostFunc: (res) => {
          mutatePayments(res.id);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireUpdatePayment, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdatePaymentParameter) => {
      return isDumbTransactionQueued(calcYWRUrl(updatePaymentKey, parameter));
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate: addCreate,
    addUpdate: addUpdate,
    isUpdateQueued,
  };
}
