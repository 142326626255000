import { atom } from "recoil";
import { AlertStatus } from "@chakra-ui/alert";

export interface AppToast {
  id: string;
  status: AlertStatus;
  message: string;
}

export const appToastsState = atom<AppToast[]>({
  key: "AppToasts",
  default: [],
});
