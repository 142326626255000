import { getConfig, YWRConfig, YWRKey } from "@/features/ui/hooks/use-ywr";
import { AxiosRequestConfig } from "axios";
import { ErrorResponse } from "@/utils/uno-axios";
import { Dispatch, useCallback, useReducer, useRef } from "react";
import { Draft, produce } from "immer";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";

export interface YWRInfiniteResponse<PathParameter, Request, Response> {
  data: YWRResponseSegment<Request, Response>[];
  key: string | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
  clear: (key: string | string[]) => void;
}

export interface YWRResponseSegment<Request, Response> {
  key: string;
  request: Request | undefined;
  response: Response | undefined;
  error: ErrorResponse | undefined;
  isLoading: boolean;
  progress?: number;
  clear: () => void;
}

export interface YWRFireParameter<PathParameter, Request, Response> {
  pathParameter: PathParameter;
  request: Request;
  ywrConfig?: YWRConfig;
  fireConfig?: AxiosRequestConfig<Request>;
}

type Action<Request, Response> =
  | {
      type: "start";
      key: string;
      request: Request;
    }
  | { type: "progress"; key: string; percentage: number }
  | { type: "success"; key: string; response: Response }
  | { type: "error"; key: string; error: ErrorResponse }
  | { type: "clear"; key: string | string[] };

function ywrInfiniteReducer<PathParameter, Request, Response>(
  prevState: YWRInfiniteResponse<PathParameter, Request, Response>,
  action: Action<Request, Response>
) {
  function synchronize(
    draft: Draft<YWRInfiniteResponse<PathParameter, Request, Response>>
  ) {
    draft.isLoading = draft.data.findIndex((i) => i.isLoading) > -1;
  }

  switch (action.type) {
    case "start":
      return produce(prevState, (draft) => {
        const datum = draft.data.find((d) => d.key === action.key);
        if (datum) {
          datum.isLoading = true;
          datum.request = action.request as Draft<Request>;
          datum.response = undefined;
          datum.error = undefined;
          datum.isLoading = true;
          datum.clear = () => {
            prevState.clear(action.key);
          };
        } else {
          draft.data.push({
            key: action.key,
            request: action.request as Draft<Request>,
            response: undefined,
            error: undefined,
            isLoading: true,
            clear: () => {
              prevState.clear(action.key);
            },
          });
        }
        synchronize(draft);
      });

    case "progress":
      return produce(prevState, (draft) => {
        const datum = draft.data.find((d) => d.key === action.key);
        if (datum) {
          datum.isLoading = true;
          datum.progress = action.percentage;
        }
        synchronize(draft);
      });
    case "success":
      return produce(prevState, (draft) => {
        const datum = draft.data.find((d) => d.key === action.key);
        if (datum) {
          datum.isLoading = false;
          datum.response = action.response as Draft<Response>;
          datum.error = undefined;
          datum.isLoading = false;
        }
        if (draft.key === action.key) {
          draft.error = undefined;
        }

        synchronize(draft);
      });
    case "error":
      return produce(prevState, (draft) => {
        const datum = draft.data.find((d) => d.key === action.key);
        if (datum) {
          datum.isLoading = false;
          datum.response = undefined;
          datum.error = action.error;
          datum.isLoading = false;
        }
        draft.key = action.key;
        draft.error = action.error;
        synchronize(draft);
      });
    case "clear":
      return produce(prevState, (draft) => {
        const keys = typeof action.key === "string" ? [action.key] : action.key;
        //삭제로 변경
        keys.forEach((key) => {
          const i = draft.data.findIndex((d) => d.key === key);
          if (i > -1) {
            draft.data.splice(i, 1);
          }
        });
        if (draft.key && keys.indexOf(draft.key) > -1) {
          draft.key = undefined;
          draft.error = undefined;
        }
        synchronize(draft);
      });
  }
}

export default function useYWRInfinite<
  PathParameter,
  Request,
  Response,
  Error = any
>(
  key: YWRKey<PathParameter>,
  fetcher: (config: AxiosRequestConfig<Request>) => Promise<Response>,
  defaultConfig?: AxiosRequestConfig<Request>
) {
  const initialArgumentsRef = useRef({
    key,
    fetcher,
    defaultConfig,
  });
  const firedKeysRef = useRef<string[]>([]);

  const calcConfig = useCallback(
    (
      parameter: PathParameter,
      request: Request,
      fireConfig?: AxiosRequestConfig<Request>
    ) => {
      const { key, defaultConfig } = initialArgumentsRef.current;
      const { url: urlOrFunc, method } = key;
      const config = {
        url: calcYWRUrl(key, parameter),
        method,
        ...defaultConfig,
        ...fireConfig,
      };

      if (config.method === "get" || config.method === "GET") {
        config.params = {
          ...config.params,
          ...request,
        };
      } else {
        config.data = request;
        // config.onUploadProgress = (progressEvent) => {
        //   if (progressEvent.total) {
        //     let percentage = (progressEvent.loaded * 100) / progressEvent.total;
        //   }
        // };
      }

      return config;
    },
    []
  );

  const [response, dispatch] = useReducer(
    ywrInfiniteReducer<PathParameter, Request, Response>,
    {
      data: [],
      key: undefined,
      isLoading: false,
      error: undefined,
      clear: (key: string | string[]) => {
        firedKeysRef.current = firedKeysRef.current.filter((firedKey) => {
          if (typeof key === "string") {
            return firedKey !== key;
          } else {
            return !key.includes(firedKey);
          }
        });
        dispatch({
          type: "clear",
          key: key,
        });
      },
    }
  );

  const fire = useCallback(
    async (
      parameter: PathParameter,
      request: Request,
      ywrConfig?: YWRConfig,
      fireConfig?: AxiosRequestConfig<Request>
    ) => {
      const config = calcConfig(parameter, request, fireConfig);
      const { fetcher: _initialFetcher } = initialArgumentsRef.current;
      const datumKey = config.url;

      const fetcher = ywrConfig?.fetcher || _initialFetcher;

      try {
        if (!firedKeysRef.current.includes(datumKey)) {
          firedKeysRef.current.push(datumKey);

          dispatch({
            type: "start",
            key: datumKey,
            request: request,
          });

          config.onUploadProgress = (progressEvent) => {
            if (progressEvent.total) {
              let percentage =
                (progressEvent.loaded * 100) / progressEvent.total;
              dispatch({ type: "progress", key: datumKey, percentage });
            }
          };

          const response = await fetcher(config);

          dispatch({ type: "success", key: datumKey, response });

          if (ywrConfig?.autoClear) {
            firedKeysRef.current = firedKeysRef.current.filter((firedKey) => {
              return firedKey !== datumKey;
            });
            dispatch({ type: "clear", key: datumKey });
          }

          return Promise.resolve(response);
        } else {
          throw {
            error: {
              code: -1,
              message: "",
              status: "DUPLICATED_REQUEST",
            },
          };
        }
      } catch (error: any) {
        dispatch({ type: "error", key: datumKey, error });
        if (ywrConfig?.rethrow) {
          throw error;
        }

        if (ywrConfig?.autoClear) {
          firedKeysRef.current = firedKeysRef.current.filter((firedKey) => {
            return firedKey !== datumKey;
          });
          dispatch({ type: "clear", key: datumKey });
        }

        return Promise.reject(error);
      }
    },
    [calcConfig, dispatch]
  );

  return {
    ...response,
    clear: response.clear,
    fire,
  };
}
