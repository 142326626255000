import useIdentity from "@/features/ui/hooks/use-identity";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useEffect, useMemo, useState } from "react";
import useListBankRemittanceReceipts, {
  ListBankRemittanceReceiptsPathParameter,
  ListBankRemittanceReceiptsRequest,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-list-bank-remittance-receipts";
import { BankRemittanceReceiptRow } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-row.type";
import { bankRemittanceReceiptRowMapper } from "@/features/invoices/utils/bank-remittance-receipt-row-mapper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import IsolatedPanelSkeleton from "@/features/invoices/isolated-panel-skeleton";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import AppGrid, {
  AppGridColumn,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";

interface IsolatedBankRemittanceReceiptPanelProps {
  bankRemittanceReceiptIds?: number[];
}

export default function IsolatedBankRemittanceReceiptPanel({
  bankRemittanceReceiptIds,
}: IsolatedBankRemittanceReceiptPanelProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle, tCurrencyString } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const key = useMemo(():
    | {
        parameter: ListBankRemittanceReceiptsPathParameter;
        request: ListBankRemittanceReceiptsRequest;
      }
    | undefined => {
    if (
      identity?.company?.type &&
      bankRemittanceReceiptIds &&
      bankRemittanceReceiptIds.length > 0
    ) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          id__in: bankRemittanceReceiptIds,
          pageNumber: 1,
          pageSize: bankRemittanceReceiptIds.length,
        },
      };
    }
    return undefined;
  }, [bankRemittanceReceiptIds, identity]);

  const {
    data: listBankRemittanceReceiptsData,
    error: listBankRemittanceReceiptsError,
    mutate: mutateListBankRemittanceReceipts,
  } = useListBankRemittanceReceipts(key?.parameter, key?.request);

  useEffect(() => {
    if (listBankRemittanceReceiptsError) {
      showError(listBankRemittanceReceiptsError);
    }
  }, [listBankRemittanceReceiptsError, showError]);

  const rowMapper = useMemo(() => {
    return bankRemittanceReceiptRowMapper.copy();
  }, []);

  const [rows, setRows] = useState<BankRemittanceReceiptRow[] | undefined>();

  useEffect(() => {
    if (listBankRemittanceReceiptsData) {
      setRows((prev) => {
        return rowMapper.toRows(
          listBankRemittanceReceiptsData.bankRemittanceReceipts.map(
            (resource) => {
              return {
                ...resource,
                isRead: false,
              };
            }
          ),
          prev
        );
      });
    }
  }, [listBankRemittanceReceiptsData, rowMapper]);

  const appGrid = useAppGrid();
  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: mutateListBankRemittanceReceipts,
  });

  const metas = useMemo((): AppGridMeta[] => {
    let pairs: { column: AppGridColumn; field: AppGridField }[] = [];

    if (identity?.company?.type === "BUYER") {
      pairs = [
        appGrid.pairs.season,
        // invoiceGrid.pairs.issuedBy,
        invoiceGrid.pairs.issuedThrough,
        // invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "AGENCY") {
      pairs = [
        appGrid.pairs.season,
        // invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    } else if (identity?.company?.type === "BOUTIQUE") {
      pairs = [
        appGrid.pairs.season,
        // invoiceGrid.pairs.issuedBy,
        // invoiceGrid.pairs.issuedThrough,
        invoiceGrid.pairs.orderedBy,
        appGrid.pairs.title,
        invoiceGrid.pairs.amount,
        invoiceGrid.pairs.issuedOn,
      ];
    }

    return [
      {
        rowType: "BankRemittanceReceipt",
        gap: "8px",
        columns: pairs.map((pair) => pair.column),
        fields: pairs.map((pair) => pair.field),
        indicator: (row: GridRow) => {
          return (
            <GridRowIndicator
              type={"VIEW"}
              isCollapsed={row.isCollapsed}
              isHovered={row.isHovered}
            />
          );
        },
        onClick: (row: GridRow) => {
          navigate(`/bank-remittance-receipts/${row.id}`);
        },
      },
    ];
  }, [
    appGrid.pairs.season,
    appGrid.pairs.title,
    identity?.company?.type,
    invoiceGrid.pairs.amount,
    // invoiceGrid.pairs.issuedBy,
    invoiceGrid.pairs.issuedOn,
    invoiceGrid.pairs.issuedThrough,
    invoiceGrid.pairs.orderedBy,
    navigate,
  ]);

  if (key === undefined) {
    return null;
  } else if (rows === undefined) {
    return <IsolatedPanelSkeleton />;
  }

  return (
    <AppPanel
      title={tTitle("related_bank_remittance_receipts")}
      variant={"ghost"}
    >
      <AppGrid isAnchored={false} rows={rows} metas={metas} />
    </AppPanel>
  );
}
