import { BoutiqueConfirmationMetadata } from "@/features/order-sheets/order-sheet.type";
import { Box, Flex } from "@chakra-ui/react";
import BoutiqueConfirmationMetadataInput from "@/features/order-status/boutique-confirmation-metadata-input";
import { BoutiqueConfirmationMetadataFormField } from "@/features/order-status/modals/confirm-by-boutique-order-sheet-set-modal";

interface BoutiqueConfirmationMetadataControllerProps {
  value: BoutiqueConfirmationMetadataFormField[];
  onChange: (value: BoutiqueConfirmationMetadataFormField[]) => void;
}
export default function BoutiqueConfirmationMetadataController({
  value,
  onChange,
}: BoutiqueConfirmationMetadataControllerProps) {
  function withChange(index: number) {
    return (_value: BoutiqueConfirmationMetadataFormField) => {
      onChange(
        value.map((original, _index) => {
          if (_index === index) {
            return _value;
          }
          return original;
        })
      );
    };
  }

  return (
    <Flex marginTop={"8px"} flexDirection={"column"} gap={"8px"}>
      {value.map((_value, index) => {
        return (
          <BoutiqueConfirmationMetadataInput
            key={`BoutiqueConfirmationMetadataInput_${index}`}
            value={_value}
            onChange={withChange(index)}
          />
        );
      })}
    </Flex>
  );
}
