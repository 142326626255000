import useIdentity from "@/features/ui/hooks/use-identity";
import AgencyBalanceTransactionListPage from "@/features/balance-transaction-status/agency-balance-transaction-list-page";
import BuyerBalanceTransactionListPage from "@/features/balance-transaction-status/buyer-balance-transaction-list-page";
import { Helmet } from "react-helmet-async";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export default function BalanceTransactionListPage() {
  const identity = useIdentity();
  const { tTitle } = useI18nHelper();

  return (
    <>
      <Helmet>
        <title>{`Pathrade | ${tTitle("balance_transactions")}`}</title>
        <meta
          property="og:title"
          content={`Pathrade | ${tTitle("balance_transactions")}`}
        />
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      {identity?.company?.type === "AGENCY" ? (
        <AgencyBalanceTransactionListPage />
      ) : identity?.company?.type === "BUYER" ? (
        <BuyerBalanceTransactionListPage />
      ) : (
        <></>
      )}
    </>
  );
}
