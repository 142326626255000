import { atom } from "recoil";

import { BuyerOrderSheetSetRow } from "@/features/order-status/order-sheet-set-row.type";

export const buyerOrderSheetSetRowsState = atom<
  BuyerOrderSheetSetRow[] | undefined
>({
  key: "BuyerOrderSheetSetRow",
  default: undefined,
});

export const buyerOrderSheetSetRowsMutateId = atom<number>({
  key: "BuyerOrderSheetSetRowMutateId",
  default: 0,
});
