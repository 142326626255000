import useIdentity from "@/features/ui/hooks/use-identity";
import useListTransportations, {
  ListTransportationsPathParameter,
  ListTransportationsRequest,
} from "@/features/transportations/hooks/use-list-transportations";
import { useEffect, useMemo, useState } from "react";
import AppListHeader from "@/features/ui/app-list-header";
import { Box, Flex, Grid } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import usePersistedTransportationListPageQuery from "@/features/transportations/hooks/use-persisted-transportation-list-page-query";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import {
  TransportationRow,
  transportationRowMapper,
} from "@/features/transportations/utils/transportation-row-mapper";
import AppGridCell from "@/features/order-sheet-sets/app-grid-cell";
import TransportationStatusLabel from "@/features/transportations/transportation-status-label";
import FlightTime from "@/features/transportations/flight-time";
import AppIcon from "@/features/ui/app-icon";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";

export interface ListPersistedTransportationListPageQuery
  extends Partial<ListTransportationsRequest> {}

export default function TransportationListPage() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { navigate } = useRouterHelper();
  const { t, tTitle, tCurrencyString, tLocalDateTimeString2 } = useI18nHelper();
  const [query, setQuery] =
    useTypedSearchParams<ListPersistedTransportationListPageQuery>();

  const key = useMemo(():
    | {
        parameter: ListTransportationsPathParameter;
        request: ListTransportationsRequest;
      }
    | undefined => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: 10,
        },
      };
    }
    return undefined;
  }, [identity, query]);

  const {
    data: listTransportationsData,
    error: listTransportationsError,
    mutate: mutateListTransportations,
  } = useListTransportations(key?.parameter, key?.request, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (listTransportationsError) {
      showError(listTransportationsError);
    }
  }, [listTransportationsError, showError]);

  const [rows, setRows] = useState<TransportationRow[]>();

  const rowMapper = useMemo(() => {
    return transportationRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows(rowMapper.toRows(listTransportationsData?.transportations));
  }, [listTransportationsData?.transportations, rowMapper]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        gap={"12px"}
        height={"100%"}
        paddingBottom={"16px"}
      >
        <AppListHeader
          filters={[
            <AppCompanySelect
              key={"shipped_from"}
              name={tTitle("shipped_from")}
              isMultiple={true}
              id={query["shippedFromList.id__in"]}
              types={["BOUTIQUE", "CROSS_TRADER"]}
              onSelect={(value) => {
                setQuery((prev) => {
                  return {
                    ...prev,
                    ["shippedFromList.id__in"]: value?.map((item) => item.id),
                    pageNumber: 1,
                  };
                });
              }}
            />,
            <AppButton
              key={"clear"}
              onClick={() => {
                setQuery((prev) => {
                  return {
                    pageNumber: 1,
                  };
                });
              }}
            >
              {tTitle("common:reset_filter")}
            </AppButton>,
          ]}
          actions={[
            (() => {
              if (identity?.company?.type === "AGENCY") {
                return (
                  <AppButton
                    key={"upload"}
                    variant={"primary"}
                    onClick={() =>
                      navigate("/transportations/upload", {
                        appendReferer: true,
                      })
                    }
                  >
                    {tTitle("upload_transportation.do")}
                  </AppButton>
                );
              }
            })(),
          ]}
        />
        <AppGrid
          rows={rows}
          metas={[
            {
              rowType: "Transportation",
              gap: "16px",
              columns: [
                {
                  name: tTitle("number.abbr"),
                  width: "200px",
                },
                {
                  name: tTitle("common:related_proforma_invoices"),
                  width: "1fr",
                },
                {
                  name: tTitle("departure"),
                  width: "280px",
                },
                {
                  name: "arrow",
                  width: "48px",
                  value: " ",
                },
                {
                  name: tTitle("arrival"),
                  width: "280px",
                },
                {
                  name: tTitle("common:transportation_cost.abbr"),
                  width: "80px",
                  textAlign: "end",
                },
                {
                  name: tTitle("last_updated_at"),
                  width: "144px",
                },
              ],
              fields: [
                {
                  name: "number",
                  value: (_row) => {
                    const row = _row as TransportationRow;
                    return (
                      <AppGridCell
                        value={[
                          <TransportationStatusLabel
                            key={"status"}
                            value={row.status}
                          />,
                          <Grid
                            width={"100%"}
                            key={"bl&invoice"}
                            gridTemplateColumns={"48px 1fr"}
                            gridAutoRows={"18px"}
                            columnGap={"8px"}
                          >
                            <Box color={"#8F8F8C"}>
                              {tTitle("bl_number.abbr")}
                            </Box>
                            <Box
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              {row.houseBlNumbers.join(", ")}
                            </Box>
                            <Box color={"#8F8F8C"}>
                              {tTitle("invoice_number.abbr")}
                            </Box>
                            <Box
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              {row.forwarderInvoiceNumbers.join(", ")}
                            </Box>
                            {/*<Box>{row.forwarderInvoiceNumbers.join(", ")}</Box>*/}
                          </Grid>,
                        ]}
                      />
                    );
                  },
                },
                {
                  name: "related_proforma_invoices",
                  value: (_row) => {
                    let value = "";
                    const row = _row as TransportationRow;
                    const count = row.proformaInvoices.length;
                    if (count > 0) {
                      value += row.proformaInvoices[0].name;
                      const othersCount = count - 1;
                      if (othersCount > 0) {
                        value += ` ${t(
                          othersCount > 1
                            ? "and_other_proforma_invoice_one"
                            : "and_other_proforma_invoice_other",
                          {
                            count: othersCount,
                          }
                        )}`;
                      }
                    }
                    return value;
                  },
                },
                {
                  name: "departure",
                  value: (_row) => {
                    const row = _row as TransportationRow;
                    return (
                      <AppGridCell
                        value={[
                          <Box
                            key={"shipped_from_list"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            fontWeight={"700"}
                          >
                            {row.shippedFromList
                              .map((shippedFrom) => shippedFrom.name)
                              .join(", ")}
                          </Box>,
                          <Box key={"departure"} fontWeight={"700"}>
                            {row.flightDepartureAirport}
                          </Box>,
                          <FlightTime
                            key={"time"}
                            estimated={row.flightEtd}
                            actual={row.flightAtd}
                          />,
                        ]}
                      />
                    );
                  },
                },
                {
                  name: "arrow",
                  value: (_row) => {
                    return <AppIcon icon={"right"} color={"#1272EF"} />;
                  },
                },
                {
                  name: "arrival",
                  value: (_row) => {
                    const row = _row as TransportationRow;
                    return (
                      <AppGridCell
                        value={[
                          <Box key={"shipped_to"} fontWeight={"700"}>
                            {row.shippedTo.name}
                          </Box>,
                          <Box key={"shipped_to_address"} fontWeight={"700"}>
                            {row.shippedToAddress}
                          </Box>,
                          <FlightTime
                            key={"time"}
                            estimated={row.flightEta}
                            actual={row.flightAta}
                          />,
                        ]}
                      />
                    );
                  },
                },
                {
                  name: "transportation_cost",
                  chakraProps: {
                    textAlign: "end",
                  },
                  value: (_row) => {
                    const row = _row as TransportationRow;
                    return tCurrencyString(row.cost, {
                      code: "KRW",
                      type: "SYMBOL",
                    });
                  },
                },
                {
                  name: "last_updated_at",
                  value: (_row) => {
                    const row = _row as TransportationRow;
                    return tLocalDateTimeString2(row.updatedAt);
                  },
                },
              ],
              onClick: (_row) => {
                const row = _row as TransportationRow;
                navigate(`/transportations/${row.id}`, { appendReferer: true });
              },
            },
          ]}
        />
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <AppPagination
            capacity={10}
            pageSize={10}
            totalCount={listTransportationsData?.totalCount}
            pageNumber={query.pageNumber}
            setPageNumber={(pageNumber) =>
              setQuery((prev) => {
                return {
                  ...prev,
                  pageNumber,
                };
              })
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
