import { useLocation } from "react-router";
import { useCallback, useEffect, useRef } from "react";

export default function useGetLocation() {
  const location = useLocation();
  const ref = useRef(location);

  useEffect(() => {
    ref.current = location;
  }, [location]);

  return useCallback(() => {
    return ref.current;
  }, []);
}
