import { StoredObject } from "@/features/types";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import AppButton from "@/features/line-sheet-sets/app-button";
import SVG from "react-inlinesvg";
import { Flex } from "@chakra-ui/react";
import LineSheetFileInput from "@/features/line-sheet-sets/line-sheet-file-input";
import React, { useCallback } from "react";
import { LineSheetFile } from "@/features/line-sheet-sets/line-sheet-file-controller";
import AppFileInput from "@/features/inventories/app-file-input";

interface AppFileControllerProps {
  value: (File | Pick<StoredObject, "id" | "name">)[];
  onChange: (value: (File | Pick<StoredObject, "id" | "name">)[]) => void;
  isMultiple?: boolean;
  isDisabled?: boolean;
}
export default function AppFileController({
  value,
  onChange,
  isMultiple,
  isDisabled,
}: AppFileControllerProps) {
  const handleFilesAdd = useCallback(
    (files: File[] | File) => {
      const addFiles = Array.isArray(files) ? files : [files];
      onChange([...value, ...addFiles]);
    },
    [onChange, value]
  );

  const withOnChange = useCallback(
    (index: number) => {
      return (file: File) => {
        const newValues = [...value];
        newValues[index] = file;
        onChange(newValues);
      };
    },
    [value, onChange]
  );

  const withOnDelete = useCallback(
    (index: number) => {
      return () => {
        const newValues = [...value];
        newValues.splice(index, 1);
        onChange(newValues);
      };
    },
    [value, onChange]
  );

  return (
    <Flex flexDirection={"column"} gap={"12px"} width={"100%"}>
      {!isDisabled && (
        <AppFileDragAndDropZone
          isMultiple={isMultiple}
          onFilesAdd={handleFilesAdd}
        />
      )}

      <Flex
        flexDirection={"column"}
        gap={"8px"}
        height={"144px"}
        overflowY={"scroll"}
        style={{ scrollbarGutter: "stable" }}
        paddingRight={"8px"}
      >
        {value.map((_value, index) => {
          return (
            <AppFileInput
              key={"AppFileInput__" + index}
              value={_value}
              onChange={withOnChange(index)}
              onDelete={withOnDelete(index)}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
