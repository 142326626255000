import {
  getListPaymentsKey,
  ListPaymentsPathParameter,
  ListPaymentsRequest,
} from "@/features/invoices/payment/hooks/use-list-payments";
import { DensePayment } from "@/features/invoices/payment/payment.type";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import {
  ListDensePaymentsRequest,
  ListDensePaymentsResponse,
} from "@/features/invoices/payment/hooks/use-list-dense-payments";

export interface ListAllDensePaymentsRequest
  extends Omit<ListPaymentsRequest, "pageSize" | "pageNumber"> {}

export type ListAllDensePaymentsResponse = DensePayment[];

export interface ListAllDensePaymentsPathParameter
  extends ListPaymentsPathParameter {}

export const listAllDensePaymentsTemplateUrl = `/:by/:companyId/payments/list-all`;

export function getListAllDensePaymentsKey(
  parameter?: ListAllDensePaymentsPathParameter,
  request?: ListAllDensePaymentsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllDensePaymentsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllDensePaymentsKey(
  key: AxiosRequestConfig<ListAllDensePaymentsRequest>
) {
  const urlParser = new UrlParser<ListAllDensePaymentsPathParameter>(
    listAllDensePaymentsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllDensePayments(
  parameter?: ListAllDensePaymentsPathParameter,
  request?: ListAllDensePaymentsRequest,
  config?: SWRConfiguration<ListAllDensePaymentsResponse>
) {
  const vanillaFetcher = useFetcher<
    ListDensePaymentsRequest,
    ListDensePaymentsResponse
  >();

  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListDensePaymentsRequest>) => {
        const { parameter, request } = parseListAllDensePaymentsKey(config);
        return vanillaFetcher(getListPaymentsKey(parameter, request)!);
      },
      (response) => {
        return response.payments;
      }
    );
  }, [vanillaFetcher]);

  return useSWR(
    getListAllDensePaymentsKey(parameter, request),
    fetcher,
    config
  );
}
