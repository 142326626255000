import { AppDialogProps } from "@/features/ui/recoils/app-dialog-state";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import { useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";

export default function AppDialog({
  id,
  title,
  message,
  confirmName,
  onConfirm,
  onCancel,
  size = "xl",
  resolve,
  isDisabled,
}: AppDialogProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation();
  const { closeDialog } = useAppDialog();
  const { error: showError } = useAppToasts();

  function handleOnClose() {
    onClose();
    closeDialog(id);
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function isPromise<T>(value: T | Promise<T>): value is Promise<T> {
    return value instanceof Promise;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      onEsc={() => {
        if (!isLoading) {
          onCancel?.();
          resolve(false);
          handleOnClose();
        }
      }}
      closeOnEsc={false}
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {title}
        </ModalHeader>
        <ModalBody
          display={"flex"}
          gap={"18px"}
          p={"24px 20px"}
          flexDirection={"column"}
        >
          {typeof message === "string" ? (
            <Text as={"h3"} color={"#444440"} fontSize={"12px"}>
              {message}
            </Text>
          ) : (
            message
          )}
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            onClick={() => {
              onCancel?.();
              resolve(false);
              handleOnClose();
            }}
          >
            {toTitle(t("not_now"))}
          </AppButton>
          <AppButton
            variant={"primary"}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClick={async () => {
              if (onConfirm) {
                setIsLoading(true);
                try {
                  await onConfirm();
                  resolve(true);
                  handleOnClose();
                } catch (e) {
                  showError(e);
                } finally {
                  setIsLoading(false);
                }
              } else {
                resolve(true);
                handleOnClose();
              }
            }}
          >
            {confirmName}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
