import {
  RemittanceStatus,
  RemittanceStatuses,
} from "@/features/invoices/invoice.type";
import { useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { useTranslation } from "react-i18next";
import { toLower, toTitle } from "@/utils/case";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppRemittanceStatusSelectorProps {
  value?: RemittanceStatus;
  to?: "AGENCY" | "BOUTIQUE";
  onSelect?: (status?: RemittanceStatus) => void;
  width?: string;
}

export default function AppRemittanceStatusSelect({
  value,
  onSelect,
  to,
  width = "144px",
}: AppRemittanceStatusSelectorProps) {
  const { t, tTitle } = useI18nHelper();

  const nameKey = useMemo(() => {
    switch (to) {
      case "AGENCY":
        return "remittance_status_to_agency";
      case "BOUTIQUE":
        return "remittance_status_to_boutique";
      default:
        return "remittance_status";
    }
  }, [to]);

  const options = useMemo((): AppSelectOption<RemittanceStatus>[] => {
    return RemittanceStatuses.map((status) => {
      return {
        name: toTitle(t(`${nameKey}.${toLower(status)}`)),
        value: status,
      };
    });
  }, [t, nameKey]);

  return (
    <AppSelect
      name={tTitle(nameKey)}
      options={options}
      width={width}
      value={value}
      onSelect={onSelect}
    />
  );
}
