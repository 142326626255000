import axios, {
  AxiosError,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { toCamelKeyObject, toSnakeKeyObject } from "@/utils/case";
import Cookies from "js-cookie";
import { isErrorResponse } from "@/utils/use-fetcher";
import { getJwtPayload } from "@/utils/jwt";
import { TokenRefresher } from "@/utils/token-refresher";
import i18next from "i18next";
import { DateUtils } from "@/features/ui/utils/date-utils";
import { isSerializable } from "@/features/ui/app.type";

export interface ErrorResponse {
  error: ErrorPayload;
}

interface ErrorDetails {}

export interface LocalizedMessage extends ErrorDetails {
  "@type": "LocalizedMessage";
  locale: string;
  message: string;
}

export function isLocalizedMessage(obj: any): obj is LocalizedMessage {
  return (obj as LocalizedMessage).locale !== undefined;
}

export interface ErrorPayload {
  code: number;
  message: string;
  status: string;

  details?: ErrorDetails[];
}

export function isErrorPayload(obj: any): obj is ErrorPayload {
  const payload = obj as ErrorPayload;
  return payload.code !== undefined && payload.status !== undefined;
}

interface Pair<Data = any, Error = any> {
  resolve: (data: Data) => void;
  reject: (err: Error) => void;
}

export const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 30 * 1000,
  headers: {
    common: {
      "ngrok-skip-browser-warning": "1",
    },
  },
  paramsSerializer: (params) => {
    function foo(key: string, value: any): Record<string, any> {
      if (Array.isArray(value)) {
        return value.flatMap((i) => foo(key, i));
      } else if (value instanceof Date) {
        return [[key, DateUtils.toISOStringWithOffset(value)]];
      } else if (isSerializable(value)) {
        return [[key, value.serialize()]];
      } else if (typeof value === "object") {
        return [[key, JSON.stringify(value)]];
      } else {
        return [[key, value]];
      }
    }

    return Object.entries(params)
      .flatMap(([key, value]): Record<string, any> => {
        return foo(key, value);
      })
      .map((i) => {
        const [k, v] = i;
        return `${encodeURIComponent(k)}=${encodeURIComponent(String(v))}`;
      })
      .join("&");
  },
  formSerializer: {
    indexes: null,
  },
});

function isFileOrFiles(value: any) {
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.every((i) => i instanceof File);
    } else {
      return value instanceof File;
    }
  }
  return false;
}

function isDateOrDates(value: any) {
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.every((i) => i instanceof Date);
    } else {
      return value instanceof Date;
    }
  }
}

function isPlainObject(obj: any) {
  // Ensure obj is not null or undefined
  if (obj === null || typeof obj !== "object") {
    return false;
  }

  // Ensure obj's prototype is Object or null
  const proto = Object.getPrototypeOf(obj);
  return proto === Object.prototype || proto === null;
}

function isTokenExpired(buffInMilliseconds: number = 1000 * 15) {
  const payload = getJwtPayload();
  if (payload) {
    const { exp } = payload;
    const now = new Date();
    now.setTime(now.getTime() + buffInMilliseconds);
    return exp.getTime() < now.getTime();
  }
  return false;
}

const tokenRefresher = new TokenRefresher();

const requestInterceptor: Parameters<
  AxiosInterceptorManager<InternalAxiosRequestConfig<any>>["use"]
> = [
  async (config) => {
    if (config.data) {
      let data = config.data;
      // console.dir(data);
      // data = deleteUndefined(data);
      data = toSnakeKeyObject(data);

      if (config.headers["Content-Type"] === "multipart/form-data") {
        //data = deleteEmpty(data);

        if (typeof data === "object") {
          //multipart-form은 1depth만 검사한다
          Object.keys(data).forEach((key) => {
            const value = data[key];
            if (typeof value === "object") {
              if (Array.isArray(value)) {
                // if (
                //   value.every(isPlainObject) ||
                //   value.every((i) => Array.isArray(i))
                // ) {
                //   data[key] = value.map((i) => JSON.stringify(i));
                // }
                if (value.every((i) => Array.isArray(i))) {
                  data[key] = value.map((i) => JSON.stringify(i));
                } else {
                  data[key] = value.map((i) => {
                    if (isPlainObject(i)) {
                      return JSON.stringify(i);
                    } else {
                      return i;
                    }
                  });
                }
              } else if (isPlainObject(value)) {
                data[key] = JSON.stringify(value);
              }
            }
          });
        }

        if (isTokenExpired()) {
          await tokenRefresher.refresh();
        }
      }

      config.data = data;
    }

    if (config.params) {
      // if ("orderBy" in config.params) {
      //   config.params.orderBy = serializeOrderBy(config.params.orderBy);
      // }
      config.params = deleteUndefined(config.params);
      config.params = toSnakeKeyObject(config.params);
    }

    config.params = {
      ...config.params,
      locale: i18next.language,
    };

    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
];

client.interceptors.request.use(...requestInterceptor);

function isTokenExpiredError(error: AxiosError<ErrorResponse>) {
  const data = error.response?.data;
  if (isErrorResponse(data)) {
    const { code, status } = data.error;
    return (
      code === 401 &&
      (status === "JWT_TOKEN_EXPIRED" || status === "JWT_TOKEN_INVALIDATED")
    );
  }
  return false;
}

function deserializeDate(obj: any): typeof obj {
  const auditKeys = [
    "createdAt",
    "updatedAt",
    "issuedAt",
    "validUntil",
    "publishedAt",
    "issuedOn",
    "submittedAt",
    "organizedOn",
    "receivedAt",
    "finalEta",
  ];

  if (Array.isArray(obj)) {
    return obj.map((item) => deserializeDate(item));
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      const value = obj[key];
      if (auditKeys.indexOf(key) > -1 && value) {
        acc[key] = new Date(value);
      } else {
        acc[key] = deserializeDate(value);
      }
      return acc;
    }, {});
  }

  return obj;
}

function deleteUndefined(obj: any): typeof obj {
  if (Array.isArray(obj)) {
    return obj.map((item) => deleteUndefined(item));
  } else if (typeof obj === "object" && obj !== null) {
    if (obj instanceof Date || obj instanceof File || isSerializable(obj)) {
      return obj;
    }
    return Object.keys(obj).reduce((acc: any, key: string) => {
      const value = obj[key];
      if (value !== undefined) {
        acc[key] = deleteUndefined(obj[key]);
      }
      return acc;
    }, {});
  }

  return obj;
}

const responseInterceptor: Parameters<
  AxiosInterceptorManager<AxiosResponse<any>>["use"]
> = [
  (response) => {
    if (response.data) {
      response.data = toCamelKeyObject(response.data);
      response.data = deserializeDate(response.data);
    }

    if (isTokenContainsUrl(response.config.url)) {
      Cookies.set("access_token", response.data.accessToken);
      Cookies.set("refresh_token", response.data.refreshToken);
    } else if (isSignOut(response.config)) {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (isTokenExpiredError(error) && !originalRequest._retry) {
      await tokenRefresher.refresh();
      originalRequest._retry = true;
      return await client(originalRequest);
    }

    if (error.config && error.config.url === "/auth/refresh") {
      throw {
        error: {
          code: 401,
          status: "JWT_TOKEN_REFRESH_FAILED",
          message:
            "Oops! We're having trouble keeping you signed in right now. Please Sign in again",
        },
      };
    }

    if (isErrorResponse(error.response?.data)) {
      throw error.response.data;
    }

    throw error;
  },
];

client.interceptors.response.use(...responseInterceptor);

function isTokenContainsUrl(url: string | undefined) {
  const candidates = ["/auth", "/auth/register", "/auth/refresh"];
  return url && candidates.indexOf(url) > -1;
}

function isSignOut(config: AxiosRequestConfig) {
  return config.url === "/auth" && config.method === "delete";
}

function isAuthenticatedOnly(url: string) {
  const whiteList: (string | RegExp | ((url: string) => boolean))[] = [
    "/sign-up",
    "/sign-in",
    "/auth/refresh",
    "/auth",
  ];

  return !whiteList.find((i) => {
    if (typeof i === "string") {
      const expr = i.replace(/{[\w\d_-]+}/g, "[wd-_]+");
      return new RegExp(expr, "gi").test(url);
    } else if (i instanceof RegExp) {
      return i.test(url);
    } else if (typeof i === "function") {
      const func = i as (url: string) => boolean;
      return func(url);
    }
    return false;
  });
}
