import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useDownloadUnoFile from "@/hooks/use-download-uno-file";
import { useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isFile, StoredObject } from "@/features/types";

interface AppDownloadButtonProps {
  value?: number | File | { id: number };
}

export default function AppDownloadButton({ value }: AppDownloadButtonProps) {
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const unoFileId = useMemo(() => {
    if (value) {
      if (typeof value === "number") {
        return value;
      } else if (isFile(value)) {
        return undefined;
      } else {
        return value.id;
      }
    }
  }, [value]);

  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    isLoading: downloadUnoFileIsLoading,
    clear: clearDownloadUnoFile,
    error: downloadUnoFileError,
  } = useDownloadUnoFile();

  useEffect(() => {
    if (downloadUnoFileData) {
      clearDownloadUnoFile();
    }
  }, [downloadUnoFileData, clearDownloadUnoFile]);

  useEffect(() => {
    if (downloadUnoFileError) {
      showError(downloadUnoFileError);
      clearDownloadUnoFile();
    }
  }, [clearDownloadUnoFile, downloadUnoFileError, showError]);

  return (
    <AppButton
      isDisabled={!unoFileId}
      isLoading={downloadUnoFileIsLoading}
      onClick={() => {
        if (unoFileId) {
          fireDownloadUnoFile(
            {
              unoFileId,
            },
            {}
          );
        }
      }}
    >
      {tTitle("download")}
    </AppButton>
  );
}
