import useDumbTransaction from "@/features/ui/hooks/use-dumb-transaction";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { isFile, StoredObject } from "@/features/types";
import {
  createCreditInvoiceKey,
  CreateCreditInvoicePathParameter,
  CreateCreditInvoiceRequest,
} from "@/features/invoices/credit-invoices/hooks/use-create-credit-invoice";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import { useCallback } from "react";
import useUpdateCreditInvoice, {
  updateCreditInvoiceKey,
  UpdateCreditInvoicePathParameter,
  UpdateCreditInvoiceRequest,
} from "@/features/invoices/credit-invoices/hooks/use-update-credit-invoice";
import { calcYWRUrl } from "@/features/ui/helpers/fetcher-helpers";
import useCreateCreditInvoiceInfinite from "@/features/invoices/credit-invoices/hooks/use-create-credit-invoice-infinite";

function mutateCreditInvoices(creditInvoiceId?: number) {
  if (creditInvoiceId) {
    mutateIfMatched(
      new RegExp(
        `/\/(agencies|buyers)\/\d+\/(dense-)?credit-invoices\/${creditInvoiceId}/`
      )
    );
  }
  mutateIfMatched(/\/(agencies|buyers)\/\d+\/(dense-)?credit-invoices/);
}

export default function useCreditInvoiceDumbTransaction() {
  const { addDumbTransactions, isDumbTransactionQueued } = useDumbTransaction();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { fire: fireUpdateCreditInvoice } = useUpdateCreditInvoice();
  const { fire: fireCreateCreditInvoice } = useCreateCreditInvoiceInfinite();

  const addCreate = useCallback(
    (
      parameter: CreateCreditInvoicePathParameter,
      request: Omit<CreateCreditInvoiceRequest, "file"> & {
        file: File;
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          return [request.file];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: storeObjectResponses[0],
          };
        },
        fire: fireCreateCreditInvoice,
        keyOrFunc: calcYWRUrl(createCreditInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateCreditInvoices();
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireCreateCreditInvoice, showError]
  );

  const addUpdate = useCallback(
    (
      parameter: UpdateCreditInvoicePathParameter,
      request: Omit<UpdateCreditInvoiceRequest, "file"> & {
        file: File | Pick<StoredObject, "id" | "name">;
      }
    ) => {
      return addDumbTransactions({
        parameter,
        source: request,
        getFilesFunc: (request) => {
          if (isFile(request.file)) {
            return [request.file];
          }
          return [];
        },
        convertFunc: (source, storeObjectResponses) => {
          return {
            ...source,
            file: isFile(source.file) ? storeObjectResponses[0] : source.file,
          };
        },
        fire: fireUpdateCreditInvoice,
        keyOrFunc: calcYWRUrl(updateCreditInvoiceKey, parameter),
        onPostFunc: (res) => {
          mutateCreditInvoices(parameter.creditInvoiceId);
        },
        onErrorFunc: showError,
      });
    },
    [addDumbTransactions, fireUpdateCreditInvoice, showError]
  );

  const isUpdateQueued = useCallback(
    (parameter: UpdateCreditInvoicePathParameter) => {
      return isDumbTransactionQueued(
        calcYWRUrl(updateCreditInvoiceKey, parameter)
      );
    },
    [isDumbTransactionQueued]
  );

  return {
    addCreate,
    addUpdate,
    isUpdateQueued,
    mutateCreditInvoices,
  };
}
