import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import { useCallback, useEffect, useMemo } from "react";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useTranslation } from "react-i18next";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { StoredObject } from "@/features/types";
import { toLower, toSnake, toTitle, toUpper } from "@/utils/case";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import {
  AppGridColumn,
  AppGridField,
} from "@/features/order-sheet-sets/app-grid";
import { Property } from "csstype";
import GridColumn = Property.GridColumn;

interface UseGridProps {}

export default function useAppGrid() {
  const { error: showError } = useAppToasts();
  const {
    t,
    i18n,
    tLocalDateString,
    tLocalDateTimeString,
    tLocalDateTimeString2,
  } = useI18nHelper();

  // API : Download Uno File
  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  // END: API : Download Uno File

  const withDownloadUnoFile = useCallback(
    <T extends GridRow>(
      getter: (row: T) => Pick<StoredObject, "id" | "name"> | null,
      nameKey: string = "download",
      postProcess?: (response: any) => void
    ) => {
      return (row: GridRow) => {
        const file = getter(row as T);
        let segment;
        if (file) {
          segment = findDownloadUnoFileSegment(file.id);
        }
        return {
          name: toTitle(t(nameKey)),
          isDisabled: !file,
          isLoading: segment?.isLoading || false,
          onClick: () => {
            if (file) {
              fireDownloadUnoFile({ unoFileId: file.id }, {}).then(
                (response) => {
                  if (postProcess) {
                    postProcess(response);
                  }
                }
              );
            }
          },
        };
      };
    },
    [findDownloadUnoFileSegment, fireDownloadUnoFile, t]
  );

  // columns
  const rowTypeColumn = useMemo(() => {
    return {
      name: toTitle(t("type")),
      width: "104px",
    };
  }, [t]);

  // fields

  const withRowType = useCallback(
    (rowType: string | ((_row: GridRow) => string)) => {
      return {
        name: "type",
        value: (_row: GridRow) => {
          let key: string;

          if (typeof rowType === "function") {
            key = toLower(rowType(_row));
          } else {
            key = toLower(rowType);
          }

          if (key === "public_comment") {
            return `${toTitle(t(key))} (${t("public_comment.note")})`;
          }

          return toUpper(t(key));
        },
      };
    },
    [t]
  );

  const withLocalDateString = useCallback(
    <T extends GridRow>(name: string, getter: (row: T) => Date | null) => {
      return {
        name,
        value: (row: GridRow) => {
          const date = getter(row as T);
          return tLocalDateString(date);
        },
      };
    },
    [tLocalDateString]
  );

  const withLocalDateTimeString = useCallback(
    <T extends GridRow>(name: string, getter: (row: T) => Date | null) => {
      return {
        name,
        value: (row: GridRow) => {
          const date = getter(row as T);
          return tLocalDateTimeString(date);
        },
      };
    },
    [tLocalDateTimeString]
  );

  const pairs = useMemo((): {
    [key: string]: {
      column: AppGridColumn;
      field: AppGridField;
    };
  } => {
    return {
      season: {
        column: {
          name: toTitle(t("season")),
          width: "64px",
        },
        field: {
          name: "season",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { season: string };
            return row.season;
          },
        },
      },
      title: {
        column: {
          name: toTitle(t("title")),
          width: "1fr",
        },
        field: {
          name: "title",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { name: string };
            return row.name;
          },
        },
      },
    };
  }, [t]);

  const actions = useMemo(() => {
    return {
      withDownloadUnoFile,
    };
  }, [withDownloadUnoFile]);

  const fields = useMemo(() => {
    return {
      withRowType,
      withLocalDateString,
      withLocalDateTimeString,
    };
  }, [withRowType, withLocalDateString, withLocalDateTimeString]);

  const withColumn = useCallback((partial?: Partial<GridColumn>) => {}, []);

  const withLocalDateField = useCallback(() => {}, []);

  const calcLocalDatePair = useCallback(
    (columnKey: string, fieldKey?: string) => {
      return {
        column: {
          name: toTitle(t(toSnake(columnKey))),
          width: "64px",
        },
        field: {
          name: columnKey,
          value: (_row: GridRow) => {
            const fieldCandidates = [columnKey];

            if (columnKey.endsWith("On")) {
              fieldCandidates.push(
                columnKey.substring(0, columnKey.length - 2) + "At"
              );
            }

            if (fieldKey) {
              fieldCandidates.push(fieldKey);
            }

            const row = _row as GridRow & {
              [key: string]: Date | undefined | null;
            };

            const value = fieldCandidates.map((key) => row[key]).find((v) => v);

            return tLocalDateString(value);
          },
        },
      };
    },
    [t, tLocalDateString]
  );

  const calcLocalDateTime2Pair = useCallback(
    (columnKey: string, fieldKey?: string) => {
      return {
        column: {
          name: toTitle(t(toSnake(columnKey))),
          width: "64px",
        },
        field: {
          name: columnKey,
          value: (_row: GridRow) => {
            const fieldCandidates = [columnKey];

            if (columnKey.endsWith("On")) {
              fieldCandidates.push(
                columnKey.substring(0, columnKey.length - 2) + "At"
              );
            }

            if (fieldKey) {
              fieldCandidates.push(fieldKey);
            }

            const row = _row as GridRow & {
              [key: string]: Date | undefined | null;
            };

            const value = fieldCandidates.map((key) => row[key]).find((v) => v);

            return tLocalDateTimeString2(value);
          },
        },
      };
    },
    [t, tLocalDateTimeString2]
  );

  return {
    actions,
    fields,
    pairs,
    calcLocalDatePair,
    calcLocalDateTime2Pair,
  };
}
