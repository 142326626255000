import { CreditInvoiceFormFields } from "@/features/invoices/credit-invoices/upload-credit-invoice-page";
import { Field, FieldProps, FormikHelpers, FormikProps } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import React, { useCallback } from "react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, FormControl, FormLabel, Grid, Skeleton } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import AppInput from "@/features/ui/app-input";
import AppSeasonSelect from "@/features/line-sheet-sets/app-season-select";
import { Company } from "@/features/types";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import AppTextArea from "@/features/ui/app-text-area";
import AppCreditInvoiceTypeSelector from "@/features/invoices/credit-invoices/app-credit-invoice-type-selector";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppButton from "@/features/line-sheet-sets/app-button";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import CreateCompanyModal, {
  CreateCompanyModalProps,
} from "@/features/ui/create-company-modal";

interface CreditInvoiceInformationFormControlProps {
  formik: FormikProps<CreditInvoiceFormFields>;
  isEditing?: boolean;
}

interface FormFields extends CreditInvoiceFormFields {}

export default function CreditInvoiceInformationFormControl({
  formik,
  isEditing,
}: CreditInvoiceInformationFormControlProps) {
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  type LINK_AFFECTING_KEYS =
    | "season"
    | "issuedBy"
    | "orderedBy"
    | "type"
    | "issuedThrough";

  const handleLinkAffectingFieldChange = useCallback(
    <T extends LINK_AFFECTING_KEYS>(
      key: LINK_AFFECTING_KEYS,
      value: FormFields[T],
      setValues: FormikHelpers<FormFields>["setValues"]
    ) => {
      return setValues((prev) => {
        let next = {
          ...prev,
          [key]: value,
          orderConfirmationInvoices: [],
          earningProformaInvoices: [],
        };
        return next;
      });
    },
    []
  );

  const isDisabled = isEditing && formik.values.id !== -1;

  const {
    isOpen: isCreateCompanyModelOpen,
    onClose: onCreateCompanyModalClose,
    data: createCompanyModalData,
    openWithData: openCreateCompanyModal,
  } = useDisclosureWithData<CreateCompanyModalProps>();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Grid
          rowGap={"8px"}
          gridTemplateRows={"repeat(7, 16px 24px) repeat(2, 16px 60px)"}
        >
          {(Array(18).fill(0) as number[]).map((_, index) => {
            return <Skeleton key={index} />;
          })}
        </Grid>
      </AppPanel>
    );
  }

  return (
    <AppPanel title={tTitle("common:invoice_information.abbr")}>
      <Grid rowGap={"8px"}>
        <Field name={"name"}>
          {(props: FieldProps<FormFields["name"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("title"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"number"}>
          {(props: FieldProps<FormFields["number"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("number"))}
                </FormLabel>
                <AppInput
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  onChange={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"season"}>
          {(props: FieldProps<string, FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("season"))}
                </FormLabel>
                <AppSeasonSelect
                  width={"100%"}
                  value={props.field.value}
                  isDisabled={isDisabled}
                  readonly={!isEditing}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "season",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"type"}>
          {(props: FieldProps<FormFields["type"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
                fontSize={"12px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("type"))}
                </FormLabel>
                <AppCreditInvoiceTypeSelector
                  value={props.field.value}
                  isDisabled={isDisabled}
                  isReadOnly={!isEditing}
                  width={"100%"}
                  onSelect={(value) => {
                    props.form.setFieldValue(props.field.name, value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type === "AGENCY" && (
          <Field name={"orderedBy"}>
            {(props: FieldProps<FormFields["orderedBy"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  isRequired={isEditing}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    padding={"0"}
                    margin={"0"}
                  >
                    {toTitle(t("ordered_by"))}
                  </FormLabel>
                  <AppCompanySelect
                    name={tTitle("ordered_by")}
                    types={["BUYER"]}
                    width={"100%"}
                    id={props.field.value?.id}
                    isReadOnly={!isEditing}
                    isDisabled={isEditing && formik.values.id !== -1}
                    onSelect={(value) => {
                      handleLinkAffectingFieldChange(
                        "orderedBy",
                        value,
                        props.form.setValues
                      );
                    }}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}

        {identity?.company?.type === "BUYER" && (
          <Field name={"issuedThrough"}>
            {(props: FieldProps<FormFields["issuedThrough"], FormFields>) => {
              return (
                <Flex flexDirection={"row"} alignItems={"end"} gap={"8px"}>
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    isRequired={isEditing}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {toTitle(t("issued_through"))}
                    </FormLabel>

                    <AppCompanySelect
                      name={tTitle("issued_through")}
                      types={["AGENCY"]}
                      width={"100%"}
                      id={props.field.value?.id}
                      isReadOnly={!isEditing}
                      isDisabled={isEditing && formik.values.id !== -1}
                      onSelect={(value) => {
                        handleLinkAffectingFieldChange(
                          "issuedThrough",
                          value,
                          props.form.setValues
                        );
                      }}
                    />
                  </FormControl>
                  {isEditing && (
                    <AppButton
                      isDisabled={isEditing && formik.values.id !== -1}
                      onClick={() => {
                        openCreateCompanyModal(() => {
                          return {
                            targetBy: "AGENCY",
                            onCreate: (value) => {
                              handleLinkAffectingFieldChange(
                                "issuedThrough",
                                value,
                                props.form.setValues
                              );
                            },
                          };
                        });
                      }}
                    >
                      {tTitle("add")}
                    </AppButton>
                  )}
                </Flex>
              );
            }}
          </Field>
        )}

        <Field name={"issuedBy"}>
          {(props: FieldProps<Company, FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                isRequired={isEditing}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("issued_by"))}
                </FormLabel>
                <AppCompanySelect
                  name={toTitle(t("issued_by"))}
                  types={["BOUTIQUE"]}
                  width={"100%"}
                  id={props.field.value?.id}
                  isDisabled={isDisabled}
                  isReadOnly={!isEditing}
                  onSelect={(value) => {
                    handleLinkAffectingFieldChange(
                      "issuedBy",
                      value,
                      props.form.setValues
                    );
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        <Field name={"issuedOn"}>
          {(props: FieldProps<Date, FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                isRequired={isEditing}
                gap={"4px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {toTitle(t("issued_on"))}
                </FormLabel>
                <AppDatePicker
                  width={"100%"}
                  showTimeInput={false}
                  name={tTitle("issued_on")}
                  value={props.field.value}
                  isReadOnly={!isEditing}
                  isDisabled={isDisabled}
                  onChange={(value) => {
                    props.form.setFieldValue("issuedOn", value);
                  }}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type === "AGENCY" && (
          <>
            <Field name={"emailThreadMessageId"}>
              {(
                props: FieldProps<
                  FormFields["emailThreadMessageId"],
                  FormFields
                >
              ) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                  >
                    <FormLabel
                      fontSize={"12px"}
                      color={"#6F6F6C"}
                      padding={"0"}
                      margin={"0"}
                    >
                      {t(
                        "credit_invoices:upload_credit_invoices_form.field.email_thread_message_id.label"
                      )}
                    </FormLabel>
                    <AppTextArea
                      fontSize={"12px"}
                      value={props.field.value}
                      rows={1}
                      placeholder={
                        isEditing
                          ? t(
                              "credit_invoices:upload_credit_invoices_form.field.email_thread_message_id.placeholder"
                            )
                          : undefined
                      }
                      onChange={(e) => {
                        props.form.setFieldValue(
                          props.field.name,
                          e.target.value
                        );
                      }}
                      isReadOnly={!isEditing}
                      isDisabled={
                        isDisabled || props.form.values.sendAsNewEmail
                      }
                    />
                  </FormControl>
                );
              }}
            </Field>

            <Field name={"sendAsNewEmail"}>
              {(
                props: FieldProps<FormFields["sendAsNewEmail"], FormFields>
              ) => {
                return (
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                  >
                    <AppCheckBox
                      isChecked={props.field.value}
                      isDisabled={isDisabled}
                      name={toTitle(
                        t(
                          "upload_credit_invoices_form.field.send_as_new_mail",
                          {
                            ns: "credit_invoices",
                          }
                        )
                      )}
                      onChange={(value) => {
                        props.form.setValues((prev) => {
                          return {
                            ...prev,
                            sendAsNewEmail: value,
                            emailThreadMessageId: value
                              ? ""
                              : prev.emailThreadMessageId,
                          };
                        });
                      }}
                    />
                  </FormControl>
                );
              }}
            </Field>
          </>
        )}

        <Field name={"publicComment"}>
          {(props: FieldProps<FormFields["publicComment"], FormFields>) => {
            return (
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
              >
                <FormLabel
                  fontSize={"12px"}
                  color={"#6F6F6C"}
                  padding={"0"}
                  margin={"0"}
                >
                  {identity?.company?.type === "AGENCY"
                    ? `${toTitle(t("public_comment"))}(${t(
                        "public_comment.note"
                      )})`
                    : `${toTitle(t("comment"))}`}
                </FormLabel>
                <AppTextArea
                  flexGrow={"1"}
                  fontSize={"12px"}
                  rows={4}
                  placeholder={isEditing ? t("comment_placeholder") : undefined}
                  value={props.field.value || ""}
                  onChange={(e) => {
                    props.form.setFieldValue("publicComment", e.target.value);
                  }}
                  isReadOnly={!isEditing}
                />
              </FormControl>
            );
          }}
        </Field>

        {identity?.company?.type === "AGENCY" && (
          <Field name={"privateComment"}>
            {(props: FieldProps<FormFields["privateComment"], FormFields>) => {
              return (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel
                    fontSize={"12px"}
                    color={"#6F6F6C"}
                    marginBottom={"4px"}
                  >
                    {toTitle(t("private_comment"))}
                  </FormLabel>
                  <AppTextArea
                    flexGrow={"1"}
                    minHeight={"0"}
                    fontSize={"12px"}
                    rows={4}
                    placeholder={
                      isEditing ? t("comment_placeholder") : undefined
                    }
                    value={props.field.value || ""}
                    onChange={(e) => {
                      props.form.setFieldValue(
                        "privateComment",
                        e.target.value
                      );
                    }}
                    isReadOnly={!isEditing}
                  />
                </FormControl>
              );
            }}
          </Field>
        )}
      </Grid>
      {isCreateCompanyModelOpen && createCompanyModalData && (
        <CreateCompanyModal
          isOpen={isCreateCompanyModelOpen}
          onClose={onCreateCompanyModalClose}
          {...createCompanyModalData}
        />
      )}
    </AppPanel>
  );
}
