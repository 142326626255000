import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import useFetcher from "@/utils/use-fetcher";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { TriangularTrade } from "@/features/invoices/triangular-trades/triangular-trade.type";

export interface ReparseInvoiceRequest {}

export interface ReparseInvoiceResponse extends TriangularTrade {}

export interface ReparseInvoiceParameter {
  by: string;
  companyId: number;
  invoiceId: number;
}

export const reparseInvoiceTemplateUrl =
  "/:by/:companyId/triangular-trades/:invoiceId/reparse";

export const reparseInvoiceKey: YWRKey<ReparseInvoiceParameter> = {
  url: (parameter) => {
    return calcUrl(reparseInvoiceTemplateUrl, parameter);
  },
  method: "post",
};

export function parseReparseInvoiceKey(url: string) {
  const urlParser = new UrlParser<ReparseInvoiceParameter>(
    reparseInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      invoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReparseInvoice() {
  const fetcher = useFetcher<ReparseInvoiceRequest, ReparseInvoiceResponse>();

  return useYWR(reparseInvoiceKey, fetcher);
}
