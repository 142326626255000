import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { OrderConfirmationInvoiceRow } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-row.type";
import { GridMapper } from "@/features/ui/grid-row/gird-mapper";

export const orderConfirmationRowMapper = new GridMapper<
  OrderConfirmationInvoice,
  OrderConfirmationInvoiceRow
>(
  "OrderConfirmationInvoice",
  (invoice) => {
    return {
      ...invoice,
    };
  },
  (row) => {
    return {
      ...row,
    };
  }
);
