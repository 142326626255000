import { OrderConfirmationInvoiceFormFields } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import AppPriceInput from "@/features/invoices/app-price-input";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import AppDetailBrandSelect from "@/features/invoices/app-detail-brand-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface OrderConfirmationInvoiceDetailInputProps {
  value: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"][number];
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onChange: (
    value?: OrderConfirmationInvoiceFormFields["orderConfirmationInvoiceDetailList"][number]
  ) => void;
  index: number;
}

export default function OrderConfirmationInvoiceDetailInput({
  value,
  isDisabled,
  isReadOnly,
  onChange,
  index,
}: OrderConfirmationInvoiceDetailInputProps) {
  const { t } = useI18nHelper();
  // console.dir(value.requiredAmount);

  return (
    <React.Fragment>
      <AppDetailBrandSelect
        value={value}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSearchable={true}
        onSelect={(detailBrand) => {
          onChange({
            ...value,
            ...detailBrand,
          });
        }}
      />
      <Flex flexDirection={"column"} gap={"4px"}>
        {index === 0 && (
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("amount_by_brand"))}
          </Box>
        )}

        <AppPriceInput
          value={value.amount}
          onChange={(amount) => {
            onChange({ ...value, amount });
          }}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
      </Flex>
      <Flex flexDirection={"column"} gap={"4px"}>
        {index === 0 && (
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("deposit_required_amount_by_brand.abbr"))}
          </Box>
        )}
        <AppPriceInput
          value={value.requiredAmount}
          isReadOnly={isReadOnly}
          isDisabled={!isReadOnly}
        />
      </Flex>
      {isDisabled || isReadOnly ? (
        <Box></Box>
      ) : (
        <Flex
          height={"24px"}
          flexDirection={"row"}
          alignSelf={"end"}
          alignItems={"center"}
        >
          <AppIconButton
            ariaLabel={"delete"}
            icon={"x"}
            size={"small"}
            onClick={() => {
              onChange(undefined);
            }}
          />
        </Flex>
      )}
    </React.Fragment>
  );
}
