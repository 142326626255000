import useIdentity from "@/features/ui/hooks/use-identity";
import React, { useCallback, useEffect, useMemo } from "react";
import useGetDensePayment, {
  GetDensePaymentPathParameter,
  GetDensePaymentRequest,
} from "@/features/invoices/hooks/use-get-dense-payment";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import { Formik } from "formik";
import usePaymentFormValidationSchema from "@/features/invoices/payment/hooks/use-payment-form-validation-schema";
import InvoiceDetailPageHeader from "@/features/invoices/bank-remittance-receipts/invoice-detail-page-header";
import { Grid } from "@chakra-ui/react";
import PaymentForm from "@/features/invoices/payment/payment-form";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";
import { usePaymentDumbTransaction } from "@/features/invoices/payment/hooks/use-payment-dumb-transaction";
import useUpdatePayment, {
  UpdatePaymentRequest,
} from "@/features/invoices/payment/hooks/use-update-payment";
import { isStoredObject, StoredObject } from "@/features/types";
import PriceUtils from "@/features/ui/utils/price-utils";
import useReadResource from "@/features/invoices/proforma-invoices/hooks/use-read-resource";
import { useReadPayment } from "@/features/invoices/payment/hooks/use-read-payment";

interface PaymentDetailPageProps {
  isEditing?: boolean;
}

export default function PaymentDetailPage({
  isEditing = false,
}: PaymentDetailPageProps) {
  const identity = useIdentity();
  const { paymentId } = useRequiredParams<{ paymentId: number }>();
  const { error: showError } = useAppToasts();
  const { tTitle } = useI18nHelper();

  const getPaymentKey = useMemo(():
    | {
        parameter: GetDensePaymentPathParameter;
        request: GetDensePaymentRequest;
      }
    | undefined => {
    if (identity?.company && paymentId) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          paymentId,
        },
        request: {},
      };
    }
  }, [identity, paymentId]);

  const {
    data: payment,
    error: getPaymentError,
    mutate: mutateGetPayment,
  } = useGetDensePayment(getPaymentKey?.parameter, getPaymentKey?.request, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (getPaymentError) {
      showError(getPaymentError);
    }
  }, [getPaymentError, showError]);

  const validateSchema = usePaymentFormValidationSchema();

  const validate = useCallback(
    async (values: PaymentFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const initialValues = useMemo((): PaymentFormFields => {
    if (payment) {
      return {
        amount: payment.amount,
        id: payment.id,
        status: payment.status,
        isFeeIncluded: payment.isFeeIncluded,
        isRemitted: payment.isRemitted,
        issuedOn: payment.createdAt,
        issuedThrough: payment.issuedThroughCompany,
        krwInvoiceFiles: payment.krwInvoiceFiles || undefined,
        name: payment.name,
        remittedAmount: payment.remittedAmount,
        unremittedAmount: payment.unremittedAmount,
        orderConfirmationInvoices: payment.orderConfirmationInvoices,
        orderedBy: payment.issuedToCompany,
        privateComment: payment.privateComment || undefined,
        proformaInvoices: payment.proformaInvoices,
        publicComment: payment.publicComment || undefined,
        sender: payment.sender,
        season: payment.season,
        receiver: payment.receiver,
        bankRemittanceReceipts: payment.bankRemittanceReceipts,
      };
    }
    return {
      isFeeIncluded: false,
      isRemitted: false,
      orderConfirmationInvoices: [],
      proformaInvoices: [],
      bankRemittanceReceipts: [],
    };
  }, [payment]);

  const { isUpdateQueued, handleSubmit } = useDumbMixedUpdate({
    dumbFunction: usePaymentDumbTransaction,
    smartFunction: useUpdatePayment,
    isSmart: (request: any): request is UpdatePaymentRequest => {
      return request.krwInvoiceFiles?.every((krwInvoice: any) => {
        return isStoredObject(krwInvoice.file);
      });
    },
    toRequest: (values: PaymentFormFields) => {
      const request: Omit<UpdatePaymentRequest, "krwInvoiceFiles"> & {
        krwInvoiceFiles:
          | {
              invoices: {
                id: number;
                type: "ORDER_CONFIRMATION_INVOICE" | "PROFORMA_INVOICE";
              }[] | null,
              file: File | Pick<StoredObject, "id" | "name" | "createdAt">;
            }[]
          | null;
      } = {
        id: values.id!,
        amount: PriceUtils.placeholder(values.amount, "EUR"),
        isFeeIncluded: values.isFeeIncluded,
        issuedThroughCompany: values.issuedThrough!,
        issuedToCompany: values.orderedBy!,
        orderConfirmationInvoices: values.orderConfirmationInvoices.map(
          (ocInvoice) => ({ id: ocInvoice.id })
        ),
        proformaInvoices: values.proformaInvoices.map((pInvoice) => ({
          id: pInvoice.id,
        })),
        privateComment: values.privateComment || null,
        publicComment: values.publicComment || null,
        receiver: values.receiver!,
        season: values.season!,
        sender: values.sender!,
        status: values.status!,
        //@ts-ignore
        krwInvoiceFiles: values.krwInvoiceFiles || null,
      };
      return request;
    },
    mutate: mutateGetPayment,
    defaultRedirectUrl: `/payments/${paymentId}`,
  });

  useReadResource({
    ywrFunc: useReadPayment,
    resource: payment,
    when: () => identity?.company?.type === "BUYER",
    mutate: mutateGetPayment,
  });

  return (
    <Formik<PaymentFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          return await handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              paymentId: values.id!,
            },
            values,
            formikHelpers
          );
        } else {
          return false;
        }
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <InvoiceDetailPageHeader
              isEditing={isEditing}
              formik={props}
              isUpdateQueued={
                identity?.company && props.values.id
                  ? isUpdateQueued({
                      by: identity.company.type,
                      companyId: identity.company.id,
                      paymentId: props.values.id,
                    })
                  : false
              }
              title={tTitle("payment")}
              baseUrl={"/payments"}
            />
            <PaymentForm formik={props} isEditing={isEditing} />
          </Grid>
        );
      }}
    </Formik>
  );
}
