import {
  ListPaymentsRequest,
  ListPaymentsResponse,
} from "@/features/invoices/payment/hooks/use-list-payments";
import { CompanyType, PageNumberBasedListResponse } from "@/features/types";
import { DensePayment } from "@/features/invoices/payment/payment.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { AxiosRequestConfig } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import useListDensePaymentsFetcher from "@/features/invoices/payment/hooks/use-list-dense-payments-fetcher";

export interface ListDensePaymentsRequest extends ListPaymentsRequest {}

export interface ListDensePaymentsResponse extends PageNumberBasedListResponse {
  payments: DensePayment[];
}

export interface ListDensePaymentsPathParameter {
  by: CompanyType;
  companyId: number;
}

export const listDensePaymentsTemplateUrl = "/:by/:companyId/dense-payments";

export function getListDensePaymentsKey(
  parameter?: ListDensePaymentsPathParameter,
  request?: ListDensePaymentsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listDensePaymentsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListDensePaymentsKey(
  key: AxiosRequestConfig<ListDensePaymentsRequest>
) {
  const urlParser = new UrlParser<ListDensePaymentsPathParameter>(
    listDensePaymentsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params,
  };
}

export function useListDensPayments(
  parameter?: ListDensePaymentsPathParameter,
  request?: ListDensePaymentsRequest,
  config?: SWRConfiguration<ListDensePaymentsResponse>
) {
  const fetcher = useListDensePaymentsFetcher();
  return useSWR(getListDensePaymentsKey(parameter, request), fetcher, config);
}
