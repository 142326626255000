import useFetcher from "@/utils/use-fetcher";
import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import {
  readLineSheetSetKey,
  ReadLineSheetSetRequest,
  ReadLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-read-line-sheet-set";

export default function useReadLineSheetSetInfinite() {
  const fetcher = useFetcher<
    ReadLineSheetSetRequest,
    ReadLineSheetSetResponse
  >();

  return useYWRInfinite(readLineSheetSetKey, fetcher);
}
