import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Inventory } from "@/features/inventories/inventory.type";
import { isFile, StoredObject } from "@/features/types";
import { Flex, FormLabel, Input } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import AppFileController from "@/features/inventories/app-file-controller";
import * as Yup from "yup";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useInventoryDumbTransaction } from "@/features/inventories/hooks/use-inventory-dumb-transaction";
import useIdentity from "@/features/ui/hooks/use-identity";
import useUpdateInventory from "@/features/inventories/hooks/use-update-inventory";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppFormModal from "@/features/ui/app-form-modal";

export interface UpdateInventoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  source: Inventory;
  mutate: () => void;
}

interface FormFields {
  name: string;
  comment?: string;
  organizedOn: Date;
  organizedBy?: { id: number };
  files: (StoredObject | File)[];
}

export default function UpdateInventoryModal({
  isOpen,
  onClose,
  source,
  mutate,
}: UpdateInventoryModalProps) {
  const formikRef = useRef<FormikProps<FormFields> | null>(null);
  const { t, tTitle } = useI18nHelper();
  const { withMessage } = useYupHelper({
    prefix: "update_inventory_form",
    ns: "inventories",
  });
  const { error: showError } = useAppToasts({ id: "UPDATE_INVENTORY_FORM" });
  const { addUpdateInventory } = useInventoryDumbTransaction();
  const identity = useIdentity();

  const {
    fire: fireUpdateInventory,
    data: updateInventoryData,
    error: updateInventoryError,
  } = useUpdateInventory();

  const validateSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().label("title").required(withMessage.required),
      files: Yup.array().min(1, withMessage.min),
      organizedBy: Yup.object().required(withMessage.required),
    });
  }, [withMessage]);

  const validate = useCallback(
    async (values: FormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e: any) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  useEffect(() => {
    if (updateInventoryError) {
      showError(updateInventoryError);
    }
  }, [showError, updateInventoryError]);

  useEffect(() => {
    if (updateInventoryData) {
      mutate();
      onClose();
    }
  }, [updateInventoryData, mutate, onClose]);

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      if (identity?.company) {
        if (values.files.some(isFile)) {
          addUpdateInventory(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              inventoryId: source.id,
            },
            {
              name: values.name,
              comment: values.comment || null,
              organizedOn: values.organizedOn,
              files: values.files,
              organizedBy: values.organizedBy!,
            }
          );
          onClose();
          return true;
        } else {
          return fireUpdateInventory(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              inventoryId: source.id,
            },
            {
              name: values.name,
              comment: values.comment || null,
              organizedOn: values.organizedOn,
              organizedBy: values.organizedBy!,
              files: values.files.filter(
                (file): file is StoredObject => !isFile(file)
              ),
            }
          );
        }
      }
      return false;
    },
    [addUpdateInventory, fireUpdateInventory, identity, source, onClose]
  );

  const initialValues = useMemo(() => {
    return {
      name: source.name,
      comment: source.comment || undefined,
      organizedOn: source.organizedOn,
      organizedBy: {
        id: source.organizedBy.id,
      },
      files: source.files,
    };
  }, [source]);

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={async (values, formikHelpers) => {
        return handleSubmit(values);
      }}
      innerRef={formikRef}
    >
      <AppFormModal
        title={"update_inventory"}
        submitName={"edit"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Form>
          <Flex flexDirection={"column"} fontSize={"12px"} gap={"8px"}>
            <Field name={"name"}>
              {(props: FieldProps<string, FormFields>) => {
                return (
                  <Flex flexDirection={"column"}>
                    <FormLabel fontSize={"inherit"} color={"#6F6F6F"}>
                      {tTitle("title")}
                    </FormLabel>
                    <Input
                      borderRadius={"4px"}
                      fontSize={"inherit"}
                      height={"auto"}
                      p={"4px 12px"}
                      autoComplete={"off"}
                      placeholder={t("title_placeholder")}
                      {...props.field}
                    />
                  </Flex>
                );
              }}
            </Field>

            <Field name={"organizedOn"}>
              {(props: FieldProps<Date, FormFields>) => {
                return (
                  <Flex flexDirection={"column"}>
                    <FormLabel fontSize={"inherit"} color={"#6F6F6F"}>
                      {tTitle("organized_on")}
                    </FormLabel>
                    <AppDatePicker
                      name={tTitle("organized_on")}
                      value={props.field.value}
                      showTimeInput={false}
                      onChange={(value) => {
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  </Flex>
                );
              }}
            </Field>

            {identity?.company?.type === "AGENCY" && (
              <Field name={"organizedBy"}>
                {(props: FieldProps<{ id: number }, FormFields>) => {
                  return (
                    <Flex flexDirection={"column"}>
                      <FormLabel fontSize={"inherit"} color={"#6F6F6F"}>
                        {tTitle("organized_by")}
                      </FormLabel>
                      <AppCompanySelect
                        width={"100%"}
                        name={tTitle("organized_by")}
                        types={["BUYER"]}
                        id={props.field.value?.id}
                        onSelect={(company) => {
                          const value = company
                            ? { id: company.id }
                            : undefined;
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    </Flex>
                  );
                }}
              </Field>
            )}

            <Field name={"comment"}>
              {(props: FieldProps<string, FormFields>) => {
                return (
                  <Flex flexDirection={"column"}>
                    <FormLabel fontSize={"inherit"} color={"#6F6F6F"}>
                      {`${tTitle("comment")} (${tTitle("optional")})`}
                    </FormLabel>
                    <Input
                      borderRadius={"4px"}
                      fontSize={"inherit"}
                      height={"auto"}
                      p={"4px 12px"}
                      autoComplete={"off"}
                      placeholder={t("common:comment_placeholder")}
                      {...props.field}
                    />
                  </Flex>
                );
              }}
            </Field>

            <Field name={"files"}>
              {(props: FieldProps<File[], FormFields>) => {
                return (
                  <AppFileController
                    isMultiple={true}
                    value={props.field.value}
                    onChange={(value) => {
                      props.form.setFieldValue(props.field.name, value);
                    }}
                  />
                );
              }}
            </Field>
          </Flex>
        </Form>
      </AppFormModal>
    </Formik>
  );
}
