import { CompanyType, IdentityParameter } from "@/features/types";
import { Identity } from "@/features/ui/hooks/use-identity";
import { getJwtPayload } from "@/utils/jwt";

function isCompany(identity: Identity | null, type: CompanyType) {
  return identity?.company?.type === type;
}

export function isBuyer(identity: Identity | null) {
  return isCompany(identity, "BUYER");
}

export function isAgency(identity: Identity | null) {
  return isCompany(identity, "AGENCY");
}

export function toIdentityParameter(
  identity: Identity | null
): IdentityParameter | null {
  if (identity && identity.company) {
    return {
      companyId: identity.company.id,
      by: identity.company.type,
    };
  }
  return null;
}

export function isIdentity(candidate: any): candidate is Identity {
  const casted = candidate as Identity;
  return casted.id !== undefined && casted.company !== undefined;
}

export function pluralizeCompanyType(type: CompanyType) {
  switch (type) {
    case "AGENCY":
      return "AGENCIES";
    default:
      return type + "s";
  }
}

export function singularizeCompanyType(str: string): CompanyType {
  if (str === "agencies") {
    return "AGENCY";
  } else if (str === "buyers") {
    return "BUYER";
  } else if (str === "boutiques") {
    return "BOUTIQUE";
  }

  throw {
    message: "Sorry",
  };
}

export function getIdentity(rawCompanyId?: string): Identity | null {
  const payload = getJwtPayload();

  if (payload) {
    let company;
    if (rawCompanyId) {
      const companyId = parseInt(rawCompanyId);
      if (!isNaN(companyId)) {
        company = payload.scopes.find((scope) => scope.id === companyId);
      }

      // if (!company) {
      //   throw {
      //     error: {
      //       code: 400,
      //       status: "INVALID_COMPANY_ID",
      //       message: "Invalid company id",
      //     },
      //   };
      // }
    }

    if (!company && payload.scopes.length > 0) {
      company = payload.scopes[0];
    }

    //todo 강제로 company를 설정
    // company = {
    //   id: 1,
    //   type: "BOUTIQUE",
    // };

    return {
      id: payload.sub,
      company: company || null,
    };
  }
  return null;
}
