import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

export default function useStableSearchParams() {
  const [unstableSearchParams, setUnstableSearchParams] = useSearchParams();
  const [searchParams, setSearchParams] =
    useState<URLSearchParams>(unstableSearchParams);
  const unstableSearchParamsRef = useRef(unstableSearchParams);
  const setUnstableSearchParamsRef = useRef(setUnstableSearchParams);

  useEffect(() => {
    if (
      unstableSearchParamsRef.current.toString() !==
      unstableSearchParams.toString()
    ) {
      setSearchParams(unstableSearchParams);
      unstableSearchParamsRef.current = unstableSearchParams;
      setUnstableSearchParamsRef.current = setUnstableSearchParams;
    }
  }, [setUnstableSearchParams, unstableSearchParams]);

  const setStableSearchParams = useCallback(
    (
      valueOrUpdater:
        | URLSearchParams
        | ((prev: URLSearchParams) => URLSearchParams)
    ) => {
      setUnstableSearchParamsRef.current(valueOrUpdater);
      //setSearchParams(valueOrUpdater);
    },
    []
  );

  return [searchParams, setStableSearchParams] as const;
}
