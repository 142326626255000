import { Box, Flex } from "@chakra-ui/react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export interface AppDeletableSelectProps<T> {
  name: string;
  value?: T;
  options: AppSelectOption<T>[];
  onSelect?: (value: T | undefined) => void;
  onDelete?: (value: T) => void;
  width?: string;
  isDisabled?: boolean;
}
export default function AppDeletableSelect<T extends { id: number }>({
  name,
  value,
  options,
  onSelect,
  onDelete,
  width = "100%",
  isDisabled,
}: AppDeletableSelectProps<T>) {
  const { t } = useTranslation();

  return (
    <Flex width={width} gap={"8px"} flexDirection={"row"} alignItems={"center"}>
      <Box flexGrow={"1"}>
        <AppSelect<T>
          name={toTitle(t(name))}
          width={width}
          value={value}
          isDisabled={isDisabled}
          onSelect={onSelect}
          idFunc={(i) => i.id}
          options={options}
          isClearable={false}
        />
      </Box>
      {onDelete && !isDisabled && (
        <AppIconButton
          ariaLabel={"delete"}
          icon={"x"}
          onClick={() => {
            if (value) {
              onDelete(value);
            }
          }}
          size={"small"}
        />
      )}
    </Flex>
  );
}
