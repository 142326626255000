import {
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import { calcUrl, SWRObjectKey } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  BalanceTransaction,
  BalanceTransactionType,
} from "@/features/balance-transaction-status/balance-transaction.type";

export interface ListBalanceTransactionsRequest
  extends PageNumberBasedListRequest {
  season__eq?: string;
  season__in?: string[];
  "issuedToCompany.id__eq"?: number;
  "issuedByCompany.id__eq"?: number;
  type__in?: BalanceTransactionType[];
  type__eq?: BalanceTransactionType;
  transactionAmount__gt?: number;
  transactionAmount__lt?: number;
}

export interface ListBalanceTransactionsResponse
  extends PageNumberBasedListResponse {
  balanceTransactions: BalanceTransaction[];
}

export interface ListBalanceTransactionsPathParameter
  extends IdentityParameter {}

export const listBalanceTransactionsTemplateUrl = (() => {
  let url = "";
  url += "/:by/:companyId/balance-transactions";
  return url;
})();

export function getListBalanceTransactionsKey(
  parameter?: ListBalanceTransactionsPathParameter,
  request?: ListBalanceTransactionsRequest
): SWRObjectKey<ListBalanceTransactionsRequest> | null {
  if (parameter && request) {
    return {
      url: calcUrl(listBalanceTransactionsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

const urlParser = new UrlParser<ListBalanceTransactionsPathParameter>(
  listBalanceTransactionsTemplateUrl,
  {
    by: StringTransformer.parseBy,
    companyId: StringTransformer.parseId,
  }
);

export function parseListBalanceTransactionsKey(
  key: SWRObjectKey<ListBalanceTransactionsRequest>
): {
  parameter: ListBalanceTransactionsPathParameter;
  request: ListBalanceTransactionsRequest;
} {
  return {
    parameter: urlParser.parse(key.url!!),
    request: key.params!!,
  };
}

export const DEFAULT_LIST_BALANCE_TRANSACTIONS_PAGE_SIZE = 10;

export default function useListBalanceTransactions(
  parameter?: ListBalanceTransactionsPathParameter,
  request?: ListBalanceTransactionsRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListBalanceTransactionsRequest,
    ListBalanceTransactionsResponse
  >();
  return useSWR(
    getListBalanceTransactionsKey(parameter, request),
    fetcher,
    config
  );
}
