import { Flex } from "@chakra-ui/react";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";

interface AppDateRangePickerProps {
  value?: {
    from?: Date;
    to?: Date;
  };
  onChange?: (value: { from?: Date; to?: Date }) => void;
  width?: string;
  showTimeInput?: boolean;
  names: [string, string];
}
export default function AppDateRangePicker({
  value,
  onChange,
  width = "104px",
  showTimeInput = false,
  names,
}: AppDateRangePickerProps) {
  return (
    <Flex flexDirection={"row"} gap={"8px"} alignItems={"center"}>
      <AppDatePicker
        value={value?.from}
        width={width}
        maxDate={value?.to}
        name={names[0]}
        showTimeInput={showTimeInput}
        onChange={(date) => {
          if (onChange) {
            onChange({
              ...value,
              from: date,
            });
          }
        }}
      />
      ~
      <AppDatePicker
        value={value?.to}
        width={width}
        showTimeInput={showTimeInput}
        minDate={value?.from}
        name={names[1]}
        onChange={(date) => {
          if (onChange) {
            onChange({
              ...value,
              to: date,
            });
          }
        }}
      />
    </Flex>
  );
}
