import {
  AbstractInvoice,
  isInvoiceStatus,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { toLower, toTitle } from "@/utils/case";
import AppSelect from "@/features/line-sheet-sets/app-select";
import React, { useMemo } from "react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppInvoiceStatusSelectProps {
  value?: AbstractInvoice["status"];
  onSelect: (value?: AbstractInvoice["status"]) => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  excludeStatuses?: AbstractInvoice["status"][];
}
export default function AppInvoiceStatusSelect({
  value,
  onSelect,
  isDisabled,
  isReadOnly,
  excludeStatuses,
}: AppInvoiceStatusSelectProps) {
  const { t } = useI18nHelper();

  const options = useMemo(() => {
    return ["NORMAL", "CANCELED", "COMPLETED", "REPLACED"]
      .filter(isInvoiceStatus)
      .filter((status) => !excludeStatuses?.includes(status))
      .map((value) => {
        return {
          name: toTitle(t(`invoice_status.${toLower(value)}`)),
          value: value,
        };
      });
  }, [excludeStatuses, t]);

  return (
    <AppSelect<AbstractInvoice["status"]>
      width={"100%"}
      isDisabled={isDisabled}
      value={value}
      name={toTitle(t("status"))}
      isReadOnly={isReadOnly}
      options={options}
      onSelect={(value) => {
        onSelect(value);
      }}
    />
  );
}
