import useInventoryGrid from "@/features/inventories/hooks/use-inventory-grid";
import useBuyerInventoryRows from "@/features/inventories/hooks/use-buyer-inventory-rows";
import AppGrid from "@/features/order-sheet-sets/app-grid";
import UpdateInventoryModal from "@/features/inventories/update-inventory-modal";

interface BuyerInventoryGridProps {
  mutateRows: () => void;
}
export default function BuyerInventoryGrid({
  mutateRows,
}: BuyerInventoryGridProps) {
  const { rows } = useBuyerInventoryRows();
  const inventoryGrid = useInventoryGrid({ rows, mutateRows });

  return (
    <>
      <AppGrid rows={rows} metas={[inventoryGrid.metas.withInventory(true)]} />
      {inventoryGrid.disclosureUpdateInventoryModal.isOpen &&
        inventoryGrid.disclosureUpdateInventoryModal.data && (
          <UpdateInventoryModal
            isOpen={inventoryGrid.disclosureUpdateInventoryModal.isOpen}
            onClose={inventoryGrid.disclosureUpdateInventoryModal.onClose}
            {...inventoryGrid.disclosureUpdateInventoryModal.data}
          />
        )}
    </>
  );
}
