import { FormikProps } from "formik";
import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import React, { useCallback, useEffect } from "react";
import { useBeforeUnload } from "react-router-dom";
import BinarySplitView from "@/features/ui/binary-split-view";
import { Box, Flex } from "@chakra-ui/react";
import LineSheetSetInformationFormControl from "@/features/line-sheet-sets/detail/line-sheet-set-information-form-control";
import LineSheetableListFormControl from "@/features/line-sheet-sets/detail/line-sheetable-list-form-control";
import { KeyedMutator } from "swr";
import { GetDenseLineSheetSetResponse } from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import LineSheetSetDetailRightSkeleton from "@/features/line-sheet-sets/detail/line-sheet-set-detail-right-skeleton";
import { FormikUtils } from "@/features/ui/utils/formik-utils";
import ConfirmedConditionPanel from "@/features/line-sheet-sets/detail/confirmed-condition-panel";
import { ElementScrollRestoration } from "@epic-web/restore-scroll";

interface LineSheetSetDetailFormProps {
  formik: FormikProps<LineSheetSetFormFields>;
  isEditing?: boolean;
  mutate?: KeyedMutator<GetDenseLineSheetSetResponse>;
  isRefreshing?: boolean;
  onPublishClick: () => void;
}

export default function LineSheetSetDetailForm({
  formik,
  isEditing = false,
  isRefreshing = false,
  onPublishClick,
  mutate,
}: LineSheetSetDetailFormProps) {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (
      !formik.isSubmitting &&
      FormikUtils.isFieldsDirty(formik.values, formik.initialValues, [
        "lineSheetOrAttachmentList.isChecked",
        "lineSheetOrAttachmentList.isCheckable",
        "lineSheetOrAttachmentList.source",
      ]) &&
      !isEditing
    ) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <LineSheetSetInformationFormControl
              isRefreshing={isRefreshing}
              isEditing={isEditing}
              formikProps={formik}
              onPublishClick={onPublishClick}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"} fontSize={"12px"}>
            <ConfirmedConditionPanel
              orderSheetSet={formik.values.orderSheetSet}
            />
            {formik.values.id ? (
              <LineSheetableListFormControl
                isEditing={isEditing}
                isRefreshing={isRefreshing}
                formik={formik}
                mutate={mutate}
              />
            ) : (
              <LineSheetSetDetailRightSkeleton />
            )}
          </Flex>
        }
        minLeftWidth={420}
        minRightWidth={680}
      />
    </Box>
  );
}
