import {
  Boutique,
  Buyer,
  Company,
  LightCompany,
  Price,
  StoredObject,
} from "@/features/types";
import {
  DenseProformaInvoice,
  ProformaInvoice,
} from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import {
  DenseOrderConfirmationInvoice,
  OrderConfirmationInvoice,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";

export type CreditInvoiceType = "FIXING" | "SEASON";
export const CreditInvoiceTypes: CreditInvoiceType[] = ["FIXING", "SEASON"];

export interface CreditInvoice {
  id: number;
  number: string;
  name: string;
  season: string;
  type: CreditInvoiceType;
  issuedToCompany: Pick<Buyer, "id" | "name">;
  issuedByCompany: Company;
  issuedThroughCompany: LightCompany;
  issuedOn: Date;

  amount: Price;

  orderConfirmationInvoices: Pick<OrderConfirmationInvoice, "id">[];
  earningProformaInvoices: Pick<ProformaInvoice, "id">[];
  usingProformaInvoices: Pick<ProformaInvoice, "id">[];
  file: StoredObject | null;
  publicComment: string | null;
  privateComment: string | null;

  isUsed: boolean;

  createdAt: Date;

  isRead: boolean;

  emailThreadMessageId: string | null;
}

export interface DenseCreditInvoice
  extends Omit<
    CreditInvoice,
    | "orderConfirmationInvoices"
    | "earningProformaInvoices"
    | "usingProformaInvoice"
  > {
  orderConfirmationInvoices: DenseOrderConfirmationInvoice[];
  earningProformaInvoices: DenseProformaInvoice[];
  usingProformaInvoice: DenseProformaInvoice;
}
