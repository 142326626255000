import { useRecoilCallback } from "recoil";
import useCreateInventory, {
  CreateInventoryPathParameter,
  CreateInventoryRequest,
} from "@/features/inventories/hooks/use-create-inventory";
import { dumbTransactionsState } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useEffect } from "react";
import useUpdateInventory, {
  UpdateInventoryPathParameter,
  UpdateInventoryRequest,
} from "@/features/inventories/hooks/use-update-inventory";
import { isFile, isStoredObject, StoredObject } from "@/features/types";
import { mutate } from "swr";
import { createStoreObjectDumbTransaction } from "@/features/ui/utils/dumb-transaction-util";

function mutateIfMatched(regexp: RegExp) {
  mutate((key) => {
    if (key !== null && typeof key === "object" && "url" in key) {
      const result = key.url.match(regexp);
      return result != null;
    }
    return false;
  });
}

function mutateInventories() {
  mutateIfMatched(/\/(agencies|buyers)\/\d+\/inventories/);
}

export function useInventoryDumbTransaction() {
  const { fire: fireCreateInventory, error: createInventoryError } =
    useCreateInventory();
  const { fire: fireUpdateInventory, error: updateInventoryError } =
    useUpdateInventory();

  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const addUpdateInventory = useRecoilCallback(
    ({ set }) =>
      (
        parameter: UpdateInventoryPathParameter,
        request: Omit<UpdateInventoryRequest, "files"> & {
          files: (File | StoredObject)[];
        }
      ) => {
        const [dumbTransactions, promises] = createStoreObjectDumbTransaction(
          request.files.filter((file): file is File => isFile(file))
        );

        set(dumbTransactionsState, (prev) => {
          return [...prev, ...dumbTransactions];
        });

        Promise.all(promises).then(async (responses) => {
          const updateInventoryRequest = {
            ...request,
            files: [
              ...request.files.filter(isStoredObject),
              ...responses.map((response) => {
                return { id: response.id };
              }),
            ],
          };
          await fireUpdateInventory(parameter, updateInventoryRequest);
          mutateInventories();
          //여기서 mutate도해야함
        });
      },
    [fireUpdateInventory]
  );

  const addCreateInventory = useRecoilCallback(
    ({ set }) =>
      (
        parameter: CreateInventoryPathParameter,
        request: Omit<CreateInventoryRequest, "files"> & { files: File[] }
      ) => {
        const [dumbTransactions, promises] = createStoreObjectDumbTransaction(
          request.files
        );

        set(dumbTransactionsState, (prev) => {
          return [...prev, ...dumbTransactions];
        });

        Promise.all(promises).then(async (responses) => {
          const createInventoryRequest = {
            ...request,
            files: responses.map((response) => {
              return { id: response.id };
            }),
          };
          await fireCreateInventory(parameter, createInventoryRequest);
          mutateInventories();
        });
      },
    [fireCreateInventory]
  );

  useEffect(() => {
    if (createInventoryError) {
      showError(createInventoryError);
    }
  }, [showError, createInventoryError]);

  return {
    addCreateInventory,
    addUpdateInventory,
  };
}
