import {
  IdentityParameter,
  PageNumberBasedListRequest,
  PageNumberBasedListResponse,
} from "@/features/types";
import { Inventory } from "@/features/inventories/inventory.type";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import useSWR, { SWRConfiguration } from "swr";

export interface ListInventoriesRequest extends PageNumberBasedListRequest {
  "organizedBy.id__eq"?: number;
  createdAt__gte?: Date;
  createdAt__lt?: Date;
  organizedOn__gte?: Date;
  organizedOn__lte?: Date;
}

export interface ListInventoriesResponse extends PageNumberBasedListResponse {
  inventories: Inventory[];
}

export interface ListInventoriesPathParameter extends IdentityParameter {}

export const listInventoriesTemplateUrl = `/:by/:companyId/inventories`;

export function getListInventoriesKey(
  parameter?: ListInventoriesPathParameter,
  request?: ListInventoriesRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listInventoriesTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export const DEFAULT_LIST_INVENTORIES_PAGE_SIZE = 10;

export default function useListInventories(
  parameter?: ListInventoriesPathParameter,
  request?: ListInventoriesRequest,
  config?: SWRConfiguration<ListInventoriesResponse>
) {
  const fetcher = useFetcher<ListInventoriesRequest, ListInventoriesResponse>();
  return useSWR(getListInventoriesKey(parameter, request), fetcher, config);
}
