import {
  isPriceAdjustedByType,
  PriceAdjustedBy,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { useCallback, useMemo } from "react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { ICellRendererParams } from "ag-grid-community";
import { produce } from "immer";
import PriceAdjustedByTypeSelect from "@/features/order-sheet-sets/price-adjusted-by-type-select";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import {
  FlatProduct,
  FlatSheetKey,
  isOverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface PriceAdjustedByCellProps
  extends ICellRendererParams<
    FlatProduct,
    FlatProduct["priceAdjustedByPartial"]
  > {
  sheetKey: FlatSheetKey;
  tabIndex: number;
  isEditable: boolean;
}

export default function PriceAdjustedByCell({
  rowIndex,
  data,
  value,
  sheetKey,
  tabIndex,
  isEditable,
  api,
}: PriceAdjustedByCellProps) {
  const { getInflatedProductById, updateInflatedProduct } = useInflatedTabs();
  const { tTitle } = useI18nHelper();

  // const inflatedProduct = useMemo(() => {
  //   if (data) {
  //     return getInflatedProductById(sheetKey, tabIndex, data.id);
  //   }
  // }, [sheetKey, tabIndex, getInflatedProductById, data]);

  const inflatedProduct = data
    ? getInflatedProductById(sheetKey, tabIndex, data.id)
    : undefined;

  const handleSelect = useCallback(
    (by?: PriceAdjustedBy["by"]) => {
      if (inflatedProduct && by) {
        const nextData = produce(inflatedProduct, (draft) => {
          if (draft.confirmedPriceAdjustedBy) {
            draft.confirmedPriceAdjustedBy.by = by;
          } else {
            draft.priceAdjustedBy.by = by;
          }
        });
        updateInflatedProduct(sheetKey, tabIndex, nextData);
      }
    },
    [sheetKey, tabIndex, inflatedProduct, updateInflatedProduct]
  );

  const isOrderAllDisabled = useMemo(() => {
    if (inflatedProduct) {
      const stocks = inflatedProduct.stockQuantityWithOptionList;
      const orders = inflatedProduct.orderQuantityWithOptionList;

      return stocks.every((stock, index) => {
        const orderQuantity = orders[index].quantity;
        const stockQuantity = stock.quantity;
        if (stockQuantity === -1) {
          return true;
        } else if (stockQuantity === 0) {
          return true;
        } else {
          return stockQuantity <= orderQuantity;
        }
      });
    }
    return true;
  }, [inflatedProduct]);

  const handleOrderAll = useCallback(() => {
    if (inflatedProduct) {
      const stocks = inflatedProduct.stockQuantityWithOptionList;
      const orders = inflatedProduct.orderQuantityWithOptionList;
      const nextInflatedProduct = produce(inflatedProduct, (draft) => {
        draft.orderQuantityWithOptionList = stocks.map((stock, index) => {
          return {
            name: stock.name,
            quantity:
              stock.quantity > 0 ? stock.quantity : orders[index].quantity,
          };
        });
      });
      updateInflatedProduct(sheetKey, tabIndex, nextInflatedProduct);
    }
  }, [sheetKey, tabIndex, inflatedProduct, updateInflatedProduct]);

  if (rowIndex % 3 === 0) {
    if (isPriceAdjustedByType(value)) {
      return (
        <PriceAdjustedByTypeSelect
          width={"92px"}
          height={"22px"}
          value={value}
          onSelect={handleSelect}
          isDisabled={!isEditable}
        />
      );
    } else if (
      isOverlappedValue<PriceAdjustedBy["by"]>(value, isPriceAdjustedByType)
    ) {
      return (
        <PriceAdjustedByTypeSelect
          width={"92px"}
          height={"22px"}
          value={value.top}
          onSelect={handleSelect}
          isDisabled={!isEditable}
        />
      );
    } else {
      return null;
    }
  } else if (rowIndex % 3 === 1) {
    return null;
  } else if (rowIndex % 3 === 2) {
    return (
      <AppButton
        width={"92px"}
        height={"22px"}
        isDisabled={!isEditable || isOrderAllDisabled}
        onClick={handleOrderAll}
      >
        {tTitle("take_all")}
      </AppButton>
    );
  }
  return null;
}
