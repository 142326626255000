import AppGrid from "@/features/order-sheet-sets/app-grid";
import useInvoiceGrid from "@/features/invoices/hooks/use-invoice-grid";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import { useEffect, useMemo, useState } from "react";
import { BankRemittanceReceiptRow } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-row.type";
import { bankRemittanceReceiptRowMapper } from "@/features/invoices/utils/bank-remittance-receipt-row-mapper";
import useIdentity from "@/features/ui/hooks/use-identity";

interface AgencyBankRemittanceReceiptGridProps {
  resources?: BankRemittanceReceipt[];
  mutateResources: () => void;
}

export default function BankRemittanceReceiptGrid({
  resources,
  mutateResources,
}: AgencyBankRemittanceReceiptGridProps) {
  const identity = useIdentity();
  const [rows, setRows] = useState<BankRemittanceReceiptRow[]>();
  const rowMapper = useMemo(() => {
    return bankRemittanceReceiptRowMapper.copy();
  }, []);

  useEffect(() => {
    setRows((prev) => {
      return rowMapper.toRows(
        identity?.company?.type !== "BUYER"
          ? resources?.map((resource) => {
              return {
                ...resource,
                isRead: false,
              };
            })
          : resources,

        prev
      );
    });
  }, [identity?.company?.type, resources, rowMapper]);

  const invoiceGrid = useInvoiceGrid({
    rows,
    mutateRows: mutateResources,
  });

  const { navigate } = useRouterHelper();

  switch (identity?.company?.type) {
    case "AGENCY":
      return (
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "BankRemittanceReceipt",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.sender.column,
                invoiceGrid.pairs.receiver.column,
                // invoiceGrid.pairs.orderedBy.column,
                // invoiceGrid.pairs.issuedBy.column,
                // invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.amount.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.sender.field,
                invoiceGrid.pairs.receiver.field,
                // invoiceGrid.pairs.orderedBy.field,
                // invoiceGrid.pairs.issuedBy.field,
                // invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.amount.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => (
                <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
              ),
              onClick: (row) => {
                navigate(`/bank-remittance-receipts/${row.id}`);
              },
            },
          ]}
        />
      );
    case "BOUTIQUE":
      return (
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "BankRemittanceReceipt",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.sender.column,
                invoiceGrid.pairs.receiver.column,
                // invoiceGrid.pairs.orderedBy.column,
                // invoiceGrid.pairs.issuedBy.column,
                // invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.amount.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.sender.field,
                invoiceGrid.pairs.receiver.field,
                // invoiceGrid.pairs.orderedBy.field,
                // invoiceGrid.pairs.issuedBy.field,
                // invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.amount.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => (
                <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
              ),
              onClick: (row) => {
                navigate(`/bank-remittance-receipts/${row.id}`);
              },
            },
          ]}
        />
      );
    case "BUYER":
      return (
        <AppGrid
          rows={rows}
          metas={[
            {
              gap: "8px",
              rowType: "BankRemittanceReceipt",
              columns: [
                invoiceGrid.pairs.season.column,
                invoiceGrid.pairs.issuedThrough.column,
                invoiceGrid.pairs.sender.column,
                invoiceGrid.pairs.receiver.column,
                // invoiceGrid.pairs.orderedBy.column,
                // invoiceGrid.pairs.issuedBy.column,
                invoiceGrid.pairs.title.column,
                invoiceGrid.pairs.amount.column,
                invoiceGrid.pairs.issuedOn.column,
              ],
              fields: [
                invoiceGrid.pairs.season.field,
                invoiceGrid.pairs.issuedThrough.field,
                invoiceGrid.pairs.sender.field,
                invoiceGrid.pairs.receiver.field,
                // invoiceGrid.pairs.orderedBy.field,
                // invoiceGrid.pairs.issuedBy.field,
                invoiceGrid.pairs.title.field,
                invoiceGrid.pairs.amount.field,
                invoiceGrid.pairs.issuedOn.field,
              ],
              actions: [invoiceGrid.actions.downloadInvoice],
              indicator: (row) => (
                <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
              ),
              onClick: (row) => {
                navigate(`/bank-remittance-receipts/${row.id}`);
              },
            },
          ]}
        />
      );
    default:
      return null;
  }
}
