import { isStoredObject, StoredObject } from "@/features/types";
import { Field, FieldProps, FormikProps } from "formik";
import { Flex } from "@chakra-ui/react";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import InvoiceFileInput from "@/features/invoices/invoice-file-input";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import { useCallback, useEffect } from "react";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { produce } from "immer";

interface AppFilesFormControlProps<T> {
  isMultiple?: boolean;
  isEditing?: boolean;
  isDisabled?: boolean;
  formik: FormikProps<T>;
  field: keyof T;
  name: string;
  onPreProcess?: (prev: T) => T;
}

type FileOrStoredObject =
  | File
  | Pick<StoredObject, "id" | "name" | "createdAt">;

export default function AppFilesFormControl<T>({
  field,
  isMultiple,
  isDisabled,
  isEditing,
  formik,
  name,
  onPreProcess,
}: AppFilesFormControlProps<T>) {
  const { error: showError } = useAppToasts({ id: "GLOBAL" });

  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  return (
    <Field name={field}>
      {(props: FieldProps<T[typeof field], T>) => {
        return (
          <AppPanel title={name}>
            <Flex flexDirection={"column"} gap={"8px"}>
              {isEditing && (isMultiple || props.field.value === undefined) && (
                <AppFileDragAndDropZone
                  isMultiple={true}
                  onFilesAdd={(value: File[] | File) => {
                    if (isMultiple) {
                      formik.setValues((prev) => {
                        let next: T = {
                          ...prev,
                          [props.field.name]: [
                            // @ts-ignore
                            ...((prev[
                              props.field.name
                            ] as FileOrStoredObject[]) || []),
                            ...(Array.isArray(value) ? value : [value]),
                          ],
                        };

                        next = onPreProcess ? onPreProcess(next) : next;
                        return next;
                      });
                    } else {
                      formik.setValues((prev) => {
                        let next: T = {
                          ...prev,
                          [props.field.name]: value,
                        };

                        next = onPreProcess ? onPreProcess(next) : next;
                        return next;
                      });
                    }
                  }}
                />
              )}

              {(props.field.value as FileOrStoredObject[]).map(
                (file, index) => {
                  return (
                    <InvoiceFileInput
                      isReadOnly={!isEditing}
                      isDisabled={isDisabled}
                      key={index}
                      value={file}
                      onChange={(value) => {
                        props.form.setFieldValue(
                          props.field.name,
                          produce(
                            props.field.value as FileOrStoredObject[],
                            (draft) => {
                              draft[index] = value;
                            }
                          )
                        );
                      }}
                      onDelete={() => {
                        props.form.setFieldValue(
                          props.field.name,
                          produce(
                            props.field.value as FileOrStoredObject[],
                            (draft) => {
                              draft.splice(index, 1);
                            }
                          )
                        );
                      }}
                      isLoading={
                        !isEditing && isStoredObject(file)
                          ? findDownloadUnoFileSegment(file.id)?.isLoading
                          : false
                      }
                      onClick={() => {
                        if (isStoredObject(file)) {
                          fireDownloadUnoFile(
                            {
                              unoFileId: file.id,
                            },
                            {}
                          );
                        }
                      }}
                    />
                  );
                }
              )}
            </Flex>
          </AppPanel>
        );
      }}
    </Field>
  );
}
