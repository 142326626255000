import useFetcher from "@/utils/use-fetcher";
import {
  createOrderSheetSetKey,
  CreateOrderSheetSetPathParameter,
  CreateOrderSheetSetRequest,
  CreateOrderSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-set";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  CreateOrderSheetSeamlesslyRequest,
  CreateOrderSheetSeamlesslyResponse,
  parseCreateOrderSheetSeamlesslyKey,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly";
import {
  createOrderSheetKey,
  CreateOrderSheetPathParameter,
  CreateOrderSheetRequest,
  CreateOrderSheetResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet";
import { createYWRAxiosConfig } from "@/features/ui/helpers/fetcher-helpers";

interface PendingTask<RES> {
  key: string;
  executor: { resolve: (value: RES) => void; reject: (reason: any) => void };
}

class Queue<REQ, RES> {
  private cachedValueByKey = new Map<string, RES>();
  private pendingTasks: PendingTask<RES>[] = [];

  public async add(req: REQ, func: () => Promise<RES>): Promise<RES> {
    const key = JSON.stringify(req);

    if (this.cachedValueByKey.has(key)) {
      return this.cachedValueByKey.get(key)!;
    } else {
      if (this.isPending(key)) {
        return new Promise<RES>((resolve, reject) => {
          this.pendingTasks.push({
            key,
            executor: { resolve, reject },
          });
        });
      } else {
        try {
          const value = await func();
          this.cachedValueByKey.set(key, value);
          this.resolve(key, undefined, value);
          return value;
        } catch (e) {
          this.resolve(key, e, undefined);
          throw e;
        }
      }
    }
  }

  private resolve(key: string, error: any | undefined, res: RES | undefined) {
    this.pendingTasks = this.pendingTasks.reduce(
      (acc: PendingTask<RES>[], t) => {
        if (t.key === key) {
          if (error) {
            t.executor.reject(error);
          } else {
            t.executor.resolve(res!!);
          }
        } else {
          acc.push(t);
        }
        return acc;
      },
      []
    );

    if (res) {
      setTimeout(() => {
        this.cachedValueByKey.delete(key);
      }, 10 * 1000);
    }
  }

  private isPending(key: string) {
    return this.pendingTasks.some((t) => t.key === key);
  }
}

const queue = new Queue<
  { lineSheetSetId: number; lineSheetId: number },
  { orderSheetSetId: number }
>();

export default function useCreateOrderSheetSeamlesslyFetcher() {
  const createOrderSheetSetFetcher = useFetcher<
    CreateOrderSheetSetRequest,
    CreateOrderSheetSetResponse
  >();

  const createOrderSheetFetcher = useFetcher<
    CreateOrderSheetRequest,
    CreateOrderSheetResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<CreateOrderSheetSeamlesslyRequest>
    ): Promise<CreateOrderSheetSeamlesslyResponse> => {
      const { parameter } = parseCreateOrderSheetSeamlesslyKey(config.url!!);

      let orderSheetSetId = parameter.orderSheetSetId;

      if (orderSheetSetId < 0) {
        const result = await queue.add(
          {
            lineSheetSetId: parameter.lineSheetSetId,
            lineSheetId: parameter.lineSheetId,
          },
          async () => {
            const response = await createOrderSheetSetFetcher(
              createYWRAxiosConfig<
                CreateOrderSheetSetPathParameter,
                CreateOrderSheetSetRequest
              >(
                createOrderSheetSetKey,
                {
                  by: parameter.by,
                  companyId: parameter.companyId,
                },
                {
                  lineSheetSet: {
                    id: parameter.lineSheetSetId,
                  },
                }
              )
            );

            return {
              orderSheetSetId: response.id,
            };
          }
        );
        orderSheetSetId = result.orderSheetSetId;
      }

      const createOrderSheetResponse = await createOrderSheetFetcher(
        createYWRAxiosConfig<
          CreateOrderSheetPathParameter,
          CreateOrderSheetRequest
        >(
          createOrderSheetKey,
          {
            by: parameter.by,
            companyId: parameter.companyId,
            orderSheetSetId: orderSheetSetId,
          },
          {
            lineSheet: {
              id: parameter.lineSheetId,
            },
          }
        )
      );

      return Promise.resolve({
        ...createOrderSheetResponse,
        _orderSheetSetId: orderSheetSetId,
      });
    };
  }, [createOrderSheetFetcher, createOrderSheetSetFetcher]);
}
