import useFetcher from "@/utils/use-fetcher";
import {
  getGetLineSheetTabKey,
  GetLineSheetTabRequest,
  GetLineSheetTabResponse,
} from "@/features/line-sheet-sets/hooks/use-get-line-sheet-tab";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  ListAllLineSheetTabsRequest,
  ListAllLineSheetTabsResponse,
  parseListAllLineSheetTabsKey,
} from "@/features/line-sheet-sets/hooks/use-list-all-line-sheet-tabs";
import { uniq } from "lodash";

export default function useListAllLineSheetTabsFetcher() {
  const getLineSheetTabFetcher = useFetcher<
    GetLineSheetTabRequest,
    GetLineSheetTabResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<ListAllLineSheetTabsRequest>
    ): Promise<ListAllLineSheetTabsResponse> => {
      const { parameter, request } = parseListAllLineSheetTabsKey(config);
      const promises = uniq(request.tabIndexes).map((tabIndex) => {
        return getLineSheetTabFetcher(
          getGetLineSheetTabKey({
            by: parameter.by,
            companyId: parameter.companyId,
            lineSheetSetId: parameter.lineSheetSetId,
            lineSheetId: parameter.lineSheetId,
            lineSheetTabIndex: tabIndex,
          })!!
        );
      });

      return Promise.resolve({
        _lineSheetId: parameter.lineSheetId,
        _lineSheetSetId: parameter.lineSheetSetId,
        tabs: await Promise.all(promises),
      });
    };
  }, [getLineSheetTabFetcher]);
}
