import { PreSignedStoredObject } from "@/features/types";
import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import {
  calcUrl,
  SWRObjectKey,
  withPreSignedFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { useMemo } from "react";

export interface DownloadUnoFileRequest {}

export interface DownloadUnoFileResponse extends PreSignedStoredObject {}

export interface DownloadUnoFilePathParameter {
  unoFileId: number;
}

export const downloadUnoFileTemplateUrl = (() => {
  let url = "";
  url += "/uno-files/:unoFileId/presign";
  return url;
})();

export const downloadUnoFileKey: YWRKey<DownloadUnoFilePathParameter> = {
  url: (parameter) => {
    return calcUrl(downloadUnoFileTemplateUrl, parameter);
  },
  method: "post",
};

export function parseDownloadUnoFileKey(
  key: SWRObjectKey<DownloadUnoFileRequest>
): {
  parameter: DownloadUnoFilePathParameter;
  request: DownloadUnoFileRequest;
} {
  const urlParser = new UrlParser<DownloadUnoFilePathParameter>(
    downloadUnoFileTemplateUrl,
    {
      unoFileId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url),
    request: key.params!!,
  };
}

export default function useDownloadUnoFile() {
  const vanillaFetcher = useFetcher<
    DownloadUnoFileRequest,
    DownloadUnoFileResponse
  >();
  const preSignedFetcher = useMemo(() => {
    return withPreSignedFetcher(vanillaFetcher);
  }, [vanillaFetcher]);

  return useYWR<
    DownloadUnoFilePathParameter,
    DownloadUnoFileRequest,
    DownloadUnoFileResponse
  >(downloadUnoFileKey, preSignedFetcher);
}
