import {
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";
import { useRef } from "react";
import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import { TransientFile } from "@/features/types";
import AppFileFormControl from "@/features/line-sheet-sets/app-file-form-control";
import * as Yup from "yup";
import { ValidationError } from "yup";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useIdentity from "@/features/ui/hooks/use-identity";
import { toIdentityParameter } from "@/features/ui/helpers/identity-helpers";
import { useDumbTransactions } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import { useTranslation } from "react-i18next";
import { toTitle } from "@/utils/case";

export interface UploadOrderSheetsFormFields {
  name: string;
  transientFiles: TransientFile[];
}

interface UploadOrderSheetsFormProps {
  orderSheetSetId: number;
  name: string;
}

export interface UploadOrderSheetPayload {
  name: string;
  lineSheetSetType: LineSheetSetType;
  lineSheetSetId: number;
  orderSheetSetId: number;
  lineSheetId: number;
  orderSheetId: number;
  index: number;
}

export const defaultUploadOrderSheetPayload: UploadOrderSheetPayload = {
  name: "",
  lineSheetSetType: "STOCK",
  lineSheetSetId: -1,
  orderSheetSetId: -1,
  lineSheetId: -1,
  orderSheetId: -1,
  index: 0,
};

export interface UploadOrderSheetsModalProps extends UploadOrderSheetPayload {
  isOpen: boolean;
  onClose: () => void;
}

export default function UploadOrderSheetsModal({
  name,
  lineSheetSetType,
  lineSheetSetId,
  orderSheetSetId,
  orderSheetId,
  lineSheetId,
  index,
  isOpen,
  onClose,
}: UploadOrderSheetsModalProps) {
  const identity = useIdentity();
  const isMultiple = lineSheetSetType === "SEASON" && orderSheetId === -1;
  const { add: addAppToast } = useAppToasts();
  const {
    addCreateFallbackOrderSheetsSeamlessly,
    addUpdateFallbackOrderSheet,
  } = useDumbTransactions();
  const { t } = useTranslation();

  const validateSchema = Yup.object({
    orderSheetSetId: Yup.number().min(1),
    transientFiles: Yup.array()
      .min(1)
      .test((value, context) => {
        const transientFiles = value as TransientFile[];
        if (!isMultiple && transientFiles.length > 1) {
          return context.createError({
            message:
              "multiple files are not allowed for creating STOCK order or changing file",
          });
        }
        return true;
      }),
  });
  const formikRef = useRef<FormikProps<UploadOrderSheetsFormFields>>(null);

  const validate = async (values: UploadOrderSheetsFormFields) => {
    try {
      await validateSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      if (e.inner && e.inner.length > 0) {
        const first = e.inner.find((i: ValidationError) => {
          return i.errors.length > 0;
        });
        if (first) {
          addAppToast({ status: "error", message: first.errors[0] });
        }
      }
      return e;
    }
  };

  const onSubmit = (value: UploadOrderSheetsFormFields) => {
    const isCreate = orderSheetId === -1;
    if (isCreate) {
      addCreateFallbackOrderSheetsSeamlessly(
        value.transientFiles.map((v, i) => {
          return [
            {
              by: identity?.company?.type!,
              companyId: identity?.company?.id!!,
              orderSheetSetId,
              lineSheetSetId,
              lineSheetId,
              index: index + i,
            },
            {
              file: v.binary,
            },
          ];
        })
      );
      // addCreateFallbackOrderSheets(
      //   value.transientFiles.map((v, i) => {
      //     return [
      //       {
      //         ...toIdentityParameter(identity!!)!!,
      //         orderSheetSetId,
      //         _lineSheetSetId: lineSheetSetId,
      //         _lineSheetId: lineSheetId,
      //         _index: index + i,
      //       },
      //       {
      //         file: v.binary,
      //         "lineSheet.id": lineSheetId,
      //       },
      //     ];
      //   })
      // );
    } else {
      addUpdateFallbackOrderSheet(
        {
          buyerId: identity?.company?.id!!,
          orderSheetSetId,
          orderSheetId: orderSheetId,
        },
        { uploadedFile: value.transientFiles[0].binary }
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color={"#444440"}
          fontSize={"14px"}
          background={"#F7F9FA"}
          borderBottom={"0.5px solid #8F8F8C"}
        >
          {toTitle(t(isMultiple ? "upload_line_sheets" : "upload_line_sheet"))}
        </ModalHeader>
        <ModalBody>
          <Formik<UploadOrderSheetsFormFields>
            innerRef={formikRef}
            initialValues={{ name, transientFiles: [] }}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            onSubmit={(values, formikHelpers) => {
              onSubmit(values);
              formikHelpers.setSubmitting(false);
              onClose();
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Grid
                    gridTemplateColumns={"1fr 1fr"}
                    gridTemplateRows={"auto auto"}
                    gap={"12px"}
                  >
                    <Field name={"name"}>
                      {({
                        field,
                        form,
                      }: {
                        field: FieldInputProps<string>;
                        form: FormikProps<UploadOrderSheetsFormFields>;
                      }) => {
                        return (
                          <FormControl gridColumn={"1/3"}>
                            <FormLabel fontSize={"12px"} m={"0"}>
                              {toTitle(t("line_sheet_name"))}
                            </FormLabel>
                            <Input
                              mt={"4px"}
                              borderRadius={"4px"}
                              fontSize={"12px"}
                              height={"auto"}
                              p={"4px 12px"}
                              {...field}
                              readOnly={true}
                              isDisabled={true}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name={"transientFiles"}>
                      {({
                        field,
                        form,
                      }: {
                        field: FieldInputProps<TransientFile[]>;
                        form: FormikProps<UploadOrderSheetPayload>;
                      }) => {
                        return (
                          <AppFileFormControl
                            name={"order sheet"}
                            transientFiles={field.value}
                            onChange={(items) =>
                              form.setFieldValue("transientFiles", items, false)
                            }
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter display={"flex"} gap={"12px"}>
          <AppButton
            onClick={() => {
              onClose();
            }}
          >
            {toTitle(t("not_now"))}
          </AppButton>
          <AppButton
            variant={"primary"}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
          >
            {toTitle(t("upload"))}
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
