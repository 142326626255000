import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { Forwarder } from "@/features/invoices/triangular-trades/triangular-trade.type";
import { CompanyType } from "@/features/types";

interface CreateForwarderRequest {
  name: string;
}

interface CreateForwarderResponse extends Forwarder {}

interface CreateForwarderPathParameter {
  by: CompanyType;
  companyId: number;
}

export const createForwarderTemplateUrl = `/:by/:companyId/forwarder`;

export const createForwarderKey: YWRKey<CreateForwarderPathParameter> = {
  url: (parameters: CreateForwarderPathParameter) => {
    return calcUrl(createForwarderTemplateUrl, parameters);
  },
  method: "post",
};

export default function useCreateForwarder() {
  const fetcher = useFetcher<CreateForwarderRequest, CreateForwarderResponse>();
  return useYWR(createForwarderKey, fetcher);
}
