import { FormikProps } from "formik";
import { TransportationFormFields } from "@/features/transportations/upload-transportation-page";
import BinarySplitView from "@/features/ui/binary-split-view";
import { Box, Flex } from "@chakra-ui/react";
import TransportationInformationFormControl from "@/features/transportations/tranportation-information-form-control";
import AppFilesFormControl from "@/features/transportations/app-files-form-control";
import LinkedProformaInvoiceFormControl from "@/features/invoices/proforma-invoices/linked-proforma-invoice-form-control";
import { useCallback, useEffect, useMemo } from "react";
import { ListAllProformaInvoicesRequest } from "@/features/invoices/proforma-invoices/hooks/use-list-all-proforma-invoices";
import TransportationDetailsFormControl from "@/features/transportations/transportation-details-form-control";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useBeforeUnload } from "react-router-dom";
import { getFileNameWithoutExtension } from "@/features/types";

interface TransportationFormProps {
  isEditing?: boolean;
  formik: FormikProps<TransportationFormFields>;
}

export default function TransportationForm({
  formik,
  isEditing = false,
}: TransportationFormProps) {
  const { tTitle } = useI18nHelper();

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formik.isSubmitting || (formik.dirty && isEditing)) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [formik.isSubmitting, formik.dirty, isEditing]
  );

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    //수정 취소시 기본값으로 돌리기
    if (!formik.isSubmitting && formik.dirty && !isEditing) {
      formik.resetForm();
    }
  }, [isEditing, formik]);

  const listProformasRequest = useMemo(():
    | ListAllProformaInvoicesRequest
    | undefined => {
    const [shippedFromList, shippedTo] = [
      formik.values.shippedFromList,
      formik.values.shippedTo,
    ];

    if (shippedFromList.length > 0 && shippedTo) {
      return {
        isRemittedToBoutique__eq: true,
        isPublic__eq: true,
        status__eq: "NORMAL",
        "issuedToCompany.id__eq": shippedTo.id,
        "issuedByCompany.id__in": shippedFromList.map((company) => company.id),
      };
    }

    return undefined;
  }, [formik.values.shippedFromList, formik.values.shippedTo]);

  return (
    <Box padding={"24px"} overflow={"hidden"}>
      <BinarySplitView
        left={
          <Flex flexDirection={"column"} gap={"24px"}>
            <TransportationInformationFormControl
              formik={formik}
              isEditing={isEditing}
            />
            <AppFilesFormControl
              isMultiple={true}
              isEditing={isEditing}
              formik={formik}
              field={"files"}
              name={tTitle("files")}
              onPreProcess={(next) => {
                if (!next.name && next.files.length > 0) {
                  next.name = getFileNameWithoutExtension(next.files[0].name);
                }
                return next;
              }}
            />
          </Flex>
        }
        right={
          <Flex flexDirection={"column"} gap={"24px"}>
            <TransportationDetailsFormControl
              formik={formik}
              isEditing={isEditing}
            />
            <LinkedProformaInvoiceFormControl
              isEditing={isEditing}
              formik={formik}
              request={listProformasRequest}
              onSelect={(value) => {
                formik.setFieldValue("proformaInvoices", value);
              }}
              nameFunc={(pInvoice) => {
                return `${pInvoice.id} / ${pInvoice.name} / ${pInvoice.issuedByCompany.name}`;
              }}
            />
          </Flex>
        }
        minLeftWidth={400}
        minRightWidth={600}
      />
    </Box>
  );
}
