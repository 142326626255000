import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Flex, Skeleton } from "@chakra-ui/react";

export default function IsolatedPanelSkeleton() {
  return (
    <AppPanel title={<Skeleton height={"32px"} />} variant={"ghost"}>
      <Flex flexDirection={"column"} gap={"8px"} alignItems={"center"}>
        <Skeleton height={"32px"} width={"100%"} />
        <Skeleton height={"32px"} width={"98%"} />
        <Skeleton height={"32px"} width={"98%"} />
        <Skeleton height={"32px"} width={"98%"} />
      </Flex>
    </AppPanel>
  );
}
