import { Company, isFile, Price, StoredObject } from "@/features/types";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback, useMemo } from "react";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import BankRemittanceReceiptForm from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-form";
import useYupHelper from "@/features/ui/form/use-yup-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useBankRemittanceReceiptDumbTransaction } from "@/features/invoices/bank-remittance-receipts/hooks/use-bank-remittance-receipt-dumb-transaction";
import useIdentity from "@/features/ui/hooks/use-identity";
import useBankRemittanceReceiptFormValidationSchema from "@/features/invoices/bank-remittance-receipts/hooks/use-bank-remittance-receipt-form-validation-schema";
import { FormikUtils } from "@/features/ui/utils/formik-utils";
import useGetIssuedThroughOrIssuedTo from "@/features/invoices/hooks/use-get-issued-through-or-issued-to";
import { PriceInputValue } from "@/features/invoices/app-price-input";
import AppSubmitButton from "@/features/line-sheet-sets/app-submit-button";
import { DepositInvoice } from "@/features/invoices/deposit-invoices/deposit-invoice.type";
import PriceUtils from "@/features/ui/utils/price-utils";

export interface BankRemittanceReceiptFormFields {
  id?: number;
  name?: string;
  season?: string;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedThrough?: Pick<Company, "id" | "name">;
  issuedOn: Date;
  publicComment?: string;
  privateComment?: string;
  sender?: Company;
  receiver?: Company;
  file?: File | Pick<StoredObject, "id" | "name">;
  amount?: PriceInputValue;
  payments: {
    id: number;
    name: string;
    amount: Price;
    unremittedAmount: Price;
  }[];
  depositInvoice?: DepositInvoice;
  bankRemittanceReceiptDetailList: {
    id: number;
    amount?: PriceInputValue;
    payment?: {
      id: number;
      name: string;
    };
  }[];
}

export default function UploadBankRemittanceReceiptPage() {
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const { withMessage } = useYupHelper({
    ns: "bank_remittance_receipts",
    prefix: "update_bank_remittance_receipt_form",
  });
  const { navigate } = useRouterHelper();

  const validateSchema = useBankRemittanceReceiptFormValidationSchema();

  const validate = useCallback(
    async (values: BankRemittanceReceiptFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { addCreate } = useBankRemittanceReceiptDumbTransaction();

  const handleSubmit = useCallback(
    (values: BankRemittanceReceiptFormFields) => {
      if (identity?.company && isFile(values.file!)) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            season: values.season!,
            issuedToCompany: values.orderedBy!,
            issuedThroughCompany: values.issuedThrough!,
            issuedOn: values.issuedOn!,
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            file: values.file!,
            sender: values.sender!,
            receiver: values.receiver!,
            amount: PriceUtils.placeholder(
              values.amount,
              values.receiver?.type === "BOUTIQUE" ? "EUR" : "KRW"
            ),
            payments: values.payments.map((payment) => {
              return {
                id: payment.id,
              };
            }),
            bankRemittanceReceiptDetailList:
              values.bankRemittanceReceiptDetailList.map((detail) => {
                return {
                  amount: detail.amount as Price,
                  payment: {
                    id: detail.payment!.id,
                    name: detail.payment!.name,
                  },
                };
              }),
          }
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    [addCreate, identity]
  );

  const issuedThroughOrIssuedTo = useGetIssuedThroughOrIssuedTo();

  const initialValues = useMemo((): BankRemittanceReceiptFormFields => {
    return {
      id: -1,
      season: DEFAULT_SEASON,
      issuedOn: FormikUtils.initialIssuedOnFunc(),
      payments: [],
      bankRemittanceReceiptDetailList: [],
      ...issuedThroughOrIssuedTo,
    };
  }, [issuedThroughOrIssuedTo]);

  return (
    <Formik<BankRemittanceReceiptFormFields>
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={(values) => {
        return handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"header"} as={"h2"} fontSize={"14px"}>
                  {tTitle(
                    "bank_remittance_receipts:upload_bank_remittance_receipt"
                  )}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppSubmitButton
                  key={"upload_and_add_another"}
                  addOther={true}
                  formik={props}
                />,
                <AppSubmitButton key={"upload"} formik={props} />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/bank-remittance-receipts"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <BankRemittanceReceiptForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
