import { PaymentFormFields } from "@/features/invoices/payment/upload-payment-page";
import { Field, FieldProps, FormikProps } from "formik";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useDownloadUnoFile from "@/hooks/use-download-uno-file";
import { useEffect, useMemo } from "react";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import { Box, Flex, Grid, Skeleton } from "@chakra-ui/react";
import { getFileNameWithoutExtension, isStoredObject } from "@/features/types";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import React from "react";
import InvoiceFileInput from "@/features/invoices/invoice-file-input";
import AppSelect from "@/features/line-sheet-sets/app-select";

interface KRWInvoiceFileFormControlProps {
  formik: FormikProps<PaymentFormFields>;
  isEditing: boolean;
  isDisabled?: boolean;
}

export default function KRWInvoiceFileFormControl({
  formik,
  isEditing,
  isDisabled = false,
}: KRWInvoiceFileFormControlProps) {
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const {
    fire: fireDownloadUnoFile,
    error: downloadUnoFileError,
    isLoading: isDownloadUnoFileLoading,
  } = useDownloadUnoFile();

  useEffect(() => {
    if (downloadUnoFileError) {
      showError(downloadUnoFileError);
    }
  }, [downloadUnoFileError, showError]);

  const invoiceOptions = useMemo((): AppSelectOption<{
    id: number;
    type: "PROFORMA_INVOICE" | "ORDER_CONFIRMATION_INVOICE";
  }>[] => {
    return [
      ...formik.values.orderConfirmationInvoices.map((ocInvoice) => {
        return {
          name: ocInvoice.number,
          value: {
            id: ocInvoice.id,
            type: "ORDER_CONFIRMATION_INVOICE" as const,
          },
        };
      }),
      ...formik.values.proformaInvoices.map((pInvoice) => {
        return {
          name: pInvoice.number,
          value: {
            id: pInvoice.id,
            type: "PROFORMA_INVOICE" as const,
          },
        };
      }),
    ];
  }, [formik.values.orderConfirmationInvoices, formik.values.proformaInvoices]);

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"24px"} />}>
        <Skeleton height={"48px"} />
      </AppPanel>
    );
  }

  return (
    <Field name={"krwInvoiceFiles"}>
      {(
        props: FieldProps<
          PaymentFormFields["krwInvoiceFiles"],
          PaymentFormFields
        >
      ) => {
        if (
          props.form.values.id !== -1 &&
          !isEditing &&
          (props.field.value || []).length === 0
        ) {
          return null;
        }

        return (
          <Flex flexDirection={"column"} gap={"8px"}>
            <AppPanel
              title={tTitle("payment_files")}
              isCompact={true}
              variant={"ghost"}
            >
              {isEditing && !isDisabled && (
                <AppFileDragAndDropZone
                  isMultiple={true}
                  onFilesAdd={(value: File[] | File) => {
                    props.form.setValues((prev) => {
                      return {
                        ...prev,
                        krwInvoiceFiles: [
                          ...(prev.krwInvoiceFiles || []),
                          ...(Array.isArray(value) ? value : [value]).map(
                            (file) => {
                              return {
                                file,
                              };
                            }
                          ),
                        ],
                      };
                    });
                  }}
                />
              )}
            </AppPanel>
            {(props.field.value || []).length > 0 && (
              <AppPanel>
                <Grid
                  gridTemplateColumns={"120px 1fr"}
                  rowGap={"8px"}
                  columnGap={"8px"}
                >
                  {props.field.value?.map((item, index) => {
                    return (
                      <React.Fragment key={`_${index}`}>
                        <AppSelect<{
                          id: number;
                          type: "PROFORMA_INVOICE" | "ORDER_CONFIRMATION_INVOICE";
                        }>
                          name={"Invoice"}
                          isMultiple={true}
                          options={invoiceOptions}
                          isReadOnly={!isEditing}
                          isDisabled={isDisabled}
                          value={item.invoices == null ? undefined : item.invoices}
                          idFunc={(invoice) => invoice.id}
                          onSelect={(value) => {
                            props.form.setValues((prev) => {
                              return {
                                ...prev,
                                krwInvoiceFiles: prev.krwInvoiceFiles?.map(
                                  (f, i) => {
                                    if (i === index) {
                                      return {
                                        ...f,
                                        invoices: value,
                                      };
                                    }
                                    return f;
                                  }
                                ),
                              };
                            });
                          }}
                        />
                        <InvoiceFileInput
                          value={item.file}
                          onChange={(file) => {
                            props.form.setValues((prev) => {
                              return {
                                ...prev,
                                krwInvoiceFiles: prev.krwInvoiceFiles?.map(
                                  (f, i) => {
                                    if (i === index) {
                                      return {
                                        ...f,
                                        file,
                                      };
                                    }
                                    return f;
                                  }
                                ),
                              };
                            });
                          }}
                          isReadOnly={!isEditing}
                          onDelete={() => {
                            props.form.setValues((prev) => {
                              return {
                                ...prev,
                                krwInvoiceFiles: prev.krwInvoiceFiles?.filter(
                                  (f, i) => i !== index
                                ),
                              };
                            });
                          }}
                          isDisabled={isDisabled}
                          isLoading={isDownloadUnoFileLoading}
                          onClick={() => {
                            if (item.file && isStoredObject(item.file)) {
                              fireDownloadUnoFile(
                                {
                                  unoFileId: item.file.id,
                                },
                                {}
                              );
                            }
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </AppPanel>
            )}
          </Flex>
        );
      }}
    </Field>
  );
}
