import { useLocation } from "react-router";
import { useCallback } from "react";
import { toSnake } from "@/utils/case";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

export default function useNavigateToLineSheetSetPage() {
  const { navigate } = useRouterHelper();
  const location = useLocation();

  return useCallback(
    (lineSheetSetId: number, lineSheetId: number) => {
      const q = Object.entries({
        lineSheetId,
        referer: location.pathname,
      })
        .map(([key, value]) => {
          return `${toSnake(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/line-sheets/${lineSheetSetId}?${q}`);
    },
    [location, navigate]
  );
}
