import SocialButton from "@/features/auth/social-buttons/social-button";

export default function GoogleSocialButton() {
  return (
    <SocialButton
      bgColor="#fff"
      src="/ic_google.svg"
      name="Google"
      borderColor="#eee"
      padding="10px"
    />
  );
}
