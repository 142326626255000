import useIdentity from "@/features/ui/hooks/use-identity";
import { useMemo } from "react";
import BuyerDashboardPage from "@/features/dashboard/buyer-dashboard-page";
import { Helmet } from "react-helmet-async";

export default function DashboardPage() {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);

  if (company?.type === "BUYER") {
    return (
      <>
        <Helmet>
          <title>Pathrade | Dashboard</title>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
          <meta property="og:title" content="Pathrade | Dashboard" />
          <meta
            property="og:url"
            content={window.location.origin + window.location.pathname}
          />
        </Helmet>
        <BuyerDashboardPage />
      </>
    );
  } else {
    return null;
  }
}
