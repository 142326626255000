import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useGetMySelf from "@/features/auth/use-get-my-self";
import { useEffect, useMemo } from "react";
import { FormikUtils } from "@/features/ui/utils/formik-utils";

export default function useGetIssuedThroughOrIssuedTo() {
  const { error: showError } = useAppToasts();
  const { data: mySelf, error: getMySelfError } = useGetMySelf();

  useEffect(() => {
    if (getMySelfError) {
      showError(getMySelfError);
    }
  }, [getMySelfError, showError]);

  return useMemo(() => {
    return FormikUtils.issuedToOrIssuedThrough(mySelf);
  }, [mySelf]);
}
