import { IdentityParameter } from "@/features/types";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import {
  InvoiceStatisticByType,
  InvoiceStatisticType,
} from "@/features/dashboard/dashboard.type";

export interface ListInvoiceStatisticsByTypeRequest {
  // issuedByCompany__eq?: CompanyId;
  "issuedByCompany.id__in"?: number[];
  "madeByCompany.id__eq"?: number;
  season__gte?: string;
  season__lte?: string;
  season__eq?: string;
  season__in?: string[];
  type__eq?: InvoiceStatisticType;
  type__in?: InvoiceStatisticType[];
  issuedOn__eq?: Date;
  isRemittedToAgency__eq?: boolean;
  isRemittedToBoutique__eq?: boolean;
  "issuedByCompany.id_or_originallyIssuedByCompany.id__in"?: number;
}

export interface ListInvoiceStatisticsByTypeResponse {
  totalInvoiceStatistic: Omit<InvoiceStatisticByType, "type">;
  invoiceStatisticsByType: InvoiceStatisticByType[];
}

export interface ListInvoiceStatisticsPathParameter extends IdentityParameter {}

const ListInvoiceStatisticsTemplateUrl = `/:by/:companyId/invoice-statistics-by-type`;

export function getListInvoiceStatisticsKey(
  parameter?: ListInvoiceStatisticsPathParameter,
  request?: ListInvoiceStatisticsByTypeRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(ListInvoiceStatisticsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function useListInvoiceStatisticsByType(
  parameters?: ListInvoiceStatisticsPathParameter,
  request?: ListInvoiceStatisticsByTypeRequest,
  config?: SWRConfiguration
) {
  const fetcher = useFetcher<
    ListInvoiceStatisticsByTypeRequest,
    ListInvoiceStatisticsByTypeResponse
  >();
  return useSWR(
    getListInvoiceStatisticsKey(parameters, request),
    fetcher,
    config
  );
}
