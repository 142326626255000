import { useRecoilCallback } from "recoil";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import {
  agencyOrderSheetSetRowsMutateId,
  agencyOrderSheetSetRowsState,
} from "@/features/order-status/recoils/agency-order-sheet-set-rows-state";
import { createAgencyOrderSheetSetRows } from "@/features/order-status/helpers/agency-order-sheet-set-row-helper";
import { AgencyOrderSheetSetRow } from "@/features/order-status/order-sheet-set-row.type";
import { GridMapperToRowOption } from "@/features/ui/grid-row/gird-mapper";

export default function useAgencyOrderSheetSetRows() {
  const setRows = useRecoilCallback(
    ({ set, snapshot }) =>
      (
        orderSheetSets?: DenseOrderSheetSet[],
        option?: GridMapperToRowOption
      ) => {
        set(agencyOrderSheetSetRowsState, (prev) => {
          return createAgencyOrderSheetSetRows(
            orderSheetSets,
            prev,
            option
          ) as AgencyOrderSheetSetRow[];
        });
      },
    []
  );

  const mutateRows = useRecoilCallback(
    ({ set }) =>
      () => {
        set(agencyOrderSheetSetRowsMutateId, (prev) => {
          return prev + 1;
        });
      },
    []
  );

  return {
    setRows,
    mutateRows,
  };
}
