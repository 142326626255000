import SVG from "react-inlinesvg";
import { Props } from "react-inlinesvg/src/types";

export interface AppIconProps extends Omit<Props, "src"> {
  icon: "hamburger" | "left" | "right" | "x" | "save" | "edit" | "search";
  size?: "extraSmall" | "small" | "medium" | "large";
}

export default function AppIcon({
  icon,
  size = "medium",
  ...rest
}: AppIconProps) {
  let src = "";

  if (icon === "hamburger") {
    src = "/icons/icon_hamburger_menu.svg";
  } else if (icon === "left") {
    src = "/icons/icon_chevron_left.svg";
  } else if (icon === "right") {
    src = "/icons/icon_chevron_right.svg";
  } else if (icon === "x") {
    src = "/icons/icon_x_letter.svg";
  } else if (icon === "save") {
    src = "/icons/icon_save.svg";
  } else if (icon === "edit") {
    src = "/icons/icon_edit.svg";
  } else if (icon === "search") {
    src = "/icons/icon_magnifier.svg";
  }

  const sizeProps = { width: "16px", height: "16px" };
  if (size === "small") {
    sizeProps.height = "12px";
    sizeProps.width = "12px";
  } else if (size === "large") {
    sizeProps.height = "20px";
    sizeProps.width = "20px";
  } else if (size === "extraSmall") {
    sizeProps.height = "10px";
    sizeProps.width = "10px";
  }

  return <SVG src={src} {...sizeProps} {...rest} />;
}
