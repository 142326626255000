import { LightLineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { IdentityParameter } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import useFetcher from "@/utils/use-fetcher";

export interface ReadOrderConfirmationInvoiceRequest {}
export interface ReadOrderConfirmationInvoiceResponse extends LightLineSheet {}

interface ReadOrderConfirmationInvoicePathParameter extends IdentityParameter {
  orderConfirmationInvoiceId: number;
}

export const readOrderConfirmationInvoiceTemplateUrl =
  "/:by/:companyId/order-confirmation-invoices/:orderConfirmationInvoiceId/read";

export const readOrderConfirmationInvoiceKey: YWRKey<ReadOrderConfirmationInvoicePathParameter> =
  {
    url: (parameter: ReadOrderConfirmationInvoicePathParameter) => {
      return calcUrl(readOrderConfirmationInvoiceTemplateUrl, parameter);
    },
    method: "post",
  };

export function parseReadOrderConfirmationInvoiceKey(url: string) {
  const urlParser = new UrlParser<ReadOrderConfirmationInvoicePathParameter>(
    readOrderConfirmationInvoiceTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
      orderConfirmationInvoiceId: StringTransformer.parseId,
    }
  );

  return {
    parameter: urlParser.parse(url),
    method: "post",
  };
}

export default function useReadOrderConfirmationInvoice() {
  const fetcher = useFetcher<
    ReadOrderConfirmationInvoiceRequest,
    ReadOrderConfirmationInvoiceResponse
  >();
  return useYWR(readOrderConfirmationInvoiceKey, fetcher);
}
