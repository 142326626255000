import { Brand, Company, Price, StoredObject } from "@/features/types";
import { AbstractInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { Formik } from "formik";
import { Grid, Heading } from "@chakra-ui/react";
import AppSubmitButton from "@/features/line-sheet-sets/app-submit-button";
import AppListHeader from "@/features/ui/app-list-header";
import React, { useCallback, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import PaymentForm from "@/features/invoices/payment/payment-form";
import useGetIssuedThroughOrIssuedTo from "@/features/invoices/hooks/use-get-issued-through-or-issued-to";
import usePaymentFormValidationSchema from "@/features/invoices/payment/hooks/use-payment-form-validation-schema";
import { usePaymentDumbTransaction } from "@/features/invoices/payment/hooks/use-payment-dumb-transaction";
import useIdentity from "@/features/ui/hooks/use-identity";
import { DEFAULT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import { PriceInputValue } from "@/features/invoices/app-price-input";

export interface PaymentFormFields {
  id?: number;
  name?: string;
  season?: string;
  sender?: Company;
  receiver?: Company;
  status?: AbstractInvoice["status"];

  issuedThrough?: Pick<Company, "id" | "name">;
  orderedBy?: Pick<Company, "id" | "name">;
  // issuedBy?: Pick<Company, "id" | "name">; // 부티크 or 3자무역사
  issuedOn?: Date;
  amount?: PriceInputValue;
  remittedAmount?: PriceInputValue;
  unremittedAmount?: PriceInputValue;
  isRemitted: boolean;
  orderConfirmationInvoices: {
    id: number;
    number: string;
    paidBy: "BUYER" | "AGENCY";
    totalRequiredAmount: Price;
    orderConfirmationInvoiceDetailList: {
      id: number;
      brand: Pick<Brand, "id" | "name">;
    }[];
  }[];
  proformaInvoices: {
    id: number;
    number: string;
    paidBy: "BUYER" | "AGENCY";
    totalRequiredAmount: Price;
    proformaInvoiceDetailList: {
      id: number;
      brand: Pick<Brand, "id" | "name">;
    }[];
  }[];
  publicComment?: string;
  privateComment?: string;

  bankRemittanceReceipts: { id: number }[];
  krwInvoiceFiles?:
    | {
        invoices?: {
          id: number;
          type: "PROFORMA_INVOICE" | "ORDER_CONFIRMATION_INVOICE";
        }[] | null;
        invoiceId?: number;
        file: Pick<StoredObject, "id" | "name" | "createdAt"> | File;
      }[];

  isFeeIncluded: boolean;
}

export default function UploadPaymentPage() {
  const { tTitle } = useI18nHelper();
  const { error: showError } = useAppToasts();
  const identity = useIdentity();
  const issuedThroughOrIssuedTo = useGetIssuedThroughOrIssuedTo();

  const initialValues = useMemo(() => {
    const value: PaymentFormFields = {
      id: -1,
      season: DEFAULT_SEASON,
      isRemitted: false,
      orderConfirmationInvoices: [],
      proformaInvoices: [],
      // bankRemittanceReceipts: [],
      ...issuedThroughOrIssuedTo,
      status: "NORMAL",
      isFeeIncluded: false,
      bankRemittanceReceipts: [],
    };

    //useEffect로 기본을 채워주는 hook을 호출해도 race condition때문에 값이 무시당함, 여기서 초기값을 넣어주자
    // if (value.issuedThrough) {
    //   value.receiver = {
    //     ...value.issuedThrough,
    //     type: "AGENCY",
    //   };
    // }

    return value;
  }, [issuedThroughOrIssuedTo]);

  const validateSchema = usePaymentFormValidationSchema();

  const validate = useCallback(
    async (values: PaymentFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [validateSchema, showError]
  );

  const { addCreate } = usePaymentDumbTransaction();

  const handleSubmit = useCallback(
    async (values: PaymentFormFields) => {
      if (identity?.company) {
        addCreate(
          {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          {
            name: values.name!,
            season: values.season!,
            status: values.status!,
            amount: {
              value: values.amount!.value!,
              currency: values.amount!.currency as Price["currency"],
            },
            sender: values.sender!,
            receiver: values.receiver!,
            orderConfirmationInvoices: values.orderConfirmationInvoices.map(
              (ocInvoice) => {
                return {
                  id: ocInvoice.id,
                };
              }
            ),
            proformaInvoices: values.proformaInvoices.map((proformaInvoice) => {
              return {
                id: proformaInvoice.id,
              };
            }),
            publicComment: values.publicComment || null,
            privateComment: values.privateComment || null,
            issuedToCompany: values.orderedBy!,
            // issuedByCompany: values.issuedBy!,
            issuedThroughCompany: values.issuedThrough!,

            krwInvoiceFiles:
              values.krwInvoiceFiles?.map((krwInvoiceFile) => {
                return {
                  invoices: krwInvoiceFile.invoices?.map((invoice) => {
                    return {
                      id: invoice.id,
                      type: invoice.type,
                    };
                  }) || [],
                  file: krwInvoiceFile.file as File,
                };
              }) || null,

            isFeeIncluded: values.isFeeIncluded,
          }
        );
        return true;
      }
      return false;
    },
    [addCreate, identity]
  );

  return (
    <Formik<PaymentFormFields>
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validate={validate}
      onSubmit={(value, formikHelpers) => {
        return handleSubmit(value);
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <AppListHeader
              headers={[
                <Heading key={"header"} as={"h2"} fontSize={"14px"}>
                  {tTitle("upload_payment")}
                </Heading>,
              ]}
              filters={[]}
              actions={[
                <AppSubmitButton
                  key={"upload_and_add_another"}
                  addOther={true}
                  formik={props}
                />,
                <AppSubmitButton key={"upload"} formik={props} />,
              ]}
              showNavigateToBack={true}
              emptyStackPage={"/payments"}
              blockNavigateToBack={props.isSubmitting || props.dirty}
            />
            <PaymentForm formik={props} isEditing={true} />
          </Grid>
        );
      }}
    </Formik>
  );
}
