import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import { ICellRendererParams } from "ag-grid-community";
import { FlatProduct } from "@/features/line-sheet-sets/helpers/sheet-state";
import { Flex } from "@chakra-ui/react";

interface ProductCheckboxCellProps extends ICellRendererParams<FlatProduct> {}
export default function ProductCheckboxCell({
  node,
  api,
  rowIndex,
}: ProductCheckboxCellProps) {
  if (rowIndex % 3 === 0) {
    return (
      <Flex
        backgroundColor={"transparent"}
        height={"100%"}
        justifyContent={"center"}
      >
        <AppCheckBox
          size={"small"}
          isChecked={node.isSelected()}
          onChange={(checked) => {
            node.setSelected(checked);
            const prevNode = api.getDisplayedRowAtIndex(rowIndex + 1)!!;
            const nextNode = api.getDisplayedRowAtIndex(rowIndex + 2)!!;
            prevNode.setSelected(checked);
            nextNode.setSelected(checked);
            api.refreshCells({
              force: true,
              rowNodes: [node, prevNode, nextNode],
              columns: ["isChecked"],
            });
          }}
        />
      </Flex>
    );
  } else {
    return null;
  }
}
