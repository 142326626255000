import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";
import {
  bulkCreateLineSheetSetKey,
  BulkCreateLineSheetSetRequest,
  BulkCreateLineSheetSetResponse
} from "@/features/gmails/hooks/use-create-line-sheet-set";

export default function useBulkCreateLineSheetSetInfinite() {
  const fetcher = useFetcher<
    BulkCreateLineSheetSetRequest,
    BulkCreateLineSheetSetResponse
  >();
  return useYWRInfinite(bulkCreateLineSheetSetKey, fetcher);
}
