import { Grid, GridProps, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

export interface BillBoardItem {
  value: JSX.Element | string | null;
  variant?: "normal" | "bold";
  textAlign?: "left" | "right";
  width?: string;
}

export interface AppSheetSetBillboardProps {
  rows: (BillBoardItem | null)[][];
}

export default function AppSheetSetBillboard({
  rows,
}: AppSheetSetBillboardProps) {
  const gridTemplates = useMemo((): GridProps => {
    const cellCount = rows
      .map((row) => row.length)
      .reduce((acc, i) => Math.max(acc, i), 0);

    let gridTemplateColumns: GridProps["gridTemplateColumns"] = "";

    if (rows.length > 0) {
      gridTemplateColumns = rows[0]
        .map((row) => row?.width || "88px")
        .join(" ");
    }

    const rowCount = rows.length;
    return {
      gridTemplateColumns: gridTemplateColumns,
      gridTemplateRows: `repeat(${rowCount}, 16px)`,
    };
  }, [rows]);

  return (
    <Grid
      ml={"4px"}
      gap={"4px 12px"}
      alignItems={"center"}
      {...gridTemplates}
      fontSize={"12px"}
    >
      {rows.map((row, rowIndex) => {
        return row.map((cell, cellIndex) => {
          if (
            cell === null ||
            cell.value === null ||
            typeof cell.value === "string"
          ) {
            return (
              <Text
                key={`AppSheetSetBillBoardCell_${rowIndex}_${cellIndex}`}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                fontWeight={cell?.variant === "bold" ? "700" : "400"}
                textAlign={cell?.textAlign || "left"}
              >
                {cell?.value ? cell.value : ""}
              </Text>
            );
          } else {
            return (
              <React.Fragment
                key={`AppSheetSetBillBoardCell_${rowIndex}_${cellIndex}`}
              >
                {cell.value}
              </React.Fragment>
            );
          }
        });
      })}
    </Grid>
  );
}
