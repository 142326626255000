import { Payment } from "@/features/invoices/payment/payment.type";
import { CompanyType } from "@/features/types";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";

export interface CreatePaymentRequest
  extends Omit<
    Payment,
    | "id"
    | "isRead"
    | "createdAt"
    | "isRemitted"
    | "remittedAmount"
    | "unremittedAmount"
    | "bankRemittanceReceipts"
    | "compressedFile"
  > {}

export interface CreatePaymentResponse extends Payment {}

export interface CreatePaymentParameter {
  by: CompanyType;
  companyId: number;
}

export const createPaymentTemplateUrl = `/:by/:companyId/payments`;

export const createPaymentKey: YWRKey<CreatePaymentParameter> = {
  url: (parameter) => {
    return calcUrl(createPaymentTemplateUrl, parameter);
  },
  method: "post",
};

export default function useCreatePayment() {
  const fetcher = useFetcher();
  return useYWR(createPaymentKey, fetcher);
}
