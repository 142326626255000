import { ListItem } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

interface AppSelectOptionProp<T> {
  handleClick: (t: AppSelectOption<T>) => void;
  isActive: boolean;
  option: AppSelectOption<T>;
}

export default function AppSelectOption<T>({
  handleClick,
  option,
  isActive,
}: AppSelectOptionProp<T>) {
  let style = {};
  if (isActive) {
    style = {
      maskImage: "url('/icons/icon_check.svg')",
      backgroundColor: "#1272EF",
    };
  }

  return (
    <ListItem
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      borderBottom={"0.5px solid #D4D4D1"}
      p={"8px 16px"}
      as={"button"}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick(option);
      }}
      _before={{
        content: '""',
        display: "inline-block",
        width: "12px",
        height: "12px",
        marginRight: "8px",
        ...style,
      }}
      _hover={{
        cursor: "pointer",
        "::before": {
          backgroundColor: "#444440",
          maskImage: "url('/icons/icon_check.svg')",
          ...style,
        },
      }}
    >
      {option.component || <Text>{option.name}</Text>}
    </ListItem>
  );
}
