import SVG from "react-inlinesvg";
import AppButton from "@/features/line-sheet-sets/app-button";
import React, { useCallback, useContext } from "react";
import { Box, Flex, FormControl, Grid, Spacer } from "@chakra-ui/react";
import { Brand } from "@/features/types";
import { produce } from "immer";
import { toTitle } from "@/utils/case";
import { OrderConfirmationInvoiceDetail } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { ProformaInvoiceFormFields } from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import ProformaInvoiceDetailInput from "@/features/invoices/proforma-invoices/proforma-invoice-detail-input";
import {
  DetailBrandContext,
  DetailBrandContextType,
} from "@/features/invoices/proforma-invoices/detail-brand-provider";
import { Field, FieldProps } from "formik";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";

export interface ProformaInvoiceDetailInputValue {
  id: number;
  //브랜드별 인보이스 금액이구나
  amount?: number;
  //잔여디파짓이 없넹?
  remainingDepositAmount?: number;
  usedDepositAmount?: number;
  brand?: Pick<Brand, "id" | "name">;
  orderConfirmationInvoiceDetail?: Pick<OrderConfirmationInvoiceDetail, "id">;
  orderSheetSet?: {
    id: number;
  };
}

interface ProformaInvoiceDetailsInputsProps {
  value: ProformaInvoiceFormFields["proformaInvoiceDetailList"];
  onChange: (
    value: ProformaInvoiceFormFields["proformaInvoiceDetailList"]
  ) => void;
  negativeSequencer: NegativeSequencer;
  isDisabled?: boolean;
  isEditing?: boolean;
}

export default function ProformaInvoiceDetailsInput({
  value,
  onChange,
  negativeSequencer,
  isDisabled = false,
  isEditing = false,
}: ProformaInvoiceDetailsInputsProps) {
  const { t, tTitle } = useI18nHelper();

  const handleAddBrandClick = useCallback(() => {
    onChange([
      ...value,
      {
        id: negativeSequencer.next(),
        brand: undefined,
        amount: undefined,
        usedDepositAmount: undefined,
        requiredAmount: undefined,
      },
    ]);
  }, [value, onChange, negativeSequencer]);

  const { items } = useContext<DetailBrandContextType>(DetailBrandContext);
  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      {value.length > 0 && (
        <Grid gridTemplateColumns={"2fr repeat(5, 1fr) 12px"}>
          <Box></Box>
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("amount_by_brand"))}
          </Box>
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("remaining_deposit_amount_by_brand.abbr"))}
          </Box>
          <Flex fontSize={"12px"} flexDirection={"row"} color={"#444440"}>
            <Box flexShrink={"0"}>
              {toTitle(t("common:used_deposit_amount_by_brand.abbr"))}
            </Box>
            <Spacer />
            <Field name={"isDetailsEditedManually"}>
              {(
                props: FieldProps<
                  ProformaInvoiceFormFields["isDetailsEditedManually"],
                  ProformaInvoiceFormFields
                >
              ) => {
                if (props.form.values.proformaInvoiceDetailList.length > 0) {
                  return (
                    <FormControl
                      padding={"0"}
                      margin={"0 8px 0 0"}
                      width={"auto"}
                    >
                      <AppCheckBox
                        name={tTitle("common:edit")}
                        isChecked={props.field.value}
                        isDisabled={isDisabled || !isEditing}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    </FormControl>
                  );
                }
                return null;
              }}
            </Field>
          </Flex>
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("used_credit"))}
          </Box>
          <Box fontSize={"12px"} color={"#444440"}>
            {toTitle(t("required_amount"))}
          </Box>
        </Grid>
      )}
      {value.map((detail, index) => {
        return (
          <ProformaInvoiceDetailInput
            key={`ProformaInvoiceDetailInput_${index}`}
            value={detail}
            isDisabled={isDisabled}
            isReadOnly={!isEditing}
            onChange={(detail) => {
              onChange(
                produce(value, (draft) => {
                  if (detail) {
                    draft[index] = detail;
                  } else {
                    draft.splice(index, 1);
                  }
                })
              );
            }}
          />
        );
      })}
      {/*</Grid>*/}
      {isEditing && (
        <AppButton
          width={"100%"}
          onClick={handleAddBrandClick}
          isDisabled={isDisabled || (items || []).length <= value.length}
        >
          <SVG
            src={"/icons/icon_add.svg"}
            width={"14px"}
            height={"14px"}
            style={{ marginRight: "8px" }}
          />
          {toTitle(t("add_brand"))}
        </AppButton>
      )}
    </Flex>
  );
}
