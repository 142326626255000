import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import useSignIn from "@/features/auth/sign-in/use-sign-in";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import AppButton from "@/features/line-sheet-sets/app-button";
import { isErrorResponse } from "@/utils/use-fetcher";
import { getIdentity } from "@/features/ui/helpers/identity-helpers";
import { getDefaultPageByIdentity } from "@/pages";
import { useTranslation } from "react-i18next";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { toTitle } from "@/utils/case";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";

interface SignInFormVales {
  email: string;
  password: string;
  staySignedIn: false;
}

const initialValue: SignInFormVales = {
  email: "",
  password: "",
  staySignedIn: false,
};

export default function SignInForm() {
  //const navigate = useNavigate();
  const { isLoading, data, error: signInError, fire: fireSignIn } = useSignIn();
  const { t, i18n } = useTranslation();
  const { error: showError } = useAppToasts();
  const { navigate, searchParams } = useRouterHelper();
  const { withMessage, formT } = useYupHelper({
    prefix: "sign_in_form",
    ns: "auth",
  });

  useEffect(() => {
    if (signInError) {
      showError(signInError, {
        id: "SIGN_IN_ERROR",
        i18n: {
          keyPrefix: "sign_in.error",
          ns: "auth",
        },
      });
    }
  }, [signInError, showError]);

  useEffect(() => {
    if (data) {
      // navigate(getDefaultPage(getIdentity()), { replace: true });
      const redirectUrl = searchParams.get("redirect_url");
      if (redirectUrl) {
        navigate(decodeURIComponent(redirectUrl), { replace: true });
        return;
      }

      navigate(getDefaultPageByIdentity(getIdentity()), { replace: true });
    }
  }, [data, navigate, searchParams]);

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(withMessage.email)
          .required(withMessage.required),
        password: Yup.string()
          // .min(4, withMessage.min)
          .required(withMessage.required),
      })}
      onSubmit={(values, actions) => {
        if (!isLoading) {
          fireSignIn(null, values);
          actions.setSubmitting(true);
          return;
        }
      }}
    >
      {(props: FormikProps<SignInFormVales>) => {
        if (signInError && props.isSubmitting) {
          props.setSubmitting(false);
        }
        return (
          <Form>
            <Field name="email">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<SignInFormVales>;
              }) => (
                <FormControl
                  isRequired
                  isInvalid={!!form.errors.email && form.touched.email}
                >
                  <FormLabel display="flex" m={"0"}>
                    <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                      {toTitle(t("email_address"))}
                    </Text>
                  </FormLabel>
                  <Input
                    {...field}
                    fontSize={"14px"}
                    autoComplete={"username"}
                  />
                  <Flex
                    h="24px"
                    flexDirection={"column"}
                    justifyContent={"center"}
                  >
                    <FormErrorMessage
                      fontSize={"12px"}
                      m="0"
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      {form.errors.email}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              )}
            </Field>

            <Field name="password">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<SignInFormVales>;
              }) => (
                <FormControl
                  isRequired
                  isInvalid={!!form.errors.password && form.touched.password}
                >
                  <FormLabel display="flex" m={"0"}>
                    <Text color="#6F6F6F" fontSize={"12px"} fontWeight={"400"}>
                      {toTitle(t("password"))}
                    </Text>
                  </FormLabel>
                  <Input
                    type="password"
                    {...field}
                    fontSize={"14px"}
                    autoComplete={"current-password"}
                  />
                  <Flex
                    h="24px"
                    flexDirection={"column"}
                    justifyContent={"center"}
                  >
                    <FormErrorMessage
                      fontSize={"12px"}
                      m="0"
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      {form.errors.password}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              )}
            </Field>

            {/*<Field name="staySignedIn">*/}
            {/*  {({*/}
            {/*    field,*/}
            {/*    form,*/}
            {/*  }: {*/}
            {/*    field: FieldInputProps<boolean>;*/}
            {/*    form: FormikProps<SignInFormVales>;*/}
            {/*  }) => (*/}
            {/*    <FormControl*/}
            {/*      display="flex"*/}
            {/*      gap="8px"*/}
            {/*      flexDirection="row"*/}
            {/*      alignItems="center"*/}
            {/*      mb="8px"*/}
            {/*    >*/}
            {/*      <Checkbox {...field} value={""} />*/}
            {/*      <FormLabel m="0">*/}
            {/*        <Text color="gray.500" fontSize={"12px"}>*/}
            {/*          Stay Logged In*/}
            {/*        </Text>*/}
            {/*      </FormLabel>*/}
            {/*    </FormControl>*/}
            {/*  )}*/}
            {/*</Field>*/}

            <AppButton
              variant={"primary"}
              size={"large"}
              width={"100%"}
              chakraProps={{ width: "280px", height: "40px" }}
              type={"submit"}
              isLoading={isLoading}
              onClick={(event) => {
                //event.preventDefault();
              }}
            >
              <Text fontSize={"14px"}>
                {toTitle(t("sign_in_action", { ns: "auth" }))}
              </Text>
            </AppButton>

            {/*<Button*/}
            {/*  type="submit"*/}
            {/*  colorScheme="blue"*/}
            {/*  w="100%"*/}
            {/*  isLoading={props.isSubmitting}*/}
            {/*>*/}
            {/*  Sign in with email*/}
            {/*</Button>*/}
          </Form>
        );
      }}
    </Formik>
  );
}
