import {
  getListBankRemittanceReceiptsKey,
  ListBankRemittanceReceiptsPathParameter,
  ListBankRemittanceReceiptsRequest,
  ListBankRemittanceReceiptsResponse,
} from "@/features/invoices/bank-remittance-receipts/hooks/use-list-bank-remittance-receipts";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";

export interface ListAllBankRemittanceReceiptsRequest
  extends Omit<ListBankRemittanceReceiptsRequest, "pageSize" | "pageNumber"> {}
export type ListAllBankRemittanceReceiptsResponse = BankRemittanceReceipt[];
export interface ListAllBankRemittanceReceiptsPathParameter
  extends ListBankRemittanceReceiptsPathParameter {}

export const listAllBankRemittanceReceiptsTemplateUrl = `/:by/:companyId/bank-remittance-receipts/list-all`;

export function getListAllBankRemittanceReceiptsKey(
  parameter?: ListAllBankRemittanceReceiptsPathParameter,
  request?: ListAllBankRemittanceReceiptsRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllBankRemittanceReceiptsTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllBankRemittanceReceiptsKey(
  key: AxiosRequestConfig<ListAllBankRemittanceReceiptsRequest>
) {
  const urlParser = new UrlParser<ListAllBankRemittanceReceiptsPathParameter>(
    listAllBankRemittanceReceiptsTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllBankRemittanceReceipts(
  parameter?: ListAllBankRemittanceReceiptsPathParameter,
  request?: ListAllBankRemittanceReceiptsRequest,
  config?: SWRConfiguration<ListAllBankRemittanceReceiptsResponse>
) {
  const listBankRemittanceReceiptsFetcher = useFetcher<
    ListBankRemittanceReceiptsRequest,
    ListBankRemittanceReceiptsResponse
  >();

  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListBankRemittanceReceiptsRequest>) => {
        const { parameter, request } =
          parseListAllBankRemittanceReceiptsKey(config);
        return listBankRemittanceReceiptsFetcher(
          getListBankRemittanceReceiptsKey(parameter, request)!
        );
      },
      (response) => {
        return response.bankRemittanceReceipts;
      }
    );
  }, [listBankRemittanceReceiptsFetcher]);

  return useSWR(
    getListAllBankRemittanceReceiptsKey(parameter, request),
    fetcher,
    config
  );
}
