import useFetcher from "@/utils/use-fetcher";
import useYWR, { YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import { AxiosRequestConfig } from "axios";
import { IdentityParameter, StoredObject } from "@/features/types";
import {
  ProformaInvoice,
  ProformaInvoiceDetail,
} from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import { LineSheetSetType } from "@/features/line-sheet-sets/line-sheet-set.types";

export interface UpdateProformaInvoicePathParameter extends IdentityParameter {
  proformaInvoiceId: number;
}

export interface UpdateProformaInvoiceRequest
  extends Omit<
    ProformaInvoice,
    | "file"
    | "isRemitted"
    | "isRemittedToBoutique"
    | "isRemittedToAgency"
    | "isRead"
    | "bankRemittanceReceipts"
    | "earnedCreditInvoices"
    | "usedCreditInvoices"
    | "proformaInvoiceDetailList"
    | "lineSheetSetType"
    | "createdAt"
    | "totalRemittedAmount"
    | "totalAgencyToBoutiqueAmount"
    | "totalBuyerToBoutiqueAmount"
    | "totalBuyerToAgencyAmount"
    | "parentProformaInvoices"
    | "childProformaInvoices"
    | "payments"
  > {
  file: Pick<StoredObject, "id">;
  lineSheetSetType: LineSheetSetType | null;
  usedCreditInvoices: { id: number }[];
  proformaInvoiceDetailList: (Omit<
    ProformaInvoiceDetail,
    "orderConfirmationInvoiceDetail"
  > & {
    orderConfirmationInvoiceDetail: { id: number } | null;
  })[];
  childProformaInvoices: { id: number }[];
}

export interface UpdateProformaInvoiceResponse extends ProformaInvoice {}

export const updateProformaInvoiceTemplateUrl = `/:by/:companyId/proforma-invoices/:proformaInvoiceId`;

export const updateProformaInvoiceKey: YWRKey<UpdateProformaInvoicePathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateProformaInvoiceTemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateProformaInvoice(
  config?: AxiosRequestConfig<UpdateProformaInvoiceRequest>
) {
  const fetcher = useFetcher<
    UpdateProformaInvoiceRequest,
    UpdateProformaInvoiceResponse
  >();
  return useYWR(updateProformaInvoiceKey, fetcher, config);
}
