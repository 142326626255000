import { Flex, Input } from "@chakra-ui/react";
import AppCheckBox from "@/features/line-sheet-sets/app-checkbox";
import { useRecoilValue } from "recoil";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  FlatSheetKey,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { uniq } from "lodash";
import AppSelect from "@/features/line-sheet-sets/app-select";
import { SheetProduct } from "@/features/order-sheets/sheet.type";
import { OrderSheetSetPagePersistedQuery } from "@/features/order-sheet-sets/hooks/use-order-sheet-set-page-persisted-query";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import {
  isSheetProduct,
  LineSheetProduct,
} from "@/features/line-sheet-sets/line-sheet-set.types";

export interface SheetTabFilterQuery {
  brandName__eq?: string;
  gender__eq?: string;
  category__eq?: string;
  quantity__gte?: number;
  brandName__like?: string;
}

export const EMPTY_SHEET_TAB_FILTER_QUERY: SheetTabFilterQuery = {
  brandName__eq: undefined,
  gender__eq: undefined,
  category__eq: undefined,
  quantity__gte: undefined,
  brandName__like: undefined,
};

interface OrderSheetTabFilterProps {
  sheetKey: FlatSheetKey;
  tabIndex: number;
  query: OrderSheetSetPagePersistedQuery;
  onFilterSelect: (query: SheetTabFilterQuery) => void;
  // onSelectAllClick: (value: boolean) => void;
  // isAllSelected: boolean;
  onClear: () => void;
  isLineSheetProduct?: boolean;
}

export function isProductFiltered(
  product: SheetProduct | LineSheetProduct,
  query?: SheetTabFilterQuery
) {
  if (query) {
    const predicates = [
      (p: SheetProduct | LineSheetProduct) => {
        return !query?.gender__eq || query?.gender__eq === p.gender;
      },
      (p: SheetProduct | LineSheetProduct) => {
        return !query?.brandName__eq || query?.brandName__eq === p.brandName;
      },
      (p: SheetProduct | LineSheetProduct) => {
        return (
          !query?.category__eq ||
          (p.categories.length > 0 && query?.category__eq === p.categories[0])
        );
      },
      (p: SheetProduct | LineSheetProduct) => {
        if (isSheetProduct(p)) {
          return (
            !query?.quantity__gte ||
            p.orderQuantityWithOptionList.some(
              (i) => i.quantity >= (query?.quantity__gte || 0)
            )
          );
        } else {
          return true;
        }
      },
      (p: SheetProduct | LineSheetProduct) => {
        return (
          !query?.brandName__like ||
          p.modelNumber
            ?.toLowerCase()
            .includes(query?.brandName__like.toLowerCase()) ||
          (p.note &&
            Object.values(p.note)
              .join(" ")
              .replaceAll(/\s{2,}/g, " ")
              .toLowerCase()
              .includes(query?.brandName__like.toLowerCase()))
        );
      },
    ];
    return predicates.every((predicate) => predicate(product));
  }

  return true;
}

export default function SheetTabFilter({
  sheetKey,
  tabIndex,
  query,
  onFilterSelect,
  onClear,
  isLineSheetProduct = false,
}: OrderSheetTabFilterProps) {
  const { t, tTitle } = useI18nHelper();
  const inflatedTabsState = useRecoilValue(inflatedTabsFamily(sheetKey));

  const [searchWord, setsearchWord] = useState<string>("");

  const inflatedTab = useMemo(() => {
    if (inflatedTabsState) {
      return inflatedTabsState.tabs.find((tab) => tab.index === tabIndex);
    }
    return undefined;
  }, [inflatedTabsState, tabIndex]);

  const calcOptions = useCallback(
    (
      func: (product: SheetProduct) => string | null | undefined
    ): AppSelectOption<string>[] => {
      if (inflatedTab) {
        return uniq(
          inflatedTab.products.map(func).filter((value): value is string => {
            return typeof value === "string";
          })
        ).map((value) => {
          return {
            name: value,
            value: value,
          };
        });
      }
      return [];
    },
    [inflatedTab]
  );

  const brandOptions = useMemo((): AppSelectOption<string>[] => {
    return calcOptions((product) => {
      return product.brandName;
    });
  }, [calcOptions]);

  const genderOptions = useMemo(() => {
    return calcOptions((product) => {
      return product.gender;
    });
  }, [calcOptions]);

  const categoryOptions = useMemo(() => {
    return calcOptions((product) => {
      return product.categories.length > 0 ? product.categories[0] : null;
    });
  }, [calcOptions]);

  const handleSelect = useCallback(
    (key: string, value: string | number | undefined) => {
      onFilterSelect({
        ...query,
        [key]: value,
      });
    },
    [query, onFilterSelect]
  );

  const onSearchSubmit = useCallback(() => {
    handleSelect("brandName__like", searchWord);
  }, [handleSelect, searchWord]);

  return (
    <Flex gap={"8px"} flexDirection={"row"} alignItems={"center"}>
      {/*<AppCheckBox*/}
      {/*  size={"small"}*/}
      {/*  isChecked={isAllSelected}*/}
      {/*  onChange={onSelectAllClick}*/}
      {/*/>*/}

      <Input
        padding={"2px 8px"}
        margin={"0"}
        minHeight={"0"}
        height={"28px"}
        fontSize={"12px"}
        textAlign={"start"}
        maxWidth={"144px"}
        borderRadius={"4px"}
        border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
        background={"var(--Pathrade-White, #FFF)"}
        value={searchWord}
        placeholder={tTitle("model_number")}
        onChange={(event) => {
          setsearchWord(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSearchSubmit();
          }
        }}
      />
      <AppButton
        variant={"normal"}
        size={"medium"}
        onClick={() => {
          onSearchSubmit();
        }}
      >
        {tTitle("search")}
      </AppButton>

      <AppSelect
        isSearchable={true}
        width={"120px"}
        name={tTitle("brand")}
        value={query.brandName__eq}
        options={brandOptions}
        onSelect={(value) => handleSelect("brandName__eq", value)}
      />
      <AppSelect
        isSearchable={true}
        width={"120px"}
        name={tTitle("gender")}
        value={query.gender__eq}
        options={genderOptions}
        onSelect={(value) => handleSelect("gender__eq", value)}
      />
      <AppSelect
        isSearchable={true}
        width={"120px"}
        name={tTitle("category")}
        value={query.category__eq}
        options={categoryOptions}
        onSelect={(value) => handleSelect("category__eq", value)}
      />

      {!isLineSheetProduct && (
        <AppCheckBox
          name={t("only_rows_with_qty")}
          size={"small"}
          isChecked={query.quantity__gte == 1}
          onChange={(isChecked) => {
            handleSelect("quantity__gte", isChecked ? 1 : undefined);
          }}
        />
      )}

      <AppButton
        variant={"normal"}
        size={"medium"}
        onClick={() => {
          setsearchWord("");
          onClear();
        }}
      >
        {tTitle("reset_filter")}
      </AppButton>
    </Flex>
  );
}
