import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import { YWRResponseSegment } from "@/features/ui/hooks/use-ywr-inifite";
import { StoreObjectResponse } from "@/features/ui/hooks/use-store-object";
import { createStoreObjectDumbTransaction } from "@/features/ui/utils/dumb-transaction-util";
import { dumbTransactionsState } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import { useCallback } from "react";

const onQueuedDumbTransaction = atom<{ key: string }[]>({
  key: "QueuedDumbTransaction",
  default: [],
});

type TransactionParams<P, SOURCE_REQ, REQ, RES, ERROR = any> = {
  parameter: P;
  source: SOURCE_REQ;
  getFilesFunc: (source: SOURCE_REQ) => File[];
  convertFunc: (
    source: SOURCE_REQ,
    storeObjectResponses: StoreObjectResponse[]
  ) => REQ;
  fire: (parameter: P, request: REQ) => Promise<RES>;
  keyOrFunc: string | ((parameter: P) => string);
  onPostFunc: (response: RES) => void;
  onErrorFunc: (error: ERROR) => void;
};

function getKey<P>(
  keyOrFunc: string | ((parameter: any) => string),
  parameter: P
) {
  if (typeof keyOrFunc === "string") {
    return keyOrFunc;
  }
  return keyOrFunc(parameter);
}

export default function useDumbTransaction() {
  const queuedFinals = useRecoilValue(onQueuedDumbTransaction);

  const addDumbTransactions = useRecoilCallback(
    ({ set }) =>
      async <P, SOURCE_REQ, REQ, RES>(
        params: TransactionParams<P, SOURCE_REQ, REQ, RES>
      ) => {
        const {
          parameter,
          source,
          getFilesFunc,
          convertFunc,
          fire,
          keyOrFunc,
          onPostFunc,
          onErrorFunc,
        } = params;

        const key = getKey(keyOrFunc, parameter);

        set(onQueuedDumbTransaction, (prev) => {
          return [...prev, { key }];
        });

        const [storeObjectsTransactions, storeObjectsPromises] =
          createStoreObjectDumbTransaction(getFilesFunc(source));

        if (storeObjectsTransactions.length > 0) {
          set(dumbTransactionsState, (prev) => {
            return [...prev, ...storeObjectsTransactions];
          });
        }

        await Promise.all(storeObjectsPromises).then(
          async (storeObjectResponses) => {
            try {
              const convertedRequest = convertFunc(
                source,
                storeObjectResponses
              );
              const res = await fire(parameter, convertedRequest);
              onPostFunc(res);
            } catch (e) {
              onErrorFunc(e);
            }
          }
        );

        set(onQueuedDumbTransaction, (prev) => {
          return prev.filter((i) => i.key !== key);
        });
      },
    []
  );

  const isDumbTransactionQueued = useCallback(
    (key: string) => {
      return queuedFinals.some((i) => i.key === key);
    },
    [queuedFinals]
  );

  return {
    addDumbTransactions,
    isDumbTransactionQueued,
  };
}
