import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import Image from "next/image";
import landingFooterStyle from "./landing-footer.module.scss";
import AppLanguageSelector from "../ui/app-language-selector";

export default function LandingFooter() {
  const { t } = useI18nHelper();
  return (
    <footer className={`${landingFooterStyle.footerWrapper}`}>
      <div className={`${landingFooterStyle.footerContainer}`}>
        <div className={`${landingFooterStyle.footerLogo}`}>
          <a
            href={window.location.origin + "/#"}
            title="Pathrade Main"
            aria-label="Pathrade Main"
          >
            <Image
              src={window.location.origin + "/logo-white.svg"}
              className="logo"
              alt="Pathrade Logo."
              title="Pathrade Logo."
              loading="lazy"
              height="32"
              width="156"
            />
          </a>
        </div>
        <div className={`${landingFooterStyle.footerRight}`}>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://twitter.com/pathradeapp"
              title="Pathrade X"
              aria-label="Social link for X"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#f1f1f1"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(8.53333,8.53333)">
                    <path d="M26.37,26l-8.795,-12.822l0.015,0.012l7.93,-9.19h-2.65l-6.46,7.48l-5.13,-7.48h-6.95l8.211,11.971l-0.001,-0.001l-8.66,10.03h2.65l7.182,-8.322l5.708,8.322zM10.23,6l12.34,18h-2.1l-12.35,-18z"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://www.facebook.com/pathrade.official"
              title="Pathrade Facebook"
              aria-label="Social link for Facebook"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#f1f1f1"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(10.66667,10.66667)">
                    <path d="M19,3h-14c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h7.621v-6.961h-2.343v-2.725h2.343v-2.005c0,-2.324 1.421,-3.591 3.495,-3.591c0.699,-0.002 1.397,0.034 2.092,0.105v2.43h-1.428c-1.13,0 -1.35,0.534 -1.35,1.322v1.735h2.7l-0.351,2.725h-2.365v6.965h3.586c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2z"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://www.instagram.com/pathrade_official"
              title="Pathrade Instagram"
              aria-label="Social link for Instagram"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#f1f1f1"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(10.66667,10.66667)">
                    <path d="M8,3c-2.761,0 -5,2.239 -5,5v8c0,2.761 2.239,5 5,5h8c2.761,0 5,-2.239 5,-5v-8c0,-2.761 -2.239,-5 -5,-5zM18,5c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1zM12,7c2.761,0 5,2.239 5,5c0,2.761 -2.239,5 -5,5c-2.761,0 -5,-2.239 -5,-5c0,-2.761 2.239,-5 5,-5zM12,9c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3z"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://www.linkedin.com/showcase/pathrade"
              title="Pathrade LinkedIn"
              aria-label="Social link for LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#f1f1f1"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(10.66667,10.66667)">
                    <path d="M19,3h-14c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2zM9,17h-2.523v-7h2.523zM7.694,8.717c-0.771,0 -1.286,-0.514 -1.286,-1.2c0,-0.686 0.514,-1.2 1.371,-1.2c0.771,0 1.286,0.514 1.286,1.2c0,0.686 -0.514,1.2 -1.371,1.2zM18,17h-2.442v-3.826c0,-1.058 -0.651,-1.302 -0.895,-1.302c-0.244,0 -1.058,0.163 -1.058,1.302c0,0.163 0,3.826 0,3.826h-2.523v-7h2.523v0.977c0.325,-0.57 0.976,-0.977 2.197,-0.977c1.221,0 2.198,0.977 2.198,3.174z"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://blog.pathrade.com"
              title="Pathrade Blog"
              aria-label="Social link for the blog"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#f1f1f1"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit={10}
                  strokeDasharray=""
                  strokeDashoffset={0}
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(10.66667,10.66667)">
                    <path d="M12,2c-5.51562,0 -10,4.48438 -10,10c0,5.51563 4.48438,10 10,10c5.51563,0 10,-4.48828 10,-10c0,-5.51562 -4.48437,-10 -10,-10zM12,3c4.96094,0 9,4.03516 9,9c0,4.96484 -4.03906,9 -9,9c-4.96094,0 -9,-4.03906 -9,-9c0,-4.96484 4.03906,-9 9,-9zM12,4c-2.79687,0 -5.25781,1.42188 -6.6875,3.59375c0.1875,0.00781 0.38281,0.03125 0.53125,0.03125c0.83594,0 2.125,-0.125 2.125,-0.125c0.42969,-0.02344 0.46094,0.63672 0.03125,0.6875c0,0 -0.42578,0.03516 -0.90625,0.0625l2.65625,7.9375l1.75,-5.25l-0.96875,-2.6875c-0.42969,-0.02344 -0.84375,-0.0625 -0.84375,-0.0625c-0.43359,-0.02734 -0.39844,-0.71484 0.03125,-0.6875c0,0 1.33984,0.125 2.125,0.125c0.83594,0 2.125,-0.125 2.125,-0.125c0.43359,-0.02344 0.49219,0.63672 0.0625,0.6875c0,0 -0.45703,0.03516 -0.9375,0.0625l2.90625,8l0.8125,-2.65625c0.26172,-1.06641 0.59375,-1.90625 0.59375,-2.59375c0,-0.75 -0.34375,-1.09766 -0.65625,-1.625c-0.39453,-0.66406 -0.78125,-1.21484 -0.78125,-1.875c0,-0.73437 0.55859,-1.40625 1.34375,-1.40625c0.03516,0 0.05859,-0.00391 0.09375,0c-1.42578,-1.30469 -3.32422,-2.09375 -5.40625,-2.09375zM19.03125,8.15625c0.03516,0.25391 0.01563,0.51563 0.03125,0.8125c0.03906,0.8125 -0.12109,2.0625 -0.3125,2.65625c-0.60937,1.88672 -2.40625,7.0625 -2.40625,7.0625c0.03125,-0.01172 0.08984,-0.03516 0.21875,-0.125c2.08984,-1.42969 3.4375,-3.83984 3.4375,-6.5625c0,-1.39062 -0.34375,-2.70312 -0.96875,-3.84375zM4.6875,8.75c-0.44141,0.99219 -0.6875,2.08984 -0.6875,3.25c0,3.16406 1.83203,5.89063 4.5,7.1875zM12.15625,12.6875l-2.40625,7c0.71875,0.21094 1.46484,0.3125 2.25,0.3125c0.92969,0 1.82422,-0.14453 2.65625,-0.4375c-0.01953,-0.03516 -0.04687,-0.08594 -0.0625,-0.125z"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={`${landingFooterStyle.footerRightItem}`}>
            <a
              href="https://pf.kakao.com/_CbLsG"
              title="Pathrade Kakaotalk"
              aria-label="Social link for Kakaotalk"
            >
              <svg
                className={`${landingFooterStyle.kakaoTalkIconSvg}`}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="28"
                height="28"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#FFF"
                  d="M256 236c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20V20C0 8.954 8.954 0 20 0h216c11.046 0 20 8.954 20 20v216z"
                />
                <path d="M128 36C70.562 36 24 72.713 24 118c0 29.279 19.466 54.97 48.748 69.477-1.593 5.494-10.237 35.344-10.581 37.689 0 0-.207 1.762.934 2.434s2.483.15 2.483.15c3.272-.457 37.943-24.811 43.944-29.04 5.995.849 12.168 1.29 18.472 1.29 57.438 0 104-36.712 104-82 0-45.287-46.562-82-104-82z" />
                <path
                  fill="#FFF"
                  d="M70.5 146.625c-3.309 0-6-2.57-6-5.73V105.25h-9.362c-3.247 0-5.888-2.636-5.888-5.875s2.642-5.875 5.888-5.875h30.724c3.247 0 5.888 2.636 5.888 5.875s-2.642 5.875-5.888 5.875H76.5v35.645c0 3.16-2.691 5.73-6 5.73zM123.112 146.547c-2.502 0-4.416-1.016-4.993-2.65l-2.971-7.778-18.296-.001-2.973 7.783c-.575 1.631-2.488 2.646-4.99 2.646a9.155 9.155 0 0 1-3.814-.828c-1.654-.763-3.244-2.861-1.422-8.52l14.352-37.776c1.011-2.873 4.082-5.833 7.99-5.922 3.919.088 6.99 3.049 8.003 5.928l14.346 37.759c1.826 5.672.236 7.771-1.418 8.532a9.176 9.176 0 0 1-3.814.827c-.001 0 0 0 0 0zm-11.119-21.056L106 108.466l-5.993 17.025h11.986zM138 145.75c-3.171 0-5.75-2.468-5.75-5.5V99.5c0-3.309 2.748-6 6.125-6s6.125 2.691 6.125 6v35.25h12.75c3.171 0 5.75 2.468 5.75 5.5s-2.579 5.5-5.75 5.5H138zM171.334 146.547c-3.309 0-6-2.691-6-6V99.5c0-3.309 2.691-6 6-6s6 2.691 6 6v12.896l16.74-16.74c.861-.861 2.044-1.335 3.328-1.335 1.498 0 3.002.646 4.129 1.772 1.051 1.05 1.678 2.401 1.764 3.804.087 1.415-.384 2.712-1.324 3.653l-13.673 13.671 14.769 19.566a5.951 5.951 0 0 1 1.152 4.445 5.956 5.956 0 0 1-2.328 3.957 5.94 5.94 0 0 1-3.609 1.211 5.953 5.953 0 0 1-4.793-2.385l-14.071-18.644-2.082 2.082v13.091a6.01 6.01 0 0 1-6.002 6.003z"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className={`${landingFooterStyle.footerCompanyInfo}`}>
          <div
            className={`${landingFooterStyle.footerCompanyInfoItem} ${landingFooterStyle.footerLanguageSelector}`}
          >
            <AppLanguageSelector />
          </div>
          <div className={`${landingFooterStyle.footerCompanyInfoItem}`}>
            <a
              className={`${landingFooterStyle.footerCompanyInfoSubItem} ${landingFooterStyle.footerCompanyInfoSubItem2Column}`}
              href="https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Privacy-Policy-v1.1-20240111.pdf"
              title="Pathrade Privacy Policy"
              aria-label="Pathrade Privacy Policy"
            >
              {t("landing:footer.privacyPolicy")}
            </a>
            <a
              className={`${landingFooterStyle.footerCompanyInfoSubItem} ${landingFooterStyle.footerCompanyInfoSubItem2Column}`}
              href="https://terms.pathrade.com/files/ko/UNOTradingCorp-Pathrade-Service-Agreement-v1.0-20231208.pdf"
              title="Pathrade Service Agreement"
              aria-label="Pathrade Service Agreement"
            >
              {t("landing:footer.termsAndConditions")}
            </a>
          </div>
          <div className={`${landingFooterStyle.footerCompanyInfoItem}`}>
            <p className={`${landingFooterStyle.footerCompanyInfoSubItem}`}>
              {t("landing:footer.customerSupport")} |{" "}
              {t("landing:footer.customerSupportEmail")}
            </p>
          </div>
          <div className={`${landingFooterStyle.footerCompanyInfoItem}`}>
            <p className={`${landingFooterStyle.footerCompanyInfoSubItem}`}>
              {t("landing:footer.businessRegistrationNumber")} |{" "}
              {t("landing:footer.businessRegistrationNumberValue")}
            </p>
          </div>
          <div className={`${landingFooterStyle.footerCompanyInfoItem}`}>
            <p className={`${landingFooterStyle.footerCompanyInfoSubItem}`}>
              {t("landing:footer.companyAddress")}
            </p>
          </div>
          <div className={`${landingFooterStyle.footerCompanyInfoItem}`}>
            <p className={`${landingFooterStyle.footerCompanyInfoSubItem}`}>
              {t("landing:footer.copyRight")}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
