import useListDenseProformaInvoicesFetcher from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices-fetcher";
import { useMemo } from "react";
import {
  condenseResourcesById,
  ResourceReferenceGetter,
  ResourceReferenceSetter,
} from "@/features/ui/helpers/condense-helper";
import { DenseProformaInvoice } from "@/features/invoices/proforma-invoices/proforma-invoice.type";
import {
  getListDenseProformaInvoicesKey,
  ListDenseProformaInvoicesPathParameter,
} from "@/features/invoices/proforma-invoices/hooks/use-list-dense-proforma-invoices";

export default function useCondenseProformaInvoices() {
  const listDenseProformaInvoicesFetcher =
    useListDenseProformaInvoicesFetcher();
  return useMemo(() => {
    return async <T>(
      items: T[],
      getter: ResourceReferenceGetter<T>,
      setter: ResourceReferenceSetter<T, DenseProformaInvoice>,
      parameter: ListDenseProformaInvoicesPathParameter
    ) => {
      await condenseResourcesById(
        items,
        getter,
        setter,
        async (ids: number[]) => {
          if (ids.length > 0) {
            const proformaInvoicesResponse =
              await listDenseProformaInvoicesFetcher(
                getListDenseProformaInvoicesKey(parameter, {
                  pageSize: ids.length,
                  pageNumber: 1,
                  id__in: ids,
                })!
              );
            return proformaInvoicesResponse.proformaInvoices;
          }
          return [];
        }
      );
    };
  }, [listDenseProformaInvoicesFetcher]);
}
