import { Company } from "@/features/types";
import AppBankRemittanceReceiptsExclusiveSelector from "@/features/invoices/deposit-invoices/app-bank-remittance-receipts-exclusive-selector";
import AppPanel from "@/features/invoices/order-confirmation-invoices/app-panel";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { Field, FieldProps } from "formik";
import { ListAllBankRemittanceReceiptsRequest } from "@/features/invoices/bank-remittance-receipts/hooks/use-list-all-bank-remittance-receipts";
import { Flex, FormControl, FormLabel, Skeleton } from "@chakra-ui/react";
import { toTitle } from "@/utils/case";
import React from "react";
import IsolatedBankRemittanceReceiptPanel from "@/features/invoices/bank-remittance-receipts/isolated-bank-remittance-receipt-panel";
import { AppUtils } from "@/features/ui/utils/app-utils";
import { BankRemittanceReceipt } from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt.type";

interface FormFields {
  id?: number;
  season?: string;
  issuedBy?: Pick<Company, "id" | "name">;
  orderedBy?: Pick<Company, "id" | "name">;
  bankRemittanceReceipts?: BankRemittanceReceipt[];
  bankRemittanceReceipt?: BankRemittanceReceipt;
}

interface LinkedBankRemittanceReceiptFormControlProps<T> {
  formik: any;
  isEditing?: boolean;
  isDisabled?: boolean;
  onSelect: (value: BankRemittanceReceipt[]) => void;
  request?: ListAllBankRemittanceReceiptsRequest;
  isMultiple?: boolean;
}

export default function LinkedBankRemittanceReceiptFormControl<
  T extends FormFields
>({
  formik,
  isEditing = false,
  isMultiple = true,
  isDisabled = false,
  onSelect,
  request,
}: LinkedBankRemittanceReceiptFormControlProps<T>) {
  const { t, tTitle } = useI18nHelper();

  if (!formik.values.id) {
    return (
      <AppPanel title={<Skeleton height={"32px"} />}>
        <Flex flexDirection={"column"} gap={"4px"}>
          <Skeleton height={"16px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
          <Skeleton height={"24px"} />
        </Flex>
      </AppPanel>
    );
  }

  if (!isEditing) {
    return (
      <IsolatedBankRemittanceReceiptPanel
        bankRemittanceReceiptIds={AppUtils.extractIds([
          formik.values.bankRemittanceReceipt,
          formik.values.bankRemittanceReceipts,
        ])}
      />
    );
  }

  return (
    <AppPanel
      title={tTitle(
        isMultiple
          ? "common:related_bank_remittance_receipts"
          : "common:related_bank_remittance_receipt"
      )}
    >
      <Field
        name={isMultiple ? "bankRemittanceReceipts" : "bankRemittanceReceipt"}
      >
        {(
          props: FieldProps<
            FormFields["bankRemittanceReceipts" | "bankRemittanceReceipt"],
            FormFields
          >
        ) => {
          return (
            <FormControl display="flex" flexDirection="column" gap="4px">
              <FormLabel
                fontSize="12px"
                color="#6F6F6C"
                padding="0"
                margin="0"
                display="none"
              >
                {toTitle(
                  t(
                    isMultiple
                      ? "bank_remittance_receipts"
                      : "bank_remittance_receipt"
                  )
                )}
              </FormLabel>

              <AppBankRemittanceReceiptsExclusiveSelector
                id={(Array.isArray(props.field.value)
                  ? props.field.value
                  : props.field.value
                  ? [props.field.value]
                  : []
                ).map((i) => i.id)}
                request={request}
                isMultiple={isMultiple}
                isDisabled={isDisabled}
                onSelect={(value) => onSelect(value)}
              />
            </FormControl>
          );
        }}
      </Field>
    </AppPanel>
  );
}
