import React, { useEffect, useMemo, useRef } from "react";
import useListAllDenseOrderSheetSets, {
  ListAllDenseOrderSheetSetsPathParameter,
  ListAllDenseOrderSheetSetsRequest,
} from "@/features/order-sheet-sets/hooks/use-list-all-dense-order-sheet-sets";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { LinkedOrderSheetSet } from "@/features/invoices/app-order-sheet-set-selector";
import AppExclusiveSelect from "@/features/ui/app-exclusive-select";
import { useTranslation } from "react-i18next";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useBiSWRResponse from "@/features/invoices/deposit-invoices/use-bi-swr-response";
import { isEmpty, uniqBy } from "lodash";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

interface AppOrderSheetSetExclusiveSelectorProps {
  // season__eq?: string;
  // createdById__in?: number[];
  // issuedById__in?: number[];

  request?: ListAllDenseOrderSheetSetsRequest;

  id?: number | number[];
  onSelect: (value: DenseOrderSheetSet[]) => void;
  isDisabled?: boolean;
  selectAll?: boolean;
}

export default function AppOrderSheetSetsExclusiveSelector({
  request,
  id,
  onSelect,
  isDisabled = false,
}: AppOrderSheetSetExclusiveSelectorProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { t } = useI18nHelper();

  const key1 = useMemo(() => {
    if (identity?.company && request && !isEmpty(request)) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request,
      };
    }
  }, [identity, request]);

  const key2 = useMemo(() => {
    if (identity?.company && id) {
      const id__in = Array.isArray(id) ? id : [id];
      if (id__in.length > 0) {
        return {
          parameter: {
            by: identity.company.type,
            companyId: identity.company.id,
          },
          request: {
            id__in,
          },
        };
      }
    }
  }, [id, identity]);

  const response1 = useListAllDenseOrderSheetSets(
    key1?.parameter,
    key1?.request
  );

  const response2 = useListAllDenseOrderSheetSets(
    key2?.parameter,
    key2?.request
  );

  const {
    data: resources,
    isLoading: isListResourcesLoading,
    error: isListResourcesError,
  } = useBiSWRResponse(response1, response2, (data1, data2) => {
    return uniqBy([...(data1 || []), ...(data2 || [])], (i) => i.id);
  });

  useEffect(() => {
    if (isListResourcesError) {
      showError(isListResourcesError);
    }
  }, [isListResourcesError, showError]);

  const value = useMemo(() => {
    if (resources && id) {
      const ids = Array.isArray(id) ? id : [id];
      return ids
        .map((id) => resources.find((i) => i.id === id))
        .filter(
          (resource): resource is DenseOrderSheetSet => resource !== undefined
        );
    }
    return [];
  }, [resources, id]);

  return (
    <AppExclusiveSelect<DenseOrderSheetSet>
      name={"order_sheet_set"}
      values={value}
      options={resources || []}
      optionFunc={(i) => {
        return {
          value: i,
          name: `${i.id} / ${i.lineSheetSet.name}`,
        };
      }}
      onSelect={onSelect}
      isDisabled={isDisabled}
      isLoading={isListResourcesLoading}
    />
  );
}
