import useSWR, { SWRConfiguration } from "swr";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";
import { Company } from "@/features/types";
import { useMemo } from "react";
import {
  calcUrl,
  withListAllFetcher,
} from "@/features/ui/helpers/fetcher-helpers";
import { UrlParser } from "@/features/ui/helpers/url-parser";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import {
  getListFtaSignatureListKey,
  ListFtaSignatureListPathParameter,
  ListFtaSignatureListRequest,
  ListFtaSignatureListResponse,
} from "@/features/invoices/triangular-trades/hooks/use-list-fta-signature";

export interface ListAllFtaSignatureListRequest
  extends Omit<ListFtaSignatureListRequest, "pageSize" | "pageNumber"> {}
type ListAllFtaSignatureListResponse = Company[];
export interface ListAllFtaSignatureListPathParameter
  extends ListFtaSignatureListPathParameter {}

export const listAllFtaSignatureListTemplateUrl = `/:by/:companyId/fta_signature`;

export function getListAllFtaSignatureListKey(
  parameter?: ListAllFtaSignatureListPathParameter,
  request?: ListAllFtaSignatureListRequest
) {
  if (parameter && request) {
    return {
      url: calcUrl(listAllFtaSignatureListTemplateUrl, parameter),
      params: request,
    };
  }
  return null;
}

export function parseListAllFtaSignatureListKey(
  key: AxiosRequestConfig<ListAllFtaSignatureListRequest>
) {
  const urlParser = new UrlParser<ListAllFtaSignatureListPathParameter>(
    listAllFtaSignatureListTemplateUrl,
    {
      by: StringTransformer.parseBy,
      companyId: StringTransformer.parseId,
    }
  );
  return {
    parameter: urlParser.parse(key.url!),
    request: key.params,
  };
}

export default function useListAllFtaSignatureList(
  parameter?: ListAllFtaSignatureListPathParameter,
  request?: ListAllFtaSignatureListRequest,
  config: SWRConfiguration = { revalidateOnFocus: false }
) {
  const listFetcher = useFetcher<
    ListFtaSignatureListRequest,
    ListFtaSignatureListResponse
  >();
  const fetcher = useMemo(() => {
    return withListAllFetcher(
      (config: AxiosRequestConfig<ListFtaSignatureListRequest>) => {
        const { parameter, request } = parseListAllFtaSignatureListKey(config);
        return listFetcher(getListFtaSignatureListKey(parameter, request)!);
      },
      (response) => {
        return response.ftaSignatureList;
      }
    );
  }, [listFetcher]);

  return useSWR(
    getListAllFtaSignatureListKey(parameter, request),
    fetcher,
    config
  );
}
