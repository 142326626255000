import { useParams } from "react-router";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import { useEffect } from "react";

export default function useRequiredParams<T extends Record<string, any>>() {
  const params = useParams<T>();
  const { navigate } = useRouterHelper();

  useEffect(() => {
    if (Object.values(params).some((value) => value === undefined)) {
      navigate("/404");
    }
  }, [navigate, params]);

  return params;
}
