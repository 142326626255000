import { client, ErrorResponse, isErrorPayload } from "@/utils/uno-axios";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import useGetLocation from "@/utils/use-get-location";
import Cookies from "js-cookie";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export type AxiosRequestConfigWithRequest<RequestData> =
  AxiosRequestConfig<RequestData> & RequestData;

export function isErrorResponse(
  item: ErrorResponse | any
): item is ErrorResponse {
  if (item !== undefined) {
    const i = item as ErrorResponse;
    return i.error !== undefined && isErrorPayload(i.error);
  }
  return false;
}

function isCredentialError(response: ErrorResponse) {
  const { error } = response;
  return (
    (error.code === 401 && error.status === "UNAUTHENTICATED") ||
    (error.code === 401 && error.status === "JWT_TOKEN_REFRESH_FAILED") ||
    error.code === 403
  );
}

/*
 * react-router 가 hook만 제공하니까, hook관련된 애는 이친구가 하고, http 통신의 책임은 uno-axios로 분리
 * */

export default function useFetcher<RequestQueryOrData, ResponseData>() {
  const getLocation = useGetLocation();
  const navigate = useNavigate();
  const { t } = useI18nHelper();

  const [error, setError] = useState<ErrorResponse>();

  useEffect(() => {
    if (error) {
      alert(t("message.error.http.403"));
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      if (getLocation().pathname !== "/auth/sign-in") {
        navigate("/auth/sign-in");
      }
    }
  }, [error, getLocation, navigate, t]);

  return useCallback(
    async (config: AxiosRequestConfig<RequestQueryOrData>) => {
      return client<ResponseData, AxiosResponse<ResponseData>>(config)
        .then((r) => {
          return r.data as ResponseData;
        })
        .catch((err: AxiosError<ErrorResponse | any>) => {
          if (isErrorResponse(err) && isCredentialError(err)) {
            // Cookies.remove("access_token");
            // Cookies.remove("refresh_token");
            // if (getLocation().pathname !== "/auth/sign-in") {
            //   alert("!!");
            //   navigate("/auth/sign-in");
            // }
            setError(err);
          }

          throw err;
        });
    },
    [setError]
  );
}
