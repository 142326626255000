import { Flex } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import AppFileDragAndDropZone from "@/features/invoices/app-file-drag-and-drop-zone";
import SVG from "react-inlinesvg";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { FormikProps } from "formik";
import { getFileNameWithoutExtension, isExcel, isPDF } from "@/features/types";
import useCreateStandardOrderForm from "@/features/line-sheet-sets/hooks/use-create-standard-order-form";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import {
  AttachmentFormField,
  isLineSheetFormField,
  LineSheetFormField,
  LineSheetSetFormFields,
} from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import useLineSheetableGrid from "@/features/line-sheet-sets/detail/hooks/use-line-sheetable-grid";
import UploadOrderSheetsModal from "@/features/order-sheet-sets/upload-order-sheets-modal";
import { KeyedMutator } from "swr";
import { GetDenseLineSheetSetResponse } from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import useIdentity from "@/features/ui/hooks/use-identity";
import UpdateOrderSheetToBeSubmittedModal from "@/features/order-status/modals/update-order-sheet-to-be-submitted-modal";
import useLineSheetableRows from "@/features/line-sheet-sets/detail/hooks/use-line-sheetable-rows";
import useFillOutLineSheetSetForm from "@/features/line-sheet-sets/detail/hooks/use-fill-out-line-sheet-set-form";
import { isLineSheetRow } from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import LineSheetableGrid from "@/features/line-sheet-sets/detail/line-sheetable-grid";
import { IsolatedLineSheetRow } from "@/features/line-sheet-sets/detail/isolated-sheet.type";

interface LineSheetableListFormControlProps {
  isEditing?: boolean;
  isRefreshing: boolean;
  formik: FormikProps<LineSheetSetFormFields>;
  mutate?: KeyedMutator<GetDenseLineSheetSetResponse>;
}

export default function LineSheetableListFormControl({
  isEditing,
  isRefreshing,
  formik,
  mutate,
}: LineSheetableListFormControlProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts({ id: "GLOBAL" });
  const { tTitle } = useI18nHelper();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const orderSheetSet = formik.values.orderSheetSet;
  const negativeSequencer = useMemo(() => {
    return new NegativeSequencer();
  }, []);

  const [rows, setRows, rowMapper] = useLineSheetableRows({ formik });
  const rowLengthRef = useRef<number>(rows?.length || 0);

  const lineSheetableGrid = useLineSheetableGrid({
    orderSheetSet: orderSheetSet,
    lineSheetSet: {
      id: formik.values.id!,
      type: formik.values.type,
      status: formik.values.status!,
      publishedAt: formik.values.publishedAt,
      issuedByBoutique: formik.values.issuedByBoutique!,
      isDeleted: formik.values.isDeleted,
    },
    mutate,
    rows,
    setRows,
  });

  const fillOutLineSheetSetForm = useFillOutLineSheetSetForm({ formik });

  useEffect(() => {
    if (rows && rows.length > 0 && rowLengthRef.current === 0) {
      const lineSheetRow = rows.find(
        (row): row is IsolatedLineSheetRow =>
          isLineSheetRow(row) && row.type === "LINE_SHEET"
      );
      if (lineSheetRow) {
        fillOutLineSheetSetForm(lineSheetRow);
      }
    }
    rowLengthRef.current = rows?.length || 0;
  }, [fillOutLineSheetSetForm, rows]);

  const {
    fire: fireCreateStandardOrderForm,
    data: createStandardOrderFormData,
    error: createStandardOrderFormError,
    isLoading: isCreateStandardOrderFormLoading,
    clear: clearCreateStandardOrderForm,
  } = useCreateStandardOrderForm();

  useEffect(() => {
    if (createStandardOrderFormError) {
      showError(createStandardOrderFormError);
    }
  }, [showError, createStandardOrderFormError]);

  useEffect(() => {
    if (createStandardOrderFormData) {
      //추가하고
      setRows((prev) => {
        const file = createStandardOrderFormData;
        const orderFormRow: LineSheetFormField = {
          id: negativeSequencer.next(),
          file: file,
          name: getFileNameWithoutExtension(file),
          type: "ORDER_FORM",
          compressionStatus: null,
          isCheckable: true,
          isChecked: false,
          orderSheets: [],
          reviewStatus: null,
          updatedAt: new Date(),
        };
        const next = [
          ...(prev || []),
          ...(rowMapper.toRows([orderFormRow]) || []),
        ];
        return next;
      });
      clearCreateStandardOrderForm();
    }
  }, [
    createStandardOrderFormData,
    clearCreateStandardOrderForm,
    formik,
    negativeSequencer,
    setRows,
    rowMapper,
  ]);

  const handleFilesAdd = useCallback(
    (value: File | File[]) => {
      const files = Array.isArray(value) ? value : [value];

      setRows((prev) => {
        return [
          ...(prev || []),
          ...(rowMapper.toRows(
            files.map((file) => {
              if (isExcel(file) || isPDF(file)) {
                const next: LineSheetFormField = {
                  id: negativeSequencer.next(),
                  file: file,
                  name: getFileNameWithoutExtension(file),
                  type: "LINE_SHEET",
                  compressionStatus: null,
                  isCheckable: true,
                  isChecked: false,
                  orderSheets: [],
                  reviewStatus: null,
                  updatedAt: new Date(),
                };
                return next;
              } else {
                const next: AttachmentFormField = {
                  id: negativeSequencer.next(),
                  file: file,
                  name: file.name,
                  isCheckable: true,
                  isChecked: false,
                };
                return next;
              }
            })
          ) || []),
        ];
      });
    },
    [negativeSequencer, rowMapper, setRows]
  );

  return (
    <>
      <Flex
        // overflowY={"auto"}
        // height={"1200px"}
        // style={{ scrollbarGutter: "stable" }}
        flexDirection={"column"}
        fontSize={"12px"}
        gap={"16px"}
      >
        <input
          type={"file"}
          multiple={true}
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            // handleFilesChange(e.target.files);
            // e.target.value = "";
          }}
        />
        <Flex flexDirection={"column"} gap={"12px"}>
          {isEditing && (
            <>
              <AppFileDragAndDropZone
                isMultiple={true}
                onFilesAdd={handleFilesAdd}
              />
              {identity?.company?.type === "AGENCY" && (
                <AppButton
                  isDisabled={(() => {
                    return (
                      formik.values.lineSheetOrAttachmentList.filter(
                        isLineSheetFormField
                      ).length > 0
                    );
                  })()}
                  isLoading={isCreateStandardOrderFormLoading}
                  onClick={() => {
                    fireCreateStandardOrderForm({}, {});
                  }}
                >
                  <SVG
                    src={"/icons/icon_add.svg"}
                    width={"14px"}
                    height={"14px"}
                    style={{ marginRight: "8px" }}
                  />
                  {tTitle("line_sheet_sets:add_standard_order_form")}
                </AppButton>
              )}
            </>
          )}

          <LineSheetableGrid
            rows={rows}
            setRows={setRows}
            isRefreshing={isRefreshing}
            fileInputRef={fileInputRef}
            sequencer={negativeSequencer}
            lineSheetableGrid={lineSheetableGrid}
            orderSheetSet={orderSheetSet}
            isEditing={isEditing}
          />
        </Flex>
      </Flex>
      {lineSheetableGrid.disclosureUploadOrderSheetModal.data && (
        <UploadOrderSheetsModal
          {...lineSheetableGrid.disclosureUploadOrderSheetModal.data}
          isOpen={lineSheetableGrid.disclosureUploadOrderSheetModal.isOpen}
          onClose={lineSheetableGrid.disclosureUploadOrderSheetModal.onClose}
        />
      )}
      {lineSheetableGrid.disclosureUpdateToBeSubmittedModal.data && (
        <UpdateOrderSheetToBeSubmittedModal
          key={`UpdateOrderSheetToBeSubmittedModal_${lineSheetableGrid.disclosureUpdateToBeSubmittedModal.data.orderSheetId}`}
          {...lineSheetableGrid.disclosureUpdateToBeSubmittedModal.data}
          isOpen={lineSheetableGrid.disclosureUpdateToBeSubmittedModal.isOpen}
          onClose={lineSheetableGrid.disclosureUpdateToBeSubmittedModal.onClose}
        />
      )}
    </>
  );
}
