import {
  isProductFiltered,
  SheetTabFilterQuery,
} from "@/features/order-sheet-sets/sheet-tab-filter";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo, useRef } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import {
  ListAllLineSheetTabsPathParameter,
  ListAllLineSheetTabsRequest,
  useListAllLineSheetTabs,
} from "@/features/line-sheet-sets/hooks/use-list-all-line-sheet-tabs";
import { useSetRecoilState } from "recoil";
import {
  InflatedProduct,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { AppUtils } from "@/features/ui/utils/app-utils";

export interface UseFetchAndSetLineSheetTabsParameter {
  data:
    | {
        lineSheetSetId: number;
        lineSheetId: number;
        tabIndexes: number[];
      }
    | undefined;
  query?: SheetTabFilterQuery;
}

export default function useFetchAndSetLineSheetTabs({
  data,
  query,
}: UseFetchAndSetLineSheetTabsParameter) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { error: showError } = useAppToasts();

  const listAllLineSheetTabsKey = useMemo(():
    | {
        parameter: ListAllLineSheetTabsPathParameter;
        request: ListAllLineSheetTabsRequest;
      }
    | undefined => {
    if (data && company) {
      return {
        parameter: {
          by: company.type,
          companyId: company.id,
          lineSheetSetId: data.lineSheetSetId,
          lineSheetId: data.lineSheetId,
        },
        request: {
          tabIndexes: data.tabIndexes,
        },
      };
    }

    return undefined;
  }, [data, company]);

  const {
    isLoading: isListAllLineSheetTabsLoading,
    isValidating: isListAllLineSheetTabsValidating,
    data: listAllLineSheetTabsData,
    error: listAllLineSheetTabsError,
    mutate: mutateListAllLineSheetTabs,
  } = useListAllLineSheetTabs(
    listAllLineSheetTabsKey?.parameter,
    listAllLineSheetTabsKey?.request
  );

  useEffect(() => {
    showError(listAllLineSheetTabsError);
  }, [showError, listAllLineSheetTabsError]);

  const setInflatedTabsFamily = useSetRecoilState(
    inflatedTabsFamily({
      lineSheetSetId: data?.lineSheetSetId || -1,
      lineSheetId: data?.lineSheetId || -1,
    })
  );

  useEffect(() => {
    if (
      !isListAllLineSheetTabsLoading &&
      !isListAllLineSheetTabsValidating &&
      listAllLineSheetTabsData
    ) {
      setInflatedTabsFamily({
        key: {
          lineSheetSetId: listAllLineSheetTabsData._lineSheetSetId,
          lineSheetId: listAllLineSheetTabsData._lineSheetId,
        },
        tabs: listAllLineSheetTabsData.tabs.map((tab) => {
          return {
            ...tab,
            products: tab.products.map((product): InflatedProduct => {
              return {
                ...product,
                priceAdjustedBy: {
                  ...product.priceAdjustedBy,
                  value: AppUtils.trimFloat(product.priceAdjustedBy.value),
                },
                isFiltered: isProductFiltered(product, query),
                orderQuantityWithOptionList:
                  product.stockQuantityWithOptionList.map((i) => {
                    return {
                      quantity: 0,
                      name: i.name,
                    };
                  }),
                firstOrderQuantityWithOptionList: null,
                prevOrderQuantityWithOptionList: null,
                latestOrderQuantityWithOptionList: null,
                confirmedQuantityWithOptionList: null,
                confirmedPrice: null,
                confirmedPriceAdjustedBy: null,
                newStockWithOptionList: null,
              };
            }),
            isDirty: false,
          };
        }),
      });
    }
  }, [
    isListAllLineSheetTabsLoading,
    listAllLineSheetTabsData,
    setInflatedTabsFamily,
    query,
    isListAllLineSheetTabsValidating,
  ]);

  return {
    mutateListAllLineSheetTabs,
  };
}
