import useYWRInfinite from "@/features/ui/hooks/use-ywr-inifite";
import useFetcher from "@/utils/use-fetcher";
import {
  deleteLineSheetSetKey,
  DeleteLineSheetSetRequest,
  DeleteLineSheetSetResponse,
} from "@/features/line-sheet-sets/hooks/use-delete-line-sheet-set";

export default function useDeleteLineSheetSetInfinite() {
  const fetcher = useFetcher<
    DeleteLineSheetSetRequest,
    DeleteLineSheetSetResponse
  >();
  return useYWRInfinite(deleteLineSheetSetKey, fetcher);
}
