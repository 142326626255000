import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import { FormikProps } from "formik";
import { useCallback, useMemo } from "react";
import {
  isOrderSheetRow,
  lineSheetableMapper,
  LineSheetableRow,
} from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import useIdentity from "@/features/ui/hooks/use-identity";

interface UseLineSheetableRowsParameters {
  formik: FormikProps<LineSheetSetFormFields>;
}

export default function useLineSheetableRows({
  formik,
}: UseLineSheetableRowsParameters) {
  const identity = useIdentity();
  const rowMapper = useMemo(() => {
    return lineSheetableMapper.copy();
  }, []);

  const rows = useMemo(() => {
    let rows = rowMapper.toRows(formik.values.lineSheetOrAttachmentList);

    if (identity?.company?.type === "BUYER") {
      rows = rows?.map((row) => {
        if (isOrderSheetRow(row)) {
          return {
            ...row,
            isRead: true,
          };
        }
        return row;
      });
    }

    return rows;
  }, [
    rowMapper,
    formik.values.lineSheetOrAttachmentList,
    identity?.company?.type,
  ]);

  const setRows = useCallback(
    (
      valueOrUpdater:
        | LineSheetableRow[]
        | ((
            prev: LineSheetableRow[] | undefined
          ) => LineSheetableRow[] | undefined)
    ) => {
      let next: LineSheetableRow[] | undefined;
      if (typeof valueOrUpdater === "function") {
        next = valueOrUpdater(rows);
      } else {
        next = valueOrUpdater;
      }

      const lineSheetOrAttachmentList = next ? rowMapper.toResources(next) : [];
      formik.setValues((prev) => {
        return {
          ...prev,
          lineSheetOrAttachmentList,
        };
      });
    },
    [formik, rowMapper, rows]
  );

  return [rows, setRows, rowMapper] as const;
}
